import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";


export const logout = async () => {
  const navigate = useNavigate();
  const auth = getAuth();
  try {
    //Signout from firebase
    await signOut(auth);

    // Handle successful logout, like redirecting to the login page
    console.log("User logged out successfully");
    navigate("/sign-in", { state: { message: "User logged out successfully" } });
  } catch (error) {
    console.log("Error during logout:", error);
    navigate("/sign-in", { state: { message: error.message } });
  }
};


export const generateRandomStringForImage = (length) => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
};

export const isPlanExpired = (currentUserPlanExpiryDate) => {

  // Convert Firestore timestamp to a JavaScript Date object
  const expiryInDate = new Date(currentUserPlanExpiryDate?.seconds * 1000); // Convert seconds to milliseconds

  // Parse the expiry date
  const expiryDate = new Date(expiryInDate);

  // Get the current date
  const now = new Date();
  // console.log(currentUserPlanExpiryDate);
  // console.log(now);
  // console.log(now >= expiryDate);

  // Check if the current date is past the expiry date
  return now >= expiryDate;
};