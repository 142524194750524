import React, { useState } from 'react';
import './EasyFilterAdminUserComponent.css'; // Import your CSS file

const EasyFilterAdminUserComponent = ({ handleFilterClick, activePostStatus, activeCount, unactiveCount }) => {
    const getTextClass = (filter) => {
        switch (filter) {
            case 'All': return '29';
            case 'Active': return '31';
            case 'Unactive': return '32';
            // case 'Verified': return '39';
            // case 'Unverified': return '40';
            default: return '';
        }
    };

    return (
        <div className="easy-filter-user-admin-button-group custom-cursor">
            {['All', 'Active', 'Unactive'].map(filter => (
                <div
                    key={filter}
                    className={`easy-filter-user-admin-button-group-base1 ${activePostStatus === filter ? 'active' : ''}`}
                    onClick={() => handleFilterClick(filter)}
                >
                    <div className={`easy-filter-user-admin-text${getTextClass(filter)}`}>
                        {filter}
                    </div>

                    {filter === 'Active' && activeCount > 0 && (
                        <div className="easy-filter-user-badge">
                            <div className='easy-filter-user-badge-container-success'>
                                <div className='easy-filter-user-badge-counter-success'>{activeCount}</div>
                            </div>
                        </div>
                    )}
                    {filter === 'Unactive' && unactiveCount > 0 && (
                        <div className="easy-filter-user-badge">
                            <div className='easy-filter-user-badge-container'>
                                <div className='easy-filter-user-badge-counter'>{unactiveCount}</div>
                            </div>
                        </div>
                    )}

                </div>
            ))}
        </div>
    );
}

export default EasyFilterAdminUserComponent;
