import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import Pusher from "pusher-js";
import { MessageBox } from "./MessageBox";
import { useLazyGetUserProfileQuery } from "../../../api/UsermetaAPI";
import {
  useSaveMetadataMutation,
  useSaveConversationMutation,
  useSendMessageMutation,
  useLazyCheckChatMetadataQuery,
  useSaveEachMessageMutation,
  useLazyFetchMessagesQuery,
  useLazyFetchInitialMessagesForDisplayQuery,
  useUpdateOnlineStatusMutation,
  useMarkMessagesAsReadApproacherMutation,
} from "../../../api/ChatAPI";
import { useLazyGetPostTypesQuery } from "../../../api/TaxonomyFormAPI";

import { ChatAPI } from "../../../api/ChatAPI"; // Import your ChatAPI instance
import { updateChatCache } from "../../../helper/ChatHelper";
import SkeletonChatLoaderComponent from "../../SkeletonLoaderComponent/SkeletonChatLoaderComponent/SkeletonChatLoaderComponent";
import { ErrorShowComponent } from "../../ErrorShowComponent/ErrorShowComponent";

const ChatPanel = ({ currentUserId, displayName, profileImageLink, listingData }) => {
  const { listing, id, entity } = useParams();
  const pusherInstance = useRef(null); // Persist Pusher instance
  // const [triggerGetUserProfile, { error, isLoading }] =
  //   useLazyGetUserProfileQuery();
  const [sendMessage, { isLoading: isSendMessageLoading, isError: isSendMessageError, isSuccess: isSendMessageSuccess }] = useSendMessageMutation();
  const [saveMetadata] = useSaveMetadataMutation();
  const [saveConversation] = useSaveConversationMutation();
  const [messages, setMessages] = useState([]);
  const [postTypeMap, setPostTypeMap] = useState(new Map());
  const [triggerCheckChatMetadata] = useLazyCheckChatMetadataQuery();
  const [saveEachMessage] = useSaveEachMessageMutation();
  const [metadataId, setMetadataId] = useState(null);
  const [page, setPage] = useState(1); // Pagination state
  //const [triggerfetchMessages, { data: fetchedMessages, isLoading: isFetchLoading, isError: isFetchError }] = useLazyFetchMessagesQuery();
  const [triggerFetchMessages, { isFetching: isMessageFetching, error: isMessageFetchError }] = useLazyFetchMessagesQuery();
  const [triggerFetchInitialMessagesForDisplay, { data: initialMessagesForDisplay, isLoading: isInitialMessagesForDisplayLoading, isError: isInitialMessagesForDisplayError }] = useLazyFetchInitialMessagesForDisplayQuery();
  const isInitialMessagesFetched = useRef(false);
  // Add new state for online status
  const [isOnline, setIsOnline] = useState(true);
  const [channelName, setChannelName] = useState(null);
  const [isOwnerOnline, setIsOwnerOnline] = useState(false);
  const [updateOnlineStatus] = useUpdateOnlineStatusMutation(); // New API hook

  const [triggerFetchPostTypes, { data: postTypeQueryData }] =
    useLazyGetPostTypesQuery();

  // const { data: unreadMessages, refetch: fetchUnreadMessages } = useFetchUnreadMessagesCountQuery(currentUserId);
  const [markMessagesAsReadApproacher] = useMarkMessagesAsReadApproacherMutation();
  const timeoutRef = useRef(null); // To prevent multiple rapid updates
  const [hasMoreMessages, setHasMoreMessages] = useState(true); // Default: True

  //When page loads chat data then make unread all message read

  useEffect(() => {

    if (listingData && currentUserId && channelName) {
      const recipientId =
        listingData?.device_user?.id ||
        listingData?.spare_user?.id ||
        listingData?.software_user?.id ||
        listingData?.service_user?.id ||
        listingData?.job_user?.id;

      // const postId = postTypeMap.get(entity);
      if (!recipientId) {
        console.warn("Recipient ID not found, skipping message read update. Or postId not found");
        return;
      }

      // const channel_name = `private-chat-${listing}-${postId}-${Math.min(
      //   currentUserId,
      //   recipientId
      // )}-${Math.max(currentUserId, recipientId)}`;

      console.log("Subscribing to Pusher channel:", channelName);

      // ✅ Mark messages as read on first load with a slight delay
      setTimeout(() => {
        markMessagesAsReadApproacher({ channel_name: channelName, user_id: currentUserId, recipient_id: recipientId })
          .unwrap()
          .then(() => console.log("Initial messages marked as read."))
          .catch((error) => console.error("Error marking messages as read:", error));
      }, 500); // Small delay to ensure backend processes correctly

      // ✅ Subscribe to Pusher
      if (!pusherInstance.current) {
        pusherInstance.current = new Pusher("b4134b5b9a56f5c6f410", {
          cluster: "us2",
          authEndpoint: `${process.env.REACT_APP_API_URL}/pusher/auth`,
        });
      }

      const channel = pusherInstance.current.subscribe(channelName);

      channel.bind("new-message", (newMessage) => {
        if (newMessage.sender === currentUserId) {
          console.log("Ignoring own message.");
          return;
        }

        console.log("New message received, marking as read...");

        // ✅ Prevent duplicate read requests (if messages come in rapidly)
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
          markMessagesAsReadApproacher({ channel_name: channelName, user_id: currentUserId, recipient_id: recipientId })
            .unwrap()
            .then(() => console.log("New messages marked as read."))
            .catch((error) => console.error("Error marking new messages as read:", error));
        }, 1000); // Delay to ensure the read request is handled correctly
      });

      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        channel.unbind_all();
        channel.unsubscribe();
      };
    }
  }, [listingData, currentUserId, channelName]);

  useEffect(() => {
    if (currentUserId) {
      // triggerGetUserProfile();
      triggerFetchPostTypes();
    }
  }, [currentUserId, triggerFetchPostTypes]);

  useEffect(() => {
    if (postTypeQueryData) {
      const map = new Map(postTypeQueryData.map((item) => [item.name, item.id]));
      setPostTypeMap(map);
    }
  }, [postTypeQueryData, setPostTypeMap]);

  //PUSHER SUBSCRIPTION LOGIC START

  useEffect(() => {
    if (!pusherInstance.current) {
      pusherInstance.current = new Pusher("b4134b5b9a56f5c6f410", {
        cluster: "us2",
        authEndpoint: `${process.env.REACT_APP_API_URL}/pusher/auth`,
      });
    }

    if (metadataId && currentUserId && listingData) {
      const userId = currentUserId;
      const recipientId = listingData?.device_user?.id || listingData?.spare_user?.id || listingData?.software_user?.id || listingData?.service_user?.id || listingData?.job_user?.id;
      const postId = postTypeMap.get(entity);
      const channelName = `private-chat-${listing}-${postId}-${Math.min(userId, recipientId)}-${Math.max(userId, recipientId)}`;
      setChannelName(channelName);

      console.log('Subscribing to channel,  postId, recipientId ,userId: ', channelName, postId, recipientId, userId);
      console.log("listingData ", listingData)
      const channel = pusherInstance.current.subscribe(channelName);

      channel.bind("new-message", (newMessage) => {
        if (newMessage.sender === currentUserId) {
          console.log("This is a message sent by me, ignoring as received.");
          return; // Ignore messages sent by the same user
        }
        console.log("New message received in Pusher:");
        console.log("Message Data:", newMessage);


        // Mark message as real-time
        newMessage.real_time = true;

        // Use the helper function to update the cache
        updateChatCache({
          channelName: channelName,
          newMessage,
          page: 1,
        });
        setMessages((prevMessages) => [...prevMessages, newMessage]);
      });


      // Add listener for online status updates
      channel.bind("user-status-update", (statusUpdate) => {

        const { userId, isOnline, channelName, postId, entityType } = statusUpdate;

        // Check if this status update is for a user we're chatting with
        if (postId !== listingData.id || entityType !== entity || currentUserId !== userId) {
          console.log("Status update not relevant to this listing");
          return;
        }

        console.log(statusUpdate);

        console.log("Status update received:", statusUpdate);

        setIsOwnerOnline(isOnline);
        // // Update the online users list
        // setOnlineUsers(prevOnlineUsers => {
        //   const updatedOnlineUsers = new Set(prevOnlineUsers);
        //   console.log("Online user set : " + prevOnlineUsers);

        //   if (isOnline) {
        //     updatedOnlineUsers.add(userId);
        //   } else {
        //     updatedOnlineUsers.delete(userId);
        //   }

        //   return updatedOnlineUsers;
        // });

        // // Update the SidePanel profiles to show online status
        // setSidePanelProfiles(prevProfiles => {
        //   return prevProfiles.map(profile => {
        //     if (profile.senderId === userId) {
        //       return {
        //         ...profile,
        //         isOnline: isOnline
        //       };
        //     }
        //     return profile;
        //   });
        // });
        // setShowCardHeader(true);
      });

      // Cleanup
      return () => {
        channel.unbind_all();
        channel.unsubscribe();
      };
    }
  }, [metadataId, listingData, postTypeMap, entity]);

  //PUSHER SUBSCRIPTION LOGIC END

  useEffect(() => {
    console.log("Updated messages state:", messages);
  }, [messages]); // Runs whenever `messages` changes


  // Add this useEffect to handle online status
  useEffect(() => {
    console.log('Inside online status update');

    const handleOnlineStatus = async () => {
      if (!currentUserId || !listingData || !postTypeMap.get(entity)) {
        return;
      }

      const userId = currentUserId;
      const recipientId = listingData?.device_user?.id ||
        listingData?.spare_user?.id ||
        listingData?.software_user?.id ||
        listingData?.service_user?.id ||
        listingData?.job_user?.id;
      const postId = postTypeMap.get(entity);

      const channel_name = `private-chat-${listing}-${postId}-${Math.min(
        userId,
        recipientId
      )}-${Math.max(userId, recipientId)}`;

      // Update online status when component mounts
      try {
        await updateOnlineStatus({
          userId,
          isOnline: true,
          channelName: channel_name
        }).unwrap();

        setIsOnline(true);
        console.log('online is set true');

      } catch (error) {
        console.error("Error updating online status:", error);
      }

      // Set up event listeners for browser visibility/focus changes
      const handleVisibilityChange = async () => {
        const newOnlineStatus = !document.hidden;
        setIsOnline(newOnlineStatus);

        try {
          await updateOnlineStatus({
            userId,
            isOnline: newOnlineStatus,
            channelName: channel_name
          }).unwrap();
          console.log('offline user is set');

        } catch (error) {
          console.error("Error updating online status:", error);
        }
      };

      document.addEventListener("visibilitychange", handleVisibilityChange);

      // Cleanup: Set offline status when component unmounts
      return () => {
        document.removeEventListener("visibilitychange", handleVisibilityChange);

        // Set offline status when leaving
        updateOnlineStatus({
          userId,
          isOnline: false,
          channelName: channel_name
        }).catch(error => console.error("Error updating offline status:", error));
      };
    };

    handleOnlineStatus();
  }, [currentUserId, listingData, postTypeMap, entity, updateOnlineStatus]);

  //HANDLEMORE FUNCTION TO FETCH MESSAGES LOAD MORE
  // const handleLoadMore = async () => {
  //   try {
  //     const userId = decryptedData?.user?.id;
  //     const recipientId = deviceData?.data?.device_user?.id ||  deviceData?.data?.spare_user?.id ||  deviceData?.data?.software_user?.id ||  deviceData?.data?.service_user?.id ||  deviceData?.data?.job_user?.id;
  //     const postId = postTypeMap.get(entity);

  //       console.log("user and rec id:", userId, recipientId);
  //       const channel_name = `private-chat-${listing}-${postId}-${Math.min(
  //         userId,
  //         recipientId
  //       )}-${Math.max(userId, recipientId)}`;

  //       const response = await triggerFetchMessages({ channel_name, page }).unwrap();
  //       if (response?.messages) {
  //         setMessages((prevMessages) => [...response.messages, ...prevMessages]);
  //         setPage((prevPage) => prevPage + 1); // Increment page for the next fetch
  //       }
  //     } catch (error) {
  //       console.error("Error fetching historical messages:", error);
  //     }
  //   };



  const handleLoadMore = async () => {
    try {
      const userId = currentUserId;
      const recipientId =
        listingData?.device_user?.id ||
        listingData?.spare_user?.id ||
        listingData?.software_user?.id ||
        listingData?.service_user?.id ||
        listingData?.job_user?.id;

      const postId = postTypeMap.get(entity);

      console.log("user and rec id:", userId, recipientId);

      const channel_name = `private-chat-${listing}-${postId}-${Math.min(
        userId,
        recipientId
      )}-${Math.max(userId, recipientId)}`;

      const response = await triggerFetchMessages({ channel_name, page }).unwrap();


      if (response?.messages.length > 0) {
        setMessages((prevMessages) => {
          // Extract unique identifiers from your current messages
          const existingIds = new Set(prevMessages.map(msg => msg.messageId));
          const existingSignatures = new Set(prevMessages.map(msg => `${msg.sender}-${msg.content}`));

          // Filter out any duplicates using both checks
          const newMessages = response.messages.filter(msg =>
            !existingIds.has(msg.messageId) &&
            !existingSignatures.has(`${msg.sender}-${msg.content}`)
          );

          return [...newMessages, ...prevMessages];
        });

        setPage((prevPage) => prevPage + 1);
      } else {
        setHasMoreMessages(false);
      }
    } catch (error) {
      console.error("Error fetching historical messages:", error);
      if (error.status === 404) {
        setHasMoreMessages(false); // ✅ Hide button on 404 error
      }
    }
  };


  const handleSaveMetadataAndConversation = async (
    channel_name,
    userId,
    recipientId,
    subject,
    postId
  ) => {
    console.log("printing postid in save: ", postId);
    try {
      const metadataResponse = await saveMetadata({
        channel_name,
        user_id: userId,
        recipient_id: recipientId,
        post_id: postId,
        listing_type: listing,
        entity_type: entity,
      }).unwrap();

      const conversationResponse = await saveConversation({
        metadata_id: metadataResponse.metadata_id,
        subject,
      }).unwrap();

      return { metadataResponse, conversationResponse };
    } catch (error) {
      console.error("Error saving metadata or conversation:", error);
      throw error;
    }
  };

  // Helper function to check metadata and optionally create it
  const subscribeWithMetadataCheck = async (
    channel_name,
    userId,
    recipientId,
    postId,
    subject
  ) => {
    try {
      console.log("CHANNEL NAME WHEN CHECKING FOR DATA: ", channel_name);
      const response = await triggerCheckChatMetadata({ channel_name }).unwrap();
      console.log("RESPONSE:", response);

      console.log("HELLO SUBSCRIBING AND CHECKING NOW");
      if (response?.status) {
        console.log("yes, EXIST!", response.metadata_id);
        return response.metadata_id;
      } else {
        console.log("NO, does not exist. SAVING METADATA AND CONVO");
        const result = await handleSaveMetadataAndConversation(
          channel_name,
          userId,
          recipientId,
          subject,
          postId
        );

        if (result?.metadataResponse) {
          console.log("Created result :", result?.metadataResponse);
          return result.metadataResponse.metadata_id;
        } else {
          throw new Error("Failed to create metadata");
        }
      }
    } catch (error) {
      console.error("Error checking or saving metadata:", error);
      throw error;
    }
  };

  // Step 2: Initialize chat
  useEffect(() => {
    const initializeChat = async () => {
      if (

        !currentUserId ||
        !listingData ||
        !postTypeMap.get(entity)
      ) {
        return;
      }
      console.log("HELLO INITALIZING NOW", postTypeMap.get(entity));

      const userId = currentUserId;
      const recipientId = listingData?.device_user?.id || listingData?.spare_user?.id || listingData?.software_user?.id || listingData?.service_user?.id || listingData?.job_user?.id;
      const postId = postTypeMap.get(entity);

      console.log("HELLO INITALIZING postid NOW", postId);
      console.log("user and rec id:", userId, recipientId);
      const channel_name = `private-chat-${listing}-${postId}-${Math.min(
        userId,
        recipientId
      )}-${Math.max(userId, recipientId)}`;

      try {
        const metadata_id = await subscribeWithMetadataCheck(
          channel_name,
          userId,
          recipientId,
          listingData?.id,
          entity
        );
        setMetadataId(metadata_id);

        console.log("1st metaid:", metadataId);
      } catch (error) {
        console.error("Error initializing chat:", error);
      }
    };

    initializeChat();
  }, [currentUserId, listingData, postTypeMap, setMetadataId, metadataId]);

  //FETCH THE INITIAL MESSAGES FOR DISPLAY BTU NOT WORKING
  useEffect(() => {  //NEW DONE
    const initializeChat = async () => {
      if (!currentUserId || !listingData || !postTypeMap || isInitialMessagesFetched?.current) {
        return;
      }
      console.log("POST TYPE MAP ", postTypeMap, isInitialMessagesFetched.current);
      try {
        const userId = currentUserId;
        const recipientId = listingData?.device_user?.id || listingData?.spare_user?.id || listingData?.software_user?.id || listingData?.service_user?.id || listingData?.job_user?.id;
        const postId = postTypeMap?.get(entity);

        console.log("user and rec id:", userId, recipientId);
        const channel_name = `private-chat-${listing}-${postId}-${Math.min(
          userId,
          recipientId
        )}-${Math.max(userId, recipientId)}`;

        const response = await triggerFetchInitialMessagesForDisplay(channel_name).unwrap();
        isInitialMessagesFetched.current = true;
        console.log("TRIGGER FETCH IN CHAT APNEL ", response);
        if (response?.messages) {
          //  console.log("response in CHAT PNAEL : ",response?.messages)
          setMessages(response.messages); // Populate messages with historical data
        }
      } catch (error) {
        console.error("Error fetching initial messages:", error);
      }
    };

    initializeChat();
  }, [currentUserId, listingData, postTypeMap?.get(entity)]);


  const handleSendMessage = (messageText) => {
    if (!messageText.trim()) return;

    const userId = currentUserId;
    // const profileImageLink = profileImageLink;
    // const displayName = currentUserName;
    const recipientId = listingData?.device_user?.id || listingData?.spare_user?.id || listingData?.software_user?.id || listingData?.service_user?.id || listingData?.job_user?.id;
    const postId = postTypeMap.get(entity);

    const channel_name = `private-chat-${listing}-${postId}-${Math.min(
      userId,
      recipientId
    )}-${Math.max(userId, recipientId)}`;
    console.log("printing channel  and listingData", channel_name, listingData);

    const time = Date.now();

    const formattedTime = new Date(time).toISOString();
    const newMessage = {
      sender: userId,
      timestamp: formattedTime,
      content: messageText,
    };


    console.log("message timestamp", messageText, formattedTime)
    sendMessage({
      channel: channel_name,
      message: messageText,
      sender: userId,
      timestamp: formattedTime,
      profileImageLink,
      displayName,
    })
      .unwrap()
      .then(() => {
        setMessages((prevMessages) => [...prevMessages, newMessage])
        saveEachMessage({
          message_metadata_id: metadataId,
          message: messageText,
          message_type: "text",
          message_status: true,
          sender_id: userId, // NEW
        }).unwrap();
      })
      .catch((err) => {
        console.error("Error sending message:", err)
        // return
      });

    // console.log({
    //   message_metadata_id: metadataId,
    //   message: messageText,
    //   message_type: "text",
    //   message_status: true,
    //   sender_id: userId,
    // });



  };


  // useEffect(() => {
  //   if (isSendMessageSuccess) {
  //     saveEachMessage({
  //       message_metadata_id: metadataId,
  //       message: messageText,
  //       message_type: "text",
  //       message_status: true,
  //       sender_id: userId, // NEW
  //     }).unwrap();
  //   }
  // }, [isSendMessageSuccess])


  // if (isLoading) return <div>Loading...</div>;
  // if (error) return <div>Error loading data</div>;

  return (
    <form className="chatB-chat-panel" onSubmit={(e) => e.preventDefault()}>
      <div className="chatB-chat-content">
        {currentUserId && listingData ? (
          <>
            <MessageBox
              messages={messages}
              setMessages={setMessages}
              isOwnerOnline={isOwnerOnline}
              currentUserId={currentUserId}
              onSendMessage={handleSendMessage}
              isSendMessageLoading={isSendMessageLoading}
              listingData={listingData}
              onLoadMore={handleLoadMore} // Pass load more functionality
              isSendMessageError={isSendMessageError}
              isSendMessageSuccess={isSendMessageSuccess}
              isMessageFetching={isMessageFetching}
              isMessageFetchError={isMessageFetchError}
              hasMoreMessages={hasMoreMessages}
            />

          </>
        ) : (
          <ErrorShowComponent message1={'Oops! we got an issue while loading chat.'} />
        )}
      </div>
      {/* <SkeletonChatLoaderComponent /> */}
    </form>
  );
  // if (listingData) {
  // }
};

export { ChatPanel };
