import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  softwareCategory: '',
  softwareValueProposition: '',
  softwareType: '',
  installationLocation: '',
  rangeMin: '',
  rangeMax: '',
  unitOfMeasureSoftware: '',
  clinicalApplications: '',
  purposeUse: '',
  deviceCategory: '',
  physicalLocation: '',

  softwareFeaturesHighlights: '',
  softwareBenefitsHighlights: '',
  listParentSoftware: '',
  featureImage: '',
  featureImageObject: {},
  gallery: "",
  galleryImageObject: [],
  video: '',
  linkVideo: '',
  location: '',
  serviceAndSupportReach: '',
  
  softwareFeatures: '',
  softwareBenefits: '',
  systemRequirements: '',
  additionalInformation: '',
  document: '',
  documentFileObject: {}
}



const adminEditSoftwareSlice = createSlice({
  name: "adminEditSoftware",
  initialState,
  reducers: {
    fillAdminEditSoftwareForm(state, action) {
     return action.payload;
    },
    updateAdminEditSoftwareField(state, action) {
      const {field, value} = action.payload
      if (value !== undefined) { 
        state[field] = value; 
      }
    },
    clearAdminEditSoftwareForm() {
      return initialState;
    }
  },
});

export const { updateAdminEditSoftwareField,clearAdminEditSoftwareForm,fillAdminEditSoftwareForm } = adminEditSoftwareSlice.actions

export default adminEditSoftwareSlice.reducer

