import React, { useState, useRef, useEffect } from 'react';
import "./BeaconBroadcastSlideOut.css";
import { createPortal } from 'react-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Icons
import xclose from "../../../icons/Gerator/slide_out/xclose.svg";
import searchlg from "../../../icons/Gerator/contacts/searchlg.svg";
import campaign from "../../../icons/Gerator/sales_iq/campaign.svg";

// Helpers
import { decryptErrorData } from '../../../helper/AESHelper';
import { ErrorShowComponent } from '../../ErrorShowComponent/ErrorShowComponent';
import { useAddBeaconBroadcastMutation, useLazyGetAllUsersForWantedDeviceBeaconBroadcastQuery } from '../../../api/Beacon/BeaconBroadcastAPI';
import SearchInputListingComponent from '../../SearchInputListingComponent/SearchInputListingComponent';
// import { useGetRelatedUserOfWantedDeviceSpecification } from '../../../hooks/yourApiHooks'; // Import your actual API hook

export const BeaconBroadcastSlideOut = ({ onClose, currentPostId, postTitle, postType }) => {
  const [initialValues, setInitialValues] = useState({
    id: currentPostId,
    postType: postType ? postType : '',
    title: postTitle,
    recipientUserId: [], // Changed to array to store multiple IDs
    message: '',
  });
  const [offSet, setOffSet] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [backendValidation, setBackendValidation] = useState([]);
  const formikRef = useRef(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState({
    message: "",
    color: "",
  });

  const [triggerGetRelatedUser, { data: usersData, isFetching, isSuccess, isLoading, isError, error }] = useLazyGetAllUsersForWantedDeviceBeaconBroadcastQuery();

  const [addBeaconBroadcast] = useAddBeaconBroadcastMutation();


  useEffect(() => {
    if (currentPostId) {
      triggerGetRelatedUser({ offSet, search: searchTerm, limit, id: currentPostId },
        {
          // Refetch when component mounts or tab becomes active
          refetchOnMountOrArgChange: true
        });
    }
  }, [currentPostId]);


  useEffect(() => {
    if (currentPostId) {
      triggerGetRelatedUser({ offSet, search: searchTerm, limit, id: currentPostId },
        {
          // Refetch when component mounts or tab becomes active
          refetchOnMountOrArgChange: true
        });
    }
  }, [offSet, searchTerm, limit]);


  useEffect(() => {
    console.log(usersData);
  }, [usersData])


  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleReset = (event) => {
    formikRef.current.resetForm();
    setBackendValidation([]);
  }

  const handleUsersDataLoadMore = () => {
    if (usersData?.hasMore && !isFetching) {
      setOffSet(currentOffset => currentOffset + 5);
    }
  };

  const showUsersDataLoadMore = usersData?.hasMore &&
    usersData.beaconBroadcastAllUsers.length < usersData.totalCount &&
    !isFetching;


  const finalSubmit = async (formData, setSubmitting) => {
    try {
      const response = await addBeaconBroadcast(formData).unwrap();
      if (response.status) {
        console.log(response);
        setShowSuccessMessage({ message: "Users broadcasted successfully", color: "green" });
        await new Promise(resolve => setTimeout(resolve, 1500));
        window.location.reload();
        formikRef.current.resetForm();
        onClose();
      }
    }
    catch (error) {
      if (error.status === 422) {
        console.log(error);
        const decryptedData = decryptErrorData(error);
        console.log(decryptedData);
        setBackendValidation(decryptedData.errors);
      }
      else if (error.status === 401) {
        console.log(error);
        if (error.data.message) {
          setShowSuccessMessage({ message: error.data.message, color: "red" });
        } else {
          setShowSuccessMessage({ message: 'An unknown error occurred. Please try again later.', color: "red" });
        }
      } else {
        setShowSuccessMessage({ message: 'An unknown error occurred. Please try again later.', color: "red" });
      }
      setSubmitting(false);
    }
  };

  // Validation schema for campaign form
  const validationSchema = Yup.object({
    message: Yup.string()
      .required("Message is required")
      .max(180, 'Must be 180 characters or less'),
    recipientUserId: Yup.array() // Changed to array validation
      .min(1, "At least one recipient is required")
      .required("At least one recipient is required")
  });

  // If there's an error loading data
  if (error) {
    return <ErrorShowComponent
      message1={'Internal server error'}
      message2={'There is an issue while loading required data.'}
    />;
  }

  // Render the form with createPortal
  return createPortal(
    <div className="beacon-broadcast-slide-out-menu">
      <main className="beacon-broadcast-panel">
        <section className="beacon-broadcast-slide-out-menu-header-parent">
          <header className="beacon-broadcast-slide-out-menu-header">
            <div className="beacon-broadcast-content71">
              <img
                className="beacon-broadcast-featured-icon2"
                loading="lazy"
                alt=""
                src={campaign}
              />

              <div className="beacon-broadcast-text-and-supporting-text26">
                <a className="beacon-broadcast-title9">Create Broadcast</a>
                <div className="beacon-broadcast-supporting-text91">
                  Broadcast to related listing users
                </div>
              </div>
            </div>
            <div className="beacon-broadcast-button-close-x6" onClick={onClose}>
              <img
                className="beacon-broadcast-mail-01-icon"
                loading="lazy"
                alt=""
                src={xclose}
              />
            </div>
          </header>

          <Formik
            enableReinitialize={true}
            innerRef={formikRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              const { recipientUserId, ...broadcast } = values;
              const stringRecipientUserId = recipientUserId.join(',');
              // console.log(values);
              finalSubmit({ ...broadcast, recipientUserId: stringRecipientUserId }, setSubmitting);
            }}
          >
            {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
              <Form className="account-update-profile-content-elements" style={{ width: '100%' }}>
                <div className="beacon-broadcast-searchbox-container">
                  <SearchInputListingComponent onSearch={handleSearch} />
                </div>

                <div className="beacon-broadcast-section-container">
                  <div className="beacon-broadcast-section2">
                    <div className="beacon-broadcast-contact-header">My Contacts</div>
                    <div className="beacon-broadcast-contact-container">
                      {
                        (isSuccess) && (usersData.beaconBroadcastAllUsers.map((user, index) => (
                          <div className="beacon-broadcast-contact-container-menu" key={index}>
                            <div className="beacon-broadcast-contact-main">
                              <div className="beacon-broadcast-contact-avatar-menu" >
                                <div className='beacon-broadcast-contact-avatar' style={{
                                  backgroundImage: user.profileImage
                                    && `url(${user.profileImage})`
                                }} />
                                {/* <div className='beacon-broadcast-contact-avatar-online-indicator' /> */}
                              </div>
                              <div className='beacon-broadcast-contact-name-and-work-title'>
                                <div className='beacon-broadcast-contact-name'>{user.displayName}</div>
                                <div className='beacon-broadcast-contact-work-title'>{user.user_business.businessName}</div>
                              </div>
                            </div>
                            <div className="beacon-broadcast-contact-checkbox-container">
                              <input
                                className="beacon-broadcast-contact-checkbox"
                                type="checkbox"
                                name={`recipientUser-${user.id}`}
                                checked={values.recipientUserId.includes(user.id)}
                                onChange={(e) => {
                                  const userId = user.id;
                                  if (e.target.checked) {
                                    // Add the ID to the array if it's not already there
                                    setFieldValue('recipientUserId', [...values.recipientUserId, userId]);
                                  } else {
                                    // Remove the ID from the array
                                    setFieldValue('recipientUserId',
                                      values.recipientUserId.filter(id => id !== userId)
                                    );
                                  }
                                }}
                              />
                            </div>
                          </div>
                        )))
                      }
                    </div>
                    {/* Show how many users are selected */}
                    {values.recipientUserId.length > 0 && (
                      <div className="beacon-broadcast-selected-users-count">
                        Selected users: {values.recipientUserId.length}
                      </div>
                    )}
                    {/* Error message for recipient validation */}
                    <ErrorMessage name="recipientUserId" component="div" className="error" />
                  </div>
                </div>

                <div className="beacon-broadcast-input-field-container">
                  <div className="beacon-broadcast-input-field">
                    <div className="beacon-broadcast-label12">Message</div>
                    <div className="beacon-broadcast-input6">
                      <Field
                        as="textarea"
                        className="beacon-broadcast-content72"
                        placeholder="Max 300 characters"
                        type="text"
                        name="message"
                        maxLength="300"
                      />
                    </div>
                    <ErrorMessage name="message" component="div" className="error" />
                  </div>
                </div>

                {showSuccessMessage.message && (
                  <div className="backend-validation-errors">
                    <div
                      style={{ color: showSuccessMessage.color, textAlign: 'center', marginTop: '10px' }}
                    >
                      {showSuccessMessage.message}
                    </div>

                  </div>
                )}

                {backendValidation.length > 0 && (
                  <div className="backend-validation-errors">
                    {backendValidation.map((error, index) => (
                      <div key={index} style={{ color: 'red', textAlign: 'center', marginTop: '5px' }}>
                        {error.msg}
                      </div>
                    ))}
                  </div>
                )}

                <div className="beacon-broadcast-divider-wrapper">
                  <div className="beacon-broadcast-divider12"></div>
                </div>

                <footer className="beacon-broadcast-footer2">
                  <div className="beacon-broadcast-content76">
                    <div className="beacon-broadcast-actions19">
                      <button
                        type="button"
                        className="beacon-broadcast-buttonsbutton49"
                        onClick={handleReset}
                      >
                        <div className="beacon-broadcast-text-padding51">
                          <div className="beacon-broadcast-text275">Reset</div>
                        </div>
                      </button>
                      <button
                        type="submit"
                        className="beacon-broadcast-buttonsbutton50"
                        onClick={handleSubmit} disabled={isSubmitting}
                      >
                        <div className="beacon-broadcast-text-padding51">
                          <div className="beacon-broadcast-text276">
                            {isSubmitting ? "Broadcasting..." : "Broadcast"}
                          </div>
                        </div>
                      </button>
                    </div>
                  </div>
                </footer>
              </Form>
            )}
          </Formik>
        </section>
      </main>
    </div>,
    document.body
  );
};


// export const BeaconBroadcastSlideOut = ({ onClose, currentPostId, postTitle, postType }) => {
//   const [initialValues, setInitialValues] = useState({
//     postId: currentPostId,
//     postType: postType ? postType : '',
//     title: postTitle,
//     recipientUserId: '',
//     message: '',
//   });
//   const [offSet, setOffSet] = useState(0);
//   const [limit, setLimit] = useState(10);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [backendValidation, setBackendValidation] = useState([]);
//   const formikRef = useRef(null);
//   const [showSuccessMessage, setShowSuccessMessage] = useState({
//     message: "",
//     color: "",
//   });

//   const [triggerGetRelatedUser, { data: usersData, isFetching, isSuccess, isLoading, isError, error }] = useLazyGetAllUsersForWantedDeviceBeaconBroadcastQuery();

//   useEffect(() => {
//     if (currentPostId) {
//       triggerGetRelatedUser({ offSet, search: searchTerm, limit, id: currentPostId },
//         {
//           // Refetch when component mounts or tab becomes active
//           refetchOnMountOrArgChange: true
//         });
//     }
//   }, [currentPostId]);


//   useEffect(() => {
//     if (currentPostId) {
//       triggerGetRelatedUser({ offSet, search: searchTerm, limit, id: currentPostId },
//         {
//           // Refetch when component mounts or tab becomes active
//           refetchOnMountOrArgChange: true
//         });
//     }
//   }, [offSet, searchTerm, limit]);


//   useEffect(() => {
//     console.log(usersData);

//   }, [usersData])


//   const handleSearch = (term) => {
//     setSearchTerm(term);
//   };

//   const handleReset = (event) => {
//     formikRef.current.resetForm();
//     // setPropabilityPrecentage(null);
//     // setSelectedDate(null);
//   }

//   const handleUsersDataLoadMore = () => {
//     if (usersData?.hasMore && !isFetching) {
//       setOffSet(currentOffset => currentOffset + 5);
//     }
//   };

//   const showUsersDataLoadMore = usersData?.hasMore &&
//     usersData.beaconBroadcastAllUsers.length < usersData.totalCount &&
//     !isFetching;


//   const finalSubmit = async (formData, setSubmitting) => {
//     try {
//       const response = await getRelatedUser(formData).unwrap();
//       if (response.status) {
//         console.log(response);
//         setShowSuccessMessage({ message: "Users broadcasted successfully", color: "green" });
//         await new Promise(resolve => setTimeout(resolve, 1500));
//         window.location.reload();
//         formikRef.current.resetForm();
//         onClose();
//       }
//     }
//     catch (error) {
//       if (error.status === 422) {
//         console.log(error);
//         const decryptedData = decryptErrorData(error);
//         console.log(decryptedData);
//         setBackendValidation(decryptedData.errors);
//       }
//       else if (error.status === 401) {
//         console.log(error);
//         if (error.data.message) {
//           setShowSuccessMessage({ message: error.data.message, color: "red" });
//         } else {
//           setShowSuccessMessage({ message: 'An unknown error occurred. Please try again later.', color: "red" });
//         }
//       } else {
//         console.log(error);
//       }
//       setSubmitting(false);
//     }
//   };

//   // Validation schema for campaign form
//   const validationSchema = Yup.object({
//     message: Yup.string()
//       .required("Message is required")
//       .max(300, 'Must be 300 characters or less'),
//     recipientUserId: Yup.string()
//       .required("At least one recipient is required")
//   });

//   // If there's an error loading data
//   if (error) {
//     return <ErrorShowComponent
//       message1={'Internal server error'}
//       message2={'There is an issue while loading required data.'}
//     />;
//   }

//   // Render the form with createPortal
//   return createPortal(
//     <div className="beacon-broadcast-slide-out-menu">
//       <main className="beacon-broadcast-panel">
//         <section className="beacon-broadcast-slide-out-menu-header-parent">
//           <header className="beacon-broadcast-slide-out-menu-header">
//             <div className="beacon-broadcast-content71">
//               <img
//                 className="beacon-broadcast-featured-icon2"
//                 loading="lazy"
//                 alt=""
//                 src={campaign}
//               />

//               <div className="beacon-broadcast-text-and-supporting-text26">
//                 <a className="beacon-broadcast-title9">Create Broadcast</a>
//                 <div className="beacon-broadcast-supporting-text91">
//                   Broadcast to related listing users
//                 </div>
//               </div>
//             </div>
//             <div className="beacon-broadcast-button-close-x6" onClick={onClose}>
//               <img
//                 className="beacon-broadcast-mail-01-icon"
//                 loading="lazy"
//                 alt=""
//                 src={xclose}
//               />
//             </div>
//           </header>

//           <Formik
//             enableReinitialize={true}
//             innerRef={formikRef}
//             initialValues={initialValues}
//             validationSchema={validationSchema}
//             onSubmit={(values, { setSubmitting }) => {
//               console.log(values);
//               finalSubmit(values, setSubmitting);
//             }}
//           >
//             {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
//               <Form className="account-update-profile-content-elements" style={{ width: '100%' }}>
//                 <div className="beacon-broadcast-searchbox-container">
//                   {/* <div className="beacon-broadcast-searchbox">
//                     <div className="beacon-broadcast-searchbox-input-field">
//                       <div>
//                         <img
//                           className="beacon-broadcast-searchbox-search-icon"
//                           src={searchlg}
//                           alt=""
//                         />
//                       </div>
//                     </div>
//                     <input
//                       className="beacon-broadcast-searchbox-placeholder"
//                       type='text'
//                       placeholder='Search'
//                       />
//                       </div> */}
//                   <SearchInputListingComponent onSearch={handleSearch} />
//                 </div>

//                 <div className="beacon-broadcast-section-container">
//                   <div className="beacon-broadcast-section2">
//                     <div className="beacon-broadcast-contact-header">My Contacts</div>
//                     <div className="beacon-broadcast-contact-container">
//                       {/* {(isLoading || isError) && <option value="0">Loading...</option>} */}
//                       {
//                         (isSuccess) && (usersData.beaconBroadcastAllUsers.map((user, index) => (
//                           <div className="beacon-broadcast-contact-container-menu" key={index}>
//                             <div className="beacon-broadcast-contact-main">
//                               <div className="beacon-broadcast-contact-avatar-menu" >
//                                 <div className='beacon-broadcast-contact-avatar' style={{
//                                   backgroundImage: user.profileImage
//                                     && `url(${user.profileImage})`
//                                 }} />
//                                 <div className='beacon-broadcast-contact-avatar-online-indicator' />
//                               </div>
//                               <div className='beacon-broadcast-contact-name-and-work-title'>
//                                 <div className='beacon-broadcast-contact-name'>{user.displayName}</div>
//                                 <div className='beacon-broadcast-contact-work-title'>{user.user_business.businessName}</div>
//                               </div>
//                             </div>
//                             <div className="beacon-broadcast-contact-checkbox-container">
//                               <Field
//                                 className="beacon-broadcast-contact-checkbox"
//                                 type="checkbox"
//                                 name="recipientUserId"
//                                 // value={user.displayName}// Replace with actual user ID
//                                 onChange={(e) => {
//                                   if (e.target.checked) {
//                                     setFieldValue('recipientUserId', user.id); // Set the user ID when checked
//                                   } else {
//                                     setFieldValue('recipientUserId', ''); // Clear when unchecked
//                                   }
//                                 }}
//                               />
//                             </div>
//                           </div>
//                         )))
//                       }
//                       {/* <div className="beacon-broadcast-contact-container-menu">
//                         <div className="beacon-broadcast-contact-main">
//                           <div className="beacon-broadcast-contact-avatar-menu">
//                             <div className='beacon-broadcast-contact-avatar' />
//                             <div className='beacon-broadcast-contact-avatar-online-indicator' />
//                           </div>
//                           <div className='beacon-broadcast-contact-name-and-work-title'>
//                             <div className='beacon-broadcast-contact-name'>Olivia Rhye</div>
//                             <div className='beacon-broadcast-contact-work-title'>Tutanis</div>
//                           </div>
//                         </div>
//                         <div className="beacon-broadcast-contact-checkbox-container">
//                           <Field
//                             className="beacon-broadcast-contact-checkbox"
//                             type="checkbox"
//                             name="recipientUserId"
//                             value="olivia_id" // Replace with actual user ID
//                             onChange={(e) => {
//                               if (e.target.checked) {
//                                 setFieldValue('recipientUserId', 'olivia_id'); // Set the user ID when checked
//                               } else {
//                                 setFieldValue('recipientUserId', ''); // Clear when unchecked
//                               }
//                             }}
//                           />
//                         </div>
//                       </div> */}
//                     </div>
//                   </div>
//                 </div>

//                 <div className="beacon-broadcast-input-field-container">
//                   <div className="beacon-broadcast-input-field">
//                     <div className="beacon-broadcast-label12">Message</div>
//                     <div className="beacon-broadcast-input6">
//                       <Field
//                         as="textarea"
//                         className="beacon-broadcast-content72"
//                         placeholder="Max 300 characters"
//                         type="text"
//                         name="message"
//                         maxLength="300"
//                       />
//                       <ErrorMessage name="message" component="div" className="error" />
//                     </div>
//                   </div>
//                 </div>

//                 {showSuccessMessage.message && (
//                   <div
//                     style={{ color: showSuccessMessage.color, textAlign: 'center', marginTop: '10px' }}
//                   >
//                     {showSuccessMessage.message}
//                   </div>
//                 )}

//                 {backendValidation.length > 0 && (
//                   <div className="backend-validation-errors">
//                     {backendValidation.map((error, index) => (
//                       <div key={index} style={{ color: 'red', textAlign: 'center', marginTop: '5px' }}>
//                         {error}
//                       </div>
//                     ))}
//                   </div>
//                 )}

//                 <div className="beacon-broadcast-divider-wrapper">
//                   <div className="beacon-broadcast-divider12"></div>
//                 </div>

//                 <footer className="beacon-broadcast-footer2">
//                   <div className="beacon-broadcast-content76">
//                     <div className="beacon-broadcast-actions19">
//                       <button
//                         type="button"
//                         className="beacon-broadcast-buttonsbutton49"
//                         onClick={handleReset}
//                       >
//                         <div className="beacon-broadcast-text-padding51">
//                           <div className="beacon-broadcast-text275">Reset</div>
//                         </div>
//                       </button>
//                       <button
//                         type="submit"
//                         className="beacon-broadcast-buttonsbutton50"
//                         onClick={handleSubmit} disabled={isSubmitting}
//                       >
//                         <div className="beacon-broadcast-text-padding51">
//                           <div className="beacon-broadcast-text276">
//                             {isSubmitting ? "Broadcasting..." : "Broadcast"}
//                           </div>
//                         </div>
//                       </button>
//                     </div>
//                   </div>
//                 </footer>
//               </Form>
//             )}
//           </Formik>
//         </section>
//       </main>
//     </div>,
//     document.body
//   );
// };

