import React from 'react';
import "./ThreeDotsListingComponent.css";
import divider from '../../icons/Gerator/dashboard/divider.svg';
import { Link } from 'react-router-dom';

export const ThreeDotsArchivePageComponent = ({  url }) => {
  // const handleOptionClick = (option) => {
  //   onOptionSelect(option); // Call the function from the parent with the selected option
  // };
  return (
    <div className="three-dots-filter-dropdown-three-dots-filter-all-options-fixed">
      <section className="three-dots-filter-dropdown-menu-items">
        <div className="three-dots-filter-dropdown-dropdown-list-item" >
          <div className="three-dots-filter-dropdown-content48">
            <div className="three-dots-filter-dropdown-icon-and-text">
              {/* <Link className="three-dots-filter-dropdown-text247" to={`${url}`}>View Details</Link> */}
              <a className="three-dots-filter-dropdown-text247"href={`/${url}`} target="_blank" rel="noopener noreferrer" >
                View Details
              </a>
            </div>
          </div>
        </div>
        <div className="three-dots-filter-dropdown-dropdown-list-item" >
          <div className="three-dots-filter-dropdown-content48">
            <div className="three-dots-filter-dropdown-icon-and-text">
              {/* <Link className="three-dots-filter-dropdown-text247" to={`${url}`}>Add to My Radar</Link> */}
              <a className="three-dots-filter-dropdown-text247" href={`/${url}`}  target="_blank" rel="noopener noreferrer">
               Add to My Radar
              </a>
            </div>
          </div>
        </div>
        <div className="three-dots-filter-dropdown-dropdown-list-item" >
          <div className="three-dots-filter-dropdown-content48">
            <div className="three-dots-filter-dropdown-icon-and-text">
              {/* <Link className="three-dots-filter-dropdown-text247" to={`${url}`}>Contact Seller</Link> */}
              <a className="three-dots-filter-dropdown-text247" href={`/${url}`}  target="_blank" rel="noopener noreferrer">
                Contact Seller
              </a>
            </div>
          </div>
        </div>
        <div className="three-dots-filter-dropdown-dropdown-list-item" >
          <div className="three-dots-filter-dropdown-content48">
            <div className="three-dots-filter-dropdown-icon-and-text">
              {/* <Link className="three-dots-filter-dropdown-text247" to={`${url}`}>Share</Link> */}
              <a className="three-dots-filter-dropdown-text247" href={`/${url}`}  target="_blank" rel="noopener noreferrer">
                Share
              </a>
            </div>
          </div>
        </div>
       
      </section>
    </div>
  )
}

