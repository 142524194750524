import React, { useEffect, useState, useRef } from "react";
import "./SignInPopUp.css";
import reset_nav from "../../icons/Gerator/reset_password/_Nav menu button.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ResetPasswordDropdown } from "../../components/Dropdown/Dropdown";
import { Helmet } from "react-helmet-async";
import { auth, firestore, doc, getDoc, onAuthStateChanged } from "../../screens/Login/FirebaseClient";
import { Loader } from "../../components/Loader";
import support_icon from "../../icons/Gerator/support_24dp_5F6368_FILL0_wght400_GRAD0_opsz24.svg";
import LoginForm from "../../components/LoginComponent/LoginFormComponent/LoginForm";
import gerator_logo from "../../icons/Gerator/gerator_logo/gerator-08.svg"

export const SignInPopup = ({ isOpen, onClose }) => {
  if (!isOpen) return null; // Ensure the modal only renders when open

  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const location = useLocation();
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (location.state != null) {
      setErrorMessage(location.state.message);
      location.state = {}; // Clear the state after using it
    }
  }, [location]);

  // Handle clicks outside dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  return (
    <>
      <Helmet>
        <title>Sign-in</title>
      </Helmet>

      {/* Modal Overlay */}
      <div className="sign-in-modal-overlay" onClick={onClose}>
        <div className="sign-in-modal" onClick={(e) => e.stopPropagation()}>
          {/* Close Button */}
          <button onClick={onClose} className="sign-in-modal-close-button">X</button>

          {/* Header */}
          <header className="sign-in-modal-header">
            <div className="sign-in-modal-brand-navigation">
        
                <img className="sign-in-modal-logo1" alt="Logo" src={gerator_logo} />
           
            </div>
{/* 
            <div className="sign-in-modal-navigation-actions">
              <button className="sign-in-modal-button">
                <img className="sign-in-modal-placeholder-icon" alt="Placeholder" src="/placeholder2.svg" />
              </button>
            </div> */}

            {/* Dropdown Menu */}
            <div className="sign-in-modal-hamburger-menu" ref={dropdownRef} onClick={toggleDropdown}>
              {isDropdownVisible && <ResetPasswordDropdown />}
              <img className="hamburger-icon" src={reset_nav} alt="Menu" />
            </div>
          </header>

          {/* Footer */}
          <footer className="sign-in-modal-footer">
            <LoginForm message={errorMessage} setErrorMessage={setErrorMessage} />
          </footer>
        </div>
      </div>
    </>
  );
};
