import React from 'react';
import './SkeletonLoaderDetailPageComponent.css';

const SkeletonLoaderDetailPageComponent = () => {
    return (
        <div className="container-detail-page">
            {/* Image carousel */}
            <div className="image-carousel">
                <div className="arrow-btn arrow-left skeleton"></div>
                <div className="carousel-item skeleton"></div>
                <div className="carousel-item skeleton"></div>
                <div className="carousel-item skeleton"></div>
                <div className="carousel-item skeleton"></div>
                <div className="arrow-btn arrow-right skeleton"></div>
                <div className="photos-link skeleton"></div>
            </div>

            {/* Tabs */}
            <div className="tabs">
                <div className="tab skeleton"></div>
                <div className="tab skeleton"></div>
            </div>

            {/* Product Header */}
            <div className="product-header">
                <div className="product-title skeleton"></div>
                <div className="product-meta">
                    <div className="meta-item skeleton"></div>
                    <div className="meta-item skeleton"></div>
                </div>
                <div className="actions">
                    <div className="action-btn skeleton"></div>
                    <div className="action-btn skeleton"></div>
                </div>
            </div>

            {/* Price and CTA */}
            <div className="price-container">
                <div className="price skeleton"></div>
                <div className="cta-button skeleton"></div>
            </div>

            {/* Highlights */}
            <div className="highlights">
                <div className="highlight-header skeleton"></div>
                <div className="highlight-grid">
                    <div className="highlight-item skeleton"></div>
                    <div className="highlight-item skeleton"></div>
                    <div className="highlight-item skeleton"></div>
                    <div className="highlight-item skeleton"></div>
                </div>
            </div>

            {/* Seller Info */}
            <div className="seller-info">
                <div className="seller-photo skeleton"></div>
                <div className="seller-details">
                    <div className="seller-name skeleton"></div>
                    <div className="seller-badge skeleton"></div>
                </div>
            </div>

            {/* Specs Sections */}
            <div className="section">
                <div className="section-title skeleton"></div>
                <div className="section-content skeleton"></div>
            </div>

            <div className="section">
                <div className="section-title skeleton"></div>
                <div className="section-content skeleton"></div>
            </div>

            <div className="section">
                <div className="section-title skeleton"></div>
                <div className="section-content skeleton"></div>
            </div>

            {/* Jobs Section */}
            <div className="jobs-section">
                <div className="jobs-header">
                    <div className="section-title skeleton"></div>
                    <div className="action-btn skeleton"></div>
                </div>
                <div className="job-list">
                    <div className="job-item">
                        <div className="job-icon skeleton"></div>
                        <div className="job-details skeleton"></div>
                    </div>
                    <div className="job-item">
                        <div className="job-icon skeleton"></div>
                        <div className="job-details skeleton"></div>
                    </div>
                    <div className="job-item">
                        <div className="job-icon skeleton"></div>
                        <div className="job-details skeleton"></div>
                    </div>
                </div>
            </div>

            {/* Map */}
            <div className="map-container skeleton">
                <div className="map-controls">
                    <div className="map-control skeleton"></div>
                    <div className="map-control skeleton"></div>
                </div>
            </div>
            <div className="map-attribution skeleton"></div>
        </div>
    );
};



export default SkeletonLoaderDetailPageComponent;