
var Refresh = require('react-refresh/runtime');
var ErrorOverlay = require('react-error-overlay');
window.__REACT_REFRESH_VERSION_RUNTIME = '0.11.0';

Refresh.injectIntoGlobalHook(window);
window.$RefreshReg$ = function() {};
window.$RefreshSig$ = function() {
  return function(type) {
    return type;
  };
};

ErrorOverlay.setEditorHandler(function editorHandler(errorLocation) {
  let file = `${errorLocation.fileName}:${errorLocation.lineNumber || 1}:${errorLocation.colNumber || 1}`;
  fetch(`/__parcel_launch_editor?file=${encodeURIComponent(file)}`);
});

ErrorOverlay.startReportingRuntimeErrors({
  onError: function () {},
});

window.addEventListener('parcelhmraccept', () => {
  ErrorOverlay.dismissRuntimeErrors();
});
