import React, { useEffect, useState } from "react";
import { RatingStarComponent } from "../RatingStarComponent/RatingStarComponent";
import { ReviewDescriptionComponent } from "../ReviewDescriptionComponent/ReviewDescriptionComponent";
import placeholder2 from "../../../icons/Gerator/detailed_listing/placeholder2.svg";
import login04 from "../../../icons/Gerator/detailed_listing/login04.svg";
import star_icon from "../../../icons/Gerator/detailed_listing/star-icon.svg";
import empty_star_icon_new from "../../../icons/Gerator/detailed_listing/empty_star_icon_new.svg";
import { useAddReviewDeviceMutation } from "../../../api/Review/ReviewDeviceAPI";
import { decryptErrorData } from "../../../helper/AESHelper";
import { Form, Formik, useField } from "formik";
import * as Yup from "yup";
import { objectToReviewFormData } from "../../../helper/ReviewHelper";
import { useAddReviewSoftwareMutation } from "../../../api/Review/ReviewSoftwareAPI";

const MyRating = ({ validate, label, formikRef, ratingType, ...props }) => {

  useEffect(() => {
    console.log(star_icon);


  }, [])


  // Initialize state for each rating category
  const [ratings, setRatings] = useState({
    accuracy: 0,
    relevance: 0,
    valueForMoney: 0,
  });

  // Optional: To handle hover state for better UX
  const [hoverRatings, setHoverRatings] = useState({
    accuracy: 0,
    relevance: 0,
    valueForMoney: 0,
  });

  // Handle star click to set the rating
  const handleStarClick = (category, rating) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [category]: rating,
    }));
  };

  // Handle mouse enter to set hover state
  const handleMouseEnter = (category, rating) => {
    setHoverRatings((prevHover) => ({
      ...prevHover,
      [category]: rating,
    }));
  };

  // Handle mouse leave to reset hover state
  const handleMouseLeave = (category) => {
    setHoverRatings((prevHover) => ({
      ...prevHover,
      [category]: 0,
    }));
  };

  const renderStars = (category) => {
    const currentRating = hoverRatings[category] || ratings[category];
    const stars = [];

    for (let i = 1; i <= 5; i++) {
      stars.push(
        <img
          key={i}
          className="star-icon"
          src={i <= currentRating ? star_icon : empty_star_icon_new}
          alt={i <= currentRating ? `${i} filled star` : `${i} empty star`}
          onClick={() => handleStarClick(category, i)}
          onMouseEnter={() => handleMouseEnter(category, i)}
          onMouseLeave={() => handleMouseLeave(category)}
          style={{ cursor: "pointer" }}
        />
      );
    }

    return stars;
  };

  useEffect(() => {
    switch (ratingType) {
      case "accuracy":
        formikRef.current.setFieldValue("ratingAccuracy", ratings.accuracy);
        break;
      case "relevance":
        formikRef.current.setFieldValue("ratingRelevance", ratings.relevance);
        break;
      case "valueForMoney":
        formikRef.current.setFieldValue(
          "ratingValueForMoney",
          ratings.valueForMoney
        );
        break;

      default:
        break;
    }
  }, [ratings]);

  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  let fieldValidationData = null;
  if (validate?.length > 0) {
    validate.some((field) => {
      // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  return (
    <>
      {/* Accuracy Rating */}
      <div className="rating-star-component-heading-container">
        {/* <div className="rating-star-component-heading33">Accuracy</div> */}
        <label
          className="rating-star-component-heading33"
          htmlFor={props.id || props.name}
        >
          {label} <span className="add-wanted-device-step-span">*</span>
        </label>
        <div className="rating-star-component-listing-detail-item-date-parent">
          {renderStars(ratingType)}
          {/* <input disabled hidden style={{ display: "none" }}
            className="add-wanted-device-step-content12 myinputfield"
            value={ratings.accuracy}
            {...field}
            {...props}
          /> */}
        </div>
        {ratingType === "accuracy" && (
          <div>
            {ratings.accuracy} Star{ratings.accuracy !== 1 && "s"}
          </div>
        )}
        {ratingType === "relevance" && (
          <div>
            {ratings.relevance} Star{ratings.relevance !== 1 && "s"}
          </div>
        )}
        {ratingType === "valueForMoney" && (
          <div>
            {ratings.valueForMoney} Star{ratings.valueForMoney !== 1 && "s"}
          </div>
        )}
      </div>
      {/* <div className="add-wanted-device-step-first-upload-inner">
        <div className="add-wanted-device-step-label3">
          <label className="add-wanted-device-step-transaction-type" htmlFor={props.id || props.name}>
            {label} <span className="add-wanted-device-step-span">*</span>
          </label>
        </div>
        <div className="add-wanted-device-step-input1">
          <div className="add-wanted-device-step-content8 add-wanted-device-step-content8-custom">
            <div className="add-wanted-device-step-text-input">
              <input
                className="add-wanted-device-step-content12 myinputfield"
                {...field}
                {...props}
                onChange={(e) => {
                  onChange(e); // Call parent onChange to track remaining characters
                }}

              />
            </div>
          </div>
        </div>
      </div> */}

      {meta.touched && meta.error ? (
        <div className="add-wanted-device-step-hint-text1 error-color">
          {meta.error}
        </div>
      ) : null}

      {fieldValidationData !== null ? (
        <div className="add-wanted-device-step-hint-text1 error-color">
          {fieldValidationData.msg}
        </div>
      ) : null}
    </>
  );
};

const MyTextInput = ({
  validate,
  label,
  maxTitleLength,
  onChange,
  ...props
}) => {
  const [title, setTitle] = useState(""); // State for the title
  const remainingChars = maxTitleLength - title.length;
  const warningClass = remainingChars <= 20 ? "warning" : "";

  const [field, meta] = useField(props);

  let fieldValidationData = null;
  if (validate?.length > 0) {
    validate.some((field) => {
      // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  const handleChange = (e) => {
    setTitle(e.target.value); // Update local state
    field.onChange(e); // Trigger Formik's onChange
    if (onChange) {
      onChange(e); // Call the parent's onChange if provided
    }
  };

  return (
    <>
      <div className="review-description-component-input-with-label1">
        {/* <div className="review-description-component-label2">Title</div> */}
        <label
          className="review-description-component-label2"
          htmlFor={props.id || props.name}
        >
          {label} <span className="add-wanted-device-step-span">*</span>
        </label>
        <div className="review-description-component-content6">
          <div className="review-description-component-textarea-input-field">
            <input
              className="review-description-component-input-field-title"
              {...field}
              {...props}
              value={title}
              onChange={handleChange}
            />
            <div
              className={`review-description-component-hint-text1 ${warningClass}`}
            >
              {remainingChars} characters left
            </div>
          </div>
        </div>
      </div>

      {meta.touched && meta.error ? (
        <div className="add-wanted-device-step-hint-text1 error-color">
          {meta.error}
        </div>
      ) : null}

      {fieldValidationData !== null ? (
        <div className="add-wanted-device-step-hint-text1 error-color">
          {fieldValidationData.msg}
        </div>
      ) : null}
    </>
  );
};

const MyTextArea = ({ validate, label, maxReviewLength, ...props }) => {
  const [review, setReview] = useState(""); // State for the review
  const remainingCharsReview = maxReviewLength - review.length;
  const warningClassReview = remainingCharsReview <= 100 ? "warning" : "";

  const [field, meta] = useField(props);

  let fieldValidationData = null;
  if (validate?.length > 0) {
    validate.some((field) => {
      // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  const handleChange = (e) => {
    setReview(e.target.value); // Update local state
    field.onChange(e); // Trigger Formik's onChange
    // if (onChange) {
    //   onChange(e); // Call the parent's onChange if provided
    // }
  };

  return (
    <>
      <div className="review-description-component-input-with-label1">
        {/* <div className="review-description-component-label4">Review</div> */}
        <label
          className="review-description-component-label4"
          htmlFor={props.id || props.name}
        >
          {label} <span className="add-wanted-device-step-span">*</span>
        </label>
        <div className="review-description-component-content6">
          <div className="review-description-component-textarea-input-field1">
            <textarea
              {...field}
              {...props}
              value={review}
              onChange={handleChange}
              className={"review-description-component-input-field-review"}
              placeholder="Write your review here"
            />
            <div
              className={`review-description-component-hint-text3 ${warningClassReview}`}
            >
              {remainingCharsReview} characters left
            </div>
          </div>
        </div>
      </div>

      {meta.touched && meta.error ? (
        <div className="add-wanted-device-step-hint-text1 error-color">
          {meta.error}
        </div>
      ) : null}

      {fieldValidationData !== null ? (
        <div className="add-wanted-device-step-hint-text1 error-color">
          {fieldValidationData.msg}
        </div>
      ) : null}
    </>
  );
};

const AddReviewSoftwareForm = ({ listingId, currentUsermeta, formikRef }) => {
  const [backendValidation, setBackendValidation] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState({
    message: "",
    color: "",
  });
  const [initialValues, setInitialValues] = useState({
    ratingAccuracy: 0,
    ratingRelevance: 0,
    ratingValueForMoney: 0,
    reviewTitle: "",
    reviewText: "",
  });

  //Add Review query
  const [addReview, { isLoading, isError, error }] = useAddReviewSoftwareMutation();


  const finalSubmit = async (formData, setSubmitting) => {
    try {
      const response = await addReview(formData).unwrap();
      //If form was submitted successfully then status return with true, then reseting form
      console.log(response);
      if (response.status) {
        console.log(response);

        // setShowSuccessMessage(response.message)
        // setShowSuccessMessage({
        //   message: "Review submitted successfully",
        //   color: "green",
        // });
        // await new Promise((resolve) => setTimeout(resolve, 1500));
        // Reseting form
        // dispatch(clearWantedDeviceForm());
        formikRef.current.resetForm();
        window.location.reload();
      }
      // if (!response.status) {
      //   setShowSuccessMessage({ message: response.message, color: "red" })
      // }
    } catch (error) {
      if (error.status === 422) {
        console.log(error);
        const decryptedData = decryptErrorData(error);
        console.log(decryptedData);
        setBackendValidation(decryptedData.errors);
        // setShowSuccessMessage({ message: decryptedData.message, color: "red" });
      } else {
        console.log(error);
        // const decryptedData = decryptErrorData(error.data);
        // console.log(decryptedData);
        setShowSuccessMessage({
          message: "Internal server error",
          color: "red",
        });
      }
      setSubmitting(false);
    }
  };

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={Yup.object({
          // ratingAccuracy: Yup.number()
          // .required('Rating is required') // Ensures it's not empty
          // .test(
          //   'not-zero',
          //   'Rating is required', // Custom error message
          //   (value) => value !== 0 // Validate that the value is not 0
          // ),
          // ratingRelevance: Yup.number()
          // .required('Rating is required') // Ensures it's not empty
          // .test(
          //   'not-zero',
          //   'Rating is required', // Custom error message
          //   (value) => value !== 0 // Validate that the value is not 0
          // ),
          // ratingValueForMoney: Yup.number()
          // .required('Rating is required') // Ensures it's not empty
          // .test(
          //   'not-zero',
          //   'Rating is required', // Custom error message
          //   (value) => value !== 0 // Validate that the value is not 0
          // ),
          // reviewTitle: Yup.string().required("Field is required").max(70, 'Must be 70 characters or less'),
          // reviewText: Yup.string().required("Field is required").max(1000, 'Must be 1000 characters or less'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          multiPartData = objectToReviewFormData(
            values,
            listingId,
            currentUsermeta
          );
          console.log(values);

          finalSubmit(multiPartData, setSubmitting);
          // resetForm();
        }}
      >
        {({ handleSubmit, isSubmitting }) => {
          return (
            <>
              <Form className="detailed-device-review-form-wrapper">
                <div className="detailed-device-review-form">
                  <div className="detailed-device-review-form-child"></div>
                  <div className="detailed-device-breadcrumb-container-parent">
                    <div className="detailed-device-heading32">
                      Write Review
                    </div>

                    {/* Rating Component */}
                    <div className="rating-star-component-frame-parent3">
                      {/* <div className="rating-star-component-heading-container">
              <div className="rating-star-component-heading33">Accuracy</div>
              <div className="rating-star-component-listing-detail-item-date-parent">
                {renderStars("accuracy")}
              </div>
              <div>{ratings.accuracy} Star{ratings.accuracy !== 1 && "s"}</div>
              <div className="add-wanted-device-step-hint-text1 error-color">
                Rating required
              </div>
            </div> */}

                      {/* Accuracy Rating */}
                      <MyRating
                        label="Accuracy"
                        name="ratingAccuracy"
                        type="text"
                        id="ratingAccuracy"
                        validate={backendValidation}
                        ratingType={"accuracy"}
                        formikRef={formikRef}
                      // value={formData.modelName}
                      // onChange={handleChange}
                      // remainingChars={modelNameCharCount}
                      />

                      {/* Relevance Rating */}
                      <MyRating
                        label="Relevance"
                        name="ratingRelevance" //New Code
                        id="ratingRelevance"
                        validate={backendValidation}
                        ratingType={"relevance"}
                        formikRef={formikRef}
                      // value={formData.modelName}
                      // onChange={handleChange}
                      // remainingChars={modelNameCharCount}
                      />

                      {/* Value for Money Rating */}
                      <MyRating
                        label="Value for Money"
                        name="ratingValueForMoney" //New Code
                        id="ratingValueForMoney"
                        validate={backendValidation}
                        ratingType={"valueForMoney"}
                        formikRef={formikRef}
                      // value={formData.modelName}
                      // onChange={handleChange}
                      // remainingChars={modelNameCharCount}
                      />
                    </div>

                    <div>
                      <div className="review-description-component-input-field">
                        {/* <div className="review-description-component-input-with-label1">
                          <div className="review-description-component-label2">Title</div>
                          <div className="review-description-component-content6">
                            <div className="review-description-component-textarea-input-field">
                              <input
                                type="text"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                maxLength={maxTitleLength}
                                className="review-description-component-input-field-title"
                                placeholder="Enter your title here"
                              />
                              <div className={`review-description-component-hint-text1 ${warningClass}`}>
                                {remainingChars} characters left
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="review-description-component-hint-text">This is a hint text to help the user.</div> */}
                        <MyTextInput
                          label="Title"
                          name="reviewTitle" //New Code
                          type="text"
                          placeholder="Enter your title here"
                          id="reviewTitle"
                          validate={backendValidation}
                          maxTitleLength={70}
                        />
                      </div>

                      <div className="review-description-component-input-field1">
                        {/* <div className="review-description-component-input-with-label1">
                          <div className="review-description-component-label4">Review</div>
                          <div className="review-description-component-content6">
                            <div className="review-description-component-textarea-input-field1">
                              <textarea
                                value={review}
                                onChange={(e) => setReview(e.target.value)}
                                maxLength={maxReviewLength}
                                className={"review-description-component-input-field-review"}
                                placeholder="Write your review here"
                              />
                              <div className={`review-description-component-hint-text3 ${warningClassReview}`}>
                                {remainingCharsReview} characters left
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="review-description-component-hint-text">This is a hint text to help the user.</div> */}
                        <MyTextArea
                          label="Review"
                          name="reviewText" //New Code
                          type="textarea"
                          placeholder="Write your review here"
                          id="reviewText"
                          validate={backendValidation}
                          maxReviewLength={1000}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="detailed-device-submit-button-wrapper"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                  >
                    <div className="detailed-device-button1-submit">
                      <img
                        className="detailed-device-chevron-down-icon"
                        alt=""
                        src={placeholder2}
                      />

                      <img
                        className="detailed-device-log-in-04-icon"
                        alt=""
                        src={login04}
                      />

                      <div className="detailed-device-navigation">
                        <div className="detailed-device-text40">Submit</div>
                      </div>
                      <img
                        className="detailed-device-chevron-down-icon"
                        alt=""
                        src={placeholder2}
                      />
                    </div>
                  </div>
                  {showSuccessMessage.message && (
                    <div
                      style={{
                        fontSize: "14px",
                        color: showSuccessMessage.color,
                        padding: "15px 10px",
                        border: "1px solid " + showSuccessMessage.color,
                        borderRadius: "10px",
                        margin: "0 auto",
                      }}
                    >
                      {showSuccessMessage.message}
                    </div>
                  )}
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default AddReviewSoftwareForm;

{
  /* <div className="detailed-device-frame-parent3">
    
                     
                        <div className="detailed-device-heading-container">
                          <div className="detailed-device-heading33">Accuracy</div>
                          <div className="detailed-device-listing-detail-item-date-parent">
                            <img
                              className="detailed-device-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
    
                            <img
                              className="detailed-device-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
    
                            <img
                              className="detailed-device-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
    
                            <img
                              className="detailed-device-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
    
                            <img
                              className="detailed-device-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
                          </div>
                        </div>
                        <div className="detailed-device-heading-container">
                          <div className="detailed-device-heading33">Relevance</div>
                          <div className="detailed-device-listing-detail-item-date-parent">
                            <img
                              className="detailed-device-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
    
                            <img
                              className="detailed-device-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
    
                            <img
                              className="detailed-device-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
    
                            <img
                              className="detailed-device-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
    
                            <img
                              className="detailed-device-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
                          </div>
                        </div>
                        <div className="detailed-device-heading-container">
                          <div className="detailed-device-heading35">Value for Money</div>
                          <div className="detailed-device-listing-detail-item-date-parent">
                            <img
                              className="detailed-device-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
    
                            <img
                              className="detailed-device-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
    
                            <img
                              className="detailed-device-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
    
                            <img
                              className="detailed-device-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
    
                            <img
                              className="detailed-device-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
                          </div>
                        </div>
                      </div> */
}
{
  /* <div className="detailed-device-input-field">
                        <div className="detailed-device-input-with-label1">
                          <div className="detailed-device-label2">Title</div>
                          <div className="detailed-device-content6">
                            <div className="detailed-device-textarea-input-field">
                              <div className="detailed-device-input-with-label3">
                                <div className="detailed-device-label3">Description</div>
                                <div className="detailed-device-input2">
                                  <div className="detailed-device-text38">70 characters</div>
                                </div>
                              </div>
                              <div className="detailed-device-hint-text1">10 characters left</div>
                            </div>
                          </div>
                        </div>
                        <div className="detailed-device-hint-text">This is a hint text to help user.</div>
                      </div>
                      <div className="detailed-device-input-field1">
                        <div className="detailed-device-input-with-label1">
                          <div className="detailed-device-label4">Review</div>
                          <div className="detailed-device-content6">
                            <div className="detailed-device-textarea-input-field1">
                              <div className="detailed-device-input-with-label3">
                                <div className="detailed-device-label3">Description</div>
                                <div className="detailed-device-input3">
                                  <div className="detailed-device-text39">1000 characters</div>
                                </div>
                              </div>
                              <div className="detailed-device-hint-text3">40 characters left</div>
                            </div>
                          </div>
                        </div>
                        <div className="detailed-device-hint-text">This is a hint text to help user.</div>
                      </div> */
}
