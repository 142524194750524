import React, { useState } from 'react';
import "./RatingStarComponent.css";

import star_icon from '../../../icons/Gerator/detailed_listing/star-icon.svg';
import empty_star_icon from "../../../icons/Gerator/detailed_listing/empty_star_icon.svg";

export const RatingStarComponent = () => {
  // Initialize state for each rating category
  const [ratings, setRatings] = useState({
    accuracy: 0,
    relevance: 0,
    valueForMoney: 0,
  });

  // Optional: To handle hover state for better UX
  const [hoverRatings, setHoverRatings] = useState({
    accuracy: 0,
    relevance: 0,
    valueForMoney: 0,
  });

  // Handle star click to set the rating
  const handleStarClick = (category, rating) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [category]: rating,
    }));
  };

  // Handle mouse enter to set hover state
  const handleMouseEnter = (category, rating) => {
    setHoverRatings((prevHover) => ({
      ...prevHover,
      [category]: rating,
    }));
  };

  // Handle mouse leave to reset hover state
  const handleMouseLeave = (category) => {
    setHoverRatings((prevHover) => ({
      ...prevHover,
      [category]: 0,
    }));
  };

  const renderStars = (category) => {
    const currentRating = hoverRatings[category] || ratings[category];
    const stars = [];

    for (let i = 1; i <= 5; i++) {
      stars.push(
        <img
          key={i}
          className="star-icon"
          src={i <= currentRating ? star_icon : empty_star_icon}
          alt={i <= currentRating ? `${i} filled star` : `${i} empty star`}
          onClick={() => handleStarClick(category, i)}
          onMouseEnter={() => handleMouseEnter(category, i)}
          onMouseLeave={() => handleMouseLeave(category)}
          style={{ cursor: "pointer" }}
        />
      );
    }

    return stars;
  };

  return (
    <div className="rating-star-component-frame-parent3">
      {/* Accuracy Rating */}
      <div className="rating-star-component-heading-container">
        <div className="rating-star-component-heading33">Accuracy</div>
        <div className="rating-star-component-listing-detail-item-date-parent">
          {renderStars("accuracy")}
        </div>
        <div>{ratings.accuracy} Star{ratings.accuracy !== 1 && "s"}</div>
      </div>

      {/* Relevance Rating */}
      <div className="rating-star-component-heading-container">
        <div className="rating-star-component-heading33">Relevance</div>
        <div className="rating-star-component-listing-detail-item-date-parent">
          {renderStars("relevance")}
        </div>
        <div>{ratings.relevance} Star{ratings.relevance !== 1 && "s"}</div>
      </div>

      {/* Value for Money Rating */}
      <div className="rating-star-component-heading-container">
        <div className="rating-star-component-heading35">Value for Money</div>
        <div className="rating-star-component-listing-detail-item-date-parent">
          {renderStars("valueForMoney")}
        </div>
        <div>{ratings.valueForMoney} Star{ratings.valueForMoney !== 1 && "s"}</div>
      </div>
    </div>


  )
}






