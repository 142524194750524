import React, { useState } from 'react';
import "./AddWantedSpare.css"
import { FooterAddForms } from '../../../components/Footer/FooterAddForms/FooterAddForms';
import { Navbar1 } from '../../../components/Navbar1/Navbar1';
import { Sidebar } from '../../../components/Sidebar/Sidebar';
// import { SideBarSubNormal } from '../../../components/SideBars-Sub/SideBarSubNormal/SideBarSubNormal';
import { SideBarSubSettings } from "../../../components/SideBars-Sub/SideBarSubSettings/SideBarSubSettings";

import { HeaderForms } from '../../../components/Header/HeaderForms/HeaderForms';
import { AddWantedSpareForm } from '../../../components/AddWantedSpareFormComponent/AddWantedSpareForm';
import divider from '../../../icons/Gerator/add_form/navbar_divider/divider.svg';
import divider_light from '../../../icons/Gerator/divider.svg';
import { useEffect } from 'react';
import { useRef } from 'react';
import { firestore, doc, getDoc, auth, onAuthStateChanged } from '../../Login/FirebaseClient';

import { useNavigate } from 'react-router-dom';
import { useLazyGetProfileImageLinkQuery, useLazyGetUserProfileQuery, useLazyGetUserQuery } from '../../../api/Login&RegisterAPI';
import { Loader } from '../../../components/Loader';
import { decryptData, decryptErrorData } from '../../../helper/AESHelper';
import { Provider } from "./AddWantedSpareContext";
import { useGetAgeOfDeviceTaxQuery, useGetAvailabilityTaxQuery, useGetClinicalApplicationsTaxQuery, useGetDeviceCategoryTaxQuery, useGetOemTaxQuery, useGetPhysicalLocationTaxQuery, useGetPurposeUseTaxQuery, useGetStatusConditionTaxQuery } from "../../../api/TaxonomyFormAPI";


export const AddWantedSpare = () => {

  const [triggerGetUser, { data, isSuccess, error, isLoading, isError }] = useLazyGetUserQuery();
  const [currentUser, setCurrentUser] = useState("");
  const navigate = useNavigate();
  const [currentUserMeta, setCurrentUserMeta] = useState("");
  const [profileImageLink, setProfileImageLink] = useState("");
  const [triggerNewProfileImageLink] = useLazyGetProfileImageLinkQuery();

  const [showSuccessMessage, setShowSuccessMessage] = useState({
    message: "",
    color: "",
  });


  // useEffect(() => {
  //   // const auth = getAuth();

  //   // Listen for authentication state change
  //   const unsubscribe = onAuthStateChanged(auth, (user) => {
  //     if (user) {
  //       // User is authenticated
  //       setCurrentUser(user);
  //       triggerGetUser();
  //     } else {
  //       // User is not authenticated, redirect to login
  //       navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
  //     }
  //   });

  //   return () => unsubscribe(); // Cleanup listener on component unmount
  // }, [navigate, triggerGetUser]);

  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {

        if (user) {
          // Get user to check wizardStep
          const userDocRef = doc(firestore, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const usermeta = userDocSnapshot.data(); // Default to 1 if not set

            if (usermeta.userStatus == 2) {
              navigate('/account-disabled');
              return;
            }

            if (usermeta.userType == 5) {
              navigate('/access-denied');
              return;
            }
            // await triggerGetCurrentPlan().unwrap();
            setCurrentUser(user);
            setCurrentUserMeta(usermeta);
            triggerGetUser();
            // User is authenticated
          }
        }
      } else {
        // User is not authenticated, redirect to login
        navigate('/admin/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });




    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate, triggerGetUser]);

  useEffect(() => {
    const refreshProfileImage = async () => {
      if (currentUserMeta && currentUserMeta.profileImage?.expiresAt < Date.now() / 1000) {
        // Pre-signed URL has expired, trigger a new request to the backend
        const newImageData = await triggerNewProfileImageLink().unwrap();
        // Update the profile image link directly
        setProfileImageLink(newImageData.url);
      } else if (currentUserMeta && currentUserMeta.profileImage?.url) {
        // Use the existing pre-signed URL to display the profile image
        setProfileImageLink(currentUserMeta.profileImage.url);
      }
    };

    refreshProfileImage();
  }, [currentUserMeta]);


  //Device Category helper select field
  const [newDeviceCategoryLength, setNewDeviceCategoryLength] = useState(50);
  const [deviceCategorySearchField, setDeviceCategorySearchField] = useState("");

  //Oem helper select field
  const [newOemLength, setNewOemLength] = useState(50);
  const [oemSearchField, setOemSearchField] = useState("");

  const availabilityTax = useGetAvailabilityTaxQuery();
  const clinicalApplicationsTax = useGetClinicalApplicationsTaxQuery();
  const deviceCategoryTax = useGetDeviceCategoryTaxQuery({ id: newDeviceCategoryLength, query: deviceCategorySearchField });
  const oemTax = useGetOemTaxQuery({ id: newOemLength, query: oemSearchField });
  const physicalLocationTax = useGetPhysicalLocationTaxQuery();
  const purposeUseTax = useGetPurposeUseTaxQuery();
  const statusConditionTax = useGetStatusConditionTaxQuery();
  const ageOfDeviceTax = useGetAgeOfDeviceTaxQuery()

  // Progress var when this is true means all taxonomy loaded
  const [isTaxonomySet, setIsTaxonomySet] = useState(false);

  //useState var to save tax
  const [allTaxonomy, setAllTaxonomy] = useState({ ageOfDevice: ageOfDeviceTax, statusCondition: statusConditionTax, oem: oemTax, deviceCategory: deviceCategoryTax, physicalLocation: physicalLocationTax, purposeUse: purposeUseTax, clinicalApplications: clinicalApplicationsTax, availability: availabilityTax, })

  useEffect(() => {

    const allSuccess = [ageOfDeviceTax.isSuccess, statusConditionTax.isSuccess, oemTax.isSuccess, deviceCategoryTax.isSuccess, physicalLocationTax.isSuccess, purposeUseTax.isSuccess, clinicalApplicationsTax.isSuccess, availabilityTax.isSuccess,].every(Boolean);


    if (allSuccess) {
      setAllTaxonomy({
        oem: oemTax, deviceCategory: deviceCategoryTax, physicalLocation: physicalLocationTax, purposeUse: purposeUseTax, clinicalApplications: clinicalApplicationsTax, availability: availabilityTax, statusCondition: statusConditionTax, ageOfDevice: ageOfDeviceTax
      });
    }

    setIsTaxonomySet(true);

  }, [
    oemTax.isSuccess, oemTax.data,
    deviceCategoryTax.isSuccess, deviceCategoryTax.data,
    availabilityTax.isSuccess, availabilityTax.data,
    clinicalApplicationsTax.isSuccess, clinicalApplicationsTax.data,
    purposeUseTax.isSuccess, purposeUseTax.data,
    physicalLocationTax.isSuccess, physicalLocationTax.data,
    statusConditionTax.isSuccess, statusConditionTax.data,
    ageOfDeviceTax.isSuccess, ageOfDeviceTax.data,
  ]);


  const [backendValidation, setBackendValidation] = useState([])
  // const appData = useSelector((state) => state.appDataWantedSpare);
  const formikRef = useRef(null);

  if (error) {
    console.log(error);
    if (isError) {
      const decryptedData = decryptErrorData(error.data);
      // console.log(decryptedData);
      // navigate("/sign-in", { state: decryptedData.message });
      navigate("/sign-in", { state: { message: decryptedData.message } });
    }
    else {
      navigate("/sign-in", { state: { message: "Internal server error" } });
    }
    return; // Optionally handle error differently
  }



  if (isSuccess && isTaxonomySet) {
    return (


      <div className="add-wanted-spare-forms-add-wanted-spare add-wanted-spare-forms-add-wanted-spare-custom-margin">
        <div className="add-wanted-spare-forms-add-wanted-spare-child"></div>


        <Navbar1 userData={currentUser} navigate={navigate} profileImageLink={profileImageLink} />


        <img className="divider-icon" alt="" src={divider_light} />


        <main className="add-wanted-spare-content-parent">

          <Sidebar profileImageLink={profileImageLink}><SideBarSubSettings userData={currentUser} /></Sidebar>









          <section className="add-wanted-spare-bottom-actions">
            <div className="add-wanted-spare-progress-container-parent">
              <div className="add-wanted-spare-progress-container">
                {/* <div className="add-wanted-spare-progress-steps">
              <div className="add-wanted-spare-form-parent"> */}


                {/* {renderProgressStep(step)} */}
                {/* <ProgressStep1/> */}
                {/* <ProgressStep2/> */}
                {/* <ProgressStep3/> */}

                {/* </div>
            </div> */}
                <div className="add-wanted-spare-content5">
                  <div className="add-wanted-spare-main">


                    <HeaderForms text="Add Wanted Spare " text1=" Add Wanted Spare Listing" text2="Your first step to realizing asset value." />



                    <div className="add-wanted-spare-main-child"></div>
                    <div className="add-wanted-spare-main-item"></div>
                  </div>
                  <div className="add-wanted-spare-body">
                    <Provider value={{ oemSearchField, setOemSearchField, newOemLength, setNewOemLength, deviceCategorySearchField, setDeviceCategorySearchField, newDeviceCategoryLength, setNewDeviceCategoryLength, allTaxonomy, backendValidation, setBackendValidation }}>
                      <AddWantedSpareForm formikRef={formikRef} setShowSuccessMessage={setShowSuccessMessage} />
                    </Provider>
                    {/* {renderFormStep(step,setStep)} */}
                    {/* <AddWantedSpareStep/> */}
                    {/* <AddWantedSpareStep2/> */}
                    {/* <AddWantedSpareStep3/> */}




                  </div>
                  {showSuccessMessage.message && (
                    <div
                      style={{
                        fontSize: "14px",
                        color: showSuccessMessage.color,
                        padding: "15px 10px",
                        border: "1px solid " + showSuccessMessage.color,
                        borderRadius: "10px",
                        margin: "0 auto",
                      }}
                    >
                      {showSuccessMessage.message}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </main>
        <img
          className="add-wanted-spare-divider-icon10"
          loading="lazy"
          alt=""
          src={divider_light}
        />

        <FooterAddForms />
      </div>
    )
  } else {
    return <Loader />;
  }



}

