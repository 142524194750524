import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  serviceCategory:'',
  serviceValueProposition:'',
  clinicalApplications:'',
  purposeUse:'',
  deviceCategory:'',
  physicalLocation:'',
}

const adminEditWantedServiceSlice = createSlice({
  name: "adminEditWantedService",
  initialState,
  reducers: {
    fillAdminEditWantedServiceForm(state, action) {
     return action.payload;
    },
    updateAdminEditWantedServiceField(state, action) {
      const {field, value} = action.payload
      if (value !== undefined) { 
        state[field] = value; 
      }
    },
    clearAdminEditWantedServiceForm() {
      return initialState;
    }
  },
});

export const {fillAdminEditWantedServiceForm, updateAdminEditWantedServiceField,clearAdminEditWantedServiceForm } = adminEditWantedServiceSlice.actions

export default adminEditWantedServiceSlice.reducer

