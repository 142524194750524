
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import "../ChatPageComponent.css"; // Correct relative path

import arrowright from "../../../icons/Gerator/chat_page/arrowright.svg";
import dot2 from "../../../icons/Gerator/chat_page/-dot2.svg";
import dot1 from "../../../icons/Gerator/chat_page/-dot1.svg";
import facesmile from "../../../icons/Gerator/chat_page/facesmile.svg";
import arrowleft from "../../../icons/Gerator/chat_page/arrowleft.svg";
import avatar2 from "../../../icons/Gerator/chat_page/avatar2.svg";
import dotsvertical from "../../../icons/Gerator/chat_page/dotsvertical.svg";
import divider2 from "../../../icons/Gerator/chat_page/divider2.svg";
import divider3 from "../../../icons/Gerator/chat_page/divider-3.svg";
import dotshorizontal from "../../../icons/Gerator/chat_page/dotshorizontal.svg";
import uploadcloud02 from "../../../icons/Gerator/chat_page/uploadcloud02.svg";
import videorecorder from "../../../icons/Gerator/chat_page/videorecorder.svg";
import phonecall01 from "../../../icons/Gerator/chat_page/phonecall01.svg";
import filterlines from "../../../icons/Gerator/chat_page/filterlines.svg";
import placeholder1 from "../../../icons/Gerator/chat_page/placeholder1.svg";
import placeholder2 from "../../../icons/Gerator/chat_page/placeholder2.svg";
import placeholder3 from "../../../icons/Gerator/chat_page/placeholder3.svg";
import placeholder from "../../../icons/Gerator/chat_page/placeholder.svg";
import pageIcon from "../../../icons/Gerator/chat_page/page.svg";

import searchlg from "../../../icons/Gerator/chat_page/searchlg.svg";
import { ThreeDotsChatOwnerComponent } from "../../ThreeDotsListingComponent/ThreeDotsChatOwnerComponent";
import { ReportAbuseSlideOut } from '../../../components/ReportAbuseSlideOutForm/ReportAbuseSlideOutForm';
import { createPortal } from 'react-dom';

const CardHeader = ({ listingData, isOwnerOnline }) => {

  const [isReportAbuseOpen, setIsReportAbuseOpen] = useState(false);
  const reportAbuseRef = useRef(null);


  const handleLaunchReportAbuseClick = () => {

    setIsReportAbuseOpen(true); // Open reportAbuse slide-out
  };


  const handleCloseReportAbuse = () => {
    setIsReportAbuseOpen(false); // Close reportAbuse slide-out
  };



  // Close reportAbuse and social share when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (reportAbuseRef.current && !reportAbuseRef.current.contains(event.target)) {
        setIsReportAbuseOpen(false); // Close reportAbuse if clicked outside
      }

    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const [isThreeDotUserOpen, setIsThreeDotUserOpen] = useState(false);
  const threeDotsRefUser = useRef(null);
  const [isClickBlockedUser, setIsClickBlockedUser] = useState(false);

  // Toggle function for the second three dots dropdown
  const toggleThreeDotsDropdownUser = () => {
    if (isClickBlockedUser) return; // Prevent rapid toggle

    setIsThreeDotUserOpen(prev => !prev); // Toggle the dropdown
    setIsClickBlockedUser(true);
    setTimeout(() => setIsClickBlockedUser(false), 400); // Small delay to avoid reopening
  };

  // Handle clicks outside the second three dots dropdown
  useEffect(() => {
    const handleThreeDotClickOutsideUser = (event) => {
      const isLink = event.target.closest('a');
      if (isLink) return; // Allow navigation to happen

      if (threeDotsRefUser.current && !threeDotsRefUser.current.contains(event.target)) {
        setIsThreeDotUserOpen(false); // Close the dropdown when clicking outside
      }
    };

    document.addEventListener("mousedown", handleThreeDotClickOutsideUser);
    return () => {
      document.removeEventListener("mousedown", handleThreeDotClickOutsideUser);
    };
  }, []);


  const { id, entity } = useParams();
  useEffect(() => {
    if (listingData) {

      console.log('YES DEVICE SUCCESS in CARDHEADER', listingData);

      //.log('YES CHATPAGEB', listingData.data.profileImage);
    }
  }, [listingData]);


  if (listingData) {
    return (


      <div className="chatB-card-header1">
        <div className="chatB-content7">
          <div className="chatB-avatar-and-text">
            {/* <img className="chatB-avatar-icon" loading="lazy" alt="" src={listingData?.profileImage} /> */}
            {/* {listingData?.profileImage ?

<img
  className="chatB-avatar-icon"
  loading="lazy" 
  alt=""
  src={listingData?.profileImage}
/>
:
<img
  className="chatB-avatar-icon"
  alt=""
  src={avatar2}
/>

} */}

            <img
              className="chatB-avatar-icon"
              alt=""
              src={listingData?.profileImage}
            />
            {/* {listingData?.profileImage ?

              :
              <img
                className="chatB-avatar-icon"
                alt=""
                src={avatar2}
              />

            } */}
            <div className="chatB-text-and-supporting-text8">
              <div className="chatB-text-and-badge1">
                <div className="chatB-caller-name">   {listingData?.wanted_device_user?.displayName || listingData?.wanted_spare_user?.displayName || listingData?.wanted_software_user?.displayName || listingData?.wanted_service_user?.displayName ||
                  "Unknown User"}</div>
                <div className="chatB-badge11">
                  {isOwnerOnline ?
                    <>
                      <img className="chatB-dot-icon5" alt="" src={dot2} />
                      <div className="chatB-text27">Online</div>
                    </>
                    :
                    <>
                      <img className="chatB-dot-icon5" alt="" src={dot1} />
                      <div className="chatB-text27">Offline</div>
                    </>
                  }
                </div>
              </div>
              <div className="chatB-supporting-text17">
                {listingData?.wanted_device_user?.user_usermeta?.user_work_title || listingData?.wanted_spare_user?.user_usermeta?.user_work_title || listingData?.wanted_software_user?.user_usermeta?.user_work_title || listingData?.wanted_service_user?.user_usermeta?.user_work_title} @{listingData?.wanted_device_user?.user_usermeta?.user_business_name || listingData?.wanted_spare_user?.user_usermeta?.user_business_name || listingData?.wanted_software_user?.user_usermeta?.user_business_name || listingData?.wanted_service_user?.user_usermeta?.user_business_name}
              </div>
            </div>
          </div>
          {/* <div className="chatB-actions1">
              <div className="chatB-button">
                <img className="chatB-placeholder-icon" alt="" src={placeholder} />
                <div className="chatB-text-padding">
                  <div className="chatB-text18">Tertiary</div>
                </div>
                <img className="chatB-placeholder-icon" alt="" src={placeholder} />
              </div>
              <button className="chatB-button5">
                <img className="chatB-arrow-up-icon" alt="" src={phonecall01} />
                <div className="chatB-text-padding4">
                  <div className="chatB-text29">Call</div>
                </div>
                <img className="chatB-placeholder-icon" alt="" src={placeholder1}/>
              </button>
              <button className="chatB-button6">
                <img className="chatB-placeholder-icon" alt="" src={placeholder3} />
                <img className="chatB-arrow-up-icon" alt="" src={videorecorder} />
                <div className="chatB-text-padding4">
                  <div className="chatB-call-button-label">Video</div>
                </div>
                <img className="chatB-placeholder-icon" alt="" src={placeholder3} />
              </button>

              <button className="chatB-button7"   >

                <img className="chatB-placeholder-icon" alt="" src={placeholder2}/>
                <img className="chatB-arrow-up-icon" alt="" src={uploadcloud02} />
                <div className="chatB-text-padding4">
                  <div className="chatB-text30">Update Stage</div>
                </div>
                <img className="chatB-placeholder-icon" alt="" src={placeholder2}/>
              </button>
              
            </div> */}
          <div className="chatB-text-and-supporting-text1-dot" style={{ cursor: "pointer" }} onClick={toggleThreeDotsDropdownUser} ref={threeDotsRefUser}>
            <img className="chatB-dots-vertical-icon" alt="" src={dotsvertical} />
            {isThreeDotUserOpen && <ThreeDotsChatOwnerComponent onLaunchReportAbuseClick={handleLaunchReportAbuseClick} />}

          </div>
          {isReportAbuseOpen &&
            createPortal(
              <div ref={reportAbuseRef}  >
                <ReportAbuseSlideOut onClose={handleCloseReportAbuse} />
              </div>,
              document.body
            )}
        </div>
        <img className="chatB-divider-icon5" alt="" src={divider3} />
      </div>

    );
  }
}
export { CardHeader };