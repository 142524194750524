import React, { useState } from 'react';
import "./ViewAddress.css";
// import { AddressEditForm } from '../EditAddress_copy/EditAddress';
// import divider1 from '../../icons/Gerator/profile_settings/divider1.svg';
import divider2 from '../../../icons/Gerator/profile_settings/divider2.svg';
import placeholder2 from '../../../icons/Gerator/profile_settings/placeholder2.svg';
import placeholder3 from '../../../icons/Gerator/profile_settings/placeholder3.svg';
import trash01 from '../../../icons/Gerator/profile_settings/trash_red/trash01.svg';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { firestore, doc, getDoc, auth, onAuthStateChanged } from '../../Login/FirebaseClient';
import SkeletonListingLoaderComponent from '../../../components/SkeletonListingLoaderComponent/SkeletonListingLoaderComponent';
import { NoListingFound } from '../../../components/NoListing/NoListingFound';
import { useRef } from 'react';
import { useDeleteAddressMutation, useGetUserDashboardAddressesQuery, useLazyGetAddressesQuery } from '../../../api/AddressAPI';
import { useEffect } from 'react';
import { NoAddress } from '../../../components/NoAddress/NoAddress';
import { NothingFoundMessage } from '../../../components/NothingFoundMessage/NothingFoundMessage';
import { NoAddressFoundNew } from '../../../components/NoAddressNew/NoAddressNew';
import plus from '../../../icons/Gerator/no_listing/plus.svg';
import { useGetPlansForAddressQuery } from '../../../api/PlanAPI';
import { ErrorShowComponent } from '../../../components/ErrorShowComponent/ErrorShowComponent';
import { NoAccessAddress } from '../../../components/NoAccessAddress/NoAccessAddress';

import arrowdown1 from "../../../icons/Gerator/dashboard/arrowdown1.svg"

export const ViewAddress = ({ usermeta }) => {

  //Offset to load data when click load more
  const navigate = useNavigate();
  const [offset, setOffset] = useState(0);
  // const [sortBy, setSortBy] = useState('date_desc');
  const [currentPlanAddressLimit, setCurrentPlanAddressLimit] = useState("");
  const [currentRemainingAddress, setCurrentRemainingAddress] = useState("");
  const [searchTerm, setSearchTerm] = useState('');
  const [currentUser, setCurrentUser] = useState("");
  const [totalCount, setTotalCount] = useState("");
  // const [totalCountWantedSpare, settotalCountWantedSpare] = useState("");
  // const [totalCountWantedService, settotalCountWantedService] = useState("");
  // const [totalCountWantedSoftware, settotalCountWantedSoftware] = useState("");
  // const [activePostStatus, setActivePostStatusFilter] = useState(null);
  // Query to get data
  const [currentUserMeta, setCurrentUserMeta] = useState("");
  // const [triggerGetPlanAddress, { data: planAddressData, isSuccess: isPlanAddressSuccess, error: planAddressError, isLoading: isPlanAddressLoading, isError: isPlanAddressError }] = useGetPlansForAddressQuery();
  const { data: planAddressData, isSuccess: isPlanAddressSuccess, error: planAddressError, isLoading: isPlanAddressLoading, isError: isPlanAddressError } = useGetPlansForAddressQuery();
  const { data, isSuccess, isError, refetch, isLoading, isFetching } = useGetUserDashboardAddressesQuery(
    { offset, search: searchTerm },
    {
      // Refetch when component mounts or tab becomes active
      refetchOnMountOrArgChange: true
    }
  );

  // Delete query for address
  const [deleteAddress] = useDeleteAddressMutation();

  //flag var to show listing when loading complete when sekelteon layout is going to stop
  const [loadingComplete, setLoadingComplete] = useState(false);

  //Var is reponsible to show listings and other thing as well
  let content;

  //Sort by text show on button
  // const [selectedSortOption, setSelectedSortOption] = useState('');

  //For SortBy Dropdown
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  //For Threedots in listing dropdown
  const [activeThreeDotId, setActiveThreeDotId] = useState(null);
  const threeDotsRef = useRef(null);
  const [isClickBlocked, setIsClickBlocked] = useState(false);

  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {

        if (user) {
          // Get user to check wizardStep
          const userDocRef = doc(firestore, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);
          setCurrentUser(user);

          if (userDocSnapshot.exists()) {
            const usermeta = userDocSnapshot.data(); // Default to 1 if not set

            if (usermeta.userStatus == 2) {
              navigate('/account-disabled');
              return;
            }

            if (usermeta.userType == 5) {
              navigate('/access-denied');
              return;
            }

            if (usermeta.wizardStep !== 0) {
              navigate('/account-setup-check-email');
              return;
            }

            // await triggerGetCurrentPlan().unwrap();
            setCurrentUserMeta(usermeta);
            // triggerGetBusiness();
            // User is authenticated
          } else {
            content = <ErrorShowComponent message1={"Internal server error"} />
          }
        }
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });


    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);

  useEffect(() => {
    if (planAddressData && isPlanAddressSuccess && currentUserMeta) {
      console.log(planAddressData);
      const foundObject = planAddressData.data.find(item => item.id === currentUserMeta.userPlan);
      setCurrentPlanAddressLimit(foundObject.planAllowedAddress)
    }

  }, [isPlanAddressSuccess, planAddressData, currentUserMeta]);


  useEffect(() => {
    if (!data || !currentPlanAddressLimit) {
      return;
    }
    const currentAddressAdded = currentPlanAddressLimit - data.addresses.length
    setCurrentRemainingAddress(currentAddressAdded)

  }, [data, currentPlanAddressLimit]);




  const handleDelete = async (id) => {
    try {

      const response = await deleteAddress(id).unwrap();

      if (response.status) {
        // setShowSuccessMessage(response.message)
        // await new Promise(resolve => setTimeout(resolve, 1500));
        // Reseting form
        window.location.reload();
      }
    } catch (error) {
      console.error(`Failed to delete Address `, error);
    }
  };



  // const toggleDropdown = () => {
  //   setDropdownVisible(!isDropdownVisible);
  // }

  // const toggleThreeDotsDropdown = (id) => {
  //   if (isClickBlocked) return; // Prevent rapid toggle

  //   if (activeThreeDotId === id) {
  //     setActiveThreeDotId(null); // Close if the same one is clicked again
  //     setIsClickBlocked(true);
  //     setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
  //   } else {
  //     setActiveThreeDotId(id); // Open the clicked one
  //   }
  // };

  // const handleSearch = (term) => {
  //   setSearchTerm(term);
  // };


  //Set value for sortby selected option on button text
  // const handleOptionSelect = (option) => {
  //   if (option.value !== sortBy) {
  //     // setSortBy(option.value);
  //     setOffset(0); // Reset offset when sort option changes
  //   }
  //   setSelectedSortOption(option);
  //   setDropdownVisible(false);
  // };

  //DashbaordFilterButtonGroup component to get data
  // const handleFilterClick = (filterLabel) => {
  //   if (activePostStatus === filterLabel) {
  //     setSortBy('date_desc')
  //     setSelectedSortOption('')
  //     setActivePostStatusFilter(null);
  //   } else {
  //     setSortBy('date_desc')
  //     setSelectedSortOption('')
  //     setActivePostStatusFilter(filterLabel);
  //   }
  //   setOffset(0); // Reset offset when changing filters
  // };

  //Setting offset for loadmore when user clicked on load more button(this trigger query to fetch new data)
  // Reset offset when component mounts or remounts
  // useEffect(() => {
  //   setOffset(0);
  // }, []);
  // // Reset offset when sort option changes
  // useEffect(() => {
  //   setOffset(0);
  // }, [sortBy]);

  const handleLoadMore = () => {
    if (data?.hasMore && !isFetching) {
      setOffset(currentOffset => currentOffset + 5);
    }
  };

  const showLoadMore = data?.hasMore &&
    data.addresses.length < data.totalCount &&
    !isFetching;




  //Onclick listener for sortby dropdown
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //       setDropdownVisible(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);


  //Onclick listener for threedots dropdown
  // useEffect(() => {
  //   const handleThreeDotClickOutside = (event) => {
  //     if (threeDotsRef.current && !threeDotsRef.current.contains(event.target)) {
  //       setActiveThreeDotId(null); // Close any open dropdown when clicking outside
  //     }
  //   };

  //   document.addEventListener("mousedown", handleThreeDotClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleThreeDotClickOutside);
  //   };
  // }, []);

  // Listen for tab visibility changes
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refetch();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [refetch]);


  //Success true then pause to show sekelton layout
  useEffect(() => {
    if (isSuccess) {
      // Set totalcount in listing counter layout
      setTotalCount(data.totalCount);
      // settotalCountExpired(data.totalCountExpired);
      // Set timeout to delay the loading completion
      const timer = setTimeout(() => {
        setLoadingComplete(true);
      }, 1000); // 1 second

      // Cleanup timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);




  if (isError) {
    content = <NoListingFound path={"/add-device"} />
  }

  if (isLoading) {
    content = <SkeletonListingLoaderComponent />;
  } else if (loadingComplete && isSuccess) {

    if (data.addresses.length > 0) {
      // dataLength = data.addresses.length;
      content = data.addresses.map((address, index) =>
      (

        <div className="address-display-CheckboxGroupItem" key={index}>
          <div className="address-display-Content address-display-Content-width" >
            {/* <div className="address-display-Input" > */}
            {/* <input type="checkbox" className="address-display-CheckboxBase" style={{ accentColor: '#7F56D9' }} /> */}
            {/* </div> */}
            <div className="address-display-TextAndSupportingText" >
              <div className="address-display-TextAndSubtext" >
                <div className="address-display-Text-1"  >
                  {address.addressTitle}
                  </div>
                <div className="address-display-Subtext" ></div>
              </div>
              <div className="address-display-SupportingText address-display-text-overlflow"  >
                {address.fullAddress}
                </div>
              <br />
              <div className="address-display-Actions" >
                <div className="address-display-ButtonsButton" style={{ cursor: "pointer" }}  >
                  <div className="address-display-Text-2" >Set as default</div>
                </div>
                {
                  (address.tax_address_type.slug === 'address' && <a href={`/dashboard/settings/address/edit-address/${address.id}`} className="address-display-ButtonsButton" style={{ cursor: "pointer" }} >
                    <div className="address-display-Text-3" >Edit</div>
                  </a>)
                }
                {/* <div className="address-display-ButtonsButton" style={{ cursor: "pointer" }} >
                  <div className="address-display-Text-3" >Edit</div>
                </div> */}
              </div>
            </div>
          </div>
          {!address.isAddressUsed &&
            <div className="address-display-buttonsbutton-wrapper address-display-buttonsbutton-wrapper-width" onClick={() => handleDelete(address.id)}>
              <div className="address-display-buttonsbutton19">
                <img
                  className="address-display-arrow-narrow-left-icon"
                  alt=""
                  src={trash01}
                />
                <div className="address-display-search-input">
                  <div className="address-display-text107">Remove </div>
                </div>
              </div>
            </div>
          }

        </div>
      ))
    }
    else {
      content = (

        // <NothingFoundMessage text="Your company doesn't have an address yet ?!" text1="Let's start the process ?" text2="You need to create an address for your business,or be related one." />
        <NoAddressFoundNew path={'/dashboard/settings/address/add-address'} pathName={"Address"} />

      )
    }

  } else if (!loadingComplete && isSuccess) {
    content = <SkeletonListingLoaderComponent />; // Show loader until loadingComplete is true
  }


  if (currentRemainingAddress !== "") {
    return (
      <>
        <Helmet>
          <title>Settings - Address</title>
        </Helmet>
        <div className="address-display-form-content-wrapper">
          <div className="address-display-form-content">
            <div className="address-display-toggle-header-parent">
              <div className="address-display-toggle-header">
                <div className="address-display-toggle-title">Addresses</div>

                <div className="address-display-supporting-text2">
                  Location is your credibility multiplier
                </div>


              </div>
              <div className="address-display-toggle-controls">
                {/* <div className="address-display-rectangle-group">
                  <div className="address-display-frame-item"></div>
                  <div className="address-display-toggle-base">
                    <div className="address-display-button6"></div>
                  </div>
                  <div className="address-display-switch-label">Explorer mode</div>
                  <div className="address-display-supporting-text3">
                    Save my login details for next time.
                  </div>
                </div> */}
                <div className="address-display-toggle-supporting-text">
                  {
                    currentRemainingAddress === 0
                      ?
                      <>
                        <Link to={`/dashboard/settings/plan`} className="address-display-button8">
                          {/* <img
                            className="address-display-plus-icon1"
                            alt=""
                            src={plus}
                          /> */}

                          <div className="address-display-text49"> Upgrade</div>
                          <img
                            className="address-display-placeholder-icon"
                            alt=""
                            src={placeholder3}
                          />
                        </Link>

                        <div className="address-display-supporting-text4">
                          To add more addresses
                        </div>
                      </>

                      :
                      <>
                        <Link to={`/dashboard/settings/address/add-address`} className="address-display-button8">
                          <img
                            className="address-display-plus-icon1"
                            alt=""
                            src={plus}
                          />

                          <div className="address-display-text49"> Address</div>
                          <img
                            className="address-display-placeholder-icon"
                            alt=""
                            src={placeholder3}
                          />
                        </Link>

                        <div className="address-display-supporting-text4">
                          {currentRemainingAddress} more available
                        </div>
                      </>
                  }

                </div>


              </div>
            </div>

            <div className="address-display-divider-container">
              <img
                className="address-display-divider-icon3"
                alt=""
                src={divider2}
              />
            </div>

            {/* Listing Container */}
            <div className="address-display-component-listing-container">

              {/* Listing layout start    */}
              {content}



            </div>


            {/* Load more button  */}
            <div className="address-display-frame-wrapper44">
              <div className="address-display-frame-parent72">
                <div className="address-display-buttonsbutton-wrapper1">
                  {showLoadMore && (
                    <button className='address-display-buttonsbutton-wrapper1-child'
                      onClick={handleLoadMore}
                      disabled={isFetching}
                    >
                      <div className="address-display-buttonsbutton28">
                        <img
                          className="address-display-arrow-narrow-left-icon"
                          alt=""
                          src={arrowdown1}
                        />
                        <div className="address-display-search-input">
                          <div className="address-display-text146">{isFetching ? 'Loading...' : 'Load More'}</div>
                        </div>
                      </div>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div >
      </>

    )
  }


}