import React from "react";
import "./NavbarNotLoggedIn.css";
import gerator_logo from '../../icons/Gerator/gerator04-1@2x.png';
import bar_chart from '../../icons/Gerator/dashboard/barchart01.svg';
import dot_svg from '../../icons/Gerator/dashboard/-dot.svg';
import chevrondown from '../../icons/Gerator/dashboard/chevrondown.svg';
import dot_svg from '../../icons/Gerator/dashboard/-dot.svg';
import searchlg from '../../icons/Gerator/dashboard/searchlg.svg';
import dashboard_icon from '../../icons/Gerator/dashboard/icon.svg';
import group20 from '../../icons/Gerator/dashboard/group-20.svg';
import { getAuth, signOut } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import Cookies from 'js-cookie';
import { useGetUserQuery, useLazyGetProfileImageLinkQuery } from "../../api/Login&RegisterAPI";
import { logout } from "../../helper/UserHelper";
// import { UserMenuComponent } from "../UserMenu/UserMenu";
import { useRef } from "react";
// import HeaderNavDropdown from "../HeaderNavDropdown/HeaderNavDropdown";
import divider3 from "../../icons/Gerator/profile_settings/divider3.svg";
import { UserMenuNotLoggedIn } from "../UserMenuNotLoggedIn/UserMenuNotLoggedIn";
import HeaderNavNotLoggedInDropdown from "../HeaderNavNotLoggedInDropdown/HeaderNavNotLoggedInDropdown";

export const NavbarNotLoggedIn = ({  }) => {
  // const navigate = useNavigate();





  // const [profileImageLink, setProfileImageLink] = useState("");
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isNewDropdownVisible, setNewDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const newdropdownRef = useRef(null);


 




  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  }

  const toggleNewDropdown = () => {
    setNewDropdownVisible(!isNewDropdownVisible);

  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (dropdownRef.current && !dropdownRef.current.contains(event.target))
      ) {
        setDropdownVisible(false);

      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (

        (newdropdownRef.current && !newdropdownRef.current.contains(event.target))
      ) {

        setNewDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


//   useEffect(() => {
//     console.log(userData);
//   }, [userData])


  return (
    <>
    <div className="navbar-not-logged-in-rectangle-parent">
      <div className="navbar-not-logged-in-frame-child"></div>
      <div className="navbar-not-logged-in-top-header">
           <Link to={"/sign-in"} style={{cursor:"pointer"}}>
        <div className="navbar-not-logged-in-branding">
          <div className="navbar-not-logged-in-logo" >
            <img
              className="navbar-not-logged-in-gerator-04-1-icon"
              alt=""
              src={gerator_logo}
            />
          </div>
        </div>
        </Link>
        <div className="navbar-not-logged-in-top-header-right">
          <div className="navbar-not-logged-in-search-input">
            <div className="navbar-not-logged-in-navigation">
              <div className="navbar-not-logged-in-nav-item-base" ref={newdropdownRef} >
                <div className="navbar-not-logged-in-content">
                  <img className="navbar-not-logged-in-dot-icon" alt="" src={dot_svg} />

                  <img
                    className="navbar-not-logged-in-bar-chart-01-icon"
                    alt=""
                    src={bar_chart}
                  />


                </div>
               

                <div className="navbar-not-logged-in-text" onClick={toggleNewDropdown} style={{ cursor: 'pointer' }} >Buy</div>
                <img
                  className="navbar-not-logged-in-search-lg-icon"
                  alt=""
                  src={chevrondown}
                  onClick={toggleNewDropdown}
                  style={{ cursor: 'pointer' }}


                />

                {isNewDropdownVisible && (
                  <div className="new-dropdown-wrapper" >
                    <HeaderNavNotLoggedInDropdown />
                  </div>
                )}
              </div>
              <div className="navbar-not-logged-in-nav-item-base" style={{ cursor: "pointer" }}>
                <div className="navbar-not-logged-in-content">
                  <img className="navbar-not-logged-in-dot-icon" alt="" src="/dashboard/-dot.svg" />

                  <img
                    className="navbar-not-logged-in-bar-chart-01-icon"
                    alt=""
                    src={bar_chart}
                  />

                  <div className="navbar-not-logged-in-text2">Sell</div>
                </div>
                {/* <div className="navbar-not-logged-in-badge1">
                  <div className="navbar-not-logged-in-text1">10</div>
                </div>
                <img
                  className="navbar-not-logged-in-chevron-down-icon"
                  alt=""
                  src={chevrondown}
                /> */}
              </div>

              <Link to={"/jobs-not-logged-in"} style={{ color: "#475467" }}>
                <div className="navbar-not-logged-in-nav-item-base" style={{ cursor: "pointer" }}>
                  <div className="navbar-not-logged-in-content">
                    <img className="navbar-not-logged-in-dot-icon" alt="" src="/dashboard/-dot.svg" />

                    <img
                      className="navbar-not-logged-in-bar-chart-01-icon"
                      alt=""
                      src={bar_chart}
                    />

                    <div className="navbar-not-logged-in-text4">Jobs</div>
                  </div>
                  {/* <div className="navbar-not-logged-in-badge">
                  <div className="navbar-not-logged-in-text1">10</div>
                </div>
                <img
                  className="navbar-not-logged-in-chevron-down-icon"
                  alt=""
                  src={chevrondown}
                /> */}
                </div>
              </Link>
            </div>
          </div>
          <div className="navbar-not-logged-in-input-with-label">

            <div className="navbar-not-logged-in-label">Search</div>
            <div className="navbar-not-logged-in-input">
              <div className="navbar-not-logged-in-content3">
                <img
                  className="navbar-not-logged-in-search-lg-icon"
                  alt=""
                  src={searchlg}
                />

                <div className="navbar-not-logged-in-search-placeholder">Search</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="navbar-not-logged-in-search-input" >
        <div className="navbar-not-logged-in-user-actions">
          <div className="navbar-not-logged-in-search-input">
            <div className="navbar-not-logged-in-nav-item-button" style={{ cursor: "pointer" }}>
              <img className="navbar-not-logged-in-icon" alt="" src={dashboard_icon} />
            </div>
          </div>
          <div className="navbar-not-logged-in-location-column-inner">
            <div className="navbar-not-logged-in-nav-item-button" style={{ cursor: "pointer" }}>
              <img
                className="navbar-not-logged-in-nav-item-button-child"
                alt=""
                src={group20}
              />
            </div>
          </div>
          <div ref={dropdownRef} className="navbar-not-logged-in-avatar" style={{cursor:"pointer"}} onClick={toggleDropdown}>
            {isDropdownVisible && <UserMenuNotLoggedIn />}
            <div className="navbar-not-logged-in-contrast-border"></div>
          </div>
          {/* <div className="navbar-not-logged-in-avatar  dropbtn">
            <div className="navbar-not-logged-in-contrast-border"></div>
            <div className="dropdown-content">
              <a href="#">{userData?.displayName || 'Guest'}</a>
              <Link to={"/user-profile"}><span>Profile</span></Link>
              <a href="#" onClick={logout}>Logout</a>
            </div>
          </div> */}
        </div>
      </div>

    
    </div>
      
      {/* <section className="setting-divider-wrapper">
              <img
                className="setting-divider-icon"
                loading="lazy"
                alt=""
                src={divider3}
              />
            </section> */}
            </>
  )
}