import React from 'react';
import "./ThreeDotsDetailPageComponent.css";
import divider from '../../icons/Gerator/dashboard/divider.svg';
import { Link,useNavigate } from 'react-router-dom';
import { useSetArchiveDeviceMutation } from '../../api/DeviceAPI';
import { useSetArchiveServiceMutation } from '../../api/ServiceAPI';
import { useSetArchiveSoftwareMutation } from '../../api/SoftwareAPI';
import { useSetArchiveSpareMutation } from '../../api/SpareAPI';
import { useSetArchiveJobMutation } from '../../api/JobAPI';

export const ThreeDotsDetailPageComponent = ({ url, urlDetail, option, postId, onClose, postType, totalActiveEngagement}) => {
  // const handleOptionClick = (option) => {
  //   onOptionSele
  
   const navigate = useNavigate();
  
    const [setToDeviceArchive] = useSetArchiveDeviceMutation();
  

  
    
    const [setToSpareArchive] = useSetArchiveSpareMutation();
  

  
    
    const [setToServiceArchive] = useSetArchiveServiceMutation();
  
   
  
    
    const [setToSoftwareArchive] = useSetArchiveSoftwareMutation();
  

  
    
    const [setToJobArchive] = useSetArchiveJobMutation();
  
    const handleEdit = async (e) => {
      e.preventDefault();
      e.stopPropagation();
  
      try {
  
        navigate(`/edit-${url}`);
      } catch (error) {
        console.error('Error navigating ' + error);
      }
    };
  
    const handleSetArchive = async (e) => {
      let response;
      e.preventDefault();
      e.stopPropagation();
  
      try {
        if (!postId) {
          console.error('No post ID provided');
          return;
        }
  
        switch (postType) {
          case 'device':
            response = await setToDeviceArchive({ id: postId }).unwrap();
            break;
          case 'service':
            response = await setToServiceArchive({ id: postId }).unwrap();
            break;
          case 'software':
            response = await setToSoftwareArchive({ id: postId }).unwrap();
            break;
          case 'spare':
            response = await setToSpareArchive({ id: postId }).unwrap();
            break;
          case 'job':
            response = await setToJobArchive({ id: postId }).unwrap();
            break;
          default:
            break;
        }
  
        // console.log('Updating status for post:', postId);
        // const response = await setToWantedDeviceArchive({ id: postId }).unwrap();
        // console.log('Update response:', response);
  
        if (response?.status) {
          onClose();
          window.location.reload();
        }
      } catch (error) {
        console.error('Error updating status:', error);
      }
    };

      const handleItemClick = (e, action) => {
        e.preventDefault();
        e.stopPropagation();
        console.log('Action clicked:', action);
    
        switch (action) {
          case 'edit':
            handleEdit(e);
            break;
          case 'publish':
            handleSetPublish(e);
            break;
          case 'archive':
            handleSetArchive(e);
            break;
          // Add other cases as needed
          default:
          // handleRejectUpdate(e);
        }
      };
    
    
    
      // Reusable menu item component with proper event handling
      const MenuItem = ({ onClick, text, to, action }) => (
        <div
          className="three-dots-detail-page-filter-dropdown-dropdown-list-item"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (onClick) onClick(e);
            if (action) handleItemClick(e, action);
          }}
        >
          <div className="three-dots-detail-page-filter-dropdown-content48">
            <div className="three-dots-detail-page-filter-dropdown-icon-and-text">
              {to ? (
                <Link
                  className="three-dots-detail-page-filter-dropdown-text247"
                  to={to}
                  onClick={(e) => e.stopPropagation()}
                >
                  {text}
                </Link>
              ) : (
                <div className="three-dots-detail-page-filter-dropdown-text247">{text}</div>
              )}
            </div>
          </div>
        </div>
      );
  return (
    // <div className="three-dots-detail-page-filter-dropdown-three-dots-detail-page-filter-all-options-fixed">
    //   <section className="three-dots-detail-page-filter-dropdown-menu-items">
    //     <div className="three-dots-detail-page-filter-dropdown-dropdown-list-item" >
    //       <div className="three-dots-detail-page-filter-dropdown-content48">
    //         <div className="three-dots-detail-page-filter-dropdown-icon-and-text">
    //           {/* <Link className="three-dots-detail-page-filter-dropdown-text247" to={`${url}`}>View Engagement</Link> */}
    //           <a className="three-dots-detail-page-filter-dropdown-text247" href={`/${urlDetail}`} target="_blank" rel="noopener noreferrer" >
    //             View Details
    //           </a>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="three-dots-detail-page-filter-dropdown-dropdown-list-item" >
    //       <div className="three-dots-detail-page-filter-dropdown-content48">
    //         <div className="three-dots-detail-page-filter-dropdown-icon-and-text">
    //           <Link className="three-dots-detail-page-filter-dropdown-text247" to={`/edit-${url}`} >Edit</Link>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="three-dots-detail-page-filter-dropdown-dropdown-list-item" >
    //       <div className="three-dots-detail-page-filter-dropdown-content48">
    //         <div className="three-dots-detail-page-filter-dropdown-icon-and-text">
    //           <Link className="three-dots-detail-page-filter-dropdown-text247" to={`${url}`}>Archive</Link>
    //         </div>
    //       </div>
    //     </div>
    //     {/* <div className="three-dots-detail-page-filter-dropdown-dropdown-list-item" >
    //       <div className="three-dots-detail-page-filter-dropdown-content48">
    //         <div className="three-dots-detail-page-filter-dropdown-icon-and-text">
    //           <div className="three-dots-detail-page-filter-dropdown-text247">Price (high to low)</div>
    //         </div>
    //       </div>
    //     </div> */}
    //   </section>
    // </div>
    <div className="three-dots-filter-dropdown-three-dots-filter-all-options-fixed">
    <section className="three-dots-filter-dropdown-menu-items">

    <div className="three-dots-detail-page-filter-dropdown-dropdown-list-item">
          <div className="three-dots-detail-page-filter-dropdown-content48">
            <div className="three-dots-detail-page-filter-dropdown-icon-and-text">
              <a className="three-dots-detail-page-filter-dropdown-text247" href={`/${urlDetail}`} target="_blank" rel="noopener noreferrer">
                View Details
              </a>
            </div>
          </div>
        </div>

      {option === 'published' && (
        <>
          {totalActiveEngagement === 0 ?
            <>
              <MenuItem text="Edit" action="edit" />
              <MenuItem text="Archive" action="archive" />
            </> :
            <MenuItem text="No action found" />
          }
        </>
      )}

    {option === 'archived' && (
        <>
          {totalActiveEngagement === 0 ?
            <>
              <MenuItem text="Edit" action="edit" />
              
            </> :
            <MenuItem text="No action found" />
          }
        </>
      )}
      

        {option === 'pending' && (
              
                <MenuItem text="No action found" />
                
            )}
     
    </section>
  </div>
  )
}

