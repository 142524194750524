import React, { useEffect, useState } from 'react';
import "./SideBarSubProgressStep.css"
import { Link } from 'react-router-dom';
import arrownarrowleft from '../../../icons/Gerator/add_form/arrownarrowleft.svg';
import logout01 from '../../../icons/Gerator/dashboard/logout01.svg';
import step_icon_base from '../../../icons/Gerator/add_form/-step-icon-base.svg';
import step_icon_base1 from '../../../icons/Gerator/add_form/-step-icon-base1.svg';
import step_icon_base_1 from '../../../icons/Gerator/add_form/-step-icon-base-1.svg';
import TruncatedTextTooltip from "../../TruncatedTextTooltip/TruncatedTextTooltip";


export const SideBarSubProgressStep2 = ({ userData }) => {
  console.log(userData);

  const [user, setUser] = useState("")


  useEffect(() => {
    if (!userData) {
      setUser({ displayName: "Demo", email: "demo@gerator.com" })
    }
  }, [userData])

  return (
    <div className="sidebar-sub-nav">
      <div className="sidebar-content5">
        {/* <div className="sidebar-sub-nav-header">
          <div className="sidebar-sub-nav-title"><Link to={"/dashboard"}>Dashboard</Link></div>
          <img
            className="sidebar-arrow-narrow-left-icon"
            alt=""
            src={arrownarrowleft}
          />
        </div> */}
        <div className="sidebar-navigation4">
          <div className="progress-step-step-one">
            <div className="progress-step-form-header">
              <div className="progress-step-form-title">Steps</div>
              <img
                className="progress-step-arrow-narrow-left-icon"
                loading="lazy"
                alt=""
                src={arrownarrowleft}
              />
            </div>
            <div className="progress-step-steps">
              <div className="progress-step-step-base1">
                <div className="progress-step-step-icon">
                  <img
                    className="progress-step-step-icon-base1"
                    loading="lazy"
                    alt=""

                    src={step_icon_base1}
                  />

                  <div className="progress-step-text-wrapper">
                    <div className="progress-step-step-three-title">
                      Basic Information
                    </div>
                  </div>
                </div>
                <div className="progress-step-step-supporting">
                  <div className="progress-step-step-connector">
                    <div className="progress-step-connector4"></div>
                  </div>
                  <div className="progress-step-supporting-text1">
                    Enter device information - model, condition, specs,
                    & warranty info.
                  </div>
                </div>
              </div>



              <div className="progress-step-step-base1">
                <div className="progress-step-step-icon">
                  <img
                    className="progress-step-step-icon-base1"
                    loading="lazy"
                    alt=""
                    src={step_icon_base}
                  />

                  <div className="progress-step-text-wrapper">
                    <div className="progress-step-step-three-title">Highlights</div>
                  </div>
                </div>
                <div className="progress-step-step-supporting">
                  <div className="progress-step-step-connector">
                    <div className="progress-step-connector"></div>
                  </div>
                  <div className="progress-step-supporting-text1">
                    Key features - performance, usability, safety, and
                    unique advantages.
                  </div>
                </div>
              </div>



              <div className="progress-step-step-base1">
                <div className="progress-step-step-icon">
                  <img
                    className="progress-step-step-icon-base1"
                    loading="lazy"
                    alt=""
                    src={step_icon_base_1}
                  />

                  <div className="progress-step-text-wrapper">
                    <div className="progress-step-step-three-title">Description</div>
                  </div>
                </div>
                <div className="progress-step-step-supporting">
                  <div className="progress-step-step-connector">
                    <div className="progress-step-connector"></div>
                  </div>
                  <div className="progress-step-supporting-text1">
                    Device functionality, intended use, benefits, and
                    applications in detail.
                  </div>
                </div>
              </div>
              {/* <div className="progress-step-step-base1">
        <div className="progress-step-connector-wrap">
          <img
            className="progress-step-step-icon-base"
            loading="lazy"
            alt=""
            src="/add_form/-step-icon-base-1.svg"
          />

        
         
          <div className="progress-step-connector-wrapper">
            <div className="progress-step-connector"></div>
          </div>
        </div>
        <div className="progress-step-text-and-supporting-text">
          <div className="progress-step-step-three-title">Description</div>
          <div className="progress-step-supporting-text">
            Device functionality, intended use, benefits, and
            applications in detail.
          </div>
        </div>
      </div> */}
            </div>
          </div>
        </div>

      </div>
      <div className="sidebar-account">

        <Link to={"/dashboard/settings/profile"}>
          <div className="sidebar-price-parent">
            <div className="sidebar-title">
              {/* {userData?.displayName || user.displayName} */}
            <TruncatedTextTooltip 
                                  text={userData?.displayName || user.displayName}
              />
            </div>
            <div className="sidebar-supporting-text">
              {/* {userData?.email || user.email} */}
              <TruncatedTextTooltip 
                                  text= {userData?.email || user.email}
              />
              </div>
          </div>
        </Link>


        <Link to={"/dashboard/settings/profile"}>
          <div className="sidebar-buttonsbutton">
            <img
              className="sidebar-search-lg-icon"
              alt=""
              src={logout01}
            />
          </div>
        </Link>
      </div>
    </div>
  )
}


{/* <div className="progress-step-step-base1">
  <div className="progress-step-connector-wrap">
    <img
      className="progress-step-step-icon-base"
      loading="lazy"
      alt=""
      src="/add_form/-step-icon-base-1.svg"
    />

  
   
    <div className="progress-step-connector-wrapper">
      <div className="progress-step-connector"></div>
    </div>
  </div>
  <div className="progress-step-text-and-supporting-text">
    <div className="progress-step-step-three-title">Description</div>
    <div className="progress-step-supporting-text">
      Device functionality, intended use, benefits, and
      applications in detail.
    </div>
  </div>
</div> */}

