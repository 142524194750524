import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import Pusher from "pusher-js";
import { MessageBox } from "./MessageBox";

import { SidePanel } from "./SidePanel";

import { NoMessages } from "./NoMessages";
import { useLazyGetUserProfileQuery, useLazyGetSenderProfileQuery, useLazyGetContactedProfilesQuery } from "../../../api/UsermetaAPI";
import { updateChatCache, deduplicateMessages } from "../../../helper/ChatHelper";
import {
  useSaveMetadataMutation,
  useSaveConversationMutation,
  useSendMessageMutation,
  useLazyCheckChatMetadataQuery,
  useSaveEachMessageMutation,
  useLazyFetchLatestDetailedSenderProfilesQuery,
  useLazyFetchInitialMessagesForDisplayQuery,
  useLazyFetchInitialMessagesForDisplayQuery,
  useLazyFetchMessagesQuery,
  useUpdateOnlineStatusMutation,
  useFetchUnreadMessagesCountQuery,
  useMarkMessagesAsReadMutation,
  useLazyFetchUnreadMessagesCountQuery,
} from "../../../api/ChatAPI";
import { useLazyGetPostTypesQuery } from "../../../api/TaxonomyFormAPI";
import { ChatAPI } from "../../../api/ChatAPI"; // Import your ChatAPI instance


const ChatPanel = ({ currentUserId, listingData }) => {


  const { listing, id, entity } = useParams();
  const pusherInstance = useRef(null); // Persist Pusher instance
  // const [triggerGetUserProfile, { data: decryptedData, error, isLoading, isSuccess }] = useLazyGetUserProfileQuery();
  const [sendMessage, { isLoading: isSendMessageLoading, isError: isSendMessageError, isSuccess: isSendMessageSuccess }] = useSendMessageMutation();  //send message to save the data
  const [saveMetadata] = useSaveMetadataMutation(); //Save metadata id and details
  const [saveConversation] = useSaveConversationMutation(); //save conversation details
  const [messages, setMessages] = useState([]); //messages state 
  const [postTypeMap, setPostTypeMap] = useState(new Map()); // to hold post type map 
  const [triggerCheckChatMetadata, { isLoading: isGetMetadataLoading, isSuccess: isGetMetadataSuccess, data: currentMetadata }] = useLazyCheckChatMetadataQuery();
  const [saveEachMessage] = useSaveEachMessageMutation();
  const [metadataId, setMetadataId] = useState(null);
  const [page, setPage] = useState(1); // Pagination state
  const [selectedChat, setSelectedChat] = useState({ messageId: null, content: "", timestamp: null, senderId: null, recipientId: null, channelName: "", });
  const [triggerFetchLatestDetailedSenderProfiles, { data: LatestSenderProfiles, isLoading: isLatestSenderProfilesLoading, isError: isLatestSenderProfilesError }] = useLazyFetchLatestDetailedSenderProfilesQuery();
  const [triggerFetchInitialMessagesForDisplay, { data: initialMessagesForDisplay, isLoading: isInitialMessagesForDisplayLoading, isError: isInitialMessagesForDisplayError }] = useLazyFetchInitialMessagesForDisplayQuery();
  const [triggerFetchPostTypes, { data: postTypeQueryData }] = useLazyGetPostTypesQuery();
  const [triggerFetchMessages, { isFetching: isMessageFetching, error: isMessageFetchError }] = useLazyFetchMessagesQuery();
  const [sidePanelProfiles, setSidePanelProfiles] = useState([]);
  //const isInitialMessagesFetched = useRef(false);
  const [showCardHeader, setShowCardHeader] = useState(true);
  // Add online status tracking state
  const [onlineUsers, setOnlineUsers] = useState(new Set());
  // Add new state for online status
  const [isOnline, setIsOnline] = useState(true);
  const [updateOnlineStatus] = useUpdateOnlineStatusMutation(); // New API hook
  const sidePanelProfilesRef = useRef(sidePanelProfiles);
  const [fetchUnreadMessages, { data: unreadMessages }] = useLazyFetchUnreadMessagesCountQuery();
  const [markMessagesAsRead] = useMarkMessagesAsReadMutation();

  useEffect(() => {
    if (selectedChat?.channelName && currentUserId) {
      console.log('Marked read to' + selectedChat?.channelName);

      markMessagesAsRead({
        channel_name: selectedChat.channelName,
        user_id: selectedChat.senderId,
      })
        .unwrap()
        .then(() => {
          console.log("Messages marked as read.");
          // fetchUnreadMessages({ user_id: currentUserId, channel_name: selectedChat.channelName });
        })
        .catch((error) => console.error("Error marking messages as read:", error));
    }
  }, [selectedChat, currentUserId]);

  // useEffect(() => {
  //   if (currentUserId) {
  //     fetchUnreadMessages();
  //   }
  // }, [currentUserId]);

  // Update the ref when sidePanelProfiles changes
  useEffect(() => {
    sidePanelProfilesRef.current = sidePanelProfiles;
  }, [sidePanelProfiles]);

  useEffect(() => {
    if (currentUserId) {
      // triggerGetUserProfile();
      triggerFetchPostTypes();
    }
    // }, [currentUserId, triggerGetUserProfile, triggerFetchPostTypes]);
  }, [currentUserId, triggerFetchPostTypes]);

  useEffect(() => { //FETCHING ALL THE DATA OF "SENDERS"
    if (currentUserId && entity) {
      const recipientId = currentUserId;
      console.log("HERE REC and listing and entity: ", recipientId, listing, entity);

      //FETCHING NEW ENDPOINT TO GET ALL
      triggerFetchLatestDetailedSenderProfiles({ recipient_id: recipientId, listing_type: listing, entity_type: entity })
        .unwrap()
        .then((response) => {
          if (response.users.length > 0) {
            // Show the card header if there are senders
            setShowCardHeader(true);
          } else {
            // Hide the card header if no senders are found
            setShowCardHeader(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching sender profiles:", error);
          setShowCardHeader(false); // Optionally hide the header if there's an error
        });
    }
  }, [currentUserId, triggerFetchLatestDetailedSenderProfiles, entity]);

  useEffect(() => {
    if (LatestSenderProfiles?.users && currentUserId) {

      setSidePanelProfiles(LatestSenderProfiles.users); // Initialize the side panel data NEW
    }
  }, [LatestSenderProfiles, currentUserId]);


  useEffect(() => { //printing and checking
    if (currentUserId && LatestSenderProfiles) {
      console.log("HIHIHIHIHIHI IN SIDE LatestSenderProfiles:", LatestSenderProfiles);
    }
  }, [currentUserId, LatestSenderProfiles]);


  useEffect(() => {
    triggerCheckChatMetadata({ channel_name: selectedChat.channelName });
    console.log(selectedChat);
  }, [selectedChat])




  //SETTING SELECTED CHAT
  useEffect(() => {
    if (currentUserId && LatestSenderProfiles?.users) {

      console.log("Latest message and sender on the side panel [0]: ", LatestSenderProfiles.users[0])
      const latestChat = LatestSenderProfiles.users[0]; // Assuming sidePanelData is sorted by timestamp (latest first)
      setSelectedChat(latestChat);
      console.log("LATEST CHAT: ", latestChat);

    }
  }, [currentUserId, LatestSenderProfiles]);

  // ENF OF SIDEPANEL


  //Post Type data got from backend
  useEffect(() => {
    if (postTypeQueryData) {
      const map = new Map(postTypeQueryData.map((item) => [item.name, item.id]));
      setPostTypeMap(map);
    }
  }, [postTypeQueryData]);

  useEffect(() => {
    // Initialize Pusher once
    if (!pusherInstance.current) {
      pusherInstance.current = new Pusher("b4134b5b9a56f5c6f410", {
        cluster: "us2",
        authEndpoint: `${process.env.REACT_APP_API_URL}/pusher/auth`,
      });
      console.log("Pusher instance initialized:", pusherInstance.current);
    }

    // Subscribe to the private channel
    const privateChannel = pusherInstance.current.subscribe("private-channel");

    privateChannel.bind("new-message", (newMessage) => {
      console.log("New message received in private channel:", newMessage);

      const { channelName, content, timestamp, profileImageLink, displayName, sender, entityType, postId, isOnline } = newMessage;

      if (!channelName) {
        console.error("Missing channelName in newMessage:", newMessage);
        return;
      }

      // Ignore messages sent by the current user
      if (sender === currentUserId) {
        console.log("Message sent by me, ignoring.");
        return;
      }

      if (postId !== listingData.id || entityType !== entity) {
        console.log("Postid or entity type not matched");
        return;
      }

      //Initially when new message come from new user then metadataId is null so when you send message then will get error to fix this we running query when metadataId is null
      if (!metadataId) {
        triggerCheckChatMetadata({ channel_name: channelName });
      }

      // Update SidePanel profiles
      setSidePanelProfiles((prevProfiles) => {
        const updatedProfiles = [...prevProfiles];
        const existingProfileIndex = updatedProfiles.findIndex(
          (profile) => profile.channelName === channelName
        );

        if (existingProfileIndex >= 0) {
          updatedProfiles[existingProfileIndex] = {
            ...updatedProfiles[existingProfileIndex],
            content,
            isOnline: isOnline,
            timestamp,
            senderDetails: {
              display_name: displayName || updatedProfiles[existingProfileIndex].senderDetails?.display_name || "Unknown User",
              profileImageLink: profileImageLink || updatedProfiles[existingProfileIndex].senderDetails?.profileImageLink,
            },
          };
        } else {
          updatedProfiles.push({
            channelName,
            content,
            isOnline: isOnline,
            timestamp,
            senderId: sender,
            senderDetails: {
              display_name: displayName || "Unknown User",
              profileImageLink: profileImageLink,
            },
            hasUnreadMessage: true,
          });
        }
        console.log("CHAT PANLE OWNER UPDATED PROFILE", updatedProfiles)
        // Sort profiles by timestamp
        const sortedProfiles = updatedProfiles.sort((a, b) => {
          // Convert timestamp strings to Date objects and compare them
          return new Date(b.timestamp) - new Date(a.timestamp);
        });

        console.log("CHAT PANLE OWNER SORTED UPDATED PROFILE", sortedProfiles)
        return sortedProfiles;
      });

      // Update the currently open chat's MessageBox
      if (channelName === selectedChat?.channelName) {
        setMessages((prevMessages) => [...prevMessages, newMessage]);
      }
    });

    // Add listener for online status updates
    privateChannel.bind("user-status-update", (statusUpdate) => {
      console.log("Status update received:", statusUpdate);

      const { userId, isOnline, channelName, postId, entityType } = statusUpdate;

      // Check if this status update is for a user we're chatting with
      if (postId !== listingData.id || entityType !== entity) {
        console.log("Status update not relevant to this listing");
        return;
      }

      // Update the online users list
      setOnlineUsers(prevOnlineUsers => {
        const updatedOnlineUsers = new Set(prevOnlineUsers);
        console.log("Online user set : " + prevOnlineUsers);

        if (isOnline) {
          updatedOnlineUsers.add(userId);
        } else {
          updatedOnlineUsers.delete(userId);
        }

        return updatedOnlineUsers;
      });

      // Update the SidePanel profiles to show online status
      setSidePanelProfiles(prevProfiles => {
        return prevProfiles.map(profile => {
          if (profile.senderId === userId) {
            return {
              ...profile,
              isOnline: isOnline
            };
          }
          return profile;
        });
      });
      // setShowCardHeader(true);
    });



    // Cleanup subscription on unmount
    return () => {
      privateChannel.unbind_all();
      privateChannel.unsubscribe();
    };
  }, [selectedChat]);

  useEffect(() => {
    if (isGetMetadataSuccess && currentMetadata) {
      console.log("Intial metadata id is set : " + currentMetadata.metadata_id);

      setMetadataId(currentMetadata.metadata_id);
    }
  }, [isGetMetadataSuccess, currentMetadata])


  // In the Owner ChatPanel.js


  useEffect(() => {
    // console.log('selected chat id :', selectedChat.senderId);
    // console.log(sidePanelProfiles);

    sidePanelProfiles.forEach((profile) => {
      // console.log('profile isOnline:', profile.isOnline);

      if (profile.senderId === selectedChat.senderId) {
        setSelectedChat((prevChat) => ({
          ...prevChat,
          isOnline: profile.isOnline !== undefined ? profile.isOnline : prevChat.isOnline
        }));
      }
    });

  }, [sidePanelProfiles]); // Added dependencies

  // if (!currentUserId || !listingData || !postTypeMap.get(entity)) {
  //   return;
  // }

  // const userId = currentUserId;
  // const recipientId = listingData?.device_user?.id ||
  //   listingData?.spare_user?.id ||
  //   listingData?.software_user?.id ||
  //   listingData?.service_user?.id ||
  //   listingData?.job_user?.id;
  // const postId = postTypeMap.get(entity);

  // const channel_name = `private-chat-${listing}-${postId}-${Math.min(
  //   userId,
  //   recipientId
  // )}-${Math.max(userId, recipientId)}`;

  // // Update online status when component mounts
  // try {
  //   await updateOnlineStatus({
  //     userId,
  //     isOnline: true,
  //     channelName: channel_name
  //   }).unwrap();

  //   setIsOnline(true);
  //   console.log('online is set true');

  // } catch (error) {
  //   console.error("Error updating online status:", error);
  // }
  // Add this useEffect to handle online status
  useEffect(() => {
    console.log('Inside online status update');

    const handleOnlineStatus = () => {
      // Get the current online status based on document visibility
      const newOnlineStatus = !document.hidden;
      setIsOnline(newOnlineStatus);

      // Update online status for all profiles
      sidePanelProfilesRef.current.forEach((profile) => {
        if (profile) {
          try {
            updateOnlineStatus({
              userId: profile.senderId,
              isOnline: newOnlineStatus,
              channelName: profile.channelName || profile.channel_name // Handle both property names
            });
            console.log(`User status set to: ${newOnlineStatus ? 'online' : 'offline'}`);
          } catch (error) {
            console.error("Error updating online status:", error);
          }
        }
      });
    };

    // Set up visibility change listener
    document.addEventListener("visibilitychange", handleOnlineStatus);

    // Initial call to set status when component mounts
    handleOnlineStatus();

    // Cleanup function
    return () => {
      document.removeEventListener("visibilitychange", handleOnlineStatus);

      // Set offline status when unmounting
      sidePanelProfilesRef.current.forEach((profile) => {
        if (profile) {
          try {
            updateOnlineStatus({
              userId: profile.senderId,
              isOnline: false,
              channelName: profile.channelName || profile.channel_name
            });
            console.log('User set to offline on unmount');
          } catch (error) {
            console.error("Error updating offline status on unmount:", error);
          }
        }
      });
    };
  }, [sidePanelProfilesRef, updateOnlineStatus]); // Include dependencies
  // updateOnlineStatus({
  //   userId,
  //   isOnline: false,
  //   channelName: channel_name
  // }).catch(error => console.error("Error updating offline status:", error));

  // }, [currentUserId, listingData, postTypeMap, entity, updateOnlineStatus]);



  // const handleLoadMore = async () => { //NEW DONE
  //   try {
  //     const response = await triggerFetchMessages({ channel_name: selectedChat.channelName, page }).unwrap();
  //     if (response?.messages) {
  //       setMessages((prevMessages) => [...response.messages, ...prevMessages]);
  //       setPage((prevPage) => prevPage + 1); // Increment page for the next fetch
  //     }
  //   } catch (error) {
  //     console.error("Error fetching historical messages:", error);
  //   }
  // };

  // Helper function to save metadata and conversation
  const [hasMoreMessages, setHasMoreMessages] = useState(true); // ✅ Default: True

  const handleLoadMore = async () => {
    if (!hasMoreMessages) return; // ✅ Stop API calls if no more messages

    try {
      const response = await triggerFetchMessages({
        channel_name: selectedChat.channelName,
        page,
      }).unwrap();

      console.log("Fetched Messages:", response?.messages?.length); // Debugging

      if (response?.messages?.length > 0) {
        setMessages((prevMessages) => {
          // Extract unique identifiers from your current messages
          const existingIds = new Set(prevMessages.map(msg => msg.messageId));
          const existingSignatures = new Set(prevMessages.map(msg => `${msg.sender}-${msg.content}`));

          // Filter out any duplicates using both checks
          const newMessages = response.messages.filter(msg =>
            !existingIds.has(msg.messageId) &&
            !existingSignatures.has(`${msg.sender}-${msg.content}`)
          );

          return [...newMessages, ...prevMessages];
        });
        setPage((prevPage) => prevPage + 1);
      } else {
        console.log("No more messages available.");
        setHasMoreMessages(false); // ✅ No more messages, hide button
      }
    } catch (error) {
      console.error("Error fetching historical messages:", error);
      if (error.status === 404) {
        setHasMoreMessages(false); // ✅ Hide button on 404 error
      }
    }
  };



  const handleSaveMetadataAndConversation = async (
    channel_name,
    userId,
    recipientId,
    subject,
    postId
  ) => {
    try {
      const metadataResponse = await saveMetadata({
        channel_name,
        user_id: userId,
        recipient_id: recipientId,
        post_id: postId,
        listing_type: listing,
        entity_type: entity,
      }).unwrap();

      const conversationResponse = await saveConversation({
        metadata_id: metadataResponse.metadata_id,
        subject,
      }).unwrap();

      return { metadataResponse, conversationResponse };
    } catch (error) {
      console.error("Error saving metadata or conversation:", error);
      throw error;
    }
  };

  // Helper function to check metadata and optionally create it
  const subscribeWithMetadataCheck = async (
    channel_name,
    userId,
    recipientId,
    postId,
    subject
  ) => {
    try {
      console.log("yi:");
      const response = await triggerCheckChatMetadata({ channel_name }).unwrap();
      console.log("RESPONSE:", response);

      if (response?.status) {
        console.log("yes");
        return response.metadata_id;
      } else {
        console.log("NO");
        const result = await handleSaveMetadataAndConversation(
          channel_name,
          userId,
          recipientId,
          subject,
          postId
        );

        if (result?.metadataResponse) {
          console.log("UMMMMMM");
          return result.metadataResponse.metadata_id;
        } else {
          throw new Error("Failed to create metadata");
        }
      }
    } catch (error) {
      console.error("Error checking or saving metadata:", error);
      throw error;
    }
  };

  // Step 2: Initialize chat
  useEffect(() => {
    const initializeChat = async () => {
      if (
        !currentUserId ||
        // !decryptedData?.user ||
        !listingData ||
        !postTypeMap ||
        !LatestSenderProfiles ||
        !selectedChat ||
        !selectedChat?.senderId
      ) {
        return;
      }
      console.log("CUR: ", currentUserId);
      const userId = currentUserId;
      const recipientId = selectedChat?.senderId; //sender ID of the selected chat will be the reciver of the current user
      const postId = await postTypeMap.get(entity);

      console.log("user and rec id:", userId, recipientId);
      const channel_name = `private-chat-${listing}-${postId}-${Math.min(
        userId,
        recipientId
      )}-${Math.max(userId, recipientId)}`;

      try {
        const metadata_id = await subscribeWithMetadataCheck(
          channel_name,
          userId,
          recipientId,
          postId,
          entity
        );
        setMetadataId(metadata_id);

        console.log("1st metaid:", metadataId);
      } catch (error) {
        console.error("Error initializing chat:", error);
      }
    };

    initializeChat();
  }, [currentUserId, listingData, postTypeMap, LatestSenderProfiles, selectedChat]);

  //FETCH THE INITIAL MESSAGES FOR DISPLAY
  useEffect(() => {  //NEW DONE
    const initializeChat = async () => {
      if (!currentUserId || !selectedChat?.channelName) {//|| isInitialMessagesFetched.current) {
        return;
      }
      // Reset pagination
      setPage(1);

      // Clear messages when switching chats
      setMessages([]);
      try {
        console.log(selectedChat?.channelName)
        const response = await triggerFetchInitialMessagesForDisplay(selectedChat?.channelName).unwrap();
        //  isInitialMessagesFetched.current = true;
        console.log("PRINTING DISPLAY MESSAGES IN CHAT PANEL: ", response);
        if (response?.messages) {
          setMessages(response.messages); // Populate messages with historical data
          setShowCardHeader(true);
        }
      } catch (error) {
        console.error("Error fetching initial messages:", error);
      }
    };

    initializeChat();
  }, [currentUserId, selectedChat?.channelName]);

  const handleSendMessage = async (messageText) => {
    if (!messageText.trim()) return;
    const userId = currentUserId;
    // const profileImageLink = decryptedData?.user?.profileImageLink;
    // const displayName = currentUserId?.displayName;
    const recipientId = selectedChat?.senderId;
    const postId = postTypeMap?.get(entity);



    const channel_name = `private-chat-${listing}-${postId}-${Math.min(
      userId,
      recipientId
    )}-${Math.max(userId, recipientId)}`;

    console.log("Printing SELECTED CHAT name and decryptedData ID:", selectedChat);
    console.log("Printing channel name and user ID:", channel_name, userId, recipientId, postId);

    const time = Date.now();

    // Construct the new message object
    const newMessage = {
      sender: userId,
      timestamp: time,
      content: messageText,
      real_time: true, // To differentiate between real-time and historical messages
    };

    console.log("New message in panel:", newMessage);



    //  Update the sidePanelProfiles state


    setSidePanelProfiles((prevProfiles) => {
      // Remove the existing profile for the current chat
      const updatedProfiles = prevProfiles.filter(
        (profile) => profile.channelName !== channel_name
      );

      // Add the chat back at the top
      return [
        {
          ...selectedChat,
          content: messageText,
          timestamp: time,
        },
        ...updatedProfiles,
      ];
    });

    const formattedTime = new Date(time).toISOString();
    // Send the message to the backend via API
    await sendMessage({
      channel: channel_name,
      message: messageText,
      sender: userId,
      timestamp: formattedTime,
      // profileImageLink,
      // displayName: displayName,
    })
      .unwrap()
      .then(() =>
      // Update the chat messages state for the MessageBox
      {
        setMessages((prevMessages) => [...prevMessages, newMessage])
        saveEachMessage({
          message_metadata_id: metadataId,
          message: messageText,
          message_type: "text",
          message_status: true,
          sender_id: userId, // Sender ID
        }).unwrap();

      })
      .catch((err) => console.error("Error sending message:", err));

    // Save the message in the database
  };


  // if (isLoading) return <div>Loading...</div>;
  // if (error) return <div>Error loading data</div>;

  return (
    <form className="chatB-chat-panel" onSubmit={(e) => e.preventDefault()}>
      <div className="chatB-chat-content">
        {/* CHAT SIDE PANEL */}
        {currentUserId && <SidePanel
          currentUserId={currentUserId}
          LatestSenderProfiles={sidePanelProfiles}
          onSelectChat={(profile) => setSelectedChat(profile)}
        />}
        {messages.length > 0 && currentUserId && selectedChat ? (
          <MessageBox
            messages={messages}
            setMessages={setMessages}
            currentUserId={currentUserId}
            onSendMessage={handleSendMessage}
            isSendMessageLoading={isSendMessageLoading}
            listingData={listingData}
            onLoadMore={handleLoadMore} // Pass load more functionality
            selectedChat={selectedChat}
            showCardHeader={showCardHeader}
            isSendMessageError={isSendMessageError}
            isSendMessageSuccess={isSendMessageSuccess}
            isMessageFetching={isMessageFetching}
            isMessageFetchError={isMessageFetchError}
            hasMoreMessages={hasMoreMessages}
          />
        ) : (
          <NoMessages />
        )}
      </div>
    </form>
  );
};

export { ChatPanel };