import React from "react";
import "./ReportAbuseConfirmationModal.css";
import featured_icon from "../../icons/Gerator/success_component/featured-icon.svg";

export const ReportAbuseConfirmationModal = ({  show, onClose}) => {
    if (!show) return null;

  return (
    <div className="report-abuse-confirmation-email-popup">
      <section className="report-abuse-confirmation-frame-section">
        <div className="report-abuse-confirmation-frame-child5"></div>
        <div className="report-abuse-confirmation-container7">
          <div className="report-abuse-confirmation-container8">
            <div className="report-abuse-confirmation-content21">
              <div className="report-abuse-confirmation-container9">
                <div className="report-abuse-confirmation-content22">
                  <div className="report-abuse-confirmation-header1">
                    <img
                      className="report-abuse-confirmation-featured-icon1"
                      loading="lazy"
                      alt="Success Icon"
                      src={featured_icon}
                    />
                    <div className="report-abuse-confirmation-text-and-supporting-text3">
                      <h1 className="report-abuse-confirmation-title1">Abuse Report Filed</h1>
                      <div className="report-abuse-confirmation-supporting-text5">
                      Our TQA team will review your report and take necessary action.
                      </div>
                    </div>
                  </div>
                  <div className="report-abuse-confirmation-row1">
                   
                    <button
                      className="report-abuse-confirmation-buttonsbutton12"
                      onClick={onClose}
                    >
                      <div className="report-abuse-confirmation-back-label">
                        Close
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
