import React, { useState } from "react";
import "./ReviewDescriptionComponent.css";

export const ReviewDescriptionComponent = () => {
  const [title, setTitle] = useState(""); // State for the title
  const [review, setReview] = useState(""); // State for the review
  const maxTitleLength = 70; // Maximum characters for the title
  const maxReviewLength = 1000; // Maximum characters for the review

  const remainingChars = maxTitleLength - title.length;
  const remainingCharsReview = maxReviewLength - review.length;
  const warningClass = remainingChars <= 20 ? 'warning' : '';
  const warningClassReview = remainingCharsReview <= 100 ? 'warning' : '';

  return (
    <div>
      <div className="review-description-component-input-field">
        <div className="review-description-component-input-with-label1">
          <div className="review-description-component-label2">Title</div>
          <div className="review-description-component-content6">
            <div className="review-description-component-textarea-input-field">
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                maxLength={maxTitleLength}
                className="review-description-component-input-field-title"
                placeholder="Enter your title here"
              />
              <div className={`review-description-component-hint-text1 ${warningClass}`}>
                {remainingChars} characters left
              </div>
            </div>
          </div>
        </div>
        <div className="review-description-component-hint-text">This is a hint text to help the user.</div>
      </div>
      
      <div className="review-description-component-input-field1">
        <div className="review-description-component-input-with-label1">
          <div className="review-description-component-label4">Review</div>
          <div className="review-description-component-content6">
            <div className="review-description-component-textarea-input-field1">
              <textarea
                value={review}
                onChange={(e) => setReview(e.target.value)}
                maxLength={maxReviewLength}
                className={"review-description-component-input-field-review"}
                placeholder="Write your review here"
              />
              <div className={`review-description-component-hint-text3 ${warningClassReview}`}>
                {remainingCharsReview} characters left
              </div>
            </div>
          </div>
        </div>
        <div className="review-description-component-hint-text">This is a hint text to help the user.</div>
      </div>
    </div>
  );
};


