import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  softwareCategory:'',
  softwareValueProposition:'',
  softwareType:'',
  installationLocation:'',
  clinicalApplications:'',
  purposeUse:'',
  deviceCategory:'',
  physicalLocation:'',
}

const adminEditWantedSoftwareSlice = createSlice({
  name: "adminEditWantedSoftware",
  initialState,
  reducers: {
    fillAdminEditWantedSoftwareForm(state, action) {
     return action.payload;
    },
    updateAdminEditWantedSoftwareField(state, action) {
      const {field, value} = action.payload
      if (value !== undefined) { 
        state[field] = value; 
      }
    },
    clearAdminEditWantedSoftwareForm() {
      return initialState;
    }
  },
});

export const {fillAdminEditWantedSoftwareForm, updateAdminEditWantedSoftwareField,clearAdminEditWantedSoftwareForm } = adminEditWantedSoftwareSlice.actions

export default adminEditWantedSoftwareSlice.reducer

