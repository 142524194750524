import React, { useEffect, useState, useRef } from 'react';
import "./DetailedWantedSoftware.css"
import { Navbar1 } from '../../../components/Navbar1/Navbar1';
import FooterDetailPage from '../../../components/Footer/FooterDetailPage/FooterDetailPage';

import divider from '../../../icons/Gerator/detailed_listing/divider.svg';
import divider2 from '../../../icons/Gerator/detailed_listing/divider2.svg';

import chevronright from '../../../icons/Gerator/detailed_listing/chevronright.svg';
import users01 from '../../../icons/Gerator/detailed_listing/users01.svg';
import target_04 from "../../../icons/Gerator/dashboard/target_04.png"

import placeholder1 from '../../../icons/Gerator/detailed_listing/placeholder1.svg';

import markerpin02 from '../../../icons/Gerator/detailed_listing/markerpin02.svg';

import calendar from '../../../icons/Gerator/detailed_listing/calendar.svg';

import facebook_1_1_2x from '../../../icons/Gerator/detailed_listing/facebook-1-1@2x.png';

import instagram_1_1_2x from '../../../icons/Gerator/detailed_listing/instagram-1-1@2x.png';
import whatsapp_1_2x from '../../../icons/Gerator/detailed_listing/whatsapp-1@2x.png';
import twitter_1_2x from '../../../icons/Gerator/detailed_listing/twitter-1@2x.png';
import linkedinlogo_1_2x from '../../../icons/Gerator/detailed_listing/linkedinlogo-1@2x.png';

import dot1 from '../../../icons/Gerator/detailed_listing/-dot1.svg';

import radar_1_1_2x from '../../../icons/Gerator/detailed_listing/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/detailed_listing/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/detailed_listing/magnifier-1@2x.png';
import share_1_2x from '../../../icons/Gerator/detailed_listing/share-1@2x.png';

import group_1010 from '../../../icons/Gerator/detailed_listing/group-1010.svg';
import group_78_2x from '../../../icons/Gerator/detailed_listing/group-78@2x.png';
import group_58_2x from '../../../icons/Gerator/detailed_listing/group-58@2x.png';
import group_781_2x from '../../../icons/Gerator/detailed_listing/group-781@2x.png';

import dotsvertical from '../../../icons/Gerator/detailed_listing/dotsvertical.svg';
import divider1 from '../../../icons/Gerator/detailed_listing/divider1.svg';
import bookmarkcheck from '../../../icons/Gerator/detailed_listing/bookmarkcheck.svg';
import usercheck02 from '../../../icons/Gerator/detailed_listing/usercheck02.svg';
import messagechatcircle from '../../../icons/Gerator/detailed_listing/messagechatcircle.svg';
import { Loader } from "../../../components/Loader";
// import { useDispatch } from "react-redux";
import { useLazyFindGetWantedSoftwareQuery, useLazyGetWantedSoftwaresQuery } from "../../../api/WantedSoftwareAPI";
import { useNavigate, useParams } from "react-router-dom";
import { getCustomizedDate } from "../../../helper/SoftwareHelper";
import { decryptErrorData } from '../../../helper/AESHelper';
import { useLazyGetProfileImageLinkQuery, useLazyGetUserQuery } from '../../../api/Login&RegisterAPI';
import { firestore, doc, getDoc, auth, onAuthStateChanged } from '../../Login/FirebaseClient';
import { useAddContactMutation, useDeleteContactMutation } from '../../../api/ContactAPI';
import { useAddFunnelWantedSoftwareMutation, useDeleteFunnelWantedSoftwareMutation } from '../../../api/Funnel/FunnelWantedSoftwareAPI';
import { ErrorShowComponent } from '../../../components/ErrorShowComponent/ErrorShowComponent';

import add_contact from "../../../icons/Gerator/detailed_listing/add_contact.svg"

import follow from "../../../icons/Gerator/detailed_listing/follow.svg";
import ShareThisMetaData from '../../../components/ShareThisMetaData/ShareThisMetaData';
import { InlineShareButtons } from 'sharethis-reactjs';
import { InlineFollowButtons } from 'sharethis-reactjs';

import funnel from "../../../icons/Gerator/dashboard/funnel.svg";

import { ThreeDotsDetailPageComponent } from '../../../components/ThreeDotsListingComponent/ThreeDotsDetailPageComponent';
import { ThreeDotsDetailPageUserComponent } from "../../../components/ThreeDotsListingComponent/ThreeDotsDetailPageUserComponent";
import { useAddViewWantedSoftwareMutation } from '../../../api/Beacon/BeaconWantedSoftwareAPI';


export const DetailedWantedSoftware = () => {

  const [isThreeDotOpen, setIsThreeDotOpen] = useState(false);
  const threeDotsRef = useRef(null);
  const [isClickBlocked, setIsClickBlocked] = useState(false);

  // Toggle function for the three dots dropdown
  const toggleThreeDotsDropdown = () => {
    if (isClickBlocked) return; // Prevent rapid toggle

    setIsThreeDotOpen(prev => !prev); // Toggle the dropdown
    setIsClickBlocked(true);
    setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
  };

  // Handle clicks outside the three dots dropdown
  useEffect(() => {
    const handleThreeDotClickOutside = (event) => {
      const isLink = event.target.closest('a');
      if (isLink) return; // Allow navigation to happen

      if (threeDotsRef.current && !threeDotsRef.current.contains(event.target)) {
        setIsThreeDotOpen(false); // Close the dropdown when clicking outside
      }
    };

    document.addEventListener("mousedown", handleThreeDotClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleThreeDotClickOutside);
    };
  }, []);


  // For second dropdown (for user or other context)
  const [isThreeDotUserOpen, setIsThreeDotUserOpen] = useState(false);
  const threeDotsRefUser = useRef(null);
  const [isClickBlockedUser, setIsClickBlockedUser] = useState(false);

  // Toggle function for the second three dots dropdown
  const toggleThreeDotsDropdownUser = () => {
    if (isClickBlockedUser) return; // Prevent rapid toggle

    setIsThreeDotUserOpen(prev => !prev); // Toggle the dropdown
    setIsClickBlockedUser(true);
    setTimeout(() => setIsClickBlockedUser(false), 400); // Small delay to avoid reopening
  };

  // Handle clicks outside the second three dots dropdown
  useEffect(() => {
    const handleThreeDotClickOutsideUser = (event) => {
      const isLink = event.target.closest('a');
      if (isLink) return; // Allow navigation to happen

      if (threeDotsRefUser.current && !threeDotsRefUser.current.contains(event.target)) {
        setIsThreeDotUserOpen(false); // Close the dropdown when clicking outside
      }
    };

    document.addEventListener("mousedown", handleThreeDotClickOutsideUser);
    return () => {
      document.removeEventListener("mousedown", handleThreeDotClickOutsideUser);
    };
  }, []);

  const [activeSection, setActiveSection] = useState(null); // "share", "follow", or null

  const handleShareButtonClick = () => {
    setActiveSection((prevState) => (prevState === 'share' ? null : 'share')); // Toggle or close
  };

  const handleFollowButtonClick = () => {
    setActiveSection((prevState) => (prevState === 'follow' ? null : 'follow')); // Toggle or close
  };


  const { id } = useParams();
  let content;
  const [currentUser, setCurrentUser] = useState("");
  const navigate = useNavigate();
  const [profileImageLink, setProfileImageLink] = useState("");
  const [currentUserMeta, setCurrentUserMeta] = useState("");

  const [triggerGetWantedSoftware, { data: wantedSoftwareData,
    isLoading: isWantedSoftwareLoading,
    isSuccess: isWantedSoftwareSuccess,
    isError: isWantedSoftwareError,
    error: wantedSoftwareError }] = useLazyFindGetWantedSoftwareQuery();

  //Add View Query
  const [
    addViewWantedSoftware,
    {
      data: wantedSoftwareViewData,
      isLoading: isViewWantedSoftwareLoading,
      isSuccess: isViewWantedSoftwareSuccess,
      isError: isViewWantedSoftwareError,
      error: deviceViewError,
    },
  ] = useAddViewWantedSoftwareMutation();

  const [triggerNewProfileImageLink] = useLazyGetProfileImageLinkQuery();

  // Funnel query delete
  const [deleteFromFunnel] = useDeleteFunnelWantedSoftwareMutation();

  // Funnel query delete
  const [addToFunnel] = useAddFunnelWantedSoftwareMutation();

  // Add/Remove Contact Query
  const [addToContact, {
    isLoading: isContactLoading,
    isSuccess: isContactSuccess,
    isError: isContactError,
    error: contactError }] = useAddContactMutation();

  const [deleteFromContact] = useDeleteContactMutation();


  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {

        if (user) {
          // Get user to check wizardStep
          const userDocRef = doc(firestore, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);
          setCurrentUser(user);

          if (userDocSnapshot.exists()) {
            const usermeta = userDocSnapshot.data(); // Default to 1 if not set

            if (usermeta.userStatus == 2) {
              navigate('/account-disabled');
              return;
            }

            if (usermeta.userType == 5) {
              navigate('/access-denied');
              return;
            }

            if (usermeta.wizardStep !== 0) {
              navigate('/account-setup-check-email');
              return;
            }

            // await triggerGetCurrentPlan().unwrap();
            setCurrentUserMeta(usermeta);
            triggerGetWantedSoftware(id);
            // User is authenticated
          } else {
            content = <ErrorShowComponent message1={"Internal server error"} />
          }
        }
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });


    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);


  //Profile image load useEffect
  useEffect(() => {
    const refreshProfileImage = async () => {
      if (currentUserMeta && currentUserMeta.profileImage.expiresAt < Date.now() / 1000) {
        // Pre-signed URL has expired, trigger a new request to the backend
        const newImageData = await triggerNewProfileImageLink().unwrap();
        // Update the profile image link directly
        setProfileImageLink(newImageData.url);
      } else if (currentUserMeta && currentUserMeta.profileImage.url) {
        // Use the existing pre-signed URL to display the profile image
        setProfileImageLink(currentUserMeta.profileImage.url);
      }
    };

    refreshProfileImage();
  }, [currentUserMeta]);


  useEffect(() => {
    if (isWantedSoftwareSuccess) {
      addViewWantedSoftware(id);
      console.log("added view wanted software data");
    }
  }, [isWantedSoftwareSuccess]);


  //Funnel Code
  const handleRemoveFunnel = async (wantedSoftwareId) => {
    await deleteFromFunnel(wantedSoftwareId);
    window.location.reload();
  };

  const handleAddFunnel = async (wantedSoftwareId) => {
    await addToFunnel(wantedSoftwareId);
    window.location.reload();
  };

  //Contact Code
  const handleRemoveContact = async (wantedSoftwareId) => {
    await deleteFromContact(wantedSoftwareId);
    window.location.reload();
  };

  const handleAddContact = async (wantedSoftwareId) => {
    await addToContact(wantedSoftwareId);
    window.location.reload();
  };


  if (isWantedSoftwareError) {
    content = <ErrorShowComponent message1={"Internal server error"} />
  }

  const handlePrivateMessage = () => {
    const wantedSoftwareId = wantedSoftwareData?.data?.id; // Use the appropriate ID for the chat
    handleAddFunnel(wantedSoftwareId);
    navigate(`/chat/wanted/Software/${wantedSoftwareId}`); // Replace `/chat/:id` with the correct route for your chat page
  };

  if (isWantedSoftwareSuccess && profileImageLink) {
    return (
      <>
        <div className="detailed-wanted-software-listings-detailed-page-devic">
          <div className="detailed-wanted-software-rectangle-parent">
            <div className="detailed-wanted-software-frame-child"></div>
            <Navbar1 userData={currentUser} navigate={navigate} profileImageLink={profileImageLink} />

            <img className="detailed-wanted-software-divider-icon" alt="" src={divider} />

            <div className="detailed-wanted-software-breadcrumb-button-base">
              <div className="detailed-wanted-software-text6">...</div>
            </div>
            <img
              className="detailed-wanted-software-chevron-right-icon"
              alt=""
              src={chevronright}
            />

            <div className="detailed-wanted-software-breadcrumb-button-base1">
              <div className="detailed-wanted-software-text7">Another link</div>
            </div>
            <img
              className="detailed-wanted-software-chevron-right-icon1"
              alt=""
              src={chevronright}
            />

            <div className="detailed-wanted-software-frame-parent">
              <div className="detailed-wanted-software-breadcrumb-container-parent">
                <div className="detailed-wanted-software-breadcrumb">
                  <div className="detailed-wanted-software-breadcrumb-items">
                    <div className="detailed-wanted-software-breadcrumb-button-base2">
                      <div className="detailed-wanted-software-text9">Dashboard</div>
                    </div>
                    <div className="detailed-wanted-software-chevron-container">
                      <img
                        className="detailed-wanted-software-chevron-right-icon2"
                        alt=""
                        src={chevronright}
                      />
                    </div>
                    <div className="detailed-wanted-software-breadcrumb-button-base3">
                      <div className="detailed-wanted-software-text10">Wanted Software</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-wanted-software-listing-content-wrapper">
                <div className="detailed-wanted-software-listing-content">
                  <div className="detailed-wanted-software-description-block-parent">
                    <div className="detailed-wanted-software-description-block">
                      <div className="detailed-wanted-software-description-heading">
                        {/* <span className="detailed-wanted-software-pre-">Pre-</span>
                    <span className="detailed-wanted-software-o">o</span>
                    <span className="detailed-wanted-software-pre-">wned, </span>
                    <span className="detailed-wanted-software-o"
                    >USED, FULLY REFURBISHED 1984 gARAVENTA ACCESSIBILITY
                      WRISTVIEW STREPTOCOCCUS A HYALURONIDASE ANTIBODY
                      DETECTION, IDENTIFICATION REAGENTS FOR RENT / LEASE</span
                    > */}
                        {wantedSoftwareData.data.title}
                      </div>
                      <div className="detailed-wanted-software-listing-details">
                        <div className="detailed-wanted-software-detail-container">
                          <div className="detailed-wanted-software-details-content">
                            <div className="detailed-wanted-software-details-row">
                              <div className="detailed-wanted-software-detail-items">
                                <img
                                  className="detailed-wanted-software-marker-pin-02-icon"
                                  alt=""
                                  src={markerpin02}
                                />

                                <div className="detailed-wanted-software-supporting-text">{wantedSoftwareData.data.wanted_software_user.user_usermeta.tax_country.name}, {wantedSoftwareData.data.wanted_software_user.user_usermeta.userTown}</div>
                              </div>
                              <div className="detailed-wanted-software-detail-items">
                                <img
                                  className="detailed-wanted-software-marker-pin-02-icon"
                                  alt=""
                                  src={calendar}
                                />

                                <div className="detailed-wanted-software-supporting-text">{getCustomizedDate(wantedSoftwareData.data.createdAt)}</div>
                              </div>
                            </div>
                            <div className="detailed-wanted-software-contact-details">
                              <div className="detailed-wanted-software-contact-name-wrapper">
                                <div className="detailed-wanted-software-contact-name">4.9/5</div>
                              </div>
                              <div className="detailed-wanted-software-contact-role">
                                <div className="detailed-wanted-software-supporting-text2">99,999 reviews</div>
                              </div>
                              {/* <div className="detailed-wanted-software-social-links">
                                <div className="detailed-wanted-software-rectangle-container">
                                  <div className="detailed-wanted-software-frame-inner"></div>
                                  <div className="detailed-wanted-software-icon-backgrounds-parent">
                                    <div className="detailed-wanted-software-icon-backgrounds"></div>
                                    <img
                                      className="detailed-wanted-software-whatsapp-1-icon"
                                      alt=""
                                      src={facebook_1_1_2x}
                                    />
                                  </div>
                                  <div className="detailed-wanted-software-icon-backgrounds-parent">
                                    <div className="detailed-wanted-software-ellipse-div"></div>
                                    <img
                                      className="detailed-wanted-software-whatsapp-1-icon"
                                      alt=""
                                      src={instagram_1_1_2x}
                                    />
                                  </div>
                                  <div className="detailed-wanted-software-icon-backgrounds-parent">
                                    <div className="detailed-wanted-software-frame-child1"></div>
                                    <img
                                      className="detailed-wanted-software-whatsapp-1-icon"
                                      alt=""
                                      src={whatsapp_1_2x}
                                    />
                                  </div>
                                  <div className="detailed-wanted-software-icon-backgrounds-parent">
                                    <div className="detailed-wanted-software-icon-backgrounds"></div>
                                    <img
                                      className="detailed-wanted-software-whatsapp-1-icon"
                                      alt=""
                                      src={twitter_1_2x}
                                    />
                                  </div>
                                  <div className="detailed-wanted-software-icon-backgrounds-parent">
                                    <div className="detailed-wanted-software-ellipse-div"></div>
                                    <img
                                      className="detailed-wanted-software-whatsapp-1-icon"
                                      alt=""
                                      src={linkedinlogo_1_2x}
                                    />
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          </div>
                          <div className="detailed-wanted-software-button-row">
                            {!wantedSoftwareData.data.isAuthor && (
                              wantedSoftwareData.data.addedToFunnel ? (

                                <div className="detailed-wanted-software-buttonsbutton1" onClick={() => handleRemoveFunnel(wantedSoftwareData.data.id)
                                } style={{ cursor: "pointer" }}  >

                                  <img
                                    className="detailed-wanted-software-magnifier-1-icon"
                                    alt=""
                                    src={target_04}
                                  />

                                  <div className="detailed-wanted-software-text-padding">
                                    <div className="detailed-wanted-software-text11">Added To Funnel</div>
                                  </div>
                                </div>
                              ) : (
                                <div className="detailed-wanted-software-buttonsbutton1" onClick={() => handleAddFunnel(wantedSoftwareData.data.id)
                                } style={{ cursor: "pointer" }}  >

                                  <img
                                    className="detailed-wanted-software-magnifier-1-icon"
                                    alt=""
                                    src={funnel}
                                  />

                                  <div className="detailed-wanted-software-text-padding">
                                    <div className="detailed-wanted-software-text11">Add To Funnel</div>
                                  </div>
                                </div>
                              )
                            )}

                            {/* <div className="detailed-wanted-software-buttonsbutton2">
                              <img
                                className="detailed-wanted-software-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />

                              <img
                                className="detailed-wanted-software-magnifier-1-icon"
                                alt=""
                                src={deal_2_1_2x}
                              />

                              <img
                                className="detailed-wanted-software-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />
                            </div>
                            <div className="detailed-wanted-software-buttonsbutton2">
                              <img
                                className="detailed-wanted-software-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />

                              <img
                                className="detailed-wanted-software-magnifier-1-icon"
                                alt=""
                                src={magnifier_1_2x}
                              />

                              <img
                                className="detailed-wanted-software-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />
                            </div> */}
                            <div className='detailed-wanted-software-sharethis-parent'>

                              <div className="detailed-wanted-software-buttonsbutton2" onClick={handleShareButtonClick} style={{ cursor: 'pointer' }}>
                                <img
                                  className="detailed-wanted-software-placeholder-icon4"
                                  alt=""
                                  src={placeholder1}
                                />

                                <img
                                  className="detailed-wanted-software-magnifier-1-icon"
                                  alt=""
                                  src={share_1_2x}
                                />

                                <img
                                  className="detailed-wanted-software-placeholder-icon4"
                                  alt=""
                                  src={placeholder1}
                                />



                              </div>

                              {activeSection === 'share' && (
                                <div className='detailed-wanted-software-sharethis'>
                                  <InlineShareButtons
                                    config={{
                                      alignment: 'center',
                                      color: 'black',
                                      enabled: true,
                                      font_size: 14,
                                      labels: 'cta',
                                      language: 'en',
                                      networks: ["twitter", "whatsapp", "linkedin", "email", "facebook"
                                        , "flipboard"
                                        , "google"
                                        , "digg"
                                        , "livejournal"
                                        , "mailru"
                                        , "meneame"
                                        , "messenger"
                                        , "oknoklassniki"
                                        , "pinterest"
                                        , "print"
                                        , "reddit"
                                        , "sharethis"
                                        , "sms"
                                        , "stumbleupon"
                                        , "tumblr"
                                        , "blogger", "vk", "wechat", "weibo", "delicious", "xing"],
                                      padding: 12,
                                      radius: 4,
                                      show_total: false,
                                      size: 34,
                                      url: "https://gerator.io/", // Use the current page URL dynamically
                                      image: 'https://bit.ly/2CMhCMC',
                                      description: 'Check out this amazing content!',
                                      title: 'Custom Share Title',
                                    }}
                                  />
                                </div>
                              )}

                            </div >


                            {/* <img
                                                                                     className="detailed-wanted-software-button-row-child"
                                                                                     alt=""
                                                                                     src={group_1010}
                                                                                   /> */}

                            <div className='detailed-wanted-software-sharethis-follow-parent'>

                              <div className="detailed-wanted-software-buttonsbutton2" onClick={handleFollowButtonClick} style={{ cursor: 'pointer' }}>
                                <img
                                  className="detailed-wanted-software-placeholder-icon4"
                                  alt=""
                                  src={placeholder1}
                                />

                                <img
                                  className="detailed-wanted-software-magnifier-1-icon"
                                  alt=""
                                  src={follow}

                                />

                                <img
                                  className="detailed-wanted-software-placeholder-icon4"
                                  alt=""
                                  src={placeholder1}
                                />

                                Follow us

                              </div>

                              {activeSection === 'follow' && (
                                <div className='detailed-wanted-software-sharethis-follow'>
                                  <InlineFollowButtons
                                    config={{
                                      action: 'Follow us:', // call to action (STRING)
                                      action_enable: false,
                                      action_pos: 'bottom', // position of call to action (left, top, right)
                                      alignment: 'center',  // alignment of buttons (left, center, right)
                                      color: 'white',       // set the color of buttons (social, white)
                                      enabled: true,        // show/hide buttons (true, false)
                                      networks: [
                                        'linkedin',         // which networks to include (see FOLLOW NETWORKS)
                                        'twitter',
                                        'facebook',
                                        'instagram',

                                      ],
                                      padding: 8,           // padding within buttons (INTEGER)
                                      profiles: {           // social profile links for buttons
                                        twitter: 'ger8or',
                                        facebook: 'Ger8orMarketplace ',
                                        instagram: 'ger8or',
                                        linkedin: 'company/gerator',
                                      },
                                      radius: 9,            // the corner radius on each button (INTEGER)
                                      size: 32,             // the size of each button (INTEGER)
                                      spacing: 8            // the spacing between buttons (INTEGER)
                                    }}
                                  />
                                </div>
                              )}

                            </div>
                          </div>
                        </div>
                        <div className="detailed-wanted-software-dropdown-container" style={{ cursor: "pointer" }} onClick={toggleThreeDotsDropdown} ref={threeDotsRef}>
                          <div className="detailed-wanted-software-dropdown">
                            <img
                              className="detailed-wanted-software-maximize-01-icon"
                              alt=""
                              src={dotsvertical}
                            />
                          </div>
                          {isThreeDotOpen && <ThreeDotsDetailPageComponent />}

                        </div>
                      </div>
                    </div>
                    <img className="detailed-wanted-software-divider-icon" alt="" src={divider1} />



                    <div className="detailed-wanted-software-heading-parent">
                      <div className="detailed-wanted-software-heading">Highlights</div>
                      <div className="detailed-wanted-software-description-content">
                        <div className="detailed-wanted-software-description-columns-first">
                          <div className="detailed-wanted-software-first-columns-paragraphs">
                            <div className="detailed-wanted-software-paragraph">
                              {wantedSoftwareData.data.tax_software_category.name}
                            </div>
                            <div className="detailed-wanted-software-paragraph1">Software Category</div>
                          </div>
                          <div className="detailed-wanted-software-first-columns-paragraphs">
                            <div className="detailed-wanted-software-paragraph">{wantedSoftwareData.data.tax_software_type.name}</div>
                            <div className="detailed-wanted-software-paragraph1">Software Type</div>
                          </div>
                          <div className="detailed-wanted-software-first-columns-paragraphs">
                            <div className="detailed-wanted-software-paragraph">{wantedSoftwareData.data.tax_installation_location.name}</div>
                            <div className="detailed-wanted-software-paragraph1">Installation Location</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-software-description-columns-second">
                          <div className="detailed-wanted-software-second-columns-paragraphs">
                            <div className="detailed-wanted-software-paragraph">
                              {wantedSoftwareData.data.tax_device_category.name}
                            </div>
                            <div className="detailed-wanted-software-paragraph1">Software Category</div>
                          </div>
                          <div className="detailed-wanted-software-second-columns-paragraphs">
                            <div className="detailed-wanted-software-paragraph">{wantedSoftwareData.data.tax_physical_location.name}</div>
                            <div className="detailed-wanted-software-paragraph1">Physical Location</div>
                          </div>
                          <div className="detailed-wanted-software-second-columns-paragraphs">
                            <div className="detailed-wanted-software-paragraph">
                              {wantedSoftwareData.data.tax_clinical_application.name}
                            </div>
                            <div className="detailed-wanted-software-paragraph1">Clinical Applications</div>
                          </div>

                          <div className="detailed-wanted-software-second-columns-paragraphs">
                            <div className="detailed-wanted-software-paragraph">
                              {wantedSoftwareData.data.tax_purpose_use.name}
                            </div>
                            <div className="detailed-wanted-software-paragraph1">Purpose/Use</div>
                          </div>


                        </div>
                      </div>
                    </div>


                  </div>
                  <div className="detailed-wanted-software-service-card">
                    <div className="detailed-wanted-software-card-content">
                      <div className="detailed-wanted-software-card-content-child"></div>
                      {/* <div className="detailed-wanted-software-card-header">
                        <div className="detailed-wanted-software-price-tag">
                          <div className="detailed-wanted-software-badge-wrapper">
                            <div className="detailed-wanted-software-badge3">
                              <img
                                className="detailed-wanted-software-dot-icon3"
                                alt=""
                                src={dot1}
                              />

                              <div className="detailed-wanted-software-text29">Sale</div>
                            </div>
                          </div>
                          <div className="detailed-wanted-software-price-parent">
                            <div className="detailed-wanted-software-price">$9,999,999.99</div>
                            <div className="detailed-wanted-software-original-price">
                              <div className="detailed-wanted-software-discount">USD - Per Unit</div>
                            </div>
                          </div>
                          <div className="detailed-wanted-software-service-title-container">
                            <div className="detailed-wanted-software-service-title">Posted By Manufacturer</div>
                          </div>
                        </div>
                      </div> */}
                      <div className="detailed-wanted-software-card-footer">
                        <img
                          className="detailed-wanted-software-card-footer-child"
                          alt=""
                          src={wantedSoftwareData.data.profileImage}
                        />
                        {/* 
                        <div className="detailed-wanted-software-footer-divider">
                          <img
                            className="detailed-wanted-software-divider-icon4"
                            alt=""
                            src={divider2}
                          />
                        </div> */}
                      </div>
                      <div className="detailed-wanted-software-professional-info">
                        <div className="detailed-wanted-software-professional-details">
                          <div className="detailed-wanted-software-professional-actions">
                            <div className="detailed-wanted-software-benefits-container">
                              <div className="detailed-wanted-software-amlie-laurent">{wantedSoftwareData.data.wanted_software_user.displayName}</div>
                              {
                                wantedSoftwareData?.data?.wanted_software_user?.businessName &&
                                <div className="detailed-wanted-software-role">{wantedSoftwareData.data.wanted_software_user.businessName}</div>
                              }

                            </div>
                            {/* <div className="detailed-wanted-software-action-buttons">
                              <div className="detailed-wanted-software-contact-button">
                                <div className="detailed-wanted-software-message-button">
                                  <div className="detailed-wanted-software-navigation">
                                    <img
                                      className="detailed-wanted-software-bookmark-check-icon"
                                      alt=""
                                      src={bookmarkcheck}
                                    />
                                  </div>
                                  <div className="detailed-wanted-software-supporting-text10">
                                    Verified by Gerätor
                                  </div>
                                </div>
                              </div>
                              <div className="detailed-wanted-software-supporting-text11">
                                Adani Health conforms to Gerätor's requirements for
                                verification
                              </div>
                            </div> */}
                            <div className="detailed-wanted-software-favorite-button">
                              <div className="detailed-wanted-software-button-container2">
                                {!wantedSoftwareData.data.isAuthor && (
                                  <div className="detailed-device-buttonsbutton5" onClick={handlePrivateMessage}>
                                    <img
                                      className="detailed-device-filter-lines-icon"
                                      alt=""
                                      src={messagechatcircle}
                                    />
                                    <div className="detailed-device-text30">Private Message</div>
                                  </div>
                                )}
                                {/* Add To Contact */}
                                {!wantedSoftwareData.data.isAuthor && (
                                  (wantedSoftwareData.data.addedToContact) ?
                                    <div className="detailed-wanted-software-buttonsbutton6" onClick={() => handleRemoveContact(wantedSoftwareData.data.wanted_software_user.id)} style={{ cursor: 'pointer' }}>
                                      <img
                                        className="detailed-wanted-software-search-lg-icon"
                                        alt=""
                                        src={usercheck02}
                                      />
                                    </div>
                                    :
                                    <div className="detailed-wanted-software-buttonsbutton6-add" onClick={() => handleAddContact(wantedSoftwareData.data.wanted_software_user.id)} style={{ cursor: 'pointer' }}>
                                      <img
                                        className="detailed-wanted-software-search-lg-icon"
                                        alt=""
                                        src={add_contact}
                                      />
                                    </div>
                                )}

                              </div>
                            </div>
                          </div>
                          <div className="detailed-wanted-software-dropdown-menu" style={{ cursor: "pointer" }} onClick={toggleThreeDotsDropdownUser} ref={threeDotsRefUser}>
                            <div className="detailed-wanted-software-dropdown1">
                              <img
                                className="detailed-wanted-software-maximize-01-icon"
                                alt=""
                                src={dotsvertical}
                              />
                            </div>
                            {isThreeDotUserOpen && <ThreeDotsDetailPageUserComponent />}

                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <FooterDetailPage />
          </div>
        </div></>
    )
  } else {
    return <Loader />;
  }



}

