import React from 'react';

import { Helmet } from 'react-helmet-async';

const ShareThisMetaData = ({ title, description, image, url }) => {
  const siteName = "Gerator";

  return (
    <Helmet>
      {/* Open Graph Metadata */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={siteName} />

      {/* Twitter Card Metadata */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:site" content="@yourTwitterHandle" />
    </Helmet>
  );
};

export default ShareThisMetaData;
