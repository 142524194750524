// import { AddDevice } from "../screens/Device/AddDevice/AddDevice";
// import { ArchiveDevice } from "../screens/Device/ArchiveDevice/ArchiveDevice";
// import { ArchiveDeviceStatic } from "../screens/Device/ArchiveDevice/ArchiveDeviceStatic";
// import { DetailedDevice } from "../screens/Device/DetailedDevice/DetailedDevice";
import { AdminDashboard } from "../screens/Admin/AdminDashboard/AdminDashboard";

import { AdminEditDevice } from "../screens/Admin/AdminEditDevice/AdminEditDevice";
import { AdminEditJob } from "../screens/Admin/AdminEditJob/AdminEditJob";
import { AdminEditService } from "../screens/Admin/AdminEditService/AdminEditService";
import { AdminEditSoftware } from "../screens/Admin/AdminEditSoftware/AdminEditSoftware";
import { AdminEditSpare } from "../screens/Admin/AdminEditSpare/AdminEditSpare";
import { AdminEditWantedDevice } from "../screens/Admin/AdminEditWantedDevice/AdminEditWantedDevice";
import { AdminEditWantedService } from "../screens/Admin/AdminEditWantedService/AdminEditWantedService";
import { AdminEditWantedSoftware } from "../screens/Admin/AdminEditWantedSoftware/AdminEditWantedSoftware";
import { AdminEditWantedSpare } from "../screens/Admin/AdminEditWantedSpare/AdminEditWantedSpare";
import { AdminSettings } from "../screens/Settings/AdminSettings";
import { AdminUserProfile } from "../screens/UserProfile/AdminUserProfile";
import { AdminDetailedDevice } from "../screens/Admin/AdminDetailedDevice/AdminDetailedDevice";
import { AdminDetailedService } from "../screens/Admin/AdminDetailedService/AdminDetailedService"
import { AdminDetailedSoftware } from "../screens/Admin/AdminDetailedSoftware/AdminDetailedSoftware";
import { AdminDetailedSpare } from "../screens/Admin/AdminDetailedSpare/AdminDetailedSpare";
import { AdminDetailedJob } from "../screens/Admin/AdminDetailedJob/AdminDetailedJob";
import { AdminDetailedWantedDevice } from "../screens/Admin/AdminDetailedWantedPage/DetailedWantedDevice/AdminDetailedWantedDevice";
import { AdminDetailedWantedSpare } from "../screens/Admin/AdminDetailedWantedPage/DetailedWantedSpare/AdminDetailedWantedSpare";
import { AdminDetailedWantedService } from "../screens/Admin/AdminDetailedWantedPage/DetailedWantedService/AdminDetailedWantedService";
import { AdminDetailedWantedSoftware } from "../screens/Admin/AdminDetailedWantedPage/DetailedWantedSoftware/AdminDetailedWantedSoftware";




export const adminRoutes = [
  {
    path: "/admin/dashboard",
    element: <AdminDashboard />,
  },
  {
    path: "/admin/edit-profile",
    element: < AdminUserProfile />,
  },
  {
    path: "/admin/edit-device/:id",
    element: <AdminEditDevice />,
  },
  {
    path: "/admin/edit-job/:id",
    element: < AdminEditJob />,
  },
  {
    path: "/admin/edit-service/:id",
    element: < AdminEditService />,
  },
  {
    path: "/admin/edit-software/:id",
    element: < AdminEditSoftware />,
  },
  {
    path: "/admin/edit-spare/:id",
    element: < AdminEditSpare />,
  },
  {
    path: "/admin/edit-wanted-device/:id",
    element: < AdminEditWantedDevice />,
  },
  {
    path: "/admin/edit-wanted-service/:id",
    element: < AdminEditWantedService />,
  },
  {
    path: "/admin/edit-wanted-software/:id",
    element: < AdminEditWantedSoftware />,
  },
  {
    path: "/admin/edit-wanted-spare/:id",
    element: < AdminEditWantedSpare />,
  },
  {
    path: "/admin/device/:id/*",
    element: <AdminDetailedDevice />,
  },
  {
    path: "/admin/service/:id/*",
    element: <AdminDetailedService />,
  },
  {
    path: "/admin/software/:id/*",
    element: <AdminDetailedSoftware />,
  },
  {
    path: "/admin/spare/:id",
    element: <AdminDetailedSpare />,
  },
  {
    path: "/admin/job/:id/*",
    element: <AdminDetailedJob />,
  },
  {
    path: "/admin/wanted-device/:id",
    element: <AdminDetailedWantedDevice />,
  },
  {
    path: "/admin/wanted-spare/:id",
    element: <AdminDetailedWantedSpare />,
  },
  {
    path: "/admin/wanted-service/:id",
    element: <AdminDetailedWantedService />,
  },
  {
    path: "/admin/wanted-software/:id",
    element: <AdminDetailedWantedSoftware />,
  },



];
