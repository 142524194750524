import React from "react";
import "./Navbar1.css";
import gerator_logo from '../../icons/Gerator/gerator04-1@2x.png';
import bar_chart from '../../icons/Gerator/dashboard/barchart01.svg';
import dot_svg from '../../icons/Gerator/dashboard/-dot.svg';
import chevrondown from '../../icons/Gerator/dashboard/chevrondown.svg';
import dot_svg from '../../icons/Gerator/dashboard/-dot.svg';
import searchlg from '../../icons/Gerator/dashboard/searchlg.svg';
import dashboard_icon from '../../icons/Gerator/dashboard/icon.svg';
import group20 from '../../icons/Gerator/dashboard/group-20.svg';
import { getAuth, signOut } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import Cookies from 'js-cookie';
import { useGetUserQuery, useLazyGetProfileImageLinkQuery } from "../../api/Login&RegisterAPI";
import { logout } from "../../helper/UserHelper";
import { UserMenuComponent } from "../UserMenu/UserMenu";
import { useRef } from "react";
import HeaderNavDropdown from "../HeaderNavDropdown/HeaderNavDropdown";
import divider3 from "../../icons/Gerator/profile_settings/divider3.svg";
import { AdminUserMenuComponent } from "../UserMenu/AdminUserMenu";

export const AdminNavbar = ({ userData, navigate, profileImageLink }) => {
  // const navigate = useNavigate();





  // const [profileImageLink, setProfileImageLink] = useState("");
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isNewDropdownVisible, setNewDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const newdropdownRef = useRef(null);


  // const [triggerNewProfileImageLink, { data, isError, error, isLoading, isSuccess }] = useLazyGetProfileImageLinkQuery();

  // useEffect(() => {

  //   if (userMeta && userMeta.profileImage.expiresAt < Date.now() / 1000) {
  //     // Pre-signed URL has expired, trigger a new request to the backend
  //     triggerNewProfileImageLink();
  //     // window.location.reload();
  //     return;
  //   } else {
  //     // Use the existing pre-signed URL to display the profile image
  //     setProfileImageLink(userMeta.profileImage.url);
  //   }


  // }, [userMeta]);

  // useEffect(() => {
  //   if (data) {
  //     console.log(data);
  //   }

  //   // if (data.status) {
  //   //   window.location.reload();
  //   //   return;
  //   // }
  // }, [data, isSuccess])




  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  }

  const toggleNewDropdown = () => {
    setNewDropdownVisible(!isNewDropdownVisible);

  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (dropdownRef.current && !dropdownRef.current.contains(event.target))
      ) {
        setDropdownVisible(false);

      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (

        (newdropdownRef.current && !newdropdownRef.current.contains(event.target))
      ) {

        setNewDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  useEffect(() => {
    console.log(userData);
  }, [userData])

  // const logout = async () => {
  //   const auth = getAuth();
  //   try {
  //     //Signout from firebase
  //     await signOut(auth);

  //     // // Clear cookies
  //     // Cookies.remove('token', { secure: true, sameSite: 'strict' });
  //     // Cookies.remove('uid', { secure: true, sameSite: 'strict' });

  //     // Handle successful logout, like redirecting to the login page
  //     console.log("User logged out successfully");
  //     navigate("/sign-in");
  //   } catch (error) {
  //     console.error("Error during logout:", error);
  //   }
  // };
  // if (profileImageLink) {
  // }
  return (
    <>
      <div className="navbar-rectangle-parent">
        <div className="navbar-frame-child"></div>
        <div className="navbar-top-header">
           <Link to={"/admin/dashboard"} style={{cursor:"pointer"}}>
          <div className="navbar-branding">
            <div className="navbar-logo">
              <img
                className="navbar-gerator-04-1-icon"
                alt=""
                src={gerator_logo}
              />
            </div>
          </div>
          </Link>
          <div className="navbar-top-header-right">
            <div className="navbar-search-input">
              {/* <div className="navbar-navigation">
                <div className="navbar-nav-item-base" ref={newdropdownRef} >
                  <div className="navbar-content">
                    <img className="navbar-dot-icon" alt="" src={dot_svg} />

                    <img
                      className="navbar-bar-chart-01-icon"
                      alt=""
                      src={bar_chart}
                    />


                  </div>
                  <div className="navbar-badge">
                  <div className="navbar-text1">10</div>
                </div>
                <img
                  className="navbar-chevron-down-icon"
                  alt=""
                  src={chevrondown}
                />

                  <div className="navbar-text" onClick={toggleNewDropdown} style={{ cursor: 'pointer' }} >Buy</div>
                  <img
                    className="navbar-search-lg-icon"
                    alt=""
                    src={chevrondown}
                    onClick={toggleNewDropdown}
                    style={{ cursor: 'pointer' }}


                  />

                  {isNewDropdownVisible && (
                    <div className="new-dropdown-wrapper" >
                      <HeaderNavDropdown />
                    </div>
                  )}
                </div>
                <div className="navbar-nav-item-base" style={{ cursor: "pointer" }}>
                  <div className="navbar-content">
                    <img className="navbar-dot-icon" alt="" src="/dashboard/-dot.svg" />

                    <img
                      className="navbar-bar-chart-01-icon"
                      alt=""
                      src={bar_chart}
                    />

                    <div className="navbar-text2">Sell</div>
                  </div>
                  <div className="navbar-badge1">
                  <div className="navbar-text1">10</div>
                </div>
                <img
                  className="navbar-chevron-down-icon"
                  alt=""
                  src={chevrondown}
                />
                </div>

                <Link to={"/jobs"} style={{ color: "#475467" }}>
                  <div className="navbar-nav-item-base" style={{ cursor: "pointer" }}>
                    <div className="navbar-content">
                      <img className="navbar-dot-icon" alt="" src="/dashboard/-dot.svg" />

                      <img
                        className="navbar-bar-chart-01-icon"
                        alt=""
                        src={bar_chart}
                      />

                      <div className="navbar-text4">Jobs</div>
                    </div>
                    <div className="navbar-badge">
                  <div className="navbar-text1">10</div>
                </div>
                <img
                  className="navbar-chevron-down-icon"
                  alt=""
                  src={chevrondown}
                />
                  </div>
                </Link>
              </div> */}
            </div>
            <div className="navbar-input-with-label">

              <div className="navbar-label">Search</div>
              <div className="navbar-input">
                <div className="navbar-content3">
                  <img
                    className="navbar-search-lg-icon"
                    alt=""
                    src={searchlg}
                  />

                  <div className="navbar-search-placeholder">Search</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="navbar-search-input" >
          <div className="navbar-user-actions">
            <div className="navbar-search-input">
              <div className="navbar-nav-item-button" style={{ cursor: "pointer" }}>
                <img className="navbar-icon" alt="" src={dashboard_icon} />
              </div>
            </div>
            <div className="navbar-location-column-inner">
              <div className="navbar-nav-item-button" style={{ cursor: "pointer" }}>
                <img
                  className="navbar-nav-item-button-child"
                  alt=""
                  src={group20}
                />
              </div>
            </div>
            <div ref={dropdownRef} className="navbar-avatar" style={{
              backgroundImage: profileImageLink
                && `url(${profileImageLink})`
            }} onClick={toggleDropdown}>
              {isDropdownVisible && <AdminUserMenuComponent user={userData} profileImage={profileImageLink} />}
              <div className="navbar-contrast-border"></div>
            </div>
            {/* <div className="navbar-avatar  dropbtn">
            <div className="navbar-contrast-border"></div>
            <div className="dropdown-content">
              <a href="#">{userData?.displayName || 'Guest'}</a>
              <Link to={"/user-profile"}><span>Profile</span></Link>
              <a href="#" onClick={logout}>Logout</a>
            </div>
          </div> */}
          </div>
        </div>


      </div>

      {/* <section className="setting-divider-wrapper">
              <img
                className="setting-divider-icon"
                loading="lazy"
                alt=""
                src={divider3}
              />
            </section> */}
    </>
  )
}