import React, { useEffect, useRef, useState } from 'react';
import "./AddAddress.css"
import { Navbar1 } from '../../../components/Navbar1/Navbar1';
import { Footer } from '../../../components/Footer/Footer';
import { Sidebar } from '../../../components/Sidebar/Sidebar';
import { firestore, doc, getDoc, auth, onAuthStateChanged } from '../../Login/FirebaseClient';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { SideBarSubNormal } from '../../../components/SideBars-Sub/SideBarSubNormal/SideBarSubNormal';
import divider from '../../../icons/Gerator/profile_settings/divider.svg';
import homeline_1 from '../../../icons/Gerator/profile_settings/homeline-1.svg';
import chevronright from '../../../icons/Gerator/profile_settings/chevronright.svg';
import divider1 from '../../../icons/Gerator/profile_settings/divider1.svg';
import divider2 from '../../../icons/Gerator/profile_settings/divider2.svg';
import help_icon from '../../../icons/Gerator/profile_settings/help-icon.svg';
import placeholder from '../../../icons/Gerator/profile_settings/placeholder.svg';
import chevrondown from '../../../icons/Gerator/profile_settings/chevrondown.svg';
import placeholder3 from '../../../icons/Gerator/profile_settings/placeholder3.svg';
import MapComponent from '../../../components/MapComponent/MapComponent';
import { AddAddressForm } from '../../../components/AddAddressFormComponent/AddAddressForm';
import { useGetCountryTaxQuery } from '../../../api/TaxonomyFormAPI';
import { Provider } from './AddAddressFormContext';
import { useLazyFindGetMyBusinessQuery } from '../../../api/BusinessAPI';
import { NoBusinessFound } from '../../../components/NoBusiness/NoBusiness';
import { Loader } from '../../../components/Loader';
import { ErrorShowComponent } from '../../../components/ErrorShowComponent/ErrorShowComponent';
import { useGetPlansForAddressQuery } from '../../../api/PlanAPI';
import { useFindGetRelatedAddressRemoteQuery, useGetUserDashboardAddressesQuery, useLazyFindGetRelatedAddressRemoteQuery } from '../../../api/AddressAPI';
import { NoAccessAddress } from '../../../components/NoAccessAddress/NoAccessAddress';
import { useLazyGetProfileImageLinkQuery } from '../../../api/Login&RegisterAPI';
// import divider2 from '../../../icons/Gerator/profile_settings/divider2.svg';
// import divider2 from '../../../icons/Gerator/profile_settings/divider2.svg';
// import divider2 from '../../../icons/Gerator/profile_settings/divider2.svg';



export const AddAddress = () => {
  let content = '';
  const [profileImageLink, setProfileImageLink] = useState("");
  const [backendValidation, setBackendValidation] = useState([])
  const formikRef = useRef(null);
  const countryTax = useGetCountryTaxQuery();
  const [currentUsermeta, setCurrentUsermeta] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState({
    message: "",
    color: "",
  });
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState("");
  const [allTaxonomy, setAllTaxonomy] = useState({ country: countryTax });
  const [currentPlanAddressLimit, setCurrentPlanAddressLimit] = useState("");
  const [currentRemainingAddress, setCurrentRemainingAddress] = useState("");
  const [triggerGetBusiness, { data, isSuccess, error, isLoading, isError }] = useLazyFindGetMyBusinessQuery();

  const [triggerNewProfileImageLink] = useLazyGetProfileImageLinkQuery();

  const { data: planAddressData, isSuccess: isPlanAddressSuccess, error: planAddressError, isLoading: isPlanAddressLoading, isError: isPlanAddressError } = useGetPlansForAddressQuery();

  const [triggerFindGetAddress, { data: userDashboardAddressData, isSuccess: isUserDashboardAddressSuccess, isError: isUserDashboardAddressError, isLoading: isUserDashboardAddressLoading }] = useLazyFindGetRelatedAddressRemoteQuery();

  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {

        if (user) {
          // Get user to check wizardStep
          const userDocRef = doc(firestore, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);
          setCurrentUser(user);

          if (userDocSnapshot.exists()) {
            const usermeta = userDocSnapshot.data(); // Default to 1 if not set

            if (usermeta.userStatus == 2) {
              navigate('/account-disabled');
              return;
            }

            if (usermeta.userType == 5) {
              navigate('/access-denied');
              return;
            }
            if (usermeta.wizardStep !== 0) {
              navigate('/account-setup-check-email');
              return;
            }

            // await triggerGetCurrentPlan().unwrap();
            setCurrentUsermeta(usermeta);
            triggerGetBusiness();
            triggerFindGetAddress();
            // User is authenticated
          } else {
            content = <ErrorShowComponent message1={"Internal server error"} />
          }
        }
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });


    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);

  useEffect(() => {
    const refreshProfileImage = async () => {
      if (currentUsermeta && currentUsermeta.profileImage.expiresAt < Date.now() / 1000) {
        // Pre-signed URL has expired, trigger a new request to the backend
        const newImageData = await triggerNewProfileImageLink().unwrap();
        // Update the profile image link directly
        setProfileImageLink(newImageData.url);
      } else if (currentUsermeta && currentUsermeta.profileImage.url) {
        // Use the existing pre-signed URL to display the profile image
        setProfileImageLink(currentUsermeta.profileImage.url);
      }
    };

    refreshProfileImage();
  }, [currentUsermeta]);


  useEffect(() => {
    if (planAddressData && isPlanAddressSuccess && currentUsermeta) {
      console.log(planAddressData);
      const foundObject = planAddressData.data.find(item => item.id === currentUsermeta.userPlan);
      setCurrentPlanAddressLimit(foundObject.planAllowedAddress)
    }

  }, [isPlanAddressSuccess, planAddressData, currentUsermeta]);


  useEffect(() => {
    if (!userDashboardAddressData || !currentPlanAddressLimit) {
      return;
    }
    const currentAddressAdded = currentPlanAddressLimit - userDashboardAddressData.data.length;
    console.log(currentAddressAdded);

    if (currentAddressAdded === 0) {
      navigate('/dashboard/settings/address');
      return;
    }
    setCurrentRemainingAddress(currentAddressAdded)

  }, [userDashboardAddressData, currentPlanAddressLimit]);


  useEffect(() => {

    const allSuccess = [countryTax.isSuccess,].every(Boolean);


    if (allSuccess) {
      setAllTaxonomy({
        country: countryTax
      });
    }
  }, [
    countryTax.isSuccess, countryTax.data,
  ]);

  if (isError) {
    console.log(error);
    content = <ErrorShowComponent message1={error.data.message} />
  }


  if (isSuccess) {
    console.log(data);
    if (data.status) {
      content = <>
        <Provider value={{ allTaxonomy, backendValidation, setBackendValidation }}>
          <AddAddressForm formikRef={formikRef} setShowSuccessMessage={setShowSuccessMessage} currentUsermeta={currentUsermeta} />
        </Provider>
      </>
    } else {
      content = <NoBusinessFound path={'/dashboard/settings/business/add-business'} pathName={"Business"} />
    }
  }
  if (content !== "" && (currentRemainingAddress > 0) && profileImageLink) {
    return (
      <>
        <Helmet>
          <title>Settings - Add Address</title>
        </Helmet>
        <div className="address-form-dashboard-frame-layout-setti">
          <main className="address-form-dashboard-frame-layout-setti1">
            <div className="address-form-dashboard-frame-layout-setti-child"></div>

            <div className="address-form-frame-child"></div>

            <Navbar1 userData={currentUser} navigate={navigate} profileImageLink={profileImageLink} />


            <section className="address-form-divider-wrapper">
              <img
                className="address-form-divider-icon"
                loading="lazy"
                alt=""
                src={divider}
              />
            </section>
            <section className="address-form-sidebar-navigation-parent">
              <div className="address-form-sidebar-navigation">

                <Sidebar><SideBarSubNormal userData={currentUser} /></Sidebar>


              </div>
              <div className="address-form-main-parent">
                <div className="address-form-main">
                  <div className="address-form-header-section">
                    <div className="address-form-container">
                      <div className="address-form-page-header">
                        <div className="address-form-breadcrumbs">
                          <div className="address-form-tabs">
                            <div className="address-form-breadcrumb-button-base">
                              <img
                                className="address-form-search-lg-icon"
                                alt=""
                                src={homeline_1}
                              />
                            </div>
                            <img
                              className="address-form-chevron-right-icon"
                              alt=""
                              src={chevronright}
                            />

                            <div className="address-form-breadcrumb-button-base1">
                              <a className="address-form-text22">Dashboard</a>
                            </div>
                            <img
                              className="address-form-chevron-right-icon"
                              alt=""
                              src={chevronright}
                            />

                            {/* <div className="address-form-breadcrumb-button-base2">
                            <div className="address-form-text23">...</div>
                          </div>
                          <img
                            className="address-form-chevron-right-icon"
                            alt=""
                            src={chevronright}
                          /> */}

                            <div className="address-form-breadcrumb-button-base1">
                              <a className="address-form-text22">Settings</a>
                            </div>
                            <img
                              className="address-form-chevron-right-icon"
                              alt=""
                              src={chevronright}
                            />

                            <div className="address-form-breadcrumb-button-base1">
                              <a className="address-form-text22">Address</a>
                            </div>
                            <img
                              className="address-form-chevron-right-icon"
                              alt=""
                              src={chevronright}
                            />

                            <div className="address-form-breadcrumb-button-base4">
                              <a className="address-form-text25">Add Address</a>
                            </div>
                          </div>
                        </div>
                        <img
                          className="address-form-divider-icon1"
                          alt=""
                          src={divider1}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="address-form-main-child"></div>
                  <div className="address-form-main-item"></div>
                </div>
                <div className="address-form-main-wrapper">
                  <div className="address-form-main1">
                    <div className="address-form-header-content">
                      <div className="address-form-header-section1">
                        <div className="address-form-container1">
                          <div className="address-form-page-header1">
                            <div className="address-form-breadcrumbs1">
                              {/* <div className="address-form-tabs1">
                              <div className="address-form-breadcrumb-button-base">
                                <img
                                  className="address-form-search-lg-icon"
                                  alt=""
                                  src={homeline_1}
                                />
                              </div>
                              <img
                                className="address-form-chevron-right-icon"
                                alt=""
                                src={chevronright}
                              />
  
                              <div className="address-form-breadcrumb-button-base6">
                                <div className="address-form-text26">Settings</div>
                              </div>
                              <img
                                className="address-form-chevron-right-icon"
                                alt=""
                                src={chevronright}
                              />
  
                              <div className="address-form-breadcrumb-button-base7">
                                <div className="address-form-text23">...</div>
                              </div>
                              <img
                                className="address-form-chevron-right-icon"
                                alt=""
                                src={chevronright}
                              />
  
                              <div className="address-form-breadcrumb-button-base3">
                                <div className="address-form-text24">Another link</div>
                              </div>
                              <img
                                className="address-form-chevron-right-icon"
                                alt=""
                                src={chevronright}
                              />
  
                              <div className="address-form-breadcrumb-button-base4">
                                <div className="address-form-text29">Team</div>
                              </div>
                            </div> */}
                            </div>
                            <div className="address-form-content13">
                              <div className="address-form-text-and-supporting-text">
                                <h1 className="address-form-page-title">Add Address</h1>
                                <div className="address-form-supporting-text1">
                                  Accurate address improves your trust score
                                </div>
                              </div>
                              {/* <div className="address-form-actions">
                              <div className="address-form-button">
                                <img
                                  className="address-form-placeholder-icon"
                                  alt=""
                                  src={placeholder}
                                />
  
                                <div className="address-form-text-padding">
                                  <div className="address-form-title">Tertiary</div>
                                </div>
                                <img
                                  className="address-form-placeholder-icon"
                                  alt=""
                                  src={placeholder}
                                />
                              </div>
                              <div className="address-form-button1">
                                <img
                                  className="address-form-placeholder-icon"
                                  alt=""
                                  src="/profile_settings/placeholder1.svg"
                                />
  
                                <div className="address-form-text-padding">
                                  <div className="address-form-title">Secondary</div>
                                </div>
                                <img
                                  className="address-form-placeholder-icon"
                                  alt=""
                                  src="/profile_settings/placeholder1.svg"
                                />
                              </div>
                              <div className="address-form-button2">
                                <img
                                  className="address-form-placeholder-icon"
                                  alt=""
                                  src="/profile_settings/placeholder2.svg"
                                />
  
                                <div className="address-form-text-padding">
                                  <div className="address-form-title">Secondary</div>
                                </div>
                                <img
                                  className="address-form-placeholder-icon"
                                  alt=""
                                  src="/profile_settings/placeholder2.svg"
                                />
                              </div>
                              <div className="address-form-button3">
                                <img
                                  className="address-form-placeholder-icon"
                                  alt=""
                                  src={placeholder3}
                                />
  
                                <div className="address-form-text-padding">
                                  <div className="address-form-title">Primary</div>
                                </div>
                                <img
                                  className="address-form-placeholder-icon"
                                  alt=""
                                  src={placeholder3}
                                />
                              </div>
                            </div> */}
                              {/* <div className="address-form-input-dropdown">
                              <div className="address-form-input-with-label1">
                                <div className="address-form-label">Search</div>
                                <div className="address-form-input1">
                                  <div className="address-form-content3">
                                    <img
                                      className="address-form-search-lg-icon"
                                      alt=""
                                      src="/profile_settings/searchlg.svg"
                                    />
  
                                    <input
                                      className="address-form-text34"
                                      placeholder="Search"
                                      type="text"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="address-form-hint-text">
                                This is a hint text to help user.
                              </div>
                            </div> */}
                            </div>
                            <img
                              className="address-form-divider-icon2"
                              alt=""
                              src={divider2}
                            />
                          </div>
                          {/* <div className="address-form-horizontal-tabs">
                          <div className="address-form-tabs2">
                            <div className="address-form-tab-button-base1">
                              <a className="address-form-text35">Profile</a>
                            </div>
                            <div className="address-form-tab-button-base1">
                              <a className="address-form-text36">Business</a>
                            </div>
                            <div className="address-form-tab-button-base">
                              <a className="address-form-text25">Address</a>
                            </div>
                            <div className="address-form-tab-button-base3">
                              <div className="address-form-text38">Team</div>
                              <div className="address-form-badge10">
                                <div className="address-form-badge-count">4</div>
                              </div>
                            </div>
                            <div className="address-form-tab-button-base1">
                              <div className="address-form-text39">Plan</div>
                            </div>
                            <div className="address-form-tab-button-base3">
                              <a className="address-form-text40">Billing</a>
                              <div className="address-form-badge10">
                                <div className="address-form-badge-count">2</div>
                              </div>
                            </div>
                            <div className="address-form-tab-button-base1">
                              <a className="address-form-text40">Social</a>
                            </div>
                            <div className="address-form-tab-button-base7">
                              <a className="address-form-text43">Preferences</a>
                              <div className="address-form-badge10">
                                <div className="address-form-badge-count">2</div>
                              </div>
                            </div>
                            <div className="address-form-tab-button-base1">
                              <div className="address-form-text45">Integrations</div>
                            </div>
                            <div className="address-form-tab-button-base9">
                              <div className="address-form-text46">API</div>
                            </div>
                          </div>
                        </div> */}
                        </div>
                      </div>
                    </div>
                    {/* <div className="address-form-button4">
                <img
                  className="address-form-placeholder-icon"
                  alt=""
                  src={placeholder}
                />
  
                <div className="address-form-text-padding4">
                  <div className="address-form-text47">Tertiary</div>
                </div>
                <img
                  className="address-form-placeholder-icon"
                  alt=""
                  src={placeholder}
                />
              </div>
              <div className="address-form-button5">
                <img
                  className="address-form-placeholder-icon"
                  alt=""
                  src="/profile_settings/placeholder1.svg"
                />
  
                <div className="address-form-text-padding4">
                  <div className="address-form-text47">Secondary</div>
                </div>
                <img
                  className="address-form-placeholder-icon"
                  alt=""
                  src="/profile_settings/placeholder1.svg"
                />
              </div>
              <div className="address-form-dropdown">
                <img
                  className="address-form-dots-vertical-icon"
                  alt=""
                  src="/profile_settings/dotsvertical.svg"
                />
              </div> */}






                    {/* <Provider value={{ allTaxonomy, backendValidation, setBackendValidation }}>
                    <AddAddressForm formikRef={formikRef} setShowSuccessMessage={setShowSuccessMessage} />
                  </Provider> */}

                    {content}






























                    {showSuccessMessage.message && (
                      <div
                        style={{
                          fontSize: "14px",
                          color: showSuccessMessage.color,
                          padding: "15px 10px",
                          border: "1px solid " + showSuccessMessage.color,
                          borderRadius: "10px",
                          margin: "0 auto",
                        }}
                      >
                        {showSuccessMessage.message}
                      </div>
                    )}
                  </div>

                </div>
              </div>
            </section>
            <img className="address-form-divider-icon5" alt="" src={divider} />
            {/* <footer className="address-form-footer1"> */}
            <Footer />

            {/* <div className="address-form-container2">
          <div className="address-form-content47">
            <div className="address-form-footer-text">
              © Gerator Asia LLP. All rights reserved.
            </div>
            <div className="address-form-logo1">
              <div className="address-form-logo-wrapper1">
                <div className="address-form-logo-container">
                  <div className="address-form-footer-text">All prices are in USD</div>
                </div>
                <div className="address-form-logomark">
                  <div className="address-form-logomark1">
                    <img
                      className="address-form-gerator-10-2-icon"
                      loading="lazy"
                      alt=""
                      src="/profile_settings/gerator10-2@2x.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
            {/* </footer> */}

          </main>
        </div>
      </>

    )
  } else {
    return <Loader />
  }


}

