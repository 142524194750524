import React, { useRef } from 'react';
import "./LandingPage.css"
import { NavbarNotLoggedIn } from '../../components/NavbarNotLoggedIn/NavbarNotLoggedIn';
import { FooterAddForms } from '../../components/Footer/FooterAddForms/FooterAddForms';
import React, { useEffect, useState } from "react";

import { Loader } from "../../components/Loader";
import { Link, useNavigate, useParams } from "react-router-dom";

import divider4 from '../../icons/Gerator/archive_page/divider-4.svg';








import { useLazyGetProfileImageLinkQuery, useLazyGetUserQuery } from "../../api/Login&RegisterAPI";
import { firestore, doc, getDoc, auth, onAuthStateChanged } from '../Login/FirebaseClient';
import { decryptErrorData } from "../../helper/AESHelper";
// import { useDeleteDeviceMutation, useGetDevicesQuery, useGetDevicesWithoutLoginQuery, useLazyFilterDevicesQuery, useLazyFilterDevicesWithoutLoginQuery, useLazyGetDevicesQuery } from '../../api/DeviceAPI';
import { useGetAvailabilityTaxQuery, useGetClinicalApplicationsTaxQuery, useGetDeviceCategoryTaxQuery, useGetOemTaxQuery, useGetPhysicalLocationTaxQuery, useGetPurposeUseTaxQuery, useGetShippingTaxQuery, useGetStatusConditionTaxQuery, useGetTransactionTypeTaxQuery, useGetUnitOfMeasureDeviceSpareTaxQuery, useGetWarrantyTaxQuery, useGetYearOfManufactureTaxQuery, useGetYourRoleTaxQuery, useGetYearOfManufactureFilterTaxQuery, useGetClinicalApplicationsFilterTaxQuery, useGetPhysicalLocationFilterTaxQuery, useGetPurposeUseFilterTaxQuery, useGetCountryFilterTaxQuery } from "../../api/TaxonomyFormAPI";
import { NoListingFound } from '../../components/NoListing/NoListingFound';
import { NoListingFoundForFilter } from '../../components/NoListingFoundForFilter/NoListingFoundForFilter';
import * as Yup from 'yup';
import { Helmet } from 'react-helmet-async';

import { ErrorShowComponent } from '../../components/ErrorShowComponent/ErrorShowComponent';
import SkeletonListingLoaderComponent from '../../components/SkeletonListingLoaderComponent/SkeletonListingLoaderComponent';

import divider3 from "../../icons/Gerator/dashboard/divider3.svg";
import { SignInPopup } from '../SignInPopUp/SignInPopUp';

import {
  useLazyGetAllSuggestionForDeviceQuery,
  useLazyGetAllSuggestionForJobQuery,
  useLazyGetAllSuggestionForServiceQuery,
  useLazyGetAllSuggestionForSoftwareQuery,
  useLazyGetAllSuggestionForSpareQuery,
} from "../../api/Listing/ListingSuggestionAPI";
import { JobListingSuggestionComponent } from "../../components/ListingSuggestion/JobListingSuggestionComponent";
import { ListingSuggestionComponent } from "../../components/ListingSuggestion/ListingSuggestionComponent";
import { useLazyGetSparesQuery } from "../../api/SpareAPI";
import { useLazyGetServicesQuery } from "../../api/ServiceAPI";
import { useLazyGetSoftwaresQuery } from "../../api/SoftwareAPI";

import { useLazyFindGetDeviceQuery, useLazyGetDevicesQuery, } from "../../api/DeviceAPI";
import { useLazyFindGetSpareQuery, useLazyGetSparesQuery } from "../../api/SpareAPI";
import { useLazyFindGetSoftwareQuery, useLazyGetSoftwaresQuery } from "../../api/SoftwareAPI";
import { useLazyFindGetServiceQuery, useLazyGetServicesQuery } from "../../api/ServiceAPI";
import { useLazyFindGetJobQuery, useLazyGetJobsQuery } from "../../api/JobAPI";

import { useGetDevicesWithoutLoginQuery } from '../../api/DeviceAPI';
import { useGetServicesWithoutLoginQuery } from '../../api/ServiceAPI';
import { useGetSoftwaresWithoutLoginQuery } from '../../api/SoftwareAPI';
import { useGetSparesWithoutLoginQuery } from '../../api/SpareAPI';
import { useGetJobsWithoutLoginQuery } from '../../api/JobAPI';





export const LandingPage = () => {
  const { id } = useParams();

  const [offset, setOffset] = useState(0);
  const [sortBy, setSortBy] = useState('date_desc');
  const [activePostStatus, setActivePostStatus] = useState(null);


  const [offsetFilter, setOffsetFilter] = useState(0);
  const [sortByFilter, setSortByFilter] = useState('date_desc');
  const [activePostStatusFilter, setActivePostStatusFilter] = useState(null);
  // -------Sign in Pop-up

  const [isSignInOpen, setIsSignInOpen] = useState(false);

  // ----------------------------------- PAGE VAR USED MENTION HERE ----------------------------------
  //Main Var
  // let dataLength = 0;
  let content;
  const [authChecked, setAuthChecked] = useState(false);
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState("");
  const [showFilterLayout, setShowFilterLayout] = useState(false);
  const [profileImageLink, setProfileImageLink] = useState("");
  const [currentUserMeta, setCurrentUserMeta] = useState("");





  //flag var to show listing when loading complete when sekelteon layout is going to stop
  const [loadingComplete, setLoadingComplete] = useState(false);





  const [triggerNewProfileImageLink] = useLazyGetProfileImageLinkQuery();
  // const [queryRunInitiator, setQueryRunInitiator] = useState(false);














  //------------------------------------- QUERY USED ON THIS PAGE CODE --------------------------------

  // For user session management
  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
      }
      // Important: Mark that we've completed the auth check
      setAuthChecked(true);
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);

  const { data: LandingDevicedata, isSuccess: isLandingDeviceSuccess, isLoading: isLandingDeviceLoading, isError: isLandingDeviceError, isFetching: isLandingDeviceFetching } = useGetDevicesWithoutLoginQuery({ offset, sortBy, postStatus: activePostStatus }
    ,
    {
      // Refetch when component mounts or tab becomes active
      refetchOnMountOrArgChange: true
    });

  const { data: LandingSparedata, isSuccess: isLandingSpareSuccess, isLoading: isLandingSpareLoading, isError: isLandingSpareError, isFetching: isLandingSpareFetching } = useGetSparesWithoutLoginQuery({ offset, sortBy, postStatus: activePostStatus }
    ,
    {
      // Refetch when component mounts or tab becomes active
      refetchOnMountOrArgChange: true
    });


  const { data: LandingServicedata, isSuccess: isLandingServiceSuccess, isLoading: isLandingServiceLoading, isError: isLandingServiceError, isFetching: isLandingServiceFetching } = useGetServicesWithoutLoginQuery({ offset, sortBy, postStatus: activePostStatus }
    ,
    {
      // Refetch when component mounts or tab becomes active
      refetchOnMountOrArgChange: true
    });

  const { data: LandingSoftwaredata, isSuccess: isLandingSoftwareSuccess, isLoading: isLandingSoftwareLoading, isError: isLandingSoftwareError, isFetching: isLandingSoftwareFetching } = useGetSoftwaresWithoutLoginQuery({ offset, sortBy, postStatus: activePostStatus }
    ,
    {
      // Refetch when component mounts or tab becomes active
      refetchOnMountOrArgChange: true
    });

  const { data: LandingJobdata, isSuccess: isLandingJobSuccess, isLoading: isLandingJobLoading, isError: isLandingJobError, isFetching: isLandingJobFetching } = useGetJobsWithoutLoginQuery({ offset, sortBy, postStatus: activePostStatus }
    ,
    {
      // Refetch when component mounts or tab becomes active
      refetchOnMountOrArgChange: true
    });


  const carouselRef = useRef(null);

 


  const nextSlide = () => {
    if (carouselRef.current) {
      console.log("Next Slide Clicked");
      carouselRef.current.scrollBy({ left: 260, behavior: "smooth" });
    } else {
      console.log("CarouselRef is not available");
    }
  };
  
  const prevSlide = () => {
    if (carouselRef.current) {
      console.log("Prev Slide Clicked");
      carouselRef.current.scrollBy({ left: -260, behavior: "smooth" });
    } else {
      console.log("CarouselRef is not available");
    }
  };
  

  // const nextSlide = () => {
  //   if (carouselRef.current) {
  //     carouselRef.current.scrollBy({ left: 260, behavior: "smooth" }); // Scroll right
  //   }
  // };

  // const prevSlide = () => {
  //   if (carouselRef.current) {
  //     carouselRef.current.scrollBy({ left: -260, behavior: "smooth" }); // Scroll left
  //   }
  // };




  //Success true then pause to show sekelton layout
  useEffect(() => {
    if (isLandingDeviceSuccess
      , isLandingSpareSuccess, isLandingServiceSuccess, isLandingSoftwareSuccess, isLandingJobSuccess
    ) {


      const timer = setTimeout(() => {
        setLoadingComplete(true);
      }, 1000); // 1 second

      // Cleanup timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [isLandingDeviceSuccess
    , isLandingSpareSuccess, isLandingServiceSuccess, isLandingSoftwareSuccess, isLandingJobSuccess
  ]);





  if (isLandingDeviceError
    // , isLandingSpareError, isLandingServiceError, isLandingSoftwareError, isLandingJobError
  ) {
    content = <ErrorShowComponent message1={"There is an issue, while fetching data."} />
  }


  //Get all Devices
  if (isLandingDeviceLoading
    ||
    isLandingSpareLoading ||
    isLandingServiceLoading ||
    isLandingSoftwareLoading ||
    isLandingJobLoading
  ) {
    content = <SkeletonListingLoaderComponent />;
  }
  // else if (loadingComplete && isLandingDeviceSuccess && isLandingSpareSuccess && isLandingServiceSuccess && isLandingSoftwareSuccess && isLandingJobSuccess) 
  else if (
    loadingComplete &&
    (isLandingDeviceSuccess)
    ||
    (isLandingSpareSuccess) ||
    (isLandingServiceSuccess) ||
    (isLandingSoftwareSuccess) ||
    (isLandingJobSuccess)
  ) {


    content =
      (

        <>
          {/* <div className="landing-page-listing-image-grid3">
            <img
              className="landing-page-divider-icon11"
              alt=""
              src={divider3}
            />
          </div> */}

          {/* {isLandingDeviceSuccess && deviceSuggestionData?.deviceSuggestions.length > 0 && (
                                     <ListingSuggestionComponent
                                       cardData={deviceSuggestionData?.deviceSuggestions}
                                       categoryText={"device"}
                                       carouselRef={carouselRef}
                                       prevSlide={prevSlide}
                                       nextSlide={nextSlide}
                                     />
                                   )} */}

          {isLandingDeviceSuccess && (
            <ListingSuggestionComponent
              cardData={LandingDevicedata?.devices}
              categoryText={"device"}
              carouselRef={carouselRef}
              prevSlide={prevSlide}
              nextSlide={nextSlide}
            />
          )}

          <div className="landing-page-listing-image-grid3">
            <img
              className="landing-page-divider-icon11"
              alt=""
              src={divider3}
            />
          </div>

          {isLandingSpareSuccess && (
            <ListingSuggestionComponent
              cardData={LandingSparedata?.spares}
              categoryText={"spare"}
              carouselRef={carouselRef}
              prevSlide={prevSlide}
              nextSlide={nextSlide}
            />
          )}

          <div className="landing-page-listing-image-grid3">
            <img
              className="landing-page-divider-icon11"
              alt=""
              src={divider3}
            />
          </div>


          {isLandingServiceSuccess && (
            <ListingSuggestionComponent
              cardData={LandingServicedata?.services}
              categoryText={"service"}
              carouselRef={carouselRef}
              prevSlide={prevSlide}
              nextSlide={nextSlide}
            />
          )}


          <div className="landing-page-listing-image-grid3">
            <img
              className="landing-page-divider-icon11"
              alt=""
              src={divider3}
            />
          </div>


          {isLandingSoftwareSuccess && (
            <ListingSuggestionComponent
              cardData={LandingSoftwaredata?.softwares}
              categoryText={"software"}
              carouselRef={carouselRef}
              prevSlide={prevSlide}
              nextSlide={nextSlide}
            />
          )}

          <div className="landing-page-listing-image-grid3">
            <img
              className="landing-page-divider-icon11"
              alt=""
              src={divider3}
            />
          </div>


          {isLandingJobSuccess && (
            <ListingSuggestionComponent
              cardData={LandingJobdata?.jobs}
              categoryText={"job"}
              carouselRef={carouselRef}
              prevSlide={prevSlide}
              nextSlide={nextSlide}
            />
          )}

        </>



      )

    //   else {
    //     content = (

    //       <NoListingFound path="/add-device" pathName={"Device"} />

    //     )
    //   }
  } else if (!loadingComplete && isLandingDeviceSuccess
    // ,isLandingSpareSuccess,isLandingServiceSuccess,isLandingSoftwareSuccess,isLandingJobSuccess
  ) {
    content = <SkeletonListingLoaderComponent />; // Show loader until loadingComplete is true
  }

  if (!authChecked) {
    return <Loader />; // Show loader while checking auth
  } else {
    if (currentUser === null) {
      // Show component for non-authenticated users
      return (
        <>
          <Helmet>
            <title>Homepage</title>
          </Helmet>
          <div className="landing-page-listings-search-results-list">

            <NavbarNotLoggedIn />
            <img className="landing-page-divider-icon" alt="" src={divider3} />

            {/* <div className='landing-page-mid-container'> */}




              {/* <div className="landing-page-filters"> */}



                {/* <div className="landing-page-listing-container archive-device-listing-container-custom"> */}
                <div className="landing-page-listing-container">

                  {
                    <>
                      <div className="landing-page-listing-image-grid3">
                        <img
                          className="landing-page-divider-icon11"
                          alt=""
                          src={divider3}
                        />
                      </div>

                   
                      {content}

                   

                      <div className="landing-page-listing-image-grid3">
                        <img
                          className="landing-page-divider-icon11"
                          alt=""
                          src={divider3}
                        />
                      </div>
                  

                     


          
                    </>

                  }
                  <SignInPopup isOpen={isSignInOpen} onClose={() => setIsSignInOpen(false)} />
                </div>







              {/* </div> */}
            {/* </div> */}
            <img className="landing-page-divider-icon6" alt="" src={divider4} />

            <img className="landing-page-divider-icon7 archive-device1-divider-icon7-cstm" alt="" src={divider4} />





            <FooterAddForms />




          </div>
        </>


      )
    } else {
      // User is authenticated, navigate
      navigate('/dashboard');
      return null; // Return null while navigating
    }
  }





}

