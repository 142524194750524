import React from 'react';
import "./ThreeDotsListingComponent.css";
import divider from '../../icons/Gerator/dashboard/divider.svg';
import { Link } from 'react-router-dom';

export const ThreeDotsDetailPageUserComponent = ({ onLaunchReportAbuseClick,id, url,  isAddedToContact,handleAddContact, handleRemoveContact  }) => {
  // const handleOptionClick = (option) => {
  //   onOptionSelect(option); // Call the function from the parent with the selected option
  // };

  const handleLaunchReportAbuse = () => {
    console.log("Report Abuse Clicked");
    if (onLaunchReportAbuseClick) {
      onLaunchReportAbuseClick();
    }
  };

  return (
    <div className="three-dots-filter-dropdown-three-dots-filter-all-options-fixed">
      <section className="three-dots-filter-dropdown-menu-items">
        {/* <div className="three-dots-filter-dropdown-dropdown-list-item" >
          <div className="three-dots-filter-dropdown-content48">
            <div className="three-dots-filter-dropdown-icon-and-text">
              <Link className="three-dots-filter-dropdown-text247" to={`${url}`}>Add to Contacts</Link>
            </div>
          </div>
        </div> */}
          <div className="three-dots-filter-dropdown-dropdown-list-item">
          <div className="three-dots-filter-dropdown-content48">
            <div className="three-dots-filter-dropdown-icon-and-text">
              {isAddedToContact ? (
                <div 
                  className="three-dots-filter-dropdown-text247"
                  style={{ cursor: 'pointer' }} 
                  onClick={() => handleRemoveContact(id)}
                >
                  Remove from Contacts
                </div>
              ) : (
                <div 
                  className="three-dots-filter-dropdown-text247"
                  style={{ cursor: 'pointer' }} 
                  onClick={() => handleAddContact(id)}
                >
                  Add to Contacts
                </div>
              )}
            </div>
          </div>
        </div>
        
        <div className="three-dots-filter-dropdown-dropdown-list-item" >
          <div className="three-dots-filter-dropdown-content48">
            <div className="three-dots-filter-dropdown-icon-and-text">
              <div className="three-dots-filter-dropdown-text247"   
              style={{ cursor: 'pointer' }} 
                onClick={handleLaunchReportAbuse}>
                  
                  Report Abuse
                  
                  </div>
            </div>
          </div>
        </div>
        {/* <div className="three-dots-filter-dropdown-dropdown-list-item" >
          <div className="three-dots-filter-dropdown-content48">
            <div className="three-dots-filter-dropdown-icon-and-text">
              <Link className="three-dots-filter-dropdown-text247" to={`${url}`}>Archive</Link>
            </div>
          </div>
        </div> */}
        {/* <div className="three-dots-filter-dropdown-dropdown-list-item" >
          <div className="three-dots-filter-dropdown-content48">
            <div className="three-dots-filter-dropdown-icon-and-text">
              <div className="three-dots-filter-dropdown-text247">Price (high to low)</div>
            </div>
          </div>
        </div> */}
      </section>
    </div>
  )
}

