import React, { useEffect, useState, useRef } from "react";
import "../ChatPageComponent.css"; // Correct relative path
import chat_day_divider from "../../../icons/Gerator/chat_page/chat_day_divider.svg";


const MessageBody = ({ messages, currentUserId, selectedChat }) => {
  // const messagesEndRef = useRef(null); // Create a ref for the end of the message list
  const lastMessageRef = useRef(null);
  const [latestMessageTime, setLatestMessageTime] = useState(null);
  
  // Track the latest message timestamp before updating messages
  useEffect(() => {
    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
  
      // Check if the last message is truly new (based on time)
      if (!latestMessageTime || new Date(lastMessage.timestamp) > new Date(latestMessageTime)) {
        setLatestMessageTime(lastMessage.timestamp); // Update to new latest timestamp
  
        // Scroll only for new messages, not when older messages are loaded
        if (lastMessageRef.current) {
          lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  }, [messages, currentUserId]);
  
  // Auto-scroll to the bottom when messages update
  // useEffect(() => {
  //   if (messagesEndRef.current && currentUser) {
  //     messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [messages, currentUser]);

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const getDateLabel = (timestamp) => {
    const messageDate = new Date(timestamp);
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today to midnight

    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1); // Normalize yesterday to midnight

    if (messageDate.getTime() >= today.getTime()) return "Today";
    if (messageDate.getTime() >= yesterday.getTime()) return "Yesterday";

    return messageDate.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  // Ensure messages are sorted by timestamp (ascending order)
  const sortedMessages = [...messages].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

  let lastDate = null; // Tracks the last date divider

  // Process messages to include dividers
  const processedMessages = [];
  sortedMessages.forEach((message) => {
    const messageDate = new Date(message.timestamp).toDateString();
    if (messageDate !== lastDate) {
      processedMessages.push({ isDivider: true, text: getDateLabel(message.timestamp) });
      lastDate = messageDate;
    }
    processedMessages.push({ ...message, isDivider: false });
  });

  return (
    <div className="chatB-container1">
      <div className="chatB-messages-parent-owner">
        <div className="chatB-messages-owner">
          {processedMessages.map((item, index) =>
            item.isDivider ? (
              <div key={`divider-${index}`} className="chatB-divider-container">
                {/* <div className="chatB-divider-line"></div> */}
                  <img className="chatB-divider-line" src={chat_day_divider} alt="divider" />
                <div className="chatB-divider-label">{item.text}</div>
                  <img className="chatB-divider-line" src={chat_day_divider} alt="divider" />
                {/* <div className="chatB-divider-line"></div> */}
              </div>
            ) : (
              <div
              ref={index === processedMessages.length - 1 ? lastMessageRef : null}
                key={item.messageId || index}
                className={`${
                  item.sender === currentUserId
                    ? "chatB-row"
                    : "chatB-message12"
                }`}
              >
                {item.sender !== currentUserId && (
                  <img
                    className="chatB-avatar2"
                    src={selectedChat?.senderDetails?.profileImageLink || avatar2}
                    alt="Avatar"
                  />
                )}
                {item.sender === currentUserId ? (
                  <div className="chatB-content13">
                    <div className="chatB-name-and-time3">
                      <div className="chatB-single-message-username">You</div>
                    </div>
                    <div className="chatB-input5">
                      <div className="chatB-text31">{item.content}</div>
                      <div className="chatB-sub-text3">
                        {formatTime(item.timestamp)}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="chatB-content8">
                    <div className="chatB-name-and-time">
                      <div className="chatB-usernames">
                        {selectedChat.senderDetails.display_name}
                      </div>
                    </div>
                    <div className="chatB-input2">
                      <div className="chatB-receiever">{item.content}</div>
                      <div className="chatB-sub-text">
                        {formatTime(item.timestamp)}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )
          )}
          {/* <div ref={messagesEndRef} /> */}
        </div>
      </div>
    </div>
  );
};

export { MessageBody };


// import React, { useEffect, useState, useRef } from "react";
// import "../ChatPageComponent.css"; // Correct relative path

// const MessageBody = ({ messages, currentUserId, selectedChat }) => {
//   const messagesEndRef = useRef(null); // Create a ref for the end of the message list

//   // Auto-scroll to the bottom when messages update
//   useEffect(() => {
//     if (messagesEndRef.current && currentUserId) {
//       messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
//     }
//   }, [messages, currentUserId]); // Triggered when `messages` changes

//   const formatTime = (timestamp) => {
//     const date = new Date(timestamp);
//     const hours = date.getHours().toString().padStart(2, "0");
//     const minutes = date.getMinutes().toString().padStart(2, "0");
//     return `${hours}:${minutes}`;
//   };

//   const getDateLabel = (timestamp) => {
//     const messageDate = new Date(timestamp);
//     const today = new Date();
//     const yesterday = new Date(today);
//     yesterday.setDate(today.getDate() - 1);
    

    
//  console.log("MESSAGE DATE SIDEPANEL ", messageDate)
//  console.log("TODAY AND YESTERDAY: ", today, yesterday)
 
//     if (messageDate.toDateString() === today.toDateString()) return "Today";
//     else if (messageDate.toDateString() === yesterday.toDateString()) return "Yesterday";
//     else return messageDate;

//     // const daysDifference = Math.floor((today - messageDate) / (1000 * 60 * 60 * 24));
//     // if (daysDifference < 7) return messageDate.toLocaleDateString("en-US", { weekday: "long" });

//     // return messageDate.toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" });
//   };

//   let lastDate = null; // Tracks the date of the last processed message

//   return (
//     <div className="chatB-container1">
//       <div className="chatB-messages-parent">
//         <div className="chatB-messages">
//           {messages.map((message, index) => {
//             const messageDate = new Date(message.timestamp).toDateString();
//             const showDivider = messageDate !== lastDate; // Check if the date has changed
//             lastDate = messageDate; // Update the last date

//             return (
//               <React.Fragment key={index}>
//                 {/* Render the divider if the date has changed */}
//                 {showDivider && (
//                   <div className="chatB-divider-container">
//                     <div className="chatB-divider-line"></div>
//                     <div className="chatB-divider-label">{getDateLabel(message.timestamp)}</div>
//                     <div className="chatB-divider-line"></div>
//                   </div>
//                 )}
//                 <div
//                   className={`${
//                     message.sender === currentUserId
//                       ? "chatB-row"
//                       : "chatB-message12"
//                   }`}
//                 >
//                   {message.sender !== currentUserId && (
//                     <img
//                       className="chatB-avatar2"
//                       src={selectedChat?.senderDetails?.profileImageLink || avatar2}
//                       alt="Avatar"
//                     />
//                   )}
//                   {message.sender === currentUserId ? (
//                     <div className="chatB-content13">
//                       <div className="chatB-name-and-time3">
//                         <div className="chatB-single-message-username">You</div>
//                       </div>
//                       <div className="chatB-input5">
//                         <div className="chatB-text31">{message.content}</div>
//                         <div className="chatB-sub-text3">
//                           {formatTime(message.timestamp)}
//                         </div>
//                       </div>
//                     </div>
//                   ) : (
//                     <div className="chatB-content8">
//                       <div className="chatB-name-and-time">
//                         <div className="chatB-usernames">
//                           {selectedChat.senderDetails.display_name}
//                         </div>
//                       </div>
//                       <div className="chatB-input2">
//                         <div className="chatB-receiever">{message.content}</div>
//                         <div className="chatB-sub-text">
//                           {formatTime(message.timestamp)}
//                         </div>
//                       </div>
//                     </div>
//                   )}
//                 </div>
//               </React.Fragment>
//             );
//           })}
//           <div ref={messagesEndRef} />
//         </div>
//       </div>
//     </div>
//   );
// };

// export { MessageBody };
