import React from "react";
// import "./style.css";
import divider2 from '../../icons/Gerator/detailed_listing/divider2.svg';
import f8595f4b711e503bc72fe396e5043e0c2_7_2x from '../../icons/Gerator/detailed_listing/8595f4b711e503bc72fe396e5043e0c2-7@2x.png';

import { useAddRadarDeviceMutation, useDeleteRadarDeviceMutation } from "../../api/Radar/RadarDeviceAPI";
import TruncatedTextTooltip from "../TruncatedTextTooltip/TruncatedTextTooltip";
import { formatNumber, getCustomizedDate } from "../../helper/DeviceHelper";
import { Link } from "react-router-dom";


export const JobListingSuggestionComponent = ({ cardData }) => {

  // const [addToRadar] = useAddRadarDeviceMutation();

  // const [deleteFromRadar] = useDeleteRadarDeviceMutation();


  // // ------------------------------------ RADAR CODE START ---------------------------

  // const handleRemoveRadar = async (deviceId) => {
  //   await deleteFromRadar(deviceId);
  //   window.location.reload();
  // };

  // const handleAddRadar = async (deviceId) => {
  //   await addToRadar(deviceId);
  //   window.location.reload();
  // };


  // ----------------------------------- RADAR CODE END ---------------------------

  return (
    <>
      <div className="detailed-device-rectangle-parent4">
        <div className="detailed-device-frame-child6"></div>
        <div className="detailed-device-testimonial-header">
          <div className="detailed-device-header-content1">
            <div className="detailed-device-heading23">Jobs</div>
            <div className="detailed-device-testimonial-description">
              {/* <div className="detailed-device-description">View All</div> */}
              <Link to={`/jobs`} target="_blank" className="detailed-device-description">
                View All
              </Link>
            </div>
          </div>
        </div>
        <div className="detailed-device-testimonial-list">
          {cardData?.map((card, index) => (
            <div key={index}>
              <img
                className="detailed-device-divider-icon"
                alt=""
                src={divider2}
              />

              <div className="detailed-device-testimonial-divider">
                <div className="detailed-device-testimonial-item">
                  <div className="detailed-device-rectangle-parent5">
                    <div className="detailed-device-frame-child7"></div>
                    <img
                      className="detailed-device-f4b711e503bc72fe396e5043e0c2-7-icon"
                      alt=""
                      src={f8595f4b711e503bc72fe396e5043e0c2_7_2x}
                    />
                  </div>
                  <div className="detailed-device-testimonial-details">
                    <div className="detailed-device-client-name">
                      <Link to={`/job/${card.id}/${card.title}`} target="_blank" className="detailed-device-text42">
                        {card.title}
                      </Link> / {card.tax_engagement_type.name}
                    </div>
                    {/* <div className="detailed-device-heading24">Marketing Fusion SARL</div> */}
                    <Link className="detailed-device-heading24" to={`/business/${card.job_business.id}/${card.job_business.businessName}`} target="_blank">
                      {card.job_business.businessName}
                    </Link>
                  </div>
                </div>
              </div>

            </div>
          ))}
        </div>
      </div>
    </>
  );
};
