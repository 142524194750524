// import React from "react";
// import "../ChatPageComponent.css";

// import avatar2 from "../../../icons/Gerator/chat_page/avatar2.svg"; // Replace with correct paths
// import dotsvertical from "../../../icons/Gerator/chat_page/dotsvertical.svg";
// import searchlg from "../../../icons/Gerator/chat_page/searchlg.svg";

// const SidePanel = ({ LatestSenderProfiles = [], onSelectChat }) => {

//   // Handle chat selection and notify the parent component
//   const handleSelectChat = (message) => {
//     onSelectChat(message); // Notify the parent component
//   };

//   return (
//     <div className="chatB-side-panel">
//       {/* Header Section */}
//       <div className="chatB-card-header">
//         <div className="chatB-content5">
//           <div className="chatB-text-and-supporting-text">
//             <div className="chatB-text-and-badge">
//               <div className="chatB-chat-name-label">Chats</div>
//               <div className="chatB-badge10">
//                 <div className="chatB-text17">{LatestSenderProfiles.length}</div>
//               </div>
//             </div>
//             <div className="chatB-supporting-text2">
//               Select a conversation to view messages.
//             </div>
//           </div>
//         </div>
//         <div className="chatB-dropdown5">
//           <img className="chatB-dots-vertical-icon" alt="" src={dotsvertical} />
//         </div>
//         <hr className="chatB-divider-icon4" />
//       </div>

//       {/* Search Section */}
//       <div className="chatB-search-wrap">
//         <div className="chatB-input-dropdown">
//           <div className="chatB-input-with-label1">
//             <div className="chatB-label1">Search</div>
//             <div className="chatB-input1">
//               <div className="chatB-tabs">
//                 <img className="chatB-search-lg-icon" alt="" src={searchlg} />
//                 <input
//                   className="chatB-search-placeholder1"
//                   placeholder="Search chats"
//                   type="text"
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Chat List Section */}
//       <div className="chatB-message-list">
//         {LatestSenderProfiles.length > 0 ? (
//           LatestSenderProfiles.map((profile, index) => (
//             <div
//             key={profile.messageId}
//             onClick={() => handleSelectChat(profile)} // Call onSelectChat with the selected message
//             className="chat-item"
//           >
//               <div className="chatB-avatar-and-time">
//                 <div className="chatB-avatar-label-group">
//                   <img
//                     className="chatB-avatar2"
//                     src={profile?.senderDetails?.profileImageLink}
//                     alt="Avatar"
//                   />
//                   <div className="chatB-text-and-supporting-text1">
//                     <div className="chatB-message-sender-name">
//                       {profile?.senderDetails?.display_name || "Unknown User"}
//                     </div>
//                     <div className="chatB-supporting-text3">
//                       {profile?.timestamp
//                         ? new Date(profile.timestamp).toLocaleTimeString()
//                         : "Unknown Time"}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="chatB-message-preview-wrap">
//                 <div className="chatB-message-preview">
//                   {profile?.content || "No message"}
//                 </div>
//               </div>
//             </div>
//           ))
//         ) : (
//           <div className="chatB-no-messages">No messages to display</div>
//         )}
//       </div>
//     </div>
//   );
// };

// export { SidePanel };



import React, { useEffect, useState } from "react";
import "../ChatPageComponent.css";
import dot2 from "../../../icons/Gerator/chat_page/-dot2.svg";
import dot1 from "../../../icons/Gerator/chat_page/-dot1.svg";
import stage0 from "../../../icons/Gerator/chat_page/stage0.svg";

import stage1 from "../../../icons/Gerator/chat_page/stage0.svg";
import stage2 from "../../../icons/Gerator/chat_page/stage1.svg";
import stage3 from "../../../icons/Gerator/chat_page/stage2.svg";
import stage4 from "../../../icons/Gerator/chat_page/stage3.svg";
import stage5 from "../../../icons/Gerator/chat_page/stage4.svg";
import stage6 from "../../../icons/Gerator/chat_page/stage5.svg";
import stage7 from "../../../icons/Gerator/chat_page/stage6.svg";

import avatar2 from "../../../icons/Gerator/chat_page/avatar2.svg"; // Replace with correct paths
import dotsvertical from "../../../icons/Gerator/chat_page/dotsvertical.svg";
import searchlg from "../../../icons/Gerator/chat_page/searchlg.svg";
import { useFetchUnreadMessagesCountQuery, useLazyFetchUnreadMessagesCountQuery } from "../../../api/ChatAPI";

const SidePanel = ({ currentUserId, LatestSenderProfiles = [], onSelectChat }) => {
  const [fetchUnreadMessages, { data: unreadMessages }] = useLazyFetchUnreadMessagesCountQuery();
  const [localUnreadMessages, setLocalUnreadMessages] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  // Filter chat profiles based on search query
  const filteredProfiles = LatestSenderProfiles.filter((profile) =>
    (profile?.senderDetails?.display_name || "Unknown User")
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  // Handle chat selection and update unread messages
  const handleSelectChat = (message) => {
    onSelectChat(message);

    // Remove the unread count for the selected chat
    setLocalUnreadMessages((prev) => {
      const updated = { ...prev };
      delete updated[message.channelName];
      return updated;
    });
  };

  // Fetch unread messages count
  useEffect(() => {
    if (LatestSenderProfiles.length > 0 && currentUserId) {
      const senderProfilesChannelNames = LatestSenderProfiles.map((item) => item.channelName).filter(Boolean);
      fetchUnreadMessages({ user_id: currentUserId, channel_names: senderProfilesChannelNames });
    }
  }, [LatestSenderProfiles, currentUserId]);

  // Update local unread messages when API data arrives
  useEffect(() => {
    if (unreadMessages) {
      console.log("Unread Messages:", unreadMessages);
      setLocalUnreadMessages(unreadMessages);
    }
  }, [unreadMessages]);

  return (
    <div className="chatB-side-panel">
      {/* Header Section */}
      <div className="chatB-card-header">
        <div className="chatB-content5">
          <div className="chatB-text-and-supporting-text">
            <div className="chatB-text-and-badge">
              <div className="chatB-chat-name-label">Chats</div>
              <div className="chatB-badge10">
                <div className="chatB-text17">{LatestSenderProfiles.length}</div>
              </div>
            </div>
            <div className="chatB-supporting-text2">Select a conversation to view messages.</div>
          </div>
        </div>
        <div className="chatB-dropdown5">
          <img className="chatB-dots-vertical-icon" alt="" src={dotsvertical} />
        </div>
        <hr className="chatB-divider-icon4" />
      </div>

      {/* Search Section */}
      <div className="chatB-search-wrap">
        <div className="chatB-input-dropdown">
          <div className="chatB-input-with-label1">
            <div className="chatB-label1">Search</div>
            <div className="chatB-input1">
              <div className="chatB-tabs">
                <img className="chatB-search-lg-icon" alt="" src={searchlg} />
                <input
                  className="chatB-search-placeholder1"
                  placeholder="Search chats"
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Chat List Section */}
      <div className="chatB-message-list">
        {filteredProfiles.length > 0 ? (
          filteredProfiles.map((profile, index) => {
            const unreadCount = localUnreadMessages?.[profile.channelName] || 0;

            return (
              <div
                key={index}
                onClick={() => handleSelectChat(profile)}
                className="chat-item"
              >
                <div className="chatB-avatar-and-time">
                  <div className="chatB-avatar-label-group">
                    <img
                      className="chatB-avatar2"
                      src={profile?.senderDetails?.profileImageLink}
                      alt="Avatar"
                    />
                    <img
                      className="chatB-dot-icon5 chatB-dot-icon5-position"
                      alt=""
                      src={profile?.isOnline ? dot2 : dot1}
                    />
                    <div className="chatB-text-and-supporting-text1">
                      <div className="chatB-message-sender-name">
                        {profile?.senderDetails?.display_name || "Unknown User"}
                      </div>
                      <div className="chatB-supporting-text3">
                        {profile?.timestamp
                          ? new Date(profile.timestamp).toLocaleTimeString()
                          : "Unknown Time"}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="chatB-message-preview-wrap">
                  <div className="chatB-message-preview">
                    {profile?.content || "No message"}
                  </div>
                  {unreadCount > 0 && <span className="chatB-unread-count">{unreadCount}</span>}
                </div>
              </div>
            );
          })
        ) : (
          <div className="chatB-no-messages">No chats found</div>
        )}
      </div>
    </div>
  );
};

export { SidePanel };

// return (
//   <div className="chatB-side-panel">
//     {/* Chat List Section */}
//     <div className="chatB-message-list">
//       {LatestSenderProfiles.length > 0 ? (
//         LatestSenderProfiles.map((profile, index) => {
//           const unreadCount = unreadMessages?.[profile.channelName] || 0; // Get unread count for this chat

//           return (
//             <div
//               key={index}
//               onClick={() => handleSelectChat(profile)}
//               className="chat-item"
//             >
//               <div className="chatB-avatar-and-time">
//                 <div className="chatB-avatar-label-group">
//                   <img
//                     className="chatB-avatar2"
//                     src={profile?.senderDetails?.profileImageLink}
//                     alt="Avatar"
//                   />
//                   {profile?.isOnline ? (
//                     <img className="chatB-dot-icon5 chatB-dot-icon5-position" alt="" src={dot2} />
//                   ) : (
//                     <img className="chatB-dot-icon5 chatB-dot-icon5-position" alt="" src={dot1} />
//                   )}
//                   <div className="chatB-text-and-supporting-text1">
//                     <div className="chatB-message-sender-name">
//                       {profile?.senderDetails?.display_name || "Unknown User"}
//                     </div>
//                     <div className="chatB-supporting-text3">
//                       {profile?.timestamp
//                         ? new Date(profile.timestamp).toLocaleTimeString()
//                         : "Unknown Time"}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="chatB-message-preview-wrap">
//                 <div className="chatB-message-preview">
//                   {profile?.content || "No message"}
//                 </div>
//                 {unreadCount > 0 && (
//                   <span className="chatB-unread-count">{unreadCount}</span>
//                 )}
//               </div>
//             </div>
//           );
//         })
//       ) : (
//         <div className="chatB-no-messages">No chats found</div>
//       )}
//     </div>
//   </div>
// );
// };
