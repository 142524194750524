import React from 'react';
import "./FilterAdminBusinessComponent.css";
// import divider from '../../icons/Gerator/dashboard/divider.svg';

export const FilterAdminBusinessComponent = ({ onOptionSelect }) => {
  const handleOptionClick = (option) => {
    onOptionSelect(option); // Call the function from the parent with the selected option
  };
  return (
    <div className="filters-admin-business-sort-by-dropdown-filters-admin-business-sort-by-all-options-fixed">
      {/* <div className="filters-admin-business-sort-by-dropdown-dropdown-list-header">
        <div className="filters-admin-business-sort-by-dropdown-avatar-label-group">


        </div>
      </div> */}
      <section className="filters-admin-business-sort-by-dropdown-menu-items">
        <div className="filters-admin-business-sort-by-dropdown-dropdown-list-item" onClick={() => handleOptionClick({ label: 'Date (newest to oldest)', value: 'date_desc' })}>
          <div className="filters-admin-business-sort-by-dropdown-content48">
            <div className="filters-admin-business-sort-by-dropdown-icon-and-text">
              <div className="filters-admin-business-sort-by-dropdown-text247">Date (newest to oldest)</div>
            </div>
          </div>
        </div>
        <div className="filters-admin-business-sort-by-dropdown-dropdown-list-item" onClick={() => handleOptionClick({ label: 'Date (oldest to newest)', value: 'date_asc' },)}>
          <div className="filters-admin-business-sort-by-dropdown-content48">
            <div className="filters-admin-business-sort-by-dropdown-icon-and-text">
              <div className="filters-admin-business-sort-by-dropdown-text247">Date (oldest to newest)</div>
            </div>
          </div>
        </div>
        <div className="filters-admin-business-sort-by-dropdown-dropdown-list-item" onClick={() => handleOptionClick({ label: 'Title (a to z)', value: 'title_asc' },)}>
          <div className="filters-admin-business-sort-by-dropdown-content48">
            <div className="filters-admin-business-sort-by-dropdown-icon-and-text">
              <div className="filters-admin-business-sort-by-dropdown-text247">Title (a to z)</div>
            </div>
          </div>
        </div>
        <div className="filters-admin-business-sort-by-dropdown-dropdown-list-item" onClick={() => handleOptionClick({ label: 'Title (z to a)', value: 'title_desc' })}>
          <div className="filters-admin-business-sort-by-dropdown-content48">
            <div className="filters-admin-business-sort-by-dropdown-icon-and-text">
              <div className="filters-admin-business-sort-by-dropdown-text247">Title (z to a)</div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

