import React, { useEffect, useRef, useState } from 'react';
import "./AdminDetailedJob.css"
import { Navbar1 } from '../../../components/Navbar1/Navbar1';
import SlideShowDetailPageComponent from '../../../components/SlideShowDetailPageComponent/SlideShowDetailPageComponent';
import FooterDetailPage from '../../../components/Footer/FooterDetailPage/FooterDetailPage';
import { firestore, doc, getDoc, auth, onAuthStateChanged } from '../../Login/FirebaseClient';

import divider from '../../../icons/Gerator/detailed_listing/divider.svg';
import divider2 from '../../../icons/Gerator/detailed_listing/divider2.svg';
import divider3 from '../../../icons/Gerator/detailed_listing/divider3.svg';
import divider4 from '../../../icons/Gerator/detailed_listing/divider4.svg';

import chevronright from '../../../icons/Gerator/detailed_listing/chevronright.svg';

import rectangle_9_2x from '../../../icons/Gerator/detailed_listing/rectangle-9@2x.png';
import rectangle_5_2x from '../../../icons/Gerator/detailed_listing/rectangle-5@2x.png';
import rectangle_43_2x from '../../../icons/Gerator/detailed_listing/rectangle-43@2x.png';
import rectangle_95_2x from '../../../icons/Gerator/detailed_listing/rectangle-95@2x.png';
import rectangle_431_2x from '../../../icons/Gerator/detailed_listing/rectangle-431@2x.png';
import rectangle_196_2x from '../../../icons/Gerator/detailed_listing/rectangle-196@2x.png';

import arrowleft from '../../../icons/Gerator/detailed_listing/arrowleft.svg';
import arrowright from '../../../icons/Gerator/detailed_listing/arrowright.svg';

import breadcrumb_controls_2x from '../../../icons/Gerator/detailed_listing/breadcrumb-controls@2x.png';
import breadcrumb_controls1_2x from '../../../icons/Gerator/detailed_listing/breadcrumb-controls1@2x.png';
import breadcrumb_controls2_2x from '../../../icons/Gerator/detailed_listing/breadcrumb-controls2@2x.png';

import placeholder from '../../../icons/Gerator/detailed_listing/placeholder.svg';
import placeholder1 from '../../../icons/Gerator/detailed_listing/placeholder1.svg';
import placeholder2 from '../../../icons/Gerator/detailed_listing/placeholder2.svg';

import maximize01 from '../../../icons/Gerator/detailed_listing/maximize01.svg';
import markerpin02 from '../../../icons/Gerator/detailed_listing/markerpin02.svg';

import calendar from '../../../icons/Gerator/detailed_listing/calendar.svg';
import calendar1 from '../../../icons/Gerator/detailed_listing/calendar1.svg';

import facebook_1_1_2x from '../../../icons/Gerator/detailed_listing/facebook-1-1@2x.png';

import instagram_1_1_2x from '../../../icons/Gerator/detailed_listing/instagram-1-1@2x.png';
import whatsapp_1_2x from '../../../icons/Gerator/detailed_listing/whatsapp-1@2x.png';
import twitter_1_2x from '../../../icons/Gerator/detailed_listing/twitter-1@2x.png';
import linkedinlogo_1_2x from '../../../icons/Gerator/detailed_listing/linkedinlogo-1@2x.png';

import target_04 from "../../../icons/Gerator/dashboard/target_04.png"

import radar_1_1_2x from '../../../icons/Gerator/detailed_listing/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/detailed_listing/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/detailed_listing/magnifier-1@2x.png';
import share_1_2x from '../../../icons/Gerator/detailed_listing/share-1@2x.png';

import group_1010 from '../../../icons/Gerator/detailed_listing/group-1010.svg';
import group_78_2x from '../../../icons/Gerator/detailed_listing/group-78@2x.png';
import group_58_2x from '../../../icons/Gerator/detailed_listing/group-58@2x.png';
import group_781_2x from '../../../icons/Gerator/detailed_listing/group-781@2x.png';

import dotsvertical from '../../../icons/Gerator/detailed_listing/dotsvertical.svg';
import divider1 from '../../../icons/Gerator/detailed_listing/divider1.svg';
import group_351 from '../../../icons/Gerator/detailed_listing/group-351.svg';
import page from '../../../icons/Gerator/detailed_listing/page.svg';
import progress_fill from '../../../icons/Gerator/detailed_listing/progress-fill.svg';
import searchlg from '../../../icons/Gerator/detailed_listing/searchlg.svg';
import filterlines from '../../../icons/Gerator/detailed_listing/filterlines.svg';
import star_background from '../../../icons/Gerator/detailed_listing/star-background.svg';
import star_icon from '../../../icons/Gerator/detailed_listing/star-icon.svg';
import dot1 from '../../../icons/Gerator/detailed_listing/-dot1.svg';
import bookmarkcheck from '../../../icons/Gerator/detailed_listing/bookmarkcheck.svg';
import usercheck02 from '../../../icons/Gerator/detailed_listing/usercheck02.svg';
import f8595f4b711e503bc72fe396e5043e0c2_7_2x from '../../../icons/Gerator/detailed_listing/8595f4b711e503bc72fe396e5043e0c2-7@2x.png';
import messagedotscircle from '../../../icons/Gerator/detailed_listing/messagedotscircle.svg';
import markerpin021 from '../../../icons/Gerator/detailed_listing/markerpin021.svg';
import icon1 from '../../../icons/Gerator/detailed_listing/icon1.svg';
import login04 from '../../../icons/Gerator/detailed_listing/login04.svg';
import users01 from '../../../icons/Gerator/detailed_listing/users01.svg';
import add_contact from "../../../icons/Gerator/detailed_listing/add_contact.svg"


import { Loader } from "../../../components/Loader";
// import { useDispatch } from "react-redux";
import { useLazyFindGetJobQuery, useLazyGetJobsQuery } from "../../../api/JobAPI";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getCustomizedDate } from "../../../helper/JobHelper";
import { decryptErrorData } from '../../../helper/AESHelper';
import { useLazyGetProfileImageLinkQuery, useLazyGetUserQuery } from '../../../api/Login&RegisterAPI';
import { useAddContactMutation, useDeleteContactMutation } from '../../../api/ContactAPI';
import { useAddRadarJobMutation, useDeleteRadarJobMutation } from '../../../api/Radar/RadarJobAPI';
import MapLocationViewComponent from '../../../components/MapLocationViewComponent/MapLocationViewComponent';
import VideoSection from '../../../components/VideoSection/VideoSection';
import { formatNumber } from '../../../helper/DeviceHelper';
import { useAddViewJobMutation } from '../../../api/View/ViewJobAPI';

import follow from "../../../icons/Gerator/detailed_listing/follow.svg";

import ShareThisMetaData from '../../../components/ShareThisMetaData/ShareThisMetaData';


import { InlineShareButtons } from 'sharethis-reactjs';

import { InlineFollowButtons } from 'sharethis-reactjs';
import { RatingStarComponent } from '../../../components/review/RatingStarComponent/RatingStarComponent';
import { ReviewDescriptionComponent } from '../../../components/review/ReviewDescriptionComponent/ReviewDescriptionComponent';
import ReviewJobView from '../../../components/review/ReviewViewComponent/ReviewJobView';
import AddReviewJobForm from '../../../components/review/ReviewFormComponent/AddReviewJobForm';
import messagechatcircle from "../../../icons/Gerator/detailed_listing/messagechatcircle.svg";
import { ThreeDotsDetailPageComponent } from '../../../components/ThreeDotsListingComponent/ThreeDotsDetailPageComponent';
import { ThreeDotsDetailPageUserComponent } from '../../../components/ThreeDotsListingComponent/ThreeDotsDetailPageUserComponent';
import { createPortal } from 'react-dom';
import { ReportAbuseSlideOut } from '../../../components/ReportAbuseSlideOutForm/ReportAbuseSlideOutForm';
import { Helmet } from 'react-helmet-async';

import verified from "../../../icons/Gerator/profile_settings/bookmarkcheck.svg";
import unverified from "../../../icons/Gerator/profile_settings/bookmark-x.svg";
import awaiting from "../../../icons/Gerator/profile_settings/bookmark-minus.svg";
import { AdminNavbar } from '../../../components/Navbar1/AdminNavbar';
import { useSetArchiveJobMutation, useSetPublishJobMutation } from '../../../api/admin/AdminListingOperationAPI';

export const AdminDetailedJob = () => {
  const [isReportAbuseOpen, setIsReportAbuseOpen] = useState(false);
  const reportAbuseRef = useRef(null);


  const handleLaunchReportAbuseClick = () => {

    setIsReportAbuseOpen(true); // Open reportAbuse slide-out
  };


  const handleCloseReportAbuse = () => {
    setIsReportAbuseOpen(false); // Close reportAbuse slide-out
  };



  // Close reportAbuse and social share when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (reportAbuseRef.current && !reportAbuseRef.current.contains(event.target)) {
        setIsReportAbuseOpen(false); // Close reportAbuse if clicked outside
      }

    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [isThreeDotOpen, setIsThreeDotOpen] = useState(false);
  const threeDotsRef = useRef(null);
  const [isClickBlocked, setIsClickBlocked] = useState(false);

  // Toggle function for the three dots dropdown
  const toggleThreeDotsDropdown = () => {
    if (isClickBlocked) return; // Prevent rapid toggle

    setIsThreeDotOpen(prev => !prev); // Toggle the dropdown
    setIsClickBlocked(true);
    setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
  };

  // Handle clicks outside the three dots dropdown
  useEffect(() => {
    const handleThreeDotClickOutside = (event) => {
      const isLink = event.target.closest('a');
      if (isLink) return; // Allow navigation to happen

      if (threeDotsRef.current && !threeDotsRef.current.contains(event.target)) {
        setIsThreeDotOpen(false); // Close the dropdown when clicking outside
      }
    };

    document.addEventListener("mousedown", handleThreeDotClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleThreeDotClickOutside);
    };
  }, []);


  // For second dropdown (for user or other context)
  const [isThreeDotUserOpen, setIsThreeDotUserOpen] = useState(false);
  const threeDotsRefUser = useRef(null);
  const [isClickBlockedUser, setIsClickBlockedUser] = useState(false);

  // Toggle function for the second three dots dropdown
  const toggleThreeDotsDropdownUser = () => {
    if (isClickBlockedUser) return; // Prevent rapid toggle

    setIsThreeDotUserOpen(prev => !prev); // Toggle the dropdown
    setIsClickBlockedUser(true);
    setTimeout(() => setIsClickBlockedUser(false), 400); // Small delay to avoid reopening
  };

  // Handle clicks outside the second three dots dropdown
  useEffect(() => {
    const handleThreeDotClickOutsideUser = (event) => {
      const isLink = event.target.closest('a');
      if (isLink) return; // Allow navigation to happen

      if (threeDotsRefUser.current && !threeDotsRefUser.current.contains(event.target)) {
        setIsThreeDotUserOpen(false); // Close the dropdown when clicking outside
      }
    };

    document.addEventListener("mousedown", handleThreeDotClickOutsideUser);
    return () => {
      document.removeEventListener("mousedown", handleThreeDotClickOutsideUser);
    };
  }, []);


  const formikRef = useRef(null);


  const [showReply, setShowReply] = useState(false);
  const [replyText, setReplyText] = useState('');

  const maxLength = 500; // Maximum character count

  const handleReplyClick = () => {
    setShowReply(!showReply); // Toggle text area visibility
  };

  const handleChange = (event) => {
    if (event.target.value.length <= maxLength) {
      setReplyText(event.target.value); // Update reply text
    }
  };

  const handleSubmit = () => {
    // Handle reply submission here
    console.log('Reply submitted:', replyText);
    setShowReply(false); // Hide the text area after submission
  };

  const remainingChars = maxLength - replyText.length;
  const warningClass = remainingChars <= 50 ? 'warning' : '';


  const [activeSection, setActiveSection] = useState(null); // "share", "follow", or null

  const handleShareButtonClick = () => {
    setActiveSection((prevState) => (prevState === 'share' ? null : 'share')); // Toggle or close
  };

  const handleFollowButtonClick = () => {
    setActiveSection((prevState) => (prevState === 'follow' ? null : 'follow')); // Toggle or close
  };


  const { id } = useParams();
  let content;
  const [currentUser, setCurrentUser] = useState("");
  const navigate = useNavigate();
  const [profileImageLink, setProfileImageLink] = useState("");
  const [currentUsermeta, setCurrentUserMeta] = useState("");

  const [triggerNewProfileImageLink] = useLazyGetProfileImageLinkQuery();


  // const [triggerGetUser, { data, isSuccess, error, isLoading, isError }] = useLazyGetUserQuery();
  const [triggerGetJob, { data: jobData,
    isLoading: isJobLoading,
    isSuccess: isJobSuccess,
    isError: isJobError,
    error: jobError }] = useLazyFindGetJobQuery(id);

  const [addViewJob, { data: jobViewData,
    isLoading: isViewJobLoading,
    isSuccess: isViewJobSuccess,
    isError: isViewJobError,
    error: jobViewError }] = useAddViewJobMutation();


  const [addToContact, {
    isLoading: isContactLoading,
    isSuccess: isContactSuccess,
    isError: isContactError,
    error: contactError }] = useAddContactMutation();

  const [deleteFromContact] = useDeleteContactMutation();

  // Radar query delete
  const [deleteFromRadar] = useDeleteRadarJobMutation();

  // Radar query delete
  const [addToRadar] = useAddRadarJobMutation();

  // Listing Operation
  const [setToJobArchive] = useSetArchiveJobMutation();

  const [setToJobPublish] = useSetPublishJobMutation();


  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {

        if (user) {
          // Get user to check wizardStep
          const userDocRef = doc(firestore, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);
          setCurrentUser(user);

          if (userDocSnapshot.exists()) {
            const usermeta = userDocSnapshot.data(); // Default to 1 if not set

            if (usermeta.userStatus == 2) {
              navigate('/account-disabled');
              return;
            }

            if (usermeta.userType !== 5) {
              navigate('/access-denied');
              return;
            }

            if (usermeta.wizardStep !== 0) {
              navigate('/account-setup-check-email');
              return;
            }

            // await triggerGetCurrentPlan().unwrap();
            setCurrentUserMeta(usermeta);
            triggerGetJob(id);
            // User is authenticated
          } else {
            content = <ErrorShowComponent message1={"Internal server error"} />
          }
        }
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });


    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);


  //Profile image load useEffect
  useEffect(() => {
    const refreshProfileImage = async () => {
      if (currentUsermeta && currentUsermeta.profileImage?.expiresAt < Date.now() / 1000) {
        // Pre-signed URL has expired, trigger a new request to the backend
        const newImageData = await triggerNewProfileImageLink().unwrap();
        // Update the profile image link directly
        setProfileImageLink(newImageData.url);
      } else if (currentUsermeta && currentUsermeta.profileImage?.url) {
        // Use the existing pre-signed URL to display the profile image
        setProfileImageLink(currentUsermeta.profileImage.url);
      }
    };

    refreshProfileImage();
  }, [currentUsermeta]);


  // // Trigger the get wanted job query only after user data is successfully fetched
  useEffect(() => {
    if (isJobSuccess && jobData) {
      addViewJob(id);
      console.log('added view data');
    }
  }, [isJobSuccess, jobData]);

  useEffect(() => {
    if (jobViewData) {
      console.log(jobViewData);
    }
  }, [jobViewData]);

  const handleRemoveRadar = async (jobId) => {
    await deleteFromRadar(jobId);
    window.location.reload();
  };

  const handleAddRadar = async (jobId) => {
    await addToRadar(jobId);
    window.location.reload();
  };

  const handleRemoveContact = async (jobId) => {
    const result = await deleteFromContact(jobId);
    if (result.data) {
      // If successful, reload the page
      window.location.reload();
    } else if (result.error) {
      console.log(result.error);
    }
  };

  const handleAddContact = async (jobId) => {
    const result = await addToContact(jobId);
    if (result.data) {
      // If successful, reload the page
      window.location.reload();
    } else if (result.error) {
      console.log(result.error);
    }
  };


  const handleSetArchive = async (e) => {
    let response;
    e.preventDefault();
    e.stopPropagation();

    try {
      if (!id) {
        console.error('No post ID provided');
        return;
      }

      response = await setToJobArchive({ id }).unwrap();

      if (response?.status) {
        // onClose();
        window.location.reload();
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleSetPublish = async (e) => {
    let response;
    e.preventDefault();
    e.stopPropagation();

    try {
      if (!id) {
        console.error('No post ID provided');
        return;
      }

      response = await setToJobPublish({ id }).unwrap();

      // console.log('Updating status for post:', postId);
      // const response = await setToWantedDevicePublish({ id: postId }).unwrap();
      // console.log('Update response:', response);

      if (response.status) {
        window.location.reload();
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };


  // To show error when adding contact
  useEffect(() => {
    // const { status } = contactError;
    // console.log(contactError);

    if (contactError?.status === 405)
      console.log(contactError.data.message);
    else
      console.log("Internal server error");
  }, [isContactError])

  const handlePrivateMessage = () => {
    const jobId = jobData.data.id; // Use the appropriate ID for the chat
    handleAddRadar(jobId);
    navigate(`/chat/offered/Job/${jobId}`); // Replace `/chat/:id` with the correct route for your chat page
  };


  if (isJobSuccess) {
    return (
      <>
        <Helmet>
          <title>Job</title>
        </Helmet>
        <ShareThisMetaData
          title={`Discover ${jobData.data.title} on Gerator.io!`}
          description={`Check out ${jobData.data.title} – a top listing on Gerator.io!`}
          // image={deviceData.data.galleryImages[0]}
          url={window.location.href}
        />
        <div className="admin-detailed-job-listings-detailed-page-devic">
          <div className="admin-detailed-job-rectangle-parent">
            <div className="admin-detailed-job-frame-child"></div>

            <AdminNavbar userData={currentUser} navigate={navigate} profileImageLink={profileImageLink} />

            <img className="admin-detailed-job-divider-icon" alt="" src={divider} />

            {/* <div className="admin-detailed-job-breadcrumb-button-base">
            <div className="admin-detailed-job-text6">...</div>
          </div>
          <img
            className="admin-detailed-job-chevron-right-icon"
            alt=""
            src={chevronright}
          />

          <div className="admin-detailed-job-breadcrumb-button-base1">
            <div className="admin-detailed-job-text7">Another link</div>
          </div>
          <img
            className="admin-detailed-job-chevron-right-icon1"
            alt=""
            src={chevronright}
          /> */}

            <div className="admin-detailed-job-frame-parent">
              <div className="admin-detailed-job-breadcrumb-container-parent">

                {/* <div className="admin-detailed-job-breadcrumb-container">
                <div className="admin-detailed-job-breadcrumb-controls">
                  <img
                    className="admin-detailed-job-breadcrumb-controls-child"
                    alt=""
                    src="/detailed_listing/rectangle-5@2x.png"
                  />
  
                  <div className="admin-detailed-job-testiomonial-carousel-arrow">
                    <img
                      className="admin-detailed-job-arrow-left-icon"
                      alt=""
                      src={arrowleft}
                    />
                  </div>
                </div>
                <img
                  className="admin-detailed-job-breadcrumb-controls-icon"
                  alt=""
                  src="/detailed_listing/breadcrumb-controls@2x.png"
                />
  
                <img
                  className="admin-detailed-job-breadcrumb-controls-icon"
                  alt=""
                  src="/detailed_listing/breadcrumb-controls1@2x.png"
                />
  
                <img
                  className="admin-detailed-job-breadcrumb-controls-icon"
                  alt=""
                  src="/detailed_listing/breadcrumb-controls2@2x.png"
                />
  
                <div className="admin-detailed-job-breadcrumb-controls1">
                  <img
                    className="admin-detailed-job-breadcrumb-controls-item"
                    alt=""
                    src="/detailed_listing/rectangle-9@2x.png"
                  />
  
                  <div className="admin-detailed-job-buttonsbutton">
                    <img
                      className="admin-detailed-job-chevron-down-icon"
                      alt=""
                      src="/detailed_listing/placeholder.svg"
                    />
  
                    <div className="admin-detailed-job-navigation">
                      <img
                        className="admin-detailed-job-maximize-01-icon"
                        alt=""
                        src="/detailed_listing/maximize01.svg"
                      />
                    </div>
                    <div className="admin-detailed-job-text8">See all Photos</div>
                    <img
                      className="admin-detailed-job-chevron-down-icon"
                      alt=""
                      src="/detailed_listing/placeholder.svg"
                    />
                  </div>
                  <div className="admin-detailed-job-testiomonial-carousel-arrow1">
                    <img
                      className="admin-detailed-job-arrow-left-icon"
                      alt=""
                      src={arrowright}
                    />
                  </div>
                </div>
              </div> */}
                <div className="admin-detailed-job-breadcrumb">
                  <div className="admin-detailed-job-breadcrumb-items">
                    <div className="admin-detailed-job-breadcrumb-button-base2">
                      <div className="admin-detailed-job-text9">Dashboard</div>
                    </div>
                    <div className="admin-detailed-job-chevron-container">
                      <img
                        className="admin-detailed-job-chevron-right-icon2"
                        alt=""
                        src={chevronright}
                      />
                    </div>
                    <div className="admin-detailed-job-breadcrumb-button-base3">
                      <div className="admin-detailed-job-text10">Job</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="admin-detailed-job-listing-content-wrapper">
                <div className="admin-detailed-job-listing-content">
                  <div className="admin-detailed-job-description-block-parent">
                    <div className="admin-detailed-job-description-block">
                      <div className="admin-detailed-job-description-heading">
                        {jobData.data.title}
                      </div>
                      <div className="admin-detailed-job-listing-details">
                        <div className="admin-detailed-job-detail-container">
                          <div className="admin-detailed-job-details-content">
                            <div className="admin-detailed-job-details-row">
                              <div className="admin-detailed-job-detail-items">
                                <img
                                  className="admin-detailed-job-marker-pin-02-icon"
                                  alt=""
                                  src={markerpin02}
                                />
                                {jobData.data.job_address.tax_address_type
                                  .slug === "address" ?
                                  <div className="admin-detailed-job-supporting-text">{jobData.data.job_address.addressTownCity},  {jobData.data.job_address.tax_country.name}</div>
                                  :
                                  <div className="admin-detailed-job-supporting-text">{jobData.data.job_address.tax_address_type
                                    .name}</div>
                                }
                              </div>
                              <div className="admin-detailed-job-detail-items">
                                <img
                                  className="admin-detailed-job-marker-pin-02-icon"
                                  alt=""
                                  src={calendar}
                                />

                                <div className="admin-detailed-job-supporting-text">{getCustomizedDate(jobData.data.createdAt)}</div>
                              </div>
                            </div>
                            <div className="admin-detailed-job-contact-details">
                              <div className="admin-detailed-job-contact-name-wrapper">
                                <div className="admin-detailed-job-contact-name"> {jobData.data.totalRatingAvg ? `${jobData.data.totalRatingAvg}/5` : "0/5"}</div>
                              </div>
                              <div className="admin-detailed-job-contact-role">
                                <div className="admin-detailed-job-supporting-text2">
                                {jobData.data.totalReviews} {jobData.data.totalReviews === 0 || jobData.data.totalReviews === 1 ? "review" : "reviews"}

                                  </div>
                              </div>
                              {/* <div className="admin-detailed-job-social-links">
                              <div className="admin-detailed-job-rectangle-container">
                                <div className="admin-detailed-job-frame-inner"></div>
                                <div className="admin-detailed-job-icon-backgrounds-parent">
                                  <div className="admin-detailed-job-icon-backgrounds"></div>
                                  <img
                                    className="admin-detailed-job-whatsapp-1-icon"
                                    alt=""
                                    src={facebook_1_1_2x}
                                  />
                                </div>
                                <div className="admin-detailed-job-icon-backgrounds-parent">
                                  <div className="admin-detailed-job-ellipse-div"></div>
                                  <img
                                    className="admin-detailed-job-whatsapp-1-icon"
                                    alt=""
                                    src={instagram_1_1_2x}
                                  />
                                </div>
                                <div className="admin-detailed-job-icon-backgrounds-parent">
                                  <div className="admin-detailed-job-frame-child1"></div>
                                  <img
                                    className="admin-detailed-job-whatsapp-1-icon"
                                    alt=""
                                    src={whatsapp_1_2x}
                                  />
                                </div>
                                <div className="admin-detailed-job-icon-backgrounds-parent">
                                  <div className="admin-detailed-job-icon-backgrounds"></div>
                                  <img
                                    className="admin-detailed-job-whatsapp-1-icon"
                                    alt=""
                                    src={twitter_1_2x}
                                  />
                                </div>
                                <div className="admin-detailed-job-icon-backgrounds-parent">
                                  <div className="admin-detailed-job-ellipse-div"></div>
                                  <img
                                    className="admin-detailed-job-whatsapp-1-icon"
                                    alt=""
                                    src={linkedinlogo_1_2x}
                                  />
                                </div>
                              </div>
                            </div> */}
                            </div>
                          </div>
                          <div className="admin-detailed-job-button-row">

                            {/* {!jobData.data.isAuthor && (
                            jobData.data.addedToRadar ? (

                                <div className="admin-detailed-job-buttonsbutton1" onClick={() => handleRemoveRadar(jobData.data.id)} style={{ cursor: "pointer" }}>
                                  <img
                                    className="admin-detailed-job-magnifier-1-icon"
                                    alt=""
                                    src={target_04}
                                  />
                                  <div className="admin-detailed-job-text-padding">
                                    <div className="admin-detailed-job-text11">Added To Radar</div>
                                  </div>

                                </div>
                              ) : (
                                <div className="admin-detailed-job-buttonsbutton1" onClick={() => handleAddRadar(jobData.data.id)} style={{ cursor: "pointer" }}>
                                  <img
                                    className="admin-detailed-job-magnifier-1-icon"
                                    alt=""
                                    src={radar_1_1_2x}
                                  />

                                <div className="admin-detailed-job-text-padding">
                                  <div className="admin-detailed-job-text11">Add To Radar</div>
                                </div>
                              </div>
                            )
                          )} */}
                            {/* <div className="admin-detailed-job-buttonsbutton2">
                            <img
                              className="admin-detailed-job-placeholder-icon4"
                              alt=""
                              src={placeholder1}
                            />

                            <img
                              className="admin-detailed-job-magnifier-1-icon"
                              alt=""
                              src={deal_2_1_2x}
                            />

                            <img
                              className="admin-detailed-job-placeholder-icon4"
                              alt=""
                              src={placeholder1}
                            />
                          </div>
                          <div className="admin-detailed-job-buttonsbutton2">
                            <img
                              className="admin-detailed-job-placeholder-icon4"
                              alt=""
                              src={placeholder1}
                            />

                            <img
                              className="admin-detailed-job-magnifier-1-icon"
                              alt=""
                              src={magnifier_1_2x}
                            />

                            <img
                              className="admin-detailed-job-placeholder-icon4"
                              alt=""
                              src={placeholder1}
                            />
                          </div> */}
                            {/* <div className='detailed-job-sharethis-parent'>

                              <div className="admin-detailed-job-buttonsbutton2" onClick={handleShareButtonClick} style={{ cursor: 'pointer' }}>
                                <img
                                  className="admin-detailed-job-placeholder-icon4"
                                  alt=""
                                  src={placeholder1}
                                />

                                <img
                                  className="admin-detailed-job-magnifier-1-icon"
                                  alt=""
                                  src={share_1_2x}
                                />

                                <img
                                  className="admin-detailed-job-placeholder-icon4"
                                  alt=""
                                  src={placeholder1}
                                />



                              </div>

                              {activeSection === 'share' && (
                                <div className='detailed-job-sharethis'>
                                  <InlineShareButtons
                                    config={{
                                      alignment: 'center',
                                      color: 'black',
                                      enabled: true,
                                      font_size: 14,
                                      labels: 'cta',
                                      language: 'en',
                                      networks: ["twitter", "whatsapp", "linkedin", "email", "facebook"
                                        , "flipboard"
                                        , "google"
                                        , "digg"
                                        , "livejournal"
                                        , "mailru"
                                        , "meneame"
                                        , "messenger"
                                        , "oknoklassniki"
                                        , "pinterest"
                                        , "print"
                                        , "reddit"
                                        , "sharethis"
                                        , "sms"
                                        , "stumbleupon"
                                        , "tumblr"
                                        , "blogger", "vk", "wechat", "weibo", "delicious", "xing"],
                                      padding: 12,
                                      radius: 4,
                                      show_total: false,
                                      size: 34,
                                      // url: "https://gerator.io/", // Use the current page URL dynamically
                                      // image: 'https://bit.ly/2CMhCMC',
                                      // description: 'Check out this amazing content!',
                                      // title: 'Custom Share Title',
                                    }}
                                  />
                                </div>
                              )}

                          </div > */}


                            {/* <img
                                                     className="admin-detailed-job-button-row-child"
                                                     alt=""
                                                     src={group_1010}
                                                   /> */}

                            {/* <div className='detailed-job-sharethis-follow-parent'>

                              <div className="admin-detailed-job-buttonsbutton2" onClick={handleFollowButtonClick} style={{ cursor: 'pointer' }}>
                                <img
                                  className="admin-detailed-job-placeholder-icon4"
                                  alt=""
                                  src={placeholder1}
                                />

                                <img
                                  className="admin-detailed-job-magnifier-1-icon"
                                  alt=""
                                  src={follow}

                                />

                                <img
                                  className="admin-detailed-job-placeholder-icon4"
                                  alt=""
                                  src={placeholder1}
                                />

                                Follow us

                              </div>

                              {activeSection === 'follow' && (
                                <div className='detailed-job-sharethis-follow'>
                                  <InlineFollowButtons
                                    config={{
                                      action: 'Follow us:', // call to action (STRING)
                                      action_enable: false,
                                      action_pos: 'bottom', // position of call to action (left, top, right)
                                      alignment: 'center',  // alignment of buttons (left, center, right)
                                      color: 'white',       // set the color of buttons (social, white)
                                      enabled: true,        // show/hide buttons (true, false)
                                      networks: [
                                        'linkedin',         // which networks to include (see FOLLOW NETWORKS)
                                        'twitter',
                                        'facebook',
                                        'instagram',

                                      ],
                                      padding: 8,           // padding within buttons (INTEGER)
                                      profiles: {           // social profile links for buttons
                                        twitter: 'ger8or',
                                        facebook: 'Ger8orMarketplace ',
                                        instagram: 'ger8or',
                                        linkedin: 'company/gerator',
                                      },
                                      radius: 9,            // the corner radius on each button (INTEGER)
                                      size: 32,             // the size of each button (INTEGER)
                                      spacing: 8            // the spacing between buttons (INTEGER)
                                    }}
                                  />
                                </div>
                              )}

                          </div> */}
                          </div>
                        </div>
                        <div className="admin-detailed-job-dropdown-container" style={{ cursor: "pointer" }} onClick={toggleThreeDotsDropdown} ref={threeDotsRef}>
                          <div className="admin-detailed-job-dropdown">
                            <img
                              className="admin-detailed-job-maximize-01-icon"
                              alt=""
                              src={dotsvertical}
                            />
                          </div>
                          {isThreeDotOpen && <ThreeDotsDetailPageComponent />}

                        </div>
                      </div>
                    </div>
                    <img className="admin-detailed-job-divider-icon" alt="" src={divider1} />

                    <div className="admin-detailed-job-heading-parent">
                      <div className="admin-detailed-job-heading">Highlights</div>
                      <div className="admin-detailed-job-description-content">
                        <div className="admin-detailed-job-description-columns-first">
                          <div className="admin-detailed-job-first-columns-paragraphs">

                            <div className="admin-detailed-job-paragraph">
                              {jobData.data.tax_service_category.name}
                            </div>
                            <div className="admin-detailed-job-paragraph1">Service Category</div>

                          </div>
                          <div className="admin-detailed-job-first-columns-paragraphs">
                            <div className="admin-detailed-job-paragraph">{jobData.data.tax_experience_level.name}</div>
                            <div className="admin-detailed-job-paragraph1">Experience Level</div>
                          </div>
                          <div className="admin-detailed-job-first-columns-paragraphs">
                            <div className="admin-detailed-job-paragraph">{jobData.data.tax_engagement_type.name}</div>
                            <div className="admin-detailed-job-paragraph1">Engagement Type</div>
                          </div>
                        </div>
                        <div className="admin-detailed-job-description-columns-second">
                          <div className="admin-detailed-job-second-columns-paragraphs">
                            <div className="admin-detailed-job-paragraph">
                              {jobData.data.job_business.businessName}
                            </div>
                            <div className="admin-detailed-job-paragraph1">Hiring Entity</div>
                          </div>
                          <div className="admin-detailed-job-second-columns-paragraphs">
                            <div className="admin-detailed-job-paragraph">{jobData.data.tax_occupation_category.name}</div>
                            <div className="admin-detailed-job-paragraph1">Occupation Category</div>
                          </div>

                          {jobData.data.tax_physical_location?.name &&
                            <div className="admin-detailed-job-second-columns-paragraphs">
                              <div className="admin-detailed-job-paragraph">
                                {jobData.data.tax_physical_location.name}
                              </div>
                              <div className="admin-detailed-job-paragraph1">Physical Location / Department</div>
                            </div>
                          }

                          {jobData.data.tax_purpose_use?.name &&
                            <div className="admin-detailed-job-second-columns-paragraphs">
                              <div className="admin-detailed-job-paragraph">{jobData.data.tax_purpose_use.name}</div>
                              <div className="admin-detailed-job-paragraph1">Device Use</div>
                            </div>
                          }
                          <div className="admin-detailed-job-second-columns-paragraphs">

                            {/* <div className="admin-detailed-job-paragraph">{jobData.data.location}</div> */}
                            {jobData.data.job_address.tax_address_type
                              .slug === "address" ?
                              <div className="admin-detailed-job-paragraph">{jobData.data.job_address.addressTownCity},  {jobData.data.job_address.tax_country.name}</div>
                              :
                              <div className="admin-detailed-job-paragraph">{jobData.data.job_address.tax_address_type
                                .name}</div>
                            }
                            <div className="admin-detailed-job-paragraph1">Job Location</div>
                          </div>

                          {jobData.data.tax_clinical_application?.name &&
                            <div className="admin-detailed-job-second-columns-paragraphs">
                              <div className="admin-detailed-job-paragraph">{jobData.data.tax_clinical_application.name}</div>
                              <div className="admin-detailed-job-paragraph1">Clinical Applications</div>
                            </div>
                          }

                          {/* <div className="admin-detailed-job-second-columns-paragraphs">
                        <div className="admin-detailed-job-paragraph">EchoStar</div>
                        <div className="admin-detailed-job-paragraph1">Communication</div>
                      </div> */}

                        </div>
                      </div>
                    </div>

                    <div className="admin-detailed-job-content-blocks">
                      <div className="admin-detailed-job-heading1">Overview</div>
                      <div className="admin-detailed-job-paragraph26">
                        {jobData.data.overview}
                      </div>
                    </div>
                    <div className="admin-detailed-job-content-blocks">
                      <div className="admin-detailed-job-heading1">
                        Deliverables
                      </div>
                      <div className="admin-detailed-job-paragraph26">
                        {jobData.data.deliverables}
                      </div>
                    </div>

                    <div className="admin-detailed-job-content-blocks">
                      <div className="admin-detailed-job-heading1">Location</div>
                      {jobData.data.job_address.tax_address_type
                        .slug === "address" ?
                        <div className="admin-detailed-job-paragraph26">
                          {jobData.data.job_address?.fullAddress}
                        </div>
                        :
                        <div className="admin-detailed-job-paragraph26">
                          {jobData.data.job_address.tax_address_type
                            .name}
                        </div>
                      }

                      {jobData.data.job_address.tax_address_type
                        .slug === "address" && <MapLocationViewComponent
                          key={jobData.data.id}
                          width={"100%"}
                          height={"400px"}
                          initialCoordinates={jobData.data.job_address.addressLocation}
                        />}
                    </div>
                    {(jobData.data.videoType && jobData.data.linkVideo) &&
                      // (jobData.data.videoType === 'Youtube') ?
                      <>
                        <VideoSection videoUrl={jobData.data.linkVideo} />
                        <br />
                      </>
                      // :
                      // <>
                      //   <VideoSection videoUrl={serviceData.data.linkVideo} />
                      //   <br />
                      // </>
                    }

                    <img className="admin-detailed-job-divider-icon" alt="" src={divider1} />

                    {jobData.data?.documentFiles?.length > 0 && (
                      <div className="detailed-device-heading-parent">
                        <div className="detailed-device-heading1">Documents</div>
                        <div className="detailed-device-download-items-parent detailed-device-download-items-parent-custom">
                          {jobData.data.documentFiles.map((link, index) => (
                            <a
                              key={index}
                              href={link} // Use the appropriate property for the file URL
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <div className="detailed-device-download-items">
                                <div className="detailed-device-file-upload-item-base">
                                  <img
                                    className="detailed-device-progress-fill-icon"
                                    alt=""
                                    src={progress_fill}
                                  />
                                  <div className="detailed-device-file-type-icon">
                                    <img
                                      className="detailed-device-page-icon"
                                      alt=""
                                      src={page}
                                    />
                                    <b className="detailed-device-file-type">PDF</b>
                                  </div>
                                  <div className="detailed-device-download-info">
                                    <div className="detailed-device-text12">
                                      {jobData.data.jobs_files[index].file_name} {/* Access file name from the current file object */}
                                    </div>
                                    <div className="detailed-device-supporting-text3">
                                      {(jobData.data.jobs_files[index].file_size / 1000).toFixed(1) + " KB"} {/* Access file size */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          ))}
                        </div>
                      </div>
                    )}

                    {/* <div className="admin-detailed-job-heading15">Reviews (2)</div>
                  <div className="admin-detailed-job-filters-bar">
                    <div className="admin-detailed-job-content4">
                      <div className="admin-detailed-job-button-group">
                        <div className="admin-detailed-job-button-group-base">
                          <div className="admin-detailed-job-text18">Newest</div>
                        </div>
                        <div className="admin-detailed-job-button-group-base1">
                          <div className="admin-detailed-job-text19">Highest</div>
                        </div>
                        <div className="admin-detailed-job-button-group-base2">
                          <div className="admin-detailed-job-text20">Text</div>
                        </div>
                        <div className="admin-detailed-job-button-group-base3">
                          <div className="admin-detailed-job-text20">Text</div>
                        </div>
                        <div className="admin-detailed-job-button-group-base4">
                          <div className="admin-detailed-job-text20">Text</div>
                        </div>
                        <div className="admin-detailed-job-button-group-base5">
                          <div className="admin-detailed-job-text20">Text</div>
                        </div>
                        <div className="admin-detailed-job-button-group-base6">
                          <div className="admin-detailed-job-text20">Text</div>
                        </div>
                        <div className="admin-detailed-job-button-group-base7">
                          <div className="admin-detailed-job-text20">Text</div>
                        </div>
                        <div className="admin-detailed-job-button-group-base8">
                          <div className="admin-detailed-job-text26">Lowest</div>
                        </div>
                      </div>
                      <div className="admin-detailed-job-actions">
                        <div className="admin-detailed-job-input-dropdown">
                          <div className="admin-detailed-job-input-with-label1">
                            <div className="admin-detailed-job-label">Search</div>
                            <div className="admin-detailed-job-input1">
                              <div className="admin-detailed-job-content5">
                                <img
                                  className="admin-detailed-job-search-lg-icon"
                                  alt=""
                                  src={searchlg}
                                />

                                <input
                                  className="admin-detailed-job-text27"
                                  placeholder="Thomson Medical"
                                  type="text"
                                />

                              </div>
                            </div>
                          </div>
                          <div className="admin-detailed-job-hint-text">
                            This is a hint text to help user.
                          </div>
                        </div>
                        <div className="admin-detailed-job-button">
                          <img
                            className="admin-detailed-job-filter-lines-icon"
                            alt=""
                            src={filterlines}
                          />

                          <div className="admin-detailed-job-text-padding">
                            <div className="admin-detailed-job-text28">Filters</div>
                          </div>
                          <img
                            className="admin-detailed-job-placeholder-icon4"
                            alt=""
                            src={placeholder1}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="admin-detailed-job-review-container">
                    <div className="admin-detailed-job-review-content">
                      <div className="admin-detailed-job-first-review">
                        <b className="admin-detailed-job-empty-review">5.0</b>
                        <div className="admin-detailed-job-reviewer">
                          <div className="admin-detailed-job-heading16">/5</div>
                        </div>
                      </div>
                      <div className="admin-detailed-job-review-stars-background">
                        <img
                          className="admin-detailed-job-star-background-icon"
                          alt=""
                          src={star_background}
                        />

                        <img
                          className="admin-detailed-job-star-background-icon"
                          alt=""
                          src={star_background}
                        />

                        <img
                          className="admin-detailed-job-star-background-icon"
                          alt=""
                          src={star_background}
                        />

                        <img
                          className="admin-detailed-job-star-background-icon"
                          alt=""
                          src={star_background}
                        />

                        <img
                          className="admin-detailed-job-star-background-icon"
                          alt=""
                          src={star_background}
                        />
                      </div>
                    </div>
                    <div className="admin-detailed-job-review-stars">
                      <div className="admin-detailed-job-rectangle-parent1">
                        <div className="admin-detailed-job-rectangle-div"></div>
                        <div className="admin-detailed-job-stars">
                          <img
                            className="admin-detailed-job-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="admin-detailed-job-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="admin-detailed-job-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="admin-detailed-job-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="admin-detailed-job-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />
                        </div>
                        <div className="admin-detailed-job-first-review-fifth-star">
                          <div className="admin-detailed-job-heading17">Accuracy (5.0)</div>
                        </div>
                      </div>
                      <div className="admin-detailed-job-rectangle-parent1">
                        <div className="admin-detailed-job-rectangle-div"></div>
                        <div className="admin-detailed-job-stars">
                          <img
                            className="admin-detailed-job-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="admin-detailed-job-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="admin-detailed-job-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="admin-detailed-job-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="admin-detailed-job-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />
                        </div>
                        <div className="admin-detailed-job-heading-wrapper">
                          <div className="admin-detailed-job-heading18">Relevance (5.0)</div>
                        </div>
                      </div>
                      <div className="admin-detailed-job-rectangle-parent3">
                        <div className="admin-detailed-job-rectangle-div"></div>
                        <div className="admin-detailed-job-stars-wrapper">
                          <div className="admin-detailed-job-stars2">
                            <img
                              className="admin-detailed-job-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="admin-detailed-job-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="admin-detailed-job-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="admin-detailed-job-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="admin-detailed-job-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
                          </div>
                        </div>
                        <div className="admin-detailed-job-heading19">Value for Money (5.0)</div>
                      </div>
                    </div>
                  </div> */}
                    <div className="admin-detailed-job-divider-wrapper">
                      <img
                        className="admin-detailed-job-divider-icon3"
                        alt=""
                        src={divider1}
                      />
                    </div>
                    {/* <div className="admin-detailed-job-client-review">
                    <img
                      className="admin-detailed-job-client-review-child"
                      alt=""
                      src={group_78_2x}
                    />

                    <div className="admin-detailed-job-client-review-header">
                      <div className="admin-detailed-job-heading20">Chloe Tammy</div>
                      <div className="admin-detailed-job-heading21">
                        <span>Marketing Communications at</span>
                        <span className="admin-detailed-job-alvo-medical"> Alvo Medical</span>
                      </div>
                    </div>
                    <div className="admin-detailed-job-client-review-content">
                      <div className="admin-detailed-job-client-rating">
                        <div className="admin-detailed-job-stars3">
                          <img
                            className="admin-detailed-job-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="admin-detailed-job-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="admin-detailed-job-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="admin-detailed-job-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="admin-detailed-job-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />
                        </div>
                        <div className="admin-detailed-job-rating-value">
                          <div className="admin-detailed-job-heading22">1 month ago</div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  </div>
                  <div className="admin-detailed-job-service-card">
                    <div className="admin-detailed-job-card-content">
                      <div className="admin-detailed-job-card-content-child"></div>
                      <div className="admin-detailed-job-card-header">
                        <div className="admin-detailed-job-price-tag">
                          <div className="admin-detailed-job-badge-wrapper">
                            {/* <div className="admin-detailed-job-badge3">
                            <img
                              className="admin-detailed-job-dot-icon3"
                              alt=""
                              src={dot1}
                            />

                            <div className="admin-detailed-job-text29">Sale</div>
                          </div> */}
                          </div>
                          <div className="admin-detailed-job-price-parent">
                            <div className="admin-detailed-job-price">${formatNumber(jobData.data.budget)}</div>
                            <div className="admin-detailed-job-original-price">
                              <div className="admin-detailed-job-discount">{jobData.data.tax_unit_measure_service.name}</div>
                            </div>
                          </div>
                          {/* <div className="admin-detailed-job-service-title-container">
                          <div className="admin-detailed-job-service-title">Posted By {jobData.data.tax_your_role.name}</div>
                        </div> */}
                        </div>
                      </div>
                      <div className="admin-detailed-job-card-footer">
                        {jobData.data.logoImage ?
                          <img
                            className="admin-detailed-job-card-footer-child"
                            alt=""
                            src={jobData.data.logoImage}
                          />
                          :
                          <img
                            className="admin-detailed-job-card-footer-child"
                            alt=""
                            src={group_781_2x}
                          />
                        }

                        <div className="admin-detailed-job-footer-divider">
                          <img
                            className="admin-detailed-job-divider-icon4"
                            alt=""
                            src={divider2}
                          />
                        </div>
                      </div>
                      <div className="admin-detailed-job-professional-info">
                        <div className="admin-detailed-job-professional-details">
                          <div className="admin-detailed-job-professional-actions">
                            <div className="admin-detailed-job-benefits-container">
                              <div className="admin-detailed-job-amlie-laurent">{jobData.data.job_user.displayName}</div>
                              <Link className="admin-detailed-job-role" to={`/business/${jobData.data.job_business.id}/${jobData.data.job_business.businessName}`}>
                                {jobData.data.job_business.businessName}
                              </Link>
                              {/* <div className="admin-detailed-job-role">{jobData.data.job_business.businessName}</div> */}
                            </div>
                            <div className="admin-detailed-job-action-buttons">
                              <div className="admin-detailed-job-contact-button">
                                <div className="admin-detailed-job-message-button">


                                  {jobData.data.job_business.businessVerification === 1 &&
                                    <>
                                      <div className="admin-detailed-job-navigation">
                                        <img
                                          className="admin-detailed-job-bookmark-check-icon"
                                          alt=""
                                          src={verified}
                                        />
                                      </div>
                                      <div className="admin-detailed-job-supporting-text10">
                                        Verified by Gerätor
                                      </div>
                                    </>
                                  }
                                  {jobData.data.job_business.businessVerification === 2 &&
                                    <>
                                      <div className="admin-detailed-job-navigation">
                                        <img
                                          className="admin-detailed-job-bookmark-check-icon"
                                          alt=""
                                          src={unverified}
                                        />
                                      </div>
                                      <div className="admin-detailed-job-supporting-text10-a">
                                        Unverified
                                      </div>
                                    </>
                                  }
                                  {jobData.data.job_business.businessVerification === 3 &&
                                    <>
                                      <div className="admin-detailed-job-navigation">
                                        <img
                                          className="admin-detailed-job-bookmark-check-icon"
                                          alt=""
                                          src={awaiting}
                                        />
                                      </div>
                                      <div className="admin-detailed-job-supporting-text10">
                                        Awaiting verification by Gerätor
                                      </div>
                                    </>
                                  }
                                  {/* <div className="admin-detailed-job-navigation">
                                  <img
                                    className="admin-detailed-job-bookmark-check-icon"
                                    alt=""
                                    src={bookmarkcheck}
                                  />
                                </div>
                                <div className="admin-detailed-job-supporting-text10">
                                  Verified by Gerätor
                                </div> */}
                                </div>
                              </div>
                              <div className="admin-detailed-job-supporting-text11">
                                {jobData.data.job_business.businessVerification === 1 &&
                                  <>
                                    {jobData.data.job_business.businessName} conforms to Gerätor's requirements for
                                    verification

                                  </>
                                }
                              </div>
                            </div>
                            {/* <div className="admin-detailed-job-favorite-button">
                              <div className="admin-detailed-job-button-container2">
                                {!jobData.data.isAuthor && (
                                  <div className="detailed-device-buttonsbutton5" onClick={handlePrivateMessage}>
                                    <img
                                      className="detailed-device-filter-lines-icon"
                                      alt=""
                                      src={messagechatcircle}
                                    />
                                    <div className="detailed-device-text30">Private Message</div>
                                  </div>
                                )}
                             
                                {!jobData.data.isAuthor && (
                                  (jobData.data.addedToContact) ?
                                    <div className="admin-detailed-job-buttonsbutton6" onClick={() => handleRemoveContact(jobData.data.job_user.id)} style={{ cursor: 'pointer' }}>
                                      <img
                                        className="admin-detailed-job-search-lg-icon"
                                        alt=""
                                        src={usercheck02}
                                      />
                                    </div>
                                    :
                                    <div className="admin-detailed-job-buttonsbutton6-add" onClick={() => handleAddContact(jobData.data.job_user.id)} style={{ cursor: 'pointer' }}>
                                      <img
                                        className="admin-detailed-job-search-lg-icon"
                                        alt=""
                                        src={add_contact}
                                      />
                                    </div>
                                )}
                              </div>
                            </div> */}
                          </div>
                          <div className="admin-detailed-job-dropdown-menu">
                            <div className="admin-detailed-job-dropdown1">
                              <img
                                className="admin-detailed-job-maximize-01-icon"
                                alt=""
                                src={dotsvertical}
                              />
                            </div>
                            {isThreeDotUserOpen && <ThreeDotsDetailPageUserComponent onLaunchReportAbuseClick={handleLaunchReportAbuseClick} />}

                          </div>
                        </div>
                        {isReportAbuseOpen &&
                          createPortal(
                            <div ref={reportAbuseRef}  >
                              <ReportAbuseSlideOut onClose={handleCloseReportAbuse} />
                            </div>,
                            document.body
                          )}
                      </div>
                    </div>
                    {!jobData.data.totalActiveEngagement &&
                      <div className="admin-detailed-job-rectangle-parent4">
                        <div className="admin-detailed-job-testimonial-header">
                          <div className="admin-detailed-job-header-content1">
                            {(jobData.data.tax_post_status.id === 2 || jobData.data.tax_post_status.id === 3) &&
                              <button className="admin-detailed-page-button11" type="button" onClick={handleSetPublish} >
                                <div className="admin-detailed-page-text-padding8">
                                  <div className="admin-detailed-page-text76">Publish</div>
                                </div>

                              </button>
                            }

                            {jobData.data.tax_post_status.id === 1 &&
                              <button className="admin-detailed-page-button12" type="button" onClick={handleSetArchive} >


                                <div className="admin-detailed-page-text-padding8">
                                  <div className="admin-detailed-page-text76-a">Archive</div>
                                </div>

                              </button>
                            }
                          </div>
                        </div>

                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="admin-detailed-job-listing-image-grid">
            <div className="admin-detailed-job-paragraph37">
              One of the best offers I found in the market
            </div>
          </div>
          <div className="admin-detailed-job-listing-image-grid1">
            <div className="admin-detailed-job-paragraph35">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of type
              and scrambled it to make a type specimen book.
            </div>
          </div> */}

            {/* <div className="admin-detailed-job-client-feedback-parent">
            <div className="admin-detailed-job-client-feedback">
              <div className="admin-detailed-job-feedback-content">
                <img
                  className="admin-detailed-job-feedback-content-child"
                  alt=""
                  src={group_78_2x}
                />

                <div className="admin-detailed-job-feedback-header">
                  <div className="admin-detailed-job-header-info">
                    <div className="admin-detailed-job-heading20">Chloe Tammy</div>
                    <div className="admin-detailed-job-heading21">
                      <span>Marketing Communications at</span>
                      <span className="admin-detailed-job-alvo-medical"> Alvo Medical</span>
                    </div>
                  </div>
                </div>
                <div className="admin-detailed-job-feedback-rating">
                  <div className="admin-detailed-job-buttonsbutton7">
                      <img
                        className="admin-detailed-job-filter-lines-icon"
                        alt=""
                        src={messagedotscircle}
                      />

                      <div className="admin-detailed-job-text35">Reply</div>
                    </div>
                  <div className="admin-detailed-job-feedback-score">
                    <div className="admin-detailed-job-stars-parent">
                      <div className="admin-detailed-job-stars">
                        <img
                          className="admin-detailed-job-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="admin-detailed-job-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="admin-detailed-job-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="admin-detailed-job-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="admin-detailed-job-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />
                      </div>
                      <div className="admin-detailed-job-rating-value">
                        <div className="admin-detailed-job-heading22">1 month ago</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="admin-detailed-job-client-message">
              <div className="admin-detailed-job-message-content">
                <div className="admin-detailed-job-paragraph39">
                  One of the best offers I found in the market
                </div>
                <div className="admin-detailed-job-paragraph26">
                  Lorem Ipsum is simply dummy text of the printing and typesetting
                  industry. Lorem Ipsum has been the industry's standard dummy text
                  ever since the 1500s, when an unknown printer took a galley of
                  type and scrambled it to make a type specimen book.
                </div>
              </div>
            </div>
            <div className="admin-detailed-job-view-replies">
              <div className="admin-detailed-job-buttonsbutton8" onClick={handleReplyClick}>
                <img
                  className="admin-detailed-job-filter-lines-icon"
                  alt=""
                  src={messagedotscircle}
                />

                <div className="admin-detailed-job-text35">Reply</div>
              </div>
            </div>

            {showReply && (
              <div className="admin-detailed-job-reply-section">
                <textarea
                  className="admin-detailed-job-reply-textarea"
                  value={replyText}
                  onChange={handleChange}
                  placeholder="Type your reply..."
                />

                <div className={`detailed-job-char-counter ${warningClass}`}>
                  {maxLength - replyText.length} characters left
                </div>

                <button className="admin-detailed-job-reply-submit-btn" onClick={handleSubmit}>
                  Submit Reply
                </button>
              </div>
            )}
          </div>

          <div className="admin-detailed-job-pagination-wrapper">
            <div className="admin-detailed-job-pagination">
              <div className="admin-detailed-job-button-wrap">
                <div className="admin-detailed-job-buttonsbutton9">
                  <img
                    className="admin-detailed-job-placeholder-icon4"
                    alt=""
                    src={placeholder1}
                  />

                  <div className="admin-detailed-job-text-padding">
                    <div className="admin-detailed-job-page-number">Previous</div>
                  </div>
                  <img
                    className="admin-detailed-job-placeholder-icon4"
                    alt=""
                    src={placeholder1}
                  />
                </div>
              </div>
              <div className="admin-detailed-job-details">1 of 10</div>
              <div className="admin-detailed-job-button-wrap1">
                <div className="admin-detailed-job-buttonsbutton10">
                  <img
                    className="admin-detailed-job-placeholder-icon4"
                    alt=""
                    src={placeholder1}
                  />

                  <div className="admin-detailed-job-text-padding">
                    <div className="admin-detailed-job-text37">Next</div>
                  </div>
                  <img
                    className="admin-detailed-job-placeholder-icon4"
                    alt=""
                    src={placeholder1}
                  />
                </div>
              </div>
            </div>
          </div> */}
            <ReviewJobView isAdmin={true} listingId={id} currentUsermeta={currentUsermeta} />

            <div className="admin-detailed-job-listing-image-grid1">
              <img className="admin-detailed-job-divider-icon3" alt="" src={divider1} />
            </div>

            {/* Review Add Form component */}
            {/* {(!jobData.data.isAuthor && (!jobData.data.isReviewed) && <AddReviewJobForm listingId={id} currentUsermeta={currentUsermeta} formikRef={formikRef} />)
            } */}
            {/* <div className="admin-detailed-job-review-form-wrapper">
            <div className="admin-detailed-job-review-form">
              <div className="admin-detailed-job-review-form-child"></div>
              <div className="admin-detailed-job-breadcrumb-container-parent">
                <div className="admin-detailed-job-heading32">Write Review</div>

                <RatingStarComponent />
                <ReviewDescriptionComponent />

                <div className="admin-detailed-job-frame-parent3">
                  <div className="admin-detailed-job-heading-container">
                    <div className="admin-detailed-job-heading33">Accuracy</div>
                    <div className="admin-detailed-job-listing-detail-item-date-parent">
                      <img
                        className="admin-detailed-job-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="admin-detailed-job-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="admin-detailed-job-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="admin-detailed-job-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="admin-detailed-job-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />
                    </div>
                  </div>
                  <div className="admin-detailed-job-heading-container">
                    <div className="admin-detailed-job-heading33">Relevance</div>
                    <div className="admin-detailed-job-listing-detail-item-date-parent">
                      <img
                        className="admin-detailed-job-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="admin-detailed-job-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="admin-detailed-job-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="admin-detailed-job-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="admin-detailed-job-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />
                    </div>
                  </div>
                  <div className="admin-detailed-job-heading-container">
                    <div className="admin-detailed-job-heading35">Value for Money</div>
                    <div className="admin-detailed-job-listing-detail-item-date-parent">
                      <img
                        className="admin-detailed-job-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="admin-detailed-job-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="admin-detailed-job-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="admin-detailed-job-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="admin-detailed-job-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />
                    </div>
                  </div>
                </div>
                <div className="admin-detailed-job-input-field">
                  <div className="admin-detailed-job-input-with-label1">
                    <div className="admin-detailed-job-label2">Title</div>
                    <div className="admin-detailed-job-content6">
                      <div className="admin-detailed-job-textarea-input-field">
                        <div className="admin-detailed-job-input-with-label3">
                          <div className="admin-detailed-job-label3">Description</div>
                          <div className="admin-detailed-job-input2">
                            <div className="admin-detailed-job-text38">70 characters</div>
                          </div>
                        </div>
                        <div className="admin-detailed-job-hint-text1">10 characters left</div>
                      </div>
                    </div>
                  </div>
                  <div className="admin-detailed-job-hint-text">This is a hint text to help user.</div>
                </div>
                <div className="admin-detailed-job-input-field1">
                  <div className="admin-detailed-job-input-with-label1">
                    <div className="admin-detailed-job-label4">Review</div>
                    <div className="admin-detailed-job-content6">
                      <div className="admin-detailed-job-textarea-input-field1">
                        <div className="admin-detailed-job-input-with-label3">
                          <div className="admin-detailed-job-label3">Description</div>
                          <div className="admin-detailed-job-input3">
                            <div className="admin-detailed-job-text39">1000 characters</div>
                          </div>
                        </div>
                        <div className="admin-detailed-job-hint-text3">40 characters left</div>
                      </div>
                    </div>
                  </div>
                  <div className="admin-detailed-job-hint-text">This is a hint text to help user.</div>
                </div>
              </div>
              <div className="admin-detailed-job-submit-button-wrapper">
                <div className="admin-detailed-job-button1-submit">
                  <img
                    className="admin-detailed-job-chevron-down-icon"
                    alt=""
                    src={placeholder2}
                  />

                  <img className="admin-detailed-job-log-in-04-icon" alt="" src={login04} />

                  <div className="admin-detailed-job-navigation">
                    <div className="admin-detailed-job-text40">Submit</div>
                  </div>
                  <img
                    className="admin-detailed-job-chevron-down-icon"
                    alt=""
                    src={placeholder2}
                  />
                </div>
              </div>
            </div>
          </div> */}


            {/* <img className="admin-detailed-job-divider-icon24" alt="" src="/detailed_listing/divider5.svg" /> */}

            {/* <div className="admin-detailed-job-actions1">
          <div className="admin-detailed-job-button2">
            <img
              className="admin-detailed-job-placeholder-icon17"
              alt=""
              src="/detailed_listing/placeholder3.svg"
            />
  
            <div className="admin-detailed-job-text-padding4">
              <div className="admin-detailed-job-text98">Tertiary</div>
            </div>
            <img
              className="admin-detailed-job-placeholder-icon17"
              alt=""
              src="/detailed_listing/placeholder3.svg"
            />
          </div>
          <div className="admin-detailed-job-button3">
            <img
              className="admin-detailed-job-placeholder-icon17"
              alt=""
              src="/detailed_listing/placeholder4.svg"
            />
  
            <div className="admin-detailed-job-text-padding5">
              <div className="admin-detailed-job-text98">Secondary</div>
            </div>
            <img
              className="admin-detailed-job-placeholder-icon17"
              alt=""
              src="/detailed_listing/placeholder4.svg"
            />
          </div>
          <div className="admin-detailed-job-button4">
            <img
              className="admin-detailed-job-placeholder-icon17"
              alt=""
              src={placeholder1}
            />
  
            <div className="admin-detailed-job-text-padding5">
              <div className="admin-detailed-job-text98">Secondary</div>
            </div>
            <img
              className="admin-detailed-job-placeholder-icon17"
              alt=""
              src={placeholder1}
            />
          </div>
          <div className="admin-detailed-job-button5">
            <img
              className="admin-detailed-job-placeholder-icon17"
              alt=""
              src={placeholder2}
            />
  
            <div className="admin-detailed-job-text-padding7">
              <div className="admin-detailed-job-text98">Primary</div>
            </div>
            <img
              className="admin-detailed-job-placeholder-icon17"
              alt=""
              src={placeholder2}
            />
          </div>
        </div> */}


            <img className="detail-page-divider-icon6" alt="" src={divider3} />
            <FooterDetailPage />
          </div>
        </div>
      </>
    )
  }
  else {
    return <Loader />;
  }

}

