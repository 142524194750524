import React, { useState } from 'react';
import "./ThreeDotsListingComponent.css";
import { Link } from 'react-router-dom';
import { useSetArchiveWantedDeviceMutation, useSetPublishWantedDeviceMutation } from '../../api/Beacon/BeaconWantedDeviceAPI';
import { useSetArchiveWantedServiceMutation, useSetPublishWantedServiceMutation } from '../../api/Beacon/BeaconWantedServiceAPI';
import { useSetArchiveWantedSoftwareMutation, useSetPublishWantedSoftwareMutation } from '../../api/Beacon/BeaconWantedSoftwareAPI';
import { useSetArchiveWantedSpareMutation, useSetPublishWantedSpareMutation } from '../../api/Beacon/BeaconWantedSpareAPI';

export const ThreeDotsBeaconDetailPageComponent = ({ onBroadcastClick, onSocialShareClick, option, postId, onClose, postType, totalActiveEngagement }) => {


  const [setToWantedDeviceArchive] = useSetArchiveWantedDeviceMutation();

  const [setToWantedDevicePublish] = useSetPublishWantedDeviceMutation();

  const [setToWantedServiceArchive] = useSetArchiveWantedServiceMutation();

  const [setToWantedServicePublish] = useSetPublishWantedServiceMutation();

  const [setToWantedSoftwareArchive] = useSetArchiveWantedSoftwareMutation();

  const [setToWantedSoftwarePublish] = useSetPublishWantedSoftwareMutation();

  const [setToWantedSpareArchive] = useSetArchiveWantedSpareMutation();

  const [setToWantedSparePublish] = useSetPublishWantedSpareMutation();

  const handleBroadcast = () => {
    console.log("Launch Campaign Clicked");
    if (onBroadcastClick) {
      onBroadcastClick();
    }
  };

  const handleSocialShare = () => {
    console.log("Social Channels Clicked");
    if (onSocialShareClick) {
      onSocialShareClick();
    }
  };

  const handleArchiveUpdate = async (e) => {
    let response;
    e.preventDefault();
    e.stopPropagation();

    try {
      if (!postId) {
        console.error('No post ID provided');
        return;
      }

      switch (postType) {
        case 'wanted-device':
          response = await setToWantedDeviceArchive({ id: postId }).unwrap();
          break;
        case 'wanted-service':
          response = await setToWantedServiceArchive({ id: postId }).unwrap();
          break;
        case 'wanted-software':
          response = await setToWantedSoftwareArchive({ id: postId }).unwrap();
          break;
        case 'wanted-spare':
          response = await setToWantedSpareArchive({ id: postId }).unwrap();
          break;
        default:
          break;
      }

      // console.log('Updating status for post:', postId);
      // const response = await setToWantedDeviceArchive({ id: postId }).unwrap();
      // console.log('Update response:', response);

      if (response?.status) {
        onClose();
        window.location.reload();
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handlePublishUpdate = async (e) => {
    let response;
    e.preventDefault();
    e.stopPropagation();

    try {
      if (!postId) {
        console.error('No post ID provided');
        return;
      }


      switch (postType) {
        case 'wanted-device':
          response = await setToWantedDevicePublish({ id: postId }).unwrap();
          break;
        case 'wanted-service':
          response = await setToWantedServicePublish({ id: postId }).unwrap();
          break;
        case 'wanted-software':
          response = await setToWantedSoftwarePublish({ id: postId }).unwrap();
          break;
        case 'wanted-spare':
          response = await setToWantedSparePublish({ id: postId }).unwrap();
          break;
        default:
          break;
      }

      // console.log('Updating status for post:', postId);
      // const response = await setToWantedDevicePublish({ id: postId }).unwrap();
      // console.log('Update response:', response);

      if (response.status) {
        onClose();
        window.location.reload();
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };


  const handleItemClick = (e, action) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('Action clicked:', action);

    switch (action) {
      case 'publish':
        handlePublishUpdate(e);
        break;
      case 'archive':
        handleArchiveUpdate(e);
        break;
      case 'broadcast':
        handleBroadcast(e);
        onClose();
        break;
      case 'social':
        handleSocialShare(e);
        onClose();
        break;
      // case 'accept':
      //   handleAcceptUpdate(e);
      //   break;
      // case 'reject':
      //   handleRejectUpdate(e);
      //   break;
      // case 'rejectActive':
      //   handleMakeRejectActiveUpdate(e);
      //   break;
      // case 'revoke':
      //   handleRevokeUpdate(e);
      //   break;
      // case 'revokeActive':
      //   handleMakeRevokeActiveUpdate(e);
      //   break;
      // Add other cases as needed
      default:
      // handleRejectUpdate(e);
    }
  };

  const MenuItem = ({ onClick, text, to, action }) => (
    <div
      className="three-dots-admin-filter-dropdown-dropdown-list-item"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (onClick) onClick(e);
        if (action) handleItemClick(e, action);
      }}
    >
      <div className="three-dots-admin-filter-dropdown-content48">
        <div className="three-dots-admin-filter-dropdown-icon-and-text">
          {to ? (
            <Link
              className="three-dots-admin-filter-dropdown-text247"
              to={to}
              onClick={(e) => e.stopPropagation()}
            >
              {text}
            </Link>
          ) : (
            <div className="three-dots-admin-filter-dropdown-text247">{text}</div>
          )}
        </div>
      </div>
    </div>
  );




  return (
    <>

      <div className="three-dots-filter-dropdown-three-dots-filter-beacon-all-options-fixed">
        <section className="three-dots-filter-dropdown-menu-items">
          {/* Broadcast Option */}
          {/* <div className="three-dots-filter-dropdown-dropdown-list-item">
            <div className="three-dots-filter-dropdown-content48">
              <div className="three-dots-filter-dropdown-icon-and-text">
                <div
                  className="three-dots-filter-dropdown-text247"
                  style={{ cursor: 'pointer' }}
                  onClick={handleBroadcast}
                >
                  Broadcast
                </div>
              </div>
            </div>
          </div> */}

          {/* Social Share Option */}
          {/* <div className="three-dots-filter-dropdown-dropdown-list-item">
            <div className="three-dots-filter-dropdown-content48">
              <div className="three-dots-filter-dropdown-icon-and-text">
                <div
                  className="three-dots-filter-dropdown-text247"
                  style={{ cursor: 'pointer' }}
                  onClick={handleSocialShare}
                >
                  Social Channels
                </div>
              </div>
            </div>
          </div> */}


          {option === 'archived' && (
            <>
              {/* <MenuItem text="Launch Campaign" action="broadcast" />
              <MenuItem text="Social Channels" action="social" /> */}
              <MenuItem text="Republish" action="publish" />
              {/* {totalActiveEngagement === 0 &&
                <>
                </>
              } */}
            </>
          )}

          {option === 'published' && (
            <>
              <MenuItem text="Broadcast" action="broadcast" />
              <MenuItem text="Social Channels" action="social" />
              <MenuItem text="Archive" action="archive" />
              {/* {totalActiveEngagement === 0 &&
                <>
                </>
              } */}
            </>
          )}

          {option === 'pending' && (

            <MenuItem text="No action found" />

          )}
        </section>




      </div>
    </>
  );
};
