import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import "./Analytics.css";

import { getCustomizedDate } from "../../helper/ChatHelper";

import icon1 from "../../icons/Gerator/chat_page/icon-1.svg";
import divider1 from "../../icons/Gerator/chat_page/divider1.svg";
import rectangle57 from "../../icons/Gerator/chat_page/rectangle-57@2x.png";
import dot1 from "../../icons/Gerator/chat_page/-dot1.svg";
import markerpin02 from "../../icons/Gerator/chat_page/markerpin02.svg";
import calendar from "../../icons/Gerator/chat_page/calendar.svg";
import dotsvertical from "../../icons/Gerator/chat_page/dotsvertical.svg";
import arrowup from "../../icons/Gerator/chat_page/arrowup.svg";
import lock04 from "../../icons/Gerator/chat_page/lock04.svg";
import eye from "../../icons/Gerator/chat_page/eye.svg";
import lockunlocked04 from "../../icons/Gerator/chat_page/lockunlocked04.svg";
import chartmin from "../../icons/Gerator/chat_page/-chart-mini@2x.png"
import radar1 from "../../icons/Gerator/chat_page/radar-1-1@2x.png"
import deal2 from "../../icons/Gerator/chat_page/deal-2-1@2x.png"
import magnifier from "../../icons/Gerator/chat_page/magnifier-1@2x.png"
import arrowsquareupright from "../../icons/Gerator/chat_page/arrowsquareupright.svg";
import messagechatsquare from "../../icons/Gerator/chat_page/messagechatsquare.svg";


import { useLazyFetchCountOfRadarDeviceQuery } from "../../api/Radar/RadarDeviceAPI";



// Temporary
import { Link } from "react-router-dom";
import dotsvertical from '../../icons/Gerator/sales_iq/dotsvertical.svg';
import calendar1 from '../../icons/Gerator/sales_iq/calendar1.svg';
import content_elements_2x from '../../icons/Gerator/sales_iq/content-elements@2x.png';
import dot1 from '../../icons/Gerator/sales_iq/-dot1.svg';

import _chart_mini2 from "../../icons/Gerator/sales_iq/-chart-mini2@2x.png"
import arrowup from "../../icons/Gerator/sales_iq/arrowup.svg";

import radar_1_1_2x from '../../icons/Gerator/sales_iq/radar-1-1@2x.png';
import deal_2_1_2x from '../../icons/Gerator/sales_iq/deal-2-1@2x.png';
import magnifier_1_2x from '../../icons/Gerator/sales_iq/magnifier-1@2x.png';
import content_elements_2x from '../../icons/Gerator/sales_iq/content-elements@2x.png';
import eye from '../../icons/Gerator/sales_iq/eye.svg';
import icon1 from '../../icons/Gerator/sales_iq/icon1.svg';
import lock04 from '../../icons/Gerator/sales_iq/lock04.svg';
import lockunlocked04 from '../../icons/Gerator/sales_iq/lockunlocked04.svg';
import arrowsquareupright from '../../icons/Gerator/sales_iq/arrowsquareupright.svg';
import messagechatsquare from '../../icons/Gerator/sales_iq/messagechatsquare.svg';
import markerpin02 from '../../icons/Gerator/sales_iq/markerpin02.svg';
import divider2 from '../../icons/Gerator/sales_iq/divider2.svg';
import arrowup1 from '../../icons/Gerator/sales_iq/arrowup1.svg';
import arrowdown1 from '../../icons/Gerator/sales_iq/arrowdown1.svg';
import placeholder2 from '../../icons/Gerator/sales_iq/placeholder2.svg';
import group_58_2x from '../../icons/Gerator/sales_iq/group-58@2x.png';
import sliders02 from '../../icons/Gerator/sales_iq/sliders02.svg';
import playstation from "../../icons/Gerator/sales_iq/playstation.jpeg";
import { PostStatusLIstingComponent } from '../../components/PostStatusLIstingComponent/PostStatusLIstingComponent';
import { TransactionTypeComponent } from '../../components/TransactionTypeComponent/TransactionTypeComponent';
import { ThreeDotsNoActionComponent } from "../../components/ThreeDotsListingComponent/ThreeDotsNoActionComponent"
import { formatNumber } from "../../helper/DeviceHelper";



const Analytics = ({ listingData, onLoad }) => {

  const { entity } = useParams()
  const [postTypeSlug, setPostTypeSlug] = useState(null)
  useEffect(() => {
    if (listingData) {
      switch (entity) {
        case 'Device':
          setPostTypeSlug(entity.toLowerCase())
          break;
        case 'Spare':
          setPostTypeSlug(entity.toLowerCase())
          break;
        case 'Service':
          setPostTypeSlug(entity.toLowerCase())
          break;
        case 'Software':
          setPostTypeSlug(entity.toLowerCase())
          break;
        case 'Job':
          setPostTypeSlug(entity.toLowerCase())
          break;
        default:
          break;
      }

      console.log('YES DEVICE SUCCESS in ANALYTICS', listingData);
      onLoad();
    }
  }, [entity]);

  const [activeThreeDotIdNoAction, setActiveThreeDotIdNoAction] = useState(null);
  const threeDotsRefNoAction = useRef([]);
  const [isClickBlockedNoAction, setIsClickBlockedNoAction] = useState(false);

  const toggleThreeDotsDropdownNoAction = (index) => {
    if (isClickBlockedNoAction) return; // Prevent rapid toggle



    if (activeThreeDotIdNoAction === index) {
      setActiveThreeDotIdNoAction(null); // Close if the same dropdown is clicked again
    } else {
      setActiveThreeDotIdNoAction(index); // Open the dropdown
    }

    setIsClickBlockedNoAction(true);
    setTimeout(() => setIsClickBlockedNoAction(false), 400);
  };


  useEffect(() => {
    const handleThreeDotClickOutsideNoAction = (event) => {
      if (activeThreeDotIdNoAction !== null) {
        const ref = threeDotsRefNoAction.current[activeThreeDotIdNoAction];

        if (ref && !ref.contains(event.target)) {
          setActiveThreeDotIdNoAction(null); // Close when clicking outside
        }
      }
    };

    document.addEventListener("mousedown", handleThreeDotClickOutsideNoAction);
    return () => {
      document.removeEventListener("mousedown", handleThreeDotClickOutsideNoAction);
    };
  }, [activeThreeDotIdNoAction]);


  useEffect(() => {
    if (listingData) {

      console.log('YES DEVICE SUCCESS in ANALYTICS', listingData);
      //.log('YES CHATPAGEB', listingData?.profileImage);
    }
  }, [listingData]);

  useEffect(() => {
    if (onLoad) {

      console.log('YES RADAR DEVICE onLoad SUCCESS in ANALYTICS');
      onLoad();

      //.log('YES CHATPAGEB', listingData?.profileImage);
    }
  }, [onLoad]);


  if (listingData) {
    return (
      <div className="analytics-listing-container">
        <div className="analytics-sales-iq-content-items" >
          <div className="analytics-sales-iq-divider-container1">
            <div className="analytics-sales-iq-rectangle-parent5">
              <img
                className="analytics-sales-iq-frame-child7"
                alt=""
                src={listingData?.logoImage ? listingData.logoImage : listingData?.featureImage}
              />

              <div className="analytics-sales-iq-frame-parent1">
                <div className="analytics-sales-iq-action-fields">
                  <div className="analytics-sales-iq-input-elements">
                    <Link to={`/${postTypeSlug}/${listingData?.id}/${listingData?.title}`} className="analytics-sales-iq-text78">
                      {listingData?.title}
                    </Link>
                    <div className="analytics-sales-iq-frame-parent21">
                      <div className="analytics-sales-iq-badge-item-parent">
                        <div className="analytics-sales-iq-badge-parent">

                          <PostStatusLIstingComponent postStatus={listingData?.tax_post_status?.name} postSlug={listingData?.tax_post_status?.slug} postId={listingData?.id} />
                          {/* <PostStatusLIstingComponent postStatus={"Pending"} postSlug={"pending"} postId={1} /> */}

                        </div>
                        <div className="analytics-sales-iq-frame-parent4">
                          <div className="analytics-sales-iq-end-label">
                            <div className="analytics-sales-iq-search-icon-input">{listingData.totalRatingAvg ? `${listingData.totalRatingAvg}/5` : "0/5"}</div>
                          </div>
                          <div className="analytics-sales-iq-type-support">
                            <div className="analytics-sales-iq-supporting-text9">
                            {listingData.totalReviews} {listingData.totalReviews === 0 || listingData.totalReviews === 1 ? "review" : "reviews"}
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="analytics-sales-iq-frame-parent24">
                        <div className="analytics-sales-iq-location-pin-parent">
                          <div className="analytics-sales-iq-search-input">
                            <img
                              className="analytics-sales-iq-marker-pin-02-icon2"
                              alt=""
                              src={markerpin02}
                            />
                          </div>
                          <div className="analytics-sales-iq-location3"> {(listingData?.service_address?.tax_address_type?.id ||
                            listingData?.software_address?.tax_address_type?.id ||
                            listingData?.job_address?.tax_address_type?.id) == 2
                            ? 'Remote'
                            : `${listingData?.device_address?.addressTownCity ||
                            listingData?.spare_address?.addressTownCity ||
                            listingData?.service_address?.addressTownCity ||
                            listingData?.software_address?.addressTownCity ||
                            listingData?.job_address?.addressTownCity || ''}, 
      ${listingData?.device_address?.tax_country?.name ||
                            listingData?.spare_address?.tax_country?.name ||
                            listingData?.service_address?.tax_country?.name ||
                            listingData?.software_address?.tax_country?.name ||
                            listingData?.job_address?.tax_country?.name || ''}`
                          } </div>
                          {/* <div className="analytics-sales-iq-location3">Oklahoma City , United States of America</div> */}
                        </div>

                      </div>
                      <div className="analytics-sales-iq-frame-parent26">
                        <div className="analytics-sales-iq-frame-wrapper19">
                          <div className="analytics-sales-iq-legend-series">
                            <div className="analytics-sales-iq-search-input">
                              <img
                                className="analytics-sales-iq-marker-pin-02-icon2"
                                alt=""
                                src={calendar1}
                              />
                            </div>
                            <div className="analytics-sales-iq-type-of-work2">{getCustomizedDate(listingData?.createdAt)}</div>
                            {/* <div className="analytics-sales-iq-type-of-work2">November 13,2024</div> */}
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>

                  <div className="analytics-sales-iq-small-card">

                    <div className="analytics-sales-iq-badge33">
                      <div className="analytics-sales-iq-third-badge-number">
                        {listingData?.tax_engagement_type?.name ? listingData.tax_engagement_type.name
                          : listingData?.tax_service_category?.name ? listingData.tax_service_category.name
                            :
                            listingData?.tax_software_category?.name ? listingData.tax_software_category.name
                              :
                              listingData?.tax_device_category?.name ? listingData.tax_device_category.name
                                : null}
                      </div>
                    </div>



                    <div className="analytics-sales-iq-badge37">
                      <div className="analytics-sales-iq-third-badge-number"> {listingData?.tax_experience_level?.name ? listingData.tax_experience_level.name : listingData?.tax_software_type?.name ? listingData.tax_software_type.name : listingData?.tax_oem?.name ? listingData.tax_oem.name : listingData?.tax_physical_location?.name ? listingData.tax_physical_location.name : null}</div>
                      {/* <div className="analytics-sales-iq-third-badge-number">
                                12TH MAN
                              </div> */}
                    </div>

                    <div className="analytics-sales-iq-badge38">
                      <div className="analytics-sales-iq-third-badge-number">
                        {listingData?.tax_occupation_category?.name ? listingData.tax_occupation_category.name : listingData?.tax_clinical_application?.name}
                        {/* Pathology */}
                      </div>
                    </div>



                  </div>

                </div>


                <div className="analytics-sales-iq-filter-elements">
                  {/* <div className="analytics-sales-iq-dropdown10" onClick={() => toggleThreeDotsDropdown()} ref={threeDotsRef}>
                            <div className="analytics-sales-iq-dropdown10" >

                              <img
                                className="analytics-sales-iq-dots-vertical-icon"
                                alt=""
                                src={dotsvertical}
                              />
                              {activeThreeDot && <ThreeDotsListingComponent url={`device/${ listingData?.id}`} />}
                            </div>
                          </div> */}
                  <div className="analytics-sales-iq-filter-elements-inner">
                    <div className="analytics-sales-iq-price-parent">
                      {
                        listingData?.tax_transaction_type &&
                        <TransactionTypeComponent transactionType={listingData.tax_transaction_type.name} slug={listingData.tax_transaction_type.slug} />
                      }
                      {/* <TransactionTypeComponent transactionType={"Sale"} slug={"sale"} /> */}

                      {listingData?.price &&
                        <div className="analytics-sales-iq-price2">${formatNumber(listingData?.price)}</div>
                      }

                      {listingData?.rangeMin &&
                        <div className="analytics-sales-iq-price2">${formatNumber(listingData?.rangeMin)}</div>
                      }

                      {listingData?.budget &&
                        <div className="analytics-sales-iq-price2">${formatNumber(listingData?.budget)}</div>
                      }
                      {/* <div className="analytics-sales-iq-price2">$258</div> */}

                      <div className="analytics-sales-iq-dropdown-elements">
                        <div className="analytics-sales-iq-dropdown-options-parent">
                          {
                            listingData?.tax_unit_measure_device_spare &&
                            <div className="analytics-sales-iq-text83">{listingData.tax_unit_measure_device_spare.name}</div>
                          }

                          {
                            listingData?.tax_unit_measure_service &&
                            <div className="analytics-sales-iq-text83">{listingData.tax_unit_measure_service.name}</div>
                          }

                          {
                            listingData?.tax_unit_measure_software &&
                            <div className="analytics-sales-iq-text83">{listingData.tax_unit_measure_software.name}</div>
                          }
                          {/* <div className="analytics-sales-iq-text83">USD - Based on terms of exhange</div> */}

                          <div className="analytics-sales-iq-frame-wrapper3">
                            <div className="analytics-sales-iq-radar-1-1-container">
                              <img
                                className="analytics-sales-iq-magnifier-1-icon"
                                alt=""
                                src={radar_1_1_2x}
                              />

                              {/* <img
                                className="analytics-sales-iq-magnifier-1-icon"
                                alt=""
                                src={deal_2_1_2x}
                              />

                              <img
                                className="analytics-sales-iq-magnifier-1-icon"
                                alt=""
                                src={magnifier_1_2x}
                              /> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>

            <img className="analytics-sales-iq-divider-icon8" alt="" src={divider2} />


            <div className="analytics-sales-iq-metric-item-parent">

              <div className="analytics-sales-iq-metric-item3">
                <div className="analytics-sales-iq-heading9">Active engagement</div>
                <div className="analytics-sales-iq-number-and-chart">
                  <div className="analytics-sales-iq-number-and-badge3">
                    <div className="analytics-sales-iq-heading">{listingData?.engagementCount || 0}</div>
                    {/* <div className="analytics-sales-iq-heading">5</div> */}

                    <div className="analytics-sales-iq-change-and-text3">
                      <div className="analytics-sales-iq-change2">
                        <img
                          className="analytics-sales-iq-upload-04-icon"
                          alt=""
                          src={arrowup}
                        />

                        <div className="analytics-sales-iq-change7">100%</div>
                      </div>
                      <div className="analytics-sales-iq-text139">vs last month</div>
                    </div>
                  </div>
                  <img
                    className="analytics-sales-iq-chart-mini-icon3"
                    alt=""
                    src={_chart_mini2}

                  />
                </div>
                <div className="analytics-sales-iq-dropdown19" style={{ cursor: "pointer" }} onClick={() => toggleThreeDotsDropdownNoAction(0)} ref={(el) => threeDotsRefNoAction.current[0] = el}>
                  <img
                    className="analytics-sales-iq-dots-vertical-icon"
                    alt=""
                    src={dotsvertical}
                  />
                  {activeThreeDotIdNoAction === 0 && <ThreeDotsNoActionComponent />}

                </div>
              </div>

              <div className="analytics-sales-iq-metric-item3">
                <div className="analytics-sales-iq-heading9">Views</div>
                <div className="analytics-sales-iq-number-and-chart">
                  <div className="analytics-sales-iq-number-and-badge3">
                    {/* <div className="analytics-sales-iq-heading">{currentListingData.totalCountView}</div> */}
                    <div className="analytics-sales-iq-heading">{listingData?.viewsCount || 0}</div>

                    <div className="analytics-sales-iq-change-and-text3">
                      <div className="analytics-sales-iq-change2">
                        <img
                          className="analytics-sales-iq-upload-04-icon"
                          alt=""
                          src={arrowup}
                        />

                        <div className="analytics-sales-iq-change7">100%</div>
                      </div>
                      <div className="analytics-sales-iq-text139">vs last month</div>
                    </div>
                  </div>
                  <img
                    className="analytics-sales-iq-chart-mini-icon3"
                    alt=""
                    src={_chart_mini2}

                  />
                </div>
                <div className="analytics-sales-iq-dropdown19" style={{ cursor: "pointer" }} onClick={() => toggleThreeDotsDropdownNoAction(1)} ref={(el) => threeDotsRefNoAction.current[1] = el}>
                  <img
                    className="analytics-sales-iq-dots-vertical-icon"
                    alt=""
                    src={dotsvertical}
                  />
                  {activeThreeDotIdNoAction === 1 && <ThreeDotsNoActionComponent />}

                </div>
              </div>
              <div className="analytics-sales-iq-metric-item3">
                <div className="analytics-sales-iq-heading9">Added to Radar</div>
                <div className="analytics-sales-iq-number-and-chart">
                  <div className="analytics-sales-iq-number-and-badge3">
                    {/* <div className="analytics-sales-iq-heading">{currentListingData.totalCountRadar}</div> */}
                    <div className="analytics-sales-iq-heading">{listingData?.radarCount || 0}</div>

                    <div className="analytics-sales-iq-change-and-text3">
                      <div className="analytics-sales-iq-change2">
                        <img
                          className="analytics-sales-iq-upload-04-icon"
                          alt=""
                          src={arrowup}
                        />

                        <div className="analytics-sales-iq-change7">100%</div>
                      </div>
                      <div className="analytics-sales-iq-text139">vs last month</div>
                    </div>
                  </div>
                  <img
                    className="analytics-sales-iq-chart-mini-icon3"
                    alt=""
                    src={_chart_mini2}
                  />
                </div>
                <div className="analytics-sales-iq-dropdown19" style={{ cursor: "pointer" }} onClick={() => toggleThreeDotsDropdownNoAction(2)} ref={(el) => threeDotsRefNoAction.current[2] = el}>
                  <img
                    className="analytics-sales-iq-dots-vertical-icon"
                    alt=""
                    src={dotsvertical}
                  />
                  {activeThreeDotIdNoAction === 2 && <ThreeDotsNoActionComponent />}

                </div>
              </div>
              <div className="analytics-sales-iq-metric-item3">
                <div className="analytics-sales-iq-heading9">Searches</div>
                <div className="analytics-sales-iq-number-and-chart">
                  <div className="analytics-sales-iq-number-and-badge3">
                    <div className="analytics-sales-iq-heading">{listingData?.totalSearch || 0}</div>
                    {/* <div className="analytics-sales-iq-heading">1</div> */}

                    <div className="analytics-sales-iq-change-and-text3">
                      <div className="analytics-sales-iq-change2">
                        <img
                          className="analytics-sales-iq-upload-04-icon"
                          alt=""
                          src={arrowup}
                        />

                        <div className="analytics-sales-iq-change7">100%</div>
                      </div>
                      <div className="analytics-sales-iq-text139">vs last month</div>
                    </div>
                  </div>
                  <img
                    className="analytics-sales-iq-chart-mini-icon3"
                    alt=""
                    src={_chart_mini2}
                  />
                </div>
                <div className="analytics-sales-iq-dropdown19" style={{ cursor: "pointer" }} onClick={() => toggleThreeDotsDropdownNoAction(3)} ref={(el) => threeDotsRefNoAction.current[3] = el}>
                  <img
                    className="analytics-sales-iq-dots-vertical-icon"
                    alt=""
                    src={dotsvertical}
                  />
                  {activeThreeDotIdNoAction === 3 && <ThreeDotsNoActionComponent />}

                </div>
              </div>
            </div>

            <img className="analytics-sales-iq-divider-icon8" alt="" src={divider2} />


            <div className="analytics-sales-iq-frame-parent71-owner">
              <div className="analytics-sales-iq-buttonsbutton-parent1">
                <div className="analytics-sales-iq-legend-series">
                  <img
                    className="sales-iq-location-column-child"
                    alt=""
                    // src={currentListingData.profileImage}
                    src={listingData?.profileImage}
                  />

                  <div className="analytics-sales-iq-location-column-inner">
                    <div className="analytics-sales-iq-date-picker-dropdown">
                      <div className="analytics-sales-iq-name">{listingData?.device_user?.displayName || listingData?.spare_user?.displayName || listingData?.service_user?.displayName || listingData?.software_user?.displayName || listingData?.job_user?.displayName}</div>
                      <div className="analytics-sales-iq-role" >
                        {listingData?.device_business?.businessName || listingData?.spare_business?.businessName || listingData?.service_business?.businessName || listingData?.software_business?.businessName || listingData?.job_business?.businessName}
                      </div>

                      {/* <div className="sales-iq-name">{currentListingData.device_business.business_user.displayName}</div>
                              <Link className="sales-iq-role" to={`/business/${currentListingData.device_business.id}/${currentListingData.device_business.businessName}`}>
                                {currentListingData.device_business.businessName}
                              </Link> */}

                      {/* <div className="sales-iq-name">Test Account5</div>
                              <div className="sales-iq-role" >
                               Medico
                              </div> */}
                    </div>
                  </div>
                </div>
                <div className='analytics-main-buttons-group'>
                  <div className="analytics-sales-iq-buttonsbutton13">
                    <img
                      className="analytics-sales-iq-arrow-narrow-left-icon"
                      alt=""
                      src={eye}
                    />

                    <div className="analytics-sales-iq-search-input">
                      <div className="analytics-sales-iq-text67">View</div>
                    </div>
                  </div>
                  {/* <div className="analytics-sales-iq-buttonsbutton25">
                    <div className="analytics-sales-iq-icon-wrapper">
                      <img className="analytics-sales-iq-icon1" alt="" src={icon1} />
                    </div>
                    <div className="analytics-sales-iq-search-input">
                      <div className="analytics-sales-iq-text68">Sales IQ</div>
                    </div>
                    <img
                      className="analytics-sales-iq-arrow-narrow-left-icon"
                      alt=""
                      src={lock04}
                    />
                  </div> */}

                  <Link to={"/sales-iq"}>
                    <div className="analytics-sales-iq-buttonsbutton26">
                      <div className="analytics-sales-iq-icon-wrapper">
                        <img className="analytics-sales-iq-icon1" alt="" src={icon1} />
                      </div>
                      <div className="analytics-sales-iq-search-input">
                        <div className="analytics-sales-iq-text68">Sales IQ</div>
                      </div>
                      <img
                        className="analytics-sales-iq-arrow-narrow-left-icon"
                        alt=""
                        src={lockunlocked04}
                      />
                    </div>
                  </Link>


                  {/* <div className="analytics-sales-iq-buttonsbutton27">
                            <img
                              className="analytics-sales-iq-upload-04-icon"
                              alt=""
                              src={messagechatsquare}
                            />

                            <div className="analytics-sales-iq-text145">Messages</div>
                            <div className="analytics-sales-iq-ellipse-parent">
                              <div className="analytics-sales-iq-ellipse-div"></div>
                              <div className="analytics-sales-iq-div">2</div>
                            </div>
                          </div> */}
                </div>

              </div>
              <a
                //  href={`/device/${device.id}/${device.title}`}
                target="_blank"
                rel="noopener noreferrer"
                className="analytics-sales-iq-arrow-square-up-right-wrapper1"
              >
                <img
                  className="analytics-sales-iq-arrow-square-up-right-icon1"
                  alt=""
                  src={arrowsquareupright}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export { Analytics };




