import React, { useEffect, useRef, useState } from 'react';
import "./AdminDashboardUserComponent.css";
import plus from "../../../../icons/Gerator/contacts/plus.svg";
import uploadcloud02 from "../../../../icons/Gerator/contacts/uploadcloud02.svg";
import dotsvertical from "../../../../icons/Gerator/contacts/dotsvertical.svg";
import divider from "../../../../icons/Gerator/contacts/divider.svg";
import searchlg from "../../../../icons/Gerator/contacts/searchlg.svg";
import filterlines from "../../../../icons/Gerator/contacts/filterlines.svg";
import arrowdown from "../../../../icons/Gerator/contacts/arrowdown.svg";
import markerpin02 from "../../../../icons/Gerator/contacts/markerpin02.svg";
import help_icon from "../../../../icons/Gerator/contacts/help_icon.svg";
import arrowright from "../../../../icons/Gerator/contacts/arrowright.svg"
import arrowleft from "../../../../icons/Gerator/contacts/arrowleft.svg"

import arrowdown1 from '../../../../icons/Gerator/dashboard/arrowdown1.svg';
import { auth, onAuthStateChanged } from '../../../../screens/Login/FirebaseClient';

import { useNavigate } from 'react-router-dom';
import { NoListingFound } from '../../../NoListing/NoListingFound';
import SearchComponentAdmin from '../SearchComponentAdmin/SearchComponentAdmin';
import { useGetAdminDashboardUsersQuery } from '../../../../api/admin/AdminUserAPI';
import { ErrorShowComponent } from '../../../ErrorShowComponent/ErrorShowComponent';
import SkeletonTableListingLoaderComponent from '../../../SkeletonTableListingLoaderComponent/SkeletonTableListingLoaderComponent';
import { FilterAdminUserComponent } from '../FilterAdminUserComponent/FilterAdminUserComponent';
import { ThreeDotsAdminListingComponent } from '../../../ThreeDotsListingComponent/ThreeDotsAdminListingComponent';
import { ThreeDotsAdminUserComponent } from '../../../ThreeDotsListingComponent/ThreeDotsAdminUserComponent';
import { getLastSeen } from '../../../../helper/DeviceHelper';
import EasyFilterAdminUserComponent from '../EasyFilterAdminUserComponent/EasyFilterAdminUserComponent';

export const AdminDashboardUserComponent = () => {

  // const [currentUser, setCurrentUser] = useState("");
  const navigate = useNavigate();
  const [isMenuVisible, setMenuVisible] = useState(false);

  const [offset, setOffset] = useState(0);
  const [sortBy, setSortBy] = useState('date_desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [totalCount, settotalCount] = useState("");


  const [activePostStatus, setActivePostStatusFilter] = useState(null);
  //Query to get data
  const { data, isSuccess, isError, refetch, isLoading, isFetching } = useGetAdminDashboardUsersQuery(
    { offset, sortBy, postStatus: activePostStatus, search: searchTerm },
    {
      // Refetch when component mounts or tab becomes active
      refetchOnMountOrArgChange: true
    }
  );

  // const [deleteRadarDevice] = useDeleteContactMutation();

  //flag var to show listing when loading complete when sekelteon layout is going to stop
  const [loadingComplete, setLoadingComplete] = useState(false);

  //Var is reponsible to show listings and other thing as well
  let content;

  //Sort by text show on button
  const [selectedSortOption, setSelectedSortOption] = useState('');

  //For SortBy Dropdown
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  //For Threedots in listing dropdown
  const [activeThreeDotId, setActiveThreeDotId] = useState(null);
  const threeDotsRef = useRef(null);
  const [isClickBlocked, setIsClickBlocked] = useState(false);


  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  }

  const toggleThreeDotsDropdown = (id) => {
    if (isClickBlocked) return; // Prevent rapid toggle

    if (activeThreeDotId === id) {
      setActiveThreeDotId(null); // Close if the same one is clicked again
      setIsClickBlocked(true);
      setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
    } else {
      setActiveThreeDotId(id); // Open the clicked one
    }
  };

  // const handleThreeDotsClick = (id) => {
  //   console.log("Three dots clicked for id:", id);
  //   // setActiveThreeDotId(activeThreeDotId === index ? null : index);
  //   // if (isClickBlocked) return; // Prevent rapid toggle

  //   if (activeThreeDotId === id) {
  //     setActiveThreeDotId(null); // Close if the same one is clicked again
  //     // setIsClickBlocked(true);
  //     // setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
  //   } else {
  //     setActiveThreeDotId(id); // Open the clicked one
  //   }
  // };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };


  //Set value for sortby selected option on button text
  const handleOptionSelect = (option) => {
    if (option.value !== sortBy) {
      setSortBy(option.value);
      setOffset(0); // Reset offset when sort option changes
    }
    setSelectedSortOption(option);
    setDropdownVisible(false);
  };

  //DashbaordFilterButtonGroup component to get data
  const handleFilterClick = (filterLabel) => {
    if (activePostStatus === filterLabel) {
      setSortBy('date_desc')
      setSelectedSortOption('')
      setActivePostStatusFilter(null);
    } else {
      setSortBy('date_desc')
      setSelectedSortOption('')
      setActivePostStatusFilter(filterLabel);
    }
    setOffset(0); // Reset offset when changing filters
  };

  //Setting offset for loadmore when user clicked on load more button(this trigger query to fetch new data)
  // Reset offset when component mounts or remounts
  // useEffect(() => {
  //   setOffset(0);
  // }, []);
  // // Reset offset when sort option changes
  // useEffect(() => {
  //   setOffset(0);
  // }, [sortBy]);

  const handleLoadMore = () => {
    if (data?.hasMore && !isFetching) {
      setOffset(currentOffset => currentOffset + 8);
    }
  };

  const showLoadMore = data?.hasMore &&
    data.users.length < data.totalCount &&
    !isFetching;





  //Onclick listener for sortby dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  const handleThreeDotsClick = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveThreeDotId(activeThreeDotId === index ? null : index);
  };

  // Modified click outside handler
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     // Check if click is outside both the three dots icon and the dropdown
  //     if (
  //       threeDotsRef.current &&
  //       !threeDotsRef.current.contains(event.target) &&
  //       (!dropdownRef.current || !dropdownRef.current.contains(event.target))
  //     ) {
  //       setActiveThreeDotId(null);
  //     }
  //   };

  //   document.addEventListener("click", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Ensure event target is not inside any active dropdown
      if (!event.target.closest(".admin-dashboard-user-dropdown")) {
        setActiveThreeDotId(null);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  //Onclick listener for threedots dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        threeDotsRef.current &&
        !threeDotsRef.current.contains(event.target) &&
        (!dropdownRef.current || !dropdownRef.current.contains(event.target))
      ) {
        setActiveThreeDotId(null);
        setIsClickBlocked(false); // Reset blocked state when closing
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
      setIsClickBlocked(false); // Reset on cleanup
    };
  }, []);

  // Listen for tab visibility changes
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refetch();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [refetch]);


  //Success true then pause to show sekelton layout
  useEffect(() => {
    if (isSuccess) {
      // Set totalcount in listing counter layout
      settotalCount(data.totalCount);
      // Set timeout to delay the loading completion
      const timer = setTimeout(() => {
        setLoadingComplete(true);
      }, 1000); // 1 second

      // Cleanup timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);


  if (isError) {
    content = <ErrorShowComponent message1={'Something went wrong...'} />
  }

  if (isLoading) {
    content = <SkeletonTableListingLoaderComponent />;
  } else if (loadingComplete && isSuccess) {

    if (data.users.length > 0) {
      // dataLength = data.users.length;
      content = data.users.map((user, index) =>
      (
        // <div className="admin-dashboard-user-table-header-cell-parent" key={index}>


        //   <div className="admin-dashboard-user-table-cell10">
        //     {/* <div className="admin-dashboard-user-cell-content">
        //       <input className="admin-dashboard-user-checkbox-wrapper" type="checkbox" />
        //     </div> */}
        //     <div className="admin-dashboard-user-avatar" style={{
        //       backgroundImage: contact.profileImage
        //         && `url(${contact.profileImage})`
        //     }}>
        //       <div className="admin-dashboard-user-contrast-border">
        //         {/* <div className="admin-dashboard-user-avatar-online-indicator"></div> */}
        //       </div>
        //     </div>
        //     <div className="admin-dashboard-user-dropdown1">
        //       <div className="admin-dashboard-user-cell-title">{`${contact.user_usermeta.firstName} ${contact.user_usermeta.lastName}`}</div>
        //       <div className="admin-dashboard-user-supporting-text4">{contact.user_usermeta.userWorkTitle}</div>
        //     </div>
        //   </div>

        //   <div className="admin-dashboard-user-table-cell20">
        //     <div className="admin-dashboard-user-location-content">
        //       <img
        //         className="admin-dashboard-user-upload-cloud-02-icon"
        //         alt=""
        //         src={markerpin02}
        //       />
        //       <div className="admin-dashboard-user-text36">India, {contact.user_usermeta.userState}</div>
        //     </div>
        //   </div>


        //   <div className="admin-dashboard-user-table-cell30">
        //     <div className="admin-dashboard-user-text47">2 hours back</div>
        //   </div>

        //   <div className="admin-dashboard-user-table-cell40">
        //     <div className="admin-dashboard-user-text47">2 hours back</div>
        //   </div>

        //   <div className="admin-dashboard-user-table-cell50">
        //     <div className="admin-dashboard-user-text47">2 hours back</div>
        //   </div>

        //   <div className="admin-dashboard-user-table-cell60">
        //     <div className="admin-dashboard-user-text47">2 hours back</div>
        //   </div>




        //   <div className="admin-dashboard-user-table-cell70">
        //     {/* <div className="admin-dashboard-user-dropdown" onClick={() => {
        //       toggleThreeDotsDropdown(index)
        //     }
        //     } ref={threeDotsRef}>
        //       <img
        //         className="admin-dashboard-user-dots-vertical-icon"
        //         alt=""
        //         src={dotsvertical}
        //       />
        //     </div> */}
        //     {/* <div className="admin-dashboard-user-dropdown" onClick={() => handleThreeDotsClick(index)} ref={threeDotsRef}> */}
        //     <div
        //       className="admin-dashboard-user-dropdown"
        //       onClick={(e) => {
        //         e.stopPropagation(); // Prevent event bubbling
        //         handleThreeDotsClick(index);
        //       }}
        //       ref={threeDotsRef}
        //     >
        //       <img src={dotsvertical} alt="options" />
        //     </div>
        //     {/* {activeThreeDotId === index && (
        //       <ThreeDotsContactListingComponent id={contact.contact_pk_id} />)} */}
        //     {activeThreeDotId === index && (
        //       <div ref={dropdownRef}>
        //         <ThreeDotsContactListingComponent
        //           id={contact.contact_pk_id}
        //           onClose={() => setActiveThreeDotId(null)}
        //         />
        //       </div>
        //     )}
        //   </div>

        // </div>
        <div className="admin-dashboard-user-table-header-cell-parent" key={index}>
          {user.user_usermeta?.tax_user_type?.id == 5
            ?
            <>


              <div className="admin-dashboard-user-table-cell10">
                {/* <div className="admin-dashboard-user-cell-content">
            <input className="admin-dashboard-user-checkbox-wrapper" type="checkbox" />
          </div> */}
                <div className="admin-dashboard-user-avatar" style={{
                  backgroundImage: user.profileImage
                    && `url(${user.profileImage})`
                }} >
                  {/* <div className="admin-dashboard-user-contrast-border">
              <div className="admin-dashboard-user-avatar-online-indicator"></div>
            </div> */}
                </div>
                <div className="admin-dashboard-user-dropdown1">
                  <div className="admin-dashboard-user-cell-title">{user.displayName}</div>
                  <div className="admin-dashboard-user-supporting-text4">{user.user_usermeta.userWorkTitle}, {user.user_usermeta.tax_country.name}</div>
                </div>
              </div>

              <div className="admin-dashboard-user-table-cell20">
                <div className="admin-dashboard-user-location-content">
                  <div className="admin-dashboard-user-text36">{user.user_usermeta.userBusinessName}</div>
                </div>
              </div>


              <div className="admin-dashboard-user-table-cell30">
                <div className="admin-dashboard-user-text47">Not required</div>
              </div>

              <div className="admin-dashboard-user-table-cell40">
                <div className="admin-dashboard-user-text47">{user.user_usermeta.tax_user_type.name}</div>
              </div>

              <div className="admin-dashboard-user-table-cell50">
                <div className="admin-dashboard-user-text47">{getLastSeen(user.createdAt)}</div>
              </div>

              <div className="admin-dashboard-user-table-cell60">
                <div className="admin-dashboard-user-text47">{user.tax_user_status.name}</div>
              </div>
              <div className="admin-dashboard-user-table-cell40"
              >
                {/* <div className="admin-dashboard-user-dropdown" onClick={() => {
                 toggleThreeDotsDropdown(index)
               }
               } ref={threeDotsRef}>
                 <img
                   className="admin-dashboard-user-dots-vertical-icon"
                   alt=""
                   src={dotsvertical}
                 />
               </div>  */}
                <div className="admin-dashboard-user-dropdown" onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleThreeDotsClick(e, index);
                }}
                  ref={threeDotsRef}>
                  <div
                    className="admin-dashboard-user-dropdown"
                  //  onClick={(e) => {
                  //    e.stopPropagation(); // Prevent event bubbling
                  //    handleThreeDotsClick(index);
                  //  }}
                  //  ref={threeDotsRef}
                  >
                  </div>
                  <img src={dotsvertical} alt="options" />

                  {activeThreeDotId === index && (
                    <div ref={dropdownRef}>
                      <ThreeDotsAdminUserComponent
                        option={user.tax_user_status.slug}
                        userId={user.id}
                        onClose={() => {
                          setActiveThreeDotId(null);
                          setIsClickBlocked(false);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>


              {/* <div className="admin-dashboard-user-table-cell70">


            <img
              className="admin-dashboard-user-table-dots-vertical-icon"
              alt=""
              src={dotsvertical}
            />
            <div className="team-member-table-text36">
                                          
                                         </div>
          </div> */}


            </>
            :
            <>


              <div className="admin-dashboard-user-table-cell10">
                {/* <div className="admin-dashboard-user-cell-content">
            <input className="admin-dashboard-user-checkbox-wrapper" type="checkbox" />
          </div> */}
                <div className="admin-dashboard-user-avatar" style={{
                  backgroundImage: user.profileImage
                    && `url(${user.profileImage})`
                }} >
                  {/* <div className="admin-dashboard-user-contrast-border">
              <div className="admin-dashboard-user-avatar-online-indicator"></div>
            </div> */}
                </div>
                <div className="admin-dashboard-user-dropdown1">
                  <div className="admin-dashboard-user-cell-title">{user.displayName}</div>
                  <div className="admin-dashboard-user-supporting-text4">{user.user_usermeta?.userWorkTitle || 'Nil'}, {user.user_usermeta?.tax_country?.name || 'Nil'}</div>
                </div>
              </div>

              <div className="admin-dashboard-user-table-cell20">
                <div className="admin-dashboard-user-location-content">
                  <div className="admin-dashboard-user-text36">{user.user_usermeta?.userBusinessName || 'Nil'}</div>
                </div>
              </div>


              <div className="admin-dashboard-user-table-cell30">
                <div className="admin-dashboard-user-text47">{user.user_order[0]?.order_plan?.planName || 'Not Chosen'}</div>
              </div>

              <div className="admin-dashboard-user-table-cell40">
                <div className="admin-dashboard-user-text47">{user.user_usermeta?.tax_user_type.name || 'Normal'}</div>
              </div>

              <div className="admin-dashboard-user-table-cell50">
                <div className="admin-dashboard-user-text47">{getLastSeen(user.createdAt)}</div>
              </div>

              <div className="admin-dashboard-user-table-cell60">
                <div className="admin-dashboard-user-text47">{user.tax_user_status.name}</div>
              </div>

              <div className="admin-dashboard-user-table-cell40"
              >
                {/* <div className="admin-dashboard-user-dropdown" onClick={() => {
                 toggleThreeDotsDropdown(index)
               }
               } ref={threeDotsRef}>
                 <img
                   className="admin-dashboard-user-dots-vertical-icon"
                   alt=""
                   src={dotsvertical}
                 />
               </div>  */}
                <div className="admin-dashboard-user-dropdown" onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleThreeDotsClick(e, index);
                }}
                  ref={threeDotsRef}>
                  <div
                    className="admin-dashboard-user-dropdown"
                  //  onClick={(e) => {
                  //    e.stopPropagation(); // Prevent event bubbling
                  //    handleThreeDotsClick(index);
                  //  }}
                  //  ref={threeDotsRef}
                  >
                  </div>
                  <img src={dotsvertical} alt="options" />

                  {activeThreeDotId === index && (
                    <div ref={dropdownRef}>
                      <ThreeDotsAdminUserComponent
                        option={user.tax_user_status.slug}
                        userId={user.id}
                        onClose={() => {
                          setActiveThreeDotId(null);
                          setIsClickBlocked(false);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>

              {/* <div className="admin-dashboard-user-table-cell70">


            <img
              className="admin-dashboard-user-table-dots-vertical-icon"
              alt=""
              src={dotsvertical}
            />
            <div className="team-member-table-text36">
                                          
                                         </div>
          </div> */}


            </>
          }
        </div>
      ))
    }
    else {
      content = (

        <NoListingFound path="#" />

      )
      // content = <ErrorShowComponent message1={'Something went wrong...'} />

    }

  } else if (!loadingComplete && isSuccess) {
    content = <SkeletonTableListingLoaderComponent />; // Show loader until loadingComplete is true
  }



  return (
    <div className="admin-dashboard-user-prsopective-buyers">
      <header className="admin-dashboard-user-main">
        <div className="admin-dashboard-user-card-header">
          <div className="admin-dashboard-user-content1">
            <div className="admin-dashboard-user-text-and-supporting-text1">
              <div className="admin-dashboard-user-text-and-badge">
                <a className="admin-dashboard-user-text2">Users</a>
                {/* <div className="admin-dashboard-user-badge">
                  <div className="admin-dashboard-user-text3">240 have shown interest</div>
                </div> */}
              </div>
              <div className="admin-dashboard-user-supporting-text1">
                All Users
              </div>
            </div>
            <div className="admin-dashboard-user-actions1" style={{ position: "relative" }}>

              <div className="admin-dashboard-user-actions2">
                <div className="admin-dashboard-user-input-dropdown">
                  {/* <div className="admin-dashboard-user-input-with-label">
                  <div className="admin-dashboard-user-label">Search</div>
                  <div className="admin-dashboard-user-input">
                    <div className="admin-dashboard-user-content3">
                      <img
                        className="admin-dashboard-user-search-lg-icon"
                        alt=""
                        src={searchlg}
                      />

                      <input
                        className="admin-dashboard-user-text17"
                        placeholder="Magnetom"
                        type="text"
                      />

                      <div className="admin-dashboard-user-supporting-text2">@olivia</div>
                    </div>
                  </div>
                </div> */}
                  <SearchComponentAdmin onSearch={handleSearch} />

                </div>

                <button className="admin-dashboard-user-button4" onClick={toggleDropdown}>
                  <img
                    className="admin-dashboard-user-upload-cloud-02-icon"
                    alt=""
                    src={filterlines}
                  />

                  <div className="admin-dashboard-user-text-padding4" ref={dropdownRef}>
                    <div className="admin-dashboard-user-text18">Filter By{selectedSortOption && `: ${selectedSortOption.label}`}</div>
                    {isDropdownVisible && <FilterAdminUserComponent onOptionSelect={handleOptionSelect} />}
                  </div>
                  <img
                    className="admin-dashboard-user-placeholder-icon3"
                    alt=""
                    src="./public/placeholder.svg"
                  />
                </button>
              </div>

              {/* <div className="admin-dashboard-user-button">
                <img
                  className="admin-dashboard-user-placeholder-icon3"
                  alt=""
                  src="../../../placeholder2.svg"
                />

                <div className="admin-dashboard-user-text-padding2">
                  <div className="admin-dashboard-user-text4">Tertiary</div>
                </div>
                <img
                  className="admin-dashboard-user-placeholder-icon3"
                  alt=""
                  src="./public/placeholder2.svg"
                />
              </div>
              <div className="admin-dashboard-user-button1">
                <img
                  className="admin-dashboard-user-placeholder-icon3"
                  alt=""
                  src="./public/placeholder3.svg"
                />

                <div className="admin-dashboard-user-text-padding3">
                  <div className="admin-dashboard-user-text4">Secondary</div>
                </div>
                <img
                  className="admin-dashboard-user-placeholder-icon3"
                  alt=""
                  src="./public/placeholder3.svg"
                />
              </div> */}
              {/* <button className="admin-dashboard-user-button2">
                <img
                  className="admin-dashboard-user-upload-cloud-02-icon"
                  alt=""
                  src={uploadcloud02}
                />

                <div className="admin-dashboard-user-text-padding4">
                  <a className="admin-dashboard-user-text6">Import</a>
                </div>
                <img
                  className="admin-dashboard-user-placeholder-icon3"
                  alt=""
                  src="./public/placeholder.svg"
                />
              </button>
              <button className="admin-dashboard-user-button3">
                <img
                  className="admin-dashboard-user-upload-cloud-02-icon"
                  alt=""
                  src={plus}
                />

                <div className="admin-dashboard-user-text-padding4" >
                  <a className="admin-dashboard-user-text7">Add Contact</a>
                </div>
                <img
                  className="admin-dashboard-user-placeholder-icon3"
                  alt=""
                  src="./public/placeholder1.svg"
                />
              </button> */}

              {/* <SlideOutMenu isVisible={isMenuVisible} onClose={handleCloseMenu} /> */}

            </div>
            {/* <div className="admin-dashboard-user-dropdown">
              <img
                className="admin-dashboard-user-dots-vertical-icon"
                loading="lazy"
                alt=""
                src={dotsvertical}
              />
            </div> */}
          </div>
          <div className="admin-dashboard-business-section-wrapper">
            <div className="admin-dashboard-business-section1">
              <div className="admin-dashboard-business-container1">
                <div className="admin-dashboard-business-filters-bar">
                  <div className="admin-dashboard-business-content17">
                    <div className="admin-dashboard-business-dropdowns">

                      <EasyFilterAdminUserComponent
                        handleFilterClick={handleFilterClick}
                        activePostStatus={activePostStatus}
                        activeCount={data?.totalCountActive || 0}
                        unactiveCount={data?.totalCountUnactive || 0}
                      />

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <img
            className="admin-dashboard-user-divider-icon"
            loading="lazy"
            alt=""
            src={divider}
          /> */}
        </div>
        {/* <div className="admin-dashboard-user-filters-bar">
          <div className="admin-dashboard-user-content2">

            <ContactFilterButtonGroup
              handleFilterClick={handleFilterClick}
              activePostStatus={activePostStatus}
            />

         
          </div>
        </div> */}
      </header>


      <form className="admin-dashboard-user-table">
        {/* <div className="admin-dashboard-user-card-header1">
          <div className="admin-dashboard-user-content4">
            <div className="admin-dashboard-user-text-and-supporting-text2">
              <div className="admin-dashboard-user-text-and-badge1">
                <div className="admin-dashboard-user-table-header-title">Viewed listing</div>
                <div className="admin-dashboard-user-badge1">
                  <div className="admin-dashboard-user-text19">100 users</div>
                </div>
              </div>
              <div className="admin-dashboard-user-supporting-text3">
                Manage your team members and their account permissions here.
              </div>
            </div>
            <div className="admin-dashboard-user-actions3">
              <div className="admin-dashboard-user-button5">
                <img
                  className="admin-dashboard-user-placeholder-icon3"
                  alt=""
                  src="./public/placeholder2.svg"
                />

                <div className="admin-dashboard-user-text-padding7">
                  <div className="admin-dashboard-user-text20">Tertiary</div>
                </div>
                <img
                  className="admin-dashboard-user-placeholder-icon3"
                  alt=""
                  src="./public/placeholder2.svg"
                />
              </div>
              <div className="admin-dashboard-user-button6">
                <img
                  className="admin-dashboard-user-placeholder-icon3"
                  alt=""
                  src="./public/placeholder3.svg"
                />

                <div className="admin-dashboard-user-text-padding7">
                  <div className="admin-dashboard-user-text21">Secondary</div>
                </div>
                <img
                  className="admin-dashboard-user-placeholder-icon3"
                  alt=""
                  src="./public/placeholder3.svg"
                />
              </div>
              <div className="admin-dashboard-user-button7">
                <img
                  className="admin-dashboard-user-placeholder-icon3"
                  alt=""
                  src="./public/placeholder.svg"
                />

                <div className="admin-dashboard-user-text-padding7">
                  <div className="admin-dashboard-user-text22">Secondary</div>
                </div>
                <img
                  className="admin-dashboard-user-placeholder-icon3"
                  alt=""
                  src="./public/placeholder.svg"
                />
              </div>
              <div className="admin-dashboard-user-button8">
                <img
                  className="admin-dashboard-user-placeholder-icon3"
                  alt=""
                  src="./public/placeholder1.svg"
                />

                <div className="admin-dashboard-user-text-padding7">
                  <div className="admin-dashboard-user-text23">Primary</div>
                </div>
                <img
                  className="admin-dashboard-user-placeholder-icon3"
                  alt=""
                  src="./public/placeholder1.svg"
                />
              </div>
            </div>
            <div className="admin-dashboard-user-dropdown">
              <img
                className="admin-dashboard-user-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
            </div>
          </div>
          <img className="admin-dashboard-user-divider-icon" alt="" src="./public/divider.svg" />
        </div> */}

        {/* Table */}
        <div className="admin-dashboard-user-table-row">

            {/* Name */}



            <div className="admin-dashboard-user-table-header-cell1">
              {/* <input className="admin-dashboard-user-checkbox-wrapper" type="checkbox" /> */}
              <div className="admin-dashboard-user-table-header">
                <a className="admin-dashboard-user-column-title">User</a>
                {/* <img
                  className="admin-dashboard-user-arrow-down-icon1"
                  alt=""
                  src={arrowdown}
                /> */}
              </div>
            </div>

            {/* <div className="admin-dashboard-user-header-title">
<a className="admin-dashboard-user-text24">Name</a>
</div>
<div className="admin-dashboard-user-header-icon">
<img
  className="admin-dashboard-user-arrow-down-icon"
  alt=""
  src={arrowdown}
/>
</div> */}

            {/* Business  */}

            <div className="admin-dashboard-user-table-header-cell2">
              <div className="admin-dashboard-user-table-header">
                <a className="admin-dashboard-user-column-title">Business</a>

              </div>
            </div>





            {/* Subscriptin plan  */}

            <div className="admin-dashboard-user-table-header-cell3">
              <div className="admin-dashboard-user-table-header">
                <a className="admin-dashboard-user-column-title">Subscription Plan</a>

              </div>
            </div>

            {/* Role */}

            <div className="admin-dashboard-user-table-header-cell4">
              <div className="admin-dashboard-user-table-header">
                <a className="admin-dashboard-user-column-title">Role</a>

              </div>
            </div>

            {/* Last seen  */}

            <div className="admin-dashboard-user-table-header-cell5">
              <div className="admin-dashboard-user-table-header">
                <a className="admin-dashboard-user-column-title">Last seen</a>

              </div>
            </div>

            {/* Account status  */}

            <div className="admin-dashboard-user-table-header-cell6">
              <div className="admin-dashboard-user-table-header">
                <a className="admin-dashboard-user-column-title">Account status</a>

              </div>
            </div>

            {/* Action  */}

            <div className="admin-dashboard-user-table-header-cell7">
              <div className="admin-dashboard-user-table-header2">
                <div className="admin-dashboard-user-action-column-title">Action</div>
                <img
                  className="admin-dashboard-user-arrow-down-icon2"
                  alt=""
                  src={arrowdown}
                />
              </div>
            </div>

          </div>
        <div className="admin-dashboard-user-table-content">

          


          {/*
           */}
          {content}


        </div>

        {/* Load More Button */}
        {showLoadMore && (
          <div className="admin-dashboard-frame-wrapper44">
            <div className="admin-dashboard-frame-parent72">
              <div className="admin-dashboard-buttonsbutton-wrapper1">

                <button className='contact-buttonsbutton-wrapper1-child'
                  onClick={handleLoadMore}
                  disabled={isFetching}
                >
                  <div className="admin-dashboard-buttonsbutton28">
                    <img
                      className="admin-dashboard-arrow-narrow-left-icon"
                      alt=""
                      src={arrowdown1}
                    />
                    <div className="admin-dashboard-search-input">
                      <div className="admin-dashboard-text146">{isFetching ? 'Loading...' : 'Load More'}</div>
                    </div>
                  </div>
                </button>

              </div>
            </div>
          </div>
        )}

        {/* <div className="admin-dashboard-user-pagination">
        <div className="admin-dashboard-user-pagination-wrapper">
          <div className="admin-dashboard-user-button-wrap">
            <button className="admin-dashboard-user-buttonsbutton2">
              <img
                className="admin-dashboard-user-upload-cloud-02-icon"
                alt=""
                src={arrowleft}
              />

              <div className="admin-dashboard-user-text-padding4">
                <div className="admin-dashboard-user-text56">Previous</div>
              </div>
              <img
                className="admin-dashboard-user-placeholder-icon3"
                alt=""
                src="./public/placeholder.svg"
              />
            </button>
          </div>
        </div>
        <div className="admin-dashboard-user-pagination-numbers">
          <div className="admin-dashboard-user-pagination-number-base">
            <div className="admin-dashboard-user-content5">
              <div className="admin-dashboard-user-number">1</div>
            </div>
          </div>
          <div className="admin-dashboard-user-pagination-number-base1">
            <div className="admin-dashboard-user-content6">
              <div className="admin-dashboard-user-number1">2</div>
            </div>
          </div>
          <div className="admin-dashboard-user-pagination-number-base2">
            <div className="admin-dashboard-user-content7">
              <div className="admin-dashboard-user-number2">3</div>
            </div>
          </div>
          <div className="admin-dashboard-user-pagination-number-base1">
            <div className="admin-dashboard-user-content8">
              <div className="admin-dashboard-user-number3">...</div>
            </div>
          </div>
          <div className="admin-dashboard-user-pagination-number-base1">
            <div className="admin-dashboard-user-content6">
              <div className="admin-dashboard-user-number1">8</div>
            </div>
          </div>
          <div className="admin-dashboard-user-pagination-number-base1">
            <div className="admin-dashboard-user-content6">
              <div className="admin-dashboard-user-number1">9</div>
            </div>
          </div>
          <div className="admin-dashboard-user-pagination-number-base2">
            <div className="admin-dashboard-user-content11">
              <div className="admin-dashboard-user-number6">10</div>
            </div>
          </div>
        </div>
        <div className="admin-dashboard-user-pagination-wrapper">
          <div className="admin-dashboard-user-button-wrap1">
            <button className="admin-dashboard-user-buttonsbutton3">
              <img
                className="admin-dashboard-user-placeholder-icon3"
                alt=""
                src="./public/placeholder.svg"
              />

              <div className="admin-dashboard-user-text-padding4">
                <div className="admin-dashboard-user-text57">Next</div>
              </div>
              <img
                className="admin-dashboard-user-upload-cloud-02-icon"
                alt=""
                src={arrowright}
              />
            </button>
          </div>
        </div>
      </div> */}
      </form>
    </div>
  )
}
