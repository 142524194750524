import React from "react";
// import "./AddAddressForm.css"
import React, { useContext, useRef, useState, useEffect } from "react";
import { Formik, Form, useField, useFormikContext } from "formik";
import {
  updateAddressField,
  clearAddressForm,
  updateRemoteField,
} from "./AddAddressSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { decryptData, decryptErrorData } from "../../helper/AESHelper";
import * as Yup from "yup";

import divider from "../../icons/Gerator/profile_settings/divider.svg";
import homeline_1 from "../../icons/Gerator/profile_settings/homeline-1.svg";
import chevronright from "../../icons/Gerator/profile_settings/chevronright.svg";
import divider1 from "../../icons/Gerator/profile_settings/divider1.svg";
import divider2 from "../../icons/Gerator/profile_settings/divider2.svg";
import help_icon from "../../icons/Gerator/profile_settings/help-icon.svg";
import placeholder from "../../icons/Gerator/profile_settings/placeholder.svg";
import chevrondown from "../../icons/Gerator/profile_settings/chevrondown.svg";
import placeholder3 from "../../icons/Gerator/profile_settings/placeholder3.svg";
import { useEffect } from "react";
import AddAddressFormContext from "../../screens/Address/AddAddress/AddAddressFormContext";
import { useAddAddressMutation } from "../../api/AddressAPI";
import MapComponent from "../MapComponent/MapComponent";
import { objectToAddressFormData } from "../../helper/AddressHelper";
import { ErrorShowComponent } from "../ErrorShowComponent/ErrorShowComponent";

const MySelect = ({ validate, label, ...props }) => {
  const [field, meta] = useField(props);

  const inputStyle = meta.touched && meta.error ? { borderColor: "red" } : {}; //New code for border colour

  let fieldValidationData = null;
  // console.log("MySelect inside validation result");
  // console.log(validate);

  if (validate?.length > 0) {
    validate.some((field) => {
      // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  // if (fieldValidationData != null) {
  //   // console.log(fieldValidationData);
  // }
  // {
  //   fieldValidationData !== null ? (
  //     <div className="error">{fieldValidationData.msg}</div>
  //   ) : null
  // }

  return (
    <>
      <div className="address-form-section-label1">
        <label className="address-form-title">
          {label} <span className="address-form-span">*</span>{" "}
        </label>
      </div>
      <div className="address-form-input-dropdown1">
        <div className="address-form-textarea-input-field">
          <div className="address-form-input8">
            <div className="address-form-content29">
              <select
                className="address-form-profile-content-custom-23 down-icon-hide address-profile-form-text63"
                style={inputStyle} //New code for border colour
                {...field}
                {...props}
              />
            </div>
            {/* <img
              className="address-form-search-lg-icon"
              alt=""
              src={chevrondown}
            /> */}
          </div>
        </div>
        {meta.touched && meta.error ? (
          <div className="address-form-hint-text2 error-color">
            {meta.error}
          </div>
        ) : null}

        {fieldValidationData !== null ? (
          <div className="address-form-hint-text2 error-color">
            {fieldValidationData.msg}
          </div>
        ) : null}
      </div>
    </>
  );
};

const MyTextInput = ({ validate, label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  let fieldValidationData = null;
  if (validate?.length > 0) {
    validate.some((field) => {
      // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  return (
    <>
      <div className="address-form-section-label1">
        <label className="address-form-title" htmlFor={props.id || props.name}>
          {label} <span className="address-form-span">*</span>
        </label>
      </div>
      <div className="address-form-input-field1">
        <div className="address-form-textarea-input-field">
          <div className="address-form-input4">
            <div className="address-form-content19">
              <input
                className="address-form-extra-input-info"
                // style={inputStyle}  //New code for border colour
                {...field}
                {...props}
              />
            </div>

            <img className="address-form-help-icon" alt="" src={help_icon} />
          </div>
        </div>
        {meta.touched && meta.error ? (
          <div className="address-form-hint-text2 error-color">
            {meta.error}
          </div>
        ) : null}

        {fieldValidationData !== null ? (
          <div className="address-form-hint-text2 error-color">
            {fieldValidationData.msg}
          </div>
        ) : null}
      </div>
    </>
  );
};

const MyTextInputNotMandatory = ({ validate, label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  let fieldValidationData = null;
  if (validate?.length > 0) {
    validate.some((field) => {
      // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  return (
    <>
      <div className="address-form-section-label1">
        <label className="address-form-title" htmlFor={props.id || props.name}>
          {label}
          {/* <span className="address-form-span">*</span> */}
        </label>
      </div>
      <div className="address-form-input-field1">
        <div className="address-form-textarea-input-field">
          <div className="address-form-input4">
            <div className="address-form-content19">
              <input
                className="address-form-extra-input-info"
                // style={inputStyle}  //New code for border colour
                {...field}
                {...props}
              />
            </div>

            <img className="address-form-help-icon" alt="" src={help_icon} />
          </div>
        </div>
        {meta.touched && meta.error ? (
          <div className="address-form-hint-text2 error-color">
            {meta.error}
          </div>
        ) : null}

        {fieldValidationData !== null ? (
          <div className="address-form-hint-text2 error-color">
            {fieldValidationData.msg}
          </div>
        ) : null}
      </div>
    </>
  );
};

const MyCustomMap = ({ validate, label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  let fieldValidationData = null;
  if (validate?.length > 0) {
    validate.some((field) => {
      // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  return (
    <>
      <div className="address-form-section-label1">
        <label className="address-form-title">
          {label} <span className="address-form-span">*</span>
        </label>
      </div>
      <div className="address-form-input-dropdown1">
        <div className="address-form-textarea-input-field">
          <div className="address-form-input10">
            <MapComponent
              name={props.name}
              width={600}
              height={400}
              zoom={2}
              setSelectedMapCoordinates={props.setSelectedMapCoordinates}
            />
          </div>
        </div>
        {meta.touched && meta.error ? (
          <div className="address-form-hint-text2 error-color">
            {meta.error}
          </div>
        ) : null}

        {fieldValidationData !== null ? (
          <div className="address-form-hint-text2 error-color">
            {fieldValidationData.msg}
          </div>
        ) : null}
      </div>
    </>
  );
};

export const AddAddressForm = ({
  formikRef,
  setShowSuccessMessage,
  currentUsermeta,
}) => {
  // const {setFieldValue } = useFormikContext();
  const dispatch = useDispatch();
  const ulDeviceCategoryRef = useRef(null);
  const ulOemRef = useRef(null);
  const navigate = useNavigate();
  const [selectedAddressType, setSelectedAddressType] = useState("address");
  //Mainly used to show map backend validation reger useEffedt 235 line
  const [showAddressLocationError, setShowAddressLocationError] =
    useState(null);
  const [selectedMapCoordinates, setSelectedMapCoordinates] = useState(null);
  const { backendValidation, setBackendValidation, allTaxonomy } = useContext(
    AddAddressFormContext
  );

  const formData = useSelector((state) => state.addAddress);
  // console.log(formData);

  // const formikRef = useRef(null);
  const [addAddress, { isLoading, isError, error }] = useAddAddressMutation();

  useEffect(() => {
    if (backendValidation?.length > 0) {
      backendValidation.some((field) => {
        // Use 'some' for early termination
        if (field.path === "addressLocation") {
          setShowAddressLocationError(field.msg);
          return;
        }
      });
    }
  }, [backendValidation]);

  useEffect(() => {
    formikRef.current?.setValues(formData);
    // console.log(formikRef.current);
  }, [formData]);

  useEffect(() => {
    dispatch(
      updateAddressField({
        field: "addressLocation",
        value: selectedMapCoordinates,
      })
    );
    // formikRef.current?.setFieldValue('addressLocation', selectedMapCoordinates);
    // console.log(formikRef.current);
  }, [selectedMapCoordinates]);

  // const resetForm = () => {
  //   dispatch(clearAddressForm())
  //   setBackendValidation([])
  //   setDeviceCategorySearchField("");
  //   setOemSearchField("");
  // }

  const handleChange = (event) => {
    const { name, value } = event.target;
    formikRef.current?.setFieldValue(name, value);
    //Saving details into form
    dispatch(updateAddressField({ field: name, value }));
  };

  // Handler function to update state when a checkbox is clicked
  const handleLocationChange = (event) => {
    const { name, value } = event.target;

    if (value === "address") {
      formikRef.current?.resetForm();
      dispatch(clearAddressForm());
    }

    if (value === "remote") {
      console.log("inside location remote change");
      dispatch(updateRemoteField());
    }
    setSelectedAddressType(value);
  };

  const finalSubmit = async (formData, setSubmitting) => {
    try {
      console.log("inside finam submit");

      const response = await addAddress(formData).unwrap();
      //If form was submitted successfully then status return with true, then reseting form
      console.log(response);
      if (response.status) {
        console.log(response);

        // setShowSuccessMessage(response.message)
        setShowSuccessMessage({
          message: "Address added successfully",
          color: "green",
        });
        await new Promise((resolve) => setTimeout(resolve, 1500));
        // Reseting form
        formikRef.current.resetForm();
        // window.location.reload();
        navigate("/dashboard/settings/address");
        // clearing form and state var important
        formikRef.current?.resetForm();
        dispatch(clearAddressForm());
      }
      // if (!response.status) {
      //   setShowSuccessMessage({ message: response.message, color: "red" })
      // }
    } catch (error) {
      if (error.status === 422) {
        console.log(error);
        const decryptedData = decryptErrorData(error);
        console.log(decryptedData);
        setBackendValidation(decryptedData.errors);
        // setShowSuccessMessage({ message: decryptedData.message, color: "red" });
      } else {
        console.log(error);
        // const decryptedData = decryptErrorData(error.data);
        // console.log(decryptedData);
        setShowSuccessMessage({
          message: "Internal server error",
          color: "red",
        });
      }
      setSubmitting(false);
    }
  };

  if (currentUsermeta) {
    return (
      // <form className="add-wanted-device-step-section-header-parent">

      <>
        <Formik
          innerRef={formikRef}
          initialValues={{
            formData,
          }}
          validationSchema={
            selectedAddressType === "address"
              ? Yup.object().shape({
                  addressTitle: Yup.string()
                    .required("This field is mandatory.")
                    .matches(
                      /^[\p{L}0-9][\p{L}0-9\s\-\.\/&,]+$/u,
                      "Should begin with a letter or number and include only allowed characters."
                    )
                    .min(3, "Enter at least 3 characters.")
                    .max(50, "Maximum of 50 characters allowed."),
                  addressBuilding: Yup.string()
                    .required("This field is mandatory.")
                    .matches(
                      /^[\p{L}0-9][\p{L}0-9\s\-\.\/&,]+$/u,
                      "Should begin with a letter or number and include only allowed characters."
                    )
                    .min(3, "Enter at least 3 characters.")
                    .max(35, "Maximum of 35 characters allowed."),
                  addressStreet: Yup.string()
                    .required("This field is mandatory.")
                    .matches(
                      /^[\p{L}0-9][\p{L}0-9\s\-\.\/&,]+$/u,
                      "Should begin with a letter or number and include only allowed characters."
                    )
                    .min(3, "Enter at least 3 characters.")
                    .max(40, "Maximum of 40 characters allowed."),
                  addressState: Yup.string()
                    .test(
                      "is-required-or-empty",
                      "Must start with a letter and use only valid characters.",
                      (value) =>
                        !value || /^[\p{L}][\p{L}\s\-\.\/&,]+$/u.test(value)
                    )
                    .min(3, "Must contain at least 3 characters.")
                    .max(40, "Cannot exceed 40 characters."),
                  addressDistrict: Yup.string()
                    .matches(
                      /^[\p{L}][\p{L}\s\-\.\/&,]+$/u,
                      "Should start with a letter and only use allowed characters."
                    )
                    .min(3, "Enter at least 3 characters.")
                    .max(25, "Cannot exceed 25 characters.")
                    .nullable(),
                  addressTownCity: Yup.string()
                    .required("This field is required.")
                    .matches(
                      /^[\p{L}][\p{L}\s\-\.\/&,]+$/u,
                      "Must begin with a letter and contain only valid characters."
                    )
                    .min(3, "Requires at least 3 characters.")
                    .max(40, "No more than 40 characters allowed."),
                  addressPostcodeZip: Yup.string()
                    .required("This field is required.")
                    .matches(
                      /^[\p{L}0-9]+$/,
                      "Only letters and numbers are allowed."
                    )
                    .min(3, "At least 3 characters required.")
                    .max(10, "Cannot exceed 10 characters."),
                  addressLocation: Yup.object()
                    .shape({
                      lat: Yup.number()
                        .required("Latitude is required.")
                        .min(-90, "Latitude must be between -90 and 90.")
                        .max(90, "Latitude must be between -90 and 90."),
                      lng: Yup.number()
                        .required("Longitude is required.")
                        .min(-180, "Longitude must be between -180 and 180.")
                        .max(180, "Longitude must be between -180 and 180."),
                    })
                    .required("Location information is mandatory."),
                  addressCountry: Yup.string()
                    .required("This field is required.")
                    .test(
                      "OPTION",
                      "Select a valid option.",
                      (value) => value !== "0"
                    ),
                  // addressTitle: Yup.string().required("Field is required").max(50, 'Must be 50 characters or less'),
                  // addressBuilding: Yup.string().required("Field is required").max(35, 'Must be 35 characters or less'),
                  // addressStreet: Yup.string().required("Field is required").max(40, 'Must be 40 characters or less'),
                  // addressState: Yup.string().required("Field is required").max(40, 'Must be 40 characters or less'),
                  // addressTownCity: Yup.string().required("Field is required").max(40, 'Must be 40 characters or less'),
                  // addressDistrict: Yup.string().max(25, 'Must be 25 characters or less'),
                  // addressPostcodeZip: Yup.string().required("Field is required").max(10, 'Must be 10 characters or less'),
                  // addressLocation: Yup.object({
                  //   lat: Yup.number()
                  //     .required("Latitude is required"),
                  //   // .min(-90, "Latitude must be between -90 and 90")
                  //   // .max(90, "Latitude must be between -90 and 90"),
                  //   lng: Yup.number()
                  //     .required("Longitude is required"),
                  //   // .min(-180, "Longitude must be between -180 and 180")
                  //   // .max(180, "Longitude must be between -180 and 180"),
                  // }).required("Field is required"),
                  // addressCountry: Yup.string().required("Field is required").test(
                  //   "OPTION", "Please select a valid option",
                  //   (value) => value !== '0'
                  // ),
                })
              : Yup.object().shape({
                  addressTitle: Yup.string()
                    .required("This field is mandatory.")
                    .matches(
                      /^[\p{L}0-9][\p{L}0-9\s\-\.\/&,]+$/u,
                      "Should begin with a letter or number and include only allowed characters."
                    )
                    .min(3, "Enter at least 3 characters.")
                    .max(50, "Maximum of 50 characters allowed."),
                })
          }
          onSubmit={(values, { setSubmitting }) => {
            setShowAddressLocationError(null);
            console.log(values);
            const encryptedData = objectToAddressFormData(
              values,
              currentUsermeta
            );
            finalSubmit(encryptedData, setSubmitting);
            // resetForm();
            // setSubmitting(false);
          }}
        >
          {({ handleSubmit, isSubmitting, errors, touched }) => {
            return (
              <>
                <Form className="address-form-main2">
                  <div className="address-form-form-content-wrapper">
                    <div className="address-form-form-content">
                      {/* <div className="address-form-toggle-header-parent">
                        <div className="address-form-toggle-header">
                          <div className="address-form-toggle-title">Address</div>
                          <div className="address-form-supporting-text2">
                            Update your photo and personal details here.
                          </div>
                        </div>
                        <div className="address-form-toggle-controls">
                          <div className="address-form-rectangle-group">
                            <div className="address-form-frame-item"></div>
                            <div className="address-form-toggle-base">
                              <div className="address-form-button6"></div>
                            </div>
                            <div className="address-form-switch-label">Explorer mode</div>
                            <div className="address-form-supporting-text3">
                              Save my login details for next time.
                            </div>
                          </div>
                          <button className="address-form-button7">
                            <img
                              className="address-form-placeholder-icon"
                              alt=""
                              src="/profile_settings/placeholder2.svg"
                            />

                            <div className="address-form-button-labels">Badge #1</div>
                            <img
                              className="address-form-placeholder-icon"
                              alt=""
                              src="/profile_settings/placeholder2.svg"
                            />
                          </button>
                          <button className="address-form-button8">
                            <img
                              className="address-form-placeholder-icon"
                              alt=""
                              src={placeholder3}
                            />

                            <div className="address-form-text49">Business Owner</div>
                            <img
                              className="address-form-placeholder-icon"
                              alt=""
                              src={placeholder3}
                            />
                          </button>
                        </div>
                      </div> */}
                      <div className="address-form-divider-container">
                        <img
                          className="address-form-divider-icon3"
                          alt=""
                          src={divider2}
                        />
                      </div>

                      <div className="address-form-content18">
                        <MyTextInput
                          label="Address  Label"
                          name="addressTitle" //New Code
                          type="text"
                          placeholder="Enter Address Title"
                          id="addressTitle"
                          validate={backendValidation}
                          onChange={handleChange}
                          value={formData.addressTitle}
                        />
                        {/* <div className="address-form-section-label1">
                        <div className="address-form-title">Address title <span className="address-form-span">*</span>
                        </div>
                      </div>
                      <div className="address-form-input-field1">
                        <div className="address-form-textarea-input-field">
                          <div className="address-form-input4">
                            <div className="address-form-content19">


                              <input
                                className="address-form-extra-input-info"
                                type="text"
                              />
                            </div>

                            <img
                              className="address-form-help-icon"
                              alt=""
                              src={help_icon}
                            />
                          </div>
                        </div>

                      </div> */}
                        <div className="address-form-hint-text2a">
                          Helps you organize your addresses
                        </div>
                      </div>

                      <div className="address-form-divider"></div>

                      <div className="address-form-tagged-inputs">
                        <div className="address-form-input-field12">
                          <div className="address-form-input-with-label19">
                            <div className="address-form-label21">
                              <span className="address-form-transaction-type">
                                Choose Address type{" "}
                              </span>
                              <span className="address-form-span">*</span>
                            </div>
                          </div>
                          {/* <div className="address-form-hint-text1">
            Field hint text to help user.
          </div> */}
                          <div className="address-form-file-button-container">
                            <div className="address-form-buttonsbutton4">
                              <label
                                htmlFor="address"
                                className="address-form-text-padding6"
                              >
                                <input
                                  // className='address-form-search-lg-icon'
                                  type="radio"
                                  id="address"
                                  name="addressType"
                                  value="address"
                                  checked={selectedAddressType === "address"}
                                  onChange={handleLocationChange}
                                />
                                <div className="address-form-file-button-labels">
                                  Geographic Location
                                </div>
                              </label>
                            </div>

                            <div className="address-form-buttonsbutton4">
                              <label
                                htmlFor="remote"
                                className="address-form-text-padding6"
                              >
                                <input
                                  // className='address-form-search-lg-icon'
                                  type="radio"
                                  id="remote"
                                  name="addressType"
                                  value="remote"
                                  checked={selectedAddressType === "remote"}
                                  onChange={handleLocationChange}
                                />
                                <div className="address-form-file-button-labels">
                                  Remote/Virtual Location
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      {selectedAddressType === "address" && (
                        <div className="address-form-type-container address-form-form-content">
                          <div className="address-form-divider"></div>
                          {/* className='account-update-profile-content-custom-23 down-icon-hide account-update-profile-text63 ' */}
                          <div className="address-form-content18">
                            <MySelect
                              label="Country"
                              name="addressCountry"
                              id="addressCountry"
                              onChange={handleChange}
                              value={formData.addressCountry}
                              validate={backendValidation}
                            >
                              <option
                                value="0"
                                className="account-update-profile-text61"
                              >
                                Select From Dropdown List{" "}
                              </option>
                              {(allTaxonomy.country.isLoading ||
                                allTaxonomy.country.isError) && (
                                <option value="0">Loading...</option>
                              )}
                              {allTaxonomy.country.isSuccess &&
                                allTaxonomy.country.data.map((tax) => (
                                  <option key={tax.id} value={tax.id}>
                                    {tax.name}
                                  </option>
                                ))}
                            </MySelect>
                          </div>

                          <div className="address-form-divider"></div>
                          <div className="address-form-content18">
                            <MyTextInput
                              label="Building"
                              type="text"
                              placeholder="Enter Building"
                              name="addressBuilding"
                              id="addressBuilding"
                              validate={backendValidation}
                              onChange={handleChange}
                              value={formData.addressBuilding}
                            />
                          </div>
                          <div className="address-form-divider"></div>
                          <div className="address-form-content18">
                            <MyTextInput
                              label="Street"
                              type="text"
                              placeholder="Enter Street"
                              name="addressStreet"
                              id="addressStreet"
                              validate={backendValidation}
                              onChange={handleChange}
                              value={formData.addressStreet}
                            />
                          </div>
                          <div className="address-form-divider"></div>

                          <div className="address-form-content18">
                            <MyTextInputNotMandatory
                              label="State"
                              type="text"
                              placeholder="Enter State"
                              name="addressState"
                              id="addressState"
                              validate={backendValidation}
                              onChange={handleChange}
                              value={formData.addressState}
                            />
                          </div>

                          <div className="address-form-divider"></div>
                          <div className="address-form-content18">
                            <MyTextInput
                              label="Town / City"
                              type="text"
                              placeholder="Enter Town / City"
                              name="addressTownCity"
                              id="addressTownCity"
                              validate={backendValidation}
                              onChange={handleChange}
                              value={formData.addressTownCity}
                            />
                          </div>

                          <div className="address-form-divider"></div>

                          <div className="address-form-content18">
                            <MyTextInputNotMandatory
                              label="District"
                              type="text"
                              placeholder="Enter District"
                              name="addressDistrict"
                              id="addressDistrict"
                              validate={backendValidation}
                              onChange={handleChange}
                              value={formData.addressDistrict}
                            />
                          </div>

                          <div className="address-form-divider"></div>
                          <div className="address-form-content18">
                            <MyTextInput
                              label="Postcode / ZIP"
                              type="text"
                              placeholder="Enter Postcode / ZIP"
                              name="addressPostcodeZip"
                              id="addressPostcodeZip"
                              validate={backendValidation}
                              onChange={handleChange}
                              value={formData.addressPostcodeZip}
                            />
                          </div>

                          <div className="address-form-divider"></div>
                          <div className="address-form-content18">
                            <div className="address-form-section-label1">
                              <div className="address-form-title">
                                Select the location on the map{" "}
                                <span className="address-form-span">*</span>
                              </div>
                            </div>
                            <div className="address-form-input-dropdown1">
                              <div className="address-form-textarea-input-field">
                                <div className="address-form-input10">
                                  <MapComponent
                                    width={"600px"}
                                    height={"400px"}
                                    zoom={2}
                                    setSelectedMapCoordinates={
                                      setSelectedMapCoordinates
                                    }
                                  />
                                </div>
                              </div>
                              {errors.addressLocation &&
                                touched.addressLocation && (
                                  <div className="address-form-hint-text2 error-color">
                                    {errors.addressLocation}
                                  </div>
                                )}
                              {showAddressLocationError && (
                                <div className="address-form-hint-text2 error-color">
                                  {showAddressLocationError}
                                </div>
                              )}
                            </div>
                            {/* <MyCustomMap
                            width={600}
                            height={400}
                            zoom={2}
                            setSelectedMapCoordinates={setSelectedMapCoordinates}
                            label="Select the location on the map"
                            name="addressLocation"
                            id="addressLocation"
                            validate={backendValidation}
                          /> */}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="address-form-upload-footer">
                    <div className="address-form-section-footer">
                      <img
                        className="address-form-divider-icon4"
                        alt=""
                        src={divider2}
                      />

                      <div className="address-form-content46">
                        {/* <div className="address-form-buttonsbutton5">
                          <img
                            className="address-form-placeholder-icon"
                            alt=""
                            src={placeholder}
                          />

                          <div className="address-form-text73">Learn more</div>
                          <img
                            className="address-form-placeholder-icon"
                            alt=""
                            src={placeholder}
                          />
                        </div> */}
                        <div className="address-form-actions1">
                          {/* <div className="address-form-button9">
                            <img
                              className="address-form-placeholder-icon"
                              alt=""
                              src={placeholder}
                            />

                            <div className="address-form-text-padding">
                              <div className="address-form-title">Tertiary</div>
                            </div>
                            <img
                              className="address-form-placeholder-icon"
                              alt=""
                              src={placeholder}
                            />
                          </div> */}

                          <button
                            className="address-form-button11"
                            type="submit"
                            onClick={handleSubmit}
                            disabled={isSubmitting}
                          >
                            <img
                              className="address-form-placeholder-icon"
                              alt=""
                              src={placeholder3}
                            />

                            <div className="address-form-text-padding8">
                              <div className="address-form-text76">
                                {isSubmitting ? "Submitting..." : "Submit"}
                              </div>
                            </div>
                            <img
                              className="address-form-placeholder-icon"
                              alt=""
                              src={placeholder3}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </>

      // </form>
    );
  } else {
    return <ErrorShowComponent message1={"Oops! something went wrong."} />;
  }
};
