import React, { useEffect, useRef, useState } from 'react';
import "./InactiveTeamMemberTableComponent.css";
import divider3 from '../../../icons/Gerator/sales_iq/divider3.svg';
import arrowdown from '../../../icons/Gerator/dashboard/arrowdown.svg';
import dotsvertical from '../../../icons/Gerator/sales_iq/dotsvertical.svg';
import arrowdown1 from '../../../icons/Gerator/dashboard/arrowdown1.svg';
import { useLazyGetInactiveMemberForTeamDashboardQuery } from '../../../api/MemberAPI';
import SkeletonTableListingLoaderComponent from '../../SkeletonTableListingLoaderComponent/SkeletonTableListingLoaderComponent';
import { NoTableDataDisplay } from '../../NoTableDataDisplay/NoTableDataDisplay';
import { ErrorShowComponent } from '../../ErrorShowComponent/ErrorShowComponent';
import ThreeDotsMemberComponent from '../../ThreeDotsListingComponent/ThreeDotsMemberComponent';



export const InactiveTeamMemberTableComponent = ({ currentUser, currentUsermeta }) => {

  const [loadingComplete, setLoadingComplete] = useState(false);
  let content;
  const [offSetInactiveMember, setOffSetInactiveMember] = useState(0);

  //For Threedots in listing dropdown
  const [activeThreeDotId, setActiveThreeDotId] = useState(null);
  const threeDotsRef = useRef(null);
  const dropdownRef = useRef(null);
  const [isClickBlocked, setIsClickBlocked] = useState(false);

  //Query to get Active Member 
  const [triggerGetInactiveMemberData, { data: currentInactiveMemberData, isError: isInactiveMembersDataError, error: currentInactiveMemberError, isLoading: isInactiveMembersDataLoading, isSuccess: isInactiveMembersDataSuccess, isFetching: isInactiveMemberDataFetching }] = useLazyGetInactiveMemberForTeamDashboardQuery();

  const handleThreeDotsClick = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveThreeDotId(activeThreeDotId === index ? null : index);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        threeDotsRef.current &&
        !threeDotsRef.current.contains(event.target) &&
        (!dropdownRef.current || !dropdownRef.current.contains(event.target))
      ) {
        setActiveThreeDotId(null);
        setIsClickBlocked(false); // Reset blocked state when closing
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
      setIsClickBlocked(false); // Reset on cleanup
    };
  }, []);

  useEffect(() => {
    if (currentUser) {
      triggerGetInactiveMemberData({ offSetInactiveMember },
        {
          // Refetch when component mounts or tab becomes active
          refetchOnMountOrArgChange: true
        });
    }
  }, [currentUser, offSetInactiveMember]);


  useEffect(() => {
    if (currentInactiveMemberData) {
      console.log(currentInactiveMemberData);
    }
  }, [currentInactiveMemberData])



  //Onclick listener for threedots dropdown
  useEffect(() => {
    const handleThreeDotClickOutside = (event) => {
      if (threeDotsRef.current && !threeDotsRef.current.contains(event.target)) {
        setActiveThreeDotId(null); // Close any open dropdown when clicking outside
      }
    };

    document.addEventListener("mousedown", handleThreeDotClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleThreeDotClickOutside);
    };
  }, []);


  const handleInactiveMemberDataLoadMore = () => {
    if (currentInactiveMemberData?.hasMore && !isInactiveMemberDataFetching) {
      setOffSetInactiveMember(currentOffset => currentOffset + 5);
    }
  };

  const showInactiveMemberDataLoadMore = currentInactiveMemberData?.hasMore &&
    currentInactiveMemberData.inactiveMembers.length < currentInactiveMemberData.totalCount &&
    !isInactiveMemberDataFetching;

  //Success true then pause to show sekelton layout
  useEffect(() => {
    if (isInactiveMembersDataSuccess) {

      const timer = setTimeout(() => {
        setLoadingComplete(true);
      }, 1000); // 1 second

      // Cleanup timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [isInactiveMembersDataSuccess]);

  if (isInactiveMembersDataError) {
    content = <ErrorShowComponent message1={'Internal server error'} />
  }

  if (isInactiveMembersDataLoading) {
    content = <SkeletonTableListingLoaderComponent />;
  } else if (loadingComplete && isInactiveMembersDataSuccess) {

    if (currentInactiveMemberData?.inactiveMembers.length > 0) {
      // currentInactiveMemberData.inactiveMembersLength = currentInactiveMemberData.inactiveMembers.length;
      content = currentInactiveMemberData.inactiveMembers.map((data, index) =>
      (
        <div className="team-member-table-table-header-cell-parent" key={index}>

          <div className="team-member-table-table-cell10-Inactive">

            {/* <div className="team-member-table-cell-content-Inactive">
                                  <input className="team-member-table-checkbox-wrapper" type="checkbox" />
                                </div> */}

            <div className="team-member-table-avatar-Inactive" style={{
              backgroundImage: data?.profileImage
                && `url(${data.profileImage})`
            }}>
              {/* <div className="team-member-table-contrast-border">
                                        <div className="team-member-table-avatar-online-indicator"></div>
                                    </div> */}
            </div>

            <div className="team-member-table-dropdown1-Inactive">
              <div className="team-member-table-cell-title-Inactive">{data.displayName}</div>
              {
                (data.user_usermeta?.userWorkTitle) ?
                  <div className="team-member-table-supporting-text4-Inactive">{data.user_usermeta.userWorkTitle}, {data.user_usermeta.tax_country.name}</div>
                  :
                  <div className="team-member-table-supporting-text4-Inactive">Unknown</div>
              }

            </div>
          </div>

          <div className="team-member-table-table-cell20-Inactive">
            <div className="team-member-table-location-content">


              <div className="team-member-table-text36">{data.email}</div>
            </div>
          </div>

          {/* <div className="team-member-table-table-cell30-Inactive">
                                <div className="team-member-table-location-content">
                                <div className="team-member-table-text36">Access Sort</div>
                                </div>
                              </div>

                              <div className="team-member-table-table-cell30-Inactive">
                                <div className="team-member-table-location-content">


                                  <div className="team-member-table-text36">5-Nov-2024, 22:00</div>
                                </div>
                              </div> */}



          <div className="team-member-table-table-cell40-Inactive" onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleThreeDotsClick(e, index);
          }}
            ref={threeDotsRef}>
            <div className="team-member-table-location-content" >

              <img
                className="team-member-table-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
              {/* <div className="team-member-table-text36">
                                          
                                         </div> */}
              {/* {activeThreeDotId === index && <ThreeDotsMemberComponent option={data.userInvitationStatus.slug} userId={data.id}  />} */}

              {activeThreeDotId === index && (
                <div ref={dropdownRef}>
                  <ThreeDotsMemberComponent
                    option={data.userInvitationStatus.slug}
                    userId={data.id}
                    onClose={() => {
                      setActiveThreeDotId(null);
                      setIsClickBlocked(false);
                    }}
                  />
                </div>
              )}
            </div>

          </div>
        </div>

      ))
    }
    else {
      content = (
        <NoTableDataDisplay />
      )
    }

  } else if (!loadingComplete && isInactiveMembersDataSuccess) {
    content = <SkeletonTableListingLoaderComponent />; // Show loader until loadingComplete is true
  }


  return (
    <>


      <div className="team-member-table-display-form-content-wrapper-Inactive">
        <div className="team-member-table-display-form-content-Inactive">
          <div className="team-member-table-display-toggle-header-parent-Inactive">
            <div className="team-member-table-display-toggle-header-Inactive">
              {/* <div className="team-member-table-display-toggle-title-Inactive">Inactive</div> */}
              {/* <PlanExpiryStatus expiryDate={currentPlanExpiry} planName={currentPlanName} /> */}
            </div>

          </div>
          {/* <div className="team-member-table-display-divider-container-Inactive">
                        <img
                        className="team-member-table-display-divider-icon3-Inactive"
                        alt=""
                        src={divider3}
                        />
                    </div> */}

          <form className="team-member-table-table-Inactive">

            <div className="team-member-table-table-content-Inactive">

              <div className="team-member-table-table-row-Inactive">

                {/* Invoice */}



                <div className="team-member-table-table-header-cell1-Inactive">
                  {/* <input className="team-member-table-checkbox-wrapper" type="checkbox" /> */}
                  <div className="team-member-table-table-header-Inactive">
                    <a className="team-member-table-column-title-Inactive">Name</a>
                    {/* <img
                                className="team-member-table-arrow-down-icon1"
                                alt=""
                                src={arrowdown}
                                /> */}
                  </div>
                </div>


                {/* Location */}

                <div className="team-member-table-table-header-cell2-Inactive">
                  <div className="team-member-table-table-header-Inactive">
                    <a className="team-member-table-column-title-Inactive">Email</a>

                  </div>
                </div>


                {/* Role  */}

                {/* <div className="team-member-table-table-header-cell3-Inactive">
                            <div className="team-member-table-table-header-Inactive">
                                <a className="team-member-table-column-title-Inactive">Status</a>


                            </div>
                            </div> */}

                {/* Last seen  */}
                {/* 
                            <div className="team-member-table-table-header-cell3-Inactive">
                            <div className="team-member-table-table-header-Inactive">
                                <a className="team-member-table-column-title-Inactive">Date & Time</a>


                            </div>
                            </div> */}

                {/* Price  */}

                {/* <div className="team-member-table-table-header-cell4">
                            <div className="team-member-table-table-header">
                                <a className="team-member-table-column-title">Price</a>


                            </div>
                            </div> */}

                {/* Action  */}

                <div className="team-member-table-table-header-cell4-Inactive">
                  <div className="team-member-table-table-header-Inactive">
                    <div className="team-member-table-action-column-title-Inactive">Action</div>
                    <img
                      className="team-member-table-arrow-down-icon2-Inactive"
                      alt=""
                      src={arrowdown}
                    />
                  </div>
                </div>

              </div>



            </div>

            <div className='team-member-table-table-data-custom-main-container'>

              {content}
            </div>

            {/* Load More  */}
            {showInactiveMemberDataLoadMore || undefined && (
              <div className="team-member-table-frame-wrapper44">
                <div className="team-member-table-frame-parent72">
                  <div className="team-member-table-buttonsbutton-wrapper1">

                    <button className='team-member-table-buttonsbutton-wrapper1-child'
                      onClick={handleInactiveMemberDataLoadMore}
                      disabled={isInactiveMemberDataFetching}
                    >
                      <div className="team-member-table-buttonsbutton28">
                        <img
                          className="team-member-table-arrow-narrow-left-icon"
                          alt=""
                          src={arrowdown1}
                        />
                        <div className="team-member-table-search-input">
                          <div className="team-member-table-text146">{isInactiveMemberDataFetching ? 'Loading...' : 'Load More'}</div>
                        </div>
                      </div>
                    </button>

                  </div>
                </div>
              </div>
            )}



          </form>


        </div>
      </div >
    </>
  )
}

