// import Pusher from "pusher-js";

// const pusherConfig = {
//   appKey: "032ab85ab72373f5f617", // Replace with your Pusher app key
//   cluster: "us3", // Replace with your cluster
//   authEndpoint: "http://localhost:3000/api/pusher/auth", // Replace with your auth endpoint
// };

// // Initialize Pusher (only once in the app's lifecycle)
// const pusher = new Pusher(pusherConfig.appKey, {
//   cluster: pusherConfig.cluster,
//   authEndpoint: pusherConfig.authEndpoint,
// });

// /* Subscribe to a channel and bind to events. string channelName - Name of the Pusher channel.
//  string eventName - Name of the event to listen to.
//  Function} callback - Function to execute when the event is triggered.
//  */
// export const subscribeToChannel = (channelName, eventName, callback) => {
//   const channel = pusher.subscribe(channelName);

//   // Bind the event to the callback
//   channel.bind(eventName, (data) => {
//     callback(data);
//   });

//   return channel; // Return channel reference for later unsubscription
// };

// /* Unsubscribe from a channel. channelName - Name of the Pusher channel.
//  */
// export const unsubscribeFromChannel = (channelName) => {
//   pusher.unsubscribe(channelName);
// };

// /* Cleanup all bindings and disconnect Pusher instance.
//  */
// export const disconnectPusher = () => {
//   pusher.disconnect();
// };

// export default pusher;

import Pusher from "pusher-js";

import { ChatAPI } from "../api/ChatAPI";
import { encryptData } from "./AESHelper";
// Initialize Pusher globally
const pusher = new Pusher("b4134b5b9a56f5c6f410", {
  cluster: "us2",
  authEndpoint: `${process.env.REACT_APP_API_URL}/pusher/auth`,
});

export function objectToUpdateStageData(obj, channelName) {

  // Destructure to remove file fields from obj
  const { email, ...values } = obj;

  values.transactionValue = values.transactionValue.replace(/,/g, '');
  values.channelName = channelName;
  console.log(values);


  // // Encrypt the object without the document
  const encryptedValue = encryptData(values); // Custom encryptData function

  return encryptedValue;
}

// Optionally subscribe to a channel later
const subscribeToChannel = (channelName, eventName, callback) => {
  const channel = pusher.subscribe(channelName);
  channel.bind(eventName, callback);
  return channel;
};

// Unsubscribe when done
const unsubscribeFromChannel = (channelName) => {
  const channel = pusher.channel(channelName);
  if (channel) {
    channel.unbind_all();
    channel.unsubscribe();
  }
};


export function getCustomizedDate(sqlDate) {
  // const date = ; 
  const formattedDate = new Date(sqlDate).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return formattedDate;
}

// const subscribeWithMetadataCheck = async (
//   triggerCheckChatMetadata,
//   handleSaveMetadataAndConversation,
//   channelName,
//   userId,
//   receiverID,
//   postId
// ) => {
//   try {
//     // Trigger metadata check with correct query parameter
//     const response = await triggerCheckChatMetadata({ channel_name: channelName }).unwrap();

//     if (response.status) {
//       console.log("Metadata exists:", response.data);
//     } else {
//       console.log("Metadata does not exist. Saving new metadata...");
//       await handleSaveMetadataAndConversation(
//         channelName,
//         userId,
//         receiverID,
//         postId
//       );
//     }
//     return channelName;
//   } catch (error) {
//     console.error("Error checking chat metadata:", error);
//   }
// };

// Helper function to save metadata and conversation
const handleSaveMetadataAndConversation = async (
  channel_name,
  userId,
  recipientId,
  subject,
  postId
) => {
  try {
    const metadataResponse = await saveMetadata({
      channel_name,
      user_id: userId,
      recipient_id: recipientId,
      post_id: postId,
    }).unwrap();

    const conversationResponse = await saveConversation({
      metadata_id: metadataResponse.metadata_id,
      subject,
    }).unwrap();

    return { metadataResponse, conversationResponse };
  } catch (error) {
    console.error("Error saving metadata or conversation:", error);
    throw error;
  }
};

// Helper function to check metadata and optionally create it
const subscribeWithMetadataCheck = async (
  channel_name,
  userId,
  recipientId,
  postId,
  subject
) => {
  try {
    const response = await triggerCheckChatMetadata({ channel_name }).unwrap();
    console.log("RESPONSE:", response);

    if (response?.status) {
      console.log("yes");
      return response.metadata_id;
    } else {
      console.log("NO");
      const result = await handleSaveMetadataAndConversation(
        channel_name,
        userId,
        recipientId,
        subject,
        postId
      );

      if (result?.metadataResponse) {
        console.log("UMMMMMM");
        return result.metadataResponse.metadata_id;
      } else {
        throw new Error("Failed to create metadata");
      }
    }
  } catch (error) {
    console.error("Error checking or saving metadata:", error);
    throw error;
  }
};

// src/helpers/ChatHelper.js

//  const updateChatCache = (api, channel_name, newMessage) => {
//   api.util.updateQueryData(
//     "FetchMessages", // RTK Query's query name
//     { channel_name, page: 1 }, // Cache key to identify the query
//     (draft) => {
//       if (!draft.messages) {
//         draft.messages = []; // Initialize messages array if empty
//       }
//       draft.messages.push(newMessage); // Add the new message to the cache
//     }
//   );
// };

const updateChatCache = ({ channelName, newMessage, page }) => {
  if (!ChatAPI?.util?.updateQueryData) {
    console.error("ChatAPI or its method updateQueryData is undefined");
    return;
  }

  ChatAPI.util.updateQueryData(
    "FetchMessages",
    { channel_name: channelName, page },
    (draft) => {
      if (!draft.messages) {
        draft.messages = [];
      }
      draft.messages.push(newMessage);
    }
  );
};

// Function to deduplicate messages
const deduplicateMessages = (newMessages, existingMessages) => {
  const messageMap = new Map();

  // Add existing messages to the map
  existingMessages.forEach((msg) => messageMap.set(msg.messageId, msg));

  // Add new messages, overriding duplicates
  newMessages.forEach((msg) => messageMap.set(msg.messageId, msg));

  // Return the deduplicated list
  return Array.from(messageMap.values());
};


export { pusher, subscribeToChannel, unsubscribeFromChannel, subscribeWithMetadataCheck, handleSaveMetadataAndConversation, updateChatCache, deduplicateMessages };
