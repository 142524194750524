import { SalesIQServiceDetailPage } from "../screens/SalesIQDetailsPage/SalesIQServiceDetailPage/SalesIQServiceDetailPage";
import { AddService } from "../screens/Service/AddService/AddService";
import { ArchiveService } from "../screens/Service/ArchiveService/ArchiveService";
import { ArchiveServiceNotLoggedIn } from "../screens/Service/ArchiveService/ArchiveServiceNotLoggedIn";
import { DetailedService } from "../screens/Service/DetailedService/DetailedService";
import { EditService } from "../screens/Service/EditService/EditService";

export const serviceRoutes = [
  {
    path: "/add-service",
    element: <AddService />,
  },
  {
    path: "/edit-service/:id/*",
    element: <EditService />,
  },
  {
    path: "/services",
    element: <ArchiveService />,
  },
  {
    path: "/services-not-logged-in",
    element: <ArchiveServiceNotLoggedIn />,
  },
  {
    path: "/service/:id/*",
    element: <DetailedService />,
  },
];
