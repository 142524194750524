import React, { useEffect, useRef, useState } from 'react';
import "./RadarJobComponent.css"
import dotsvertical from '../../../icons/Gerator/dashboard/dotsvertical.svg';
import calendar1 from '../../../icons/Gerator/dashboard/calendar1.svg';
import content_elements_2x from '../../../icons/Gerator/dashboard/content-elements@2x.png';
import dot1 from '../../../icons/Gerator/dashboard/-dot1.svg';
import radar_1_1_2x from '../../../icons/Gerator/dashboard/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/dashboard/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/dashboard/magnifier-1@2x.png';
import content_elements_2x from '../../../icons/Gerator/dashboard/content-elements@2x.png';
import eye from '../../../icons/Gerator/dashboard/eye.svg';
import icon1 from '../../../icons/Gerator/dashboard/icon1.svg';
import lock04 from '../../../icons/Gerator/dashboard/lock04.svg';
import lockunlocked04 from '../../../icons/Gerator/dashboard/lockunlocked04.svg';
import arrowsquareupright from '../../../icons/Gerator/dashboard/arrowsquareupright.svg';
import messagechatsquare from '../../../icons/Gerator/dashboard/messagechatsquare.svg';
import markerpin02 from '../../../icons/Gerator/dashboard/markerpin02.svg';
import divider2 from '../../../icons/Gerator/dashboard/divider2.svg';
import arrowup1 from '../../../icons/Gerator/dashboard/arrowup1.svg';
import arrowdown1 from '../../../icons/Gerator/dashboard/arrowdown1.svg';
import placeholder2 from '../../../icons/Gerator/dashboard/placeholder2.svg';
import group_58_2x from '../../../icons/Gerator/dashboard/user-placeholder2.png';
import sliders02 from '../../../icons/Gerator/dashboard/sliders02.svg';
import d8595f4b711e503bc72fe396e5043e0c2 from '../../../icons/Gerator/dashboard/8595f4b711e503bc72fe396e5043e0c2-1@2x.png';
import arrowsquareupright1 from '../../../icons/Gerator/dashboard/arrowsquareupright1.svg';
import { SortByFilterComponent } from '../../SortByFilterComponent/SortByFilterComponent';
import RadarFilterButtonGroup from '../../RadarFilterButtonGroup/RadarFilterButtonGroup';
import { TransactionTypeComponent } from '../../TransactionTypeComponent/TransactionTypeComponent';
import { ThreeDotsListingComponent } from '../../ThreeDotsListingComponent/ThreeDotsListingComponent';
import { Link } from 'react-router-dom';
import { PostStatusLIstingComponent } from '../../PostStatusLIstingComponent/PostStatusLIstingComponent';
import { useGetUserDashboardDevicesQuery } from '../../../api/DeviceAPI';
import { NoListingFound } from '../../NoListing/NoListingFound';
import SkeletonListingLoaderComponent from '../../SkeletonListingLoaderComponent/SkeletonListingLoaderComponent';
import { getCustomizedDate } from '../../../helper/JobHelper';
import { useGetPostTypeTaxQuery } from '../../../api/TaxonomyFormAPI';
import SearchInputListingComponent from '../../SearchInputListingComponent/SearchInputListingComponent';
import target_04 from "../../../icons/Gerator/dashboard/target_04.png"
import { useDeleteRadarJobMutation, useGetUserDashboardRadarJobsQuery } from '../../../api/Radar/RadarJobAPI';

import { ThreeDotsNoActionComponent } from '../../ThreeDotsListingComponent/ThreeDotsNoActionComponent';
import { ThreeDotsRadarComponent } from '../../ThreeDotsListingComponent/ThreeDotsRadarComponent';
import TruncatedTextTooltip from "../../TruncatedTextTooltip/TruncatedTextTooltip";
import { formatNumber } from '../../../helper/DeviceHelper';

export const RadarJobComponent = () => {

  const [activeThreeDotIdNoAction, setActiveThreeDotIdNoAction] = useState(null);
  const threeDotsRefNoAction = useRef([]);
  const [isClickBlockedNoAction, setIsClickBlockedNoAction] = useState(false);

  const toggleThreeDotsDropdownNoAction = (index) => {
    if (isClickBlockedNoAction) return; // Prevent rapid toggle



    if (activeThreeDotIdNoAction === index) {
      setActiveThreeDotIdNoAction(null); // Close if the same dropdown is clicked again
    } else {
      setActiveThreeDotIdNoAction(index); // Open the dropdown
    }

    setIsClickBlockedNoAction(true);
    setTimeout(() => setIsClickBlockedNoAction(false), 400);
  };


  useEffect(() => {
    const handleThreeDotClickOutsideNoAction = (event) => {

      const isLink = event.target.closest('a');

      if (isLink) {
        return; // Allow navigation to happen
      }

      if (threeDotsRefNoAction.current[activeThreeDotIdNoAction] && !threeDotsRefNoAction.current[activeThreeDotIdNoAction].contains(event.target)) {
        setActiveThreeDotIdNoAction(null); // Close any open dropdown when clicking outside
      }
    };

    document.addEventListener("mousedown", handleThreeDotClickOutsideNoAction);
    return () => {
      document.removeEventListener("mousedown", handleThreeDotClickOutsideNoAction);
    };
  }, []);

  //Offset to load data when click load more
  const [offset, setOffset] = useState(0);
  const [sortBy, setSortBy] = useState('date_desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [totalCountRadarDevice, settotalCountRadarDevice] = useState("");
  const [totalCountRadarSpare, settotalCountRadarSpare] = useState("");
  const [totalCountRadarService, settotalCountRadarService] = useState("");
  const [totalCountRadarSoftware, settotalCountRadarSoftware] = useState("");
  const [totalCountRadarJob, settotalCountRadarJob] = useState("");
  const [totalCountRadarBusiness, settotalCountRadarBusiness] = useState("");

  const [activePostStatus, setActivePostStatusFilter] = useState(null);
  //Query to get data
  const { data, isSuccess, isError, refetch, isLoading, isFetching } = useGetUserDashboardRadarJobsQuery(
    { offset, sortBy, postStatus: activePostStatus, search: searchTerm },
    {
      // Refetch when component mounts or tab becomes active
      refetchOnMountOrArgChange: true
    }
  );

  // Query to delete from RadarJob
  const [deleteRadarJob] = useDeleteRadarJobMutation();


  //flag var to show listing when loading complete when sekelteon layout is going to stop
  const [loadingComplete, setLoadingComplete] = useState(false);

  //Var is reponsible to show listings and other thing as well
  let content;

  //Sort by text show on button
  const [selectedSortOption, setSelectedSortOption] = useState('');

  //For SortBy Dropdown
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  //For Threedots in listing dropdown
  const [activeThreeDotId, setActiveThreeDotId] = useState(null);
  const threeDotsRef = useRef(null);
  const [isClickBlocked, setIsClickBlocked] = useState(false);

  // Method reponsible to hit rtk delete radar query
  const handleRemoveRadar = async (jobId) => {
    await deleteRadarJob(jobId);
    window.location.reload();
  };

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  }

  const toggleThreeDotsDropdown = (id) => {
    if (isClickBlocked) return; // Prevent rapid toggle

    if (activeThreeDotId === id) {
      setActiveThreeDotId(null); // Close if the same one is clicked again
      setIsClickBlocked(true);
      setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
    } else {
      setActiveThreeDotId(id); // Open the clicked one
    }
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };


  //Set value for sortby selected option on button text
  const handleOptionSelect = (option) => {
    if (option.value !== sortBy) {
      setSortBy(option.value);
      setOffset(0); // Reset offset when sort option changes
    }
    setSelectedSortOption(option);
    setDropdownVisible(false);
  };

  //DashbaordFilterButtonGroup component to get data
  const handleFilterClick = (filterLabel) => {
    // if (activePostStatus === filterLabel) {
    //   setSortBy('date_desc')
    //   setSelectedSortOption('')
    //   setActivePostStatusFilter(null);
    // } else {
    //   setSortBy('date_desc')
    //   setSelectedSortOption('')
    //   setActivePostStatusFilter(filterLabel);
    // }
    // setOffset(0); // Reset offset when changing filters
  };

  //Setting offset for loadmore when user clicked on load more button(this trigger query to fetch new data)
  // Reset offset when component mounts or remounts
  // useEffect(() => {
  //   setOffset(0);
  // }, []);
  // // Reset offset when sort option changes
  // useEffect(() => {
  //   setOffset(0);
  // }, [sortBy]);

  const handleLoadMore = () => {
    if (data?.hasMore && !isFetching) {
      setOffset(currentOffset => currentOffset + 5);
    }
  };

  const showLoadMore = data?.hasMore &&
    data.radar_job.length < data.totalCountRadarJob &&
    !isFetching;





  //Onclick listener for sortby dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  //Onclick listener for threedots dropdown
  useEffect(() => {
    const handleThreeDotClickOutside = (event) => {
      if (threeDotsRef.current && !threeDotsRef.current.contains(event.target)) {
        setActiveThreeDotId(null); // Close any open dropdown when clicking outside
      }
    };

    document.addEventListener("mousedown", handleThreeDotClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleThreeDotClickOutside);
    };
  }, []);

  // Listen for tab visibility changes
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refetch();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [refetch]);


  //Success true then pause to show sekelton layout
  useEffect(() => {
    if (isSuccess) {
      // Set totalcount in listing counter layout
      settotalCountRadarDevice(data.totalCountRadarDevice);
      settotalCountRadarSpare(data.totalCountRadarSpare);
      settotalCountRadarSoftware(data.totalCountRadarSoftware);
      settotalCountRadarService(data.totalCountRadarService);
      settotalCountRadarJob(data.totalCountRadarJob);
      settotalCountRadarBusiness(data.totalCountRadarBusiness);
      // Set timeout to delay the loading completion
      const timer = setTimeout(() => {
        setLoadingComplete(true);
      }, 1000); // 1 second

      // Cleanup timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);


  if (isError) {
    content = <NoListingFound path={"/add-job"} pathName={"Job"} />
  }

  if (isLoading) {
    content = <SkeletonListingLoaderComponent />;
  } else if (loadingComplete && isSuccess) {

    if (data.radar_job.length > 0) {
      // dataLength = data.radar_job.length;
      content = data.radar_job.map((job, index) =>
      (
        // <div className="radar-job-component-radar-content-items" key={index}>
        //   <div className="radar-job-component-radar-divider-container1">
        //     <div className="radar-job-component-radar-divider-container-child"></div>
        //     <div className="radar-job-component-radar-rectangle-parent5">

        //       <div className="radar-job-component-dashboard-frame-wrapper25">
        //         <div className="radar-job-component-dashboard-element-details-parent">
        //           <div className="radar-job-component-dashboard-element-details">
        //             <div className="radar-job-component-dashboard-element-details-child"></div>
        //             <img
        //               className="radar-job-component-dashboard-f4b711e503bc72fe396e5043e0c2-1-icon"
        //               alt=""
        //               src={job.logoImage}
        //             />
        //           </div>

        //           <div className="radar-job-component-dashboard-element-actions">
        //             {/* <Link to={"#"} className="radar-job-component-dashboard-text78">
        //       Assistant in Hasta Hospital
        //     </Link> */}
        //             <Link to={`/job/${job.id}/${job.title}`} className="radar-job-component-dashboard-text78">
        //               {job.title}
        //             </Link>
        //             <div className="radar-job-component-dashboard-action-icons">
        //               <div className="radar-job-component-dashboard-calendar-icon6">
        //                 <div className="radar-job-component-dashboard-date-icon">
        //                   <div className="radar-job-component-dashboard-badge-parent">
        //                     {/* <div className="radar-job-component-dashboard-supporting-text12">
        //               Devices Prime CO
        //             </div> */}
        //                     <Link className="radar-job-component-dashboard-supporting-text12" to={`/business/${job.job_business.id}/${job.job_business.businessName}`}>
        //                       {job.job_business.businessName}
        //                     </Link>
        //                     <div className="radar-job-component-dashboard-search-input">
        //                       <img
        //                         className="radar-job-component-dashboard-marker-pin-02-icon2"
        //                         alt=""
        //                         src={markerpin02}
        //                       />
        //                     </div>
        //                   </div>
        //                   <div className="radar-job-component-dashboard-type-badge-elements">
        //                     <div className="radar-job-component-dashboard-end-label">
        //                       <div className="radar-job-component-dashboard-search-icon-input">{job.totalRatingAvg ? `${job.totalRatingAvg}/5` : "0/5"}</div>
        //                     </div>
        //                     <div className="radar-job-component-dashboard-type-support">
        //                       <div className="radar-job-component-dashboard-supporting-text9">
        //                       {job.totalReviews} {job.totalReviews === 0 || job.totalReviews === 1 ? "review" : "reviews"}
        //                       </div>
        //                     </div>
        //                   </div>
        //                   <div className="radar-job-component-dashboard-type-label">
        //                     <div className="radar-job-component-dashboard-text99">{job.tax_engagement_type.name}</div>
        //                     <div className="radar-job-component-dashboard-supporting-text14">
        //                       Engagement Type
        //                     </div>
        //                   </div>
        //                 </div>
        //                 <div className="radar-job-component-dashboard-work-type-icon">
        //                   {job.job_address.tax_address_type
        //                     .slug === "address" ?
        //                     <div className="radar-job-component-dashboard-location3">{job.job_address.addressTownCity},  {job.job_address.tax_country.name}</div>
        //                     :
        //                     <div className="radar-job-component-dashboard-location3">{job.job_address.tax_address_type
        //                       .name}</div>
        //                   }
        //                   {/* <div className="radar-job-component-dashboard-location3">India, New Delhi</div> */}

        //                   <div className="radar-job-component-dashboard-work-type-value">
        //                     <div className="radar-job-component-dashboard-price-parent">
        //                       <div className="radar-job-component-dashboard-text100">{job.tax_experience_level.name}</div>
        //                       <div className="radar-job-component-dashboard-supporting-text15">Experience</div>
        //                     </div>
        //                   </div>
        //                 </div>
        //               </div>
        //               <div className="radar-job-component-dashboard-work-type-label">
        //                 <div className="radar-job-component-dashboard-search-input">
        //                   <img
        //                     className="radar-job-component-dashboard-marker-pin-02-icon2"
        //                     alt=""
        //                     src={calendar1}
        //                   />
        //                 </div>
        //                 <div className="radar-job-component-dashboard-type-of-work2">{getCustomizedDate(job.createdAt)}</div>
        //               </div>
        //             </div>
        //           </div>
        //         </div>
        //       </div>

        //       <div className="radar-job-component-dashboard-dropdown-parent1">
        //         <div className="radar-job-component-dashboard-dropdown10" onClick={toggleThreeDotsDropdown} ref={threeDotsRef}>
        //           <img
        //             className="radar-job-component-dashboard-dots-vertical-icon"
        //             alt=""
        //             src={dotsvertical}
        //           />
        //           {/* When there is active engagement then dont show three dot to remove from radar */}
        //           {(activeThreeDotId === index && !job.totalActiveEngagement) && <ThreeDotsRadarComponent url={"#"} />}

        //           {/* {isThreeDotsVisible && <ThreeDotsListingComponent url={'job/1'} />} */}
        //         </div>
        //         <div className="radar-job-component-dashboard-filter-elements-inner">
        //           <div className="radar-job-component-dashboard-frame-parent31">
        //             <div className="radar-job-component-dashboard-text-wrapper10">
        //               <div className="radar-job-component-dashboard-text101">Starting From</div>
        //             </div>
        //             <div className="radar-job-component-dashboard-price4">${formatNumber(job.budget)}</div>
        //             <div className="radar-job-component-dashboard-trend-chart">
        //               <div className="radar-job-component-dashboard-text83">{job.tax_unit_measure_service.name}</div>
        //               <div className="radar-job-component-dashboard-trend-chart-inner">
        //                 <div className="radar-job-component-dashboard-frame-parent32">
        //                   {!job.totalActiveEngagement &&
        //                     <div className="radar-job-component-dashboard-frame-wrapper27">
        //                       <div className="radar-job-component-dashboard-radar-1-1-container">
        //                         <img
        //                           className="radar-job-component-dashboard-magnifier-1-icon"
        //                           alt=""
        //                           onClick={() => handleRemoveRadar(job.id)}
        //                           src={target_04}
        //                           style={{ cursor: "pointer" }}
        //                         />

        //                         {/* <img
        //                         className="radar-job-component-dashboard-magnifier-1-icon"
        //                         alt=""
        //                         src={deal_2_1_2x}
        //                       />

        //                       <img
        //                         className="radar-job-component-dashboard-magnifier-1-icon"
        //                         alt=""
        //                         src={magnifier_1_2x}
        //                       /> */}
        //                       </div>
        //                     </div>
        //                   }
        //                 </div>
        //               </div>
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //     <img className="radar-job-component-radar-divider-icon8" alt="" src={divider2} />

        //     <div className="radar-job-component-radar-frame-parent71">
        //       <div className="radar-job-component-radar-buttonsbutton-parent1">
        //         <div className="radar-legend-series">
        //           <img
        //             className="radar-location-column-child"
        //             alt=""
        //             src={job.profileImage || group_58_2x}
        //           />

        //           <div className="radar-location-column-inner">
        //             <div className="radar-date-picker-dropdown">
        //               <div className="radar-name">{job.job_user.displayName}</div>
        //               <Link className="radar-role" to={`/business/${job.job_business.id}/${job.job_business.businessName}`}>
        //                 {job.job_business.businessName}
        //               </Link>
        //             </div>
        //           </div>
        //         </div>
        //         <div className='radar-job-component-main-buttons-group'>
        //           <div className="radar-job-component-radar-buttonsbutton13">
        //             <img
        //               className="radar-job-component-radar-arrow-narrow-left-icon"
        //               alt=""
        //               src={eye}
        //             />

        //             <div className="radar-job-component-radar-search-input">
        //               <div className="radar-job-component-radar-text67">View</div>
        //             </div>
        //           </div>
        //           {/* <div className="radar-job-component-radar-buttonsbutton25">
        //             <div className="radar-job-component-radar-icon-wrapper">
        //               <img className="radar-job-component-radar-icon1" alt="" src={icon1} />
        //             </div>
        //             <div className="radar-job-component-radar-search-input">
        //               <div className="radar-job-component-radar-text68">Sales IQ</div>
        //             </div>
        //             <img
        //               className="radar-job-component-radar-arrow-narrow-left-icon"
        //               alt=""
        //               src={lock04}
        //             />
        //           </div>
        //           <div className="radar-job-component-radar-buttonsbutton26">
        //             <div className="radar-job-component-radar-icon-wrapper">
        //               <img className="radar-job-component-radar-icon1" alt="" src={icon1} />
        //             </div>
        //             <div className="radar-job-component-radar-search-input">
        //               <div className="radar-job-component-radar-text68">Sales IQ</div>
        //             </div>
        //             <img
        //               className="radar-job-component-radar-arrow-narrow-left-icon"
        //               alt=""
        //               src={lockunlocked04}
        //             />
        //           </div> */}
        //           <Link to={`/chat/offered/Job/${job.id}`} target="_blank"
        //             rel="noopener noreferrer">
        //             <div className="radar-job-component-radar-buttonsbutton27">
        //               <img
        //                 className="radar-job-component-radar-upload-04-icon"
        //                 alt=""
        //                 src={messagechatsquare}
        //               />

        //               <div className="radar-job-component-radar-text145">Messages</div>
        //               {job?.totalUnreadMessage > 0 &&
        //                 <div className="radar-job-component-radar-ellipse-parent">
        //                   <div className="radar-job-component-radar-ellipse-div"></div>
        //                   <div className="radar-job-component-radar-div">{job?.totalUnreadMessage || 0}</div>
        //                 </div>
        //               }
        //             </div>
        //           </Link>
        //         </div>

        //       </div>
        //       <a
        //         href={`/job/${job.id}/${job.title}`}
        //         target="_blank"
        //         rel="noopener noreferrer"
        //         className="radar-job-component-radar-arrow-square-up-right-wrapper1"
        //         title="Details"
        //       >
        //         <img
        //           className="radar-job-component-radar-arrow-square-up-right-icon1"
        //           alt=""
        //           src={arrowsquareupright}
        //         />
        //       </a>
        //     </div>
        //   </div>
        // </div>
         <div className="dashboard-job-component-dashboard-content-items" key={index}>
                  <div className="dashboard-job-component-dashboard-rectangle-parent10">
                    <div className="dashboard-job-component-dashboard-frame-child12"></div>
                    <div className="dashboard-job-component-dashboard-frame-wrapper25">
                      <div className="dashboard-job-component-dashboard-element-details-parent">
                        <div className="dashboard-job-component-dashboard-element-details">
                          <div className="dashboard-job-component-dashboard-element-details-child"></div>
                          <img
                            className="dashboard-job-component-dashboard-f4b711e503bc72fe396e5043e0c2-1-icon"
                            alt=""
                            src={job.logoImage}
                          />
                        </div>
                        <div className="dashboard-job-component-dashboard-element-actions">
                          <Link to={`/job/${job.id}/${job.title}`} className="dashboard-job-component-dashboard-text78">
                            {job.title}
                          </Link>
                          <div className="dashboard-job-component-dashboard-action-icons">
        
                            <div className="dashboard-job-component-dashboard-date-icon">
                              <div className="dashboard-job-component-dashboard-badge-parent">
                                <div className="dashboard-job-component-dashboard-supporting-text12">
                                  {job.job_business.businessName}
                                </div>
        
                              </div>
                              <div className="dashboard-job-component-dashboard-type-badge-elements">
                                <div className="dashboard-job-component-dashboard-end-label">
                                  <div className="dashboard-job-component-dashboard-search-icon-input">{job.totalRatingAvg ? `${job.totalRatingAvg}/5` : "0/5"}</div>
                                </div>
                                <div className="dashboard-job-component-dashboard-type-support">
                                  <div className="dashboard-job-component-dashboard-supporting-text9">
                                  {job.totalReviews} {job.totalReviews === 0 || job.totalReviews === 1  ? "review" : "reviews"}
                                  </div>
                                </div>
                              </div>
        
                            </div>
                            <div className="dashboard-job-component-dashboard-work-type-icon">
        
                              <div className="dashboard-job-component-dashboard-search-input">
                                <img
                                  className="dashboard-job-component-dashboard-marker-pin-02-icon2"
                                  alt=""
                                  src={markerpin02}
                                />
                              </div>
        
                              <div className="dashboard-job-component-dashboard-location3">
                                {
                                  (job.job_address.tax_address_type.slug === 'remote') && job.job_address.tax_address_type.name
                                }
        
                                {
                                  (job.job_address.tax_address_type.slug === 'address' && `${job.job_address.addressTownCity}, ${job.job_address.tax_country.name}`)
                                }
        
                                {/* {
                                     job.job_address.tax_address_type.slug === 'address' && (<TruncatedTextTooltip 
                                        text={`${job.job_address.addressTownCity}, ${job.job_address.tax_country.name}`} 
                                      />)
                                    } */}
                              </div>
        
                            </div>
        
                            <div className="dashboard-job-component-dashboard-work-type-label">
                              <div className="dashboard-job-component-dashboard-search-input">
                                <img
                                  className="dashboard-job-component-dashboard-marker-pin-02-icon2"
                                  alt=""
                                  src={calendar1}
                                />
                              </div>
                              <div className="dashboard-job-component-dashboard-type-of-work2">{getCustomizedDate(job.createdAt)}</div>
                            </div>
                          </div>
        
                          <div className="dashboard-job-component-dashboard-small-card">
        
                            <div className="dashboard-job-component-dashboard-work-type-value">
                              <div className="dashboard-job-component-dashboard-price-parent">
                                <div className="dashboard-job-component-dashboard-text100">{job.tax_engagement_type.name}</div>
                                <div className="dashboard-job-component-dashboard-supporting-text15">
                                  Engagement Type
                                </div>
                              </div>
                            </div>
        
                            <div className="dashboard-job-component-dashboard-work-type-value">
                              <div className="dashboard-job-component-dashboard-price-parent">
                                <div className="dashboard-job-component-dashboard-text100">{job.tax_experience_level.name}</div>
                                <div className="dashboard-job-component-dashboard-supporting-text15">Experience</div>
                              </div>
                            </div>
        
                          </div>
                        </div>
                        <div className="dashboard-job-component-dashboard-dropdown-parent1">
                          {/* {(job.tax_post_status.slug !== 'pending') ? */}
                          <div className="dashboard-job-component-dashboard-dropdown10" onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleThreeDotsClick(e, index);
                          }}
                            ref={threeDotsRef}>
                            <img
                              className="dashboard-job-component-dashboard-dots-vertical-icon"
                              alt=""
                              src={dotsvertical}
                            />
                         {activeThreeDotId === index && (
                                               <div ref={dropdownRef}>
                                                 <ThreeDotsListingComponent
                                                   option={job.tax_post_status.slug}
                                                   postId={job.id}
                                                   postType={'job'} url={`job/${job.id}`} totalActiveEngagement={job.totalActiveEngagement}
                                                   onClose={() => {
                                                     setActiveThreeDotId(null);
                                                     setIsClickBlocked(false);
                                                   }}
                                                 />
                                               </div>
                                             )}
                            {/* {isThreeDotsVisible && <ThreeDotsListingComponent url={`job/${job.id}`} />} */}
                          </div>
                          {/* : */}
                          {/* <div className="dashboard-job-component-dashboard-dropdown10" >
                        <img
                          className="dashboard-job-component-dashboard-dots-vertical-icon"
                          alt=""
                          src=""
                        />
                      </div> */}
                          {/* } */}
                          <div className="dashboard-job-component-dashboard-filter-elements-inner">
                            <div className="dashboard-job-component-dashboard-frame-parent31">
                              <div className="dashboard-job-component-dashboard-text-wrapper10">
                                <div className="dashboard-job-component-dashboard-text101">From</div>
                              </div>
                              <div className="dashboard-job-component-dashboard-price4">${formatNumber(job.budget)}</div>
                              <div className="dashboard-job-component-dashboard-trend-chart">
                                <div className="dashboard-job-component-dashboard-text83">{job.tax_unit_measure_service.name}</div>
                                <div className="dashboard-job-component-dashboard-trend-chart-inner">
                                  <div className="dashboard-job-component-dashboard-frame-parent32">
                                    <div className="dashboard-job-component-dashboard-frame-wrapper27">
                                      <div className="dashboard-job-component-dashboard-radar-1-1-container">
                                        <img
                                          className="dashboard-job-component-dashboard-magnifier-1-icon"
                                          alt=""
                                          src={radar_1_1_2x}
                                        />
                                        {/* 
                                  <img
                                    className="dashboard-job-component-dashboard-magnifier-1-icon"
                                    alt=""
                                    src={deal_2_1_2x}
                                  />
        
                                  <img
                                    className="dashboard-job-component-dashboard-magnifier-1-icon"
                                    alt=""
                                    src={magnifier_1_2x}
                                  /> */}
                                      </div>
                                    </div>
                                    {/* <a 
                                href={`/job/${job.id}/${job.title}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                
                              >
                              <img
                                className="dashboard-job-component-dashboard-arrow-square-up-right-icon1"
                                alt=""
                                src={arrowsquareupright1}
                              />
                              </a> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <img className="dashboard-device-component-dashboard-divider-icon8" alt="" src={divider2} />
        
                    <div className="dashboard-device-component-dashboard-frame-parent71">
                      <div className="dashboard-device-component-dashboard-buttonsbutton-parent1">
                        <div className="dashboard-legend-series">
                          <img
                            className="dashboard-location-column-child-job"
                            alt=""
                            src={job.logoImage}
                          />
        
                          <div className="dashboard-location-column-inner">
                            <div className="dashboard-date-picker-dropdown-job">
                              <div className="dashboard-name">{job.job_business.businessName}</div>
                              {/* <div className="dashboard-role">{job.job_business.businessName}</div> */}
                            </div>
                          </div>
                        </div>
                        <div className='dashboard-device-component-main-buttons-group'>
                          <div className="dashboard-device-component-dashboard-buttonsbutton13">
                            <img
                              className="dashboard-device-component-dashboard-arrow-narrow-left-icon"
                              alt=""
                              src={eye}
                            />
        
                            <div className="dashboard-device-component-dashboard-search-input">
                              <div className="dashboard-device-component-dashboard-text67">View</div>
                            </div>
                          </div>
        
                          {job.currentPlan === 1 ?
                            <Link to={"/dashboard/settings/plan"}>
                              <div className="dashboard-device-component-dashboard-buttonsbutton25">
                                <div className="dashboard-device-component-dashboard-icon-wrapper">
                                  <img className="dashboard-device-component-dashboard-icon1" alt="" src={icon1} />
                                </div>
                                <div className="dashboard-device-component-dashboard-search-input">
                                  <div className="dashboard-device-component-dashboard-text68">Sales IQ</div>
                                </div>
                                <img
                                  className="dashboard-device-component-dashboard-arrow-narrow-left-icon"
                                  alt=""
                                  src={lock04}
                                />
                              </div>
                            </Link>
                            :
                            <Link to={"/sales-iq"}>
                              <div className="dashboard-device-component-dashboard-buttonsbutton26">
                                <div className="dashboard-device-component-dashboard-icon-wrapper">
                                  <img className="dashboard-device-component-dashboard-icon1" alt="" src={icon1} />
                                </div>
                                <div className="dashboard-device-component-dashboard-search-input">
                                  <div className="dashboard-device-component-dashboard-text68">Sales IQ</div>
                                </div>
                                <img
                                  className="dashboard-device-component-dashboard-arrow-narrow-left-icon"
                                  alt=""
                                  src={lockunlocked04}
                                />
                              </div>
                            </Link>
                          }
        
        
                          <Link to={`chat/offered/Job/${job.id}`}>
                            <div className="dashboard-device-component-dashboard-buttonsbutton27">
                              <img
                                className="dashboard-device-component-dashboard-upload-04-icon"
                                alt=""
                                src={messagechatsquare}
                              />
        
                              <div className="dashboard-device-component-dashboard-text145">Messages</div>
                              {job?.totalUnreadMessage > 0 &&
                                <div className="dashboard-device-component-dashboard-ellipse-parent">
                                  <div className="dashboard-device-component-dashboard-ellipse-div"></div>
                                  <div className="dashboard-device-component-dashboard-div">{job?.totalUnreadMessage || 0}</div>
                                </div>
                              }
                            </div>
                          </Link>
                        </div>
        
                      </div>
                      <a
                        href={`/job/${job.id}/${job.title}`}
                        target="_blank"
                        rel="noopener noreferrer"
        
                      >
        
                        <img
                          className="dashboard-device-component-dashboard-arrow-square-up-right-icon1"
                          alt=""
                          src={arrowsquareupright1}
                        />
                      </a>
                    </div>
                  </div>
                </div>
      ))

    }
    else {
      content = (

        <NoListingFound path={"/add-job"} pathName={"Job"} />

      )
    }

  } else if (!loadingComplete && isSuccess) {
    content = <SkeletonListingLoaderComponent />; // Show loader until loadingComplete is true
  }




  return (
    <>
      <div className="radar-frame-wrapper">
        <div className="radar-frame-group">
          <div className="radar-rectangle-group">
            <div className="radar-frame-item"></div>
            <div className="radar-metric-card-row">
              <div className="radar-metric-card">
                <div className="radar-metric-card-title">
                  <div className="radar-heading4">Devices</div>
                </div>
                <div className="radar-dropdown3" style={{ cursor: "pointer" }} onClick={() => toggleThreeDotsDropdownNoAction(0)} ref={(el) => threeDotsRefNoAction.current[0] = el}>
                  <img
                    className="radar-dots-vertical-icon"
                    alt=""
                    src={dotsvertical}
                  />
                  {activeThreeDotIdNoAction === 0 && <ThreeDotsNoActionComponent />}

                </div>
              </div>
              <div className="radar-number3">{totalCountRadarDevice || 0}</div>
            </div>
            {/* <div className="radar-badge11">
              <div className="radar-options-icon">
                <img
                  className="radar-arrow-up-icon2"
                  alt=""
                  src={arrowup1}
                />
              </div>
              <div className="radar-text58">20 slots available</div>
            </div> */}
          </div>
          <div className="radar-rectangle-container">
            <div className="radar-frame-inner"></div>
            <div className="radar-frame-container">
              <div className="radar-heading-parent">
                <div className="radar-heading5">Spares</div>
                <div className="radar-number4">{totalCountRadarSpare || 0}</div>
                {/* <div className="radar-badge12">
                  <div className="radar-options-icon">
                    <img
                      className="radar-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="radar-change-amount">20%</div>
                </div> */}
              </div>
            </div>
            <div className="radar-dropdown3" style={{ cursor: "pointer" }} onClick={() => toggleThreeDotsDropdownNoAction(1)} ref={(el) => threeDotsRefNoAction.current[1] = el}>
              <img
                className="radar-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
              {activeThreeDotIdNoAction === 1 && <ThreeDotsNoActionComponent />}

            </div>
          </div>
          <div className="radar-rectangle-container">
            <div className="radar-frame-inner"></div>
            <div className="radar-options-icon">
              <div className="radar-heading-group">
                <div className="radar-heading5">Services</div>
                <div className="radar-number4">{totalCountRadarService || 0}</div>
                {/* <div className="radar-badge12">
                  <div className="radar-options-icon">
                    <img
                      className="radar-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="radar-change-amount">20%</div>
                </div> */}
              </div>
            </div>
            <div className="radar-dropdown3" style={{ cursor: "pointer" }} onClick={() => toggleThreeDotsDropdownNoAction(2)} ref={(el) => threeDotsRefNoAction.current[2] = el}>
              <img
                className="radar-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
              {activeThreeDotIdNoAction === 2 && <ThreeDotsNoActionComponent />}

            </div>
          </div>
          <div className="radar-rectangle-container">
            <div className="radar-frame-inner"></div>
            <div className="radar-frame-container">
              <div className="radar-heading-container">
                <div className="radar-heading5">Software</div>
                <div className="radar-number4">{totalCountRadarSoftware || 0}</div>
                {/* <div className="radar-badge12">
                  <div className="radar-options-icon">
                    <img
                      className="radar-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="radar-change-amount">20%</div>
                </div> */}
              </div>
            </div>
            <div className="radar-dropdown3" style={{ cursor: "pointer" }} onClick={() => toggleThreeDotsDropdownNoAction(3)} ref={(el) => threeDotsRefNoAction.current[3] = el}>
              <img
                className="radar-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
              {activeThreeDotIdNoAction === 3 && <ThreeDotsNoActionComponent />}

            </div>
          </div>
          <div className="radar-rectangle-parent2">
            <div className="radar-frame-inner"></div>
            <div className="radar-frame-container">
              <div className="radar-heading-parent1">
                <div className="radar-heading5">Jobs</div>
                <div className="radar-number4">{totalCountRadarJob || 0}</div>
                {/* <div className="radar-badge12">
                  <div className="radar-options-icon">
                    <img
                      className="radar-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="radar-change-amount">20%</div>
                </div> */}
              </div>
            </div>
            <div className="radar-dropdown7" style={{ cursor: "pointer" }} onClick={() => toggleThreeDotsDropdownNoAction(4)} ref={(el) => threeDotsRefNoAction.current[4] = el}>
              <img
                className="radar-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
              {activeThreeDotIdNoAction === 4 && <ThreeDotsNoActionComponent />}

            </div>
          </div>

          <div className="radar-rectangle-parent2">
            <div className="radar-frame-inner"></div>
            <div className="radar-frame-container">
              <div className="radar-heading-parent1">
                <div className="radar-heading5">Businesses</div>
                <div className="radar-number4">{totalCountRadarBusiness || 0}</div>
                {/* <div className="radar-badge12">
                  <div className="radar-options-icon">
                    <img
                      className="radar-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="radar-change-amount">20%</div>
                </div> */}
              </div>
            </div>
            <div className="radar-dropdown7" style={{ cursor: "pointer" }} onClick={() => toggleThreeDotsDropdownNoAction(5)} ref={(el) => threeDotsRefNoAction.current[5] = el}>
              <img
                className="radar-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
              {activeThreeDotIdNoAction === 5 && <ThreeDotsNoActionComponent />}

            </div>
          </div>
        </div>
      </div>

      <div className="radar-section-wrapper">
        <div className="radar-section1">
          <div className="radar-container1">
            <div className="radar-filters-bar">
              <div className="radar-content17">
                <div className="radar-dropdowns">

                  <RadarFilterButtonGroup
                    handleFilterClick={handleFilterClick}
                    activePostStatus={activePostStatus}
                  />

                </div>
                <div className="radar-input-dropdown2">
                  {/* <div className="radar-input-with-label2">
                    <div className="radar-label">Search</div>
                    <div className="radar-input2">
                      <div className="radar-content3">
                        <img
                          className="radar-search-lg-icon radar-search-lg-icon-custom"
                          alt=""
                          src={searchlg}
                        />
                        <input type="text" className="radar-search-placeholder" placeholder='Search' />
                      </div>
                    </div>
                  </div> */}

                  <SearchInputListingComponent onSearch={handleSearch} />

                  <div className="radar-buttonsbutton1" onClick={toggleDropdown}>
                    <img
                      className="radar-upload-04-icon"
                      alt=""
                      src={sliders02}
                    />
                    <div className="radar-text-padding6" ref={dropdownRef}>
                      <div className="radar-text65">
                        Sort By {selectedSortOption && `: ${selectedSortOption.label}`}
                      </div>
                      {isDropdownVisible && <SortByFilterComponent onOptionSelect={handleOptionSelect} />}
                    </div>
                    <img
                      className="radar-placeholder-icon"
                      alt=""
                      src={placeholder2}
                    />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* Listing Container */}


      {/* <div className="radar-job-component-listing-container"> */}
      <div className="dashboard-device-component-listing-container">

        {/* Listing layout start    */}
        {content}

        {/* <div className="radar-job-component-radar-content-items">
          <div className="radar-job-component-radar-divider-container1">
            <div className="radar-job-component-radar-divider-container-child"></div>
            <div className="radar-job-component-radar-rectangle-parent5">

              <div className="radar-job-component-dashboard-frame-wrapper25">
                <div className="radar-job-component-dashboard-element-details-parent">
                  <div className="radar-job-component-dashboard-element-details">
                    <div className="radar-job-component-dashboard-element-details-child"></div>
                    <img
                      className="radar-job-component-dashboard-f4b711e503bc72fe396e5043e0c2-1-icon"
                      alt=""
                      src={d8595f4b711e503bc72fe396e5043e0c2}
                    />
                  </div>

                  <div className="radar-job-component-dashboard-element-actions">
                    <Link to={"#"} className="radar-job-component-dashboard-text78">
                      Assistant in Hasta Hospital
                    </Link>
                    <div className="radar-job-component-dashboard-action-icons">
                      <div className="radar-job-component-dashboard-calendar-icon6">
                        <div className="radar-job-component-dashboard-date-icon">
                          <div className="radar-job-component-dashboard-badge-parent">
                            <div className="radar-job-component-dashboard-supporting-text12">
                              Devices Prime CO
                            </div>
                            <div className="radar-job-component-dashboard-search-input">
                              <img
                                className="radar-job-component-dashboard-marker-pin-02-icon2"
                                alt=""
                                src={markerpin02}
                              />
                            </div>
                          </div>
                          <div className="radar-job-component-dashboard-type-badge-elements">
                            <div className="radar-job-component-dashboard-end-label">
                              <div className="radar-job-component-dashboard-search-icon-input">4.9/5</div>
                            </div>
                            <div className="radar-job-component-dashboard-type-support">
                              <div className="radar-job-component-dashboard-supporting-text9">
                                99,999 reviews
                              </div>
                            </div>
                          </div>
                          <div className="radar-job-component-dashboard-type-label">
                            <div className="radar-job-component-dashboard-text99">Full Time Employment</div>
                            <div className="radar-job-component-dashboard-supporting-text14">
                              Engagement Type
                            </div>
                          </div>
                        </div>
                        <div className="radar-job-component-dashboard-work-type-icon">
                          <div className="radar-job-component-dashboard-location3">India, New Delhi</div>
                          <div className="radar-job-component-dashboard-work-type-value">
                            <div className="radar-job-component-dashboard-price-parent">
                              <div className="radar-job-component-dashboard-text100">Expert (0-9 Year)</div>
                              <div className="radar-job-component-dashboard-supporting-text15">Experience</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="radar-job-component-dashboard-work-type-label">
                        <div className="radar-job-component-dashboard-search-input">
                          <img
                            className="radar-job-component-dashboard-marker-pin-02-icon2"
                            alt=""
                            src={calendar1}
                          />
                        </div>
                        <div className="radar-job-component-dashboard-type-of-work2">April 09, 2023</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="radar-job-component-dashboard-dropdown-parent1">
                <div className="radar-job-component-dashboard-dropdown10" onClick={toggleThreeDotsDropdown} ref={threeDotsRef}>
                  <img
                    className="radar-job-component-dashboard-dots-vertical-icon"
                    alt=""
                    src={dotsvertical}
                  />
                  {isThreeDotsVisible && <ThreeDotsListingComponent url={'job/1'} />}
                </div>
                <div className="radar-job-component-dashboard-filter-elements-inner">
                  <div className="radar-job-component-dashboard-frame-parent31">
                    <div className="radar-job-component-dashboard-text-wrapper10">
                      <div className="radar-job-component-dashboard-text101">From</div>
                    </div>
                    <div className="radar-job-component-dashboard-price4">$9,999,999.99</div>
                    <div className="radar-job-component-dashboard-trend-chart">
                      <div className="radar-job-component-dashboard-text83">USD - Per Unit</div>
                      <div className="radar-job-component-dashboard-trend-chart-inner">
                        <div className="radar-job-component-dashboard-frame-parent32">
                          <div className="radar-job-component-dashboard-frame-wrapper27">
                            <div className="radar-job-component-dashboard-radar-1-1-container">
                              <img
                                className="radar-job-component-dashboard-magnifier-1-icon"
                                alt=""
                                src={target_04}
                                // onClick={handleImageClick}
                                style={{ cursor: "pointer" }}
                              />

                              <img
                                className="radar-job-component-dashboard-magnifier-1-icon"
                                alt=""
                                src={deal_2_1_2x}
                              />

                              <img
                                className="radar-job-component-dashboard-magnifier-1-icon"
                                alt=""
                                src={magnifier_1_2x}
                              />
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img className="radar-job-component-radar-divider-icon8" alt="" src={divider2} />

            <div className="radar-job-component-radar-frame-parent71">
              <div className="radar-job-component-radar-buttonsbutton-parent1">
                <div className="radar-legend-series">
                  <img
                    className="radar-location-column-child"
                    alt=""
                    src={group_58_2x}
                  />

                  <div className="radar-location-column-inner">
                    <div className="radar-date-picker-dropdown">
                      <div className="radar-name">Olivia Rhye</div>
                      <div className="radar-role">Business Na...</div>
                    </div>
                  </div>
                </div>
                <div className='radar-job-component-main-buttons-group'>
                  <div className="radar-job-component-radar-buttonsbutton13">
                    <img
                      className="radar-job-component-radar-arrow-narrow-left-icon"
                      alt=""
                      src={eye}
                    />

                    <div className="radar-job-component-radar-search-input">
                      <div className="radar-job-component-radar-text67">View</div>
                    </div>
                  </div>
                  <div className="radar-job-component-radar-buttonsbutton25">
                    <div className="radar-job-component-radar-icon-wrapper">
                      <img className="radar-job-component-radar-icon1" alt="" src={icon1} />
                    </div>
                    <div className="radar-job-component-radar-search-input">
                      <div className="radar-job-component-radar-text68">Sales IQ</div>
                    </div>
                    <img
                      className="radar-job-component-radar-arrow-narrow-left-icon"
                      alt=""
                      src={lock04}
                    />
                  </div>
                  <div className="radar-job-component-radar-buttonsbutton26">
                    <div className="radar-job-component-radar-icon-wrapper">
                      <img className="radar-job-component-radar-icon1" alt="" src={icon1} />
                    </div>
                    <div className="radar-job-component-radar-search-input">
                      <div className="radar-job-component-radar-text68">Sales IQ</div>
                    </div>
                    <img
                      className="radar-job-component-radar-arrow-narrow-left-icon"
                      alt=""
                      src={lockunlocked04}
                    />
                  </div>
                  <div className="radar-job-component-radar-buttonsbutton27">
                    <img
                      className="radar-job-component-radar-upload-04-icon"
                      alt=""
                      src={messagechatsquare}
                    />

                    <div className="radar-job-component-radar-text145">Messages</div>
                    <div className="radar-job-component-radar-ellipse-parent">
                      <div className="radar-job-component-radar-ellipse-div"></div>
                      <div className="radar-job-component-radar-div">2</div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="radar-job-component-radar-arrow-square-up-right-wrapper1">
                <img
                  className="radar-job-component-radar-arrow-square-up-right-icon1"
                  alt=""
                  src={arrowsquareupright}
                />
              </div>
            </div>
          </div>
        </div> */}

      </div>





      {/* Load more button  */}
      <div className="radar-frame-wrapper44">
        <div className="radar-frame-parent72">
          <div className="radar-buttonsbutton-wrapper1">
            {showLoadMore && (
              <button className='radar-buttonsbutton-wrapper1-child'
                onClick={handleLoadMore}
                disabled={isFetching}
              >
                <div className="radar-buttonsbutton28">
                  <img
                    className="radar-arrow-narrow-left-icon"
                    alt=""
                    src={arrowdown1}
                  />
                  <div className="radar-search-input">
                    <div className="radar-text146">{isFetching ? 'Loading...' : 'Load More'}</div>
                  </div>
                </div>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

