import React, { useState, useEffect, useRef } from 'react';
import "./AdminEditWantedDevice.css"
import { Navbar1 } from '../../../components/Navbar1/Navbar1'
import { Provider } from "./AdminEditWantedDeviceContext";
import { clearEditWantedDeviceForm } from "../../../components/EditWantedDeviceFormComponent/EditWantedDeviceSlice";
// import { purgeStorage } from "../..";
import { useDispatch, useSelector } from "react-redux";
import { useGetAgeOfDeviceTaxQuery, useGetAvailabilityTaxQuery, useGetClinicalApplicationsTaxQuery, useGetDeviceCategoryTaxQuery, useGetOemTaxQuery, useGetPhysicalLocationTaxQuery, useGetPurposeUseTaxQuery, useGetStatusConditionTaxQuery } from "../../../api/TaxonomyFormAPI";
import { EditWantedDeviceForm } from "../../../components/Admin/ListingSection/EditFormComponent/EditWantedDeviceFormComponent/EditWantedDeviceForm";
import { fillEditWantedDeviceForm } from "../../../components/EditWantedDeviceFormComponent/EditWantedDeviceSlice";
import { firestore, doc, getDoc, auth, onAuthStateChanged } from '../../Login/FirebaseClient';

import { FooterAddForms } from '../../../components/Footer/FooterAddForms/FooterAddForms';
// import { EditWantedDeviceForm } from '../../../components/EditWantedDeviceFormComponent/EditWantedDeviceForm';
import divider from '../../../icons/Gerator/add_form/navbar_divider/divider.svg';
import divider_light from '../../../icons/Gerator/divider.svg';
import { Sidebar } from '../../../components/Sidebar/Sidebar';
// import { SideBarSubNormal } from '../../../components/SideBars-Sub/SideBarSubNormal/SideBarSubNormal';
import { SideBarSubSettings } from "../../../components/SideBars-Sub/SideBarSubSettings/SideBarSubSettings";


import { HeaderForms } from '../../../components/Header/HeaderForms/HeaderForms';
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyGetProfileImageLinkQuery, useLazyGetUserQuery } from '../../../api/Login&RegisterAPI';
import { Loader } from '../../../components/Loader';
import { decryptErrorData } from '../../../helper/AESHelper';
import { useLazyFindIsAdminWantedDeviceExistQuery } from '../../../api/admin/AdminWantedDeviceAPI';
import { ErrorShowComponent } from '../../../components/ErrorShowComponent/ErrorShowComponent';
import { AdminNavbar } from '../../../components/Navbar1/AdminNavbar';
import { AdminSidebar } from '../../../components/Sidebar/AdminSidebar';

export const AdminEditWantedDevice = () => {

  const { id } = useParams();

  const [triggerIsWantedDeviceExist, { data, isSuccess, error, isLoading, isError }] = useLazyFindIsAdminWantedDeviceExistQuery();
  const [triggerNewProfileImageLink] = useLazyGetProfileImageLinkQuery();
  const [currentUser, setCurrentUser] = useState("");
  const [currentUsermeta, setCurrentUsermeta] = useState("");
  const [profileImageLink, setProfileImageLink] = useState("");
  const [editWantedDeviceSavedData, setEditWantedDeviceSavedData] = useState(null);
  const navigate = useNavigate();

  const [showSuccessMessage, setShowSuccessMessage] = useState({
    message: "",
    color: "",
  });




  //Device Category helper select field
  const [newDeviceCategoryLength, setNewDeviceCategoryLength] = useState(50);
  const [deviceCategorySearchField, setDeviceCategorySearchField] = useState("");

  //Oem helper select field
  const [newOemLength, setNewOemLength] = useState(50);
  const [oemSearchField, setOemSearchField] = useState("");

  const availabilityTax = useGetAvailabilityTaxQuery();
  const clinicalApplicationsTax = useGetClinicalApplicationsTaxQuery();
  const deviceCategoryTax = useGetDeviceCategoryTaxQuery({ id: newDeviceCategoryLength, query: deviceCategorySearchField });
  const oemTax = useGetOemTaxQuery({ id: newOemLength, query: oemSearchField });
  // const deviceCategoryTax = useGetDeviceCategoryTaxQuery();
  // const oemTax = useGetOemTaxQuery();
  const physicalLocationTax = useGetPhysicalLocationTaxQuery();
  const purposeUseTax = useGetPurposeUseTaxQuery();
  const statusConditionTax = useGetStatusConditionTaxQuery();
  const ageOfDeviceTax = useGetAgeOfDeviceTaxQuery();

  // Progress var when this is true means all taxonomy loaded
  const [isTaxonomySet, setIsTaxonomySet] = useState(false);

  //useState var to save tax
  const [allTaxonomy, setAllTaxonomy] = useState({ ageOfDevice: ageOfDeviceTax, statusCondition: statusConditionTax, oem: oemTax, deviceCategory: deviceCategoryTax, physicalLocation: physicalLocationTax, purposeUse: purposeUseTax, clinicalApplications: clinicalApplicationsTax, availability: availabilityTax, })

  useEffect(() => {

    const allSuccess = [ageOfDeviceTax.isSuccess, statusConditionTax.isSuccess, oemTax.isSuccess, deviceCategoryTax.isSuccess, physicalLocationTax.isSuccess, purposeUseTax.isSuccess, clinicalApplicationsTax.isSuccess, availabilityTax.isSuccess,].every(Boolean);


    if (allSuccess) {
      setAllTaxonomy({
        oem: oemTax, deviceCategory: deviceCategoryTax, physicalLocation: physicalLocationTax, purposeUse: purposeUseTax, clinicalApplications: clinicalApplicationsTax, availability: availabilityTax, statusCondition: statusConditionTax, ageOfDevice: ageOfDeviceTax
      });
    }

    setIsTaxonomySet(true);
  }, [
    oemTax.isSuccess, oemTax.data,
    deviceCategoryTax.isSuccess, deviceCategoryTax.data,
    availabilityTax.isSuccess, availabilityTax.data,
    clinicalApplicationsTax.isSuccess, clinicalApplicationsTax.data,
    purposeUseTax.isSuccess, purposeUseTax.data,
    physicalLocationTax.isSuccess, physicalLocationTax.data,
    statusConditionTax.isSuccess, statusConditionTax.data,
    ageOfDeviceTax.isSuccess, ageOfDeviceTax.data,
  ]);


  const [backendValidation, setBackendValidation] = useState([])
  const [isRequestFirst, setIsRequestFirst] = useState(true);
  const formikRef = useRef(null);


  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {

        if (user) {
          // Get user to check wizardStep
          const userDocRef = doc(firestore, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);
          setCurrentUser(user);

          if (userDocSnapshot.exists()) {
            const usermeta = userDocSnapshot.data(); // Default to 1 if not set

            if (usermeta.userType !== 5) {
              navigate('/access-denied');
              return;
            }

            // await triggerGetCurrentPlan().unwrap();
            setCurrentUsermeta(usermeta);
          } else {
            content = <ErrorShowComponent message1={"Internal server error"} message2={"Please come back after some time"} />
          }
        }
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });


    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);

  //Profile image load useEffect
   useEffect(() => {
     const refreshProfileImage = async () => {
       if (currentUsermeta && currentUsermeta.profileImage?.expiresAt < Date.now() / 1000) {
         // Pre-signed URL has expired, trigger a new request to the backend
         const newImageData = await triggerNewProfileImageLink().unwrap();
         // Update the profile image link directly
         setProfileImageLink(newImageData.url);
       } else if (currentUsermeta && currentUsermeta.profileImage?.url) {
         // Use the existing pre-signed URL to display the profile image
         setProfileImageLink(currentUsermeta.profileImage.url);
       }
     };
 
     refreshProfileImage();
   }, [currentUsermeta]);


  //Check if wanted device exist or not 
  useEffect(() => {
    if (currentUsermeta) {
      triggerIsWantedDeviceExist(id);
    }
  }, [currentUsermeta])




  if (isError) {
    if (error.status === 404) {
      console.log(error);
      content = <ErrorShowComponent message1={"Data not found"} message2={"go back to dashboard."} />
    } else {
      content = <ErrorShowComponent message1={"Internal server error"} message2={"Please come back after some time"} />
    }
  }

  if (isSuccess && isTaxonomySet) {
    return (


      <div className="add-wanted-device-forms-add-wanted-device add-wanted-device-forms-add-wanted-device-custom-margin">
        <div className="add-wanted-device-forms-add-wanted-device-child"></div>


        <AdminNavbar userData={currentUser} navigate={navigate} profileImageLink={profileImageLink} />


        <img className="divider-icon" alt="" src={divider_light} />


        <main className="add-wanted-device-content-parent">

          <AdminSidebar profileImageLink={profileImageLink}><SideBarSubSettings userData={currentUser} /></AdminSidebar>


          <section className="add-wanted-device-bottom-actions">
            <div className="add-wanted-device-progress-container-parent">
              <div className="add-wanted-device-progress-container">
                {/* <div className="add-wanted-device-progress-steps">
                <div className="add-wanted-device-form-parent">
                
                
                
                
                </div>
                </div> */}
                {/* {renderProgressStep(step)} */}
                {/* <ProgressStep1/> */}
                {/* <ProgressStep2/> */}
                {/* <ProgressStep3/> */}
                <div className="add-wanted-device-content5">
                  <div className="add-wanted-device-main">


                    <HeaderForms text="Edit Wanted Device " text1=" Edit Wanted Device Listing" text2="Your first step to realizing asset value." />



                    <div className="add-wanted-device-main-child"></div>
                    <div className="add-wanted-device-main-item"></div>
                  </div>
                  <div className="add-wanted-device-body">
                    {/* {renderFormStep(step, setStep)} */}
                    <Provider value={{ oemSearchField, setOemSearchField, newOemLength, setNewOemLength, deviceCategorySearchField, setDeviceCategorySearchField, newDeviceCategoryLength, setNewDeviceCategoryLength, setEditWantedDeviceSavedData, isRequestFirst, setIsRequestFirst, allTaxonomy, backendValidation, setBackendValidation }}>
                      <EditWantedDeviceForm formikRef={formikRef} setShowSuccessMessage={setShowSuccessMessage} />
                    </Provider>
                    {/* <EditWantedDeviceStep2/> */}
                    {/* <EditWantedDeviceStep3/> */}




                  </div>
                  {showSuccessMessage.message && (
                    <div
                      style={{
                        fontSize: "14px",
                        color: showSuccessMessage.color,
                        padding: "15px 10px",
                        border: "1px solid " + showSuccessMessage.color,
                        borderRadius: "10px",
                        margin: "0 auto",
                      }}
                    >
                      {showSuccessMessage.message}
                    </div>
                  )}
                </div>
              </div>

            </div>
          </section>
        </main>
        <img
          className="add-wanted-device-divider-icon10"
          loading="lazy"
          alt=""
          src={divider_light}
        />
        <FooterAddForms />
      </div>
    )
  } else {
    return <Loader />;
  }


}



// const dispatch = useDispatch();
//This snippet used to reset form and applied on reset form button
// const handleReset = () => {
//   if(formikRef.current && editWantedDeviceSavedData != null) {
//     setBackendValidation([])
//     dispatch(fillEditWantedDeviceForm(editWantedDeviceSavedData))
//   };
// }

// const handleReset = () => {
//   if (formikRef.current && editWantedDeviceSavedData != null) {
//     setBackendValidation([])
//     // console.log(editWantedDeviceSavedData);
//     dispatch(fillEditWantedDeviceForm(editWantedDeviceSavedData))
//     setDeviceCategorySearchField(editWantedDeviceSavedData.tax_device_category.name)
//     setOemSearchField(editWantedDeviceSavedData.tax_oem.name)
//   };
// }