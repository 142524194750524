import React, { useEffect, useRef, useState } from 'react';
import "./AdminDetailedSoftware.css"
import { Navbar1 } from '../../../components/Navbar1/Navbar1';
import FooterDetailPage from '../../../components/Footer/FooterDetailPage/FooterDetailPage';
import SlideShowDetailPageComponent from '../../../components/SlideShowDetailPageComponent/SlideShowDetailPageComponent';

import divider from '../../../icons/Gerator/detailed_listing/divider.svg';
import divider2 from '../../../icons/Gerator/detailed_listing/divider2.svg';
import divider3 from '../../../icons/Gerator/detailed_listing/divider3.svg';
import divider4 from '../../../icons/Gerator/detailed_listing/divider4.svg';
import divider5 from '../../../icons/Gerator/detailed_listing/divider5.svg';

import chevronright from '../../../icons/Gerator/detailed_listing/chevronright.svg';

import users01 from '../../../icons/Gerator/detailed_listing/users01.svg';
import add_contact from "../../../icons/Gerator/detailed_listing/add_contact.svg";

import rectangle_9_2x from '../../../icons/Gerator/detailed_listing/rectangle-9@2x.png';
import rectangle_5_2x from '../../../icons/Gerator/detailed_listing/rectangle-5@2x.png';
import rectangle_43_2x from '../../../icons/Gerator/detailed_listing/rectangle-43@2x.png';
import rectangle_95_2x from '../../../icons/Gerator/detailed_listing/rectangle-95@2x.png';
import rectangle_431_2x from '../../../icons/Gerator/detailed_listing/rectangle-431@2x.png';
import rectangle_196_2x from '../../../icons/Gerator/detailed_listing/rectangle-196@2x.png';

import arrowleft from '../../../icons/Gerator/detailed_listing/arrowleft.svg';
import arrowright from '../../../icons/Gerator/detailed_listing/arrowright.svg';

import breadcrumb_controls_2x from '../../../icons/Gerator/detailed_listing/breadcrumb-controls@2x.png';
import breadcrumb_controls1_2x from '../../../icons/Gerator/detailed_listing/breadcrumb-controls1@2x.png';
import breadcrumb_controls2_2x from '../../../icons/Gerator/detailed_listing/breadcrumb-controls2@2x.png';

import placeholder from '../../../icons/Gerator/detailed_listing/placeholder.svg';
import placeholder1 from '../../../icons/Gerator/detailed_listing/placeholder1.svg';
import placeholder2 from '../../../icons/Gerator/detailed_listing/placeholder2.svg';
import target_04 from "../../../icons/Gerator/dashboard/target_04.png"

import maximize01 from '../../../icons/Gerator/detailed_listing/maximize01.svg';
import markerpin02 from '../../../icons/Gerator/detailed_listing/markerpin02.svg';

import calendar from '../../../icons/Gerator/detailed_listing/calendar.svg';
import calendar1 from '../../../icons/Gerator/detailed_listing/calendar1.svg';

import facebook_1_1_2x from '../../../icons/Gerator/detailed_listing/facebook-1-1@2x.png';

import instagram_1_1_2x from '../../../icons/Gerator/detailed_listing/instagram-1-1@2x.png';
import whatsapp_1_2x from '../../../icons/Gerator/detailed_listing/whatsapp-1@2x.png';
import twitter_1_2x from '../../../icons/Gerator/detailed_listing/twitter-1@2x.png';
import linkedinlogo_1_2x from '../../../icons/Gerator/detailed_listing/linkedinlogo-1@2x.png';


import radar_1_1_2x from '../../../icons/Gerator/detailed_listing/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/detailed_listing/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/detailed_listing/magnifier-1@2x.png';
import share_1_2x from '../../../icons/Gerator/detailed_listing/share-1@2x.png';

import group_1010 from '../../../icons/Gerator/detailed_listing/group-1010.svg';
import group_78_2x from '../../../icons/Gerator/detailed_listing/group-78@2x.png';
import group_58_2x from '../../../icons/Gerator/detailed_listing/group-58@2x.png';
import group_781_2x from '../../../icons/Gerator/detailed_listing/group-781@2x.png';

import dotsvertical from '../../../icons/Gerator/detailed_listing/dotsvertical.svg';
import divider1 from '../../../icons/Gerator/detailed_listing/divider1.svg';
import group_351 from '../../../icons/Gerator/detailed_listing/group-351.svg';
import page from '../../../icons/Gerator/detailed_listing/page.svg';
import progress_fill from '../../../icons/Gerator/detailed_listing/progress-fill.svg';
import searchlg from '../../../icons/Gerator/detailed_listing/searchlg.svg';
import filterlines from '../../../icons/Gerator/detailed_listing/filterlines.svg';
import star_background from '../../../icons/Gerator/detailed_listing/star-background.svg';
import star_icon from '../../../icons/Gerator/detailed_listing/star-icon.svg';
import dot1 from '../../../icons/Gerator/detailed_listing/-dot1.svg';
import bookmarkcheck from '../../../icons/Gerator/detailed_listing/bookmarkcheck.svg';
import usercheck02 from '../../../icons/Gerator/detailed_listing/usercheck02.svg';
import f8595f4b711e503bc72fe396e5043e0c2_7_2x from '../../../icons/Gerator/detailed_listing/8595f4b711e503bc72fe396e5043e0c2-7@2x.png';
import messagedotscircle from '../../../icons/Gerator/detailed_listing/messagedotscircle.svg';
import messagechatcircle from '../../../icons/Gerator/detailed_listing/messagechatcircle.svg';


import markerpin021 from '../../../icons/Gerator/detailed_listing/markerpin021.svg';
import icon1 from '../../../icons/Gerator/detailed_listing/icon1.svg';
import login04 from '../../../icons/Gerator/detailed_listing/login04.svg';
import { Loader } from "../../../components/Loader";
// import { useDispatch } from "react-redux";
import { useLazyFindGetSoftwareQuery, useLazyGetSoftwaresQuery } from "../../../api/SoftwareAPI";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getCustomizedDate } from "../../../helper/SoftwareHelper";
import { decryptErrorData } from '../../../helper/AESHelper';
import { useLazyGetProfileImageLinkQuery, useLazyGetUserQuery } from '../../../api/Login&RegisterAPI';
import { firestore, doc, getDoc, auth, onAuthStateChanged } from '../../Login/FirebaseClient';
import { useAddContactMutation, useDeleteContactMutation } from '../../../api/ContactAPI';
import { useAddRadarSoftwareMutation, useDeleteRadarSoftwareMutation } from '../../../api/Radar/RadarSoftwareAPI';
import MapLocationViewComponent from '../../../components/MapLocationViewComponent/MapLocationViewComponent';
import VideoSection from '../../../components/VideoSection/VideoSection';
import { Helmet } from 'react-helmet-async';
import { formatNumber } from '../../../helper/DeviceHelper';
import { useAddViewSoftwareMutation } from '../../../api/View/ViewSoftwareAPI';

import follow from "../../../icons/Gerator/detailed_listing/follow.svg";

import ShareThisMetaData from '../../../components/ShareThisMetaData/ShareThisMetaData';


import { InlineShareButtons } from 'sharethis-reactjs';

import { InlineFollowButtons } from 'sharethis-reactjs';
import { RatingStarComponent } from '../../../components/review/RatingStarComponent/RatingStarComponent';
import { ReviewDescriptionComponent } from '../../../components/review/ReviewDescriptionComponent/ReviewDescriptionComponent';
import ReviewSoftwareView from '../../../components/review/ReviewViewComponent/ReviewSoftwareView';
import AddReviewSoftwareForm from '../../../components/review/ReviewFormComponent/AddReviewSoftwareForm';

import { ThreeDotsDetailPageComponent } from '../../../components/ThreeDotsListingComponent/ThreeDotsDetailPageComponent';
import { ThreeDotsDetailPageUserComponent } from "../../../components/ThreeDotsListingComponent/ThreeDotsDetailPageUserComponent";
import { createPortal } from 'react-dom';
import { ReportAbuseSlideOut } from '../../../components/ReportAbuseSlideOutForm/ReportAbuseSlideOutForm';

import verified from "../../../icons/Gerator/profile_settings/bookmarkcheck.svg";
import unverified from "../../../icons/Gerator/profile_settings/bookmark-x.svg";
import awaiting from "../../../icons/Gerator/profile_settings/bookmark-minus.svg";
import { AdminNavbar } from '../../../components/Navbar1/AdminNavbar';
import { useSetArchiveSoftwareMutation, useSetPublishSoftwareMutation } from '../../../api/admin/AdminListingOperationAPI';

export const AdminDetailedSoftware = () => {

  const [isReportAbuseOpen, setIsReportAbuseOpen] = useState(false);
  const reportAbuseRef = useRef(null);


  const handleLaunchReportAbuseClick = () => {

    setIsReportAbuseOpen(true); // Open reportAbuse slide-out
  };


  const handleCloseReportAbuse = () => {
    setIsReportAbuseOpen(false); // Close reportAbuse slide-out
  };



  // Close reportAbuse and social share when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (reportAbuseRef.current && !reportAbuseRef.current.contains(event.target)) {
        setIsReportAbuseOpen(false); // Close reportAbuse if clicked outside
      }

    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const [isThreeDotOpen, setIsThreeDotOpen] = useState(false);
  const threeDotsRef = useRef(null);
  const [isClickBlocked, setIsClickBlocked] = useState(false);

  // Toggle function for the three dots dropdown
  const toggleThreeDotsDropdown = () => {
    if (isClickBlocked) return; // Prevent rapid toggle

    setIsThreeDotOpen(prev => !prev); // Toggle the dropdown
    setIsClickBlocked(true);
    setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
  };

  // Handle clicks outside the three dots dropdown
  useEffect(() => {
    const handleThreeDotClickOutside = (event) => {
      const isLink = event.target.closest('a');
      if (isLink) return; // Allow navigation to happen

      if (threeDotsRef.current && !threeDotsRef.current.contains(event.target)) {
        setIsThreeDotOpen(false); // Close the dropdown when clicking outside
      }
    };

    document.addEventListener("mousedown", handleThreeDotClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleThreeDotClickOutside);
    };
  }, []);


  // For second dropdown (for user or other context)
  const [isThreeDotUserOpen, setIsThreeDotUserOpen] = useState(false);
  const threeDotsRefUser = useRef(null);
  const [isClickBlockedUser, setIsClickBlockedUser] = useState(false);

  // Toggle function for the second three dots dropdown
  const toggleThreeDotsDropdownUser = () => {
    if (isClickBlockedUser) return; // Prevent rapid toggle

    setIsThreeDotUserOpen(prev => !prev); // Toggle the dropdown
    setIsClickBlockedUser(true);
    setTimeout(() => setIsClickBlockedUser(false), 400); // Small delay to avoid reopening
  };

  // Handle clicks outside the second three dots dropdown
  useEffect(() => {
    const handleThreeDotClickOutsideUser = (event) => {
      const isLink = event.target.closest('a');
      if (isLink) return; // Allow navigation to happen

      if (threeDotsRefUser.current && !threeDotsRefUser.current.contains(event.target)) {
        setIsThreeDotUserOpen(false); // Close the dropdown when clicking outside
      }
    };

    document.addEventListener("mousedown", handleThreeDotClickOutsideUser);
    return () => {
      document.removeEventListener("mousedown", handleThreeDotClickOutsideUser);
    };
  }, []);


  const formikRef = useRef(null);


  const [showReply, setShowReply] = useState(false);
  const [replyText, setReplyText] = useState('');

  const maxLength = 500; // Maximum character count

  const handleReplyClick = () => {
    setShowReply(!showReply); // Toggle text area visibility
  };

  const handleChange = (event) => {
    if (event.target.value.length <= maxLength) {
      setReplyText(event.target.value); // Update reply text
    }
  };

  const handleSubmit = () => {
    // Handle reply submission here
    console.log('Reply submitted:', replyText);
    setShowReply(false); // Hide the text area after submission
  };

  const remainingChars = maxLength - replyText.length;
  const warningClass = remainingChars <= 50 ? 'warning' : '';

  const [activeSection, setActiveSection] = useState(null); // "share", "follow", or null

  const handleShareButtonClick = () => {
    setActiveSection((prevState) => (prevState === 'share' ? null : 'share')); // Toggle or close
  };

  const handleFollowButtonClick = () => {
    setActiveSection((prevState) => (prevState === 'follow' ? null : 'follow')); // Toggle or close
  };


  const { id } = useParams();

  let content;
  const [currentUser, setCurrentUser] = useState("");
  const navigate = useNavigate();
  const [profileImageLink, setProfileImageLink] = useState("");
  const [currentUsermeta, setCurrentUsermeta] = useState("");


  const [triggerNewProfileImageLink] = useLazyGetProfileImageLinkQuery();


  // const [triggerGetUser, { data, isSuccess, error, isLoading, isError }] = useLazyGetUserQuery();
  const [triggerGetSoftware, { data: softwareData,
    isLoading: isSoftwareLoading,
    isSuccess: isSoftwareSuccess,
    isError: isSoftwareError,
    error: softwareError }] = useLazyFindGetSoftwareQuery(id);

  const [addViewSoftware, { data: softwareViewData,
    isLoading: isViewSoftwareLoading,
    isSuccess: isViewSoftwareSuccess,
    isError: isViewSoftwareError,
    error: softwareViewError }] = useAddViewSoftwareMutation();


  const [addToContact, {
    isLoading: isContactLoading,
    isSuccess: isContactSuccess,
    isError: isContactError,
    error: contactError }] = useAddContactMutation();

  const [deleteFromContact] = useDeleteContactMutation();

  // Radar query delete
  const [deleteFromRadar] = useDeleteRadarSoftwareMutation();

  // Radar query delete
  const [addToRadar] = useAddRadarSoftwareMutation();

  // Listing Operation
  const [setToSoftwareArchive] = useSetArchiveSoftwareMutation();

  const [setToSoftwarePublish] = useSetPublishSoftwareMutation();


  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {

        if (user) {
          // Get user to check wizardStep
          const userDocRef = doc(firestore, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);
          setCurrentUser(user);

          if (userDocSnapshot.exists()) {
            const usermeta = userDocSnapshot.data(); // Default to 1 if not set

            if (usermeta.userStatus == 2) {
              navigate('/account-disabled');
              return;
            }
            if (usermeta.userType !== 5) {
              navigate('/access-denied');
              return;
            }

            if (usermeta.wizardStep !== 0) {
              navigate('/account-setup-check-email');
              return;
            }

            // await triggerGetCurrentPlan().unwrap();
            setCurrentUsermeta(usermeta);
            triggerGetSoftware(id);
            // User is authenticated
          } else {
            content = <ErrorShowComponent message1={"Internal server error"} />
          }
        }
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });


    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);


  //Profile image load useEffect
  useEffect(() => {
    const refreshProfileImage = async () => {
      if (currentUsermeta && currentUsermeta.profileImage?.expiresAt < Date.now() / 1000) {
        // Pre-signed URL has expired, trigger a new request to the backend
        const newImageData = await triggerNewProfileImageLink().unwrap();
        // Update the profile image link directly
        setProfileImageLink(newImageData.url);
      } else if (currentUsermeta && currentUsermeta.profileImage?.url) {
        // Use the existing pre-signed URL to display the profile image
        setProfileImageLink(currentUsermeta.profileImage.url);
      }
    };

    refreshProfileImage();
  }, [currentUsermeta]);


  // // Trigger the get wanted software query only after user data is successfully fetched
  useEffect(() => {
    if (isSoftwareSuccess && softwareData) {
      addViewSoftware(id);
      console.log('added view data');
    }
  }, [isSoftwareSuccess, softwareData]);

  useEffect(() => {
    if (softwareViewData) {
      console.log(softwareViewData);
    }
  }, [softwareViewData]);

  const handleRemoveRadar = async (softwareId) => {
    await deleteFromRadar(softwareId);
    window.location.reload();
  };

  const handleAddRadar = async (softwareId) => {
    await addToRadar(softwareId);
    window.location.reload();
  };

  const handleRemoveContact = async (softwareId) => {
    const result = await deleteFromContact(softwareId);
    if (result.data) {
      // If successful, reload the page
      window.location.reload();
    } else if (result.error) {
      console.log(result.error);
    }
  };

  const handleAddContact = async (softwareId) => {
    const result = await addToContact(softwareId);
    if (result.data) {
      // If successful, reload the page
      window.location.reload();
    } else if (result.error) {
      console.log(result.error);
    }
  };


  const handleSetArchive = async (e) => {
    let response;
    e.preventDefault();
    e.stopPropagation();

    try {
      if (!id) {
        console.error('No post ID provided');
        return;
      }

      response = await setToSoftwareArchive({ id }).unwrap();

      if (response?.status) {
        // onClose();
        window.location.reload();
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleSetPublish = async (e) => {
    let response;
    e.preventDefault();
    e.stopPropagation();

    try {
      if (!id) {
        console.error('No post ID provided');
        return;
      }

      response = await setToSoftwarePublish({ id }).unwrap();

      // console.log('Updating status for post:', postId);
      // const response = await setToWantedDevicePublish({ id: postId }).unwrap();
      // console.log('Update response:', response);

      if (response.status) {
        window.location.reload();
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  // To show error when adding contact
  useEffect(() => {
    // const { status } = contactError;
    // console.log(contactError);

    if (contactError?.status === 405)
      console.log(contactError.data.message);
    else
      console.log("Internal server error");
  }, [isContactError])


  // CHAT

  const handlePrivateMessage = () => {
    const softwareId = softwareData.data.id; // Use the appropriate ID for the chat
    handleAddRadar(softwareId);
    navigate(`/chat/offered/Software/${softwareId}`); // Replace `/chat/:id` with the correct route for your chat page
  };

  if (isSoftwareSuccess) {
    return (
      <>
        <Helmet>
          <title>Software</title>
        </Helmet>
        <ShareThisMetaData
          title={`Discover ${softwareData.data.title} on Gerator.io!`}
          description={`Check out ${softwareData.data.title} – a top listing on Gerator.io!`}
          image={softwareData.data.galleryImages[0]}
          url={window.location.href}
        />
        <div className="admin-detailed-software-listings-detailed-page-devic">
          <div className="admin-detailed-software-rectangle-parent">
            <div className="admin-detailed-software-frame-child"></div>
            <AdminNavbar userData={currentUser} navigate={navigate} profileImageLink={profileImageLink} />
            <img className="admin-detailed-software-divider-icon" alt="" src={divider} />

            {/* <div className="admin-detailed-software-breadcrumb-button-base">
              <div className="admin-detailed-software-text6">...</div>
            </div>
            <img
              className="admin-detailed-software-chevron-right-icon"
              alt=""
              src={chevronright}
            />

            <div className="admin-detailed-software-breadcrumb-button-base1">
              <div className="admin-detailed-software-text7">Another link</div>
            </div>
            <img
              className="admin-detailed-software-chevron-right-icon1"
              alt=""
              src={chevronright}
            /> */}

            <div className="admin-detailed-software-frame-parent">
              <div className="admin-detailed-software-breadcrumb-container-parent">
                <SlideShowDetailPageComponent slides={softwareData.data.galleryImages} />
                <div className="admin-detailed-software-breadcrumb">
                  <div className="admin-detailed-software-breadcrumb-items">
                    <div className="admin-detailed-software-breadcrumb-button-base2">
                      <div className="admin-detailed-software-text9">Dashboard</div>
                    </div>
                    <div className="admin-detailed-software-chevron-container">
                      <img
                        className="admin-detailed-software-chevron-right-icon2"
                        alt=""
                        src={chevronright}
                      />
                    </div>
                    <div className="admin-detailed-software-breadcrumb-button-base3">
                      <div className="admin-detailed-software-text10">Software</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="admin-detailed-software-listing-content-wrapper">
                <div className="admin-detailed-software-listing-content">
                  <div className="admin-detailed-software-description-block-parent">
                    <div className="admin-detailed-software-description-block">
                      <div className="admin-detailed-software-description-heading">
                        {softwareData.data.title}
                      </div>
                      <div className="admin-detailed-software-listing-details">
                        <div className="admin-detailed-software-detail-container">
                          <div className="admin-detailed-software-details-content">
                            <div className="admin-detailed-software-details-row">
                              <div className="admin-detailed-software-detail-items">
                                <img
                                  className="admin-detailed-software-marker-pin-02-icon"
                                  alt=""
                                  src={markerpin02}
                                />
                                {softwareData.data.software_address.tax_address_type
                                  .slug === "address" ?
                                  <div className="admin-detailed-software-supporting-text">{softwareData.data.software_address.addressTownCity},  {softwareData.data.software_address.tax_country.name}</div>
                                  :
                                  <div className="admin-detailed-software-supporting-text">{softwareData.data.software_address.tax_address_type
                                    .name}</div>
                                }


                              </div>
                              <div className="admin-detailed-software-detail-items">
                                <img
                                  className="admin-detailed-software-marker-pin-02-icon"
                                  alt=""
                                  src={calendar}
                                />

                                <div className="admin-detailed-software-supporting-text">{getCustomizedDate(softwareData.data.createdAt)}</div>
                              </div>
                            </div>
                            <div className="admin-detailed-software-contact-details">
                              <div className="admin-detailed-software-contact-name-wrapper">
                                <div className="admin-detailed-software-contact-name">{softwareData.data.totalRatingAvg ? `${softwareData.data.totalRatingAvg}/5` : "0/5"}</div>
                              </div>
                              <div className="admin-detailed-software-contact-role">
                                <div className="admin-detailed-software-supporting-text2">
                                {softwareData.data.totalReviews} {softwareData.data.totalReviews === 0 || softwareData.data.totalReviews === 1 ? "review" : "reviews"}

                                  </div>
                              </div>
                              {/* <div className="admin-detailed-software-social-links">
                                <div className="admin-detailed-software-rectangle-container">
                                  <div className="admin-detailed-software-frame-inner"></div>
                                  <div className="admin-detailed-software-icon-backgrounds-parent">
                                    <div className="admin-detailed-software-icon-backgrounds"></div>
                                    <img
                                      className="admin-detailed-software-whatsapp-1-icon"
                                      alt=""
                                      src={facebook_1_1_2x}
                                    />
                                  </div>
                                  <div className="admin-detailed-software-icon-backgrounds-parent">
                                    <div className="admin-detailed-software-ellipse-div"></div>
                                    <img
                                      className="admin-detailed-software-whatsapp-1-icon"
                                      alt=""
                                      src={instagram_1_1_2x}
                                    />
                                  </div>
                                  <div className="admin-detailed-software-icon-backgrounds-parent">
                                    <div className="admin-detailed-software-frame-child1"></div>
                                    <img
                                      className="admin-detailed-software-whatsapp-1-icon"
                                      alt=""
                                      src={whatsapp_1_2x}
                                    />
                                  </div>
                                  <div className="admin-detailed-software-icon-backgrounds-parent">
                                    <div className="admin-detailed-software-icon-backgrounds"></div>
                                    <img
                                      className="admin-detailed-software-whatsapp-1-icon"
                                      alt=""
                                      src={twitter_1_2x}
                                    />
                                  </div>
                                  <div className="admin-detailed-software-icon-backgrounds-parent">
                                    <div className="admin-detailed-software-ellipse-div"></div>
                                    <img
                                      className="admin-detailed-software-whatsapp-1-icon"
                                      alt=""
                                      src={linkedinlogo_1_2x}
                                    />
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          </div>
                          <div className="admin-detailed-software-button-row">
                            {/* {!softwareData.data.isAuthor && (
                              softwareData.data.addedToRadar ? (

                                <div className="admin-detailed-software-buttonsbutton1" onClick={() => handleRemoveRadar(softwareData.data.id)} style={{ cursor: "pointer" }}>
                                  <img
                                    className="admin-detailed-software-magnifier-1-icon"
                                    alt=""
                                    src={target_04}
                                  />
                                  <div className="admin-detailed-software-text-padding">
                                    <div className="admin-detailed-software-text11">Added To Radar</div>
                                  </div>

                                </div>
                              ) : (
                                <div className="admin-detailed-software-buttonsbutton1" onClick={() => handleAddRadar(softwareData.data.id)} style={{ cursor: "pointer" }}>
                                  <img
                                    className="admin-detailed-software-magnifier-1-icon"
                                    alt=""
                                    src={radar_1_1_2x}
                                  />

                                  <div className="admin-detailed-software-text-padding">
                                    <div className="admin-detailed-software-text11">Add To Radar</div>
                                  </div>
                                </div>
                              )
                            )} */}
                            {/* <div className="admin-detailed-software-buttonsbutton2">
                              <img
                                className="admin-detailed-software-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />

                              <img
                                className="admin-detailed-software-magnifier-1-icon"
                                alt=""
                                src={deal_2_1_2x}
                              />

                              <img
                                className="admin-detailed-software-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />
                            </div>
                            <div className="admin-detailed-software-buttonsbutton2">
                              <img
                                className="admin-detailed-software-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />

                              <img
                                className="admin-detailed-software-magnifier-1-icon"
                                alt=""
                                src={magnifier_1_2x}
                              />

                              <img
                                className="admin-detailed-software-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />
                            </div> */}
                            {/* <div className='detailed-software-sharethis-parent'>

                              <div className="admin-detailed-software-buttonsbutton2" onClick={handleShareButtonClick} style={{ cursor: 'pointer' }}>
                                <img
                                  className="admin-detailed-software-placeholder-icon4"
                                  alt=""
                                  src={placeholder1}
                                />

                                <img
                                  className="admin-detailed-software-magnifier-1-icon"
                                  alt=""
                                  src={share_1_2x}
                                />

                                <img
                                  className="admin-detailed-software-placeholder-icon4"
                                  alt=""
                                  src={placeholder1}
                                />



                              </div>

                              {activeSection === 'share' && (
                                <div className='detailed-software-sharethis'>
                                  <InlineShareButtons
                                    config={{
                                      alignment: 'center',
                                      color: 'black',
                                      enabled: true,
                                      font_size: 14,
                                      labels: 'cta',
                                      language: 'en',
                                      networks: ["twitter", "whatsapp", "linkedin", "email", "facebook"
                                        , "flipboard"
                                        , "google"
                                        , "digg"
                                        , "livejournal"
                                        , "mailru"
                                        , "meneame"
                                        , "messenger"
                                        , "oknoklassniki"
                                        , "pinterest"
                                        , "print"
                                        , "reddit"
                                        , "sharethis"
                                        , "sms"
                                        , "stumbleupon"
                                        , "tumblr"
                                        , "blogger", "vk", "wechat", "weibo", "delicious", "xing"],
                                      padding: 12,
                                      radius: 4,
                                      show_total: false,
                                      size: 34,
                                      url: "https://gerator.io/", // Use the current page URL dynamically
                                      image: 'https://bit.ly/2CMhCMC',
                                      description: 'Check out this amazing content!',
                                      title: 'Custom Share Title',
                                    }}
                                  />
                                </div>
                              )}

                            </div > */}


                            {/* <img
                                                          className="admin-detailed-software-button-row-child"
                                                          alt=""
                                                          src={group_1010}
                                                        /> */}

                            {/* <div className='detailed-software-sharethis-follow-parent'>

                              <div className="admin-detailed-software-buttonsbutton2" onClick={handleFollowButtonClick} style={{ cursor: 'pointer' }}>
                                <img
                                  className="admin-detailed-software-placeholder-icon4"
                                  alt=""
                                  src={placeholder1}
                                />

                                <img
                                  className="admin-detailed-software-magnifier-1-icon"
                                  alt=""
                                  src={follow}

                                />

                                <img
                                  className="admin-detailed-software-placeholder-icon4"
                                  alt=""
                                  src={placeholder1}
                                />

                                Follow us

                              </div>

                              {activeSection === 'follow' && (
                                <div className='detailed-software-sharethis-follow'>
                                  <InlineFollowButtons
                                    config={{
                                      action: 'Follow us:', // call to action (STRING)
                                      action_enable: false,
                                      action_pos: 'bottom', // position of call to action (left, top, right)
                                      alignment: 'center',  // alignment of buttons (left, center, right)
                                      color: 'white',       // set the color of buttons (social, white)
                                      enabled: true,        // show/hide buttons (true, false)
                                      networks: [
                                        'linkedin',         // which networks to include (see FOLLOW NETWORKS)
                                        'twitter',
                                        'facebook',
                                        'instagram',

                                      ],
                                      padding: 8,           // padding within buttons (INTEGER)
                                      profiles: {           // social profile links for buttons
                                        twitter: 'ger8or',
                                        facebook: 'Ger8orMarketplace ',
                                        instagram: 'ger8or',
                                        linkedin: 'company/gerator',
                                      },
                                      radius: 9,            // the corner radius on each button (INTEGER)
                                      size: 32,             // the size of each button (INTEGER)
                                      spacing: 8            // the spacing between buttons (INTEGER)
                                    }}
                                  />
                                </div>
                              )}

                            </div> */}
                          </div>
                        </div>
                        <div className="admin-detailed-software-dropdown-container" style={{ cursor: "pointer" }} onClick={toggleThreeDotsDropdown} ref={threeDotsRef}>
                          <div className="admin-detailed-software-dropdown">
                            <img
                              className="admin-detailed-software-maximize-01-icon"
                              alt=""
                              src={dotsvertical}
                            />
                          </div>

                          {isThreeDotOpen && <ThreeDotsDetailPageComponent />}

                        </div>
                      </div>
                    </div>
                    <img className="admin-detailed-software-divider-icon" alt="" src={divider1} />

                    <div className="admin-detailed-software-heading-parent">
                      <div className="admin-detailed-software-heading">Highlights</div>
                      <div className="admin-detailed-software-description-content">
                        <div className="admin-detailed-software-description-columns-first">
                          <div className="admin-detailed-software-first-columns-paragraphs">
                            <div className="admin-detailed-software-paragraph">
                              {softwareData.data.tax_software_category.name}
                            </div>
                            <div className="admin-detailed-software-paragraph1">Software Category</div>
                          </div>
                          <div className="admin-detailed-software-first-columns-paragraphs">
                            <div className="admin-detailed-software-paragraph">{softwareData.data.tax_software_type.name}</div>
                            <div className="admin-detailed-software-paragraph1">Software Type</div>

                          </div>

                          {softwareData.data?.tax_software_category?.name?.trim() !== "" && (
                            <div className="admin-detailed-software-second-columns-paragraphs">
                              <div className="admin-detailed-software-paragraph">
                                {softwareData.data.tax_software_category.name}
                              </div>
                              <div className="admin-detailed-software-paragraph1">Device Category</div>
                            </div>
                          )}

                        </div>
                        <div className="admin-detailed-software-description-columns-second">
                          <div className="admin-detailed-software-second-columns-paragraphs">
                            <div className="admin-detailed-software-paragraph">
                              Fittydent-Altwirth & Schmitt
                            </div>
                            <div className="admin-detailed-software-paragraph1">Software Brand / Company</div>
                          </div>
                          <div className="admin-detailed-software-second-columns-paragraphs">
                            <div className="admin-detailed-software-paragraph">{softwareData.data.tax_service_and_support_reach.name}</div>
                            <div className="admin-detailed-software-paragraph1">Service & Support Reach</div>

                          </div>

                          <div className="admin-detailed-software-first-columns-paragraphs">
                            <div className="admin-detailed-software-paragraph">{softwareData.data.tax_installation_location.name}</div>
                            <div className="admin-detailed-software-paragraph1">Installation Location</div>
                          </div>


                          {softwareData.data?.tax_physical_location?.name?.trim() !== "" && (
                            <div className="admin-detailed-software-second-columns-paragraphs">
                              <div className="admin-detailed-software-paragraph">{softwareData.data.tax_physical_location.name}</div>
                              <div className="admin-detailed-software-paragraph1">Physical Location</div>
                            </div>
                          )}
                          {softwareData.data?.tax_clinical_application?.name?.trim() !== "" && (
                            <div className="admin-detailed-software-second-columns-paragraphs">
                              <div className="admin-detailed-software-paragraph">
                                {softwareData.data.tax_clinical_application.name}
                              </div>
                              <div className="admin-detailed-software-paragraph1">Clinical Applications</div>
                            </div>
                          )}
                          {softwareData.data?.tax_purpose_use?.name?.trim() !== "" && (
                            <div className="admin-detailed-software-second-columns-paragraphs">
                              <div className="admin-detailed-software-paragraph">{softwareData.data.tax_purpose_use.name}</div>
                              <div className="admin-detailed-software-paragraph1">Device Use</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="admin-detailed-software-content-blocks">
                      <div className="admin-detailed-software-heading1">Software Features Highlights</div>
                      <div className="admin-detailed-software-paragraph26">
                        {softwareData.data.softwareFeaturesHighlights}
                      </div>
                    </div>
                    <div className="admin-detailed-software-content-blocks">
                      <div className="admin-detailed-software-heading1">
                        Software Benefits Highlights
                      </div>
                      <div className="admin-detailed-software-paragraph26">
                        {softwareData.data.softwareBenefitsHighlights}
                      </div>
                    </div>
                    <div className="admin-detailed-software-content-blocks">
                      <div className="admin-detailed-software-heading1">Parent Devices for which this software is available</div>
                      <div className="admin-detailed-software-paragraph26">
                        {softwareData.data.listParentSoftware}
                      </div>
                    </div>
                    <div className="admin-detailed-software-content-blocks">
                      <div className="admin-detailed-software-heading1">Location</div>
                      {softwareData.data.software_address.tax_address_type
                        .slug === "address" ?
                        <div className="admin-detailed-software-paragraph26">
                          {softwareData.data.software_address?.fullAddress}
                        </div>
                        :
                        <div className="admin-detailed-software-paragraph26">
                          {softwareData.data.software_address.tax_address_type
                            .name}
                        </div>
                      }

                      {softwareData.data.software_address.tax_address_type
                        .slug === "address" && <MapLocationViewComponent
                          key={softwareData.data.id}
                          width={"100%"}
                          height={"400px"}
                          initialCoordinates={softwareData.data.software_address.addressLocation}
                        />}
                    </div>
                    {(softwareData.data.videoType && softwareData.data.linkVideo) &&
                      // (softwareData.data.videoType === 'Youtube') ?
                      <>
                        <VideoSection videoUrl={softwareData.data.linkVideo} />
                        <br />
                      </>
                      // :
                      // <>
                      //   <VideoSection videoUrl={serviceData.data.linkVideo} />
                      //   <br />
                      // </>
                    }

                    <img className="admin-detailed-software-divider-icon" alt="" src={divider1} />



                    <div className="admin-detailed-software-section">
                      <div className="admin-detailed-software-benefits-container">
                        <div className="admin-detailed-software-benefits-content">
                          <div className="admin-detailed-software-heading6">Description</div>
                          {softwareData.data?.softwareFeatures?.trim() !== "" && (
                            <div className="admin-detailed-software-benefits-subtitle">
                              <div className="admin-detailed-software-heading7">Software Features</div>
                              <div className="admin-detailed-software-paragraph30">
                                {softwareData.data.softwareFeatures}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {softwareData.data?.softwareBenefits?.trim() !== "" && (
                      <div className="admin-detailed-software-content-blocks">
                        <div className="admin-detailed-software-heading1">
                          Software Benefits
                        </div>
                        <div className="admin-detailed-software-paragraph26">
                          {softwareData.data.softwareBenefits}
                        </div>
                      </div>
                    )}
                    {softwareData.data?.systemRequirements?.trim() !== "" && (
                      <div className="admin-detailed-software-content-blocks">
                        <div className="admin-detailed-software-heading1">System Requirements</div>
                        <div className="admin-detailed-software-paragraph26">
                          {softwareData.data.systemRequirements}
                        </div>
                      </div>
                    )}
                    {softwareData.data?.additionalInformation?.trim() !== "" && (
                      <div className="admin-detailed-software-content-blocks">
                        <div className="admin-detailed-software-heading1">Additional Information</div>
                        <div className="admin-detailed-software-paragraph26">
                          {softwareData.data.additionalInformation}
                        </div>
                      </div>
                    )}

                    {softwareData.data?.documentFiles?.length > 0 && (
                      <div className="detailed-device-heading-parent">
                        <div className="detailed-device-heading1">Documents</div>
                        <div className="detailed-device-download-items-parent detailed-device-download-items-parent-custom">
                          {softwareData.data.documentFiles.map((link, index) => (
                            <a
                              key={index}
                              href={link} // Use the appropriate property for the file URL
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <div className="detailed-device-download-items">
                                <div className="detailed-device-file-upload-item-base">
                                  <img
                                    className="detailed-device-progress-fill-icon"
                                    alt=""
                                    src={progress_fill}
                                  />
                                  <div className="detailed-device-file-type-icon">
                                    <img
                                      className="detailed-device-page-icon"
                                      alt=""
                                      src={page}
                                    />
                                    <b className="detailed-device-file-type">PDF</b>
                                  </div>
                                  <div className="detailed-device-download-info">
                                    <div className="detailed-device-text12">
                                      {softwareData.data.softwares_files[index].file_name} {/* Access file name from the current file object */}
                                    </div>
                                    <div className="detailed-device-supporting-text3">
                                      {(softwareData.data.softwares_files[index].file_size / 1000).toFixed(1) + " KB"} {/* Access file size */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          ))}
                        </div>
                      </div>
                    )}
                    {/* <div className="admin-detailed-software-heading15">Reviews (2)</div>
                    <div className="admin-detailed-software-filters-bar">
                      <div className="admin-detailed-software-content4">
                        <div className="admin-detailed-software-button-group">
                          <div className="admin-detailed-software-button-group-base">
                            <div className="admin-detailed-software-text18">Newest</div>
                          </div>
                          <div className="admin-detailed-software-button-group-base1">
                            <div className="admin-detailed-software-text19">Highest</div>
                          </div>
                          <div className="admin-detailed-software-button-group-base2">
                            <div className="admin-detailed-software-text20">Text</div>
                          </div>
                          <div className="admin-detailed-software-button-group-base3">
                            <div className="admin-detailed-software-text20">Text</div>
                          </div>
                          <div className="admin-detailed-software-button-group-base4">
                            <div className="admin-detailed-software-text20">Text</div>
                          </div>
                          <div className="admin-detailed-software-button-group-base5">
                            <div className="admin-detailed-software-text20">Text</div>
                          </div>
                          <div className="admin-detailed-software-button-group-base6">
                            <div className="admin-detailed-software-text20">Text</div>
                          </div>
                          <div className="admin-detailed-software-button-group-base7">
                            <div className="admin-detailed-software-text20">Text</div>
                          </div>
                          <div className="admin-detailed-software-button-group-base8">
                            <div className="admin-detailed-software-text26">Lowest</div>
                          </div>
                        </div>
                        <div className="admin-detailed-software-actions">
                          <div className="admin-detailed-software-input-dropdown">
                            <div className="admin-detailed-software-input-with-label1">
                              <div className="admin-detailed-software-label">Search</div>
                              <div className="admin-detailed-software-input1">
                                <div className="admin-detailed-software-content5">
                                  <img
                                    className="admin-detailed-software-search-lg-icon"
                                    alt=""
                                    src={searchlg}
                                  />

                                  <input
                                    className="admin-detailed-software-text27"
                                    placeholder="Thomson Medical"
                                    type="text"
                                  />
                                  <div className="admin-detailed-software-text27">Thomson Medical</div>
                                  <div className="admin-detailed-software-supporting-text9">@olivia</div>
                                </div>
                              </div>
                            </div>
                            <div className="admin-detailed-software-hint-text">
                              This is a hint text to help user.
                            </div>
                          </div>
                          <div className="admin-detailed-software-button">
                            <img
                              className="admin-detailed-software-filter-lines-icon"
                              alt=""
                              src={filterlines}
                            />

                            <div className="admin-detailed-software-text-padding">
                              <div className="admin-detailed-software-text28">Filters</div>
                            </div>
                            <img
                              className="admin-detailed-software-placeholder-icon4"
                              alt=""
                              src={placeholder1}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="admin-detailed-software-review-container">
                      <div className="admin-detailed-software-review-content">
                        <div className="admin-detailed-software-first-review">
                          <b className="admin-detailed-software-empty-review">5.0</b>
                          <div className="admin-detailed-software-reviewer">
                            <div className="admin-detailed-software-heading16">/5</div>
                          </div>
                        </div>
                        <div className="admin-detailed-software-review-stars-background">
                          <img
                            className="admin-detailed-software-star-background-icon"
                            alt=""
                            src={star_background}
                          />

                          <img
                            className="admin-detailed-software-star-background-icon"
                            alt=""
                            src={star_background}
                          />

                          <img
                            className="admin-detailed-software-star-background-icon"
                            alt=""
                            src={star_background}
                          />

                          <img
                            className="admin-detailed-software-star-background-icon"
                            alt=""
                            src={star_background}
                          />

                          <img
                            className="admin-detailed-software-star-background-icon"
                            alt=""
                            src={star_background}
                          />
                        </div>
                      </div>
                      <div className="admin-detailed-software-review-stars">
                        <div className="admin-detailed-software-rectangle-parent1">
                          <div className="admin-detailed-software-rectangle-div"></div>
                          <div className="admin-detailed-software-stars">
                            <img
                              className="admin-detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="admin-detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="admin-detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="admin-detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="admin-detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
                          </div>
                          <div className="admin-detailed-software-first-review-fifth-star">
                            <div className="admin-detailed-software-heading17">Accuracy (5.0)</div>
                          </div>
                        </div>
                        <div className="admin-detailed-software-rectangle-parent1">
                          <div className="admin-detailed-software-rectangle-div"></div>
                          <div className="admin-detailed-software-stars">
                            <img
                              className="admin-detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="admin-detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="admin-detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="admin-detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="admin-detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
                          </div>
                          <div className="admin-detailed-software-heading-wrapper">
                            <div className="admin-detailed-software-heading18">Relevance (5.0)</div>
                          </div>
                        </div>
                        <div className="admin-detailed-software-rectangle-parent3">
                          <div className="admin-detailed-software-rectangle-div"></div>
                          <div className="admin-detailed-software-stars-wrapper">
                            <div className="admin-detailed-software-stars2">
                              <img
                                className="admin-detailed-software-filter-lines-icon"
                                alt=""
                                src={star_icon}
                              />

                              <img
                                className="admin-detailed-software-filter-lines-icon"
                                alt=""
                                src={star_icon}
                              />

                              <img
                                className="admin-detailed-software-filter-lines-icon"
                                alt=""
                                src={star_icon}
                              />

                              <img
                                className="admin-detailed-software-filter-lines-icon"
                                alt=""
                                src={star_icon}
                              />

                              <img
                                className="admin-detailed-software-filter-lines-icon"
                                alt=""
                                src={star_icon}
                              />
                            </div>
                          </div>
                          <div className="admin-detailed-software-heading19">Value for Money (5.0)</div>
                        </div>
                      </div>
                    </div> */}
                    <div className="admin-detailed-software-divider-wrapper">
                      <img
                        className="admin-detailed-software-divider-icon3"
                        alt=""
                        src={divider1}
                      />
                    </div>
                    {/* <div className="admin-detailed-software-client-review">
                      <img
                        className="admin-detailed-software-client-review-child"
                        alt=""
                        src={group_78_2x}
                      />

                      <div className="admin-detailed-software-client-review-header">
                        <div className="admin-detailed-software-heading20">Chloe Tammy</div>
                        <div className="admin-detailed-software-heading21">
                          <span>Marketing Communications at</span>
                          <span className="admin-detailed-software-alvo-medical"> Alvo Medical</span>
                        </div>
                      </div>
                      <div className="admin-detailed-software-client-review-content">
                        <div className="admin-detailed-software-client-rating">
                          <div className="admin-detailed-software-stars3">
                            <img
                              className="admin-detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="admin-detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="admin-detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="admin-detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="admin-detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
                          </div>
                          <div className="admin-detailed-software-rating-value">
                            <div className="admin-detailed-software-heading22">1 month ago</div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="admin-detailed-software-service-card">
                    <div className="admin-detailed-software-card-content">
                      <div className="admin-detailed-software-card-content-child"></div>
                      <div className="admin-detailed-software-card-header">
                        <div className="admin-detailed-software-price-tag">
                          {/* <div className="admin-detailed-software-badge-wrapper">
                          <div className="admin-detailed-software-badge3">
                            <img
                              className="admin-detailed-software-dot-icon3"
                              alt=""
                              src={dot1}
                            />

                            <div className="admin-detailed-software-text29">Sale</div>
                          </div>
                        </div> */}
                          <div className="admin-detailed-software-price-parent">
                            <div className="admin-detailed-software-price">${formatNumber(softwareData.data.rangeMin)}</div>
                            <div className="admin-detailed-software-original-price">
                              <div className="admin-detailed-software-discount">{softwareData.data.tax_unit_measure_software.name}</div>
                            </div>
                          </div>
                          {/* <div className="admin-detailed-software-service-title-container">
                          <div className="admin-detailed-software-service-title">Posted By Manufacturer</div>
                        </div> */}
                        </div>
                      </div>
                      <div className="admin-detailed-software-card-footer">


                        {softwareData.data.profileImage ?

                          <img
                            className="admin-detailed-software-card-footer-child"
                            alt=""
                            src={softwareData.data.profileImage}
                          />
                          :
                          <img
                            className="admin-detailed-software-card-footer-child"
                            alt=""
                            src={group_781_2x}
                          />

                        }

                        <div className="admin-detailed-software-footer-divider">
                          <img
                            className="admin-detailed-software-divider-icon4"
                            alt=""
                            src={divider2}
                          />
                        </div>
                      </div>
                      <div className="admin-detailed-software-professional-info">
                        <div className="admin-detailed-software-professional-details">
                          <div className="admin-detailed-software-professional-actions">
                            <div className="admin-detailed-software-benefits-container">
                              <div className="admin-detailed-software-amlie-laurent">{softwareData.data.software_user.displayName}</div>
                              <Link className="admin-detailed-software-role" to={`/business/${softwareData.data.software_business.id}/${softwareData.data.software_business.businessName}`}>
                                {softwareData.data.software_business.businessName}
                              </Link>
                              {/* <div className="admin-detailed-software-role">{softwareData.data.software_business.businessName}</div> */}
                            </div>
                            <div className="admin-detailed-software-action-buttons">
                              <div className="admin-detailed-software-contact-button">
                                <div className="admin-detailed-software-message-button">

                                  {softwareData.data.software_business.businessVerification === 1 &&
                                    <>
                                      <div className="admin-detailed-software-navigation">
                                        <img
                                          className="admin-detailed-software-bookmark-check-icon"
                                          alt=""
                                          src={verified}
                                        />
                                      </div>
                                      <div className="admin-detailed-software-supporting-text10">
                                        Verified by Gerätor
                                      </div>
                                    </>
                                  }
                                  {softwareData.data.software_business.businessVerification === 2 &&
                                    <>
                                      <div className="admin-detailed-software-navigation">
                                        <img
                                          className="admin-detailed-software-bookmark-check-icon"
                                          alt=""
                                          src={unverified}
                                        />
                                      </div>
                                      <div className="admin-detailed-software-supporting-text10-a">
                                        Unverified
                                      </div>
                                    </>
                                  }
                                  {softwareData.data.software_business.businessVerification === 3 &&
                                    <>
                                      <div className="admin-detailed-software-navigation">
                                        <img
                                          className="admin-detailed-software-bookmark-check-icon"
                                          alt=""
                                          src={awaiting}
                                        />
                                      </div>
                                      <div className="admin-detailed-software-supporting-text10">
                                        Awaiting verification by Gerätor
                                      </div>
                                    </>
                                  }

                                  {/* <div className="admin-detailed-software-navigation">
                                    <img
                                      className="admin-detailed-software-bookmark-check-icon"
                                      alt=""
                                      src={bookmarkcheck}
                                    />
                                  </div>
                                  <div className="admin-detailed-software-supporting-text10">
                                    Verified by Gerätor
                                  </div> */}
                                </div>
                              </div>
                              <div className="admin-detailed-software-supporting-text11">
                                {softwareData.data.software_business.businessVerification === 1 &&
                                  <>
                                    {softwareData.data.software_business.businessName} conforms to Gerätor's requirements for
                                    verification

                                  </>
                                }
                              </div>
                            </div>
                            {/* <div className="admin-detailed-software-favorite-button">
                              <div className="admin-detailed-software-button-container2">
                                {!softwareData.data.isAuthor && (
                                  <div className="detailed-device-buttonsbutton5" onClick={handlePrivateMessage}>
                                    <img
                                      className="detailed-device-filter-lines-icon"
                                      alt=""
                                      src={messagechatcircle}
                                    />
                                    <div className="detailed-device-text30">Private Message</div>
                                  </div>
                                )}
                                
                                {!softwareData.data.isAuthor && (
                                  (softwareData.data.addedToContact) ?
                                    <div className="admin-detailed-software-buttonsbutton6" onClick={() => handleRemoveContact(softwareData.data.software_user.id)} style={{ cursor: 'pointer' }}>
                                      <img
                                        className="admin-detailed-software-search-lg-icon"
                                        alt=""
                                        src={usercheck02}
                                      />
                                    </div>
                                    :
                                    <div className="admin-detailed-software-buttonsbutton6-add" onClick={() => handleAddContact(softwareData.data.software_user.id)} style={{ cursor: 'pointer' }}>
                                      <img
                                        className="admin-detailed-software-search-lg-icon"
                                        alt=""
                                        src={add_contact}
                                      />
                                    </div>
                                )}
                             
                              </div>
                            </div> */}
                          </div>
                          <div className="admin-detailed-software-dropdown-menu" style={{ cursor: "pointer" }} onClick={toggleThreeDotsDropdownUser} ref={threeDotsRefUser}>
                            <div className="admin-detailed-software-dropdown1">
                              <img
                                className="admin-detailed-software-maximize-01-icon"
                                alt=""
                                src={dotsvertical}
                              />
                            </div>

                            {isThreeDotUserOpen && <ThreeDotsDetailPageUserComponent onLaunchReportAbuseClick={handleLaunchReportAbuseClick} />}

                          </div>
                        </div>
                        {isReportAbuseOpen &&
                          createPortal(
                            <div ref={reportAbuseRef}  >
                              <ReportAbuseSlideOut onClose={handleCloseReportAbuse} />
                            </div>,
                            document.body
                          )}
                      </div>
                    </div>
                    {!softwareData.data.totalActiveEngagement &&
                      <div className="admin-detailed-software-rectangle-parent4">
                        <div className="admin-detailed-software-testimonial-header">
                          <div className="admin-detailed-software-header-content1">
                            {(softwareData.data.tax_post_status.id === 2 || softwareData.data.tax_post_status.id === 3) &&
                              <button className="admin-detailed-page-button11" type="button" onClick={handleSetPublish} >
                                <div className="admin-detailed-page-text-padding8">
                                  <div className="admin-detailed-page-text76">Publish</div>
                                </div>

                              </button>
                            }

                            {softwareData.data.tax_post_status.id === 1 &&
                              <button className="admin-detailed-page-button12" type="button" onClick={handleSetArchive} >


                                <div className="admin-detailed-page-text-padding8">
                                  <div className="admin-detailed-page-text76-a">Archive</div>
                                </div>

                              </button>
                            }
                          </div>
                        </div>

                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="admin-detailed-software-listing-image-grid">
              <div className="admin-detailed-software-paragraph37">
                One of the best offers I found in the market
              </div>
            </div>
            <div className="admin-detailed-software-listing-image-grid1">
              <div className="admin-detailed-software-paragraph35">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy text
                ever since the 1500s, when an unknown printer took a galley of type
                and scrambled it to make a type specimen book.
              </div>
            </div> */}
            {/* 
            <div className="admin-detailed-software-client-feedback-parent">
              <div className="admin-detailed-software-client-feedback">
                <div className="admin-detailed-software-feedback-content">
                  <img
                    className="admin-detailed-software-feedback-content-child"
                    alt=""
                    src={group_78_2x}
                  />

                  <div className="admin-detailed-software-feedback-header">
                    <div className="admin-detailed-software-header-info">
                      <div className="admin-detailed-software-heading20">Chloe Tammy</div>
                      <div className="admin-detailed-software-heading21">
                        <span>Marketing Communications at</span>
                        <span className="admin-detailed-software-alvo-medical"> Alvo Medical</span>
                      </div>
                    </div>
                  </div>
                  <div className="admin-detailed-software-feedback-rating">
                    <div className="admin-detailed-software-buttonsbutton7">
                        <img
                          className="admin-detailed-software-filter-lines-icon"
                          alt=""
                          src={messagedotscircle}
                        />

                        <div className="admin-detailed-software-text35">Reply</div>
                      </div>
                    <div className="admin-detailed-software-feedback-score">
                      <div className="admin-detailed-software-stars-parent">
                        <div className="admin-detailed-software-stars">
                          <img
                            className="admin-detailed-software-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="admin-detailed-software-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="admin-detailed-software-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="admin-detailed-software-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="admin-detailed-software-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />
                        </div>
                        <div className="admin-detailed-software-rating-value">
                          <div className="admin-detailed-software-heading22">1 month ago</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="admin-detailed-software-client-message">
                <div className="admin-detailed-software-message-content">
                  <div className="admin-detailed-software-paragraph39">
                    One of the best offers I found in the market
                  </div>
                  <div className="admin-detailed-software-paragraph26">
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the industry's standard dummy text
                    ever since the 1500s, when an unknown printer took a galley of
                    type and scrambled it to make a type specimen book.
                  </div>
                </div>
              </div>
              <div className="admin-detailed-software-view-replies">
                <div className="admin-detailed-software-buttonsbutton8" onClick={handleReplyClick}>
                  <img
                    className="admin-detailed-software-filter-lines-icon"
                    alt=""
                    src={messagedotscircle}
                  />

                  <div className="admin-detailed-software-text35">Reply</div>
                </div>
              </div>

              {showReply && (
                <div className="admin-detailed-software-reply-section">
                  <textarea
                    className="admin-detailed-software-reply-textarea"
                    value={replyText}
                    onChange={handleChange}
                    placeholder="Type your reply..."
                  />

                  <div className={`detailed-software-char-counter ${warningClass}`}>
                    {maxLength - replyText.length} characters left
                  </div>

                  <button className="admin-detailed-software-reply-submit-btn" onClick={handleSubmit}>
                    Submit Reply
                  </button>
                </div>
              )}
            </div>
            <div className="admin-detailed-software-pagination-wrapper">
              <div className="admin-detailed-software-pagination">
                <div className="admin-detailed-software-button-wrap">
                  <div className="admin-detailed-software-buttonsbutton9">
                    <img
                      className="admin-detailed-software-placeholder-icon4"
                      alt=""
                      src={placeholder1}
                    />

                    <div className="admin-detailed-software-text-padding">
                      <div className="admin-detailed-software-page-number">Previous</div>
                    </div>
                    <img
                      className="admin-detailed-software-placeholder-icon4"
                      alt=""
                      src={placeholder1}
                    />
                  </div>
                </div>
                <div className="admin-detailed-software-details">1 of 10</div>
                <div className="admin-detailed-software-button-wrap1">
                  <div className="admin-detailed-software-buttonsbutton10">
                    <img
                      className="admin-detailed-software-placeholder-icon4"
                      alt=""
                      src={placeholder1}
                    />

                    <div className="admin-detailed-software-text-padding">
                      <div className="admin-detailed-software-text37">Next</div>
                    </div>
                    <img
                      className="admin-detailed-software-placeholder-icon4"
                      alt=""
                      src={placeholder1}
                    />
                  </div>
                </div>
              </div>
            </div> */}


            <ReviewSoftwareView isAdmin={true} listingId={id} currentUsermeta={currentUsermeta} />

            <div className="admin-detailed-software-listing-image-grid1">
              <img className="admin-detailed-software-divider-icon3" alt="" src={divider1} />
            </div>

            {/* Review Add Form component */}
            {/* {(!softwareData.data.isAuthor && (!softwareData.data.isReviewed) && <AddReviewSoftwareForm listingId={id} currentUsermeta={currentUsermeta} formikRef={formikRef} />)
            } */}
            {/* <div className="admin-detailed-software-review-form-wrapper">
              <div className="admin-detailed-software-review-form">
                <div className="admin-detailed-software-review-form-child"></div>
                <div className="admin-detailed-software-breadcrumb-container-parent">
                  <div className="admin-detailed-software-heading32">Write Review</div>

                  <RatingStarComponent />
                  <ReviewDescriptionComponent />
                  <div className="admin-detailed-software-frame-parent3">
                    <div className="admin-detailed-software-heading-container">
                      <div className="admin-detailed-software-heading33">Accuracy</div>
                      <div className="admin-detailed-software-listing-detail-item-date-parent">
                        <img
                          className="admin-detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="admin-detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="admin-detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="admin-detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="admin-detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />
                      </div>
                    </div>
                    <div className="admin-detailed-software-heading-container">
                      <div className="admin-detailed-software-heading33">Relevance</div>
                      <div className="admin-detailed-software-listing-detail-item-date-parent">
                        <img
                          className="admin-detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="admin-detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="admin-detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="admin-detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="admin-detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />
                      </div>
                    </div>
                    <div className="admin-detailed-software-heading-container">
                      <div className="admin-detailed-software-heading35">Value for Money</div>
                      <div className="admin-detailed-software-listing-detail-item-date-parent">
                        <img
                          className="admin-detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="admin-detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="admin-detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="admin-detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="admin-detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="admin-detailed-software-input-field">
                    <div className="admin-detailed-software-input-with-label1">
                      <div className="admin-detailed-software-label2">Title</div>
                      <div className="admin-detailed-software-content6">
                        <div className="admin-detailed-software-textarea-input-field">
                          <div className="admin-detailed-software-input-with-label3">
                            <div className="admin-detailed-software-label3">Description</div>
                            <div className="admin-detailed-software-input2">
                              <div className="admin-detailed-software-text38">70 characters</div>
                            </div>
                          </div>
                          <div className="admin-detailed-software-hint-text1">10 characters left</div>
                        </div>
                      </div>
                    </div>
                    <div className="admin-detailed-software-hint-text">This is a hint text to help user.</div>
                  </div>
                  <div className="admin-detailed-software-input-field1">
                    <div className="admin-detailed-software-input-with-label1">
                      <div className="admin-detailed-software-label4">Review</div>
                      <div className="admin-detailed-software-content6">
                        <div className="admin-detailed-software-textarea-input-field1">
                          <div className="admin-detailed-software-input-with-label3">
                            <div className="admin-detailed-software-label3">Description</div>
                            <div className="admin-detailed-software-input3">
                              <div className="admin-detailed-software-text39">1000 characters</div>
                            </div>
                          </div>
                          <div className="admin-detailed-software-hint-text3">40 characters left</div>
                        </div>
                      </div>
                    </div>
                    <div className="admin-detailed-software-hint-text">This is a hint text to help user.</div>
                  </div>
                </div>
                <div className="admin-detailed-software-submit-button-wrapper">
                  <div className="admin-detailed-software-button1-submit">
                    <img
                      className="admin-detailed-software-chevron-down-icon"
                      alt=""
                      src={placeholder2}
                    />

                    <img className="admin-detailed-software-log-in-04-icon" alt="" src={login04} />

                    <div className="admin-detailed-software-navigation">
                      <div className="admin-detailed-software-text40">Submit</div>
                    </div>
                    <img
                      className="admin-detailed-software-chevron-down-icon"
                      alt=""
                      src={placeholder2}
                    />
                  </div>
                </div>
              </div>
            </div> */}


            {/* <img className="admin-detailed-software-divider-icon24" alt="" src={divider5} /> */}

            {/* <div className="admin-detailed-software-actions1">
          <div className="admin-detailed-software-button2">
            <img
              className="admin-detailed-software-placeholder-icon17"
              alt=""
              src="/detailed_listing/placeholder3.svg"
            />
  
            <div className="admin-detailed-software-text-padding4">
              <div className="admin-detailed-software-text98">Tertiary</div>
            </div>
            <img
              className="admin-detailed-software-placeholder-icon17"
              alt=""
              src="/detailed_listing/placeholder3.svg"
            />
          </div>
          <div className="admin-detailed-software-button3">
            <img
              className="admin-detailed-software-placeholder-icon17"
              alt=""
              src="/detailed_listing/placeholder4.svg"
            />
  
            <div className="admin-detailed-software-text-padding5">
              <div className="admin-detailed-software-text98">Secondary</div>
            </div>
            <img
              className="admin-detailed-software-placeholder-icon17"
              alt=""
              src="/detailed_listing/placeholder4.svg"
            />
          </div>
          <div className="admin-detailed-software-button4">
            <img
              className="admin-detailed-software-placeholder-icon17"
              alt=""
              src={placeholder1}
            />
  
            <div className="admin-detailed-software-text-padding5">
              <div className="admin-detailed-software-text98">Secondary</div>
            </div>
            <img
              className="admin-detailed-software-placeholder-icon17"
              alt=""
              src={placeholder1}
            />
          </div>
          <div className="admin-detailed-software-button5">
            <img
              className="admin-detailed-software-placeholder-icon17"
              alt=""
              src={placeholder2}
            />
  
            <div className="admin-detailed-software-text-padding7">
              <div className="admin-detailed-software-text98">Primary</div>
            </div>
            <img
              className="admin-detailed-software-placeholder-icon17"
              alt=""
              src={placeholder2}
            />
          </div>
        </div> */}

            <img className="detail-page-divider-icon6" alt="" src={divider3} />
            <FooterDetailPage />
          </div>
        </div>
      </>
    )
  }
  else {
    return <Loader />;
  }

}

