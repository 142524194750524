import React from 'react';
import "./NoAccessFormUpgradePlan.css";
import bifocallens from '../../icons/Gerator/no_listing/bifocallens.png';
import placeholder from '../../icons/Gerator/no_listing/placeholder.svg';
import placeholder1 from '../../icons/Gerator/no_listing/placeholder1.svg';
import plus from '../../icons/Gerator/no_listing/plus.svg';
import { Link } from "react-router-dom";
import NoAccess from "../../icons/Gerator/business_page/NoAccess.png";

export const NoAccessExpirePlan = ({ path, pathName }) => {
  return (
    <div className="no-access-upgrade-plan-no-access-address">
      <section className="no-access-upgrade-plan-empty-state">
        <div className="no-access-upgrade-plan-empty-state1">
          <div className="no-access-upgrade-plan-content">
            <img
              className="no-access-upgrade-plan-bifocal-optometry-measurement-icon"
              loading="lazy"
              alt=""
              src={NoAccess}
            />

            <div className="no-access-upgrade-plan-text-and-supporting-text">
              <div className="no-access-upgrade-plan-title">Take a pause to fix these issues</div>
              <div className="no-access-upgrade-plan-supporting-text">
                Your plan is expired please upgrade plan to add more listings.
              </div>
            </div>
          </div>
          <div className="no-access-upgrade-plan-actions">
            <Link to={path}>
              <button className="no-access-upgrade-plan-buttonsbutton">
                {/* <img
                  className="no-access-upgrade-plan-placeholder-icon"
                  alt=""
                  src={placeholder}
                /> */}

                <div className="no-access-upgrade-plan-text-padding">
                  <div className="no-access-upgrade-plan-text">{pathName}</div>
                </div>
                {/* <img
                  className="no-access-upgrade-plan-placeholder-icon"
                  alt=""
                  src={placeholder}
                /> */}
              </button>
            </Link>

            {/* <img className="no-access-upgrade-plan-plus-icon" alt="" src={plus} /> */}

            {/* <button className="no-access-upgrade-plan-buttonsbutton1"  >
              <div className='no-access-upgrade-plan-button-link'>

                <div className="no-access-upgrade-plan-text-padding">
                  <div className="no-access-upgrade-plan-text1">Request Team Access</div>
                </div>
                <img
                  className="no-access-upgrade-plan-placeholder-icon"
                  alt=""
                  src={placeholder1}
                />
              </div>
            </button> */}
          </div>


        </div>
      </section>
    </div>
  )
}
