import React, { useState } from 'react';
import "./AddJob.css"
import { Navbar1 } from '../../../components/Navbar1/Navbar1'
import { FooterAddForms } from '../../../components/Footer/FooterAddForms/FooterAddForms';
import { HeaderForms } from '../../../components/Header/HeaderForms/HeaderForms';
import { AddJobStep1 } from '../../../components/AddJobFormComponent/AddJobStep1';
import { AddJobStep2 } from '../../../components/AddJobFormComponent/AddJobStep2';
// import { AddJobStep3 } from '../../../components/AddJobFormComponent/AddJobStep3';
import divider from '../../../icons/Gerator/add_form/navbar_divider/divider.svg';
import divider_light from '../../../icons/Gerator/divider.svg';
import { Sidebar } from '../../../components/Sidebar/Sidebar';
import { SideBarSubProgressStep1 } from '../../../components/SideBars-Sub/SideBarSubAddForm/SideBarSubProgressStep1';
import { SideBarSubProgressStep2 } from '../../../components/SideBars-Sub/SideBarSubAddForm/SideBarSubProgressStep2';
import AddJobParent from '../../../components/AddJobFormComponent/AddJobParent';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { firestore, doc, getDoc, auth, onAuthStateChanged } from '../../Login/FirebaseClient';
import { Loader } from '../../../components/Loader';
import { useLazyGetProfileImageLinkQuery, useLazyGetUserProfileQuery, useLazyGetUserQuery } from '../../../api/Login&RegisterAPI';
import { useEffect } from 'react';
import { useRef } from 'react';
import { decryptErrorData } from '../../../helper/AESHelper';
import { ErrorShowComponent } from '../../../components/ErrorShowComponent/ErrorShowComponent';
import { useLazyFindGetMyBusinessQuery, useLazyFindIsUserHasBusinessQuery, useLazyGetBusinessAllListingCountQuery } from '../../../api/BusinessAPI';
import { NoBusinessFound } from '../../../components/NoBusiness/NoBusiness';
import { useGetPlansForAddressQuery } from '../../../api/PlanAPI';
import { NoAccessFormUpgradePlan } from '../../../components/NoAccessFormUpgradePlan/NoAccessFormUpgradePlan';
import { Helmet } from 'react-helmet-async';
import { NoAccessExpirePlan } from '../../../components/NoAccessFormUpgradePlan/NoAccessExpirePlan';
import { NoAccessRequestedMember } from '../../../components/NoAccessFormUpgradePlan/NoAccessRequestedMember';
import { NoAccessRevokedMember } from '../../../components/NoAccessFormUpgradePlan/NoAccessRevokedMember';
import { isPlanExpired } from '../../../helper/UserHelper';
import { NoBusinessPublished } from '../../../components/NoBusinessPublished/NoBusinessPublished';
// const renderFormStep = (step, setStep) => {
//   switch (step) {
//     case 0:
//       return <AddJobStep1 setStep={setStep} />;
//     case 1:
//       return <AddJobStep2 setStep={setStep} />;
//     // case 2:
//     //   return <AddJobStep3 setStep={setStep}/>;
//     default:z
//       return null;
//   }
// };

const renderProgressStep = (step, userNameEmail) => {
  switch (step) {
    case 0:
      return <SideBarSubProgressStep1 userData={userNameEmail} />;
    case 1:
      return <SideBarSubProgressStep2 userData={userNameEmail} />;
    // case 2:
    //   return <SideBarSubProgressStep3 />;
    default:
      return null;
  }
};


export const AddJob = () => {

  let content = "";
  const appData = useSelector((state) => state.appDataAddJob);
  // Progress var when this is true means all taxonomy loaded
  const [isTaxonomySet, setIsTaxonomySet] = useState(false);
  // const [triggerGetUser, { data, isSuccess, error, isLoading, isError }] = useLazyGetUserQuery();
  const [currentUser, setCurrentUser] = useState("");
  const navigate = useNavigate();

  const [showSuccessMessage, setShowSuccessMessage] = useState({
    message: "",
    color: "",
  });

  const [currentPlanConditionData, setCurrentPlanConditionData] = useState("")
  const [profileImageLink, setProfileImageLink] = useState("");
  const [currentPlanAllowedListing, setCurrentPlanAllowedListing] = useState("");
  const [currentRemainingListing, setCurrentRemainingListing] = useState("");
  const [currentUsermeta, setCurrentUsermeta] = useState("");
  const [triggerNewProfileImageLink] = useLazyGetProfileImageLinkQuery();


  const [triggerGetBusiness, { data, isSuccess, error, isLoading, isError }] = useLazyFindIsUserHasBusinessQuery();

  const { data: planConditionData, isSuccess: isPlanConditionSuccess, error: planConditionError, isLoading: isPlanConditionLoading, isError: isPlanConditionError } = useGetPlansForAddressQuery();

  const [triggerGetListingCount, { data: listingCountData, isError: isListingCountError }] = useLazyGetBusinessAllListingCountQuery();


  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {

        if (user) {
          // Get user to check wizardStep
          const userDocRef = doc(firestore, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);
          setCurrentUser(user);

          if (userDocSnapshot.exists()) {
            const usermeta = userDocSnapshot.data(); // Default to 1 if not set

            if (usermeta.userStatus == 2) {
              navigate('/account-disabled');
              return;
            }

            if (usermeta.userType == 5) {
              navigate('/access-denied');
              return;
            }

            if (usermeta.wizardStep !== 0) {
              navigate('/account-setup-check-email');
              return;
            }

            // await triggerGetCurrentPlan().unwrap();
            setCurrentUsermeta(usermeta);
            // triggerGetBusiness();
            triggerGetListingCount();
            // User is authenticated
          } else {
            content = <ErrorShowComponent message1={"Internal server error"} />
          }
        }
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });


    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);

  //Profile image load useEffect
  useEffect(() => {
    const refreshProfileImage = async () => {
      if (currentUsermeta && currentUsermeta.profileImage.expiresAt < Date.now() / 1000) {
        // Pre-signed URL has expired, trigger a new request to the backend
        const newImageData = await triggerNewProfileImageLink().unwrap();
        // Update the profile image link directly
        setProfileImageLink(newImageData.url);
      } else if (currentUsermeta && currentUsermeta.profileImage.url) {
        // Use the existing pre-signed URL to display the profile image
        setProfileImageLink(currentUsermeta.profileImage.url);
      }
    };

    refreshProfileImage();
  }, [currentUsermeta]);


  //Load plan data to restrict page access
  useEffect(() => {
    if (planConditionData && isPlanConditionSuccess && currentUsermeta) {
      console.log(planConditionData);
      const foundObject = planConditionData.data.find(item => item.id === currentUsermeta.userPlan);
      setCurrentPlanAllowedListing(foundObject.planAllowedListing);
      setCurrentPlanConditionData(foundObject);
    }

  }, [isPlanConditionSuccess, planConditionData, currentUsermeta]);


  // Set remaining listing useState to set condition 
  useEffect(() => {
    if (!listingCountData || !currentPlanAllowedListing) {
      return;
    }

    const remainingListingOfCurrentPlan = currentPlanAllowedListing - listingCountData.data;
    console.log(remainingListingOfCurrentPlan);
    setCurrentRemainingListing(remainingListingOfCurrentPlan);

  }, [listingCountData, currentPlanAllowedListing]);

  // if (isError || isListingCountError) {
  //   console.log(error);
  //   content = <ErrorShowComponent message1={error.data.message} />
  // }

  // if (isSuccess) {
  //   console.log(data);
  //   if (data.status && currentRemainingListing) {
  //     content = <>
  //       <AddJobParent setShowSuccessMessage={setShowSuccessMessage} setIsTaxonomySet={setIsTaxonomySet} currentPlanData={currentPlanConditionData} />
  //     </>
  //   } else if (data.status && !currentRemainingListing) {
  //     content = <NoAccessFormUpgradePlan path={'/dashboard/settings/plan'} pathName={"Upgrade"} />
  //   }
  //   else {
  //     content = <NoBusinessFound path={'/dashboard/settings/business/add-business'} pathName={"Business"} />
  //   }

  // }

  if (currentUsermeta.businessPostStatus === 2){
      content = <NoBusinessPublished path={'/dashboard/settings/business'} />
    } else {

      // User Type is Normal
      if (currentUsermeta.userType === 1) {
        content = <NoBusinessFound path={'/dashboard/settings/business/add-business'} pathName={"Business"} />
      }
    
      // User Type is Normal but requested for team member
      if (currentUsermeta.userType === 1 && currentUsermeta?.isMemberRequested) {
        content = <NoAccessRequestedMember path={'/dashboard/settings/business'} pathName={"Request Status"} />
      }
    
      // User type is Owner
      if (currentUsermeta.userType === 2) {
    
        // If user plan is 1 then dont check for plan expiry and business verification
        if (currentUsermeta.userPlan === 1) {
    
          if (currentRemainingListing > 0) {
            content = <>
              <AddJobParent setShowSuccessMessage={setShowSuccessMessage} currentUsermeta={currentUsermeta} currentPlanData={currentPlanConditionData} />
            </>
    
          } else {
    
            content = <NoAccessFormUpgradePlan path={'/dashboard/settings/plan'} pathName={"Upgrade"} />
          }
    
    
        } else {
    
          //Plan is greater than 1 then Checking plan is expired or not
          if (isPlanExpired(currentUsermeta.userPlanExpiry)) {
    
            content = <NoAccessExpirePlan path={'/dashboard/settings/plan'} pathName={"Upgrade"} />
    
          } else {
    
            if (currentRemainingListing > 0) {
              content = <>
                <AddJobParent setShowSuccessMessage={setShowSuccessMessage} currentUsermeta={currentUsermeta} currentPlanData={currentPlanConditionData} />
              </>
    
            } else {
    
              content = <NoAccessFormUpgradePlan path={'/dashboard/settings/plan'} pathName={"Upgrade"} />
            }
          }
    
        }
    
      }
    
      
      if (currentUsermeta.userType === 3) {
    
        //Check plan is expired or not
        if (isPlanExpired(currentUsermeta.userPlanExpiry)) {
    
          content = <NoAccessExpirePlan path={'/dashboard/settings/plan'} pathName={"Upgrade"} />
    
        } else {
          // Check if team member is active or not
          if (currentUsermeta.isMemberActive) {
            //Current user remaing listing quota is greater than 0 then allow to add listing
            if (currentRemainingListing > 0) {
              content = <>
                <AddJobParent setShowSuccessMessage={setShowSuccessMessage} currentUsermeta={currentUsermeta} currentPlanData={currentPlanConditionData} />
              </>
    
            } else {
    
              content = <NoAccessFormUpgradePlan path={'/dashboard/settings/plan'} pathName={"Upgrade"} />
            }
          } else if (!currentUsermeta.isMemberInvited) {
            //Checked if invite was revoked 
            content = <NoAccessRevokedMember />
          } else if (currentUsermeta.isMemberRequested) {
            content = <NoAccessRequestedMember path={'/dashboard/settings/business'} pathName={"Request Status"} />
          } else {
            // Account is not Active
            content = <NoAccessInactiveMember />
          }
        }
      }
    
    
      // User Type is Co-Owner Member
      if (currentUsermeta.userType === 4) {
    
        //Check plan is expired or not
        if (isPlanExpired(currentUsermeta.userPlanExpiry)) {
    
          content = <NoAccessExpirePlan path={'/dashboard/settings/plan'} pathName={"Upgrade"} />
    
        } else {
          // Check if team member is active or not
          if (currentUsermeta.isMemberActive) {
            //Current user remaing listing quota is greater than 0 then allow to add listing
            if (currentRemainingListing > 0) {
              content = <>
                <AddJobParent setShowSuccessMessage={setShowSuccessMessage} currentUsermeta={currentUsermeta} currentPlanData={currentPlanConditionData} />
              </>
    
            } else {
    
              content = <NoAccessFormUpgradePlan path={'/dashboard/settings/plan'} pathName={"Upgrade"} />
            }
          } else if (!currentUsermeta.isMemberInvited) {
            //Checked if invite was revoked 
            content = <NoAccessRevokedMember />
          } else {
            // Account is not Active
            content = <NoAccessInactiveMember />
          }
        }
      }

    }




  if (content !== "" && profileImageLink) {
    return (

      <>
        <Helmet>
          <title>Add Job</title>
        </Helmet>
        <div className="add-job-forms-add-job add-job-forms-add-job-custom-margin">
          <div className="add-job-forms-add-job-child"></div>


          <Navbar1 userData={currentUser} navigate={navigate} profileImageLink={profileImageLink} />

          <img className="divider-icon" alt="" src={divider_light} />


          <main className="add-job-content-parent">

            <Sidebar profileImageLink={profileImageLink}>{renderProgressStep(appData.currentStep, { displayName: currentUser.displayName, email: currentUser.email })}</Sidebar>


            <section className="add-job-bottom-actions">

              <div className="add-job-progress-container-parent">
                <div className="add-job-progress-container">
                  {/* <div className="add-job-progress-steps">
                  <div className="add-job-form-parent">
                   
    
    
    
                  </div>
                </div> */}
                  {/* {renderProgressStep(step)} */}
                  {/* <ProgressStep1/> */}
                  {/* <ProgressStep2/> */}
                  {/* <ProgressStep3/> */}
                  <div className="add-job-content5">
                    <div className="add-job-main">


                      <HeaderForms text="Add Job " text1="Add Job Listing" text2="Your first step to realizing asset value." />




                      <div className="add-job-main-child"></div>
                      <div className="add-job-main-item"></div>
                    </div>
                    <div className="add-job-body">

                      {content}

                    </div>
                    {showSuccessMessage.message && (
                      <div
                        style={{
                          fontSize: "14px",
                          color: showSuccessMessage.color,
                          padding: "15px 10px",
                          border: "1px solid " + showSuccessMessage.color,
                          borderRadius: "10px",
                          margin: "0 auto",
                        }}
                      >
                        {showSuccessMessage.message}
                      </div>
                    )}
                  </div>
                </div>

              </div>
            </section>
          </main>
          <img
            className="add-job-divider-icon10"
            loading="lazy"
            alt=""
            src={divider_light}
          />

          <FooterAddForms />
        </div>
      </>
    )
  } else {
    return <Loader />
  }


}


 // User type is Team Member
  // if (currentUsermeta.userType === 3) {

  //   //Check plan is expired or not
  //   if (isPlanExpired(currentUsermeta.userPlanExpiry)) {

  //     content = <NoAccessExpirePlan path={'/dashboard/settings/plan'} pathName={"Upgrade"} />

  //   } else {
  //     // Check if team member is active or not
  //     if (currentUsermeta.isMemberActive) {
  //       // Business is Verified or not
  //       if (currentUsermeta.businessVerification === 1) {
  //         //Current user remaing listing quota is greater than 0 then allow to add listing
  //         if (currentRemainingListing > 0) {
  //           content = <>
  //             <AddJobParent setShowSuccessMessage={setShowSuccessMessage} currentUsermeta={currentUsermeta} currentPlanData={currentPlanConditionData} />
  //           </>

  //         } else {

  //           content = <NoAccessFormUpgradePlan path={'/dashboard/settings/plan'} pathName={"Upgrade"} />
  //         }

  //       } else {

  //         content = <ErrorShowComponent message1={"Business is not verified"} message2={'Please wait! while we verify business'} />

  //       }
  //     } else if (!currentUsermeta.isMemberInvited) {
  //       //Checked if invite was revoked 
  //       content = <NoAccessRevokedMember />
  //     } else if (currentUsermeta.isMemberRequested) {
  //       content = <NoAccessRequestedMember path={'/dashboard/settings/business'} pathName={"Request Status"} />
  //     } else {
  //       // Account is not Active
  //       content = <NoAccessInactiveMember />
  //     }
  //   }
  // }


