import React from 'react';
import "./NoAccessFormUpgradePlan.css";
import bifocallens from '../../icons/Gerator/no_listing/bifocallens.png';
import placeholder from '../../icons/Gerator/no_listing/placeholder.svg';
import placeholder1 from '../../icons/Gerator/no_listing/placeholder1.svg';
import plus from '../../icons/Gerator/no_listing/plus.svg';
import { Link } from "react-router-dom";
import NoAccess from "../../icons/Gerator/business_page/NoAccess.png";

export const NoAccessInactiveMember = ({ path, pathName }) => {
  return (
    <div className="no-access-upgrade-plan-no-access-address">
      <section className="no-access-upgrade-plan-empty-state">
        <div className="no-access-upgrade-plan-empty-state1">
          <div className="no-access-upgrade-plan-content">
            <img
              className="no-access-upgrade-plan-bifocal-optometry-measurement-icon"
              loading="lazy"
              alt=""
              src={NoAccess}
            />

            <div className="no-access-upgrade-plan-text-and-supporting-text">
              <div className="no-access-upgrade-plan-title">Your team member access is disabled.</div>
              <div className="no-access-upgrade-plan-supporting-text">
                Please contact to your business owner to make account active.
              </div>
            </div>
          </div>
          {/* <div className="no-access-upgrade-plan-actions">
            <Link to={path}>
              <button className="no-access-upgrade-plan-buttonsbutton">

                <div className="no-access-upgrade-plan-text-padding">
                  <div className="no-access-upgrade-plan-text">{pathName}</div>
                </div>
              </button>
            </Link>

          </div> */}


        </div>
      </section>
    </div>
  )
}
