import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  deviceCategory: '',
  oem: '',
  modelName: '',
  statusCondition: '',
  ageOfDevice: '',
  availability: '',
  clinicalApplications: '',
  purposeUse: '',
  physicalLocation: '',
}

const adminEditWantedDeviceSlice = createSlice({
  name: "adminEditWantedDevice",
  initialState,
  reducers: {
    fillAdminEditWantedDeviceForm(state, action) {
      return action.payload;
    },
    updateAdminEditWantedDeviceField(state, action) {
      const { field, value } = action.payload
      if (value !== undefined) {
        state[field] = value;
      }
    },
    clearAdminEditWantedDeviceForm() {
      return initialState;
    }
  },
});

export const { fillAdminEditWantedDeviceForm, updateAdminEditWantedDeviceField, clearAdminEditWantedDeviceForm } = adminEditWantedDeviceSlice.actions

export default adminEditWantedDeviceSlice.reducer

