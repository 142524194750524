// import React, { useEffect } from "react";

// import "../ChatPageComponent.css"; // Correct relative path

// import arrowright from "../../../icons/Gerator/chat_page/arrowright.svg";
// import dot2 from "../../../icons/Gerator/chat_page/-dot2.svg";
// import facesmile from "../../../icons/Gerator/chat_page/facesmile.svg";
// import arrowleft from "../../../icons/Gerator/chat_page/arrowleft.svg";
// import avatar2 from "../../../icons/Gerator/chat_page/avatar2.svg";
// import dotsvertical from "../../../icons/Gerator/chat_page/dotsvertical.svg";
// import divider2 from "../../../icons/Gerator/chat_page/divider2.svg";
// import divider3 from "../../../icons/Gerator/chat_page/divider-3.svg";
// import dotshorizontal from "../../../icons/Gerator/chat_page/dotshorizontal.svg";
// import uploadcloud02 from "../../../icons/Gerator/chat_page/uploadcloud02.svg";
// import videorecorder from "../../../icons/Gerator/chat_page/videorecorder.svg";
// import phonecall01 from "../../../icons/Gerator/chat_page/phonecall01.svg";
// import filterlines from "../../../icons/Gerator/chat_page/filterlines.svg";
// import placeholder1 from "../../../icons/Gerator/chat_page/placeholder1.svg";
// import placeholder2 from "../../../icons/Gerator/chat_page/placeholder2.svg";
// import placeholder3 from "../../../icons/Gerator/chat_page/placeholder3.svg";
// import placeholder from "../../../icons/Gerator/chat_page/placeholder.svg";
// import pageIcon from "../../../icons/Gerator/chat_page/page.svg";

// import searchlg from "../../../icons/Gerator/chat_page/searchlg.svg";

// const CardHeader = ({deviceData}) => {
//   useEffect(() => {
//     if (deviceData) {

//       console.log('YES DEVICE SUCCESS in CARDHEADER', deviceData);

//       //.log('YES CHATPAGEB', deviceData.data.profileImage);
//     }
//   }, [deviceData]);


//   return (


//         <div className="chatB-card-header1">
//           <div className="chatB-content7">
//             <div className="chatB-avatar-and-text">
//               {/* <img className="chatB-avatar-icon" loading="lazy" alt="" src={deviceData?.profileImage} /> */}
//               {/* {deviceData?.data?.profileImage ?

// <img
//   className="chatB-avatar-icon"
//   loading="lazy" 
//   alt=""
//   src={deviceData?.data?.profileImage}
// />
// :
// <img
//   className="chatB-avatar-icon"
//   alt=""
//   src={avatar2}
// />

// } */}

// {deviceData?.data?.profileImage ?

// <img
//   className="chatB-avatar-icon"
//   alt=""
//   src={deviceData?.data?.profileImage}
// />
// :
// <img
//   className="chatB-avatar-icon"
//   alt=""
//   src={avatar2}
// />

// }
//               <div className="chatB-text-and-supporting-text8">
//                 <div className="chatB-text-and-badge1">
//                   <div className="chatB-caller-name">   {deviceData?.data?.device_business?.business_user?.displayName ||
//                   "Unknown User"}</div>
//                   <div className="chatB-badge11">
//                     <img className="chatB-dot-icon5" alt="" src={dot2}/>
//                     <div className="chatB-text27">Online</div>
//                   </div>
//                 </div>
//                 <div className="chatB-supporting-text17">
//                 {deviceData?.data?.tax_your_role?.name } @{deviceData?.data?.device_business?.businessName}
//                 </div>
//               </div>
//             </div>
//             <div className="chatB-actions1">
//               <div className="chatB-button">
//                 <img className="chatB-placeholder-icon" alt="" src={placeholder} />
//                 <div className="chatB-text-padding">
//                   <div className="chatB-text18">Tertiary</div>
//                 </div>
//                 <img className="chatB-placeholder-icon" alt="" src={placeholder} />
//               </div>
//               <button className="chatB-button5">
//                 <img className="chatB-arrow-up-icon" alt="" src={phonecall01} />
//                 <div className="chatB-text-padding4">
//                   <div className="chatB-text29">Call</div>
//                 </div>
//                 <img className="chatB-placeholder-icon" alt="" src={placeholder1}/>
//               </button>
//               <button className="chatB-button6">
//                 <img className="chatB-placeholder-icon" alt="" src={placeholder3} />
//                 <img className="chatB-arrow-up-icon" alt="" src={videorecorder} />
//                 <div className="chatB-text-padding4">
//                   <div className="chatB-call-button-label">Video</div>
//                 </div>
//                 <img className="chatB-placeholder-icon" alt="" src={placeholder3} />
//               </button>
//               <button className="chatB-button7">
//                 <img className="chatB-placeholder-icon" alt="" src={placeholder2}/>
//                 <img className="chatB-arrow-up-icon" alt="" src={uploadcloud02} />
//                 <div className="chatB-text-padding4">
//                   <div className="chatB-text30">Update Stage</div>
//                 </div>
//                 <img className="chatB-placeholder-icon" alt="" src={placeholder2}/>
//               </button>
//             </div>
//             <div className="chatB-text-and-supporting-text1">
//               <img className="chatB-dots-vertical-icon" alt="" src={dotsvertical} />
//             </div>
//           </div>
//           <img className="chatB-divider-icon5" alt="" src={divider3} />
//         </div>

//   );
// }

// export {CardHeader};

import React, { useEffect, useRef, useState } from "react";

import "../ChatPageComponent.css";

import avatar2 from "../../../icons/Gerator/chat_page/avatar2.svg";
import dot2 from "../../../icons/Gerator/chat_page/-dot2.svg";
import dot1 from "../../../icons/Gerator/chat_page/-dot1.svg";
import stage0 from "../../../icons/Gerator/chat_page/stage0.svg";
import stage1 from "../../../icons/Gerator/chat_page/stage0.svg";
import stage2 from "../../../icons/Gerator/chat_page/stage1.svg";
import stage3 from "../../../icons/Gerator/chat_page/stage2.svg";
import stage4 from "../../../icons/Gerator/chat_page/stage3.svg";
import stage5 from "../../../icons/Gerator/chat_page/stage4.svg";
import stage6 from "../../../icons/Gerator/chat_page/stage5.svg";
import stage7 from "../../../icons/Gerator/chat_page/stage6.svg";
import phonecall01 from "../../../icons/Gerator/chat_page/phonecall01.svg";
import videorecorder from "../../../icons/Gerator/chat_page/videorecorder.svg";
import uploadcloud02 from "../../../icons/Gerator/chat_page/uploadcloud02.svg";
import placeholder from "../../../icons/Gerator/chat_page/placeholder.svg";
import placeholder1 from "../../../icons/Gerator/chat_page/placeholder1.svg";
import placeholder2 from "../../../icons/Gerator/chat_page/placeholder2.svg";
import placeholder3 from "../../../icons/Gerator/chat_page/placeholder3.svg";
import dotsvertical from "../../../icons/Gerator/chat_page/dotsvertical.svg";
import divider3 from "../../../icons/Gerator/chat_page/divider-3.svg";
import { StageSlideOutForm } from "../../StageSlideOutForm/StageSlideOutForm";
import { ThreeDotsChatOwnerComponent } from "../../ThreeDotsListingComponent/ThreeDotsChatOwnerComponent";
import { ReportAbuseSlideOut } from '../../../components/ReportAbuseSlideOutForm/ReportAbuseSlideOutForm';
import { createPortal } from 'react-dom';
import { useLazyFetchUserDetailsForChatHeaderQuery } from "../../../api/ChatAPI";
import { StageSlideOutEditForm } from "../../StageSlideOutForm/StageSlideOutEditForm";

const CardHeader = ({ deviceData, selectedChat }) => {

  const stages = [stage0, stage1, stage2, stage3, stage4, stage5, stage6, stage7];

  const [triggerUsermetaForChatHeader, { isLoading, isSuccess, data: usermeta }] = useLazyFetchUserDetailsForChatHeaderQuery();

  //Store usermeta if not passed in selectedChat
  const [selectChatUserDetails, setSelectChatUserDetails] = useState(null);

  const [isReportAbuseOpen, setIsReportAbuseOpen] = useState(false);
  const reportAbuseRef = useRef(null);


  const handleLaunchReportAbuseClick = () => {

    setIsReportAbuseOpen(true); // Open reportAbuse slide-out
  };


  const handleCloseReportAbuse = () => {
    setIsReportAbuseOpen(false); // Close reportAbuse slide-out
  };

  useEffect(() => {
    if (selectedChat && selectedChat?.senderDetails?.user_usermeta) {

    } else {
      triggerUsermetaForChatHeader(selectedChat.channelName);
    }
  }, [selectedChat]);


  // Close reportAbuse and social share when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (reportAbuseRef.current && !reportAbuseRef.current.contains(event.target)) {
        setIsReportAbuseOpen(false); // Close reportAbuse if clicked outside
      }

    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const [isThreeDotUserOpen, setIsThreeDotUserOpen] = useState(false);
  const threeDotsRefUser = useRef(null);
  const [isClickBlockedUser, setIsClickBlockedUser] = useState(false);

  // Toggle function for the second three dots dropdown
  const toggleThreeDotsDropdownUser = () => {
    if (isClickBlockedUser) return; // Prevent rapid toggle

    setIsThreeDotUserOpen(prev => !prev); // Toggle the dropdown
    setIsClickBlockedUser(true);
    setTimeout(() => setIsClickBlockedUser(false), 400); // Small delay to avoid reopening
  };

  // Handle clicks outside the second three dots dropdown
  useEffect(() => {
    const handleThreeDotClickOutsideUser = (event) => {
      const isLink = event.target.closest('a');
      if (isLink) return; // Allow navigation to happen

      if (threeDotsRefUser.current && !threeDotsRefUser.current.contains(event.target)) {
        setIsThreeDotUserOpen(false); // Close the dropdown when clicking outside
      }
    };

    document.addEventListener("mousedown", handleThreeDotClickOutsideUser);
    return () => {
      document.removeEventListener("mousedown", handleThreeDotClickOutsideUser);
    };
  }, []);



  const [isFormOpen, setIsFormOpen] = useState(false);



  const toggleForm = () => {
    setIsFormOpen(!isFormOpen);
  };

  useEffect(() => {
    if (selectedChat) {
      console.log("selectedChat in CARD HEADER:", selectedChat);
    }
  }, [selectedChat]);

  return (
    <div className="chatB-card-header1">
      <div className="chatB-content7">
        <div className="chatB-avatar-and-text">
          {selectedChat?.profileImage ? (
            <img
              className="chatB-avatar-icon"
              alt="Avatar"
              src={selectedChat.profileImage}
            />
          ) : (
            <img className="chatB-avatar-icon" alt="Default Avatar" src={selectedChat?.senderDetails?.profileImageLink} />
          )}

          <div className="chatB-text-and-supporting-text8">
            <div className="chatB-text-and-badge1">
              <div className="chatB-caller-name">
                {selectedChat?.senderDetails?.display_name || "Unknown User"}
              </div>
              <div className="chatB-badge11">
                {selectedChat.isOnline ?
                  <>
                    <img className="chatB-dot-icon5" alt="" src={dot2} />
                    <div className="chatB-text27">Online</div>
                  </>
                  :
                  <>
                    <img className="chatB-dot-icon5" alt="" src={dot1} />
                    <div className="chatB-text27">Offline</div>
                  </>
                }
              </div>
              {
                selectedChat?.updateStageId &&
                <img alt="" src={stages[selectedChat.updateStageId]} />
              }
            </div>
            <div className="chatB-supporting-text17">
              {usermeta ? usermeta.data.user_work_title : selectedChat?.senderDetails?.user_usermeta?.user_work_title || "User Role"} @
              {usermeta ? usermeta.data.user_business_name : selectedChat?.senderDetails?.user_usermeta?.user_business_name || "Business Name"}
            </div>
          </div>
        </div>

        <div className="chatB-actions1">
          {/* <button className="chatB-button5">
            <img className="chatB-arrow-up-icon" alt="Call" src={phonecall01} />
            <div className="chatB-text-padding4">
              <div className="chatB-text29">Call</div>
            </div>
          </button>
          <button className="chatB-button6">
            <img className="chatB-arrow-up-icon" alt="Video" src={videorecorder} />
            <div className="chatB-text-padding4">
              <div className="chatB-call-button-label">Video</div>
            </div>
          </button> */}
          <button className="chatB-button7" onClick={toggleForm}>
            <img className="chatB-arrow-up-icon" alt="Update" src={uploadcloud02} />
            <div className="chatB-text-padding4">
              <div className="chatB-text30">Update Stage</div>
            </div>
          </button>
          {
            selectedChat?.updateStageId ?
              //when there is already update stage then edit form will open
              isFormOpen && <StageSlideOutEditForm onClose={toggleForm} channelName={selectedChat.channelName} />
              :
              //If no update stage then add update stage form will open
              isFormOpen && <StageSlideOutForm onClose={toggleForm} channelName={selectedChat.channelName} />

          }
        </div>

        <div className="chatB-text-and-supporting-text1" style={{ cursor: "pointer" }} onClick={toggleThreeDotsDropdownUser} ref={threeDotsRefUser}>
          <img className="chatB-dots-vertical-icon" alt="Options" src={dotsvertical} />
          {isThreeDotUserOpen && <ThreeDotsChatOwnerComponent onLaunchReportAbuseClick={handleLaunchReportAbuseClick} />}

        </div>
        {isReportAbuseOpen &&
          createPortal(
            <div ref={reportAbuseRef}  >
              <ReportAbuseSlideOut onClose={handleCloseReportAbuse} />
            </div>,
            document.body
          )}
      </div>
      <img className="chatB-divider-icon5" alt="" src={divider3} />
    </div>
  );
};

export { CardHeader };


