import React, { useEffect, useRef, useState } from 'react';
import "./StageSlideOutForm.css";
import xclose from "../../icons/Gerator/slide_out/xclose.svg";
import help_icon from "../../icons/Gerator/slide_out/help-icon.svg";
import { Formik, useField, Field, ErrorMessage, Form } from 'formik';
// import { Form } from 'react-router-dom';
import * as Yup from "yup";


import funnel from "../../icons/Gerator/dashboard/funnel.svg"
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import dollar from "../../icons/Gerator/add_form/dollar.svg";
import { useChatUpdateStageMutation, useFetchChatUpdateStageQuery, useLazyFetchChatUpdateStageQuery } from '../../api/ChatAPI';
import { ErrorShowComponent } from '../ErrorShowComponent/ErrorShowComponent';
import { Form, Formik } from 'formik';
import { useGetUpdateStagesQuery } from '../../api/TaxonomyFormAPI';
import { decryptErrorData } from '../../helper/AESHelper';
import { formatNumber } from '../../helper/DeviceHelper';
import { objectToUpdateStageData } from '../../helper/ChatHelper';

const MyTextInput = ({ validate, label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  let fieldValidationData = null;
  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  return (
    <>
      <div className="stage-input-field">
        <div className="stage-input-field">
          <label className="stage-label12" htmlFor={props.id || props.name}>{label}</label>
          <div className="stage-input6">
            <img className="stage-help-icon2" alt="" src={dollar} />
            <input
              className="stage-content72"
              {...field}
              {...props}
            />
          </div>
        </div>
        {(meta.touched && meta.error) ? (
          <div className="account-update-profile-hint-text2 error-color">
            {meta.error}
          </div>
        ) : null}

        {fieldValidationData !== null ? (
          <div className="account-update-profile-hint-text2 error-color">
            {fieldValidationData.msg}
          </div>
        ) : null}
      </div>

    </>
  );
};

// Fixed MySelect component
const MySelect = ({ validate, label, ...props }) => {
  const [field, meta] = useField(props);

  const inputStyle = meta.touched && meta.error ? { borderColor: 'red' } : {};

  let fieldValidationData = null;

  if (validate?.length > 0) {
    validate.some(field => {
      if (field.path === props.name) {
        fieldValidationData = field;
        return true;
      }
      return false;
    });
  }

  return (
    <>
      <div className="stage-input-field">
        <div className="stage-label9" htmlFor={props.name}>
          {label}
        </div>
        <select
          className="stage-input6a"
          {...field}
          {...props}
        />
      </div>

      {(meta.touched && meta.error) ? (
        <div className="error-for-all-field">{meta.error}</div>
      ) : null}

      {fieldValidationData !== null ? (
        <div className="error-for-all-field">{fieldValidationData.msg}</div>
      ) : null}
    </>
  );
};

const MyDatePicker = ({ validate, label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  let fieldValidationData = null;
  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  return (
    <>
      <div className="stage-input-field">
        <div className="stage-input-field">
          <label className="stage-label11" htmlFor={props.id || props.name}>{label}</label>
          <div className="stage-input6">
            <DatePicker
              className="stage-content72"
              {...field}
              {...props}
            // selected={selectedDate}
            // onChange={(date) => setSelectedDate(date)}
            // placeholderText="Select a date"
            // minDate={new Date()}
            // dateFormat="dd-MM-yyyy"
            />
          </div>
        </div>
        {(meta.touched && meta.error) ? (
          <div className="account-update-profile-hint-text2 error-color">
            {meta.error}
          </div>
        ) : null}

        {fieldValidationData !== null ? (
          <div className="account-update-profile-hint-text2 error-color">
            {fieldValidationData.msg}
          </div>
        ) : null}
      </div>



    </>
  );
};


const MyTextArea = ({ validate, label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  let fieldValidationData = null;
  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  return (
    <>
      <div className="stage-input-field">
        <div className="stage-input-field">
          <label className="stage-label12" htmlFor={props.id || props.name}>{label}</label>
          <div className="stage-input6">
            <textarea
              className="stage-content72"
              {...field}
              {...props}
            />
          </div>
        </div>
        {(meta.touched && meta.error) ? (
          <div className="account-update-profile-hint-text2 error-color">
            {meta.error}
          </div>
        ) : null}

        {fieldValidationData !== null ? (
          <div className="account-update-profile-hint-text2 error-color">
            {fieldValidationData.msg}
          </div>
        ) : null}
      </div>

    </>
  );
};

// Fixed StageSlideOutEditForm
export const StageSlideOutEditForm = ({ onClose, channelName }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [propabilityPrecentage, setPropabilityPrecentage] = useState();
  const [backendValidation, setBackendValidation] = useState([]);
  const formikRef = useRef(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState({
    message: "",
    color: "",
  });

  const [triggerGetUpdateStage, { isSuccess: isUpdateStageSuccess, isLoading: isUpdateStageLoading, isError: isUpdateStageError, error: errorUpdateStage, data: updateStageData }] = useLazyFetchChatUpdateStageQuery();
  const [upsertUpdateStage, { isSuccess, isLoading, isError, error }] = useChatUpdateStageMutation();
  const updateStageTax = useGetUpdateStagesQuery();

  const [initialValues, setInitialValues] = useState({
    id: '',
    updateStageId: '',
    transactionValue: '',
    comment: '',
    timelineClosure: '',
  });

  useEffect(() => {
    if (channelName) {
      console.log("Triggering API call with channelName:", channelName);
      triggerGetUpdateStage(channelName);
    }
  }, [channelName, triggerGetUpdateStage]);

  useEffect(() => {
    console.log("updateStageData received:", updateStageData);

    if (updateStageData?.data?.chatmessagemetadata_chatupdatestage) {
      console.log("Found stage data:", updateStageData.data.chatmessagemetadata_chatupdatestage);

      const stageData = updateStageData.data.chatmessagemetadata_chatupdatestage;

      // Format the transaction value
      const formattedValue = stageData.transactionValue
        ? formatNumber(stageData.transactionValue.toString())
        : '';

      // Set date if available
      if (stageData.timelineClosure) {
        setSelectedDate(new Date(stageData.timelineClosure));
      }

      // Set initial values
      setInitialValues({
        id: stageData.updateStageId,
        updateStageId: stageData.updateStageId?.toString() || '',
        transactionValue: formattedValue,
        comment: stageData.comment || '',
        timelineClosure: stageData.timelineClosure
          ? stageData.timelineClosure.split('T')[0]
          : '',
      });
    } else {
      console.log("No stage data found or unexpected structure");
      // Keep default empty values
    }
  }, [isUpdateStageSuccess, updateStageData]);

  // Set probability percentage when stage or initial values change
  useEffect(() => {
    if (initialValues?.updateStageId &&
      updateStageTax.isSuccess &&
      updateStageTax.data) {

      const selectedTax = updateStageTax.data.find(
        tax => tax.id.toString() === initialValues.updateStageId.toString()
      );

      if (selectedTax) {
        setPropabilityPrecentage(selectedTax);
      }
    }
  }, [initialValues, updateStageTax.isSuccess, updateStageTax.data]);

  const handleReset = () => {
    const selectedTax = updateStageTax.data.find(
      tax => tax.id.toString() === initialValues.updateStageId.toString()
    );

    if (selectedTax) {
      setPropabilityPrecentage(selectedTax);
    }
    // setPropabilityPrecentage(null);
    setSelectedDate(new Date(updateStageData.data.chatmessagemetadata_chatupdatestage.timelineClosure));
    formikRef.current.resetForm();
  }

  const handleChangePercentage = (selectedTax, setFieldValue) => {
    setPropabilityPrecentage(selectedTax);
    // Important: Update Formik's value
    setFieldValue("updateStageId", selectedTax.id);
  }

  const handleChangeTransaction = async (event, setFieldValue) => {
    const { name, value } = event.target;

    if (name === 'transactionValue') {
      // Only format when there's actually a value
      if (value) {
        const formattedValue = formatNumber(value);
        setFieldValue(name, formattedValue);
      } else {
        // Handle empty input
        setFieldValue(name, '');
      }
    }
  }

  // Update selectedDate to Formik
  useEffect(() => {
    if (selectedDate && formikRef.current) {
      const formattedDate = selectedDate.toISOString().split('T')[0]; // Format as YYYY-MM-DD
      formikRef.current.setFieldValue('timelineClosure', formattedDate);
    }
  }, [selectedDate]);

  const finalSubmit = async (formData, setSubmitting) => {
    try {
      const response = await upsertUpdateStage(formData).unwrap();
      if (response.status) {
        console.log(response);
        setShowSuccessMessage({ message: "Stage updated successfully", color: "green" });
        await new Promise(resolve => setTimeout(resolve, 1500));
        window.location.reload();
        formikRef.current.resetForm();
        onClose();
      }
    }
    catch (error) {
      if (error.status === 422) {
        console.log(error);
        const decryptedData = decryptErrorData(error);
        console.log(decryptedData);
        setBackendValidation(decryptedData.errors);
      }
      else if (error.status === 401) {
        console.log(error);
        if (error.data.message) {
          setShowSuccessMessage({ message: error.data.message, color: "red" });
        } else {
          setShowSuccessMessage({ message: 'An unknown error occurred. Please try again later.', color: "red" });
        }
      } else {
        console.log(error);
      }
      setSubmitting(false);
    }
  };

  if (isUpdateStageLoading) {
    return <div>Loading...</div>;
  } else if (updateStageTax.error || !channelName) {
    return <ErrorShowComponent message1={'Internal server error'} message2={'There is an issue while loading required data.'} />;
  } else {
    return (
      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={Yup.object({
          updateStageId: Yup.string().required("This is a required field").test(
            "OPTION", "Please select a valid option",
            (value) => value !== '0' && value !== ''
          ),
          timelineClosure: Yup.string().required("This is a required field").max(190, 'Must be 190 characters or less'),
          transactionValue: Yup.string()
            .required("This is a required field")
            .test(
              "no-decimals",
              "No decimal places are allowed",
              (value) => {
                if (!value) return true;
                return !/[.]/.test(value);
              }
            )
            .test(
              'is-numeric',
              'Characters are not allowed, please enter numbers only',
              (value) => {
                if (!value) return true;
                const cleanValue = value.replace(/,/g, '');
                return /^-?\d*\.?\d*$/.test(cleanValue);
              }
            )
            .test(
              'non-negative',
              'Field data cannot be negative',
              value => {
                if (!value) return true;
                const numValue = Number(value.replace(/,/g, ''));
                return numValue >= 0;
              }
            )
            .test(
              "range",
              "Value must be between 1 and 99,999",
              (value) => {
                if (!value) return true;
                const numberValue = Number(value.replace(/,/g, ''));
                return numberValue > 0 && numberValue <= 99999;
              }
            ),
          comment: Yup.string().required("This is a required field").max(300, 'Must be 300 characters or less'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);
          finalSubmit(objectToUpdateStageData(values, channelName), setSubmitting);
        }}
      >
        {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
          <Form className="account-update-profile-content-elements">
            <div className="stage-slide-out-menu">
              <main className="stage-panel">
                <section className="stage-slide-out-menu-header-parent">
                  <header className="stage-slide-out-menu-header">
                    <div className="stage-content71">
                      <img className="stage-featured-icon2" loading="lazy" alt="" src={funnel} />
                      <div className="stage-text-and-supporting-text26">
                        <a className="stage-title9">Update Stage</a>
                        <div className="stage-supporting-text91">Add a line here</div>
                      </div>
                    </div>
                    <div className="stage-button-close-x6" onClick={onClose}>
                      <img className="stage-mail-01-icon" loading="lazy" alt="" src={xclose} />
                    </div>
                  </header>
                  <div className="stage-section-container">
                    <div className="stage-section2">
                      <div className="stage-input-field">
                        <MySelect
                          label="Stage"
                          validate={backendValidation}
                          name="updateStageId"
                          id="updateStageId"
                          value={values.updateStageId}
                          onChange={(e) => {
                            const selectedIndex = e.target.selectedIndex - 1;
                            if (updateStageTax.isSuccess && selectedIndex >= 0) {
                              const selectedTax = updateStageTax.data[selectedIndex];
                              handleChangePercentage(selectedTax, setFieldValue);
                            }
                          }}
                        >
                          <option value="">Select From Dropdown list</option>
                          {updateStageTax.isLoading && <option value="">Loading...</option>}
                          {updateStageTax.isError && <option value="">Failed to load options</option>}
                          {updateStageTax.isSuccess && updateStageTax.data.length === 0 && (
                            <option value="">No stage available</option>
                          )}
                          {updateStageTax.isSuccess &&
                            updateStageTax.data.map((tax) => (
                              <option key={tax.id} value={tax.id}>
                                {tax.name}
                              </option>
                            ))}
                        </MySelect>
                      </div>

                      <div className="stage-form">
                        <div className="stage-input-field">
                          <div className="stage-input-field">
                            <div className="stage-label10">Probability of closure</div>
                            <div className="stage-input6">
                              <div className="stage-content73">
                                <input
                                  className="stage-content72"
                                  type="text"
                                  value={`${propabilityPrecentage?.probability || ""}`}
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <MyDatePicker
                        label="Timeline to closure"
                        name="timelineClosure"
                        id="timelineClosure"
                        selected={selectedDate}
                        validate={backendValidation}
                        onChange={(date) => setSelectedDate(date)}
                        placeholderText="Select a date"
                        minDate={new Date()}
                        dateFormat="dd-MM-yyyy"
                      />

                      <MyTextInput
                        label="Transaction Value"
                        name="transactionValue"
                        id="transactionValue"
                        type="text"
                        onChange={(e) => {
                          handleChangeTransaction(e, setFieldValue);
                        }}
                        validate={backendValidation}
                        placeholder="Value in USD"
                      />

                      <MyTextArea
                        label="Comment"
                        name="comment"
                        id="comment"
                        validate={backendValidation}
                        placeholder="Max 300 characters"
                        type="text"
                        maxLength="300"
                      />
                    </div>
                  </div>

                  {showSuccessMessage.message && (
                    <div
                      style={{
                        fontSize: "14px",
                        color: showSuccessMessage.color,
                        padding: "15px 10px",
                        border: "1px solid " + showSuccessMessage.color,
                        borderRadius: "10px",
                        margin: "0 auto",
                      }}
                    >
                      {showSuccessMessage.message}
                    </div>
                  )}

                  <div className="stage-divider-wrapper">
                    <div className="stage-divider12"></div>
                  </div>
                </section>

                <footer className="stage-footer2">
                  <div className="stage-content76">
                    <div className="stage-actions19">
                      <button
                        type="button"
                        className="stage-buttonsbutton49"
                        onClick={handleReset}
                      >
                        <img className="stage-placeholder-icon91" alt="" src="./public/placeholder2.svg" />
                        <div className="stage-text-padding51">
                          <div className="stage-text275">Reset</div>
                        </div>
                        <img className="stage-placeholder-icon91" alt="" src="./public/placeholder2.svg" />
                      </button>

                      <button
                        className="stage-buttonsbutton50"
                        type='submit' onClick={handleSubmit} disabled={isSubmitting}
                      >
                        <img className="stage-placeholder-icon91" alt="" src="./public/placeholder3.svg" />
                        <div className="stage-text-padding51">
                          <div className="stage-text276">Submit</div>
                        </div>
                        <img className="stage-placeholder-icon91" alt="" src="./public/placeholder3.svg" />
                      </button>
                    </div>
                  </div>
                </footer>
              </main>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
};

// if (initialValues) {
//   return (
//     <>
//       <Formik
//         enableReinitialize={true}
//         innerRef={formikRef}
//         initialValues={initialValues}
//         validationSchema={Yup.object({
//           updateStageId: Yup.string().required("This is a required field").test(
//             "OPTION", "Please select a valid option",
//             (value) => value !== '0' && value !== ''
//           ),
//           timelineClosure: Yup.string().required("This is a required field").max(190, 'Must be 190 characters or less'),
//           transactionValue: Yup.string()
//             .required("This is a required field")
//             .test(
//               "no-decimals",
//               "No decimal places are allowed",
//               (value) => {
//                 if (!value) return true;
//                 return !/[.]/.test(value);
//               }
//             )
//             .test(
//               'is-numeric',
//               'Characters are not allowed, please enter numbers only',
//               (value) => {
//                 if (!value) return true;
//                 const cleanValue = value.replace(/,/g, '');
//                 return /^-?\d*\.?\d*$/.test(cleanValue);
//               }
//             )
//             .test(
//               'non-negative',
//               'Field data cannot be negative',
//               value => {
//                 if (!value) return true;
//                 const numValue = Number(value.replace(/,/g, ''));
//                 return numValue >= 0;
//               }
//             )
//             .test(
//               "range",
//               "Value must be between 1 and 99,999",
//               (value) => {
//                 if (!value) return true;
//                 const numberValue = Number(value.replace(/,/g, ''));
//                 return numberValue > 0 && numberValue <= 99999;
//               }
//             ),
//           comment: Yup.string().required("This is a required field").max(300, 'Must be 300 characters or less'),
//         })}
//         onSubmit={(values, { setSubmitting }) => {
//           console.log(values);
//           // setSubmitting(false);
//           finalSubmit(objectToUpdateStageData(values, channelName), setSubmitting);
//         }}
//       >
//         {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
//           <Form className="account-update-profile-content-elements">
//             <div className="stage-slide-out-menu">
//               <main className="stage-panel">
//                 <section className="stage-slide-out-menu-header-parent">
//                   <header className="stage-slide-out-menu-header">
//                     <div className="stage-content71">
//                       <img className="stage-featured-icon2" loading="lazy" alt="" src={funnel} />
//                       <div className="stage-text-and-supporting-text26">
//                         <a className="stage-title9">Update Stage</a>
//                         <div className="stage-supporting-text91">Add a line here</div>
//                       </div>
//                     </div>
//                     <div className="stage-button-close-x6" onClick={onClose}>
//                       <img className="stage-mail-01-icon" loading="lazy" alt="" src={xclose} />
//                     </div>
//                   </header>
//                   <div className="stage-section-container">
//                     <div className="stage-section2">
//                       <div className="stage-input-field">
//                         <MySelect
//                           label="Stage"
//                           validate={backendValidation}
//                           name="updateStageId"
//                           id="updateStageId"
//                           value={values.updateStageId}
//                           onChange={(e) => {
//                             const selectedIndex = e.target.selectedIndex - 1;
//                             if (updateStageTax.isSuccess && selectedIndex >= 0) {
//                               const selectedTax = updateStageTax.data[selectedIndex];
//                               handleChangePercentage(selectedTax, setFieldValue);
//                             }
//                           }}
//                         >
//                           <option value="">Select From Dropdown list</option>
//                           {updateStageTax.isLoading && <option value="">Loading...</option>}
//                           {updateStageTax.isError && <option value="">Failed to load options</option>}
//                           {updateStageTax.isSuccess && updateStageTax.data.length === 0 && (
//                             <option value="">No stage available</option>
//                           )}
//                           {updateStageTax.isSuccess &&
//                             updateStageTax.data.map((tax) => (
//                               <option key={tax.id} value={tax.id}>
//                                 {tax.name}
//                               </option>
//                             ))}
//                         </MySelect>
//                       </div>

//                       <div className="stage-form">
//                         <div className="stage-input-field">
//                           <div className="stage-input-field">
//                             <div className="stage-label10">Probability of closure</div>
//                             <div className="stage-input6">
//                               <div className="stage-content73">
//                                 <input
//                                   className="stage-content72"
//                                   type="text"
//                                   value={`${propabilityPrecentage?.probability || ""}`}
//                                   readOnly
//                                 />
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>

//                       {/* <div className="stage-input-field">
//                           <div className="stage-input-field">
//                             <div className="stage-label11">Timeline to closure</div>
//                             <div className="stage-input6">
//                               <DatePicker
//                                 className="stage-content72"
//                                 selected={selectedDate}
//                                 onChange={(date) => setSelectedDate(date)}
//                                 placeholderText="Select a date"
//                                 minDate={new Date()}
//                                 dateFormat="dd-MM-yyyy"
//                               />
//                             </div>
//                           </div>
//                           <div className="stage-hint-text6">This is a hint text to help user.</div>
//                         </div> */}
//                       <MyDatePicker
//                         label="Timeline to closure"
//                         name="timelineClosure"
//                         id="timelineClosure"
//                         selected={selectedDate}
//                         validate={backendValidation}
//                         onChange={(date) => setSelectedDate(date)}
//                         placeholderText="Select a date"
//                         minDate={new Date()}
//                         dateFormat="dd-MM-yyyy"
//                       />

//                       {/* <div className="stage-input-field">
//                           <div className="stage-input-field">
//                             <div className="stage-label12">Transaction Value</div>
//                             <div className="stage-input6">
//                               <img className="stage-help-icon2" alt="" src={dollar} />
//                               <Field
//                                 className="stage-content72"
//                                 placeholder="Value in USD"
//                                 type="text"
//                                 name="transactionValue"
//                               />
//                             </div>
//                           </div>
//                           <div className="stage-hint-text6">This is a hint text to help user.</div>
//                           <ErrorMessage name="transactionValue" component="div" className="error-for-all-field" />
//                         </div> */}

//                       <MyTextInput
//                         label="Transaction Value"
//                         name="transactionValue"
//                         id="transactionValue"
//                         type="text"
//                         onChange={(e) => {
//                           handleChangeTransaction(e, setFieldValue);
//                         }}
//                         validate={backendValidation}
//                         placeholder="Value in USD"
//                       />

//                       {/* <div className="stage-input-field">
//                           <div className="stage-input-field">
//                             <div className="stage-label12">Comment</div>
//                             <div className="stage-input6">
//                               <Field
//                                 as="textarea"
//                                 className="stage-content72"
//                                 placeholder="Max 300 characters"
//                                 type="text"
//                                 maxLength="300"
//                                 name="comment"
//                               />
//                             </div>
//                           </div>
//                           <ErrorMessage name="comment" component="div" className="error-for-all-field" />
//                         </div> */}

//                       <MyTextArea
//                         label="Comment"
//                         name="comment"
//                         id="comment"
//                         validate={backendValidation}
//                         placeholder="Max 300 characters"
//                         type="text"
//                         maxLength="300"
//                       />
//                     </div>
//                   </div>

//                   {showSuccessMessage.message && (
//                     <div
//                       style={{
//                         fontSize: "14px",
//                         color: showSuccessMessage.color,
//                         padding: "15px 10px",
//                         border: "1px solid " + showSuccessMessage.color,
//                         borderRadius: "10px",
//                         margin: "0 auto",
//                       }}
//                     >
//                       {showSuccessMessage.message}
//                     </div>
//                   )}

//                   <div className="stage-divider-wrapper">
//                     <div className="stage-divider12"></div>
//                   </div>
//                 </section>

//                 <footer className="stage-footer2">
//                   <div className="stage-content76">
//                     <div className="stage-actions19">
//                       <button
//                         type="button"
//                         className="stage-buttonsbutton49"
//                         onClick={handleReset}
//                       >
//                         <img className="stage-placeholder-icon91" alt="" src="./public/placeholder2.svg" />
//                         <div className="stage-text-padding51">
//                           <div className="stage-text275">Reset</div>
//                         </div>
//                         <img className="stage-placeholder-icon91" alt="" src="./public/placeholder2.svg" />
//                       </button>

//                       <button
//                         className="stage-buttonsbutton50"
//                         type='submit' onClick={handleSubmit} disabled={isSubmitting}
//                       >
//                         <img className="stage-placeholder-icon91" alt="" src="./public/placeholder3.svg" />
//                         <div className="stage-text-padding51">
//                           <div className="stage-text276">Submit</div>
//                         </div>
//                         <img className="stage-placeholder-icon91" alt="" src="./public/placeholder3.svg" />
//                       </button>
//                     </div>
//                   </div>
//                 </footer>
//               </main>
//             </div>
//           </Form>
//         )}
//       </Formik>
//     </>
//   );
// } else if (updateStageTax.error || !channelName) {
//   return <ErrorShowComponent message1={'Internal server error'} message2={'Theres is an issue while loading required data.'} />
// }