import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  serviceCategory: '',
  serviceValueProposition: '',
  rangeMin: '',
  rangeMax: '',
  unitOfMeasureService: '',
  clinicalApplications: '',
  purposeUse: '',
  deviceCategory: '',
  physicalLocation: '',

  serviceFeatureHighlights: '',
  serviceBenefitsHighlights: '',
  parentDevices: '',
  featureImage: '',
  featureImageLink: '',
  bannerImage: '',
  bannerImageLink: '',
  videoType: '',
  linkVideo: '',
  location: '',
  serviceAndSupportReach: '',

  serviceFeatures: '',
  serviceBenefits: '',
  scopeOfWork: '',
  serviceLevelAgreement: '',
  additionalInformation:'',
  document: '',
  documentLink: '',
}


const adminEditServiceSlice = createSlice({
  name: "adminEditService",
  initialState,
  reducers: {
    fillAdminEditServiceForm(state, action) {
     return action.payload;
    },
    updateAdminEditServiceField(state, action) {
      const {field, value} = action.payload
      if (value !== undefined) { 
        state[field] = value; 
      }
    },
    clearAdminEditServiceForm() {
      return initialState;
    }
  },
});

export const { updateAdminEditServiceField,clearAdminEditServiceForm,fillAdminEditServiceForm } = adminEditServiceSlice.actions

export default adminEditServiceSlice.reducer

