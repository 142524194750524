import React, { useState } from 'react';
import "./ThreeDotsListingComponent.css";
import { Link, useNavigate } from 'react-router-dom';
import { useSetArchiveDeviceMutation, useSetPublishDeviceMutation } from '../../api/DeviceAPI';
import { useSetArchiveServiceMutation, useSetPublishServiceMutation } from '../../api/ServiceAPI';
import { useSetArchiveSoftwareMutation, useSetPublishSoftwareMutation } from '../../api/SoftwareAPI';
import { useSetArchiveSpareMutation, useSetPublishSpareMutation } from '../../api/SpareAPI';
import { useSetArchiveJobMutation, useSetPublishJobMutation } from '../../api/JobAPI';



export const ThreeDotsSalesIqDetailPageComponent = ({ option, postId, onClose, postType, url, totalActiveEngagement, onLaunchCampaignClick, onSocialShareClick }) => {

  const handleLaunchCampaign = () => {
    console.log("Launch Campaign Clicked");
    if (onLaunchCampaignClick) {
      onLaunchCampaignClick();
    }
  };

  const handleSocialShare = () => {
    console.log("Social Channels Clicked");
    if (onSocialShareClick) {
      onSocialShareClick();
    }
  };


  const navigate = useNavigate();

  const [setToDeviceArchive] = useSetArchiveDeviceMutation();

  const [setToDevicePublish] = useSetPublishDeviceMutation();


  const [setToSpareArchive] = useSetArchiveSpareMutation();

  const [setToSparePublish] = useSetPublishSpareMutation();


  const [setToServiceArchive] = useSetArchiveServiceMutation();

  const [setToServicePublish] = useSetPublishServiceMutation();


  const [setToSoftwareArchive] = useSetArchiveSoftwareMutation();

  const [setToSoftwarePublish] = useSetPublishSoftwareMutation();


  const [setToJobArchive] = useSetArchiveJobMutation();

  const [setToJobPublish] = useSetPublishJobMutation();

  const handleEdit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {

      navigate(`/edit-${url}`);
    } catch (error) {
      console.error('Error navigating ' + error);
    }
  };

  const handleSetArchive = async (e) => {
    let response;
    e.preventDefault();
    e.stopPropagation();

    try {
      if (!postId) {
        console.error('No post ID provided');
        return;
      }

      switch (postType) {
        case 'device':
          response = await setToDeviceArchive({ id: postId }).unwrap();
          break;
        case 'service':
          response = await setToServiceArchive({ id: postId }).unwrap();
          break;
        case 'software':
          response = await setToSoftwareArchive({ id: postId }).unwrap();
          break;
        case 'spare':
          response = await setToSpareArchive({ id: postId }).unwrap();
          break;
        case 'job':
          response = await setToJobArchive({ id: postId }).unwrap();
          break;
        default:
          break;
      }

      // console.log('Updating status for post:', postId);
      // const response = await setToWantedDeviceArchive({ id: postId }).unwrap();
      // console.log('Update response:', response);

      if (response?.status) {
        onClose();
        window.location.reload();
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleSetPublish = async (e) => {
    let response;
    e.preventDefault();
    e.stopPropagation();

    try {
      if (!postId) {
        console.error('No post ID provided');
        return;
      }


      switch (postType) {
        case 'device':
          response = await setToDevicePublish({ id: postId }).unwrap();
          break;
        case 'service':
          response = await setToServicePublish({ id: postId }).unwrap();
          break;
        case 'software':
          response = await setToSoftwarePublish({ id: postId }).unwrap();
          break;
        case 'spare':
          response = await setToSparePublish({ id: postId }).unwrap();
          break;
        case 'job':
          response = await setToJobPublish({ id: postId }).unwrap();
          break;
        default:
          break;
      }

      // console.log('Updating status for post:', postId);
      // const response = await setToWantedDevicePublish({ id: postId }).unwrap();
      // console.log('Update response:', response);

      if (response.status) {
        onClose();
        window.location.reload();
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };


  const handleItemClick = (e, action) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('Action clicked:', action);

    switch (action) {
      case 'edit':
        handleEdit(e);
        break;
      case 'publish':
        handleSetPublish(e);
        break;
      case 'archive':
        handleSetArchive(e);
        break;
      case 'campaign':
        handleLaunchCampaign(e);
        onClose();
        break;
      case 'social':
        handleSocialShare(e);
        onClose();
        break;
      // Add other cases as needed
      default:
      // handleRejectUpdate(e);
    }
  };

  const MenuItem = ({ onClick, text, to, action }) => (
    <div
      className="three-dots-filter-dropdown-dropdown-list-item"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (onClick) onClick(e);
        if (action) handleItemClick(e, action);
      }}
    >
      <div className="three-dots-filter-dropdown-content48">
        <div className="three-dots-filter-dropdown-icon-and-text">
          {to ? (
            <Link
              className="three-dots-filter-dropdown-text247"
              to={to}
              onClick={(e) => e.stopPropagation()}
            >
              {text}
            </Link>
          ) : (
            <div className="three-dots-filter-dropdown-text247">{text}</div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <>

      <div className="three-dots-filter-dropdown-three-dots-filter-all-options-fixed">
        <section className="three-dots-filter-dropdown-menu-items">
          {/* Broadcast Option */}
          {/* <div className="three-dots-filter-dropdown-dropdown-list-item">
            <div className="three-dots-filter-dropdown-content48">
              <div className="three-dots-filter-dropdown-icon-and-text">
                <div
                  className="three-dots-filter-dropdown-text247"
                  style={{ cursor: 'pointer' }}
                  onClick={handleLaunchCampaign}
                >
                  Launch Campaign
                </div>
              </div>
            </div>
          </div> */}

          {/* Social Share Option */}
          {/* <div className="three-dots-filter-dropdown-dropdown-list-item">
            <div className="three-dots-filter-dropdown-content48">
              <div className="three-dots-filter-dropdown-icon-and-text">
                <div
                  className="three-dots-filter-dropdown-text247"
                  style={{ cursor: 'pointer' }}
                  onClick={handleSocialShare}
                >
                  Social Channels
                </div>
              </div>
            </div>
          </div> */}

          {option === 'published' && (
            <>
              <MenuItem text="Launch Campaign" action="campaign" />
              <MenuItem text="Social Channels" action="social" />
              {totalActiveEngagement === 0 &&
                <>
                  <MenuItem text="Edit" action="edit" />
                  <MenuItem text="Archive" action="archive" />
                </>
              }
            </>
          )}
          {option === 'archived' && (
            <>
              {/* <MenuItem text="Launch Campaign" action="campaign" />
              <MenuItem text="Social Channels" action="social" /> */}
              {totalActiveEngagement === 0 &&
                <>
                  <MenuItem text="Edit" action="edit" />
                </>
              }
              <MenuItem text="Republish" action="publish" />
            </>
          )}

          {option === 'pending' && (

            <MenuItem text="No action found" />

          )}
        </section>




      </div>
    </>
  );
};
