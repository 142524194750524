import React from 'react';
import "./FunnelFilterByComponent.css";
// import divider from '../../icons/Gerator/dashboard/divider.svg';

export const FunnelFilterByComponent = ({ onOptionSelect }) => {
  const handleOptionClick = (option) => {
    onOptionSelect(option); // Call the function from the parent with the selected option
  };
  return (
    <div className="funnel-all-sort-by-dropdown-funnel-all-sort-by-all-options-fixed">
      {/* <div className="funnel-all-sort-by-dropdown-dropdown-list-header">
        <div className="funnel-all-sort-by-dropdown-avatar-label-group">


        </div>
      </div> */}
      <section className="funnel-all-sort-by-dropdown-menu-items">
        <div className="funnel-all-sort-by-dropdown-dropdown-list-item" onClick={() => handleOptionClick({ label: 'Date (newest to oldest)', value: 'date_desc' })}>
          <div className="funnel-all-sort-by-dropdown-content48">
            <div className="funnel-all-sort-by-dropdown-icon-and-text">
              <div className="funnel-all-sort-by-dropdown-text247">Date (newest to oldest)</div>
            </div>
          </div>
        </div>
        <div className="funnel-all-sort-by-dropdown-dropdown-list-item" onClick={() => handleOptionClick({ label: 'Date (oldest to newest)', value: 'date_asc' },)}>
          <div className="funnel-all-sort-by-dropdown-content48">
            <div className="funnel-all-sort-by-dropdown-icon-and-text">
              <div className="funnel-all-sort-by-dropdown-text247">Date (oldest to newest)</div>
            </div>
          </div>
        </div>
        <div className="funnel-all-sort-by-dropdown-dropdown-list-item" onClick={() => handleOptionClick({ label: 'Stage (1 to 7)', value: 'stage_asc' },)}>
          <div className="funnel-all-sort-by-dropdown-content48">
            <div className="funnel-all-sort-by-dropdown-icon-and-text">
              <div className="funnel-all-sort-by-dropdown-text247">Stage (1 to 7)</div>
            </div>
          </div>
        </div>
        <div className="funnel-all-sort-by-dropdown-dropdown-list-item" onClick={() => handleOptionClick({ label: 'Stage (7 to 1)', value: 'stage_desc' })}>
          <div className="funnel-all-sort-by-dropdown-content48">
            <div className="funnel-all-sort-by-dropdown-icon-and-text">
              <div className="funnel-all-sort-by-dropdown-text247">Stage (7 to 1)</div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

