import React, { useContext, useEffect, useRef, useState } from "react";
import gerator_logo from '../../icons/Gerator/gerator04-1@2x.png';
import social_logo_google from '../../icons/Gerator/social-icon.svg';
import social_logo_facebook from '../../icons/Gerator/social-icon-1.svg';
import help_icon from '../../icons/Gerator/help-icon.svg';
import chevron_right from '../../icons/Gerator/chevronright.svg';
import avatar_company_icon from '../../icons/Gerator/-avatar-company-icon@2x.png';
import { Formik, Form, useField, useFormikContext } from "formik";
import { auth } from "../../screens/Login/FirebaseClient";
import * as Yup from "yup";


// import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import { auth, GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup } from "../../screens/Login/FirebaseClient";
import { useAddUserMutation, useGetUserQuery, useLazyAddUserFacebookQuery, useLazyAddUserGoogleQuery, useLazyGetUserForSignUpQuery, useLazyGetUserQuery } from "../../api/Login&RegisterAPI";
import { decryptData, decryptErrorData } from "../../helper/AESHelper";
// import { encryptData, secretKey, secretKeyHex } from "../../helper/AESHelper";

export const MyTextInput = ({ validate, label, ...props }) => {

    const [field, meta] = useField(props);

    let fieldValidationData = null;
    if (validate?.length > 0) {
        validate.some((field) => {
            // Use 'some' for early termination
            if (field.path === props.name) {
                fieldValidationData = field;
                return true; // This will stop the loop early
            }
            return false; // Continue if no match
        });
    }


    return (
        <>

            <div className="sign-up-input-field">
                <div className="sign-up-label">{label}</div>
                <div className="sign-up-input">
                    <input
                        className="sign-up-input-field"
                        {...field}
                        {...props}
                    />
                    <img
                        className="sign-up-help-icon"
                        alt=""
                        src="/help-icon.svg"
                    />
                </div>
            </div>


            {meta.touched && meta.error ? (
                <div className="sign-up-hint-text error-color">{meta.error}</div>
            ) : null}

            {fieldValidationData !== null ? (
                <div className="sign-up-hint-text error-color">{fieldValidationData.msg}</div>
            ) : null}
        </>
    );
};

const RegisterForm = () => {
    const providerGoogle = new GoogleAuthProvider();
    // const providerFacebook = new FacebookAuthProvider();
    // Mutation for adding a user
    const [addUser, { isLoading: isAddingUser, isError: isAddUserError, error: addUserError }] = useAddUserMutation();

    // Lazy query for getting user data
    const [triggerGetUserForSignUp, { data, isLoading: isGettingUser, isError: isGetUserError, error: getUserError }] = useLazyGetUserForSignUpQuery();

    // Lazy query for getting user data saved in db confirmation
    const [triggerAddUserGoogle, { dataGoogle, isLoading: isGettingUserGoogle, isError: isGetUserGoogleError, error: getUserGooleError }] = useLazyAddUserGoogleQuery();

    // Lazy query for getting user data saved in db confirmation
    // const [triggerAddUserFacebook, { dataFacebook, isLoading: isGettingUserFacebook, isError: isGetUserFacebookError, error: getUserFacebookError }] = useLazyAddUserFacebookQuery();

    const formikRef = useRef(null);

    const navigate = useNavigate();
    const [showSuccessMessage, setShowSuccessMessage] = useState({
        message: "",
        color: "",
    });
    const [backendValidation, setBackendValidation] = useState(null);

    // useEffect(() => {
    //   formikRef.current?.setValues(formData);
    //   // console.log(formikRef.current);
    // }, [formData]);


    const [isGoogleSubmitting, setGoogleSubmitting] = useState(false);


    const signUpWithGoogle = async () => {
        try {

            setGoogleSubmitting(true);

            const userCredential = await signInWithPopup(auth, providerGoogle)
            const user = userCredential.user;
            console.log("User Info: ", await user.getIdToken());
            console.log("User uid: ", user.uid);
            // return;
            const idToken = await user.getIdToken();
            // const uid = user.uid;
            console.log(user);
            //Get user details with current
            const status = await triggerGetUserForSignUp();
            console.log(status.data.status);

            if (!status.data.status) {
                try {
                    const addedUser = await triggerAddUserGoogle();
                    console.log(addedUser);
                    if (addedUser.data.status) {
                        setShowSuccessMessage({ message: "Signed up successfully", color: "green" });
                        await new Promise(resolve => setTimeout(resolve, 1500));
                        navigate("/account-setup-update-profile");
                    } else {
                        throw new Error("Failed to signup");
                    }
                    return;
                } catch (error) {
                    throw error;
                }


            }
            else {
                if (status.data.status) {
                    console.log(status.data);
                    const userDetails = status.data.user;
                    console.log(userDetails);

                    if (!userDetails.emailVerified && (userDetails.wizardStep === 1)) {
                        try {
                            const updatedUser = await triggerAddUserGoogle();
                            console.log(updatedUser);
                            if (updatedUser.data.status) {
                                setShowSuccessMessage({ message: "Sign in successfully", color: "green" });
                                await new Promise(resolve => setTimeout(resolve, 1500));
                                navigate("/account-setup-update-profile");
                            } else {
                                throw new Error("Failed to signup");
                            }
                            return;
                        } catch (error) {
                            throw error;
                        }
                    }

                    if (userDetails.emailVerified && ((userDetails.wizardStep >= 2 && userDetails.wizardStep <= 3))) {
                        switch (userDetails.wizardStep) {
                            case 1:
                                navigate("/account-setup-check-email");
                                break;
                            case 2:
                                navigate("/account-setup-update-profile");
                                break;
                            default:
                                navigate("/account-setup-subscription-plan");
                                break;
                        }
                    }
                    else {
                        setShowSuccessMessage({ message: "Already gerator user, logged in successfully", color: "green" });
                        await new Promise(resolve => setTimeout(resolve, 1500));
                        navigate("/dashboard");
                    }
                }
            }
            // else {
            //     throw new Error("Failed to retrieve ID token.");
            // }

        } catch (error) {
            console.log(error);

            if (error.status === 401) {
                setShowSuccessMessage({ message: error.message, color: "red" });
                return;
            }
            // console.log("Login failed:", error.message);
            setShowSuccessMessage({ message: "Internal server error", color: "red" });
        }
        finally {
            setGoogleSubmitting(false); // Stop Google sign-in loading
        }

    };

    const finalSubmit = async (formData, setSubmitting) => {
        // const auth = getAuth();

        try {

            
            // First create user in backend
            const response = await addUser(formData).unwrap();

            if (response.status) {
                // After successful backend registration, sign in the user with Firebase
                try {
                    const userCredential = await signInWithEmailAndPassword(
                        auth,
                        formData.email,
                        formData.password
                    );

                    // Set up auth state listener
                    auth.onAuthStateChanged((user) => {
                        if (user) {
                            // User is signed in
                            // localStorage.setItem('authUser', JSON.stringify(user));

                            setShowSuccessMessage({
                                message: response.message,
                                color: "green"
                            });

                            // Delay navigation to ensure auth state is properly set
                            setTimeout(() => {
                                navigate('/account-setup-check-email');
                            }, 1500);
                        }
                    });
                } catch (firebaseError) {
                    console.error('Firebase sign-in error:', firebaseError);
                    setShowSuccessMessage({
                        message: "Account created but login failed. Please try logging in.",
                        color: "orange"
                    });
                }
            } else {
                setShowSuccessMessage({
                    message: response.message,
                    color: "red"
                });
            }
        } catch (error) {
            if (error.status === 422) {
                const decryptedData = decryptErrorData(error.data);
                setBackendValidation(decryptedData.errors);
            } else if (error.status === 401) {
                setShowSuccessMessage({
                    message: error.data.message,
                    color: "red"
                });
            } else {
                setShowSuccessMessage({
                    message: error.message,
                    color: "red"
                });
            }
            setSubmitting(false);
        }
    };

    // const finalSubmit = async (formData, setSubmitting) => {
    //     try {

    //         const response = await addUser(formData).unwrap();
    //         // console.log("Device added successfully!", response);
    //         //If form was submitted successfully then status return with true, then reseting form
    //         if (response.status) {
    //             // setShowSuccessMessage(response.message)
    //             setShowSuccessMessage({ message: response.message, color: "green" });
    //             await new Promise(resolve => setTimeout(resolve, 1500));
    //             // Reseting form
    //             // resetForm();
    //             navigate('/account-setup-check-email');
    //         }
    //         if (!response.status) {
    //             setShowSuccessMessage({ message: response.message, color: "red" })
    //         }
    //     } catch (error) {
    //         if (error.status === 422) {
    //             const decryptedData = decryptErrorData(error.data);
    //             // console.log(decryptedData);
    //             setBackendValidation(decryptedData.errors)
    //         }

    //         if (error.status === 401) {
    //             setShowSuccessMessage({ message: error.data.message, color: "red" });
    //         }
    //         else {

    //             console.log(error);

    //             // const decryptedData = decryptErrorData(error.data);
    //             // console.log(decryptedData);
    //             setShowSuccessMessage({ message: error.message, color: "red" });
    //         }
    //         setSubmitting(false)
    //     }

    // };
    return (
        <Formik
            initialValues={{
                displayName: "",
                email: "",
                password: "",
            }}
            validationSchema={Yup.object({
                // displayName: Yup.string()
                //     .required('Field is required')
                //     .min(3, 'Field must be at least 3 characters long')
                //     .max(50, 'Field must be at most 50 characters long')
                //     .matches(/^[a-zA-Z\s]*$/, 'Field can only contain letters and spaces'),
                // email: Yup.string()
                //     .email("Invalid email address")
                //     .required("Field is required"),
                // password: Yup.string()
                //     .required('Field is required')
                //     .min(8, 'Field must be at least 8 characters long')
                //     .matches(/\d/, 'Field must contain at least one number')
                //     .matches(/[a-zA-Z]/, 'Field must contain at least one letter')
            })}
            onSubmit={(values, { setSubmitting }) => {
                // multiPartData = objectToWantedDeviceFormData(values)
                finalSubmit(values, setSubmitting);
                // console.log(values);
                // resetForm();
            }}
        >
            {({ handleSubmit, isSubmitting }) => (
                <>
                    <form className="sign-up-content2" >
                        <div className="sign-up-form">
                            <div className="sign-up-input-field">
                                <MyTextInput
                                    className="sign-up-content3"
                                    name="displayName"
                                    type="text"
                                    label="Name*"
                                    placeholder="Enter your name"
                                    disabled={isSubmitting || isGoogleSubmitting}
                                     

                                     style={{
                                      
                                        opacity: isSubmitting || isGoogleSubmitting ? 0.5 : 1
                                    }}
                                    validate={backendValidation}
                                />
                            </div>

                            <div className="sign-up-input-field">
                                <MyTextInput
                                    className="sign-up-content3"
                                    name="email"
                                    label="Email*"
                                    type="email"
                                    placeholder="Enter your work email"
                                    disabled={isSubmitting || isGoogleSubmitting}
                                     

                                    style={{
                                     
                                       opacity: isSubmitting || isGoogleSubmitting ? 0.5 : 1
                                   }}
                                    validate={backendValidation}
                                />
                            </div>
                            <div className="sign-up-input-field ">
                                <MyTextInput
                                    validate={backendValidation}
                                    className="sign-up-content3 "
                                    id="password"
                                    label="Password*"
                                    name="password"
                                    disabled={isSubmitting || isGoogleSubmitting}
                                     

                                    style={{
                                     
                                       opacity: isSubmitting || isGoogleSubmitting ? 0.5 : 1
                                   }}
                                    type="password"
                                    placeholder="Enter your password"
                                />
                            </div>
                        </div>
                        <div className="sign-up-actions">
                            <button className="sign-up-buttonsbutton2" type='submit' onClick={handleSubmit}
                                     disabled={isSubmitting || isGoogleSubmitting}
                                     

                                     style={{
                                        cursor: isSubmitting || isGoogleSubmitting ? "not-allowed" : "pointer",
                                        opacity: isSubmitting || isGoogleSubmitting ? 0.5 : 1
                                    }}

                        >
                                <img
                                    className="sign-up-placeholder-icon"
                                    alt=""
                                    src="/placeholder.svg"
                                />

                                <div className="sign-up-text-padding">
                                    <div className="sign-up-signup-button-label">{isSubmitting ? "Submitting..." : "Get Started"}</div>
                                </div>
                                <img
                                    className="sign-up-placeholder-icon"
                                    alt=""
                                    src="/placeholder.svg"
                                />
                            </button>
                            <div className="sign-up-social-button-groups">
                                <div className="sign-up-social-button"  onClick={isSubmitting || isGoogleSubmitting ? null : signUpWithGoogle}
                                
                               

                                style={{
                                    cursor: isSubmitting || isGoogleSubmitting ? "not-allowed" : "pointer",
                                    opacity: isSubmitting || isGoogleSubmitting ? 0.5 : 1,
                                    pointerEvents: isSubmitting || isGoogleSubmitting ? "none" : "auto" 
                            }}
                            >
                                    <img
                                        className="sign-up-social-icon"
                                        loading="lazy"
                                        alt=""
                                        src={social_logo_google}
                                    />
                                    <div className="sign-up-text2">   {isGoogleSubmitting ? "Signing up..." : "Sign up with Google"}</div>
                                </div>
                                {/* <div className="sign-up-social-button" >
                                    <img
                                        className="sign-up-social-icon1"
                                        alt=""
                                        src={social_logo_facebook}
                                    />

                                    <div className="sign-up-text2">Sign in with Facebook</div>
                                </div> */}

                                {showSuccessMessage.message && (
                                    <div
                                        style={{
                                            fontSize: "14px",
                                            color: showSuccessMessage.color,
                                            padding: "15px 10px",
                                            border: "1px solid " + showSuccessMessage.color,
                                            borderRadius: "10px",
                                            margin: "0 auto",
                                        }}
                                    >
                                        {showSuccessMessage.message}
                                    </div>
                                )}
                            </div>
                        </div>
                    </form>
                </>
            )}
        </Formik>
    );
};
export default RegisterForm;
