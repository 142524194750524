import "./ArchiveWantedSpare.css"
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLazyGetWantedSparesQuery, useDeleteWantedSpareMutation } from "../../../api/WantedSpareAPI";
import { getCustomizedDate } from "../../../helper/SpareHelper";
import { Loader } from "../../../components/Loader";
import { useLazyGetUserQuery } from "../../../api/Login&RegisterAPI";
import { auth, onAuthStateChanged } from '../../Login/FirebaseClient';
import { decryptErrorData } from "../../../helper/AESHelper";
import { useNavigate } from 'react-router-dom';
import { Navbar1 } from '../../../components/Navbar1/Navbar1';
import { Navbar1 } from '../../../components/Navbar1/Navbar1';
import { FooterAddForms } from '../../../components/Footer/FooterAddForms/FooterAddForms';
import divider from '../../../icons/Gerator/archive_page/divider.svg';
import divider1 from '../../../icons/Gerator/archive_page/divider1.svg';

import divider2 from '../../../icons/Gerator/archive_page/divider2.svg';
import divider4 from '../../../icons/Gerator/archive_page/divider-4.svg';

import eye from '../../../icons/Gerator/archive_page/eye.svg';

import rectangle_41_2x from '../../../icons/Gerator/archive_page/rectangle-41@2x.png';

import dotsvertical from '../../../icons/Gerator/archive_page/dotsvertical.svg';
import dot1 from '../../../icons/Gerator/archive_page/-dot1.svg';


import chevronright from '../../../icons/Gerator/archive_page/chevronright.svg';
import chevrondown from '../../../icons/Gerator/archive_page/chevrondown.svg';

import placeholder from '../../../icons/Gerator/archive_page/placeholder.svg';
import placeholder1 from '../../../icons/Gerator/archive_page/placeholder1.svg';
import placeholder2 from '../../../icons/Gerator/archive_page/placeholder2.svg';
import placeholder3 from '../../../icons/Gerator/archive_page/placeholder3.svg';

import homeline from '../../../icons/Gerator/archive_page/homeline.svg';
import searchlg from '../../../icons/Gerator/archive_page/searchlg.svg';

import calendar from '../../../icons/Gerator/archive_page/calendar.svg';


import radar_1_1_2x from '../../../icons/Gerator/archive_page/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/archive_page/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/archive_page/magnifier-1@2x.png';

import group_58_2x from '../../../icons/Gerator/archive_page/group-58@2x.png';

import dotsvertical from '../../../icons/Gerator/archive_page/dotsvertical.svg';
import filterfunnel01 from '../../../icons/Gerator/archive_page/filterfunnel01.svg';
import searchlg from '../../../icons/Gerator/archive_page/searchlg.svg';
import filterlines from '../../../icons/Gerator/archive_page/filterlines.svg';
import dot1 from '../../../icons/Gerator/archive_page/-dot1.svg';
import xclose from '../../../icons/Gerator/archive_page/xclose.svg';
import lock04 from '../../../icons/Gerator/archive_page/lock04.svg';
import lockunlocked04 from '../../../icons/Gerator/archive_page/lockunlocked04.svg';
import arrowsquareupright from '../../../icons/Gerator/archive_page/arrowsquareupright.svg';
import markerpin02 from '../../../icons/Gerator/archive_page/markerpin02.svg';
import icon1 from '../../../icons/Gerator/archive_page/icon-1.svg';
import sliders02 from '../../../icons/Gerator/archive_page/sliders02.svg';
import { NoListingFound } from "../../../components/NoListing/NoListingFound";



export const ArchiveWantedSpare = () => {

  let dataLength = 0;
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState("");


  const [deleteWantedSpare] = useDeleteWantedSpareMutation();
  const [triggerGetUser, { data, isSuccess, error, isLoading, isError }] = useLazyGetUserQuery();
  const [triggerGetWantedSpare, { data: wantedSpareData,
    isLoading: isWantedSpareLoading,
    isSuccess: isWantedSpareSuccess,
    isError: isWantedSpareError,
    error: wantedSpareError }] = useLazyGetWantedSparesQuery();

  let content;
  // const [totalListings, setTotalListings] = useState("0");

  //Delete
  const handleDelete = async (id) => {
    try {

      const response = await deleteWantedSpare(id).unwrap();

      if (response.status) {
        // setShowSuccessMessage(response.message)
        // await new Promise(resolve => setTimeout(resolve, 1500));
        // Reseting form
        window.location.reload();
      }
    } catch (error) {
      console.error(`Failed to delete wanted spare `, error);
    }
  };

  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is authenticated
        setCurrentUser(user);
        triggerGetUser();
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate, triggerGetUser]);

  // Trigger the get wanted spare query only after user data is successfully fetched
  useEffect(() => {
    if (isSuccess) {
      // Once the user is authenticated and the getUser query is successful, trigger the getWantedSpare query
      triggerGetWantedSpare();
    }
  }, [isSuccess, triggerGetWantedSpare]);


  if (error) {
    console.log(error);
    if (isError) {
      const decryptedData = decryptErrorData(error.data);
      console.log(decryptedData);
      // navigate("/sign-in", { state: decryptedData.message });
      navigate("/sign-in", { state: { message: decryptedData.message } });
    }
    else {
      navigate("/sign-in", { state: { message: "Internal server error" } });
    }
    return; // Optionally handle error differently
  }


  if (isWantedSpareSuccess) {
    console.log(wantedSpareData);
    if (wantedSpareData.data.length > 0) {
      dataLength = wantedSpareData.data.length;
      content = wantedSpareData.data.map(wantedSpare =>
      (

        <div className="archive-wanted-device-rectangle-group listing-item" key={wantedSpare.id}>

          <div className="archive-wanted-spare-rectangle-container">
            {/* <img
              className="archive-wanted-spare-frame-inner"
              alt=""
              src={rectangle_41_2x}
            /> */}

            <div className="archive-wanted-spare-dots-vertical-container">
              {/* <img
                className="archive-wanted-spare-dots-vertical-icon1"
                alt=""
                src={dotsvertical}
              /> */}

              <div className="archive-wanted-spare-text-parent">

                <Link to={`/wanted-spare/${wantedSpare.id}/${wantedSpare.title}`} className=" archive-wanted-spare-text37"><p className="text-overflow-archive-spare">{wantedSpare.title}</p></Link>

                <div className="archive-wanted-spare-frame-group">
                  <div className="archive-wanted-spare-frame-container">
                    <div className="archive-wanted-spare-frame-div">
                      <div className="archive-wanted-spare-frame-parent1">
                        <div className="archive-wanted-spare-badge-parent">
                          <div className="archive-wanted-spare-badge9">
                            <img
                              className="archive-wanted-spare-dot-icon3"
                              alt=""
                              src={dot1}
                            />

                            <div className="archive-wanted-spare-price-value">Pending</div>
                          </div>
                          <div className="archive-wanted-spare-supporting-text4">ID# {wantedSpare.id}</div>
                        </div>
                        <div className="archive-wanted-spare-deal-type-parent">
                          <div className="archive-wanted-spare-completed">
                            <div className="archive-wanted-spare-second-value">4.9/5</div>
                          </div>
                          <div className="archive-wanted-spare-supporting-text-wrapper">
                            <div className="archive-wanted-spare-supporting-text3">99,999 reviews</div>
                          </div>
                        </div>
                        <div className="archive-wanted-spare-badge10">
                          <div className="archive-wanted-spare-third-badge-count text-overflow-archive-spare">
                            {wantedSpare.tax_device_category.name}
                          </div>
                        </div>
                      </div>
                      <div className="archive-wanted-spare-deal-location-parent">
                        <div className="archive-wanted-spare-dot-item-location-parent">
                          {/* <div className="archive-wanted-spare-navigation">
                            <img
                              className="archive-wanted-spare-marker-pin-02-icon"
                              alt=""
                              src={markerpin02}
                            />
                          </div> */}
                          {/* <div className="archive-wanted-spare-location">India, New Delhi</div> */}
                        </div>
                        <div className="archive-wanted-spare-frame-wrapper1">
                          <div className="archive-wanted-spare-badge-group">
                            <div className="archive-wanted-spare-badge10">
                              <div className="archive-wanted-spare-sub-badge text-overflow-archive-spare">{wantedSpare.tax_clinical_application.name}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="archive-wanted-spare-card-work">
                        <div className="archive-wanted-spare-inner-work">
                          <div className="archive-wanted-spare-dot-item-type-parent">
                            <div className="archive-wanted-spare-navigation">
                              <img
                                className="archive-wanted-spare-marker-pin-02-icon"
                                alt=""
                                src={calendar}
                              />
                            </div>
                            <div className="archive-wanted-spare-type-of-work">{getCustomizedDate(wantedSpare.createdAt)}</div>
                          </div>
                        </div>
                        <div className="archive-wanted-spare-badge13 text-overflow-archive-spare">
                          <div className="archive-wanted-spare-third-badge-count">
                            {wantedSpare.tax_purpose_use.name}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  {/* <div className="archive-wanted-spare-second-price">
                    <div className="archive-wanted-spare-price">$9,999,999.99</div>
                    <div className="archive-wanted-spare-price-value1">
                      <div className="archive-wanted-spare-deal-title">USD - Per Unit</div>
                    </div>
                    <div className="archive-wanted-spare-inner-icons-wrapper">
                      <div className="archive-wanted-spare-inner-icons">
                        <img
                          className="archive-wanted-spare-magnifier-1-icon"
                          alt=""
                          src={radar_1_1_2x}
                        />

                        <img
                          className="archive-wanted-spare-magnifier-1-icon"
                          alt=""
                          src={deal_2_1_2x}
                        />

                        <img
                          className="archive-wanted-spare-magnifier-1-icon"
                          alt=""
                          src={magnifier_1_2x}
                        />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="archive-wanted-spare-divider-parent">
            <img className="archive-wanted-spare-divider-icon4" alt="" src={divider2} />

            <div className="archive-wanted-spare-user-listings">
              <div className="archive-wanted-spare-frame-parent3">
                <div className="archive-wanted-spare-frame-parent4">
                  <img
                    className="archive-wanted-spare-user-content-child"
                    alt=""
                    src={group_58_2x}
                  />

                  <div className="archive-wanted-spare-inner-info-wrapper">
                    <div className="archive-wanted-spare-inner-info">
                      <div className="archive-wanted-spare-name">Olivia Rhye</div>
                      <div className="archive-wanted-spare-role1">Business Name</div>
                    </div>
                  </div>
                </div>
                <div className="archive-wanted-spare-buttonsbutton-wrapper">
                  <Link to={"/edit-wanted-spare/" + wantedSpare.id}>
                    <button className="archive-wanted-spare-buttonsbutton1">

                      <img className="archive-wanted-spare-eye-icon" alt="" src={eye} />

                      <div className="archive-wanted-spare-navigation">
                        <div className="archive-wanted-spare-view-label">Edit</div>
                      </div>
                    </button>
                  </Link>
                </div>
                <div className="archive-wanted-spare-logo-wrapper">
                  {/* <Link to={"/delete-wanted-spare/" + wantedSpare.id}> */}
                  <div className="archive-wanted-spare-buttonsbutton2 archive-wanted-spare-view-label" onClick={() => handleDelete(wantedSpare.id)}>
                    <div className="archive-wanted-spare-icon-wrapper">
                      <img className="archive-wanted-spare-icon1" alt="" src={icon1} />
                    </div>
                    <div className="archive-wanted-spare-navigation">
                      <div className="archive-wanted-spare-lock-label">Delete</div>
                    </div>
                    <img className="archive-wanted-spare-eye-icon" alt="" src={lock04} />
                  </div>
                  {/* </Link> */}
                </div>
                <div className="archive-wanted-spare-logo-wrapper">
                  <div className="archive-wanted-spare-buttonsbutton3">
                    <div className="archive-wanted-spare-icon-wrapper">
                      <img className="archive-wanted-spare-icon1" alt="" src={icon1} />
                    </div>
                    <div className="archive-wanted-spare-navigation">
                      <div className="archive-wanted-spare-lock-label">Sales IQ</div>
                    </div>
                    <img
                      className="archive-wanted-spare-eye-icon"
                      alt=""
                      src={lockunlocked04}
                    />
                  </div>
                </div>
              </div>
              <div className="archive-wanted-spare-arrow-square-up-right-wrapper">
                <img
                  className="archive-wanted-spare-frame-child1"
                  alt=""
                  src={arrowsquareupright}
                />
              </div>
            </div>
          </div>
        </div>


      ))
    }
    else {
      content = (

        <NoListingFound path={"/add-wanted-spare"} pathName={"Wanted Spare"} />


      )
    }
    // content = <Loader/>
  } else {
    return <Loader />
  }



  return (
    <div className="archive-wanted-spare-listings-search-results-list">
      <div className="archive-wanted-spare-listings-search-results-list-child"></div>


      <Navbar1 userData={currentUser} navigate={navigate} />



      <div className="archive-wanted-spare-filters">
        <div className="archive-wanted-spare-main">
          <div className="archive-wanted-spare-header-section">
            <div className="archive-wanted-spare-container">
              <div className="archive-wanted-spare-page-header">
                <div className="archive-wanted-spare-breadcrumbs">
                  <div className="archive-wanted-spare-tabs">
                    <div className="archive-wanted-spare-breadcrumb-button-base">
                      <img
                        className="archive-wanted-spare-search-lg-icon"
                        alt=""
                        src={homeline}
                      />
                    </div>
                    <img
                      className="archive-wanted-spare-chevron-right-icon"
                      alt=""
                      src={chevronright}
                    />

                    <div className="archive-wanted-spare-breadcrumb-button-base1">
                      <a className="archive-wanted-spare-text7">Dashboard</a>
                    </div>
                    <img
                      className="archive-wanted-spare-chevron-right-icon"
                      alt=""
                      src={chevronright}
                    />

                    <div className="archive-wanted-spare-breadcrumb-button-base2">
                      <div className="archive-wanted-spare-text8">...</div>
                    </div>
                    <img
                      className="archive-wanted-spare-chevron-right-icon2"
                      alt=""
                      src={chevronright}
                    />

                    <div className="archive-wanted-spare-breadcrumb-button-base3">
                      <div className="archive-wanted-spare-text9">Another link</div>
                    </div>
                    <img
                      className="archive-wanted-spare-chevron-right-icon2"
                      alt=""
                      src={chevronright}
                    />

                    <div className="archive-wanted-spare-breadcrumb-button-base4">
                      <a className="archive-wanted-spare-text10">Category</a>
                    </div>
                  </div>
                </div>
                <img className="archive-wanted-spare-divider-icon" alt="" src={{ divider }} />
              </div>
            </div>
          </div>
          <div className="archive-wanted-spare-main-child"></div>
          <div className="archive-wanted-spare-main-item"></div>
        </div>
        <div className="archive-wanted-spare-secondary-content">
          <div className="archive-wanted-spare-page-header1">
            <div className="archive-wanted-spare-breadcrumbs1">
              <div className="archive-wanted-spare-tabs1">
                <div className="archive-wanted-spare-breadcrumb-button-base">
                  <img
                    className="archive-wanted-spare-search-lg-icon"
                    alt=""
                    src={homeline}
                  />
                </div>
                <img
                  className="archive-wanted-spare-chevron-right-icon"
                  alt=""
                  src={chevronright}
                />

                <div className="archive-wanted-spare-breadcrumb-button-base6">
                  <div className="archive-wanted-spare-text11">Settings</div>
                </div>
                <img
                  className="archive-wanted-spare-chevron-right-icon"
                  alt=""
                  src={chevronright}
                />

                <div className="archive-wanted-spare-breadcrumb-button-base7">
                  <div className="archive-wanted-spare-text8">...</div>
                </div>
                <img
                  className="archive-wanted-spare-chevron-right-icon"
                  alt=""
                  src={chevronright}
                />

                <div className="archive-wanted-spare-breadcrumb-button-base3">
                  <div className="archive-wanted-spare-text9">Another link</div>
                </div>
                <img
                  className="archive-wanted-spare-chevron-right-icon2"
                  alt=""
                  src={chevronright}
                />

                <div className="archive-wanted-spare-breadcrumb-button-base4">
                  <div className="archive-wanted-spare-menu-label">Team</div>
                </div>
              </div>
            </div>
            <div className="archive-wanted-spare-content4">
              <div className="archive-wanted-spare-text-and-supporting-text">
                <div className="archive-wanted-spare-page-title">{dataLength} Listings Found</div>
                <div className="archive-wanted-spare-supporting-text">
                  Manage your team members and their account permissions here.
                </div>
              </div>
              <div className="archive-wanted-spare-input-dropdown">
                <div className="archive-wanted-spare-input-with-label1">
                  <div className="archive-wanted-spare-label1">Search</div>
                  <div className="archive-wanted-spare-input1">
                    <div className="archive-wanted-spare-content5">
                      <img
                        className="archive-wanted-spare-search-lg-icon"
                        alt=""
                        src={searchlg}
                      />

                      <div className="archive-wanted-spare-text15">Search</div>
                    </div>
                  </div>
                </div>
                <div className="archive-wanted-spare-hint-text">This is a hint text to help user.</div>
              </div>
            </div>
            <img className="archive-wanted-spare-divider-icon1" alt="" src={divider1} />
          </div>
        </div>
        <div className="archive-wanted-spare-button-group">
          <button className="archive-wanted-spare-button-group-base">
            <a className="archive-wanted-spare-text16">View all</a>
          </button>
          <div className="archive-wanted-spare-button-group-base1">
            <div className="archive-wanted-spare-text17">Monitored</div>
          </div>
          <div className="archive-wanted-spare-button-group-base2">
            <div className="archive-wanted-spare-menu-label">Text</div>
          </div>
          <div className="archive-wanted-spare-button-group-base3">
            <div className="archive-wanted-spare-menu-label">Text</div>
          </div>
          <div className="archive-wanted-spare-button-group-base4">
            <div className="archive-wanted-spare-menu-label">Text</div>
          </div>
          <div className="archive-wanted-spare-button-group-base5">
            <div className="archive-wanted-spare-menu-label">Text</div>
          </div>
          <div className="archive-wanted-spare-button-group-base6">
            <div className="archive-wanted-spare-menu-label">Text</div>
          </div>
          <div className="archive-wanted-spare-button-group-base7">
            <div className="archive-wanted-spare-menu-label">Text</div>
          </div>
          <div className="archive-wanted-spare-button-group-base1">
            <div className="archive-wanted-spare-text24">Unmonitored</div>
          </div>
          {/* <div className="archive-wanted-spare-breadcrumbs2">
          <div className="archive-wanted-spare-tabs2">
            <div className="archive-wanted-spare-breadcrumb-button-base">
              <img
                className="archive-wanted-spare-search-lg-icon"
                alt=""
                src={homeline}
              />
            </div>
            <div className="archive-wanted-spare-logo-wrapper">
              <input className="archive-wanted-spare-chevron-right" type="checkbox" />
            </div>
            <div className="archive-wanted-spare-breadcrumb-button-base11">
              <div className="archive-wanted-spare-text11">Settings</div>
            </div>
            <div className="archive-wanted-spare-logo-wrapper">
              <input className="archive-wanted-spare-chevron-right" type="checkbox" />
            </div>
            <div className="archive-wanted-spare-breadcrumb-button-base12">
              <div className="archive-wanted-spare-text8">...</div>
            </div>
            <div className="archive-wanted-spare-logo-wrapper">
              <img
                className="archive-wanted-spare-arrow-down-icon"
                alt=""
                src={chevronright}
              />
            </div>
            <div className="archive-wanted-spare-breadcrumb-button-base3">
              <div className="archive-wanted-spare-text9">Another link</div>
            </div>
            <img
              className="archive-wanted-spare-chevron-right-icon2"
              alt=""
              src={chevronright}
            />

            <div className="archive-wanted-spare-breadcrumb-button-base14">
              <div className="archive-wanted-spare-text28">Team</div>
            </div>
          </div>
        </div> */}
        </div>
        <div className="archive-wanted-spare-input-dropdown1">
          <div className="archive-wanted-spare-hint-text1">This is a hint text to help user.</div>
          <div className="archive-wanted-spare-buttonsbutton">
            <img className="archive-wanted-spare-sliders-02-icon" alt="" src={sliders02} />

            <div className="archive-wanted-spare-text-padding">
              <div className="archive-wanted-spare-menu-label">Sort By : Relevance</div>
              <img
                className="archive-wanted-spare-sliders-02-icon"
                alt=""
                src={chevrondown}
              />
            </div>
            <img
              className="archive-wanted-spare-placeholder-icon"
              alt=""
              src={placeholder}
            />
          </div>
        </div>
        <img className="archive-wanted-spare-divider-icon2" alt="" src={divider2} />

        <img className="archive-wanted-spare-divider-icon3" alt="" src={divider2} />

        <div className="archive-wanted-spare-supporting-text1">
          You searched based on the following criteria.
        </div>



        <div className="listings-container">

          {content}
        </div>


        <div className="archive-wanted-spare-buttonsbutton7">
          <img className="archive-wanted-spare-placeholder-icon" alt="" src={placeholder} />

          <a className="archive-wanted-spare-text47">All time</a>
          <img className="archive-wanted-spare-sliders-02-icon" alt="" src={xclose} />
        </div>
        <button className="archive-wanted-spare-buttonsbutton8">
          <img className="archive-wanted-spare-placeholder-icon" alt="" src={placeholder} />

          <div className="archive-wanted-spare-text48">Clinical Applications</div>
          <img className="archive-wanted-spare-sliders-02-icon" alt="" src={xclose} />
        </button>
        <div className="archive-wanted-spare-buttonsbutton9">
          <img className="archive-wanted-spare-placeholder-icon" alt="" src={placeholder} />

          <div className="archive-wanted-spare-text49">US, AU, +4</div>
          <img className="archive-wanted-spare-sliders-02-icon" alt="" src={xclose} />
        </div>
        <div className="archive-wanted-spare-buttonsbutton10">
          <img className="archive-wanted-spare-placeholder-icon" alt="" src={placeholder} />

          <div className="archive-wanted-spare-text50">All/Employee</div>
          <img className="archive-wanted-spare-sliders-02-icon" alt="" src={xclose} />
        </div>
        <button className="archive-wanted-spare-buttonsbutton11">
          <img className="archive-wanted-spare-placeholder-icon" alt="" src={placeholder} />

          <div className="archive-wanted-spare-text48">Clinical Applications</div>
          <img className="archive-wanted-spare-sliders-02-icon" alt="" src={xclose} />
        </button>
        <div className="archive-wanted-spare-buttonsbutton12">
          <img className="archive-wanted-spare-placeholder-icon" alt="" src={placeholder} />

          <div className="archive-wanted-spare-menu-label">Year of Manufacture</div>
          <img className="archive-wanted-spare-sliders-02-icon" alt="" src={xclose} />
        </div>
        <button className="archive-wanted-spare-buttonsbutton13">
          <img className="archive-wanted-spare-placeholder-icon" alt="" src={placeholder} />

          <div className="archive-wanted-spare-text53">Warrenty</div>
          <img className="archive-wanted-spare-sliders-02-icon" alt="" src={xclose} />
        </button>
        <button className="archive-wanted-spare-buttonsbutton14">
          <img className="archive-wanted-spare-placeholder-icon" alt="" src={placeholder} />

          <div className="archive-wanted-spare-text54">Manufacturer</div>
          <img className="archive-wanted-spare-sliders-02-icon" alt="" src={xclose} />
        </button>
        <button className="archive-wanted-spare-buttonsbutton15">
          <img className="archive-wanted-spare-placeholder-icon" alt="" src={placeholder} />

          <div className="archive-wanted-spare-text55">All Applications</div>
          <img className="archive-wanted-spare-sliders-02-icon" alt="" src={xclose} />
        </button>
        <button className="archive-wanted-spare-buttonsbutton16">
          <img className="archive-wanted-spare-placeholder-icon" alt="" src={placeholder} />

          <div className="archive-wanted-spare-text56">Rent/Lease</div>
          <img className="archive-wanted-spare-sliders-02-icon" alt="" src={xclose} />
        </button>
        <button className="archive-wanted-spare-buttonsbutton17">
          <img className="archive-wanted-spare-sliders-02-icon" alt="" src={filterlines} />

          <div className="archive-wanted-spare-text57">More filters</div>
          <img className="archive-wanted-spare-placeholder-icon" alt="" src={placeholder} />
        </button>
        <button className="archive-wanted-spare-buttonsbutton18">
          <img
            className="archive-wanted-spare-sliders-02-icon"
            alt=""
            src={filterfunnel01}
          />

          <div className="archive-wanted-spare-text58">Clear Filter</div>
          <img className="archive-wanted-spare-placeholder-icon" alt="" src={placeholder} />
        </button>
        <button className="archive-wanted-spare-actions">
          <div className="archive-wanted-spare-button">
            <img
              className="archive-wanted-spare-placeholder-icon"
              alt=""
              src={placeholder1}
            />

            <div className="archive-wanted-spare-text-padding1">
              <div className="archive-wanted-spare-text59">Tertiary</div>
            </div>
            <img
              className="archive-wanted-spare-placeholder-icon"
              alt=""
              src={placeholder1}
            />
          </div>
          <div className="archive-wanted-spare-button1">
            <img
              className="archive-wanted-spare-placeholder-icon"
              alt=""
              src={placeholder2}
            />

            <div className="archive-wanted-spare-text-padding1">
              <div className="archive-wanted-spare-text60">Secondary</div>
            </div>
            <img
              className="archive-wanted-spare-placeholder-icon"
              alt=""
              src={placeholder2}
            />
          </div>
          <div className="archive-wanted-spare-button2">
            <img
              className="archive-wanted-spare-sliders-02-icon"
              alt=""
              src={filterlines}
            />

            <div className="archive-wanted-spare-text-padding">
              <div className="archive-wanted-spare-filter-label">Save Filter</div>
            </div>
            <img
              className="archive-wanted-spare-placeholder-icon"
              alt=""
              src={placeholder3}
            />
          </div>
        </button>
      </div>

      <img className="archive-wanted-spare-divider-icon6" alt="" src={divider4} />

      <img className="archive-wanted-spare-divider-icon7" alt="" src={divider4} />


      {/* <div className="archive-wanted-spare-slide-out">
      <img className="archive-wanted-spare-divider-icon14" alt="" src="/archive_page/divider-12.svg" />

      <div className="archive-wanted-spare-rectangle-parent4">
        <div className="archive-wanted-spare-frame-child8"></div>
        <div className="archive-wanted-spare-frame-child9"></div>
        <img className="archive-wanted-spare-dot-icon7" alt="" src="/archive_page/-dot.svg" />

        <img className="archive-wanted-spare-dot-icon8" alt="" src="/archive_page/-dot.svg" />

        <img className="archive-wanted-spare-dot-icon9" alt="" src="/archive_page/-dot.svg" />

        <img className="archive-wanted-spare-dot-icon10" alt="" src="/archive_page/-dot.svg" />

        <img className="archive-wanted-spare-dot-icon11" alt="" src="/archive_page/-dot.svg" />

        <img className="archive-wanted-spare-dot-icon12" alt="" src="/archive_page/-dot.svg" />

        <img className="archive-wanted-spare-dot-icon13" alt="" src="/archive_page/-dot.svg" />

        <div className="archive-wanted-spare-frame-parent28">
          <div className="archive-wanted-spare-slide-out-menu-header-parent">
            <div className="archive-wanted-spare-slide-out-menu-header">
              <div className="archive-wanted-spare-content24">
                <img
                  className="archive-wanted-spare-featured-icon1"
                  alt=""
                  src="/archive_page/featured-icon1.svg"
                />

                <div className="archive-wanted-spare-text-and-supporting-text6">
                  <a className="archive-wanted-spare-text184">Filters</a>
                  <div className="archive-wanted-spare-supporting-text57">
                    Apply filters to table data.
                  </div>
                </div>
              </div>
              <div className="archive-wanted-spare-button-close-x">
                <img
                  className="archive-wanted-spare-search-lg-icon"
                  alt=""
                  src="/archive_page/xclose-10.svg"
                />
              </div>
            </div>
            <div className="archive-wanted-spare-frame-wrapper13">
              <div className="archive-wanted-spare-slide-out-title-parent">
                <a className="archive-wanted-spare-slide-out-title">Price</a>
                <div className="archive-wanted-spare-navigation">
                  <img
                    className="archive-wanted-spare-marker-pin-02-icon2"
                    alt=""
                    src={chevrondown}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="archive-wanted-spare-frame-parent29">
            <div className="archive-wanted-spare-frame-parent30">
              <div className="archive-wanted-spare-frame-wrapper14">
                <div className="archive-wanted-spare-frame-parent31">
                  <div className="archive-wanted-spare-background-parent">
                    <div className="archive-wanted-spare-background"></div>
                    <div className="archive-wanted-spare-progress">
                      <div className="archive-wanted-spare-progress-line"></div>
                      <div className="archive-wanted-spare-control-handle">
                        <div className="archive-wanted-spare-tooltip1">
                          <div className="archive-wanted-spare-content25">
                            <div className="archive-wanted-spare-text185">0</div>
                          </div>
                        </div>
                        <div className="archive-wanted-spare-handle"></div>
                      </div>
                      <div className="archive-wanted-spare-control-handle">
                        <div className="archive-wanted-spare-tooltip2">
                          <div className="archive-wanted-spare-content26">
                            <div className="archive-wanted-spare-text186">9,999,999,99</div>
                          </div>
                        </div>
                        <div className="archive-wanted-spare-handle"></div>
                      </div>
                    </div>
                  </div>
                  <div className="archive-wanted-spare-input-fields-parent">
                    <div className="archive-wanted-spare-input-fields">
                      <div className="archive-wanted-spare-input-field">
                        <div className="archive-wanted-spare-input-with-label4">
                          <div className="archive-wanted-spare-label5">Email</div>
                          <div className="archive-wanted-spare-input4">
                            <div className="archive-wanted-spare-content27">
                              <div className="archive-wanted-spare-text187">Min Value</div>
                            </div>
                            <img
                              className="archive-wanted-spare-help-icon1"
                              alt=""
                              src="/archive_page/help-icon1.svg"
                            />
                          </div>
                        </div>
                        <div className="archive-wanted-spare-hint-text">
                          This is a hint text to help user.
                        </div>
                      </div>
                      <div className="archive-wanted-spare-text188">–</div>
                      <div className="archive-wanted-spare-input-field">
                        <div className="archive-wanted-spare-input-with-label4">
                          <div className="archive-wanted-spare-label5">Email</div>
                          <div className="archive-wanted-spare-input4">
                            <div className="archive-wanted-spare-content27">
                              <div className="archive-wanted-spare-text187">Max Value</div>
                            </div>
                            <img
                              className="archive-wanted-spare-help-icon1"
                              alt=""
                              src="/archive_page/help-icon1.svg"
                            />
                          </div>
                        </div>
                        <div className="archive-wanted-spare-hint-text">
                          This is a hint text to help user.
                        </div>
                      </div>
                    </div>
                    <div className="archive-wanted-spare-checkboxes">
                      <div className="archive-wanted-spare-collapsed-names">
                        <input className="archive-wanted-spare-input6" type="checkbox" />

                        <div className="archive-wanted-spare-price-parent">
                          <div className="archive-wanted-spare-text8">US$ 0 — US$ 1,000</div>
                          <div className="archive-wanted-spare-hint-text">
                            Save my login details for next time.
                          </div>
                        </div>
                      </div>
                      <div className="archive-wanted-spare-collapsed-names">
                        <input className="archive-wanted-spare-input6" type="checkbox" />

                        <div className="archive-wanted-spare-price-parent">
                          <div className="archive-wanted-spare-text8">US$ 1,001 — US$ 5,000</div>
                          <div className="archive-wanted-spare-hint-text">
                            Save my login details for next time.
                          </div>
                        </div>
                      </div>
                      <div className="archive-wanted-spare-collapsed-names">
                        <input className="archive-wanted-spare-input6" type="checkbox" />

                        <div className="archive-wanted-spare-price-parent">
                          <div className="archive-wanted-spare-text8">US$ 5,001 — US$ 15,000</div>
                          <div className="archive-wanted-spare-hint-text">
                            Save my login details for next time.
                          </div>
                        </div>
                      </div>
                      <div className="archive-wanted-spare-collapsed-names">
                        <input className="archive-wanted-spare-input6" type="checkbox" />

                        <div className="archive-wanted-spare-price-parent">
                          <div className="archive-wanted-spare-text8">US$ 15,001 — US$ 9,999,999</div>
                          <div className="archive-wanted-spare-hint-text">
                            Save my login details for next time.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="archive-wanted-spare-frame-child10"></div>
            </div>
            <img
              className="archive-wanted-spare-divider-icon15"
              alt=""
              src="/archive_page/divider-13.svg"
            />

            <div className="archive-wanted-spare-section-wrapper">
              <div className="archive-wanted-spare-section-parent">
                <div className="archive-wanted-spare-section">
                  <div className="archive-wanted-spare-content-parent">
                    <div className="archive-wanted-spare-content29">
                      <img className="archive-wanted-spare-dot-icon" alt="" src="/archive_page/-dot.svg" />

                      <div className="archive-wanted-spare-text194">Date Posted</div>
                    </div>
                    <img
                      className="archive-wanted-spare-search-lg-icon"
                      alt=""
                      src={chevrondown}
                    />
                  </div>
                </div>
                <div className="archive-wanted-spare-input-fields">
                  <div className="archive-wanted-spare-input-field2">
                    <div className="archive-wanted-spare-input-with-label4">
                      <div className="archive-wanted-spare-label5">Email</div>
                      <div className="archive-wanted-spare-input10">
                        <div className="archive-wanted-spare-content27">
                          <div className="archive-wanted-spare-text187">Jan 6, 2024</div>
                        </div>
                        <img
                          className="archive-wanted-spare-help-icon1"
                          alt=""
                          src="/archive_page/help-icon2.svg"
                        />
                      </div>
                    </div>
                    <div className="archive-wanted-spare-hint-text">
                      This is a hint text to help user.
                    </div>
                  </div>
                  <div className="archive-wanted-spare-text188">–</div>
                  <div className="archive-wanted-spare-input-field2">
                    <div className="archive-wanted-spare-input-with-label4">
                      <div className="archive-wanted-spare-label5">Email</div>
                      <div className="archive-wanted-spare-input10">
                        <div className="archive-wanted-spare-content27">
                          <div className="archive-wanted-spare-text187">Jan 12, 2024</div>
                        </div>
                        <img
                          className="archive-wanted-spare-help-icon1"
                          alt=""
                          src="/archive_page/help-icon2.svg"
                        />
                      </div>
                    </div>
                    <div className="archive-wanted-spare-hint-text">
                      This is a hint text to help user.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img
              className="archive-wanted-spare-divider-icon15"
              alt=""
              src="/archive_page/divider-13.svg"
            />

            <div className="archive-wanted-spare-section-wrapper">
              <div className="archive-wanted-spare-section1">
                <div className="archive-wanted-spare-content-parent">
                  <div className="archive-wanted-spare-content29">
                    <img className="archive-wanted-spare-dot-icon" alt="" src="/archive_page/-dot.svg" />

                    <div className="archive-wanted-spare-text198">Seller Role</div>
                  </div>
                  <img
                    className="archive-wanted-spare-search-lg-icon"
                    alt=""
                    src={chevrondown}
                  />
                </div>
                <div className="archive-wanted-spare-checkboxes1">
                  <div className="archive-wanted-spare-collapsed-names">
                    <input className="archive-wanted-spare-input6" type="checkbox" />

                    <div className="archive-wanted-spare-price-parent">
                      <div className="archive-wanted-spare-text8">Dealer/Distributor</div>
                      <div className="archive-wanted-spare-hint-text">
                        Save my login details for next time.
                      </div>
                    </div>
                  </div>
                  <div className="archive-wanted-spare-collapsed-names">
                    <input className="archive-wanted-spare-input6" type="checkbox" />

                    <div className="archive-wanted-spare-price-parent">
                      <div className="archive-wanted-spare-text8">Manufacturer</div>
                      <div className="archive-wanted-spare-hint-text">
                        Save my login details for next time.
                      </div>
                    </div>
                  </div>
                  <div className="archive-wanted-spare-collapsed-names">
                    <input className="archive-wanted-spare-input6" type="checkbox" />

                    <div className="archive-wanted-spare-price-parent">
                      <div className="archive-wanted-spare-text8">Owner/Employee</div>
                      <div className="archive-wanted-spare-hint-text">
                        Save my login details for next time.
                      </div>
                    </div>
                  </div>
                  <div className="archive-wanted-spare-collapsed-names">
                    <input className="archive-wanted-spare-input6" type="checkbox" />

                    <div className="archive-wanted-spare-price-parent">
                      <div className="archive-wanted-spare-text8">Third Party/Agent</div>
                      <div className="archive-wanted-spare-hint-text">
                        Save my login details for next time.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img
              className="archive-wanted-spare-divider-icon15"
              alt=""
              src="/archive_page/divider-13.svg"
            />

            <div className="archive-wanted-spare-section-wrapper">
              <div className="archive-wanted-spare-section1">
                <div className="archive-wanted-spare-content-parent">
                  <div className="archive-wanted-spare-content29">
                    <img className="archive-wanted-spare-dot-icon" alt="" src="/archive_page/-dot.svg" />

                    <div className="archive-wanted-spare-text203">Transaction Type</div>
                  </div>
                  <img
                    className="archive-wanted-spare-search-lg-icon"
                    alt=""
                    src={chevrondown}
                  />
                </div>
                <div className="archive-wanted-spare-checkboxes1">
                  <div className="archive-wanted-spare-collapsed-names">
                    <input className="archive-wanted-spare-input6" type="checkbox" />

                    <div className="archive-wanted-spare-price-parent">
                      <div className="archive-wanted-spare-text8">Exchange</div>
                      <div className="archive-wanted-spare-hint-text">
                        Save my login details for next time.
                      </div>
                    </div>
                  </div>
                  <div className="archive-wanted-spare-collapsed-names">
                    <input className="archive-wanted-spare-input6" type="checkbox" />

                    <div className="archive-wanted-spare-price-parent">
                      <div className="archive-wanted-spare-text8">Rent/Lease</div>
                      <div className="archive-wanted-spare-hint-text">
                        Save my login details for next time.
                      </div>
                    </div>
                  </div>
                  <div className="archive-wanted-spare-collapsed-names">
                    <input className="archive-wanted-spare-input6" type="checkbox" />

                    <div className="archive-wanted-spare-price-parent">
                      <div className="archive-wanted-spare-text8">Sale</div>
                      <div className="archive-wanted-spare-hint-text">
                        Save my login details for next time.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img
              className="archive-wanted-spare-divider-icon15"
              alt=""
              src="/archive_page/divider-13.svg"
            />

            <div className="archive-wanted-spare-section-wrapper">
              <div className="archive-wanted-spare-section3">
                <div className="archive-wanted-spare-content-parent">
                  <div className="archive-wanted-spare-content29">
                    <img className="archive-wanted-spare-dot-icon" alt="" src="/archive_page/-dot.svg" />

                    <div className="archive-wanted-spare-text207">Status</div>
                  </div>
                  <img
                    className="archive-wanted-spare-search-lg-icon"
                    alt=""
                    src={chevrondown}
                  />
                </div>
              </div>
            </div>
            <img
              className="archive-wanted-spare-divider-icon15"
              alt=""
              src="/archive_page/divider-13.svg"
            />

            <div className="archive-wanted-spare-section-wrapper">
              <div className="archive-wanted-spare-section3">
                <div className="archive-wanted-spare-content-parent">
                  <div className="archive-wanted-spare-content29">
                    <img className="archive-wanted-spare-dot-icon" alt="" src="/archive_page/-dot.svg" />

                    <div className="archive-wanted-spare-text208">Warranty</div>
                  </div>
                  <img
                    className="archive-wanted-spare-search-lg-icon"
                    alt=""
                    src={chevrondown}
                  />
                </div>
              </div>
            </div>
            <img
              className="archive-wanted-spare-divider-icon15"
              alt=""
              src="/archive_page/divider-13.svg"
            />

            <div className="archive-wanted-spare-section-wrapper">
              <div className="archive-wanted-spare-section3">
                <div className="archive-wanted-spare-content-parent">
                  <div className="archive-wanted-spare-content29">
                    <img className="archive-wanted-spare-dot-icon" alt="" src="/archive_page/-dot.svg" />

                    <div className="archive-wanted-spare-text209">Shipping</div>
                  </div>
                  <img
                    className="archive-wanted-spare-search-lg-icon"
                    alt=""
                    src={chevrondown}
                  />
                </div>
              </div>
            </div>
            <img
              className="archive-wanted-spare-divider-icon15"
              alt=""
              src="/archive_page/divider-13.svg"
            />

            <div className="archive-wanted-spare-section-wrapper">
              <div className="archive-wanted-spare-section-parent">
                <div className="archive-wanted-spare-actions6">
                  <div className="archive-wanted-spare-navigation">
                    <input className="archive-wanted-spare-search-lg" type="radio" />
                  </div>
                  <div className="archive-wanted-spare-text210">Country</div>
                </div>
                <div className="archive-wanted-spare-input-fields2">
                  <div className="archive-wanted-spare-input-field2">
                    <div className="archive-wanted-spare-input-with-label4">
                      <div className="archive-wanted-spare-label5">Email</div>
                      <div className="archive-wanted-spare-input19">
                        <input
                          className="archive-wanted-spare-content37"
                          placeholder="Search"
                          type="text"
                        />

                        <img
                          className="archive-wanted-spare-help-icon1"
                          alt=""
                          src="/archive_page/help-icon.svg"
                        />
                      </div>
                    </div>
                    <div className="archive-wanted-spare-hint-text">
                      This is a hint text to help user.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="archive-wanted-spare-second-badge-divider">
              <img
                className="archive-wanted-spare-divider-icon22"
                alt=""
                src="/archive_page/divider-13.svg"
              />

              <img
                className="archive-wanted-spare-divider-icon23"
                alt=""
                src="/archive_page/divider-21.svg"
              />
            </div>
            <div className="archive-wanted-spare-frame-wrapper17">
              <div className="archive-wanted-spare-actions6">
                <div className="archive-wanted-spare-navigation">
                  <input className="archive-wanted-spare-search-lg" type="radio" />
                </div>
                <div className="archive-wanted-spare-text211">Spare Category</div>
              </div>
            </div>
            <div className="archive-wanted-spare-second-badge-divider">
              <img
                className="archive-wanted-spare-divider-icon22"
                alt=""
                src="/archive_page/divider-13.svg"
              />

              <img
                className="archive-wanted-spare-divider-icon23"
                alt=""
                src="/archive_page/divider-21.svg"
              />
            </div>
            <div className="archive-wanted-spare-frame-wrapper17">
              <div className="archive-wanted-spare-actions6">
                <div className="archive-wanted-spare-navigation">
                  <input className="archive-wanted-spare-search-lg" type="radio" />
                </div>
                <div className="archive-wanted-spare-text212">OEM Brand</div>
              </div>
            </div>
            <div className="archive-wanted-spare-frame-parent36">
              <div className="archive-wanted-spare-second-badge-divider">
                <img
                  className="archive-wanted-spare-divider-icon22"
                  alt=""
                  src="/archive_page/divider-13.svg"
                />

                <img
                  className="archive-wanted-spare-divider-icon23"
                  alt=""
                  src="/archive_page/divider-21.svg"
                />
              </div>
              <div className="archive-wanted-spare-frame-wrapper19">
                <div className="archive-wanted-spare-frame-parent37">
                  <div className="archive-wanted-spare-navigation">
                    <input className="archive-wanted-spare-search-lg" type="radio" />
                  </div>
                  <div className="archive-wanted-spare-text213">Year of Manufacture</div>
                </div>
              </div>
              <div className="archive-wanted-spare-second-badge-divider">
                <img
                  className="archive-wanted-spare-divider-icon22"
                  alt=""
                  src="/archive_page/divider-13.svg"
                />

                <img
                  className="archive-wanted-spare-divider-icon23"
                  alt=""
                  src="/archive_page/divider-21.svg"
                />
              </div>
              <div className="archive-wanted-spare-frame-wrapper19">
                <div className="archive-wanted-spare-actions6">
                  <div className="archive-wanted-spare-navigation">
                    <input className="archive-wanted-spare-search-lg" type="radio" />
                  </div>
                  <div className="archive-wanted-spare-text213">Clinical Applications</div>
                </div>
              </div>
            </div>
            <div className="archive-wanted-spare-card-header-parent">
              <img
                className="archive-wanted-spare-divider-icon15"
                alt=""
                src="/archive_page/divider-13.svg"
              />

              <img
                className="archive-wanted-spare-divider-icon31"
                alt=""
                src="/archive_page/divider-21.svg"
              />
            </div>
            <div className="archive-wanted-spare-frame-wrapper17">
              <div className="archive-wanted-spare-actions6">
                <div className="archive-wanted-spare-navigation">
                  <input className="archive-wanted-spare-search-lg" type="radio" />
                </div>
                <div className="archive-wanted-spare-text212">Spare Use</div>
              </div>
            </div>
            <div className="archive-wanted-spare-second-badge-divider">
              <img
                className="archive-wanted-spare-divider-icon22"
                alt=""
                src="/archive_page/divider-13.svg"
              />

              <img
                className="archive-wanted-spare-divider-icon23"
                alt=""
                src="/archive_page/divider-21.svg"
              />
            </div>
            <div className="archive-wanted-spare-frame-wrapper17">
              <div className="archive-wanted-spare-frame-parent37">
                <div className="archive-wanted-spare-navigation">
                  <input className="archive-wanted-spare-search-lg" type="radio" />
                </div>
                <div className="archive-wanted-spare-text216">Physical Location</div>
              </div>
            </div>
            <img
              className="archive-wanted-spare-divider-icon15"
              alt=""
              src="/archive_page/divider-13.svg"
            />
          </div>
        </div>
        <img className="archive-wanted-spare-dot-icon20" alt="" src="/archive_page/-dot.svg" />

        <div className="archive-wanted-spare-frame-parent41">
          <div className="archive-wanted-spare-divider-parent6">
            <img
              className="archive-wanted-spare-divider-icon35"
              alt=""
              src="/archive_page/divider-13.svg"
            />

            <div className="archive-wanted-spare-footer1">
              <div className="archive-wanted-spare-content38">
                <div className="archive-wanted-spare-logo1">
                  <div className="archive-wanted-spare-buttonsbutton37">
                    <img
                      className="archive-wanted-spare-placeholder-icon"
                      alt=""
                      src={placeholder2}
                    />

                    <div className="archive-wanted-spare-text217">Save filter</div>
                    <img
                      className="archive-wanted-spare-placeholder-icon"
                      alt=""
                      src={placeholder2}
                    />
                  </div>
                </div>
                <div className="archive-wanted-spare-actions9">
                  <button className="archive-wanted-spare-buttonsbutton38">
                    <img
                      className="archive-wanted-spare-placeholder-icon"
                      alt=""
                      src={placeholder}
                    />

                    <div className="archive-wanted-spare-text-padding">
                      <div className="archive-wanted-spare-text218">Cancel</div>
                    </div>
                    <img
                      className="archive-wanted-spare-placeholder-icon"
                      alt=""
                      src={placeholder}
                    />
                  </button>
                  <button className="archive-wanted-spare-buttonsbutton39">
                    <img
                      className="archive-wanted-spare-placeholder-icon"
                      alt=""
                      src={placeholder3}
                    />

                    <div className="archive-wanted-spare-text-padding">
                      <div className="archive-wanted-spare-text219">Apply</div>
                    </div>
                    <img
                      className="archive-wanted-spare-placeholder-icon"
                      alt=""
                      src={placeholder3}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="archive-wanted-spare-dot-item-innermost">
            <div className="archive-wanted-spare-collapsed-content">
              <img
                className="archive-wanted-spare-divider-icon22"
                alt=""
                src="/archive_page/divider-13.svg"
              />

              <img
                className="archive-wanted-spare-divider-icon37"
                alt=""
                src="/archive_page/divider-21.svg"
              />
            </div>
          </div>
        </div>
      </div>
    
    </div> */}
      <FooterAddForms />




    </div>


  )
}

