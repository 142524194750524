
import { Funnel } from "../screens/Funnel/Funnel";
import { FunnelDeviceDetailPage } from "../screens/FunnelDetailsPage/FunnelDeviceDetailPage/FunnelDeviceDetailPage";
import { FunnelJobDetailPage } from "../screens/FunnelDetailsPage/FunnelJobDetailPage/FunnelJobDetailPage";
import { FunnelServiceDetailPage } from "../screens/FunnelDetailsPage/FunnelServiceDetailPage/FunnelServiceDetailPage";
import { FunnelSoftwareDetailPage } from "../screens/FunnelDetailsPage/FunnelSoftwareDetailPage/FunnelSoftwareDetailPage";
import { FunnelSpareDetailPage } from "../screens/FunnelDetailsPage/FunnelSpareDetailPage/FunnelSpareDetailPage";




export const funnelRoutes = [

  {
    path: "/funnel",
    element: <Funnel />
  },
  {
    path: "/funnel/device/:id/*",
    element: <FunnelDeviceDetailPage />
  },
  {
    path: "/funnel/spare/:id/*",
    element: <FunnelSpareDetailPage />
  },
  {
    path: "/funnel/service/:id/*",
    element: <FunnelServiceDetailPage />
  },
  {
    path: "/funnel/software/:id/*",
    element: <FunnelSoftwareDetailPage />
  },
  {
    path: "/funnel/job/:id/*",
    element: <FunnelJobDetailPage />
  },

];
