// import { useEffect, useState } from "react";
// import { useGetAvailabilityTaxQuery, useGetClinicalApplicationsTaxQuery, useGetPhysicalLocationTaxQuery, useGetPurposeUseTaxQuery, useGetShippingTaxQuery, useGetStatusConditionTaxQuery, useGetTransactionTypeTaxQuery, useGetUnitOfMeasureDeviceSpareTaxQuery, useGetWarrantyTaxQuery, useGetYearOfManufactureTaxQuery, useGetYourRoleTaxQuery } from "../api/TaxonomyFormAPI";

import { encryptData } from "./AESHelper";

export function objectToFormDataAddService(obj, featureImage, bannerImage, documentMultiUploadStatus, currentUsermeta) {
  const formData = new FormData();

  // Destructure to remove file fields from obj
  const { document: _, bannerImage: __, featureImage: ___, imageDocCheckbox: ____, ...values } = obj;

  values.rangeMax = values.rangeMax.replace(/,/g, '');
  values.rangeMin = values.rangeMin.replace(/,/g, '');

  values.businessId = currentUsermeta.userBusinessId;

  values.userId = currentUsermeta.userId;

  values.businessId = currentUsermeta.userBusinessId;

  values.userId = currentUsermeta.userId;

  values.document = documentMultiUploadStatus.map(item => item.postId);

  console.log(values);
  // Encrypt the object without the document
  const encryptedValue = encryptData(values); // Custom encryptData function

  // Append the encrypted text data
  formData.append("encryptedData", encryptedValue);

  // Append featureImage if it's a valid File
  if (featureImage instanceof File) {
    formData.append("featureImageObject", featureImage);
  }

  // Append bannerImage if it's a valid File
  if (bannerImage instanceof File) {
    formData.append("bannerImageObject", bannerImage);
  }

  // // Append document if it's a valid File
  // if (document instanceof File) {
  //   formData.append("documentFileObject", document);
  // }

  return formData;
}



export function objectToFormDataEditService(obj, documentFileRM, featureImage, bannerImage, documentMultiUploadStatus) {
  const formData = new FormData();

  // Destructure to remove document, bannerImage, and featureImage from obj
  const { document: _, bannerImage: __, featureImage: ___, services_files, tax_device_category, feature_image, banner_image,
    document_file, documentFile, imageCheckbox, imageDocCheckbox, ...values } = obj;

  // Appending field if document was removed from form
  if (documentFileRM !== "" && documentFileRM !== undefined) {
    values.documentFileRM = documentFileRM; // Add documentFileRM to values
  }

  values.rangeMax = values.rangeMax.replace(/,/g, '');
  values.rangeMin = values.rangeMin.replace(/,/g, '');

  values.document = documentMultiUploadStatus
    .map(item => Number(item.postId)) // Convert each postId to a number
    .filter(postId => !isNaN(postId)); // Filter out non-numeric values

  if (documentFileRM !== "" && documentFileRM !== undefined) {
    values.documentFileRM = documentFileRM; // Add documentFileRM to values
  }

  console.log(values);

  // Encrypt the object without the document
  const encryptedValue = encryptData(values); // Custom encryptData function

  // Append the encrypted text data to formData
  formData.append("encryptedData", encryptedValue);

  // Appending featureImage to formData
  if (featureImage instanceof File) {
    formData.append("featureImageObject", featureImage);
  }

  // Appending bannerImage to formData
  if (bannerImage instanceof File) {
    formData.append("bannerImageObject", bannerImage);
  }

  return formData;
}



export function getCustomizedDate(sqlDate) {
  // const date = ; 
  const formattedDate = new Date(sqlDate).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return formattedDate;
}

export function getAllTaxonomyData() {


  return allTaxonomy;
}


export function isValidURL(urlString) {
  try {
    new URL(urlString);
    return true;
  } catch (_) {
    return false;
  }
}

export function isAllURLsValid(urlArray) {
  for (const url of urlArray) {
    if (!isValidURL(url)) {
      return false; // If even one URL is invalid, return false
    }
  }
  return true; // All URLs were valid
}