import React from "react";
import "../../Sidebar/Sidebar.css";
import arrownarrowleft from "../../../icons/Gerator/dashboard/arrownarrowleft.svg";
import pluscircle_gray from "../../../icons/Gerator/dashboard/pluscircle_gray.svg";
import address from "../../../icons/Gerator/dashboard/address.svg";
import business from "../../../icons/Gerator/dashboard/business.svg";
import plan from "../../../icons/Gerator/dashboard/plan.svg";
import logout01 from "../../../icons/Gerator/dashboard/logout01.svg";
import { Link } from "react-router-dom";
import TruncatedTextTooltip from "../../TruncatedTextTooltip/TruncatedTextTooltip";


export const SideBarSubNormal = ({ userData, isSubSideBarVisible, setSubSideBarVisible }) => {
  return (
    // <div className="sidebar-container">
    <>
      {isSubSideBarVisible ? (
        // Sidebar content
        <div className="sidebar-sub-nav">
          <div className="sidebar-content5">
            <div className="sidebar-sub-nav-header">
              <div className="sidebar-sub-nav-title">
                <Link to={"/dashboard"}>Dashboard</Link>
              </div>
              {/* Toggle Sidebar Visibility */}
              <img
                className="sidebar-arrow-narrow-left-icon"
                onClick={() => setSubSideBarVisible(!isSubSideBarVisible)}
                alt="Toggle Sidebar"
                src={arrownarrowleft}
              />
            </div>
            <div className="sidebar-navigation4">
              {/* Add Device */}
              <div className="sidebar-nav-item-base4">
                <Link to={"/add-device"} style={{ color: "#475467" }}>
                  <div className="sidebar-content6">
                    <img className="sidebar-dot-icon" alt="" src="/dashboard/-dot.svg" />
                    <img className="sidebar-arrow-narrow-left-icon" alt="" src={pluscircle_gray} />
                    <div className="sidebar-text6">Add Device</div>
                  </div>
                </Link>
              </div>
              {/* Add Spare */}
              <div className="sidebar-nav-item-base4">
                <Link to={"/add-spare"} style={{ color: "#475467" }}>
                  <div className="sidebar-content7">
                    <img className="sidebar-dot-icon" alt="" src="/dashboard/-dot.svg" />
                    <img className="sidebar-arrow-narrow-left-icon" alt="" src={pluscircle_gray} />
                    <div className="sidebar-text8">Add Spare</div>
                  </div>
                </Link>
              </div>
              {/* Add Service */}
              <div className="sidebar-nav-item-base4">
                <Link to={"/add-service"} style={{ color: "#475467" }}>
                  <div className="sidebar-content8">
                    <img className="sidebar-dot-icon" alt="" src="/dashboard/-dot.svg" />
                    <img className="sidebar-arrow-narrow-left-icon" alt="" src={pluscircle_gray} />
                    <div className="sidebar-text8">Add Service</div>
                  </div>
                </Link>
              </div>
              {/* Add Job */}
              <div className="sidebar-nav-item-base4">
                <Link to={"/add-job"} style={{ color: "#475467" }}>
                  <div className="sidebar-content8">
                    <img className="sidebar-dot-icon" alt="" src="/dashboard/-dot.svg" />
                    <img className="sidebar-arrow-narrow-left-icon" alt="" src={pluscircle_gray} />
                    <div className="sidebar-text8">Add Job</div>
                  </div>
                </Link>
              </div>
              {/* Business */}
              <div className="sidebar-nav-item-base4">
                <Link to={"/dashboard/settings/business"} style={{ color: "#475467" }}>
                  <div className="sidebar-content8">
                    <img className="sidebar-dot-icon" alt="" src="/dashboard/-dot.svg" />
                    <img className="sidebar-arrow-narrow-left-icon" alt="" src={business} />
                    <div className="sidebar-text8">Business</div>
                  </div>
                </Link>
              </div>
              {/* Address */}
              <div className="sidebar-nav-item-base4">
                <Link to={"/dashboard/settings/address"} style={{ color: "#475467" }}>
                  <div className="sidebar-content12">
                    <img className="sidebar-dot-icon" alt="" src="/dashboard/-dot.svg" />
                    <img className="sidebar-arrow-narrow-left-icon" alt="" src={address} />
                    <div className="sidebar-text17">Address</div>
                  </div>
                </Link>
              </div>
              {/* Upgrade Plan */}
              <div className="sidebar-nav-item-base4">
                <Link to={"/dashboard/settings/plan"} style={{ color: "#475467" }}>
                  <div className="sidebar-content12">
                    <img className="sidebar-dot-icon" alt="" src="/dashboard/-dot.svg" />
                    <img className="sidebar-arrow-narrow-left-icon" alt="" src={plan} />
                    <div className="sidebar-text17">Upgrade Plan</div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          {/* User Account Section */}
          <div className="sidebar-account">
            <Link to={"/dashboard/settings/profile"}>
              <div className="sidebar-price-parent">
                <div className="sidebar-title">
                  {/* {userData?.displayName ? userData.displayName : "Demo User"} */}
                  <TruncatedTextTooltip 
                                  text={userData?.displayName ? userData.displayName : "Demo User"}
              />
                </div>
                <div className="sidebar-supporting-text">
                  {/* {userData?.email ? userData.email : "demo@gerator.com"} */}
                  <TruncatedTextTooltip 
                                  text={userData?.email ? userData.email : "demo@gerator.com"}

              />
                </div>
              </div>
            </Link>
            <Link to={"/dashboard/settings/profile"}>
              <div className="sidebar-buttonsbutton">
                <img className="sidebar-search-lg-icon" alt="Logout" src={logout01} />
              </div>
            </Link>
          </div>
        </div>
      ) : (
        // Display ChatPanel when Sidebar is hidden
        <ChatPanel />
      )}
    {/* </div> */}
    </>
  );
};
