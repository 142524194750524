import React, { useEffect, useState, useRef } from "react";
// import { Provider } from "../../../../../screens/Admin/AdminEditDevice/AdminEditDeviceContext";
import { Provider } from "../../../../../screens/Admin/AdminEditSoftware/AdminEditSoftwareContext";

import { useNavigate, useParams } from "react-router-dom";
// import { clearEditSoftwareForm, updateEditSoftwareField } from "../EditSoftwareFormComponent/EditSoftwareSlice";
// import { updateAppDataEditSoftware, clearAppDataEditSoftware } from "../EditSoftwareFormComponent/AppDataEditSoftwareSlice";
import { clearAdminEditSoftwareForm, updateAdminEditSoftwareField } from "./AdminEditSoftwareSlice";
import { updateAppDataAdminEditSoftware, clearAppDataAdminEditSoftware } from "./AdminAppDataEditSoftwareSlice";
import { useGetClinicalApplicationsTaxQuery, useGetDeviceCategoryTaxQuery, useGetInstallationLocationTaxQuery, useGetPhysicalLocationTaxQuery, useGetPurposeUseTaxQuery, useGetSoftwareCategoryTaxQuery, useGetSoftwareTypeTaxQuery, useGetUnitOfMeasureSoftwareTaxQuery, useGetServiceandSupportReachTaxQuery } from "../../../../../api/TaxonomyFormAPI";
// import { getAllTaxonomyData } from "../../helper/EditSoftwareHelper";
import { EditSoftwareStep1 } from "./EditSoftwareStep1";
import { EditSoftwareStep2 } from "./EditSoftwareStep2";
import { EditSoftwareStep3 } from "./EditSoftwareStep3";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useEditAdminSoftwareMutation } from "../../../../../api/admin/AdminSoftwareAPI";
import { isValidURL, objectToFormDataForEditSoftware } from "../../../../../helper/SoftwareHelper";
import { decryptErrorData } from "../../../../../helper/AESHelper";
// import { useFindGetRelatedAddressRemoteQuery } from "../../../../../api/AddressAPI";
import { useFindGetAdminRelatedAddressNoRemoteQuery } from "../../../../../api/admin/AdminAddressAPI";


const renderStep = (step) => {
  switch (step) {
    case 0:
      return <EditSoftwareStep1 />;
    case 1:
      return <EditSoftwareStep2 />;
    case 2:
      return <EditSoftwareStep3 />;
    default:
      return null;
  }
};

const EditSoftwareParent = ({ setShowSuccessMessage, currentListingUserId, currentPlanData }) => {


  const formikRef = useRef(null);
  //Device Category helper select field
  const [newDeviceCategoryLength, setNewDeviceCategoryLength] = useState(50);
  const [deviceCategorySearchField, setDeviceCategorySearchField] = useState("");

  // console.log("Id of post is " + id);
  //For Validation message navidating to steps. Used only when backend validation will be trigger
  const step1FormKey = ["softwareCategory", "softwareValueProposition", "softwareType", "installationLocation", "rangeMin", "rangeMax", "unitOfMeasureSoftware", "clinicalApplications", "purposeUse", "deviceCategory", "physicalLocation"];
  const step2FormKey = ["softwareFeaturesHighlights", "softwareBenefitsHighlights", "listParentSoftware", "featureImageObject", "galleryImageObject", "linkVideo", "location", "serviceAndSupportReach"]
  const step3FormKey = ["documentFileObject", "documentLink", "softwareFeatures", "softwareBenefits", "systemRequirements", "additionalInformation",]


  const [editSoftware, { isLoading, isError, error }] = useEditAdminSoftwareMutation();
  const navigate = useNavigate();
  const softwareCategoryTax = useGetSoftwareCategoryTaxQuery();
  const softwareTypeTax = useGetSoftwareTypeTaxQuery();
  const installationLocationTax = useGetInstallationLocationTaxQuery();
  const unitOfMeasureSoftwareTax = useGetUnitOfMeasureSoftwareTaxQuery();
  const clinicalApplicationsTax = useGetClinicalApplicationsTaxQuery()
  const purposeUseTax = useGetPurposeUseTaxQuery()
  const physicalLocationTax = useGetPhysicalLocationTaxQuery()
  const serviceAndSupportReachTax = useGetServiceandSupportReachTaxQuery();
  const deviceCategoryTax = useGetDeviceCategoryTaxQuery({ id: newDeviceCategoryLength, query: deviceCategorySearchField });
  const relatedAddressTax = useFindGetAdminRelatedAddressNoRemoteQuery(currentListingUserId);


  const [allTaxonomy, setAllTaxonomy] = useState({ softwareCategory: softwareCategoryTax, deviceCategory: deviceCategoryTax, physicalLocation: physicalLocationTax, purposeUse: purposeUseTax, clinicalApplications: clinicalApplicationsTax, unitOfMeasureSoftware: unitOfMeasureSoftwareTax, installationLocation: installationLocationTax, softwareType: softwareTypeTax, serviceAndSupportReach: serviceAndSupportReachTax, relatedAddress: relatedAddressTax })

  useEffect(() => {

    const allSuccess = [softwareCategoryTax.isSuccess, deviceCategoryTax.isSuccess, physicalLocationTax.isSuccess, purposeUseTax.isSuccess, clinicalApplicationsTax.isSuccess, unitOfMeasureSoftwareTax.isSuccess, installationLocationTax.isSuccess, softwareTypeTax.isSuccess, serviceAndSupportReachTax.isSuccess, relatedAddressTax.isSuccess].every(Boolean);


    if (allSuccess) {
      setAllTaxonomy({
        softwareCategory: softwareCategoryTax,
        deviceCategory: deviceCategoryTax, physicalLocation: physicalLocationTax, purposeUse: purposeUseTax, clinicalApplications: clinicalApplicationsTax, unitOfMeasureSoftware: unitOfMeasureSoftwareTax, installationLocation: installationLocationTax, softwareType: softwareTypeTax, serviceAndSupportReach: serviceAndSupportReachTax, relatedAddress: relatedAddressTax
      });
    }
  }, [
    softwareCategoryTax.isSuccess, softwareCategoryTax.data,
    softwareTypeTax.isSuccess, softwareTypeTax.data,
    installationLocationTax.isSuccess, installationLocationTax.data,
    deviceCategoryTax.isSuccess, deviceCategoryTax.data,
    unitOfMeasureSoftwareTax.isSuccess, unitOfMeasureSoftwareTax.data,
    clinicalApplicationsTax.isSuccess, clinicalApplicationsTax.data,
    purposeUseTax.isSuccess, purposeUseTax.data,
    physicalLocationTax.isSuccess, physicalLocationTax.data,
    serviceAndSupportReachTax.isSuccess, serviceAndSupportReachTax.data,
    relatedAddressTax.isSuccess, relatedAddressTax.data,
  ]);


  const dispatch = useDispatch();
  const formData = useSelector((state) => state.adminEditSoftware);
  const appData = useSelector((state) => state.adminAppDataEditSoftware);

  // const [featureImageMeta, setFeatureImageMeta] = useState("");
  const [backendValidation, setBackendValidation] = useState([])
  const [isRequestFirst, setIsRequestFirst] = useState(true);
  const [editSoftwareFormInitialData, setEditSoftwareFormInitialData] = useState(null);

  //Feature image useState
  const [selectedImageFile, setSelectedImageFile] = useState("");
  const [cropedImageFile, setCropedImageFile] = useState("");

  //Gallery image useState
  const [galleryImageToDelete, setGalleryImageToDelete] = useState([]);
  const [selectedMultiImageFile, setSelectedMultiImageFile] = useState([]);
  //This will pause choose file option while image is uploading
  const [imageMultiUploadStatus, setImageMultiUploadStatus] = useState([]);
  //This will indicate is currently image uploading or not
  const [isImageUploading, setIsImageUploading] = useState(false);
  //While uploading image if got error from backend then this will be true and user will not be able to go next form
  const [isUploadedImageHasErrorShow, setisUploadedImageHasErrorShow] = useState(false);

  //Document useState
  const [documentFileToDelete, setDocumentFileToDelete] = useState([]);
  const [selectedMultiDocumentFile, setSelectedMultiDocumentFile] = useState([]);
  const [documentMultiCheckboxError, setDocumentMultiCheckboxError] = useState("");
  const [documentMultiUploadStatus, setDocumentMultiUploadStatus] = useState([]);
  const [isDocumentUploading, setIsDocumentUploading] = useState(false);
  const [isUploadedDocumentHasErrorShow, setIsUploadedDocumentHasErrorShow] = useState(false);



  //This snippet used to reset form and applied on reset form button
  // const resetForm = () => {
  //   setSelectedImageFile("")
  //   setSelectedMultiImageFile([])
  //   setSelectedDocumentFile("")
  //   setBackendValidation([])
  //   setShowSuccessMessage("")
  //   setDeviceCategorySearchField(editSoftwareFormInitialData.tax_device_category.name)
  //   dispatch(clearAppDataEditSoftware());
  //   dispatch(fillEditSoftwareForm(editSoftwareFormInitialData))
  //   // formikRef.current.resetForm();
  // }

  const step1Schema = Yup.object({
    softwareCategory: Yup.string().required("This is a required field").test(
      "OPTION", "Please select a valid option",
      (value) => value !== '0'

    ),

    softwareValueProposition: Yup.string().required("This is a required field").max(70, 'Must be 70 characters or less'),

    softwareType: Yup.string().required("This is a required field").test(
      "OPTION", "Please select a valid option",
      (value) => value !== '0'

    ),

    installationLocation: Yup.string().required("This is a required field").test(
      "OPTION", "Please select a valid option",
      (value) => value !== '0'

    ),

    rangeMin: Yup.string()
      .required("This is a required field")
      .test(
        'is-numeric',
        'Characters are not allowed, please enter numbers only',
        (value) => {
          if (!value) return true; // Allow empty value for required validation to handle

          // Remove commas and check if the remaining string is a valid number pattern
          const cleanValue = value.replace(/,/g, '');

          // Check if the value contains only valid numeric characters
          return /^-?\d*\.?\d*$/.test(cleanValue);
        }
      )
      .test(
        'decimal-places',
        'Only two decimal places are allowed',
        value => {
          if (!value) return true;
          const cleanValue = value.replace(/,/g, '');
          // First, check if it's just a decimal point
          if (cleanValue === '.') return false;
          const decimalMatch = cleanValue.match(/\.(\d+)/);
          return !decimalMatch || decimalMatch[1].length <= 2;
        }
      )
      .test(
        'non-negative',
        'Field data cannot be negative',
        value => {
          if (!value) return true;
          const numValue = Number(value.replace(/,/g, ''));
          return numValue >= 0;
        }
      )
      .test(
        'min-value',
        'Field data must be greater than or equal to 0.01',
        value => {
          if (!value) return true;
          const numValue = Number(value.replace(/,/g, ''));
          return numValue >= 0.01;
        }
      )
      .test(
        'max-value',
        'Field data must be less than or equal to 9,999,999.99',
        value => {
          if (!value) return true;
          const numValue = Number(value.replace(/,/g, ''));
          return numValue <= 9999999.99;
        }
      ),
    rangeMax: Yup.string()
      .required("This is a required field")
      .test(
        'is-numeric',
        'Characters are not allowed, please enter numbers only',
        (value) => {
          if (!value) return true; // Allow empty value for required validation to handle

          // Remove commas and check if the remaining string is a valid number pattern
          const cleanValue = value.replace(/,/g, '');

          // Check if the value contains only valid numeric characters
          return /^-?\d*\.?\d*$/.test(cleanValue);
        }
      )
      .test(
        'decimal-places',
        'Only two decimal places are allowed',
        value => {
          if (!value) return true;
          const cleanValue = value.replace(/,/g, '');
          // First, check if it's just a decimal point
          if (cleanValue === '.') return false;
          const decimalMatch = cleanValue.match(/\.(\d+)/);
          return !decimalMatch || decimalMatch[1].length <= 2;
        }
      )
      .test(
        'non-negative',
        'Field data cannot be negative',
        value => {
          if (!value) return true;
          const numValue = Number(value.replace(/,/g, ''));
          return numValue >= 0;
        }
      )
      .test(
        'min-value',
        'Field data must be greater than or equal to 0.01',
        value => {
          if (!value) return true;
          const numValue = Number(value.replace(/,/g, ''));
          return numValue >= 0.01;
        }
      )
      .test(
        'max-value',
        'Field data must be less than or equal to 9,999,999.99',
        value => {
          if (!value) return true;
          const numValue = Number(value.replace(/,/g, ''));
          return numValue <= 9999999.99;
        }
      )
      .when('rangeMin', {
        is: (rangeMin) => rangeMin != null,
        then: (schema) => schema.test({
          test: (rangeMax, context) => {
            const cleanMin = context.parent.rangeMin ? context.parent.rangeMin.replace(/,/g, '') : '0';
            const cleanMax = rangeMax ? rangeMax.replace(/,/g, '') : '0';
            return Number(cleanMax) > Number(cleanMin);
          },
          message: 'Maximum range must be greater than minimum range'
        })
      }),

    unitOfMeasureSoftware: Yup.string().required("This is a required field").test(
      "OPTION", "Please select a valid option",
      (value) => value !== '0'

    ),

    clinicalApplications: Yup.string().required("This is a required field").test(
      "OPTION", "Please select a valid option",
      (value) => value !== '0'

    ),

    purposeUse: Yup.string().required("This is a required field").test(
      "OPTION", "Please select a valid option",
      (value) => value !== '0'

    ),

    deviceCategory: Yup.string().required("This is a required field").test(
      "OPTION", "Please select a valid option",
      (value) => value !== '0'

    ),


    physicalLocation: Yup.string().required("This is a required field").test(
      "OPTION", "Please select a valid option",
      (value) => value !== '0'

    ),
  })

  const step2Schema = Yup.object().shape({

    softwareFeaturesHighlights: Yup.string().required("This is a required field").max(190, 'Must be 190 characters or less'),

    softwareBenefitsHighlights: Yup.string().required("This is a required field").max(190, 'Must be 190 characters or less'),

    listParentSoftware: Yup.string().max(1000, 'Must be 1000 characters or less'),

    location: Yup.string().required("This is a required field").test(
      "OPTION", "Please select a valid option",
      (value) => value !== '0'

    ),

    serviceAndSupportReach: Yup.string().required("This is a required field").test(
      "OPTION", "Please select a valid option",
      (value) => value !== '0'

    ),
    featureImage: Yup.mixed()
      .test(
        'fileType',
        'Unsupported File Format (only JPEG, PNG, and WebP allowed)',
        value => {
          if (isValidURL(value)) return true;
          if (!value) return true;
          return ['image/jpeg', 'image/png', 'image/webp'].includes(value.type)
        }

      )
      .test(
        'fileSize',
        'File too large (max size 1MB)',
        value => {
          if (isValidURL(value)) return true;
          if (!value) return true;
          return (value && value.size <= 1024 * 1024);
        }
      )
      .required('A file is required'),

    gallery: Yup.array()
      .of(
        Yup.mixed()
          .test(
            'fileType',
            'Unsupported File Format (only JPEG, PNG, and WebP allowed)',
            value => {
              // if (!value)return true
              if (isValidURL(value)) return true;

              return ['image/jpeg', 'image/png', 'image/webp'].includes(value.file.type)
            }
          )
          .test(
            'fileSize',
            'File too large (max size 1MB)',
            value => {
              if (isValidURL(value)) return true;
              return !value.file || (value.file && value.file.size <= 1024 * 1024)
            }
          )
      )

  }
  )

  const step3Schema = Yup.object().shape({

    softwareFeatures: Yup.string().max(1000, 'Must be 1000 characters or less'),

    softwareBenefits: Yup.string().max(1000, 'Must be 1000 characters or less'),

    systemRequirements: Yup.string().max(1000, 'Must be 1000 characters or less'),

    additionalInformation: Yup.string().max(2000, 'Must be 2000 characters or less'),

    document: Yup.array()
      .of(
        Yup.mixed()
          .test(
            'fileType',
            'Unsupported File Format (only PDF allowed)',
            value => {
              // if (!value)return true
              if (isValidURL(value)) return true;
              return ['application/pdf'].includes(value.file.type)
            }
          )
          .test(
            'fileSize',
            'File too large (max size 1MB)',
            value => {
              if (isValidURL(value)) return true;
              return !value.file || (value.file && value.file.size <= 1024 * 1024)
            }
          )
      ),
  })

  const matchErrorsAgainstBackendValidationList = async (backendValidationError) => {
    if (backendValidationError?.length > 0) {



      for (const field of backendValidationError) {
        if (step1FormKey.includes(field.path)) {
          dispatch(updateAppDataAdminEditSoftware({ case: "CURRENTSTEP", value: 0 }));
          console.log("step1 was true");
          break;
        } else if (step2FormKey.includes(field.path)) {
          console.log("step2 was true");
          dispatch(updateAppDataAdminEditSoftware({ case: "CURRENTSTEP", value: 1 }));
          break;
        } else if (step3FormKey.includes(field.path)) {
          console.log("step3 was true");
          dispatch(updateAppDataAdminEditSoftware({ case: "CURRENTSTEP", value: 2 }));
        }
      }
    }
  }

  const finalSubmit = async (multiPartData, setSubmitting) => {
    try {

      const response = await editSoftware({ body: multiPartData }).unwrap();
      //If form was submitted successfully then isUpdated return with true, then reseting form
      console.log(response);
      if (response.status) {
        console.log(response);

        // setShowSuccessMessage(response.message)
        setShowSuccessMessage({ message: "Software edited successfully", color: "green" });
        await new Promise(resolve => setTimeout(resolve, 1500));
        // Reseting form
        dispatch(clearAdminEditSoftwareForm());
        dispatch(clearAppDataAdminEditSoftware());
        formikRef.current.resetForm();
        navigate('/admin/dashboard');
      }
      // if (!response.status) {
      //   setShowSuccessMessage({ message: response.message, color: "red" })
      // }
    }
    catch (error) {
      if (error.status === 422) {
        console.log(error);
        const decryptedData = decryptErrorData(error.data);
        console.log(decryptedData);
        setBackendValidation(decryptedData.errors);
        matchErrorsAgainstBackendValidationList(decryptedData.errors);
        // setShowSuccessMessage({ message: decryptedData.message, color: "red" });
      }
      else {
        console.log(error);
        // const decryptedData = decryptErrorData(error.data);
        // console.log(decryptedData);
        setShowSuccessMessage({ message: "Internal server error", color: "red" });
      }
      setSubmitting(false);
    }
  };

  useEffect(() => {
    console.log(backendValidation);
  }, [backendValidation])


  return (
    <Formik
      innerRef={formikRef}
      initialValues={formData}
      // validateOnChange={false}
      // validateOnBlur={false}
      validationSchema={appData.currentStep === 0 ? step1Schema : appData.currentStep === 1 ? step2Schema : appData.currentStep === 2 ? step3Schema : Yup.object().shape({})}
      onSubmit={(values, { setSubmitting }) => {

        // Add immediate disable of submit button
        setSubmitting(true);

        // Check if any uploads are still in progress
        const isAnyUploadPending = documentMultiUploadStatus.some(status => status.pending);

        const statusExists = documentMultiUploadStatus.some(status => status.isError === true);
        console.log("status " + statusExists);
        console.log(isDocumentUploading);

        if (statusExists || isDocumentUploading || isAnyUploadPending) {
          setSubmitting(false);
          setIsUploadedDocumentHasErrorShow(true);
          dispatch(updateAppDataAdminEditSoftware({ case: "CURRENTSTEP", value: 2 }));
          return;
        }

        // Getting FormData object to send multipart request
        if (appData.isYoutubeChecked) { dispatch(updateAdminEditSoftwareField({ field: "videoType", value: "Youtube" })) }
        if (appData.isVimeoChecked) dispatch(updateAdminEditSoftwareField({ field: "videoType", value: "Vimeo" }));
        multiPartData = objectToFormDataForEditSoftware(values, documentFileToDelete, galleryImageToDelete, cropedImageFile, imageMultiUploadStatus, documentMultiUploadStatus)
        // console.log(multiPartData);
        //   for (const [key, value] of multiPartData.entries()) {
        //     console.log(key, value);
        // }
        // Final submission of form
        setBackendValidation([]);
        finalSubmit(multiPartData, setSubmitting);
        //   for (const [key, value] of multiPartData.entries()) {
        //     console.log(key, value);
        // }
        // console.log(values);
        // setSubmitting(false);
        // console.log(values);
        // console.log(values);
      }}
    >
      {(formik) => (
        <Form className="add-software-step-section-header-parent">


          <Provider value={{ documentMultiCheckboxError, setDocumentMultiCheckboxError, selectedMultiDocumentFile, setSelectedMultiDocumentFile, isUploadedDocumentHasErrorShow, setIsUploadedDocumentHasErrorShow, documentMultiUploadStatus, setDocumentMultiUploadStatus, isDocumentUploading, setIsDocumentUploading, isUploadedImageHasErrorShow, setisUploadedImageHasErrorShow, isImageUploading, setIsImageUploading, imageMultiUploadStatus, setImageMultiUploadStatus, currentPlanData, deviceCategorySearchField, setDeviceCategorySearchField, newDeviceCategoryLength, setNewDeviceCategoryLength, editSoftwareFormInitialData, setEditSoftwareFormInitialData, documentFileToDelete, setDocumentFileToDelete, isRequestFirst, setIsRequestFirst, galleryImageToDelete, setGalleryImageToDelete, backendValidation, setBackendValidation, allTaxonomy, cropedImageFile, setCropedImageFile, selectedMultiImageFile, setSelectedMultiImageFile, selectedImageFile, setSelectedImageFile }}>


            {renderStep(appData.currentStep)}
          </Provider>
        </Form>
      )}
    </Formik>

  );
};

export default EditSoftwareParent;