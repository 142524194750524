import React, { useContext, useEffect, useState } from 'react'
import "./UserProfileFormView.css";
import divider from '../../icons/Gerator/account_setup_profile_subscription/divider.svg';
import homeline from '../../icons/Gerator/account_setup_profile_subscription/homeline.svg';
import chevronright from '../../icons/Gerator/account_setup_profile_subscription/chevronright.svg';
import placeholder1 from '../../icons/Gerator/account_setup_profile_subscription/placeholder1.svg';
import placeholder2 from '../../icons/Gerator/account_setup_profile_subscription/placeholder2.svg';
import placeholder3 from '../../icons/Gerator/account_setup_profile_subscription/placeholder3.svg';
import placeholder4 from '../../icons/Gerator/account_setup_profile_subscription/placeholder4.svg';
import searchlg from '../../icons/Gerator/account_setup_profile_subscription/searchlg.svg';
import help_icon from '../../icons/Gerator/account_setup_profile_subscription/help-icon.svg';
import lock04 from '../../icons/Gerator/archive_page/lock04.svg';
import mail01_11 from '../../icons/Gerator/account_setup_profile_subscription/mail01-11.svg';
import checkcircle from '../../icons/Gerator/account_setup_profile_subscription/checkcircle.svg';
import phone from '../../icons/Gerator/account_setup_profile_subscription/phone.svg';
import featured_icon_41 from '../../icons/Gerator/account_setup_profile_subscription/featured-icon-41.svg';
import page from '../../icons/Gerator/account_setup_profile_subscription/page.svg';
import cursor_2x from '../../icons/Gerator/account_setup_profile_subscription/cursor@2x.png';
// import user01 from '../../icons/Gerator/dashboard/user01.svg';
import settings011 from '../../icons/Gerator/dashboard/settings011.svg';
import zap from '../../icons/Gerator/dashboard/zap.svg';
import divider from '../../icons/Gerator/dashboard/divider.svg';
import divider2 from '../../icons/Gerator/profile_settings/divider2.svg';
import homeline from '../../icons/Gerator/dashboard/homeline.svg';
import userplus01 from '../../icons/Gerator/dashboard/userplus01.svg';
import layerstwo01 from '../../icons/Gerator/dashboard/layerstwo01.svg';
import messagesmilecircle from '../../icons/Gerator/dashboard/messagesmilecircle.svg';
import helpcircle from '../../icons/Gerator/dashboard/helpcircle.svg';
import container from '../../icons/Gerator/dashboard/container.svg';
import logout011 from '../../icons/Gerator/dashboard/logout011.svg';
import users011 from '../../icons/Gerator/dashboard/users011.svg';
import users011 from '../../icons/Gerator/dashboard/users011.svg';
import au from '../../icons/Gerator/account_setup_profile_subscription/au.svg';
import chevrondown from '../../icons/Gerator/account_setup_profile_subscription/chevrondown.svg';
import { AccountSetupButton } from '../AccountSetupButton/AccountSetupButton';
import { Form, Link, useNavigate } from 'react-router-dom';
import UserProfileContext from '../../screens/UserProfile/UserProfileContext';
import { Field, Formik, useField } from 'formik';
import * as Yup from "yup";
import { useGetUserProfileWithTaxNameQuery } from '../../api/UsermetaAPI';
import { decryptData } from '../../helper/AESHelper';
import { NoListingFound } from '../NoListing/NoListingFound';
import { Helmet } from 'react-helmet-async';


const UserProfileFormView = ({ currentUsermeta }) => {

  const initialToggleState = localStorage.getItem('toggleState') === 'false' ? false : true;

  // State to manage the toggle
  const [isReady, setIsReady] = useState(initialToggleState);

  // Toggle the state when button is clicked
  const handleToggle = () => {
    const newToggleState = !isReady;
    setIsReady(newToggleState);
    localStorage.setItem('toggleState', newToggleState);
  };

  useEffect(() => {
    // On component mount, check localStorage and set the state accordingly
    const savedToggleState = localStorage.getItem('toggleState');
    if (savedToggleState !== null) {
      setIsReady(savedToggleState === 'true');
    }
  }, []);




  const { data, isLoading, isError, error, isSuccess } = useGetUserProfileWithTaxNameQuery();
  const navigate = useNavigate();


  if (isSuccess && data && currentUsermeta) {
    return (
      <>
        <Helmet>
          <title>Settings - Profile</title>
        </Helmet>
        <div className="update-profile-view-content-elements">
          <div className="profile-setting-toggle-header-parent">
            <div className="profile-setting-toggle-header">
              <div className="profile-setting-toggle-title">Personal info</div>
              <div className="profile-setting-supporting-text2">
                A superhero lives here!
              </div>
            </div>
            <div className="profile-setting-toggle-controls">
              {/* <div className="profile-setting-rectangle-group">

                <div className="profile-setting-switch-label">Explorer mode</div>
                <div className="profile-setting-supporting-text3">
                  Save my login details for next time.
                </div>
              </div> */}
              <div className="profile-setting-rectangle-group">
                <div className="profile-setting-frame-item"></div>
                <div className={`profile-setting-switch-label1 ${isReady ? 'active' : ''}`}>Taking a break</div>
                <div className="profile-setting-toggle-base" onClick={handleToggle}>
                  <div className={`profile-setting-button6a ${isReady ? 'left' : 'right'}`}></div>
                </div>
                <div className={`profile-setting-switch-label ${!isReady ? 'active' : ''}`}>
                  Ready for work</div>
                <div className="profile-setting-supporting-text3">
                  Save my login details for next time.
                </div>
              </div>
              {/* <button className="profile-setting-button7">
                <img
                  className="profile-setting-placeholder-icon"
                  alt=""
                  src={placeholder2}
                />

                <div className="profile-setting-button-labels">Badge #1</div>
                <img
                  className="profile-setting-placeholder-icon"
                  alt=""
                  src={placeholder2}
                />
              </button> */}
              {currentUsermeta.userType == 5
                ?
                <Link to="/admin/edit-profile" className="profile-setting-button8">
                  {/* <button className="profile-setting-button8"> */}
                  <img
                    className="profile-setting-placeholder-icon"
                    alt=""
                    src={placeholder3}
                  />

                  <div className="profile-setting-text49">Edit Profile</div>
                  <img
                    className="profile-setting-placeholder-icon"
                    alt=""
                    src={placeholder3}
                  />
                  {/* </button> */}
                </Link>
                :
                <Link to="/edit-profile" className="profile-setting-button8">
                  {/* <button className="profile-setting-button8"> */}
                  <img
                    className="profile-setting-placeholder-icon"
                    alt=""
                    src={placeholder3}
                  />

                  <div className="profile-setting-text49">Edit Profile</div>
                  <img
                    className="profile-setting-placeholder-icon"
                    alt=""
                    src={placeholder3}
                  />
                  {/* </button> */}
                </Link>
              }
            </div>
          </div>
          <div className="update-profile-view-divider-container">
            <img
              className="update-profile-view-divider-icon3"
              alt=""
              src={divider2}
            />
          </div>
          <div className="update-profile-view-content9">
            <div className="update-profile-view-section-label">
              <div className="update-profile-view-text50">Name</div>
            </div>
            <div className="update-profile-view-input-fields">
              <div className="update-profile-view-input-field">
                <div className="update-profile-view-input-with-label1">
                  <div className="update-profile-view-input1 update-profile-view-input-disabled">
                    <input
                      className="update-profile-view-content10 update-profile-view-input-disabled-color"
                      // placeholder="Oliva"
                      name="firstName"
                      type="text"
                      value={data.user.user_usermeta.first_name}
                      disabled
                    />
                    <img
                      className="update-profile-view-home-line-icon"
                      alt=""
                      src={lock04}
                    />
                  </div>
                </div>
                <div className="update-profile-view-hint-text2">
                  This field cannot be edited.
                </div>
              </div>
              <div className="update-profile-view-input-field">

                <div className="update-profile-view-input2 update-profile-view-input-disabled">
                  <input
                    className="update-profile-view-content11 update-profile-view-input-disabled-color"
                    // placeholder="Oliva"
                    name="lastName"
                    type="text"
                    value={data.user.user_usermeta.last_name}
                    disabled
                  />
                  <img
                    className="update-profile-view-home-line-icon"
                    alt=""
                    src={lock04}
                  />
                </div>
                <div className="update-profile-view-hint-text2">
                  This field cannot be edited.
                </div>
              </div>
            </div>
          </div>
          <div className="update-profile-view-divider"></div>
          <div className="update-profile-view-content12">
            <div className="update-profile-view-section-label1">
              <a className="update-profile-view-text51">Email</a>
            </div>
            <div className="update-profile-view-input-field1">
              <div className="update-profile-view-input-with-label3">
                <div className="update-profile-view-input3 update-profile-view-input-disabled">
                  <div className="update-profile-view-content13">
                    <img
                      className="update-profile-view-home-line-icon"
                      alt=""
                      src={mail01_11}
                    />
                    <input
                      name="email"
                      className="update-profile-view-text52 update-profile-view-input-disabled-color"
                      // placeholder="Oliva"
                      value={data.user.email}
                      type="text"
                      disabled
                    />


                  </div>
                  <img
                    className="update-profile-view-home-line-icon"
                    alt=""
                    src={lock04}
                  />

                  <img
                    className="update-profile-view-help-icon"
                    alt=""
                    src={help_icon}
                  />
                </div>
              </div>
              <div className="update-profile-view-hint-text2">
                This field cannot be edited.
              </div>
            </div>
          </div>
          {/* <div className="update-profile-view-divider"></div>
          <div className="update-profile-view-content12">
            <div className="update-profile-view-section-label1">
              <a className="update-profile-view-text51">Plan</a>
            </div>
            <div className="update-profile-view-input-field1">
              <div className="update-profile-view-input-with-label3">
                <div className="update-profile-view-input3 update-profile-view-input-disabled">
                  <div className="update-profile-view-content13">
                    <img
                      className="update-profile-view-home-line-icon"
                      alt=""
                      src={homeline}
                    />


                    <input
                      name="userRole"
                      className="update-profile-view-text52 update-profile-view-input-disabled-color"
                      type="text"
                      value={data.user.user_order.order_plan.planName}
                      disabled
                    />
                  </div>

                  <img
                    className="update-profile-view-help-icon"
                    alt=""
                    src={help_icon}
                  />
                </div>
              </div>
              <div className="update-profile-view-hint-text2">
                <Link to={"/dashboard/settings/plan"} style={{ color: '#7e56d9' }}>Upgrage plan</Link> to get more features.
              </div>
            </div>
          </div> */}
          <div className="update-profile-view-divider"></div>
          <div className="update-profile-view-content12">
            <div className="update-profile-view-section-label1">
              <a className="update-profile-view-text51">User Category</a>
            </div>
            <div className="update-profile-view-input-field1">
              <div className="update-profile-view-input-with-label3">
                <div className="update-profile-view-input3 update-profile-view-input-disabled">
                  <div className="update-profile-view-content13">
                    <img
                      className="update-profile-view-home-line-icon"
                      alt=""
                      src={users011}
                    />
                    <input
                      name="userType"
                      className="update-profile-view-text52 update-profile-view-input-disabled-color"
                      type="text"
                      value={data.user.user_usermeta.tax_user_type.name}
                      disabled
                    />


                  </div>
                  <img
                    className="update-profile-view-home-line-icon"
                    alt=""
                    src={lock04}
                  />

                  <img
                    className="update-profile-view-help-icon"
                    alt=""
                    src={help_icon}
                  />
                </div>
              </div>
              <div className="update-profile-view-hint-text2">
                This field cannot be edited.
              </div>
            </div>
          </div>
          <div className="update-profile-view-divider"></div>
          <div className="update-profile-view-content9">
            <div className="update-profile-view-section-label1">
              <div className="update-profile-view-heading-and-help-icon">
                <div className="update-profile-view-text55">Profile pic<span style={{ color: "red" }}>*</span></div>
                <div className="update-profile-view-help-icon-wrapper">
                  <img
                    className="update-profile-view-help-icon3"
                    loading="lazy"
                    alt=""
                    src={help_icon}
                  />
                </div>
              </div>
              <div className="update-profile-view-supporting-text14">
                This will be displayed on your profile.
              </div>
            </div>
            <div className="update-profile-view-avatar-and-file-upload">
              <div className="update-profile-view-avatar" style={{
                backgroundImage: data.user.profileImage
                  && `url(${data.user.profileImage})`
              }}>
                <div className="update-profile-view-contrast-border"></div>
              </div>
              <div className="update-profile-view-file-upload">
                <div className="update-profile-view-file-upload-base">
                  <div className="update-profile-view-content17">
                    <img
                      className="update-profile-view-featured-icon12"
                      loading="lazy"
                      alt=""
                      src={featured_icon_41}
                    />

                    <div className="update-profile-view-text-and-supporting-text5">
                      <div className="update-profile-view-action">
                        <div className="update-profile-view-buttonsbutton8">
                          <img
                            className="update-profile-view-placeholder-icon16"
                            alt=""
                            src={placeholder3}
                          />

                          <div className="update-profile-view-text56">Click to upload</div>
                          <img
                            className="update-profile-view-placeholder-icon16"
                            alt=""
                            src={placeholder3}
                          />
                        </div>
                        <div className="update-profile-view-text57">or drag and drop</div>
                      </div>
                      <div className="update-profile-view-supporting-text15">
                        PNG or JPG (max. 400x400px)
                      </div>
                    </div>
                    {/* <div className="update-profile-view-file">
                      <div className="update-profile-view-file-type-icon">
                        <img
                          className="update-profile-view-page-icon"
                          alt=""
                          src={page}
                        />

                        <div className="update-profile-view-file-type-wrap">
                          <b className="update-profile-view-file-type">JPG</b>
                        </div>
                      </div>
                      <img
                        className="update-profile-view-cursor-icon"
                        loading="lazy"
                        alt=""
                        src={cursor_2x}
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="update-profile-view-divider"></div>
          <div className="update-profile-view-content12">
            <div className="update-profile-view-section-label1">
              <div className="update-profile-view-text53">Phone <span style={{ color: "red" }}>*</span></div>
              <div className="update-profile-view-supporting-text14">
                Country Code - Number
              </div>
            </div>
            <div className="update-profile-view-input-field1">
              <div className="update-profile-view-input-with-label3">
                <div className="update-profile-view-input3">
                  <input
                    className="update-profile-view-text52"
                    // placeholder="+91-9876543210"
                    type="text"
                    name="phone"
                    value={data.user.phone}
                    disabled
                  />
                  <img
                    className="update-profile-view-help-icon"
                    alt=""
                    src={help_icon}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="update-profile-view-divider"></div>
          <div className="update-profile-view-content12">
            <div className="update-profile-view-section-label1">
              <div className="update-profile-view-text53">Work Title <span style={{ color: "red" }}>*</span></div>
            </div>
            <div className="update-profile-view-input-field1">
              <div className="update-profile-view-input-with-label3">
                <div className="update-profile-view-input3">
                  <input
                    className="update-profile-view-text52"
                    // placeholder="Procurement Manager"
                    type="text"
                    name="userWorkTitle"
                    value={data.user.user_usermeta.user_work_title}
                    disabled
                  />
                  <img
                    className="update-profile-view-help-icon"
                    alt=""
                    src={help_icon}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="update-profile-view-divider"></div>
          <div className="update-profile-view-content12">
            <div className="update-profile-view-section-label1">
              <div className="update-profile-view-text53">Business Name <span style={{ color: "red" }}>*</span></div>
            </div>
            <div className="update-profile-view-input-field1">
              <div className="update-profile-view-input-with-label3">
                <div className="update-profile-view-input3">
                  <input
                    className="update-profile-view-content19"
                    // placeholder="Pacific Health Inc."
                    type="text"
                    name="userBusinessName"
                    value={data.user.user_usermeta.user_business_name}
                    disabled
                  />
                  <img
                    className="update-profile-view-help-icon"
                    alt=""
                    src={help_icon}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="update-profile-view-divider"></div>
          <div className="update-profile-view-content12">
            <div className="update-profile-view-section-label1">
              <div className="update-profile-view-text53">Country <span style={{ color: "red" }}>*</span></div>
            </div>
            <div className="update-profile-view-input-dropdown1">
              <div className="update-profile-view-input-with-label3">

                <div className="update-profile-view-input7">
                  <input
                    className='update-profile-view-content-custom-23 down-icon-hide update-profile-view-text63 '
                    name="userCountry"
                    type='text'
                    value={data.user.user_usermeta.tax_country.name}
                    disabled
                  />

                  <img
                    className="update-profile-view-search-lg-icon"
                    alt=""
                    src={chevrondown}
                  />
                </div>
              </div>


            </div>
          </div>
          <div className="update-profile-view-divider"></div>
          <div className="update-profile-view-content12">
            <div className="update-profile-view-section-label1">
              <div className="update-profile-view-text53">Building <span style={{ color: "red" }}>*</span></div>
            </div>
            <div className="update-profile-view-input-dropdown1">
              {/* <div className="update-profile-view-input-with-label3">
               <div className="update-profile-view-input8">
                 
               </div>

             </div> */}
              <div className="update-profile-view-input-with-label3">
                <div className="update-profile-view-input3">
                  <input
                    className="update-profile-view-content27"
                    // placeholder="Pacific Health Inc."
                    type="text"
                    name="userBuilding"
                    value={data.user.user_usermeta.user_building}
                    disabled
                  />
                  <img
                    className="update-profile-view-help-icon"
                    alt=""
                    src={help_icon}
                  />
                </div>
              </div>

            </div>
          </div>
          <div className="update-profile-view-divider"></div>
          <div className="update-profile-view-content12">
            <div className="update-profile-view-section-label1">
              <div className="update-profile-view-text53">Street <span style={{ color: "red" }}>*</span></div>
            </div>
            <div className="update-profile-view-input-dropdown1">
              <div className="update-profile-view-input-with-label3">
                <div className="update-profile-view-input3">
                  <input
                    className="update-profile-view-content27"
                    // placeholder="Acton Street"
                    type="text"
                    name="userStreet"
                    value={data.user.user_usermeta.user_street}
                    disabled
                  />
                  <img
                    className="update-profile-view-help-icon"
                    alt=""
                    src={help_icon}
                  />
                </div>
              </div>


            </div>
          </div>
          <div className="update-profile-view-divider"></div>
          <div className="update-profile-view-content12">
            <div className="update-profile-view-section-label1">
              <div className="update-profile-view-text53">State/Region</div>
            </div>
            <div className="update-profile-view-input-dropdown1">
              <div className="update-profile-view-input-with-label3">
                <div className="update-profile-view-input3">
                  <input
                    className="update-profile-view-content27"
                    // placeholder="QLD"
                    type="text"
                    name="userState"
                    value={data.user.user_usermeta.user_state}
                    disabled
                  />
                  <img
                    className="update-profile-view-help-icon"
                    alt=""
                    src={help_icon}
                  />
                </div>
              </div>

            </div>
          </div>
          <div className="update-profile-view-divider"></div>
          <div className="update-profile-view-content12">
            <div className="update-profile-view-section-label1">
              <div className="update-profile-view-text53">Town/City <span style={{ color: "red" }}>*</span></div>
            </div>
            <div className="update-profile-view-input-dropdown1">
              <div className="update-profile-view-input-with-label3">
                <div className="update-profile-view-input3">
                  <input
                    className="update-profile-view-content27"
                    // placeholder="Ashgrove"
                    type="text"
                    name="userTown"
                    value={data.user.user_usermeta.user_town}
                    disabled
                  />
                  <img
                    className="update-profile-view-help-icon"
                    alt=""
                    src={help_icon}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="update-profile-view-divider"></div>
          <div className="update-profile-view-content12">
            <div className="update-profile-view-section-label1">
              <div className="update-profile-view-text53">District</div>
            </div>
            <div className="update-profile-view-input-dropdown1">
              <div className="update-profile-view-input-with-label3">
                <div className="update-profile-view-input3">
                  <input
                    className="update-profile-view-content27"
                    // placeholder="Enter District"
                    type="text"
                    name="userDistrict"
                    value={data.user.user_usermeta.user_district}
                    disabled
                  />
                  <img
                    className="update-profile-view-help-icon"
                    alt=""
                    src={help_icon}
                  />
                </div>
              </div>

            </div>
          </div>
          <div className="update-profile-view-divider"></div>
          <div className="update-profile-view-content-container">
            <div className="update-profile-view-content32">
              <div className="update-profile-view-section-label1">
                <div className="update-profile-view-text53">ZIP <span style={{ color: "red" }}>*</span></div>
              </div>
              <div className="update-profile-view-input-dropdown1">
                <div className="update-profile-view-input-with-label3">
                  <div className="update-profile-view-input3">
                    <input
                      className="update-profile-view-content27"
                      // placeholder="4060"
                      type="text"
                      name="userPostcodeZip"
                      value={data.user.user_usermeta.user_postcode_zip}
                      disabled
                    />
                    <img
                      className="update-profile-view-help-icon"
                      alt=""
                      src={help_icon}
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>

          {/* <div className="update-profile-view-section-footer">
          <img
            className="update-profile-view-divider-icon1"
            loading="lazy"
            alt=""
            src={divider}
          />

          <div className="update-profile-view-content34 update-profile-view-content34-custom-align-self">

            <div className="update-profile-view-actions5 update-profile-view-actions5-custom-padding">


              <button className="update-profile-view-button6 update-profile-view-button6-custom-width">

                <div className="update-profile-view-text-padding14">
                  <div className="update-profile-view-text71 update-profile-view-text71-custom "></div>
                </div>
              </button>
            </div>
          </div>
        </div> */}
        </div >

      </>
    )
  }
  else {
    return <NoListingFound />
  }


};

export default UserProfileFormView;
