import React from 'react';
import "./ThreeDotsListingComponent.css";
import { Link } from 'react-router-dom';



export const ThreeDotsWantedMessageComponent = ({ listingId, postType }) => {
  
  return (
    <div className="three-dots-message-dropdown-three-dots-message-all-options-fixed">
      <section className="three-dots-admin-filter-dropdown-menu-items">
        <div className="three-dots-admin-filter-dropdown-dropdown-list-item" >
          <div className="three-dots-admin-filter-dropdown-content48">
            <div className="three-dots-admin-filter-dropdown-icon-and-text">
              <Link className="three-dots-admin-filter-dropdown-text247" to={`/dashboard/chat/wanted/${postType}/${listingId}`} >Message</Link>
            </div>
          </div>
        </div>
      
      </section>
    </div>
  )
}

