import React from 'react';
import { Link } from 'react-router-dom';
import './HeaderNavNotLoggedInDropdown.css';
import devices from "../../icons/Gerator/dashboard/devices.svg";
import spare from "../../icons/Gerator/dashboard/spare.svg";
import service from "../../icons/Gerator/dashboard/service.svg";
import software from "../../icons/Gerator/dashboard/software.svg";

const HeaderNavNotLoggedInDropdown = () => {
  return (
    <div className="header-not-logged-in-nav-menu">
      <div className="header-not-logged-in-nav-content">

        <Link to={"/devices-not-logged-in"}>
        <HeaderNavNotLoggedInDropdownItem
          title="Devices"
          description="Choose from more than 30,000 categories of devices ranging from thermometers to linear accelerators"
          iconClass={devices}
        />
        </Link>

        <Link to={"/spares-not-logged-in"}>
        <HeaderNavNotLoggedInDropdownItem
          title="Spares"
          description="The easiest way to find spares for any of the devices you have at your facility"
          iconClass={spare}
        />
        </Link>

        <Link to={"/services-not-logged-in"}>
        <HeaderNavNotLoggedInDropdownItem
          title="Services"
          description="Need an expert to fix a device for you? Look no further."
          iconClass={service}
        />
        </Link>

        <Link to={"/softwares-not-logged-in"}>
        <HeaderNavNotLoggedInDropdownItem
          title="Softwares"
          description="All it needs is an upgrade, a new license, or renewal of your software subscription! Find them here."
          iconClass={software}
        />
        </Link>

        {/* <HeaderNavDropdownItem
          title="Help and support"
          description="Learn, fix a problem, and get answers to your questions."
          iconClass="lifeBuoy01"
        /> */}
      </div>
    </div>
  );
};

const HeaderNavNotLoggedInDropdownItem = ({ title, description, iconClass }) => {
  return (
    <div className="header-not-logged-in-nav-navMenuItem">
      <div className="header-not-logged-in-nav-iconWrapper">
      <img src={iconClass} alt={title} className="header-not-logged-in-nav-icon" />
      </div>
      <div className="header-not-logged-in-nav-itemContent">
        <div className="header-not-logged-in-nav-textAndSupportingText">
          <div className="header-not-logged-in-nav-textAndBadge">
            <div className="header-not-logged-in-nav-text">{title}</div>
          </div>
          <div className="header-not-logged-in-nav-supportingText">{description}</div>
        </div>
      </div>
    </div>
  );
};

export default HeaderNavNotLoggedInDropdown;
