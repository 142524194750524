import React, { useState, useCallback, useRef } from 'react';
import "./SearchInputListingComponent.css";
import searchlg from '../../icons/Gerator/dashboard/searchlg.svg';
import { X } from 'lucide-react';

// Custom debounce function
function useDebounce(callback, delay) {
    const timeoutRef = useRef(null);

    return useCallback((...args) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            callback(...args);
        }, delay);
    }, [callback, delay]);
}

const SearchInputListingComponent = ({ onSearch }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [isInputFocused, setIsInputFocused] = useState(false);

    const debouncedSearch = useDebounce((term) => {
        onSearch(term);
    }, 300);

    const handleSearchIconClick = () => {
        debouncedSearch(searchTerm);
    };

    const handleInputChange = (e) => {
        const term = e.target.value;
        setSearchTerm(term);
        debouncedSearch(term);
    };

    const clearSearch = () => {
        setSearchTerm('');
        onSearch('');
    };
    return (
        <div className="dashboard-input-with-label2">
            <div className="dashboard-label">Search</div>
            <div
                    className={`dashboard-input2 ${
                     isInputFocused ? "dashboard-input-focused" : ""
                }`} 
            >
                <div className="dashboard-content3">
                    <img
                        className="dashboard-search-lg-icon dashboard-search-lg-icon-custom cursor-pointer"
                        alt="Search"
                        src={searchlg}
                        onClick={handleSearchIconClick}
                    />
                    <input
                        type="text"
                        className="dashboard-search-placeholder"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={handleInputChange}
                        onFocus={() => setIsInputFocused(true)}
                        onBlur={() => setIsInputFocused(false)}
                        maxLength={70}
                       
                    />
                    {searchTerm && (
                        <X
                            className="h-5 w-5 text-gray-400 hover:text-gray-600 cursor-pointer"
                            onClick={clearSearch}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default SearchInputListingComponent

