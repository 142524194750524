import { useEffect, useState } from "react";
import { useGetAvailabilityTaxQuery, useGetClinicalApplicationsTaxQuery, useGetPhysicalLocationTaxQuery, useGetPurposeUseTaxQuery, useGetShippingTaxQuery, useGetStatusConditionTaxQuery, useGetTransactionTypeTaxQuery, useGetUnitOfMeasureDeviceSpareTaxQuery, useGetWarrantyTaxQuery, useGetYearOfManufactureTaxQuery, useGetYourRoleTaxQuery } from "../api/TaxonomyFormAPI";
import { encryptData } from "./AESHelper";

// export function imageToFormData(galleryImage) {
//   const formData = new FormData();
//   console.log(galleryImage);

//   if (galleryImage instanceof File) {
//     console.log('galleryimage is added ');

//     formData.append("galleryImageObject", galleryImage);
//   }
//   console.log(formData);

//   return formData;

// }


// export function documentToFormData(documentImage) {
//   const formData = new FormData();
//   console.log(documentImage);

//   if (documentImage instanceof File) {
//     console.log('document is added ');

//     formData.append("documentImageObject", documentImage);
//   }
//   console.log(formData);

//   return formData;

// }



export function objectToAddressFormData(obj, currentUsermeta) {

  const { extra, ...values } = obj;

  values.businessId = currentUsermeta.userBusinessId;

  values.userId = currentUsermeta.userId;

  console.log(values);
  // Encrypt the object without the document
  const encryptedData = encryptData(values);

  return encryptedData;
}

export function objectToEditAddressFormData(obj, currentUsermeta) {

  const { extra, ...values } = obj;

  values.businessId = currentUsermeta.userBusinessId;

  values.userId = currentUsermeta.userId;

  console.log(values);
  // Encrypt the object without the document
  const encryptedData = encryptData(values);

  console.log(encryptedData);

  return encryptedData;
}


