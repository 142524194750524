import React from 'react';
import "./ThreeDotsAdminUserComponent.css";
import divider from '../../icons/Gerator/dashboard/divider.svg';
import { Link } from 'react-router-dom';
import { useSetUserActiveMutation, useSetUserInactiveMutation } from '../../api/admin/AdminUserAPI';

export const ThreeDotsAdminUserComponent = ({ option, userId, onClose }) => {

  const [setToInactive] = useSetUserInactiveMutation();

  const [setToActive] = useSetUserActiveMutation();

  const handleInactiveUpdate = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      if (!userId) {
        console.error('No user ID provided');
        return;
      }

      console.log('Updating status for user:', userId);
      const response = await setToInactive({ id: userId }).unwrap();
      console.log('Update response:', response);

      if (response.status) {
        onClose();
        window.location.reload();
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleActiveUpdate = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      if (!userId) {
        console.error('No user ID provided');
        return;
      }

      console.log('Updating status for user:', userId);
      const response = await setToActive({ id: userId }).unwrap();
      console.log('Update response:', response);

      if (response.status) {
        onClose();
        window.location.reload();
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };


  const handleItemClick = (e, action) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('Action clicked:', action);

    switch (action) {
      case 'active':
        handleActiveUpdate(e);
        break;
      case 'inactive':
        handleInactiveUpdate(e);
        break;
      // case 'accept':
      //   handleAcceptUpdate(e);
      //   break;
      // case 'reject':
      //   handleRejectUpdate(e);
      //   break;
      // case 'rejectActive':
      //   handleMakeRejectActiveUpdate(e);
      //   break;
      // case 'revoke':
      //   handleRevokeUpdate(e);
      //   break;
      // case 'revokeActive':
      //   handleMakeRevokeActiveUpdate(e);
      //   break;
      // Add other cases as needed
      default:
      // handleRejectUpdate(e);
    }
  };

  // Reusable menu item component with proper event handling
  const MenuItem = ({ onClick, text, to, action }) => (
    <div
      className="three-dots-admin-filter-dropdown-dropdown-list-item"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (onClick) onClick(e);
        if (action) handleItemClick(e, action);
      }}
    >
      <div className="three-dots-admin-filter-dropdown-content48">
        <div className="three-dots-admin-filter-dropdown-icon-and-text">
          {to ? (
            <Link
              className="three-dots-admin-filter-dropdown-text247"
              to={to}
              onClick={(e) => e.stopPropagation()}
            >
              {text}
            </Link>
          ) : (
            <div className="three-dots-admin-filter-dropdown-text247">{text}</div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="three-dots-admin-filter-dropdown-three-dots-admin-filter-all-options-fixed">
      <section className="three-dots-admin-filter-dropdown-menu-items">
        {option === 'inactive' && (
          <MenuItem
            text="Active"
            action="active"
          />
        )}

        {option === 'active' && (
          <MenuItem
            text="Inactive"
            action="inactive"
          />
        )}
        {/* <div className="three-dots-admin-filter-dropdown-dropdown-list-item" >
          <div className="three-dots-admin-filter-dropdown-content48">
            <div className="three-dots-admin-filter-dropdown-icon-and-text">
              <Link className="three-dots-admin-filter-dropdown-text247" to={`/admin/edit-${url}`}>Active</Link>
            </div>
          </div>
        </div>
        <div className="three-dots-admin-filter-dropdown-dropdown-list-item" >
          <div className="three-dots-admin-filter-dropdown-content48">
            <div className="three-dots-admin-filter-dropdown-icon-and-text">
              <Link className="three-dots-admin-filter-dropdown-text247" to={`/admin/archive-${url}`}>Inactive</Link>
            </div>
          </div>
        </div> */}
        {/* <div className="three-dots-admin-filter-dropdown-dropdown-list-item" >
          <div className="three-dots-admin-filter-dropdown-content48">
            <div className="three-dots-admin-filter-dropdown-icon-and-text">
              <Link className="three-dots-admin-filter-dropdown-text247" to={`/admin/delete-${url}`}>Delete</Link>
            </div>
          </div>
        </div> */}
        {/* <div className="three-dots-admin-filter-dropdown-dropdown-list-item" >
          <div className="three-dots-admin-filter-dropdown-content48">
            <div className="three-dots-admin-filter-dropdown-icon-and-text">
              <div className="three-dots-admin-filter-dropdown-text247">Price (high to low)</div>
            </div>
          </div>
        </div> */}
      </section>
    </div>
  )
}



export default ThreeDotsAdminUserComponent;