import React from 'react';
import "./NoBusinessPublished.css";
import bifocallens from '../../icons/Gerator/no_listing/bifocallens.png';
import placeholder from '../../icons/Gerator/no_listing/placeholder.svg';
import placeholder1 from '../../icons/Gerator/no_listing/placeholder1.svg';
import plus from '../../icons/Gerator/no_listing/plus.svg';
import { Link } from "react-router-dom";
import anton from "../../icons/Gerator/dashboard/Anton.png";

export const NoBusinessPublished = ({ path, pathName }) => {
  return (
    <div className="no-business-published-no-business-published">
      <section className="no-business-published-empty-state">
        <div className="no-business-published-empty-state1">
          <div className="no-business-published-content">
            <img
              className="no-business-published-bifocal-optometry-measurement-icon"
              loading="lazy"
              alt=""
              src={anton}
            />

            <div className="no-business-published-text-and-supporting-text">
              <div className="no-business-published-title">Business not published yet.</div>
              <div className="no-business-published-supporting-text">
                Please wait while we review your business page and publish it.
              </div>
            </div>
          </div>
          <div className="no-business-published-actions">
            {/* <button className="no-business-published-buttonsbutton">
              <img
                className="no-business-published-placeholder-icon"
                alt=""
                src={placeholder}
              />

              <div className="no-business-published-text-padding">
                <div className="no-business-published-text">Clear search</div>
              </div>
              <img
                className="no-business-published-placeholder-icon"
                alt=""
                src={placeholder}
              />
            </button> */}
            <button className="no-business-published-buttonsbutton1"  >
              <Link to={path} className='no-business-published-button-link'>
                {/* <img className="no-business-published-plus-icon" alt="" src={plus} /> */}

                <div className="no-business-published-text-padding">
                  <div className="no-business-published-text1">Check Status</div>
                </div>
                <img
                  className="no-business-published-placeholder-icon"
                  alt=""
                  src={placeholder1}
                />
              </Link>
            </button>
          </div>
        </div>
      </section>
    </div>
  )
}
