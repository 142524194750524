import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  jobTitle: '',
  overview: '',
  deliverables: '',
  engagementType: '',
  experienceLevel: '',
  occupationCategory: '',
  serviceCategory: '',
  budget: '',
  unitOfMeasureService: '',
  deviceCategory: '',
  clinicalApplications: '',
  purposeUse: '',
  physicalLocation: '',
  location: '',
  videoType: '',
  linkVideo: '',
  document: '',
  documentFileObject: {}
}


const adminEditJobSlice = createSlice({
  name: "adminEditJob",
  initialState,
  reducers: {
    fillAdminEditJobForm(state, action) {
      return action.payload;
    },
    updateAdminEditJobField(state, action) {
      const { field, value } = action.payload
      if (value !== undefined) {
        state[field] = value;
      }
    },
    clearAdminEditJobForm() {
      return initialState;
    }
  },
});

export const { updateAdminEditJobField, clearAdminEditJobForm, fillAdminEditJobForm } = adminEditJobSlice.actions

export default adminEditJobSlice.reducer


