import React, { useEffect, useRef, useState } from 'react';
import "./Contact.css";
import { Sidebar } from '../../components/Sidebar/Sidebar';
import { Navbar1 } from '../../components/Navbar1/Navbar1';
import { Footer } from '../../components/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Loader } from '../../components/Loader';
import { decryptData, decryptErrorData } from '../../helper/AESHelper';
import { firestore, doc, getDoc, auth, onAuthStateChanged, sendPasswordResetEmail, fetchSignInMethodsForEmail } from "../../screens/Login/FirebaseClient";

import divider from '../../icons/Gerator/dashboard/divider.svg';
import divider1 from '../../icons/Gerator/dashboard/divider1.svg';
import homeline1 from '../../icons/Gerator/dashboard/homeline1.svg';
import chevronright from '../../icons/Gerator/dashboard/chevronright.svg';
import chart_data2x from '../../icons/Gerator/dashboard/-chart-data@2x.png';
import arrowup from '../../icons/Gerator/dashboard/arrowup.svg';
import arrowdown from '../../icons/Gerator/dashboard/arrowdown.svg';
import chart_mini2x from '../../icons/Gerator/dashboard/-chart-mini@2x.png';
import dotsvertical from '../../icons/Gerator/dashboard/dotsvertical.svg';
import chart_mini1_2x from '../../icons/Gerator/dashboard/-chart-mini1@2x.png';
import searchlg from '../../icons/Gerator/dashboard/searchlg.svg';
import _1_2x from '../../icons/Gerator/dashboard/8595f4b711e503bc72fe396e5043e0c2-1@2x.png';
import placeholder from '../../icons/Gerator/dashboard/placeholder.svg';
import placeholder1 from '../../icons/Gerator/dashboard/placeholder1.svg';
import placeholder2 from '../../icons/Gerator/dashboard/placeholder2.svg';
import placeholder3 from '../../icons/Gerator/dashboard/placeholder3.svg';
import upload04 from '../../icons/Gerator/dashboard/upload04.svg';
import pluscircle from '../../icons/Gerator/dashboard/pluscircle.svg';
import chevrondown1 from '../../icons/Gerator/dashboard/chevrondown1.svg';
import calendar from '../../icons/Gerator/dashboard/calendar.svg';
import filterlines from '../../icons/Gerator/dashboard/filterlines.svg';
import arrowleft from '../../icons/Gerator/dashboard/arrowleft.svg';
import arrowright from '../../icons/Gerator/dashboard/arrowright.svg';
import markerpin02 from '../../icons/Gerator/dashboard/markerpin02.svg';
import divider2 from '../../icons/Gerator/dashboard/divider2.svg';
import calendar1 from '../../icons/Gerator/dashboard/calendar1.svg';
import arrowsquareupright1 from '../../icons/Gerator/dashboard/arrowsquareupright1.svg';
import arrowsdown from '../../icons/Gerator/dashboard/arrowsdown.svg';
import chart_mini2_2x from '../../icons/Gerator/dashboard/-chart-mini2@2x.png';
import content_elements_2x from '../../icons/Gerator/dashboard/content-elements@2x.png';
import dot1 from '../../icons/Gerator/dashboard/-dot1.svg';
import radar_1_1_2x from '../../icons/Gerator/dashboard/radar-1-1@2x.png';
import deal_2_1_2x from '../../icons/Gerator/dashboard/deal-2-1@2x.png';
import magnifier_1_2x from '../../icons/Gerator/dashboard/magnifier-1@2x.png';
import group_58_2x from '../../icons/Gerator/dashboard/group-58@2x.png';
import eye from '../../icons/Gerator/dashboard/eye.svg';
import icon1 from '../../icons/Gerator/dashboard/icon1.svg';
import lock04 from '../../icons/Gerator/dashboard/lock04.svg';
import lockunlocked04 from '../../icons/Gerator/dashboard/lockunlocked04.svg';
import arrowsquareupright from '../../icons/Gerator/dashboard/arrowsquareupright.svg';
import tooltip from '../../icons/Gerator/dashboard/tooltip.svg';
import group_212 from '../../icons/Gerator/dashboard/group_212.svg';
import trash01 from '../../icons/Gerator/dashboard/trash01.svg';
import icon2 from '../../icons/Gerator/dashboard/icon2.svg';
import messagechatsquare from '../../icons/Gerator/dashboard/messagechatsquare.svg';
import bifocal_optometry_measurement_device_2_2x from '../../icons/Gerator/dashboard/bifocal-optometry-measurement-device-2@2x.png';
import uploadcloud02 from '../../icons/Gerator/dashboard/uploadcloud02.svg';
import plus from '../../icons/Gerator/dashboard/plus.svg';
import divider3 from '../../icons/Gerator/dashboard/divider3.svg';
import help_icon from '../../icons/Gerator/dashboard/help-icon.svg';
import f4b711e503bc72fe396e5043e0c2_11_2x from '../../icons/Gerator/dashboard/8595f4b711e503bc72fe396e5043e0c2-11@2x.png';

import featured_icon from '../../icons/Gerator/dashboard/featured-icon.svg';
import arrowdown1 from '../../icons/Gerator/dashboard/arrowdown1.svg';


import xclose from '../../icons/Gerator/dashboard/xclose.svg';
import { SideBarSubNormal } from '../../components/SideBars-Sub/SideBarSubNormal/SideBarSubNormal';
import { DashboardDeviceComponent } from '../../components/Dashboard/DashboardDeviceComponent/DashboardDeviceComponent';
import { DashboardSpareComponent } from '../../components/Dashboard/DashboardSpareComponent/DashboardSpareComponent';
import { DashboardServiceComponent } from '../../components/Dashboard/DashboardServiceComponent/DashboardServiceComponent';
import { DashboardSoftwareComponent } from '../../components/Dashboard/DashboardSoftwareComponent/DashboardSoftwareComponent';
import { DashboardJobComponent } from '../../components/Dashboard/DashboardJobComponent/DashboardJobComponent';
import { Helmet } from 'react-helmet-async';
import { ContactComponent } from '../../components/ContactComponent/ContactComponent';

import divider_light from '../../icons/Gerator/divider.svg';
import { ErrorShowComponent } from '../../components/ErrorShowComponent/ErrorShowComponent';
import { useLazyGetProfileImageLinkQuery } from '../../api/Login&RegisterAPI';

// const auth = getAuth(app);


export const Contact = () => {

  const [currentUser, setCurrentUser] = useState("");
  const navigate = useNavigate();
  const [profileImageLink, setProfileImageLink] = useState("");
  const [currentUsermeta, setCurrentUsermeta] = useState("");
  const [triggerNewProfileImageLink] = useLazyGetProfileImageLinkQuery();



  // const [triggerGetUser, { data, error, isLoading, isError }] = useLazyGetUserQuery();

  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {

        if (user) {
          // Get user to check wizardStep
          const userDocRef = doc(firestore, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);
          setCurrentUser(user);

          if (userDocSnapshot.exists()) {
            const usermeta = userDocSnapshot.data(); // Default to 1 if not set

            if (usermeta.userStatus == 2) {
              navigate('/account-disabled');
              return;
            }

            if (usermeta.userType == 5) {
              navigate('/access-denied');
              return;
            }

            if (usermeta.wizardStep !== 0) {
              navigate('/account-setup-check-email');
              return;
            }

            // await triggerGetCurrentPlan().unwrap();
            setCurrentUsermeta(usermeta);
            // User is authenticated
          } else {
            content = <ErrorShowComponent message1={"Internal server error"} />
          }
        }
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });


    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);

  //Profile image load useEffect
  useEffect(() => {
    const refreshProfileImage = async () => {
      if (currentUsermeta && currentUsermeta.profileImage.expiresAt < Date.now() / 1000) {
        // Pre-signed URL has expired, trigger a new request to the backend
        const newImageData = await triggerNewProfileImageLink().unwrap();
        // Update the profile image link directly
        setProfileImageLink(newImageData.url);
      } else if (currentUsermeta && currentUsermeta.profileImage.url) {
        // Use the existing pre-signed URL to display the profile image
        setProfileImageLink(currentUsermeta.profileImage.url);
      }
    };

    refreshProfileImage();
  }, [currentUsermeta]);


  if (currentUser && currentUsermeta) {
    return (
      <div className="contact-contact-frame-layout">
        <Helmet>
          <title>Contact</title>
        </Helmet>
        <div className="contact-contact-frame-layout-child"></div>

        <Navbar1 userData={currentUser} navigate={navigate} profileImageLink={profileImageLink} />

        <div className="contact-all">
          <img className="contact-divider-icon" alt="" src={divider_light} />

          <div className="contact-all-content">

            <Sidebar profileImageLink={profileImageLink}>
              <SideBarSubNormal userData={currentUser} />
            </Sidebar>

            <div className="contact-frame-parent">
              <div className="contact-main-wrapper">
                <div className="contact-main contact-main-custom">
                  <div className="contact-header-section">
                    <div className="contact-container">
                      <div className="contact-page-header">
                        <div className="contact-breadcrumbs">
                          <div className="contact-tabs">
                            <div className="contact-breadcrumb-button-base">
                              <img
                                className="contact-search-lg-icon"
                                alt=""
                                src={homeline1}
                              />
                            </div>
                            <img
                              className="contact-chevron-right-icon"
                              alt=""
                              src={chevronright}
                            />

                            <div className="contact-breadcrumb-button-base1">
                              <div className="contact-location">Dashboard</div>
                            </div>
                            <img
                              className="contact-chevron-right-icon"
                              alt=""
                              src={chevronright}
                            />

                            <div className="contact-breadcrumb-button-base2">
                              <div className="contact-text20">...</div>
                            </div>
                            <img
                              className="contact-chevron-right-icon2"
                              alt=""
                              src={chevronright}
                            />

                            <div className="contact-breadcrumb-button-base3">
                              <div className="contact-text21">Another link</div>
                            </div>
                            <img
                              className="contact-chevron-right-icon2"
                              alt=""
                              src={chevronright}
                            />

                            <div className="contact-breadcrumb-button-base4">
                              <div className="contact-text22">Contact</div>
                            </div>
                          </div>
                        </div>

                        <img
                          className="contact-divider-icon1"
                          alt=""
                          src={divider1}
                        />
                      </div>
                      {/* <div className="contact-tabs-and-filters">
                        <div className="contact-button-group">
                          <div className="contact-button-group-base">
                            <div className="contact-text31">12 months</div>
                          </div>
                          <div className="contact-button-group-base1">
                            <div className="contact-text32">30 days</div>
                          </div>
                          <div className="contact-button-group-base2">
                            <div className="contact-text29">7 days</div>
                          </div>
                          <div className="contact-button-group-base3">
                            <div className="contact-text34">Text</div>
                          </div>
                          <div className="contact-button-group-base4">
                            <div className="contact-text34">Text</div>
                          </div>
                          <div className="contact-button-group-base5">
                            <div className="contact-text34">Text</div>
                          </div>
                          <div className="contact-button-group-base6">
                            <div className="contact-text34">Text</div>
                          </div>
                          <div className="contact-button-group-base7">
                            <div className="contact-text34">Text</div>
                          </div>
                          <div className="contact-button-group-base8">
                            <div className="contact-text39">24 hours</div>
                          </div>
                        </div>
                        <div className="contact-actions2">
                          <div className="contact-date-picker-dropdown">
                            <div className="contact-buttonsbutton1">
                              <img
                                className="contact-upload-04-icon"
                                alt=""
                                src={calendar}
                              />

                              <div className="contact-text-padding6">
                                <div className="contact-filter-date">Select dates</div>
                              </div>
                              <img
                                className="contact-placeholder-icon"
                                alt=""
                                src={placeholder2}
                              />
                            </div>
                          </div>
                          <div className="contact-buttonsbutton2">
                            <img
                              className="contact-upload-04-icon"
                              alt=""
                              src={filterlines}
                            />

                            <div className="contact-text-padding6">
                              <div className="contact-filter-options">Filters</div>
                            </div>
                            <img
                              className="contact-placeholder-icon"
                              alt=""
                              src={placeholder2}
                            />
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="contact-line-and-bar-chart-wrapper">
                    <div className="contact-line-and-bar-chart">
                      <div className="contact-line-and-bar-chart">

                      </div>
                    </div>
                  </div>


                </div>
              </div>
              {/* <div className="contact-separator"></div>
              <div className="contact-separator1"></div> */}
              <div className="contact-main-wrapper">



                <ContactComponent />

                {/* <div className="contact-horizontal-tabs-wrapper">
                  <div className="contact-horizontal-tabs">
                    <div className="contact-tabs2">
                      <div className={activeTab === 'device' ? "contact-tab-button-base" : "contact-tab-button-base1"}>
                        <div className="contact-tab-name" style={{ cursor: "pointer" }} onClick={() => setActiveTab('device')}>Devices</div>
                      </div>
                      <div className={activeTab === 'spare' ? "contact-tab-button-base" : "contact-tab-button-base1"}>
                        <div className="contact-text48" style={{ cursor: "pointer" }} onClick={() => setActiveTab('spare')}>Spares</div>
                      </div>
                      <div className={activeTab === 'service' ? "contact-tab-button-base" : "contact-tab-button-base1"}>
                        <div className="contact-text49" style={{ cursor: "pointer" }} onClick={() => setActiveTab('service')}>Service</div>
                      </div>
                      <div className={activeTab === 'software' ? "contact-tab-button-base" : "contact-tab-button-base1"}>
                        <div className="contact-text50" style={{ cursor: "pointer" }} onClick={() => setActiveTab('software')}>Software</div>
                      </div>
                      <div className={activeTab === 'job' ? "contact-tab-button-base" : "contact-tab-button-base1"}>
                        <div className="contact-text51" style={{ cursor: "pointer" }} onClick={() => setActiveTab('job')}>Jobs</div>
                      </div>
                      <div className="contact-tab-button-base5">
                        <div className="contact-text52">Billing</div>
                      </div>
                      <div className="contact-tab-button-base5">
                        <div className="contact-text52">Email</div>
                      </div>
                      <div className="contact-tab-button-base7">
                        <div className="contact-text52">Notifications</div>
                        <div className="contact-badge10">
                          <div className="contact-text55">2</div>
                        </div>
                      </div>
                      <div className="contact-tab-button-base5">
                        <div className="contact-text52">Integrations</div>
                      </div>
                      <div className="contact-tab-button-base9">
                        <div className="contact-text52">API</div>
                      </div>
                    </div>
                  </div>
                </div> */}



                {/* {renderContent()} */}



              </div>





            </div>
          </div>
        </div>
        <img className="contact-divider-icon6" alt="" src={divider_light} />

        <Footer />


      </div>
    )
  }
  else {
    return <Loader />
  }


}

