import React from "react";
// import "./style.css";

import divider4 from '../../icons/Gerator/detailed_listing/divider4.svg';
import arrowleft from '../../icons/Gerator/detailed_listing/arrowleft.svg';
import arrowright from '../../icons/Gerator/detailed_listing/arrowright.svg';
import calendar1 from '../../icons/Gerator/detailed_listing/calendar1.svg';
import radar_1_1_2x from '../../icons/Gerator/detailed_listing/radar-1-1@2x.png';
import group_58_2x from '../../icons/Gerator/detailed_listing/group-58@2x.png';
import markerpin021 from '../../icons/Gerator/detailed_listing/markerpin021.svg';
import icon1 from '../../icons/Gerator/detailed_listing/icon1.svg';
import target_04 from "../../icons/Gerator/dashboard/target_04.png"
import { useAddRadarDeviceMutation, useDeleteRadarDeviceMutation } from "../../api/Radar/RadarDeviceAPI";
import TruncatedTextTooltip from "../TruncatedTextTooltip/TruncatedTextTooltip";
import { formatNumber, getCustomizedDate } from "../../helper/DeviceHelper";
import { Link } from "react-router-dom";
import { useAddRadarSpareMutation, useDeleteRadarSpareMutation } from "../../api/Radar/RadarSpareAPI";


export const SpareListingSuggestionComponent = ({ cardData, carouselRef, prevSlide, nextSlide }) => {

  const [addToRadar] = useAddRadarSpareMutation();

  const [deleteFromRadar] = useDeleteRadarSpareMutation();


  // ------------------------------------ RADAR CODE START ---------------------------

  const handleRemoveRadar = async (spareId) => {
    await deleteFromRadar(spareId);
    window.location.reload();
  };

  const handleAddRadar = async (spareId) => {
    await addToRadar(spareId);
    window.location.reload();
  };


  // ----------------------------------- RADAR CODE END ---------------------------

  return (
    <>
      <div className="detailed-device-listing-image-grid4">
        <div className="detailed-device-heading-parent3">
          <div className="detailed-device-heading36">Related Devices</div>
          {/* {cardData?.length > 5 && */}
          <div className="detailed-device-text-wrapper">
            {/* <div className="detailed-device-description">View All</div> */}
            <Link to={`/devices`} target="_blank" className="detailed-device-description">
              View All
            </Link>
          </div>
          {/* } */}
        </div>
      </div>

      <div className="detailed-device-listing-image-grid5">
        <div className="detailed-device-frame-parent4">
          {cardData?.length > 5 &&
            <div className="detailed-device-testiomonial-carousel-arrow2" onClick={prevSlide}>
              <img
                className="detailed-device-arrow-left-icon"
                alt=""
                src={arrowleft}
              />
            </div>
          }

          <div className="detailed-device-frame-parent5" ref={carouselRef}>
            {cardData?.map((card, index) => (
              <div key={index} className="detailed-device-rectangle-parent10">
                <div className="detailed-device-frame-child12"></div>
                <div className="detailed-device-listing-card-badge-overlay" style={{
                  backgroundImage: card.featureImage
                    && `url(${card.featureImage})`
                }}>
                  {/* <img
                    className="detailed-device-listing-card-badge-overlay-child"
                    alt=""
                    src={card.featureImage}
                  /> */}

                  <div className="detailed-device-image-overlay">
                    <div className="detailed-device-badge4">
                      <div className="detailed-device-listing-card-price">Assured</div>
                    </div>
                    <div className="detailed-device-short-listing-team">
                      <div className="detailed-device-badge5">
                        <div className="detailed-device-listing-card-radar">ID# {card.id}</div>
                      </div>
                      <div className="detailed-device-buttonsbutton11">
                        {/* <img
                          className="detailed-device-magnifier-1-icon"
                          alt=""
                          src={radar_1_1_2x}
                        /> */}
                        {card.addedToRadar ? (
                          <img
                            className="detailed-device-magnifier-1-icon"
                            alt=""
                            onClick={() => handleRemoveRadar(card.id)}
                            src={target_04}
                          />
                        ) : (
                          <img
                            className="detailed-device-magnifier-1-icon"
                            alt=""
                            onClick={() => handleAddRadar(card.id)}
                            src={radar_1_1_2x}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailed-device-listing-detail-item">
                  <div className="detailed-device-message-content">
                    <div className="detailed-device-text-frame">
                      {/* <div className="detailed-device-text42">
                        {card.title}
                      </div> */}
                      <Link to={`/spare/${card.id}/${card.title}`} className="detailed-device-text42">
                        {card.title}
                      </Link>
                    </div>
                    <div className="detailed-device-listing-detail-item-value">
                      <div className="detailed-device-listing-detail-item-pin-wrapper">
                        <div className="detailed-device-heading-container">
                          <div className="detailed-device-listing-detail-item-location">
                            <div className="detailed-device-listing-detail-item-date-parent">
                              <div className="detailed-device-listing-detail-item-date">
                                <img
                                  className="detailed-device-chevron-right-icon2"
                                  alt=""
                                  src={markerpin021}
                                />
                              </div>
                              <div className="detailed-device-location"><TruncatedTextTooltip
                                text={`${card.spare_address.addressTownCity}, ${card.spare_address.tax_country.name}`}
                              /></div>
                            </div>
                            <div className="detailed-device-listing-detail-item-date-parent">
                              <div className="detailed-device-listing-detail-item-date">
                                <img
                                  className="detailed-device-chevron-right-icon2"
                                  alt=""
                                  src={calendar1}
                                />
                              </div>
                              <div className="detailed-device-type-of-work">{getCustomizedDate(card.createdAt)}</div>
                            </div>
                          </div>
                          <div className="detailed-device-listing-team">
                            <div className="detailed-device-listing-team-member-wrapper">
                              <div className="detailed-device-listing-team-member">{card.totalRatingAvg ? `${card.totalRatingAvg}/5` : "0/5"}</div>
                            </div>
                            <div className="detailed-device-contact-role">
                              <div className="detailed-device-supporting-text12">
                              {card.totalReviews} {card.totalReviews === 0 || card.totalReviews === 1 ? "review" : "reviews"}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="detailed-device-listing">
                        <div className="detailed-device-breadcrumb-items">
                          <div className="detailed-device-listing-short-info">${formatNumber(card.price)}
                          </div>
                          <div className="detailed-device-supporting-text-wrapper">
                            <div className="detailed-device-supporting-text13">{card.tax_unit_measure_device_spare.name}</div>
                          </div>
                        </div>
                      </div>
                      <img
                        className="detailed-device-divider-icon"
                        alt=""
                        src={divider4}
                      />

                      <div className="detailed-device-listing-detail-item-value-inner">
                        <div className="detailed-device-footer-social-parent">
                          <div className="detailed-device-footer-social">
                            <img
                              className="detailed-device-footer-social-child"
                              alt=""
                              src={card.profileImage}
                            />

                            <div className="detailed-device-listing-short-detail-item-pin-parent">
                              <div className="detailed-device-listing-short-detail-item-pin">
                                <div className="detailed-device-name">{card.spare_user.displayName}</div>
                              </div>
                              <Link className="detailed-device-role" to={`/business/${card.spare_business.id}/${card.spare_business.businessName}`} target="_blank">
                                {card.spare_business.businessName}
                              </Link>
                              {/* <div className="detailed-device-role1">{card.device_business.businessName}</div> */}
                            </div>
                          </div>
                          <Link to={`/spare/${card.id}/${card.title}`} target="_blank">
                            <div className="detailed-device-listing-short-detail-item-date">
                              <img
                                className="detailed-device-icon1"
                                alt=""
                                src={icon1}
                              />
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}



          </div>
          {cardData?.length > 5 &&
            <div className="detailed-device-testiomonial-carousel-arrow3" onClick={nextSlide}>
              <img
                className="detailed-device-arrow-left-icon"
                alt=""
                src={arrowright}
              />
            </div>
          }
        </div>


      </div>
    </>
  );
};
