
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { decryptUserData, decryptUserData, encryptData, } from '../helper/AESHelper';
import { decryptData } from '../helper/AESHelper';

import { auth } from '../screens/Login/FirebaseClient';
export const ChatAPI = createApi({
  reducerPath: "chat",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  tagTypes: ["messages", "senders"],
  endpoints: (builder) => ({
    authenticateChannel: builder.mutation({
      query: (data) => ({
        url: "/pusher/auth",
        method: "POST",
        body: data,
      }),
    }),
    // sendMessage: builder.mutation({
    //   query: (messageData) => ({
    //     url: "/chat/send-message",
    //     method: "POST",
    //     body: messageData,
    //   }),
    // }),
    // Mark messages as read
    markMessagesAsRead: builder.mutation({
      query: (data) => ({
        url: "/chat/mark-messages-as-read",
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result, error, { channel_name }) => [
        { type: "messages", id: channel_name },
      ],
    }),
    markMessagesAsReadApproacher: builder.mutation({
      query: (data) => ({
        url: "/chat/mark-messages-as-read-approacher",
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result, error, { channel_name }) => {
        // You can now use the result from the API response
        console.log("Mutation result:", result);

        // You can make decisions based on the result
        const tagsToInvalidate = [
          { type: "messages", id: channel_name },
          // { type: 'Radar_Device', id: 'LIST' }
        ];

        // Conditionally add more tags based on result data
        if (result && result.success) {
          switch (result.entityType) {
            case 'Device':
              tagsToInvalidate.push({ type: 'Radar_Device', id: 'LIST' });
              break;
            case 'Spare':
              tagsToInvalidate.push({ type: 'Radar_Spare', id: 'LIST' });
              break;
            case 'Service':
              tagsToInvalidate.push({ type: 'Radar_Service', id: 'LIST' });
              break;
            case 'Software':
              tagsToInvalidate.push({ type: 'Radar_Software', id: 'LIST' });
              break;
            case 'Job':
              tagsToInvalidate.push({ type: 'Radar_Job', id: 'LIST' });
              break;
            // case 'Device':

            //   break;
            default:
              break;
          }
        } else {
          // If entityType is missing but success is true,
          // you might want a fallback behavior
          console.warn('Success response without entityType');
        }


        return tagsToInvalidate;
      },
    }),
    // markMessagesAsReadApproacher: builder.mutation({
    //   query: (data) => ({
    //     url: "/chat/mark-messages-as-read-approacher",
    //     method: "POST",
    //     body: data,
    //   }),
    //   invalidatesTags: (result, error, { channel_name }) => [
    //     { type: "messages", id: channel_name }, { type: 'Radar_Device', id: 'LIST' },
    //     { type: 'Radar_Job', id: 'LIST' }, { type: 'Radar_Service', id: 'LIST' },
    //     { type: 'Radar_Spare', id: 'LIST' }
    //   ],
    // }),
    // // Fetch unread messages count
    // fetchUnreadMessagesCount: builder.query({
    //   query: (user_id) => ({
    //     url: `/chat/unread-messages-count?user_id=${user_id}`,
    //   }),
    //   providesTags: ["messages"],
    // }),
    fetchUnreadMessagesCount: builder.query({
      query: (data) => ({
        url: `/chat/unread-messages-count/${data.user_id}/${data.channel_names.join(",")}`,
      }),
      transformResponse: (response) => response.messages,
      providesTags: ["messages"],
    }),
    chatUpdateStage: builder.mutation({
      query: (messageData) => ({
        url: "/chat/update-stage",
        body: JSON.stringify({ encryptedData: messageData }),
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
      }),
      invalidatesTags: (result, error, { channel }) => [
        { type: "messages", id: channel },
      ],
    }),
    FetchChatUpdateStage: builder.query({
      query: (channelName) => ({
        url: `/chat/get-update-stage/${channelName}`,
      }),
      // transformResponse: (response) => {
      //   // Decrypt response data
      //   // console.log(response.data);
      //   return decryptData(response);
      // },
      // providesTags: ['devices']
      invalidatesTags: (result, error, { channel }) => [
        { type: "messages", id: channel },
      ],
    }),
    sendMessage: builder.mutation({
      query: (messageData) => ({
        url: "/chat/send-message",
        method: "POST",
        body: messageData,
      }),
      invalidatesTags: (result, error, { channel }) => [
        { type: "messages", id: channel },
      ],
    }),
    updateOnlineStatus: builder.mutation({
      query: (data) => ({
        url: '/chat/online-status',
        method: 'POST',
        body: data
      })
    }),

    saveMetadata: builder.mutation({
      query: (metadata) => ({
        url: "/chat-metadata",
        method: "POST",
        body: metadata,
      }),
    }),
    saveConversation: builder.mutation({
      query: (conversation) => ({
        url: "/chat-conversation",
        method: "POST",
        body: conversation,
      }),
    }),
    checkChatMetadata: builder.query({
      query: (params) => {
        const queryString = new URLSearchParams(params).toString();
        console.log("CHECK CHAT META IN CHATAPI ", queryString);
        return `/chat/check-metadata?${queryString}`;
      },
    }),
    // checkChatMetadata: builder.query({
    //   query: (params) => {
    //     const queryString = new URLSearchParams(params).toString();
    //     return `/chat/check-metadata?${queryString}`;
    //   },
    //   transformResponse: (response, meta, error) => {
    //     if (error) {
    //       console.error("Error in checkChatMetadata:", error);
    //       return { status: false }; // Default to metadata not existing
    //     }
    //     return response;
    //   },
    // }),

    // saveEachMessage: builder.mutation({
    //   query: (messageData) => ({
    //     url: "/chat/save-message",
    //     method: "POST",
    //     body: messageData,
    //    })
    // }),
    saveEachMessage: builder.mutation({
      query: (messageData) => (
        {
          url: "/chat/save-message",
          method: "POST",
          body: messageData,
        }),
      invalidatesTags: (result, error, { message_metadata_id }) => [
        { type: "messages", id: message_metadata_id },
      ],

    }),

    // FetchMessages: builder.query({
    //   query: ({ channel_name, page }) => {
    //     const queryString = new URLSearchParams({ channel_name, page }).toString();
    //     return `/chat/get-messages?${queryString}`;
    //   },
    // }),
    // FetchMessages: builder.query({
    //   query: ({ channel_name, page }) => {
    //     const queryString = new URLSearchParams({ channel_name, page }).toString();
    //     return `/chat/get-messages?${queryString}`;
    //   },
    //   providesTags: (result, error, { channel_name }) => {
    //     console.log("API Result in providesTags:", result);
    //     return result?.messages?.messages
    //       ? [
    //           { type: "messages", id: channel_name },
    //           ...result.messages.messages.map(({ id }) => ({ type: "messages", id })),
    //         ]
    //       : [{ type: "messages", id: channel_name }];
    //   },
    // }),
    FetchMessages: builder.query({
      query: ({ channel_name, page }) => {
        const queryString = new URLSearchParams({ channel_name, page, real_time: true }).toString();
        return `/chat/get-messages?${queryString}`;
      },
      providesTags: (result, error, { channel_name }) => {
        if (result?.messages?.length) {
          return [
            { type: "messages", id: channel_name },
            ...result.messages.map((message) => ({ type: "messages", id: message.messageId })),
          ];
        }
        return [{ type: "messages", id: channel_name }];
      },
    }),


    // fetchSendersWithLatestMessages: builder.query({
    //   query: (recipient_id) => {

    //     const queryString = new URLSearchParams({ recipient_id }).toString();
    //     return `/chat/get-senders-with-latest-messages?${queryString}`;
    //   },
    //   providesTags: (result) => 
    //     result?.senders ? result.senders.map((sender) => ({ type: "senders", id: sender.id })) : [],
    // }),

    fetchSendersWithLatestMessages: builder.query({
      query: (recipient_id) => {
        const queryString = new URLSearchParams({ recipient_id }).toString();
        return `/chat/get-senders-with-latest-messages?${queryString}`;
      },
      transformResponse: (response, meta, error) => {
        if (error) {
          console.error("Error in fetchSendersWithLatestMessages:", error);
        }
        else {
          console.log("SUCCESS fetch sender with latest message at chatAPI")
          return response;
        }
      },
      providesTags: (result) =>
        result?.senders
          ? result.senders.map((sender) => ({ type: "senders", id: sender.id }))
          : [],
    }),

    FetchLatestDetailedSenderProfiles: builder.query({
      query: ({ recipient_id, listing_type, entity_type }) => {
        const queryString = new URLSearchParams({ recipient_id, listing_type, entity_type }).toString();
        console.log("here in chatapi recid", recipient_id, listing_type, entity_type);
        return `/chat/get-detailed-sender-profiles-latest-messages?${queryString}`;
      },
      providesTags: (result) =>
        result?.users ? result.users.map((user) => ({ type: "senders", id: user.id })) : [],
    }),


    // FetchInitialMessagesForDisplay: builder.query({
    //   query: ( channel_name ) => {
    //     console.log("in API", channel_name);
    //     const queryString = new URLSearchParams({ channel_name }).toString();
    //     return `/chat/get-initial-messages-to-display?${queryString}`;
    //   },
    // }),
    FetchInitialMessagesForDisplay: builder.query({
      query: (channel_name) => {
        const queryString = new URLSearchParams({ channel_name }).toString();
        return `/chat/get-initial-messages-to-display?${queryString}`;
      },
      providesTags: (result, error, channel_name) => {
        if (result?.messages?.length) {
          return [
            { type: "messages", id: channel_name },
            ...result.messages.map((message) => ({ type: "messages", id: message.messageId })),
          ];
        }
        return [{ type: "messages", id: channel_name }];
      },
    }),
    FetchUserDetailsForChatHeader: builder.query({
      query: (channel_name) => {
        const queryString = new URLSearchParams({ channel_name }).toString();
        return `/chat/get-user-details-for-chat-header?${queryString}`;
      },
      // invalidatesTags: ["spares"],
    }),

    findGetDevice: builder.query({
      queryFn: async (id, _queryApi, _extraOptions, baseQuery) => {
        try {
          const user = auth.currentUser;
          let idToken = null;

          if (user) {
            console.log("HERE 2")
            idToken = await user.getIdToken(); // Retrieve the token
          }

          // Prepare the headers
          const headers = {};
          if (idToken) {
            console.log("Adding ID Token to Authorization Header");
            headers.Authorization = `Bearer ${idToken}`; // Add token to Authorization header
          }

          // Execute the base query
          const result = await baseQuery({
            url: `/chat/find-device/${id}`,
            headers,
          });

          if (result.error) {
            console.error("Error in findGetDevice API call:", result.error);
            return { error: result.error };
          }

          // Decrypt and return the response
          return { data: decryptData(result.data) };
        } catch (error) {
          console.error("Error in findGetDevice:", error);
          return { error: { status: "FETCH_ERROR", error: error.message } };
        }
      },
      invalidatesTags: ["devices"], // Optional cache invalidation
    }),

    findGetSpare: builder.query({
      queryFn: async (id, _queryApi, _extraOptions, baseQuery) => {
        try {
          const user = auth.currentUser;
          let idToken = null;

          if (user) {
            console.log("HERE 1")
            idToken = await user.getIdToken(); // Retrieve the token
          }

          // Prepare the headers
          const headers = {};
          if (idToken) {
            console.log("Adding ID Token to Authorization Header");
            headers.Authorization = `Bearer ${idToken}`; // Add token to Authorization header
          }

          // Execute the base query
          const result = await baseQuery({
            url: `/chat/find-spare/${id}`,
            headers,
          });

          if (result.error) {
            console.error("Error in findGetSpare API call:", result.error);
            return { error: result.error };
          }

          // Decrypt and return the response
          return { data: decryptData(result.data) };
        } catch (error) {
          console.error("Error in findGetSpare:", error);
          return { error: { status: "FETCH_ERROR", error: error.message } };
        }
      },
      invalidatesTags: ["spares"], // Optional cache invalidation
    }),

    findGetSoftware: builder.query({
      queryFn: async (id, _queryApi, _extraOptions, baseQuery) => {
        try {
          const user = auth.currentUser;
          let idToken = null;

          if (user) {
            idToken = await user.getIdToken(); // Retrieve the token
          }

          // Prepare the headers
          const headers = {};
          if (idToken) {
            console.log("Adding ID Token to Authorization Header");
            headers.Authorization = `Bearer ${idToken}`; // Add token to Authorization header
          }

          // Execute the base query
          const result = await baseQuery({
            url: `/chat/find-software/${id}`,
            headers,
          });

          if (result.error) {
            console.error("Error in findGetSoftware API call:", result.error);
            return { error: result.error };
          }

          // Decrypt and return the response
          return { data: decryptData(result.data) };
        } catch (error) {
          console.error("Error in findGetSoftware:", error);
          return { error: { status: "FETCH_ERROR", error: error.message } };
        }
      },
      invalidatesTags: ["softwares"], // Optional cache invalidation
    }),

    findGetService: builder.query({
      queryFn: async (id, _queryApi, _extraOptions, baseQuery) => {
        try {
          const user = auth.currentUser;
          let idToken = null;

          if (user) {
            idToken = await user.getIdToken(); // Retrieve the token
          }

          // Prepare the headers
          const headers = {};
          if (idToken) {
            console.log("Adding ID Token to Authorization Header");
            headers.Authorization = `Bearer ${idToken}`; // Add token to Authorization header
          }

          // Execute the base query
          const result = await baseQuery({
            url: `/chat/find-service/${id}`,
            headers,
          });

          if (result.error) {
            console.error("Error in findGetService API call:", result.error);
            return { error: result.error };
          }

          // Decrypt and return the response
          return { data: decryptData(result.data) };
        } catch (error) {
          console.error("Error in findGetService:", error);
          return { error: { status: "FETCH_ERROR", error: error.message } };
        }
      },
      invalidatesTags: ["services"], // Optional cache invalidation
    }),

    findGetJob: builder.query({
      queryFn: async (id, _queryApi, _extraOptions, baseQuery) => {
        try {
          const user = auth.currentUser;
          let idToken = null;

          if (user) {
            idToken = await user.getIdToken(); // Retrieve the token
          }

          // Prepare the headers
          const headers = {};
          if (idToken) {
            console.log("Adding ID Token to Authorization Header");
            headers.Authorization = `Bearer ${idToken}`; // Add token to Authorization header
          }

          // Execute the base query
          const result = await baseQuery({
            url: `/chat/find-job/${id}`,
            headers,
          });

          if (result.error) {
            console.error("Error in findGetJob API call:", result.error);
            return { error: result.error };
          }

          // Decrypt and return the response
          return { data: decryptData(result.data) };
        } catch (error) {
          console.error("Error in findGetJob:", error);
          return { error: { status: "FETCH_ERROR", error: error.message } };
        }
      },
      invalidatesTags: ["jobs"], // Optional cache invalidation
    }),
    findGetWantedDevice: builder.query({
      queryFn: async (id, _queryApi, _extraOptions, baseQuery) => {
        try {
          const user = auth.currentUser;
          let idToken = null;

          if (user) {
            console.log("HERE findGetWantedDevice");
            idToken = await user.getIdToken(); // Retrieve the token
          }

          // Prepare the headers
          const headers = {};
          if (idToken) {
            console.log("Adding ID Token to Authorization Header");
            headers.Authorization = `Bearer ${idToken}`;
          }

          // Execute the base query
          const result = await baseQuery({
            url: `/chat/find-wanted-device/${id}`,
            headers,
          });

          if (result.error) {
            console.error("Error in findGetWantedDevice API call:", result.error);
            return { error: result.error };
          }

          return { data: decryptData(result.data) };
        } catch (error) {
          console.error("Error in findGetWantedDevice:", error);
          return { error: { status: "FETCH_ERROR", error: error.message } };
        }
      },
      invalidatesTags: ["wantedDevices"], // Optional cache invalidation
    }),

    findGetWantedSpare: builder.query({
      queryFn: async (id, _queryApi, _extraOptions, baseQuery) => {
        try {
          const user = auth.currentUser;
          let idToken = null;

          if (user) {
            console.log("HERE findGetWantedSpare");
            idToken = await user.getIdToken();
          }

          const headers = {};
          if (idToken) {
            console.log("Adding ID Token to Authorization Header");
            headers.Authorization = `Bearer ${idToken}`;
          }

          const result = await baseQuery({
            url: `/chat/find-wanted-spare/${id}`,
            headers,
          });

          if (result.error) {
            console.error("Error in findGetWantedSpare API call:", result.error);
            return { error: result.error };
          }

          return { data: decryptData(result.data) };
        } catch (error) {
          console.error("Error in findGetWantedSpare:", error);
          return { error: { status: "FETCH_ERROR", error: error.message } };
        }
      },
      invalidatesTags: ["wantedSpares"],
    }),

    findGetWantedSoftware: builder.query({
      queryFn: async (id, _queryApi, _extraOptions, baseQuery) => {
        try {
          const user = auth.currentUser;
          let idToken = null;

          if (user) {
            console.log("HERE findGetWantedSoftware");
            idToken = await user.getIdToken();
          }

          const headers = {};
          if (idToken) {
            console.log("Adding ID Token to Authorization Header");
            headers.Authorization = `Bearer ${idToken}`;
          }

          const result = await baseQuery({
            url: `/chat/find-wanted-software/${id}`,
            headers,
          });

          if (result.error) {
            console.error("Error in findGetWantedSoftware API call:", result.error);
            return { error: result.error };
          }

          return { data: decryptData(result.data) };
        } catch (error) {
          console.error("Error in findGetWantedSoftware:", error);
          return { error: { status: "FETCH_ERROR", error: error.message } };
        }
      },
      invalidatesTags: ["wantedSoftware"],
    }),

    findGetWantedService: builder.query({
      queryFn: async (id, _queryApi, _extraOptions, baseQuery) => {
        try {
          const user = auth.currentUser;
          let idToken = null;

          if (user) {
            console.log("HERE findGetWantedService");
            idToken = await user.getIdToken();
          }

          const headers = {};
          if (idToken) {
            console.log("Adding ID Token to Authorization Header");
            headers.Authorization = `Bearer ${idToken}`;
          }

          const result = await baseQuery({
            url: `/chat/find-wanted-service/${id}`,
            headers,
          });

          if (result.error) {
            console.error("Error in findGetWantedService API call:", result.error);
            return { error: result.error };
          }

          return { data: decryptData(result.data) };
        } catch (error) {
          console.error("Error in findGetWantedService:", error);
          return { error: { status: "FETCH_ERROR", error: error.message } };
        }
      },
      invalidatesTags: ["wantedServices"],
    }),

  }),
});

export const {
  useAuthenticateChannelMutation,
  useSendMessageMutation,
  useSaveMetadataMutation,
  useSaveConversationMutation,
  useLazyCheckChatMetadataQuery,
  useSaveEachMessageMutation,
  useFetchMessagesQuery,
  useLazyFetchSendersWithLatestMessagesQuery,
  useLazyFetchUserDetailsForChatHeaderQuery,
  useLazyFetchLatestDetailedSenderProfilesQuery,
  useLazyFetchInitialMessagesForDisplayQuery,
  useLazyFetchMessagesQuery,
  useLazyFindGetDeviceQuery,
  useLazyFindGetSpareQuery,
  useLazyFindGetServiceQuery,
  useLazyFindGetSoftwareQuery,
  useLazyFindGetJobQuery,
  useLazyFindGetWantedDeviceQuery,
  useLazyFindGetWantedSoftwareQuery,
  useLazyFindGetWantedServiceQuery,
  useLazyFindGetWantedSpareQuery,
  useUpdateOnlineStatusMutation,
  useChatUpdateStageMutation,
  useLazyFetchChatUpdateStageQuery,
  useMarkMessagesAsReadMutation,
  useLazyFetchUnreadMessagesCountQuery,
  useMarkMessagesAsReadApproacherMutation
} = ChatAPI;
