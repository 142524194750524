import React from 'react';
import "./ThreeDotsListingComponent.css";
import divider from '../../icons/Gerator/dashboard/divider.svg';
import { Link, useNavigate } from 'react-router-dom';
import { useSetArchiveDeviceMutation, useSetPublishDeviceMutation } from '../../api/DeviceAPI';
import { useSetArchiveServiceMutation, useSetPublishServiceMutation } from '../../api/ServiceAPI';
import { useSetArchiveSoftwareMutation, useSetPublishSoftwareMutation } from '../../api/SoftwareAPI';
import { useSetArchiveSpareMutation, useSetPublishSpareMutation } from '../../api/SpareAPI';
import { useSetArchiveJobMutation, useSetPublishJobMutation } from '../../api/JobAPI';

export const ThreeDotsListingComponent = ({ option, postId, onClose, postType, url, totalActiveEngagement }) => {
  // const handleOptionClick = (option) => {
  //   onOptionSelect(option); // Call the function from the parent with the selected option
  // };

  const navigate = useNavigate();

  const [setToDeviceArchive] = useSetArchiveDeviceMutation();

  const [setToDevicePublish] = useSetPublishDeviceMutation();

  
  const [setToSpareArchive] = useSetArchiveSpareMutation();

  const [setToSparePublish] = useSetPublishSpareMutation();

  
  const [setToServiceArchive] = useSetArchiveServiceMutation();

  const [setToServicePublish] = useSetPublishServiceMutation();

  
  const [setToSoftwareArchive] = useSetArchiveSoftwareMutation();

  const [setToSoftwarePublish] = useSetPublishSoftwareMutation();

  
  const [setToJobArchive] = useSetArchiveJobMutation();

  const [setToJobPublish] = useSetPublishJobMutation();

  const handleEdit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {

      navigate(`/edit-${url}`);
    } catch (error) {
      console.error('Error navigating ' + error);
    }
  };

  const handleSetArchive = async (e) => {
    let response;
    e.preventDefault();
    e.stopPropagation();

    try {
      if (!postId) {
        console.error('No post ID provided');
        return;
      }

      switch (postType) {
        case 'device':
          response = await setToDeviceArchive({ id: postId }).unwrap();
          break;
        case 'service':
          response = await setToServiceArchive({ id: postId }).unwrap();
          break;
        case 'software':
          response = await setToSoftwareArchive({ id: postId }).unwrap();
          break;
        case 'spare':
          response = await setToSpareArchive({ id: postId }).unwrap();
          break;
        case 'job':
          response = await setToJobArchive({ id: postId }).unwrap();
          break;
        default:
          break;
      }

      // console.log('Updating status for post:', postId);
      // const response = await setToWantedDeviceArchive({ id: postId }).unwrap();
      // console.log('Update response:', response);

      if (response?.status) {
        onClose();
        window.location.reload();
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleSetPublish = async (e) => {
    let response;
    e.preventDefault();
    e.stopPropagation();

    try {
      if (!postId) {
        console.error('No post ID provided');
        return;
      }


      switch (postType) {
        case 'device':
          response = await setToDevicePublish({ id: postId }).unwrap();
          break;
        case 'service':
          response = await setToServicePublish({ id: postId }).unwrap();
          break;
        case 'software':
          response = await setToSoftwarePublish({ id: postId }).unwrap();
          break;
        case 'spare':
          response = await setToSparePublish({ id: postId }).unwrap();
          break;
        case 'job':
          response = await setToJobPublish({ id: postId }).unwrap();
          break;
        default:
          break;
      }

      // console.log('Updating status for post:', postId);
      // const response = await setToWantedDevicePublish({ id: postId }).unwrap();
      // console.log('Update response:', response);

      if (response.status) {
        onClose();
        window.location.reload();
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };


  const handleItemClick = (e, action) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('Action clicked:', action);

    switch (action) {
      case 'edit':
        handleEdit(e);
        break;
      case 'publish':
        handleSetPublish(e);
        break;
      case 'archive':
        handleSetArchive(e);
        break;
      // Add other cases as needed
      default:
      // handleRejectUpdate(e);
    }
  };



  // Reusable menu item component with proper event handling
  const MenuItem = ({ onClick, text, to, action }) => (
    <div
      className="three-dots-filter-dropdown-dropdown-list-item"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (onClick) onClick(e);
        if (action) handleItemClick(e, action);
      }}
    >
      <div className="three-dots-filter-dropdown-content48">
        <div className="three-dots-filter-dropdown-icon-and-text">
          {to ? (
            <Link
              className="three-dots-filter-dropdown-text247"
              to={to}
              onClick={(e) => e.stopPropagation()}
            >
              {text}
            </Link>
          ) : (
            <div className="three-dots-filter-dropdown-text247">{text}</div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="three-dots-filter-dropdown-three-dots-filter-all-options-fixed">
      <section className="three-dots-filter-dropdown-menu-items">
        {option === 'published' && (
          <>
            {totalActiveEngagement === 0 ?
              <>
                <MenuItem text="Edit" action="edit" />
                <MenuItem text="Archive" action="archive" />
              </> :
              <MenuItem text="No action found" />
            }
          </>
        )}
         {option === 'archived' && (
          <>
            {totalActiveEngagement === 0 ?
              <>
                <MenuItem text="Edit" action="edit" />
                <MenuItem text="Republish" action="publish" />
              </> :
              <MenuItem text="No action found" />
            }
          </>
        )}

          {option === 'pending' && (
                
                  <MenuItem text="No action found" />
                  
              )}
        {/* <div className="three-dots-filter-dropdown-dropdown-list-item" >
          <div className="three-dots-filter-dropdown-content48">
            <div className="three-dots-filter-dropdown-icon-and-text">
              <Link className="three-dots-filter-dropdown-text247" to={`/edit-${url}`}>Edit</Link>
            </div>
          </div>
        </div>
        <div className="three-dots-filter-dropdown-dropdown-list-item" >
          <div className="three-dots-filter-dropdown-content48">
            <div className="three-dots-filter-dropdown-icon-and-text">
              <Link className="three-dots-filter-dropdown-text247" to={`/archive-${url}`}>Archive</Link>
            </div>
          </div>
        </div>
        <div className="three-dots-filter-dropdown-dropdown-list-item" >
          <div className="three-dots-filter-dropdown-content48">
            <div className="three-dots-filter-dropdown-icon-and-text">
              <Link className="three-dots-filter-dropdown-text247" >Republish</Link>
            </div>
          </div>
        </div> */}
        {/* <div className="three-dots-filter-dropdown-dropdown-list-item" >
          <div className="three-dots-filter-dropdown-content48">
            <div className="three-dots-filter-dropdown-icon-and-text">
              <div className="three-dots-filter-dropdown-text247">Price (high to low)</div>
            </div>
          </div>
        </div> */}
      </section>
    </div>
  )
}

