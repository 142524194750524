import React, { useEffect, useRef, useState } from 'react';
import "./FunnelDeviceComponent.css"
import dotsvertical from '../../../icons/Gerator/dashboard/dotsvertical.svg';
import calendar1 from '../../../icons/Gerator/dashboard/calendar1.svg';
import content_elements_2x from '../../../icons/Gerator/dashboard/content-elements@2x.png';
import dot1 from '../../../icons/Gerator/dashboard/-dot1.svg';
import radar from '../../../icons/Gerator/dashboard/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/dashboard/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/dashboard/magnifier-1@2x.png';
import content_elements_2x from '../../../icons/Gerator/dashboard/content-elements@2x.png';
import eye from '../../../icons/Gerator/dashboard/eye.svg';
import icon1 from '../../../icons/Gerator/dashboard/icon1.svg';
import lock04 from '../../../icons/Gerator/dashboard/lock04.svg';
import lockunlocked04 from '../../../icons/Gerator/dashboard/lockunlocked04.svg';
import arrowsquareupright from '../../../icons/Gerator/dashboard/arrowsquareupright.svg';
import messagechatsquare from '../../../icons/Gerator/dashboard/messagechatsquare.svg';
import markerpin02 from '../../../icons/Gerator/dashboard/markerpin02.svg';
import divider2 from '../../../icons/Gerator/dashboard/divider2.svg';
import arrowup1 from '../../../icons/Gerator/dashboard/arrowup1.svg';
import arrowdown1 from '../../../icons/Gerator/dashboard/arrowdown1.svg';
import placeholder2 from '../../../icons/Gerator/dashboard/placeholder2.svg';
import group_58_2x from '../../../icons/Gerator/dashboard/group-58@2x.png';
import sliders02 from '../../../icons/Gerator/dashboard/sliders02.svg';


import { SortByFilterComponent } from '../../SortByFilterComponent/SortByFilterComponent';
import RadarFilterButtonGroup from '../../RadarFilterButtonGroup/RadarFilterButtonGroup';
import { TransactionTypeComponent } from '../../TransactionTypeComponent/TransactionTypeComponent';
import { ThreeDotsListingComponent } from '../../ThreeDotsListingComponent/ThreeDotsListingComponent';
import { Link } from 'react-router-dom';
import { PostStatusLIstingComponent } from '../../PostStatusLIstingComponent/PostStatusLIstingComponent';
import { useGetUserDashboardDevicesQuery } from '../../../api/DeviceAPI';
import { NoListingFound } from '../../NoListing/NoListingFound';
import SkeletonListingLoaderComponent from '../../SkeletonListingLoaderComponent/SkeletonListingLoaderComponent';
import { getCustomizedDate } from '../../../helper/DeviceHelper';
import { useGetPostTypeTaxQuery } from '../../../api/TaxonomyFormAPI';
import SearchInputListingComponent from '../../SearchInputListingComponent/SearchInputListingComponent';
import { useGetUserDashboardWantedDevicesQuery } from '../../../api/WantedDeviceAPI';


// Temporary 

import playstation from '../../../icons/Gerator/sales_iq/playstation.jpeg';
import radar_1_1_2x from '../../../icons/Gerator/sales_iq/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/sales_iq/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/sales_iq/magnifier-1@2x.png';
import arrowup from "../../../icons/Gerator/sales_iq/arrowup.svg";
import _chart_mini2 from "../../../icons/Gerator/sales_iq/-chart-mini2@2x.png"
import FunnelDeviceCardTabs from '../../FunnelCardTabs/FunnelDeviceCardTabs';
import { useFindGetAllCountFunnelDashboardQuery, useLazyFindGetAllCountFunnelDashboardQuery } from '../../../api/Funnel/FunnelWantedDeviceAPI';
import { ErrorShowComponent } from '../../ErrorShowComponent/ErrorShowComponent';
import { ThreeDotsNoActionComponent } from '../../ThreeDotsListingComponent/ThreeDotsNoActionComponent';


export const FunnelDeviceComponent = () => {

  const [activeThreeDotIdNoAction, setActiveThreeDotIdNoAction] = useState(null);
  const threeDotsRefNoAction = useRef([]);
  const [isClickBlockedNoAction, setIsClickBlockedNoAction] = useState(false);

  const toggleThreeDotsDropdownNoAction = (index) => {
    if (isClickBlockedNoAction) return; // Prevent rapid toggle



    if (activeThreeDotIdNoAction === index) {
      setActiveThreeDotIdNoAction(null); // Close if the same dropdown is clicked again
    } else {
      setActiveThreeDotIdNoAction(index); // Open the dropdown
    }

    setIsClickBlockedNoAction(true);
    setTimeout(() => setIsClickBlockedNoAction(false), 400);
  };


  useEffect(() => {
    const handleThreeDotClickOutsideNoAction = (event) => {

      const isLink = event.target.closest('a');

      if (isLink) {
        return; // Allow navigation to happen
      }

      if (threeDotsRefNoAction.current[activeThreeDotIdNoAction] && !threeDotsRefNoAction.current[activeThreeDotIdNoAction].contains(event.target)) {
        setActiveThreeDotIdNoAction(null); // Close any open dropdown when clicking outside
      }
    };

    document.addEventListener("mousedown", handleThreeDotClickOutsideNoAction);
    return () => {
      document.removeEventListener("mousedown", handleThreeDotClickOutsideNoAction);
    };
  }, []);



  // useEffect(() => {
  //   const handleThreeDotClickOutsideNoAction = (event) => {

  //     const isLink = event.target.closest('a');

  //     if (isLink) {
  //       return; // Allow navigation to happen
  //     }

  //     if (threeDotsRefNoAction.current && !threeDotsRefNoAction.current.contains(event.target)) {
  //       setActiveThreeDotIdNoAction(null); // Close any open dropdown when clicking outside
  //     }
  //   };

  //   document.addEventListener("mousedown", handleThreeDotClickOutsideNoAction);
  //   return () => {
  //     document.removeEventListener("mousedown", handleThreeDotClickOutsideNoAction);
  //   };
  // }, []);

  //Offset to load data when click load more


  const [offset, setOffset] = useState(0);
  const [sortBy, setSortBy] = useState('date_desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [totalCount, setTotalCount] = useState("");
  const [totalCountWantedSpare, settotalCountWantedSpare] = useState("");
  const [totalCountWantedService, settotalCountWantedService] = useState("");
  const [totalCountWantedSoftware, settotalCountWantedSoftware] = useState("");

  const [activePostStatus, setActivePostStatusFilter] = useState(null);
  //Query to get data
  const { data, isSuccess, isError, refetch, isLoading, isFetching } = useFindGetAllCountFunnelDashboardQuery();

  //flag var to show listing when loading complete when sekelteon layout is going to stop
  const [loadingComplete, setLoadingComplete] = useState(false);

  //Var is reponsible to show listings and other thing as well
  let content;

  //Sort by text show on button
  const [selectedSortOption, setSelectedSortOption] = useState('');

  //For SortBy Dropdown
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  //For Threedots in listing dropdown
  const [activeThreeDotId, setActiveThreeDotId] = useState(null);
  const threeDotsRef = useRef(null);
  const [isClickBlocked, setIsClickBlocked] = useState(false);



  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  }

  const toggleThreeDotsDropdown = (id) => {
    if (isClickBlocked) return; // Prevent rapid toggle

    if (activeThreeDotId === id) {
      setActiveThreeDotId(null); // Close if the same one is clicked again
      setIsClickBlocked(true);
      setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
    } else {
      setActiveThreeDotId(id); // Open the clicked one
    }
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };


  //Set value for sortby selected option on button text
  const handleOptionSelect = (option) => {
    if (option.value !== sortBy) {
      setSortBy(option.value);
      setOffset(0); // Reset offset when sort option changes
    }
    setSelectedSortOption(option);
    setDropdownVisible(false);
  };

  //DashbaordFilterButtonGroup component to get data
  const handleFilterClick = (filterLabel) => {
    if (activePostStatus === filterLabel) {
      setSortBy('date_desc')
      setSelectedSortOption('')
      setActivePostStatusFilter(null);
    } else {
      setSortBy('date_desc')
      setSelectedSortOption('')
      setActivePostStatusFilter(filterLabel);
    }
    setOffset(0); // Reset offset when changing filters
  };

  //Setting offset for loadmore when user clicked on load more button(this trigger query to fetch new data)
  // Reset offset when component mounts or remounts
  // useEffect(() => {
  //   setOffset(0);
  // }, []);
  // // Reset offset when sort option changes
  // useEffect(() => {
  //   setOffset(0);
  // }, [sortBy]);

  const handleLoadMore = () => {
    if (data?.hasMore && !isFetching) {
      setOffset(currentOffset => currentOffset + 5);
    }
  };

  const showLoadMore = data?.hasMore &&
    data.wanted_devices.length < data.totalCount &&
    !isFetching;





  //Onclick listener for sortby dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  //Onclick listener for threedots dropdown
  useEffect(() => {
    const handleThreeDotClickOutside = (event) => {
      if (threeDotsRef.current && !threeDotsRef.current.contains(event.target)) {
        setActiveThreeDotId(null); // Close any open dropdown when clicking outside
      }
    };

    document.addEventListener("mousedown", handleThreeDotClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleThreeDotClickOutside);
    };
  }, []);

  // Listen for tab visibility changes
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refetch();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [refetch]);


  //Success true then pause to show sekelton layout
  useEffect(() => {
    if (isSuccess) {

      setLoadingComplete(true);

    }
  }, [isSuccess]);


  // if (isError) {
  //   content = <NoListingFound path={"/add-wanted-device"} pathName={"Wanted Device"} />
  // }

  // if (isLoading) {
  //   content = <SkeletonListingLoaderComponent />;
  // } else if (loadingComplete && isSuccess) {

  //   // if (data.wanted_devices.length > 0) {
  //   //   // dataLength = data.wanted_devices.length;
  //   //   content = data.wanted_devices.map((wantedDevice, index) =>
  //   //   (

  //   //   div

  //   //   ))
  //   // }
  //   // else {
  //   //   content = <NoListingFound path={"/add-wanted-device"} pathName={"Wanted Device"} />
  //   // }

  // } else if (!loadingComplete && isSuccess) {
  //   content = <SkeletonListingLoaderComponent />; // Show loader until loadingComplete is true
  // }



  if (isSuccess) {
    return (
      <>
        <div className="funnel-frame-wrapper">
          <div className="funnel-frame-group">
            <div className="funnel-rectangle-group">
              <div className="funnel-frame-item"></div>
              <div className="funnel-metric-card-row">
                <div className="funnel-metric-card">
                  <div className="funnel-metric-card-title">
                    <div className="funnel-heading4">Devices</div>
                  </div>
                  <div className="funnel-dropdown3" style={{ cursor: "pointer" }} onClick={() => toggleThreeDotsDropdownNoAction(0)} ref={(el) => threeDotsRefNoAction.current[0] = el}>
                    <img
                      className="funnel-dots-vertical-icon"
                      alt=""
                      src={dotsvertical}
                    />
                    {activeThreeDotIdNoAction === 0 && <ThreeDotsNoActionComponent />}

                  </div>
                </div>
                <div className="funnel-number3">{data.totalCountFunnelDevice || 0}</div>
              </div>
              {/* <div className="funnel-badge11">
              <div className="funnel-options-icon">
                <img
                  className="funnel-arrow-up-icon2"
                  alt=""
                  src={arrowup1}
                />
              </div>
              <div className="funnel-text58">20 slots available</div>
            </div> */}
            </div>
            <div className="funnel-rectangle-container">
              <div className="funnel-frame-inner"></div>
              <div className="funnel-frame-container">
                <div className="funnel-heading-parent">
                  <div className="funnel-heading5">Spares</div>
                  <div className="funnel-number4">{data.totalCountFunnelSpare || 0}</div>
                  {/* <div className="funnel-badge12">
                  <div className="funnel-options-icon">
                    <img
                      className="funnel-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="funnel-change-amount">20%</div>
                </div> */}
                </div>
              </div>
              <div className="funnel-dropdown3" style={{ cursor: "pointer" }} onClick={() => toggleThreeDotsDropdownNoAction(1)} ref={(el) => threeDotsRefNoAction.current[1] = el}>
                <img
                  className="funnel-dots-vertical-icon"
                  alt=""
                  src={dotsvertical}
                />
                {activeThreeDotIdNoAction === 1 && <ThreeDotsNoActionComponent />}

              </div>
            </div>
            <div className="funnel-rectangle-container">
              <div className="funnel-frame-inner"></div>
              <div className="funnel-options-icon">
                <div className="funnel-heading-group">
                  <div className="funnel-heading5">Services</div>
                  <div className="funnel-number4">{data.totalCountFunnelService || 0}</div>
                  {/* <div className="funnel-badge12">
                  <div className="funnel-options-icon">
                    <img
                      className="funnel-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="funnel-change-amount">20%</div>
                </div> */}
                </div>
              </div>
              <div className="funnel-dropdown3" style={{ cursor: "pointer" }} onClick={() => toggleThreeDotsDropdownNoAction(2)} ref={(el) => threeDotsRefNoAction.current[2] = el}>
                <img
                  className="funnel-dots-vertical-icon"
                  alt=""
                  src={dotsvertical}
                />
                {activeThreeDotIdNoAction === 2 && <ThreeDotsNoActionComponent />}

              </div>
            </div>
            <div className="funnel-rectangle-container">
              <div className="funnel-frame-inner"></div>
              <div className="funnel-frame-container">
                <div className="funnel-heading-container">
                  <div className="funnel-heading5">Software</div>
                  <div className="funnel-number4">{data.totalCountFunnelSoftware || 0}</div>
                  {/* <div className="funnel-badge12">
                  <div className="funnel-options-icon">
                    <img
                      className="funnel-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="funnel-change-amount">20%</div>
                </div> */}
                </div>
              </div>
              <div className="funnel-dropdown3" style={{ cursor: "pointer" }} onClick={() => toggleThreeDotsDropdownNoAction(3)} ref={(el) => threeDotsRefNoAction.current[3] = el}>
                <img
                  className="funnel-dots-vertical-icon"
                  alt=""
                  src={dotsvertical}
                />
                {activeThreeDotIdNoAction === 3 && <ThreeDotsNoActionComponent />}

              </div>
            </div>
            <div className="funnel-rectangle-container">
              <div className="funnel-frame-inner"></div>
              <div className="funnel-frame-container">
                <div className="funnel-heading-container">
                  <div className="funnel-heading5">Jobs</div>
                  <div className="funnel-number4">{data.totalCountFunnelJob || 0}</div>
                  {/* <div className="funnel-badge12">
                  <div className="funnel-options-icon">
                    <img
                      className="funnel-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="funnel-change-amount">20%</div>
                </div> */}
                </div>
              </div>
              <div className="funnel-dropdown3" style={{ cursor: "pointer" }} onClick={() => toggleThreeDotsDropdownNoAction(4)} ref={(el) => threeDotsRefNoAction.current[4] = el}>
                <img
                  className="funnel-dots-vertical-icon"
                  alt=""
                  src={dotsvertical}
                />
                {activeThreeDotIdNoAction === 4 && <ThreeDotsNoActionComponent />}

              </div>
            </div>
          </div>
        </div>


        <div className="funnel-dropdowns">

          <FunnelDeviceCardTabs />

        </div>


        {/* Load more button  */}
        <div className="funnel-frame-wrapper44">
          <div className="funnel-frame-parent72">
            <div className="funnel-buttonsbutton-wrapper1">
              {showLoadMore && (
                <button className='funnel-buttonsbutton-wrapper1-child'
                  onClick={handleLoadMore}
                  disabled={isFetching}
                >
                  <div className="funnel-buttonsbutton28">
                    <img
                      className="funnel-arrow-narrow-left-icon"
                      alt=""
                      src={arrowdown1}
                    />
                    <div className="funnel-search-input">
                      <div className="funnel-text146">{isFetching ? 'Loading...' : 'Load More'}</div>
                    </div>
                  </div>
                </button>
              )}
            </div>
          </div>
        </div>
      </>
    )

  } else if (isError) {
    return <ErrorShowComponent message1={"Internal server error"} />
  }
}

