
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./ChatPage.css"; // Correct relative path

import { firestore, doc, getDoc, auth, onAuthStateChanged } from "../Login/FirebaseClient";
import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import { SideBarSubNormal } from '../../components/SideBars-Sub/SideBarSubChatPage/SideBarSubChatPae';
import { Footer } from "../../components/Footer/Footer";
import { Sidebar } from "../../components/Sidebar/Sidebar";
import { Navbar1 } from "../../components/Navbar1/Navbar1";
import { TitleHeader } from "../../components/ChatPageComponentOwner/TitleHeader"
import { ChatPanel } from "../../components/ChatPageComponentOwner/ChatPanelMain/ChatPanel"
import { Analytics } from "../../components/ChatPageComponentOwner/Analytics"
import { useAuthenticateChannelMutation, useSendMessageMutation, useLazyFindGetDeviceQuery } from '../../api/ChatAPI';


import divider from "../../icons/Gerator/chat_page/divider.svg";
import divider3 from "../../icons/Gerator/dashboard/divider3.svg";
import { ErrorShowComponent } from "../../components/ErrorShowComponent/ErrorShowComponent";
import { useLazyGetProfileImageLinkQuery } from "../../api/Login&RegisterAPI";
import SkeletonListingChatOwnerLoaderComponent from "../../components/SkeletonLoaderComponent/SkeletonListingChatOwnerLoaderComponent/SkeletonListingChatOwnerLoaderComponent";
import SkeletonChatLoaderComponent from "../../components/SkeletonLoaderComponent/SkeletonChatLoaderComponent/SkeletonChatLoaderComponent";


const ChatPageDeviceOwner = () => {

  let content;

  //flag var to show listing when loading complete when sekelteon layout is going to stop
  const [loadingComplete, setLoadingComplete] = useState(false);

  const [isSubSideBarVisible, setSubSideBarVisible] = useState(true);
  // stores the current authenticated user
  const [currentUser, setCurrentUser] = useState(null);

  // stores user metadata (fetched from Firestore)
  const [currentUsermeta, setCurrentUsermeta] = useState(null);

  //Stores DP link 
  const [profileImageLink, setProfileImageLink] = useState("");

  // Navigation hook from React Router
  const navigate = useNavigate();

  // RTK Query hooks
  const [authenticateChannel] = useAuthenticateChannelMutation();
  const [sendMessage] = useSendMessageMutation();

  const { id, entity } = useParams();
  // const [triggerGetUser, { data, isSuccess, error, isLoading, isError }] = useLazyGetUserQuery();
  const [triggerGetDevice, { data: deviceData,
    isLoading: isDeviceLoading,
    isSuccess: isDeviceSuccess,
    isError: isDeviceError,
    error: deviceError }] = useLazyFindGetDeviceQuery(id);

  const [triggerNewProfileImageLink] = useLazyGetProfileImageLinkQuery();


  const [analyticsLoaded, setAnalyticsLoaded] = useState(false); // To track if Analytics has finished loading

  // Effect hook to handle user authentication and metadata fetching
  useEffect(() => {
    //checking if the auth state has changed
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) { //if user is true/present
        console.log(user);

        setCurrentUser(user); // Stores the user

        try {
          // Fetch user metadata from Firestore
          const userDocRef = doc(firestore, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const userMeta = userDocSnapshot.data();

            if (userMeta.userStatus == 2) {
              navigate('/account-disabled');
              return;
            }

            if (userMeta.userType == 5) {
              navigate('/access-denied');
              return;
            }


            setCurrentUsermeta(userMeta);

            console.log(userMeta);

            // If the user has a profile image, set it
            if (userMeta.profileImage?.url) {
              setProfileImageLink(userMeta.profileImage.url);
            }
          }
        } catch (error) {
          console.error("Error fetching user metadata:", error);
        }
      } else {
        // Redirect unauthenticated users to the sign-in page
        navigate("/sign-in", { state: { message: "Please log in to continue." } });
      }
    });

    // Cleanup the listener on component unmount
    return () => unsubscribe();
  }, [navigate]);

  //Profile image load useEffect
  useEffect(() => {
    const refreshProfileImage = async () => {
      if (currentUsermeta && currentUsermeta.profileImage.expiresAt < Date.now() / 1000) {
        // Pre-signed URL has expired, trigger a new request to the backend
        const newImageData = await triggerNewProfileImageLink().unwrap();
        // Update the profile image link directly
        setProfileImageLink(newImageData.url);
      } else if (currentUsermeta && currentUsermeta.profileImage.url) {
        // Use the existing pre-signed URL to display the profile image
        setProfileImageLink(currentUsermeta.profileImage.url);
      }
    };

    refreshProfileImage();
  }, [currentUsermeta]);


  // TRIGGERING PROFILE FETCHING
  useEffect(() => {
    if (currentUser) {
      triggerGetDevice(id);
    }
  }, [currentUser, triggerGetDevice, id]);



  //Success true then pause to show sekelton layout
  useEffect(() => {
    if (isDeviceSuccess) {
      // Set timeout to delay the loading completion
      const timer = setTimeout(() => {
        setLoadingComplete(true);
      }, 1000); // 1 second

      // Cleanup timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [isDeviceSuccess]);


  const handleAnalyticsLoad = () => {
    setAnalyticsLoaded(true); // Mark Analytics as loaded
  };

  if (isDeviceError) {
    content = <ErrorShowComponent message1={"Oops! we are facing some issue"} />
  }


  if (isDeviceLoading) {
    content =
      <>
        <SkeletonListingChatOwnerLoaderComponent />
        <SkeletonChatLoaderComponent />
      </>;
  } else if (loadingComplete && isDeviceSuccess) {
    content = <>
      <SkeletonListingChatOwnerLoaderComponent />
      <SkeletonChatLoaderComponent />
    </>;
    // {/* CONDITIONAL RENDERING */ }
    {
      deviceData && (
        content = <>
          {/* ANALYTICS */}
          {deviceData && <Analytics listingData={deviceData.data} onLoad={handleAnalyticsLoad} />}
          {/* CHAT PANEL */}
          {deviceData && analyticsLoaded && (<ChatPanel currentUserId={currentUsermeta?.userId}
            profileImageLink={profileImageLink} listingData={deviceData.data} />)}
        </>
      )
      // : (
      //   // Render DeviceNotFound if deviceData is not available
      //   content = <DeviceNotFound />
      // )
    }


  } else if (!loadingComplete && isDeviceSuccess) {
    content = <>
      <SkeletonListingChatOwnerLoaderComponent />
      <SkeletonChatLoaderComponent />
    </>; // Show loader until loadingComplete is true
  }


  return (
    <div>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"
      />
      <div className="chatB-messaging-with-list-view-car">
        <div className="chatB-messaging-with-list-view-car-child" />
        <main className="chatB-frame-parent">
          {/* HEADER */}
          <Helmet>
            <title>Messaging</title>
          </Helmet>

          <Navbar1 userData={currentUser} navigate={navigate} profileImageLink={profileImageLink} />
          <img className="chatB-divider-icon" loading="lazy" alt="" src={divider} />
          <section className="chatB-sidebar-navigation-parent">
            {/* Sidebar */}
            <Sidebar profileImageLink={profileImageLink}>
              {/* <SideBarSubNormal userData={currentUser} /> */}
            </Sidebar>
            {/* {profileImageLink && (
            )} */}

            <div className="chatB-main-content-wrapper">
              <div className="chatB-main-content">
                {/* TITLE HEADER */}
                <TitleHeader />
                {content}
              </div>
            </div>
          </section>
        </main>
        {/* FOOTER */}
        <img className="dashboard-divider-icon6" alt="" src={divider} />

        <Footer />

      </div>
    </div>
  );

}
export { ChatPageDeviceOwner };

//       return (
//         <div>
//           <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap" />
//           <div className="chatB-messaging-with-list-view-car">
//             <div className="chatB-messaging-with-list-view-car-child" />
//             <main className="chatB-frame-parent">
//               {/* HEADER */}
//               <Helmet>
//         <title>Messaging</title>
//       </Helmet>

// {profileImageLink &&  <Navbar1 profileImageLink={profileImageLink}/>}
//               <img className="chatB-divider-icon" loading="lazy" alt="" src={divider} />
//               <section className="chatB-sidebar-navigation-parent">

//         {/* NavBar */}


//         {profileImageLink && <Sidebar profileImageLink={profileImageLink}>
// <SideBarSubNormal
// userData={currentUser}
// />
// </Sidebar>}
//                 <div className="chatB-main-content-wrapper">
//                   <div className="chatB-main-content">
//                     {/* TITLE HEADER */}
//                     <TitleHeader />
//                     {/* ANALYTICS */}
//                     {currentUser && <Analytics deviceData={deviceData} AddedToRadarCount={radarCount} viewCount={viewCount} />}

//                     {/* <Analytics currentUser={currentUser} /> */}
//                     {/* CHAT PANEL */}
//                     {currentUser &&(<ChatPanel currentUser={currentUser} profileImageLink={profileImageLink} deviceData={deviceData}/>)}
//                   </div>
//                 </div>
//               </section>
//             </main>
//             {/* FOOTER */}
//             <Footer/>
//           </div>
//         </div>
//       );
// return (
//   <div>
//     <link
//       rel="stylesheet"
//       href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"
//     />
//     <div className="chatB-messaging-with-list-view-car">
//       <div className="chatB-messaging-with-list-view-car-child" />
//       <main className="chatB-frame-parent">
//         {/* HEADER */}
//         <Helmet>
//           <title>Messaging</title>
//         </Helmet>

//         {profileImageLink && <Navbar1 profileImageLink={profileImageLink} />}
//         <img
//           className="chatB-divider-icon"
//           loading="lazy"
//           alt=""
//           src={divider}
//         />
//         <section className="chatB-sidebar-navigation-parent">
//           {/* NavBar */}
//           {profileImageLink && (
//             <Sidebar profileImageLink={profileImageLink}>
//               <SideBarSubNormal userData={currentUser} />
//             </Sidebar>
//           )}

//           <div className="chatB-main-content-wrapper">
//             <div className="chatB-main-content">
//               {/* TITLE HEADER */}
//               <TitleHeader />

//               {/* CONDITIONAL RENDERING */}
//               {deviceData ? (
//                 <>
//                   {/* ANALYTICS */}
//                   {currentUser && (
//                     <Analytics
//                       deviceData={deviceData}
//                       AddedToRadarCount={radarCount}
//                       viewCount={viewCount}
//                     />
//                   )}

//                   {/* CHAT PANEL */}
//                   {currentUser && (
//                     <ChatPanel
//                       currentUser={currentUser}
//                       profileImageLink={profileImageLink}
//                       deviceData={deviceData}
//                     />
//                   )}
//                 </>
//               ) : (
//                 // Show DeviceNotFound if deviceData is not valid
//                 <DeviceNotFound />
//               )}
//             </div>
//           </div>
//         </section>
//       </main>
//       {/* FOOTER */}
//       <Footer />
//     </div>
//   </div>
// );
