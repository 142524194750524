// import React from "react";
// import "../ChatPage.css"; // Correct relative path

// import arrowright from "../../../icons/Gerator/chat_page/arrowright.svg";
// import dot2 from "../../../icons/Gerator/chat_page/-dot2.svg";
// import facesmile from "../../../icons/Gerator/chat_page/facesmile.svg";
// import arrowleft from "../../../icons/Gerator/chat_page/arrowleft.svg";
// import avatar2 from "../../../icons/Gerator/chat_page/avatar2.svg";
// import dotsvertical from "../../../icons/Gerator/chat_page/dotsvertical.svg";
// import divider2 from "../../../icons/Gerator/chat_page/divider2.svg";
// import divider3 from "../../../icons/Gerator/chat_page/divider-3.svg";
// import dotshorizontal from "../../../icons/Gerator/chat_page/dotshorizontal.svg";
// import uploadcloud02 from "../../../icons/Gerator/chat_page/uploadcloud02.svg";
// import videorecorder from "../../../icons/Gerator/chat_page/videorecorder.svg";
// import phonecall01 from "../../../icons/Gerator/chat_page/phonecall01.svg";
// import filterlines from "../../../icons/Gerator/chat_page/filterlines.svg";
// import placeholder1 from "../../../icons/Gerator/chat_page/placeholder1.svg";
// import placeholder2 from "../../../icons/Gerator/chat_page/placeholder2.svg";
// import placeholder3 from "../../../icons/Gerator/chat_page/placeholder3.svg";
// import placeholder from "../../../icons/Gerator/chat_page/placeholder.svg";
// import pageIcon from "../../../icons/Gerator/chat_page/page.svg";

// import searchlg from "../../../icons/Gerator/chat_page/searchlg.svg";

// const MessageBody = () => {
//   return (


//         <div className="chatB-container1">
//           <div className="chatB-content-divider1">
//             <div className="chatB-divider-wrapper">
//               <div className="chatB-divider" />
//             </div>
//             <div className="chatB-divider-label">Thursday</div>
//             <div className="chatB-divider-wrapper">
//               <div className="chatB-divider" />
//             </div>
//           </div>
//           <div className="chatB-messages-parent">
//             <div className="chatB-messages">
//               <div className="chatB-message7">
//                 <div className="chatB-avatar2">
//                   <div className="chatB-contrast-border2" />
//                   <div className="chatB-avatar-online-indicator" />
//                 </div>
//                 <div className="chatB-content8">
//                   <div className="chatB-name-and-time">
//                     <div className="chatB-usernames">Katherine Moss</div>
//                     <div className="chatB-sub-text-wrapper">
//                       <div className="chatB-sub-text">Thursday 10:16am</div>
//                     </div>
//                   </div>
//                   <div className="chatB-input2">
//                     <div className="chatB-text31">
//                       Thanks Olivia! Almost there. I’ll work on
//                       making those changes you suggested and will
//                       shoot it over.
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="chatB-message7">
//                 <div className="chatB-avatar2">
//                   <div className="chatB-contrast-border2" />
//                   <div className="chatB-avatar-online-indicator" />
//                 </div>
//                 <div className="chatB-content8">
//                   <div className="chatB-name-and-time">
//                     <div className="chatB-file-username">Katherine Moss</div>
//                     <div className="chatB-sub-text-container">
//                       <div className="chatB-sub-text1">Thursday 11:40am</div>
//                     </div>
//                   </div>
//                   <div className="chatB-input2">
//                     <div className="chatB-text33">
//                       Hey Olivia, I’ve finished with the
//                       requirements doc! I made some notes in the
//                       gdoc as well for Phoenix to look over.
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="chatB-message7">
//                 <div className="chatB-avatar2">
//                   <div className="chatB-contrast-border2" />
//                   <div className="chatB-avatar-online-indicator" />
//                 </div>
//                 <div className="chatB-content10">
//                   <div className="chatB-name-and-time2">
//                     <div className="chatB-file-username">Katherine Moss</div>
//                     <div className="chatB-sub-text-container">
//                       <div className="chatB-sub-text1">Thursday 11:40am</div>
//                     </div>
//                   </div>
//                   <div className="chatB-input4">
//                     <div className="chatB-content11">
//                       <div className="chatB-file-type-icon">
//                         <img className="chatB-page-icon" loading="lazy" alt="" src={pageIcon}/>
//                         <div className="chatB-file-type-wrap">
//                           <b className="chatB-file-type">PDF</b>
//                         </div>
//                       </div>
//                       <div className="chatB-content12">
//                         <div className="chatB-text-and-supporting-text9">
//                           <div className="chatB-text34">
//                             Tech requirements.pdf
//                           </div>
//                           <div className="chatB-supporting-text18">
//                             1.2 MB
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="chatB-row">
//                 <div className="chatB-message10">
//                   <div className="chatB-content13">
//                     <div className="chatB-name-and-time3">
//                       <div className="chatB-single-message-username">You</div>
//                       <div className="chatB-sub-text3">Thursday 11:41am</div>
//                     </div>
//                     <div className="chatB-input5">
//                       <div className="chatB-text35">
//                         Awesome! Thanks. I’ll look at this today.
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="chatB-avatar-message">
//                 <div className="chatB-message11">
//                   <div className="chatB-avatar2">
//                     <div className="chatB-contrast-border2" />
//                     <div className="chatB-avatar-online-indicator" />
//                   </div>
//                   <div className="chatB-content8">
//                     <div className="chatB-name-and-time">
//                       <div className="chatB-file-username">
//                         Katherine Moss
//                       </div>
//                       <div className="chatB-sub-text-container">
//                         <div className="chatB-sub-text1">
//                           Thursday 11:44am
//                         </div>
//                       </div>
//                     </div>
//                     <div className="chatB-input2">
//                       <div className="chatB-text33">
//                         No rush though — we still have to wait for
//                         Lana’s designs.
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="chatB-second-content-divider">
//                 <div className="chatB-content-divider2">
//                   <div className="chatB-divider-frame">
//                     <div className="chatB-divider" />
//                   </div>
//                   <div className="chatB-second-divider-label">Today</div>
//                   <div className="chatB-divider-frame">
//                     <div className="chatB-divider" />
//                   </div>
//                 </div>
//               </div>
//               <div className="chatB-message12">
//                 <div className="chatB-avatar2">
//                   <div className="chatB-contrast-border2" />
//                   <div className="chatB-avatar-online-indicator" />
//                 </div>
//                 <div className="chatB-content8">
//                   <div className="chatB-name-and-time">
//                     <div className="chatB-file-username">Katherine Moss</div>
//                     <div className="chatB-sub-text-container">
//                       <div className="chatB-sub-text5">Today 2:20pm</div>
//                     </div>
//                   </div>
//                   <div className="chatB-input2">
//                     <div className="chatB-text33">
//                       Hey Olivia, can you please review the latest
//                       design when you can?
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="chatB-row">
//                 <div className="chatB-message13">
//                   <div className="chatB-content13">
//                     <div className="chatB-name-and-time6">
//                       <div className="chatB-reaction-username">You</div>
//                       <div className="chatB-sub-text6">Just now</div>
//                     </div>
//                     <div className="chatB-input5">
//                       <div className="chatB-text35">
//                         Sure thing, I’ll have a look today. They’re
//                         looking great!
//                       </div>
//                     </div>
//                     <div className="chatB-row2">
//                       <div className="chatB-message-reaction">
//                         <img className="chatB-emoji-icon" loading="lazy" alt="" src="./public/emoji@2x.png" />
//                       </div>
//                       <div className="chatB-message-reaction">
//                         <img className="chatB-emoji-icon" alt="" src="./public/emoji-1@2x.png" />
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="chatB-message7">
//                 <div className="chatB-avatar2">
//                   <div className="chatB-contrast-border2" />
//                   <div className="chatB-avatar-online-indicator" />
//                 </div>
//                 <div className="chatB-content8">
//                   <div className="chatB-text34">Katherine Moss</div>
//                   <div className="chatB-input9">
//                     <div className="chatB-loading-dot-frame">
//                       <div className="chatB-loading-dot" />
//                     </div>
//                     <div className="chatB-loading-dot-frame1">
//                       <div className="chatB-loading-dot1" />
//                     </div>
//                     <div className="chatB-loading-dot-frame">
//                       <div className="chatB-loading-dot" />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="chatB-separator">
//               <div className="chatB-message-separator" />
//             </div>
//           </div>
//         </div>
//         // CHAT MAIN BODY

//   );
// }

// export {MessageBody};



// import React from "react";
// import "../ChatPage.css"; // Correct relative path
// import pageIcon from "../../../icons/Gerator/chat_page/page.svg";

// const MessageBody = ({ messages, currentUserId }) => {
//   return (
//     <div className="chatB-container1">
//       <div className="chatB-messages-parent">
//         <div className="chatB-messages">
//           {messages.map((message, index) => (
//             <div
//               key={index}
//               className={`chat-message7 ${
//                 message.sender === currentUserId.username
//                   ? "chatB-message-right"
//                   : "chatB-message-left"
//               }`}
//             >
//               {/* Display Avatar for Received Messages */}
//               {message.sender !== currentUserId.username && (
//                 <div className="chatB-avatar2">
//                   <div className="chatB-contrast-border2" />
//                   <div className="chatB-avatar-online-indicator" />
//                 </div>
//               )}
//               {/* Message Content */}
//               <div className="chatB-content8">
//                 <div className="chatB-name-and-time">
//                   <div className="chatB-usernames">
//                     {message.sender === currentUserId.username ? "You" : message.sender}
//                   </div>
//                   <div className="chatB-sub-text-wrapper">
//                     <div className="chatB-sub-text">{message.timestamp}</div>
//                   </div>
//                 </div>
//                 <div className="chatB-input2">
//                   <div className="chatB-text31">{message.content}</div>
//                 </div>
//               </div>
//               {/* Add space for Sent Messages */}
//               {message.sender === currentUserId.username && <div className="chatB-empty-space" />}
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export { MessageBody };
// import React from "react";
// import "../ChatPage.css"; // Correct relative path
// import pageIcon from "../../../icons/Gerator/chat_page/page.svg";

// const MessageBody = ({ messages, currentUserId }) => {
//   return (
//     <div className="chatB-container1">
//       <div className="chatB-content-divider1">
//         <div className="chatB-divider-wrapper">
//           <div className="chatB-divider" />
//         </div>
//         <div className="chatB-divider-label">Messages</div>
//         <div className="chatB-divider-wrapper">
//           <div className="chatB-divider" />
//         </div>
//       </div>
//       <div className="chatB-messages-parent">
//         <div className="chatB-messages">
//           {messages.map((message, index) => (
//             <div
//               key={index}
//               className={`${
//                 message.sender === currentUserId.first_name
//                   ? "row"
//                   : "chatB-message7"
//               }`}
//             >
//               {/* Avatar and Online Indicator */}
//               {message.sender !== currentUserId.first_name && (
//                 <div className="chatB-avatar2">
//                   <div className="chatB-contrast-border2" />
//                   <div className="chatB-avatar-online-indicator" />
//                 </div>
//               )}
//               {/* Message Content */}
//               <div className="chatB-content8">
//                 <div className="chatB-name-and-time">
//                   <div className="chatB-usernames">
//                     {message.sender === currentUserId.first_name ? "You" : message.sender}
//                   </div>
//                   <div className="chatB-sub-text-wrapper">
//                     <div className="chatB-sub-text">{message.timestamp || "Just now"}</div>
//                   </div>
//                 </div>
//                 <div className="chatB-input2">
//                   {message.type === "file" ? (
//                     <div className="chatB-content11">
//                       <div className="chatB-file-type-icon">
//                         <img className="chatB-page-icon" alt="file" src={pageIcon} />
//                         <div className="chatB-file-type-wrap">
//                           <b className="chatB-file-type">File</b>
//                         </div>
//                       </div>
//                       <div className="chatB-content12">
//                         <div className="chatB-text-and-supporting-text9">
//                           <div className="chatB-text34">{message.content}</div>
//                         </div>
//                       </div>
//                     </div>
//                   ) : (
//                     <div className="chatB-text31">{message.content}</div>
//                   )}
//                 </div>
//               </div>
//               {/* Empty Space for Sent Messages */}
//               {message.sender === currentUserId.first_name && <div className="chatB-empty-space" />}
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export { MessageBody };

// import React from "react";
// import "../ChatPage.css"; // Correct relative path

// const MessageBody = ({ messages, currentUserId }) => {

//   console.log("Message sender:", messages);
//   return (
//     <div className="chatB-container1">
//       <div className="chatB-content-divider1">
//         <div className="chatB-divider-wrapper">
//           <div className="chatB-divider" />
//         </div>
//         <div className="chatB-divider-label">Messages</div>
//         <div className="chatB-divider-wrapper">
//           <div className="chatB-divider" />
//         </div>
//       </div>
//       <div className="chatB-messages-parent">
//         <div className="chatB-messages">
//           {messages.map((message, index) => (
//             <div
//               key={index}
//               className={`${
//                 message.sender === currentUserId.first_name
//                   ? "chatB-row"
//                   : "chatB-message12"
//               }`}
//             >
//               {/* Avatar and Online Indicator for Received Messages */}
//               {message.sender !== currentUserId.first_name && (
//                 <div className="chatB-avatar2">
//                   <div className="chatB-contrast-border2" />
//                   <div className="chatB-avatar-online-indicator" />
//                 </div>
//               )}
//               {/* Message Content */}
//               <div
//                 className={`${
//                   message.sender === currentUserId.first_name
//                     ? "chatB-content13"
//                     : "chatB-content8"
//                 }`}
//               >
//                 <div
//                   className={`${
//                     message.sender === currentUserId.first_name
//                       ? "name-and-time3"
//                       : "name-and-time"
//                   }`}
//                 >
//                   <div
//                     className={`${
//                       message.sender === currentUserId.first_name
//                         ? "single-message-username"
//                         : "chatB-usernames"
//                     }`}
//                   >
//                     {message.sender === currentUserId.first_name ? "You" : message.sender}
//                   </div>
//                   <div className="chatB-sub-text-wrapper">
//                     <div
//                       className={`${
//                         message.sender === currentUserId.first_name
//                           ? "sub-text3"
//                           : "chatB-sub-text"
//                       }`}
//                     >
//                       {message.timestamp || "Just now"}
//                     </div>
//                   </div>
//                 </div>
//                 <div
//                   className={`${
//                     message.sender === currentUserId.first_name
//                       ? "chatB-input5"
//                       : "chatB-input2"
//                   }`}
//                 >
//                   <div className="chatB-text31">{message.content}</div>
//                 </div>
//               </div>
//               {/* Empty Space for Sent Messages */}
//               {message.sender === currentUserId.first_name && (
//                 <div className="chatB-empty-space" />
//               )}
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export { MessageBody };

// import React from "react";
// import "../ChatPage.css"; // Correct relative path

// const MessageBody = ({ messages, currentUserId }) => {
//   console.log("Message sender:", messages);

//   return (
//     <div className="chatB-container1">
//       <div className="chatB-content-divider1">
//         <div className="chatB-divider-wrapper">
//           <div className="chatB-divider" />
//         </div>
//         <div className="chatB-divider-label">Messages</div>
//         <div className="chatB-divider-wrapper">
//           <div className="chatB-divider" />
//         </div>
//       </div>
//       <div className="chatB-messages-parent">
//         <div className="chatB-messages">
//           {messages.map((message, index) => (
//             <div
//               key={index}
//               className={`${
//                 message.sender === currentUserId.first_name
//                   ? "chatB-row"
//                   : "chatB-message12"
//               }`}
//             >

//               {/* Avatar and Online Indicator for Received Messages */}
//               {message.sender !== currentUserId.first_name && (
//                 <div className="chatB-avatar2">
//                   <div className="chatB-contrast-border2" />
//                   <div className="chatB-avatar-online-indicator" />
//                 </div>
//               )}
//               {/* Message Content */}
//               <div
//                 className={`${
//                   message.sender === currentUserId.first_name
//                     ? "chatB-content13"
//                     : "chatB-content8"
//                 }`}
//                 style={{
//                   maxWidth: "70%", // Dynamically limit bubble width
//                   wordWrap: "break-word", // Ensure long words wrap
//                   overflowWrap: "anywhere", // Break words if necessary
//                 }}
//               >
//                 <div
//                   className={`${
//                     message.sender === currentUserId.first_name
//                       ? "name-and-time3"
//                       : "name-and-time"
//                   }`}
//                 >
//                   <div
//                     className={`${
//                       message.sender === currentUserId.first_name
//                         ? "single-message-username"
//                         : "chatB-usernames"
//                     }`}
//                   >
//                     {message.sender === currentUserId.first_name ? "You" : message.sender}
//                   </div>
//                   <div className="chatB-sub-text-wrapper">
//                     <div
//                       className={`${
//                         message.sender === currentUserId.first_name
//                           ? "sub-text3"
//                           : "chatB-sub-text"
//                       }`}
//                     >
//                       {message.timestamp || "Just now"}
//                     </div>
//                   </div>
//                 </div>
//                 <div
//                   className={`${
//                     message.sender === currentUserId.first_name
//                       ? "chatB-input5"
//                       : "chatB-input2"
//                   }`}
//                   style={{
//                     padding: "10px 15px", // Add spacing inside bubbles
//                     borderRadius:
//                       message.sender === currentUserId.first_name
//                         ? "8px 8px 0 8px" // Sent message styling
//                         : "8px 8px 8px 0", // Received message styling
//                     backgroundColor:
//                       message.sender === currentUserId.first_name
//                         ? "#7e56d9" // Purple for sent
//                         : "#e9ecef", // Light gray for received
//                     color:
//                       message.sender === currentUserId.first_name
//                         ? "#ffffff" // White text for sent
//                         : "#212529", // Dark text for received
//                   }}
//                 >
//                   <div className="chatB-text31">{message.content}</div>
//                 </div>
//               </div>
//               {/* Empty Space for Sent Messages */}
//               {message.sender === currentUserId.first_name && (
//                 <div className="chatB-empty-space" />
//               )}
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export { MessageBody };

// import React from "react";
// import "../ChatPage.css"; // Correct relative path

// const MessageBody = ({ messages, currentUserId }) => {
//   console.log("Message sender:", messages);

//   return (
//     <div className="chatB-container1">
//       <div className="chatB-content-divider1">
//         <div className="chatB-divider-wrapper">
//           <div className="chatB-divider" />
//         </div>
//         <div className="chatB-divider-label">Messages</div>
//         <div className="chatB-divider-wrapper">
//           <div className="chatB-divider" />
//         </div>
//       </div>
//       <div className="chatB-messages-parent">
//         <div className="chatB-messages">
//           {messages.map((message, index) => (
//             <div
//               key={index}
//               className={`${
//                 message.sender === currentUserId.first_name
//                   ? "chatB-row"
//                   : "chatB-message12"
//               }`}
//             >
//               {/* Avatar and Online Indicator for Received Messages */}
//               {message.sender !== currentUserId.first_name && (
//                 <div className="chatB-avatar2">
//                   <div className="chatB-contrast-border2" />
//                   <div className="chatB-avatar-online-indicator" />
//                 </div>
//               )}
//               {/* Wrapping content13 and input5 in message10 */}
//               <div className="chatB-message10">
//                 <div
//                   className={`${
//                     message.sender === currentUserId.first_name
//                       ? "chatB-content13"
//                       : "chatB-content8"
//                   }`}
//                 >
//                   <div
//                     className={`${
//                       message.sender === currentUserId.first_name
//                         ? "name-and-time3"
//                         : "name-and-time"
//                     }`}
//                   >
//                     <div
//                       className={`${
//                         message.sender === currentUserId.first_name
//                           ? "single-message-username"
//                           : "chatB-usernames"
//                       }`}
//                     >
//                       {message.sender === currentUserId.first_name ? "You" : message.sender}
//                     </div>
//                     <div className="chatB-sub-text-wrapper">
//                       <div
//                         className={`${
//                           message.sender === currentUserId.first_name
//                             ? "sub-text3"
//                             : "chatB-sub-text"
//                         }`}
//                       >
//                         {message.timestamp || "Just now"}
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div
//                   className={`${
//                     message.sender === currentUserId.first_name
//                       ? "chatB-input5"
//                       : "chatB-input2"
//                   }`}
//                 >
//                   <div className="chatB-text31">{message.content}</div>
//                 </div>
//               </div>
//               {/* Empty Space for Sent Messages */}
//               {message.sender === currentUserId.first_name && (
//                 <div className="chatB-empty-space" />
//               )}
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export { MessageBody };


// import React from "react";
// import "../ChatPageComponent.css"; // Correct relative path

// const MessageBody = ({ messages, currentUserId }) => {
//   console.log("Message sender:", messages);

//   return (
//     <div className="chatB-container1">
//       <div className="chatB-content-divider1">
//         <div className="chatB-divider-wrapper">
//           <div className="chatB-divider" />
//         </div>
//         <div className="chatB-divider-label">Messages</div>
//         <div className="chatB-divider-wrapper">
//           <div className="chatB-divider" />
//         </div>
//       </div>
//       <div className="chatB-messages-parent">
//         <div className="chatB-messages">
//           {messages.map((message, index) => (
//             <div
//               key={index}
//               className={`${
//                 message.sender === currentUserId.first_name
//                   ? "chatB-row"
//                   : "chatB-message12"
//               }`}
//             >
//               {/* Avatar and Online Indicator for Received Messages */}
//               {message.sender !== currentUserId.first_name && (
//                 <div className="chatB-avatar2">
//                   <div className="chatB-contrast-border2" />
//                   <div className="chatB-avatar-online-indicator" />
//                 </div>
//               )}
//               {message.sender === currentUserId.first_name ? (
//                 /* Wrap in message10 only for current user's messages */
//                 // <div className="chatB-message10">
//                   <div className="chatB-content13">
//                     <div className="chatB-name-and-time3">
//                       <div className="chatB-single-message-username">You</div>
//                       <div className="chatB-sub-text3">
//                         {message.timestamp || "Just now"}
//                       </div>
//                     {/* </div> */}
//                   </div>
//                   <div className="chatB-input5">
//                     <div className="chatB-text31">{message.content}</div>
//                   </div>
//                 </div>
//               ) : (
//                 /* No wrapping for received messages */
//                 <div className="chatB-content8">
//                   <div className="chatB-name-and-time">
//                     <div className="chatB-usernames">{message.sender}</div>
//                     <div className="chatB-sub-text">
//                       {message.timestamp || "Just now"}
//                     </div>
//                   </div>
//                   <div className="chatB-input2">
//                     <div className="chatB-text31">{message.content}</div>
//                   </div>
//                 </div>
//               )}
//               {/* Empty Space for Sent Messages */}
//               {message.sender === currentUserId.first_name && (
//                 <div className="chatB-empty-space" />
//               )}
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export { MessageBody };

// import React from "react";
// import "../ChatPageComponent.css"; // Correct relative path

// const MessageBody = ({ messages, currentUserId }) => {
//   console.log("Message sender:", messages);

//   return (
//     <div className="chatB-container1">
//       <div className="chatB-content-divider1">
//         <div className="chatB-divider-wrapper">
//           <div className="chatB-divider" />
//         </div>
//         <div className="chatB-divider-label">Messages</div>
//         <div className="chatB-divider-wrapper">
//           <div className="chatB-divider" />
//         </div>
//       </div>
//       <div className="chatB-messages-parent">
//         <div className="chatB-messages">
//           {messages.map((message, index) => (
//             <div
//               key={index}
//               className={`${
//                 message.sender === currentUserId.first_name
//                   ? "chatB-row"
//                   : "chatB-message12"
//               }`}
//             >
//               {/* Avatar and Online Indicator for Received Messages */}
//               {message.sender !== currentUserId.first_name && (
//                 <div className="chatB-avatar2">
//                   <div className="chatB-contrast-border2" />
//                   <div className="chatB-avatar-online-indicator" />
//                 </div>
//               )}
//               {message.sender === currentUserId.first_name ? (
//                 /* Wrap in message10 only for current user's messages */
//                 <div className="chatB-content13">
//                   <div className="chatB-name-and-time3">
//                     <div className="chatB-single-message-username">You</div>
//                     <div className="chatB-sub-text3">
//                       {message.timestamp || "Just now"}
//                     </div>
//                   </div>
//                   <div className="chatB-input5">
//                     <div className="chatB-text31">{message.content}</div>
//                   </div>
//                 </div>
//               ) : (
//                 /* No wrapping for received messages */
//                 <div className="chatB-content8">
//                   <div className="chatB-name-and-time">
//                     <div className="chatB-usernames">{message.sender}</div>
//                     <div className="chatB-sub-text">
//                       {message.timestamp || "Just now"}
//                     </div>
//                   </div>
//                   <div className="chatB-input2">
//                     <div className="chatB-receiever">{message.content}</div>
//                   </div>
//                 </div>
//               )}
//               {/* Empty Space for Sent Messages */}
//               {message.sender === currentUserId.first_name && (
//                 <div className="chatB-empty-space" />
//               )}
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export { MessageBody };

// import React, { useEffect, useState } from "react";
// import "../ChatPageComponent.css"; // Correct relative path

// const MessageBody = ({ messages, currentUserId }) => {
//   const [currentTime, setCurrentTime] = useState(Date.now());

//   // Periodically update `currentTime` every minute
//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentTime(Date.now()); // Update currentTime every 1 minute
//     }, 60000); // 60 seconds
//     return () => clearInterval(interval); // Cleanup interval on unmount
//   }, []);

//   // Helper function to format the timestamp into HH:mm
//   const formatTime = (timestamp) => {
//     const date = new Date(timestamp);
//     const hours = date.getHours();
//     const minutes = date.getMinutes();
//     return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
//   };

//   // Helper function to get display time (Just now or formatted time)
//   const getDisplayTime = (timestamp) => {
//     const elapsedTime = currentTime - timestamp;

//     // Show "Just now" for the first 60 seconds
//     if (elapsedTime < 60000) {
//       return "Just now";
//     }

//     // Otherwise, show formatted time
//     return formatTime(timestamp);
//   };

//   return (
//     <div className="chatB-container1">
//       <div className="chatB-content-divider1">
//         <div className="chatB-divider-wrapper">
//           <div className="chatB-divider" />
//         </div>
//         <div className="chatB-divider-label">Messages</div>
//         <div className="chatB-divider-wrapper">
//           <div className="chatB-divider" />
//         </div>
//       </div>
//       <div className="chatB-messages-parent">
//         <div className="chatB-messages">
//           {messages.map((message, index) => (
//             <div
//               key={index}
//               className={`${
//                 message.sender === currentUserId.first_name
//                   ? "chatB-row"
//                   : "chatB-message12"
//               }`}
//             >
//               {/* Avatar and Online Indicator for Received Messages */}
//               {message.sender !== currentUserId.first_name && (
//                 <div className="chatB-avatar2">
//                   <div className="chatB-contrast-border2" />
//                   <div className="chatB-avatar-online-indicator" />
//                 </div>
//               )}
//               {message.sender === currentUserId.first_name ? (
//                 /* Wrap in message10 only for current user's messages */
//                 <div className="chatB-content13">
//                   <div className="chatB-name-and-time3">
//                     <div className="chatB-single-message-username">You</div>
//                     <div className="chatB-sub-text3">
//                       {getDisplayTime(message.timestamp)}
//                     </div>
//                   </div>
//                   <div className="chatB-input5">
//                     <div className="chatB-text31">{message.content}</div>
//                   </div>
//                 </div>
//               ) : (
//                 /* No wrapping for received messages */
//                 <div className="chatB-content8">
//                   <div className="chatB-name-and-time">
//                     <div className="chatB-usernames">{message.sender}</div>
//                     <div className="chatB-sub-text">
//                       {getDisplayTime(message.timestamp)}
//                     </div>
//                   </div>
//                   <div className="chatB-input2">
//                     <div className="chatB-receiever">{message.content}</div>
//                   </div>
//                 </div>
//               )}
//               {/* Empty Space for Sent Messages */}
//               {message.sender === currentUserId.first_name && (
//                 <div className="chatB-empty-space" />
//               )}
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export { MessageBody };

import React, { useEffect, useState, useRef } from "react";
import "../ChatPageComponent.css"; // Correct relative path
import chat_day_divider from "../../../icons/Gerator/chat_page/chat_day_divider.svg";

const MessageBody = ({ messages, currentUserId, listingData }) => {
  const [currentTime, setCurrentTime] = useState(Date.now());
  // const messagesEndRef = useRef(null); // Create a ref for the end of the message list

  const lastMessageRef = useRef(null);
  const [latestMessageTime, setLatestMessageTime] = useState(null);

  // Track the latest message timestamp before updating messages
  useEffect(() => {
    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1];

      // Check if the last message is truly new (based on time)
      if (!latestMessageTime || new Date(lastMessage.timestamp) > new Date(latestMessageTime)) {
        setLatestMessageTime(lastMessage.timestamp); // Update to new latest timestamp

        // Scroll only for new messages, not when older messages are loaded
        if (lastMessageRef.current) {
          lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  }, [messages]);

  // Periodically update `currentTime` every minute
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now()); // Update currentTime every 1 minute
    }, 60000); // 60 seconds
    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  // Helper function to format the timestamp into HH:mm
  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
  };

  // Helper function to get display time (Just now or formatted time)
  // const getDisplayTime = (timestamp) => {
  //   const elapsedTime = currentTime - timestamp;

  //   // Show "Just now" for the first 60 seconds
  //   if (elapsedTime < 60000) {
  //     return "Just now";
  //   }

  //   // Otherwise, show formatted time
  //   return formatTime(timestamp);
  // };


  // Function to determine the date header (Today, Yesterday, or a date)
  const getDateLabel = (timestamp) => {
    const messageDate = new Date(timestamp);
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today to midnight

    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1); // Normalize yesterday to midnight

    if (messageDate.getTime() >= today.getTime()) return "Today";
    if (messageDate.getTime() >= yesterday.getTime()) return "Yesterday";

    return messageDate.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  // Ensure messages are sorted by timestamp (ascending order)
  const sortedMessages = [...messages].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

  let lastDate = null; // Tracks the last date divider

  // Process messages to include dividers
  const processedMessages = [];
  sortedMessages.forEach((message) => {
    const messageDate = new Date(message.timestamp).toDateString();
    if (messageDate !== lastDate) {
      processedMessages.push({ isDivider: true, text: getDateLabel(message.timestamp) });
      lastDate = messageDate;
    }
    processedMessages.push({ ...message, isDivider: false });
  });


  console.log("PRINTING RECEIVER IN MESSAGEBOX: ", listingData);
  return (
    <div className="chatB-container1">
      <div className="chatB-messages-parent">
        <div className="chatB-messages">
          {processedMessages.map((item, index) =>
            item.isDivider ? (
              <div key={`divider-${index}`} className="chatB-divider-container">
                {/* <div className="chatB-divider-line"></div> */}
                <img className="chatB-divider-line" src={chat_day_divider} alt="divider" />
                <div className="chatB-divider-label">{item.text}</div>
                <img className="chatB-divider-line" src={chat_day_divider} alt="divider" />
                {/* <div className="chatB-divider-line"></div> */}
              </div>
            ) : (
              <div
                ref={index === processedMessages.length - 1 ? lastMessageRef : null}

                key={item.messageId || index}
                className={`${item.sender === currentUserId ? "chatB-row" : "chatB-message12"
                  }`}
              >
                {/* Avatar for received messages */}
                {item.sender !== currentUserId && (
                  <img
                    className="chatB-avatar2"
                    src={listingData?.profileImage}
                    alt="Avatar"
                  />
                )}

                {/* Sent Messages (Current User) */}
                {item.sender === currentUserId ? (
                  <div className="chatB-content13">
                    <div className="chatB-name-and-time3">
                      <div className="chatB-single-message-username">You</div>
                    </div>
                    <div className="chatB-input5">
                      <div className="chatB-text31">{item.content}</div>
                      <div className="chatB-sub-text3">
                        {formatTime(item.timestamp)}
                      </div>
                    </div>
                  </div>
                ) : (
                  /* Received Messages */
                  <div className="chatB-content8">
                    <div className="chatB-name-and-time">
                      {/* <div className="chatB-usernames">
                        {listingData?.device_business?.business_user?.displayName || listingData?.spare_business?.business_user?.displayName || listingData?.service_business?.business_user?.displayName || listingData?.software_business?.business_user?.displayName || listingData?.job_business?.business_user?.displayName}
                      </div> */}
                    </div>
                    <div className="chatB-input2">
                      <div className="chatB-receiever">{item.content}</div>
                      <div className="chatB-sub-text">
                        {formatTime(item.timestamp)}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )
          )}
          {/* Invisible div to act as a scroll anchor */}
          {/* <div ref={messagesEndRef} /> */}
        </div>
      </div>
    </div>
  );


  // return (
  //   <div className="chatB-container1">
  //     <div className="chatB-content-divider1">
  //       <div className="chatB-divider-wrapper">
  //         <div className="chatB-divider" />
  //       </div>
  //       <div className="chatB-divider-label">Messages</div>
  //       <div className="chatB-divider-wrapper">
  //         <div className="chatB-divider" />
  //       </div>
  //     </div>
  //     <div className="chatB-messages-parent">
  //       <div className="chatB-messages">
  //         {messages.map((message, index) => (
  //           <div
  //             key={index}
  //             className={`${message.sender === currentUserId
  //               ? "chatB-row"
  //               : "chatB-message12"
  //               }`}
  //           >
  //             {/* Avatar and Online Indicator for Received Messages */}
  //             {message.sender !== currentUserId && (
  //               <img
  //                 className="chatB-avatar2"
  //                 src={listingData?.profileImage}
  //                 alt="Avatar"
  //               />
  //             )}
  //             {message.sender === currentUserId ? (
  //               /* Wrap in message10 only for current user's messages */
  //               <div className="chatB-content13">
  //                 <div className="chatB-name-and-time3">
  //                   <div className="chatB-single-message-username">You</div>
  //                   {/* <div className="chatB-sub-text3">
  //                     {getDisplayTime(message.timestamp)}
  //                   </div> */}
  //                 </div>
  //                 <div className="chatB-input5">
  //                   <div className="chatB-text31">{message.content}</div>
  //                   <div className="chatB-sub-text3">
  //                     {getDisplayTime(message.timestamp)}
  //                   </div>
  //                 </div>
  //               </div>
  //             ) : (
  //               /* No wrapping for received messages */
  //               <div className="chatB-content8">
  //                 <div className="chatB-name-and-time">
  //                   <div className="chatB-usernames">{listingData?.wanted_device_user?.displayName || listingData?.wanted_spare_user?.displayName || listingData?.wanted_service_user?.displayName || listingData?.wanted_software_user?.displayName}</div>
  //                   {/* <div className="chatB-sub-text">
  //                     {getDisplayTime(message.timestamp)}
  //                   </div> */}
  //                 </div>
  //                 <div className="chatB-input2">
  //                   <div className="chatB-receiever">{message.content}</div>
  //                   <div className="chatB-sub-text">
  //                     {getDisplayTime(message.timestamp)}
  //                   </div>
  //                 </div>
  //               </div>
  //             )}
  //             {/* Empty Space for Sent Messages */}
  //             {message.sender === currentUserId && (
  //               <div className="chatB-empty-space" />
  //             )}
  //           </div>
  //         ))}
  //         {/* Invisible div to act as a scroll anchor */}
  //         <div ref={messagesEndRef} />
  //       </div>
  //     </div>
  //   </div>
  // );

  // return (
  //   <div className="chatB-container1">
  //     <div className="chatB-content-divider1">
  //       <div className="chatB-divider-wrapper">
  //         <div className="chatB-divider" />
  //       </div>
  //       <div className="chatB-divider-label">Messages</div>
  //       <div className="chatB-divider-wrapper">
  //         <div className="chatB-divider" />
  //       </div>
  //     </div>
  //     <div className="chatB-messages-parent">
  //       <div className="chatB-messages">z
  //         {messages.map((message, index) => (
  //           <div
  //             key={index}
  //             className={`${
  //               message.sender === currentUserId
  //                 ? "chatB-row"
  //                 : "chatB-message12"
  //             }`}
  //           >
  //             {/* Avatar and Online Indicator for Received Messages */}
  //             {message.sender !== currentUserId && (
  //               <img
  //               className="chatB-avatar2"
  //               src={receiver.profileImage}
  //               alt="Avatar"
  //             />
  //             )}
  //             {message.sender === currentUserId ? (
  //               /* Wrap in message10 only for current user's messages */
  //               <div className="chatB-content13">
  //                 <div className="chatB-name-and-time3">
  //                   <div className="chatB-single-message-username">You</div>
  //                   <div className="chatB-sub-text3">
  //                     {getDisplayTime(message.timestamp)}
  //                   </div>
  //                 </div>
  //                 <div className="chatB-input5">
  //                   <div className="chatB-text31">{message.content}</div>
  //                 </div>
  //               </div>
  //             ) : (
  //               /* No wrapping for received messages */
  //               <div className="chatB-content8">
  //                 <div className="chatB-name-and-time">
  //                   <div className="chatB-usernames">{receiver}</div>
  //                   <div className="chatB-sub-text">
  //                     {getDisplayTime(message.timestamp)}
  //                   </div>
  //                 </div>
  //                 <div className="chatB-input2">
  //                   <div className="chatB-receiever">{message.content}</div>
  //                 </div>
  //               </div>
  //             )}
  //             {/* Empty Space for Sent Messages */}
  //             {message.sender === currentUserId && (
  //               <div className="chatB-empty-space" />
  //             )}
  //           </div>
  //         ))}
  //         {/* Invisible div to act as a scroll anchor */}
  //         <div ref={messagesEndRef} />
  //       </div>
  //     </div>
  //   </div>
  // );
};


export { MessageBody };

