import { createSlice } from '@reduxjs/toolkit';


export const initialState = {
  myfieldinsideradio: false,
  isYoutubeChecked: false,
  isVimeoChecked: false,
  // selectedImageFile: '',
  // selectedMultiImageFile: '',
  // selectedDocumentFile: '',
  currentStep: 0
}

const adminAppDataEditJobSlice = createSlice({
  name: "adminAppDataEditJob",
  initialState,
  reducers: {
    updateAppDataAdminEditJob(state, action) {
      // state = produce(state, draft =>{
      // })
      const { field, value } = action.payload;
      switch (action.payload.case) {
        case "CURRENTSTEP":
          state.currentStep = action.payload.value;
          break;
        case "VIDEO":
          if (value !== undefined) {
            state[field] = value;
          }
          break;
        case "FEATUREIMAGE":
          if (value !== undefined) {
            state[field] = value;
          }
          break;
        default:
          break;
      }

    },
    clearAppDataAdminEditJob() {
      return initialState;
    }
  },
});

export const { updateAppDataAdminEditJob, clearAppDataAdminEditJob } = adminAppDataEditJobSlice.actions

export default adminAppDataEditJobSlice.reducer