import React, { useRef, useState, useEffect } from 'react';

const TruncatedTextTooltip = ({ text }) => {
  const textRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      const element = textRef.current;
      if (element) {
        setIsOverflowing(element.scrollWidth > element.clientWidth);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [text]);

  return (
    <>
      <div
        ref={textRef}
        className="truncate w-full"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {text}
      </div>
      
      {isOverflowing && showTooltip && (
        <div className="absolute z-50 p-2 bg-white border rounded shadow-lg max-w-xs whitespace-normal left-0 top-full mt-1"
        style={{
            position:"absolute",
            // top:"80",
            
            zIndex:"999",
            backgroundColor: 'white',
            padding: '8px',
            borderRadius: '4px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            border: '1px solid rgba(0,0,0,0.1)',
            maxWidth: '300px',
            whiteSpace: 'normal',
            wordBreak: 'break-word'
        }}
         >
          {text}
        </div>
      )}
    </>
  );
};

export default TruncatedTextTooltip;

