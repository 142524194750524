import React from 'react'
import "./SkeletonListingChatApproacherLoaderComponent.css"
const SkeletonListingChatApproacherLoaderComponent = () => {
    return (
        <div className="skeleton-chat-approacher-listing-offered-container">
            <div className="skeleton-chat-approacher-listing-offered-image"></div>
            <div className="skeleton-chat-approacher-listing-offered-header"></div>
            <div className="skeleton-chat-approacher-listing-offered-subheader"></div>
            <div className="skeleton-chat-approacher-listing-offered-tags"></div>
            {/* <div className="skeleton-chat-approacher-listing-offered-stats">
                <div className="skeleton-chat-approacher-listing-offered-box"></div>
                <div className="skeleton-chat-approacher-listing-offered-box"></div>
                <div className="skeleton-chat-approacher-listing-offered-box"></div>
                <div className="skeleton-chat-approacher-listing-offered-box"></div>
            </div> */}
            <div className="skeleton-chat-approacher-listing-offered-footer custom-footer"></div>
        </div>
    );
}

export default SkeletonListingChatApproacherLoaderComponent