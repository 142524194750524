import React from 'react';
import "./UserMenuNotLoggedIn.css";

import settings011 from '../../icons/Gerator/dashboard/settings011.svg';

import homeline from '../../icons/Gerator/dashboard/homeline.svg';

import users011 from '../../icons/Gerator/dashboard/users011.svg';




export const UserMenuNotLoggedIn = ({ user, profileImage }) => {
  


 
  return (
    <div className="user-menu-not-logged-in-dropdown-user-menu-all-options-fixed">
      {/* <div className="user-menu-not-logged-in-dropdown-dropdown-list-header">
        <div className="user-menu-not-logged-in-dropdown-avatar-label-group">
          <div className="user-menu-not-logged-in-dropdown-avatar22" style={{
            backgroundImage: profileImage
              && `url(${profileImage})`
          }}>
            <div className="user-menu-not-logged-in-dropdown-contrast-border22"></div>
            <div className="user-menu-not-logged-in-dropdown-avatar-online-indicator6"></div>
          </div>
          <div className="user-menu-not-logged-in-dropdown-text-and-supporting-text19 user-menu-dropdown-text-and-supporting-text19-custom-width">
            <div className="user-menu-not-logged-in-dropdown-text246">{user.displayName}</div>
            <div className="user-menu-not-logged-in-dropdown-supporting-text84">{user.email}</div>
          </div>
        </div>
      </div> */}
      <section className="user-menu-not-logged-in-dropdown-menu-items">
        <div className="user-menu-not-logged-in-dropdown-dropdown-list-item">
          <a href="/sign-up" className='link-width-max'>
            <div className="user-menu-not-logged-in-dropdown-content48">
              <div className="user-menu-not-logged-in-dropdown-icon-and-text">
                {/* <img className="user-menu-not-logged-in-dropdown-user-01-icon" alt="" src={homeline} /> */}

                <div className="user-menu-not-logged-in-dropdown-text247">Sign Up</div>
              </div>
            </div>
          </a>
        </div>

        <div className="user-menu-not-logged-in-dropdown-dropdown-list-item">
          <a href="/sign-in" className='link-width-max'>
            <div className="user-menu-not-logged-in-dropdown-content48">
              <div className="user-menu-not-logged-in-dropdown-icon-and-text">
                {/* <img className="user-menu-not-logged-in-dropdown-user-01-icon" alt="" src={settings011} /> */}

                <div className="user-menu-not-logged-in-dropdown-text247">Sign In</div>
              </div>
            </div>
          </a>
        </div>
      
      
      </section>
    </div>
  )
}

