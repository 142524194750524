// import React, { useEffect, useState } from "react";
// import "../ChatPageComponent.css"; // Correct relative path

// import {CardHeader} from "./CardHeader"
// import {MessageBody} from "./MessageBody"
// import {MessageInput} from "./MessageInput"


// import arrowright from "../../../icons/Gerator/chat_page/arrowright.svg";
// import dot2 from "../../../icons/Gerator/chat_page/-dot2.svg";
// import facesmile from "../../../icons/Gerator/chat_page/facesmile.svg";
// import arrowleft from "../../../icons/Gerator/chat_page/arrowleft.svg";
// import avatar2 from "../../../icons/Gerator/chat_page/avatar2.svg";
// import dotsvertical from "../../../icons/Gerator/chat_page/dotsvertical.svg";
// import divider2 from "../../../icons/Gerator/chat_page/divider2.svg";
// import divider3 from "../../../icons/Gerator/chat_page/divider-3.svg";
// import dotshorizontal from "../../../icons/Gerator/chat_page/dotshorizontal.svg";
// import uploadcloud02 from "../../../icons/Gerator/chat_page/uploadcloud02.svg";
// import videorecorder from "../../../icons/Gerator/chat_page/videorecorder.svg";
// import phonecall01 from "../../../icons/Gerator/chat_page/phonecall01.svg";
// import filterlines from "../../../icons/Gerator/chat_page/filterlines.svg";
// import placeholder1 from "../../../icons/Gerator/chat_page/placeholder1.svg";
// import placeholder2 from "../../../icons/Gerator/chat_page/placeholder2.svg";
// import placeholder3 from "../../../icons/Gerator/chat_page/placeholder3.svg";
// import placeholder from "../../../icons/Gerator/chat_page/placeholder.svg";
// import pageIcon from "../../../icons/Gerator/chat_page/page.svg";

// import searchlg from "../../../icons/Gerator/chat_page/searchlg.svg";

// const MessageBox = ({ messages, setMessages, currentUserId, onSendMessage, listingData }) => {
//   return (

//       <div className="chatB-card-header-parent">
//        {currentUserId && <CardHeader listingData={listingData}  />}
//        <MessageBody messages={messages} currentUserId={currentUserId} setMessages={setMessages}  />
//        <MessageInput onSendMessage={onSendMessage} />
//       </div>
//   );
// }

// export {MessageBox};

import React from "react";
import "../ChatPageComponent.css"; // Correct relative path

import { CardHeader } from "./CardHeader";
import { MessageBody } from "./MessageBody";
import { MessageInput } from "./MessageInput";

import arrowright from "../../../icons/Gerator/chat_page/arrowright.svg";
import dot2 from "../../../icons/Gerator/chat_page/-dot2.svg";
import facesmile from "../../../icons/Gerator/chat_page/facesmile.svg";
import arrowleft from "../../../icons/Gerator/chat_page/arrowleft.svg";
import avatar2 from "../../../icons/Gerator/chat_page/avatar2.svg";
import dotsvertical from "../../../icons/Gerator/chat_page/dotsvertical.svg";
import divider2 from "../../../icons/Gerator/chat_page/divider2.svg";
import divider3 from "../../../icons/Gerator/chat_page/divider-3.svg";
import dotshorizontal from "../../../icons/Gerator/chat_page/dotshorizontal.svg";
import uploadcloud02 from "../../../icons/Gerator/chat_page/uploadcloud02.svg";
import videorecorder from "../../../icons/Gerator/chat_page/videorecorder.svg";
import phonecall01 from "../../../icons/Gerator/chat_page/phonecall01.svg";
import filterlines from "../../../icons/Gerator/chat_page/filterlines.svg";
import placeholder1 from "../../../icons/Gerator/chat_page/placeholder1.svg";
import placeholder2 from "../../../icons/Gerator/chat_page/placeholder2.svg";
import placeholder3 from "../../../icons/Gerator/chat_page/placeholder3.svg";
import placeholder from "../../../icons/Gerator/chat_page/placeholder.svg";
import pageIcon from "../../../icons/Gerator/chat_page/page.svg";

import searchlg from "../../../icons/Gerator/chat_page/searchlg.svg";

const MessageBox = ({
  messages,
  setMessages,
  currentUserId,
  onSendMessage,
  isSendMessageError,
  isSendMessageSuccess,
  isSendMessageLoading,
  listingData,
  onLoadMore,
  isMessageFetching,
  isMessageFetchError,
  selectedChat,
  showCardHeader,
  hasMoreMessages

}) => {

  const shouldShowLoadMore = messages.length >= 10 && hasMoreMessages;

  return (
    <div className="chatB-card-header-parent">
      {currentUserId && selectedChat && showCardHeader && <CardHeader listingData={listingData} selectedChat={selectedChat} />}
      {shouldShowLoadMore && (
        <button
          className="load-more-button"
          onClick={onLoadMore}
          disabled={isMessageFetching}
        >
          {isMessageFetching ? "Loading..." : "Load Previous Messages"}
        </button>
      )}
      {isMessageFetchError && <div className="error-message">Failed to load messages.</div>}
      {listingData && <MessageBody
        messages={messages}
        currentUserId={currentUserId}
        setMessages={setMessages}
        onLoadMore={onLoadMore} // Pass the load more functionality
        // isFetchLoading={isFetchLoading} // Pass the loading state
        // isFetchError={isFetchError} // Pass the error state
        // reciever={listingData?.data?.device_business?.business_user?.first_nam} 
        selectedChat={selectedChat}
      />}
      <MessageInput onSendMessage={onSendMessage} isSendMessageLoading={isSendMessageLoading} isSendMessageError={isSendMessageError} isSendMessageSuccess={isSendMessageSuccess} />
    </div>
  );
};

export { MessageBox };
