import React, { useState } from 'react';
import './EasyFilterAdminBusinessComponent.css'; // Import your CSS file

const EasyFilterAdminBusinessComponent = ({ handleFilterClick, activePostStatus, publishedCount, unpublishedCount, verifiedCount, unverifiedCount }) => {
    const getTextClass = (filter) => {
        switch (filter) {
            case 'All': return '29';
            case 'Published': return '31';
            case 'Unpublished': return '32';
            case 'Verified': return '39';
            case 'Unverified': return '40';
            default: return '';
        }
    };

    return (
        <div className="easy-filter-admin-button-group custom-cursor">
            {['All', 'Published', 'Unpublished', 'Verified', 'Unverified'].map(filter => (
                <div
                    key={filter}
                    className={`easy-filter-admin-button-group-base1 ${activePostStatus === filter ? 'active' : ''}`}
                    onClick={() => handleFilterClick(filter)}
                >
                    <div className={`easy-filter-admin-text${getTextClass(filter)}`}>
                        {filter}
                    </div>
                    {filter === 'Published' && publishedCount > 0 && (
                        <div className="easy-filter-badge">
                            <div className='easy-filter-badge-container-success'>
                                <div className='easy-filter-badge-counter-success'>{publishedCount}</div>
                            </div>
                        </div>
                    )}
                    {filter === 'Unpublished' && unpublishedCount > 0 && (
                        <div className="easy-filter-badge">
                            <div className='easy-filter-badge-container'>
                                <div className='easy-filter-badge-counter'>{unpublishedCount}</div>
                            </div>
                        </div>
                    )}
                    {filter === 'Verified' && verifiedCount > 0 && (
                        <div className="easy-filter-badge">
                            <div className='easy-filter-badge-container-success'>
                                <div className='easy-filter-badge-counter-success'>{verifiedCount}</div>
                            </div>
                        </div>
                    )}
                    {filter === 'Unverified' && unverifiedCount > 0 && (
                        <div className="easy-filter-badge">
                            <div className='easy-filter-badge-container'>
                                <div className='easy-filter-badge-counter'>{unverifiedCount}</div>
                            </div>
                        </div>
                    )}


                </div>
            ))}
        </div>
    );
}

export default EasyFilterAdminBusinessComponent;
