import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./ChatPageComponent.css";

import { getCustomizedDate } from "../../helper/ChatHelper";

import markerpin02 from "../../icons/Gerator/chat_page/markerpin02.svg";
// import calendar from "../../icons/Gerator/chat_page/calendar.svg";
// import dotsvertical from "../../icons/Gerator/chat_page/dotsvertical.svg";
// import arrowup from "../../icons/Gerator/chat_page/arrowup.svg";
// import lock04 from "../../icons/Gerator/chat_page/lock04.svg";
import eye from "../../icons/Gerator/chat_page/eye.svg";
// import lockunlocked04 from "../../icons/Gerator/chat_page/lockunlocked04.svg";
// import chartmin from "../../icons/Gerator/chat_page/-chart-mini@2x.png"
// import radar1 from "../../icons/Gerator/chat_page/radar-1-1@2x.png"
// import deal2 from "../../icons/Gerator/chat_page/deal-2-1@2x.png"
// import magnifier from "../../icons/Gerator/chat_page/magnifier-1@2x.png"
import arrowsquareupright from "../../icons/Gerator/chat_page/arrowsquareupright.svg";
// import messagechatsquare from "../../icons/Gerator/chat_page/messagechatsquare.svg";
import { PostStatusLIstingComponent } from "../../components/PostStatusLIstingComponent/PostStatusLIstingComponent";

import dotsvertical from '../../icons/Gerator/sales_iq/dotsvertical.svg';
import calendar1 from '../../icons/Gerator/sales_iq/calendar1.svg';
import content_elements_2x from '../../icons/Gerator/sales_iq/content-elements@2x.png';
import dot1 from '../../icons/Gerator/sales_iq/-dot1.svg';

import _chart_mini2 from "../../icons/Gerator/sales_iq/-chart-mini2@2x.png"
import arrowup from "../../icons/Gerator/sales_iq/arrowup.svg";

import radar_1_1_2x from '../../icons/Gerator/sales_iq/radar-1-1@2x.png';
import deal_2_1_2x from '../../icons/Gerator/sales_iq/deal-2-1@2x.png';
import magnifier_1_2x from '../../icons/Gerator/sales_iq/magnifier-1@2x.png';
import content_elements_2x from '../../icons/Gerator/sales_iq/content-elements@2x.png';
import eye from '../../icons/Gerator/sales_iq/eye.svg';
import icon1 from '../../icons/Gerator/sales_iq/icon1.svg';
import lock04 from '../../icons/Gerator/sales_iq/lock04.svg';
import lockunlocked04 from '../../icons/Gerator/sales_iq/lockunlocked04.svg';
import arrowsquareupright from '../../icons/Gerator/sales_iq/arrowsquareupright.svg';
import messagechatsquare from '../../icons/Gerator/sales_iq/messagechatsquare.svg';
import markerpin02 from '../../icons/Gerator/sales_iq/markerpin02.svg';
import divider2 from '../../icons/Gerator/sales_iq/divider2.svg';
import arrowup1 from '../../icons/Gerator/sales_iq/arrowup1.svg';
import arrowdown1 from '../../icons/Gerator/sales_iq/arrowdown1.svg';
import placeholder2 from '../../icons/Gerator/sales_iq/placeholder2.svg';
import group_58_2x from '../../icons/Gerator/sales_iq/group-58@2x.png';
import sliders02 from '../../icons/Gerator/sales_iq/sliders02.svg';
import playstation from "../../icons/Gerator/sales_iq/playstation.jpeg";
import { TransactionTypeComponent } from '../../components/TransactionTypeComponent/TransactionTypeComponent';


import { useLazyFindGetDeviceQuery, useLazyGetDevicesQuery } from "../../api/DeviceAPI";
import { formatNumber } from "../../helper/DeviceHelper";


const Analytics = ({ listingData, onLoad }) => {
  const { entity } = useParams()
  const [postTypeSlug, setPostTypeSlug] = useState(null)
  useEffect(() => {
    if (listingData) {
      switch (entity) {
        case 'Device':
          setPostTypeSlug(entity.toLowerCase())
          break;
        case 'Spare':
          setPostTypeSlug(entity.toLowerCase())
          break;
        case 'Service':
          setPostTypeSlug(entity.toLowerCase())
          break;
        case 'Software':
          setPostTypeSlug(entity.toLowerCase())
          break;
        case 'Job':
          setPostTypeSlug(entity.toLowerCase())
          break;
        default:
          break;
      }

      console.log('YES DEVICE SUCCESS in ANALYTICS', listingData);
      onLoad();
    }
  }, [entity]);

  if (listingData) {
    return (
      // <div className="chatB-content-divider">
      //   <div className="chatB-content-divider-child"/>
      //   <div className="chatB-frame-group">
      //     <div className="chatB-rectangle-wrapper">
      //       <img className="chatB-frame-item" loading="lazy" alt="" src={listingData?.featureImage}/>
      //     </div>
      //     <div className="chatB-content-container">
      //       <div className="chatB-content-container-inner">
      //         <div className="chatB-detail-title-parent">
      //           <div className="chatB-detail-title">
      //             <p className="chatB-pre-owned-used-fully-refurbi">
      //               {listingData?.title}
      //             </p>
      //             {/* <p className="chatB-ase-antibody-detection">
      //       ASE ANTIBODY DETECTION, IDENTIFICATION REAGENTS FOR
      //       RENT / LEASE
      //     </p> */}
      //           </div>
      //           <div className="chatB-badges-parent">
      //             <div className="chatB-badges">
      //               <div className="chatB-info-badge">
      //                 <div className="chatB-badge3">
      //                   <img className="chatB-dot-icon3" alt="" src={dot1}/>
      //                   <div className="chatB-text9">Expired</div>
      //                 </div>
      //                 <div className="chatB-supporting-text">ID# {listingData?.id}</div>
      //               </div>
      //               <div className="chatB-frame-container">
      //                 <div className="chatB-marker-icon-wrapper">
      //                   <div className="chatB-marker-icon">4.9/5</div>
      //                 </div>
      //                 <div className="chatB-location-label">
      //                   <div className="chatB-supporting-text1">
      //                     99,999 reviews
      //                   </div>
      //                 </div>
      //               </div>
      //               <div className="chatB-badge4">
      //                 <div className="chatB-text10"> {listingData?.tax_device_category.name || listingData?.tax_spare_category.name  || listingData?.tax_software_category.name  || listingData?.tax_service_category.name  || listingData?.tax_job_category.name}</div>
      //               </div>
      //             </div>
      //             <div className="chatB-frame-div">
      //               <div className="chatB-calendar-icon-parent">
      //                 <div className="chatB-navbar">
      //                   <img className="chatB-marker-pin-02-icon" alt="" src={markerpin02}/>
      //                 </div>
      //                 <div
      //                     className="chatB-location">{listingData?.device_address?.addressTownCity  || listingData?.spare_address?.addressTownCity || listingData?.software_address?.addressTownCity || listingData?.service_address?.addressTownCity || listingData?.job_address?.addressTownCity}, {listingData?.device_address?.tax_country?.name || listingData?.spare_address?.tax_country?.name  || listingData?.software_address?.tax_country?.name  || listingData?.service_address?.tax_country?.name  || listingData?.job_address?.tax_country?.name}</div>
      //               </div>
      //               <div className="chatB-work-badges-wrapper">
      //                 <div className="chatB-work-badges">
      //                   <div className="chatB-badge5">
      //                     <div className="chatB-work-type"> </div>
      //                   </div>
      //                   <div className="chatB-badge6">
      //                     <div className="chatB-text11">{listingData?.tax_oem?.name}</div>
      //                   </div>
      //                 </div>
      //               </div>
      //             </div>
      //             <div className="chatB-frame-parent1">
      //               <div className="chatB-price-tag-wrapper">
      //                 <div className="chatB-price-tag">
      //                   <div className="chatB-navbar">
      //                     <img className="chatB-marker-pin-02-icon" alt="" src={calendar}/>
      //                   </div>
      //                   <div className="chatB-type-of-work">{getCustomizedDate(listingData?.createdAt)}</div>
      //                 </div>
      //               </div>
      //               <div className="chatB-badge7">
      //                 <div className="chatB-cost-label">
      //                   Patient / Rehabititatiin
      //                 </div>
      //               </div>
      //             </div>
      //             <div className="chatB-badge8">
      //               <div className="chatB-status-label">Accounting</div>
      //             </div>
      //           </div>
      //         </div>
      //       </div>
      //       <div className="chatB-filter-dropdown-parent">
      //         <div className="chatB-filter-dropdown">
      //           <div className="chatB-badge-wrapper">
      //             <div className="chatB-badge9">
      //               <img className="chatB-dot-icon3" alt="" src={dot1}/>
      //               <div className="chatB-filter-label">Exchange</div>
      //             </div>
      //           </div>
      //           <div className="chatB-dropdown">
      //             <img className="chatB-dots-vertical-icon" alt="" src={dotsvertical}/>
      //           </div>
      //         </div>
      //         <div className="chatB-frame-wrapper">
      //           <div className="chatB-price-parent">
      //             <div className="chatB-price">$9,999,999.99</div>
      //             <div className="chatB-price-currency">
      //               <div className="chatB-price-amount">
      //                 <div className="chatB-price-value">USD - Per Unit</div>
      //                 <div className="chatB-price-actions">
      //                   <div className="chatB-price-action-icons">
      //                     <img className="chatB-magnifier-1-icon" alt="" src={radar1}/>
      //                     <img className="chatB-magnifier-1-icon" alt="" src={deal2}/>
      //                     <img className="chatB-magnifier-1-icon" alt="" src={magnifier}/>
      //                   </div>
      //                 </div>
      //               </div>
      //             </div>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      //   <img className="chatB-divider-icon2" alt="" src={divider1}/>
      //   <div className="chatB-metric-item-parent">
      //     <div className="chatB-metric-item">
      //       <div className="chatB-heading">Views</div>
      //       <div className="chatB-number-and-chart">
      //         <div className="chatB-number-and-badge">
      //           <div className="chatB-number">{listingData?.viewsCount}</div>
      //           <div className="chatB-change-and-text">
      //             <div className="chatB-change">
      //               <img className="chatB-arrow-up-icon" alt="" src={arrowup}/>
      //               <div className="chatB-change1">31%</div>
      //             </div>
      //             <div className="chatB-text12">vs last month</div>
      //           </div>
      //         </div>
      //         <img className="chatB-chart-mini-icon" loading="lazy" alt="" src={chartmin}/>
      //       </div>
      //       <div className="chatB-dropdown1">
      //         <img className="chatB-dots-vertical-icon" alt="" src={dotsvertical}/>
      //       </div>
      //     </div>
      //     <div className="chatB-metric-item">
      //       <div className="chatB-heading1">Added to Radar</div>
      //       <div className="chatB-number-and-chart">
      //         <div className="chatB-number-and-badge">
      //           <div className="chatB-number">{listingData?.radarCount}</div>
      //           <div className="chatB-change-and-text">
      //             <div className="chatB-change">
      //               <img className="chatB-arrow-up-icon" alt="" src={arrowup}/>
      //               <div className="chatB-change3">100%</div>
      //             </div>
      //             <div className="chatB-text12">vs last month</div>
      //           </div>
      //         </div>
      //         <img className="chatB-chart-mini-icon" alt="" src={chartmin}/>
      //       </div>
      //       <div className="chatB-dropdown1">
      //         <img className="chatB-dots-vertical-icon" alt="" src={{dotsvertical}}/>
      //       </div>
      //     </div>
      //     <div className="chatB-metric-item">
      //       <div className="chatB-heading">Searches</div>
      //       <div className="chatB-number-and-chart">
      //         <div className="chatB-number-and-badge">
      //           <div className="chatB-number">1,859</div>
      //           <div className="chatB-change-and-text">
      //             <div className="chatB-change">
      //               <img className="chatB-arrow-up-icon" alt="" src={arrowup}/>
      //               <div className="chatB-change5">17%</div>
      //             </div>
      //             <div className="chatB-text12">vs last month</div>
      //           </div>
      //         </div>
      //         <img className="chatB-chart-mini-icon" alt="" src={chartmin}/>
      //       </div>
      //       <div className="chatB-dropdown1">
      //         <img className="chatB-dots-vertical-icon" alt="" src={dotsvertical}/>
      //       </div>
      //     </div>
      //     <div className="chatB-metric-item">
      //       <div className="chatB-heading1">RFP Matches</div>
      //       <div className="chatB-number-and-chart">
      //         <div className="chatB-number-and-badge">
      //           <div className="chatB-number">3,351</div>
      //           <div className="chatB-change-and-text">
      //             <div className="chatB-footer-text-wrapper">
      //               <img className="chatB-arrow-up-icon" alt="" src={arrowup}/>
      //               <div className="chatB-change7">9%</div>
      //             </div>
      //             <div className="chatB-text12">vs last month</div>
      //           </div>
      //         </div>
      //         <img className="chatB-chart-mini-icon" alt="" src={chartmin}/>
      //       </div>
      //       <div className="chatB-dropdown1">
      //         <img className="chatB-dots-vertical-icon" alt="" src={dotsvertical}/>
      //       </div>
      //     </div>
      //   </div>
      //   <img className="chatB-divider-icon2" alt="" src={divider1}/>
      //   <div className="chatB-frame-parent2">
      //     <div className="chatB-buttonsbutton-parent">
      //       <button className="chatB-buttonsbutton">
      //         <img className="chatB-eye-icon" alt="" src={eye}/>
      //         <div className="chatB-navbar">
      //           <div className="chatB-eye-label">View</div>
      //         </div>
      //       </button>
      //       <div className="chatB-buttonsbutton1">
      //         <div className="chatB-icon-wrapper">
      //           <img className="chatB-icon1" alt="" src={icon1}/>
      //         </div>
      //         <div className="chatB-navbar">
      //           <div className="chatB-button-label">Sales IQ</div>
      //         </div>
      //         <img className="chatB-eye-icon" loading="lazy" alt="" src={lock04}/>
      //       </div>
      //       <div className="chatB-buttonsbutton2">
      //         <div className="chatB-icon-wrapper">
      //           <img className="chatB-icon1" alt="" src={icon1}/>
      //         </div>
      //         <div className="chatB-navbar">
      //           <div className="chatB-button-label">Sales IQ</div>
      //         </div>
      //         <img className="chatB-eye-icon" alt="" src={lockunlocked04}/>
      //       </div>
      //       <div className="chatB-buttonsbutton3">
      //         <img className="chatB-arrow-up-icon" alt="" src={messagechatsquare}/>
      //         <div className="chatB-message-button-label">Messages</div>
      //         <div className="chatB-notification-badge-background-parent">
      //           <div className="chatB-notification-badge-background"/>
      //           <div className="chatB-notification-badge-count">2</div>
      //         </div>
      //       </div>
      //     </div>
      //     <div className="chatB-arrow-square-up-right-wrapper">
      //       <img className="chatB-arrow-square-up-right-icon" alt="" src={arrowsquareupright}/>
      //     </div>
      //   </div>
      // </div>

      <div className="analytics-listing-container">
        <div className="analytics-sales-iq-content-items" >
          <div className="analytics-sales-iq-divider-container1">
            <div className="analytics-sales-iq-rectangle-parent5">
              <img
                className="analytics-sales-iq-frame-child7"
                alt=""
                src={listingData?.logoImage ? listingData.logoImage : listingData?.featureImage}
              />
              <div className="analytics-sales-iq-frame-parent1">
                <div className="analytics-sales-iq-action-fields">
                  <div className="analytics-sales-iq-input-elements">
                    <Link to={`/${postTypeSlug}/${listingData.id}/${listingData.title}`} className="analytics-sales-iq-text78">
                      {listingData.title}
                    </Link>
                    {/* <div className="analytics-sales-iq-text78">
                      {listingData?.title}
                    </div> */}
                    <div className="analytics-sales-iq-frame-parent21">
                      <div className="analytics-sales-iq-badge-item-parent">
                        <div className="analytics-sales-iq-badge-parent">

                          {/*<PostStatusLIstingComponent postStatus={"Pending"} postSlug={"pending"} postId={listingData?.id} />*/}

                        </div>
                        <div className="analytics-sales-iq-frame-parent4">
                          <div className="analytics-sales-iq-end-label">
                            <div className="analytics-sales-iq-search-icon-input">{listingData.totalRatingAvg ? `${listingData.totalRatingAvg}/5` : "0/5"}</div>
                          </div>
                          <div className="analytics-sales-iq-type-support">
                            <div className="analytics-sales-iq-supporting-text9">
                            {listingData.totalReviews} {listingData.totalReviews === 0 || listingData.totalReviews === 1 ? "review" : "reviews"}
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="analytics-sales-iq-frame-parent24">
                        <div className="analytics-sales-iq-location-pin-parent">
                          <div className="analytics-sales-iq-search-input">
                            <img
                              className="analytics-sales-iq-marker-pin-02-icon2"
                              alt=""
                              src={markerpin02}
                            />
                          </div>
                          <div className="analytics-sales-iq-location3">
                            {/* Oklahoma City , United States of America */}

                            {(listingData?.service_address?.tax_address_type?.id ||
                              listingData?.software_address?.tax_address_type?.id ||
                              listingData?.job_address?.tax_address_type?.id) == 2
                              ? 'Remote'
                              : `${listingData?.device_address?.addressTownCity ||
                              listingData?.spare_address?.addressTownCity ||
                              listingData?.service_address?.addressTownCity ||
                              listingData?.software_address?.addressTownCity ||
                              listingData?.job_address?.addressTownCity || ''}, 
      ${listingData?.device_address?.tax_country?.name ||
                              listingData?.spare_address?.tax_country?.name ||
                              listingData?.service_address?.tax_country?.name ||
                              listingData?.software_address?.tax_country?.name ||
                              listingData?.job_address?.tax_country?.name || ''}`
                            }
                            {/* {listingData?.device_address?.addressTownCity || listingData?.spare_address?.addressTownCity || listingData?.service_address?.addressTownCity || listingData?.software_address?.addressTownCity || listingData?.job_address?.addressTownCity}, {listingData?.device_address?.tax_country?.name || listingData?.spare_address?.tax_country?.name || listingData?.service_address?.tax_country?.name || listingData?.software_address?.tax_country?.name || listingData?.job_address?.tax_country?.name} */}



                          </div>
                        </div>

                      </div>
                      <div className="analytics-sales-iq-frame-parent26">
                        <div className="analytics-sales-iq-frame-wrapper19">
                          <div className="analytics-sales-iq-legend-series">
                            <div className="analytics-sales-iq-search-input">
                              <img
                                className="analytics-sales-iq-marker-pin-02-icon2"
                                alt=""
                                src={calendar1}
                              />
                            </div>
                            <div className="analytics-sales-iq-type-of-work2">{getCustomizedDate(listingData.createdAt)}</div>
                            {/* <div className="analytics-sales-iq-type-of-work2">November 13,2024</div> */}
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>

                  <div className="analytics-sales-iq-small-card">

                    <div className="analytics-sales-iq-badge33">
                      <div className="analytics-sales-iq-third-badge-number">
                        {/* {currentListingData.tax_device_category.name} */}
                        {/* 1/25-DIHYDROXYDEVICE */}
                        {listingData?.tax_engagement_type?.name ? listingData.tax_engagement_type.name
                          : listingData?.tax_service_category?.name ? listingData.tax_service_category.name
                            :
                            listingData?.tax_software_category?.name ? listingData.tax_software_category.name
                              :
                              listingData?.tax_device_category?.name ? listingData.tax_device_category.name
                                : null}

                      </div>
                    </div>



                    <div className="analytics-sales-iq-badge37">
                      {/* <div className="analytics-sales-iq-third-badge-number">{currentListingData.tax_oem.name}</div> */}
                      <div className="analytics-sales-iq-third-badge-number">
                        {listingData?.tax_experience_level?.name ? listingData.tax_experience_level.name : listingData?.tax_software_type?.name ? listingData.tax_software_type.name : listingData?.tax_oem?.name ? listingData.tax_oem.name : listingData?.tax_physical_location?.name ? listingData.tax_physical_location.name : null}
                      </div>
                    </div>


                    <div className="analytics-sales-iq-badge38">
                      <div className="analytics-sales-iq-third-badge-number">
                        {/* {currentListingData.tax_clinical_application.name} */}
                        {listingData?.tax_occupation_category?.name ? listingData.tax_occupation_category.name : listingData?.tax_clinical_application?.name}
                      </div>
                    </div>



                  </div>

                </div>


                <div className="analytics-sales-iq-filter-elements">
                  <div className="analytics-sales-iq-dropdown10"
                  // onClick={() => toggleThreeDotsDropdown()} ref={threeDotsRef}
                  >
                    <div className="analytics-sales-iq-dropdown10" >

                      {/* <img
                        className="analytics-sales-iq-dots-vertical-icon"
                        alt=""
                        src={dotsvertical}
                      /> */}
                      {/* {activeThreeDot && <ThreeDotsListingComponent url={`device/${currentListingData.id}`} />} */}
                    </div>
                  </div>
                  <div className="analytics-sales-iq-filter-elements-inner">
                    <div className="analytics-sales-iq-price-parent">
                      {
                        listingData?.tax_transaction_type &&
                        <TransactionTypeComponent transactionType={listingData.tax_transaction_type.name} slug={listingData.tax_transaction_type.slug} />
                      }
                      {/*<TransactionTypeComponent transactionType={"Sale"} slug={"sale"} />*/}

                      {listingData?.price &&
                        <div className="analytics-sales-iq-price2">${formatNumber(listingData?.price)}</div>
                      }

                      {listingData?.rangeMin &&
                        <div className="analytics-sales-iq-price2">${formatNumber(listingData?.rangeMin)}</div>
                      }

                      {listingData?.budget &&
                        <div className="analytics-sales-iq-price2">${formatNumber(listingData?.budget)}</div>
                      }
                      {/* <div className="analytics-sales-iq-price2">$258</div> */}

                      <div className="analytics-sales-iq-dropdown-elements">
                        <div className="analytics-sales-iq-dropdown-options-parent">
                          {
                            listingData?.tax_unit_measure_device_spare &&
                            <div className="analytics-sales-iq-text83">{listingData.tax_unit_measure_device_spare.name}</div>
                          }

                          {
                            listingData?.tax_unit_measure_service &&
                            <div className="analytics-sales-iq-text83">{listingData.tax_unit_measure_service.name}</div>
                          }

                          {
                            listingData?.tax_unit_measure_software &&
                            <div className="analytics-sales-iq-text83">{listingData.tax_unit_measure_software.name}</div>
                          }


                          {/* <div className="analytics-sales-iq-text83">USD - Based on terms of exhange</div> */}

                          {/* <div className="analytics-sales-iq-frame-wrapper3">
                            <div className="analytics-sales-iq-radar-1-1-container">
                              <img
                                className="analytics-sales-iq-magnifier-1-icon"
                                alt=""
                                src={radar_1_1_2x}
                              />

                              <img
                                        className="analytics-sales-iq-magnifier-1-icon"
                                        alt=""
                                        src={deal_2_1_2x}
                                      />

                                      <img
                                        className="analytics-sales-iq-magnifier-1-icon"
                                        alt=""
                                        src={magnifier_1_2x}
                                      />
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>


            <img className="analytics-sales-iq-divider-icon8" alt="" src={divider2} />




            <div className="analytics-sales-iq-frame-parent71">
              <div className="analytics-sales-iq-buttonsbutton-parent1">
                <div className="analytics-sales-iq-legend-series">
                  <img
                    className="analytics-sales-iq-location-column-child"
                    alt=""
                    // src={currentListingData.profileImage}
                    src={listingData?.profileImage}
                  />

                  <div className="analytics-sales-iq-location-column-inner">
                    <div className="analytics-sales-iq-date-picker-dropdown">
                      {/* <div className="analytics-sales-iq-name">{currentListingData.device_business.business_user.displayName}</div>
                      <div className="analytics-sales-iq-role">{currentListingData.device_business.businessName}</div> */}

                      {/* <div className="analytics-sales-iq-name">{currentListingData.device_business.business_user.displayName}</div>
                              <Link className="analytics-sales-iq-role" to={`/business/${currentListingData.device_business.id}/${currentListingData.device_business.businessName}`}>
                                {currentListingData.device_business.businessName}
                              </Link> */}

                      <div className="analytics-sales-iq-name">{listingData?.device_user?.displayName || listingData?.spare_user?.displayName || listingData?.service_user?.displayName || listingData?.software_user?.displayName || listingData?.job_user?.displayName}</div>
                      <div className="analytics-sales-iq-role" >
                        {listingData?.device_business?.businessName || listingData?.spare_business?.businessName || listingData?.service_business?.businessName || listingData?.software_business?.businessName || listingData?.job_business?.businessName}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='analytics-main-buttons-group'>
                  <div className="analytics-sales-iq-buttonsbutton13">
                    <img
                      className="analytics-sales-iq-arrow-narrow-left-icon"
                      alt=""
                      src={eye}
                    />

                    <div className="analytics-sales-iq-search-input">
                      <div className="analytics-sales-iq-text67">View</div>
                    </div>
                  </div>
                  {/* <div className="analytics-sales-iq-buttonsbutton25">
                    <div className="analytics-sales-iq-icon-wrapper">
                      <img className="analytics-sales-iq-icon1" alt="" src={icon1} />
                    </div>
                    <div className="analytics-sales-iq-search-input">
                      <div className="analytics-sales-iq-text68">Sales IQ</div>
                    </div>
                    <img
                      className="analytics-sales-iq-arrow-narrow-left-icon"
                      alt=""
                      src={lock04}
                    />
                  </div> */}


                  {/* <div className="analytics-sales-iq-buttonsbutton26">
                    <div className="analytics-sales-iq-icon-wrapper">
                      <img className="analytics-sales-iq-icon1" alt="" src={icon1} />
                    </div>
                    <div className="analytics-sales-iq-search-input">
                      <div className="analytics-sales-iq-text68">Sales IQ</div>
                    </div>
                    <img
                      className="analytics-sales-iq-arrow-narrow-left-icon"
                      alt=""
                      src={lockunlocked04}
                    />
                  </div>
 */}


                  {/* <div className="analytics-sales-iq-buttonsbutton27">
                    <img
                      className="analytics-sales-iq-upload-04-icon"
                      alt=""
                      src={messagechatsquare}
                    />

                    <div className="analytics-sales-iq-text145">Messages</div>
                    <div className="analytics-sales-iq-ellipse-parent">
                      <div className="analytics-sales-iq-ellipse-div"></div>
                      <div className="analytics-sales-iq-div">2</div>
                    </div>
                  </div> */}
                </div>

              </div>
              <a
                // href={`/device/${device.id}/${device.title}`}
                target="_blank"
                rel="noopener noreferrer"
                className="analytics-sales-iq-arrow-square-up-right-wrapper1"
              >
                <img
                  className="analytics-sales-iq-arrow-square-up-right-icon1"
                  alt=""
                  src={arrowsquareupright}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export { Analytics };


