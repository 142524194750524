import React from 'react';
import "./ThreeDotsListingComponent.css";
import divider from '../../icons/Gerator/dashboard/divider.svg';
import { Link, useNavigate } from 'react-router-dom';
import {useSetAdminArchiveDeviceMutation, useSetAdminPublishDeviceMutation, useSetAdminPendingDeviceMutation} from "../../api/admin/AdminDeviceAPI";
import {useSetAdminArchiveSpareMutation, useSetAdminPublishSpareMutation, useSetAdminPendingSpareMutation} from "../../api/admin/AdminSpareAPI";
import {useSetAdminArchiveJobMutation, useSetAdminPublishJobMutation, useSetAdminPendingJobMutation} from "../../api/admin/AdminJobAPI"
import {useSetAdminArchiveServiceMutation, useSetAdminPublishServiceMutation, useSetAdminPendingServiceMutation} from "../../api/admin/AdminServiceAPI"
import {useSetAdminArchiveSoftwareMutation, useSetAdminPublishSoftwareMutation, useSetAdminPendingSoftwareMutation} from "../../api/admin/AdminSoftwareAPI"


export const ThreeDotsAdminListingComponent = ({ option, postId, onClose, postType, url, totalActiveEngagement }) => {
  // const handleOptionClick = (option) => {
  //   onOptionSelect(option); // Call the function from the parent with the selected option
  // };

  const navigate = useNavigate();
  
    const [setAdminToDeviceArchive] = useSetAdminArchiveDeviceMutation();
  
    const [setAdminToDevicePublish] = useSetAdminPublishDeviceMutation();

    const [setAdminToDevicePending] = useSetAdminPendingDeviceMutation();
  

     
    const [setAdminToSpareArchive] = useSetAdminArchiveSpareMutation();
  
    const [setAdminToSparePublish] = useSetAdminPublishSpareMutation();

    const [setAdminToSparePending] = useSetAdminPendingSpareMutation();



    const [setAdminToServiceArchive] = useSetAdminArchiveServiceMutation();
  
    const [setAdminToServicePublish] = useSetAdminPublishServiceMutation();

    const [setAdminToServicePending] = useSetAdminPendingServiceMutation();


    const [setAdminToSoftwareArchive] = useSetAdminArchiveSoftwareMutation();
  
    const [setAdminToSoftwarePublish] = useSetAdminPublishSoftwareMutation();

    const [setAdminToSoftwarePending] = useSetAdminPendingSoftwareMutation();


    const [setAdminToJobArchive] = useSetAdminArchiveJobMutation();
  
    const [setAdminToJobPublish] = useSetAdminPublishJobMutation();

    const [setAdminToJobPending] = useSetAdminPendingJobMutation();


    const handleEdit = async (e) => {
      e.preventDefault();
      e.stopPropagation();
  
      try {
  
        navigate(`/admin/edit-${url}`);
      } catch (error) {
        console.error('Error navigating ' + error);
      }
    };
  
    const handleSetArchive = async (e) => {
      let response;
      e.preventDefault();
      e.stopPropagation();
  
      try {
        if (!postId) {
          console.error('No post ID provided');
          return;
        }
  
        switch (postType) {
          case 'device':
            response = await setAdminToDeviceArchive({ id: postId }).unwrap();
            break;
          case 'service':
            response = await setAdminToServiceArchive({ id: postId }).unwrap();
            break;
          case 'software':
            response = await setAdminToSoftwareArchive({ id: postId }).unwrap();
            break;
          case 'spare':
            response = await setAdminToSpareArchive({ id: postId }).unwrap();
            break;
          case 'job':
            response = await setAdminToJobArchive({ id: postId }).unwrap();
            break;
          default:
            break;
        }
  
        // console.log('Updating status for post:', postId);
        // const response = await setToWantedDeviceArchive({ id: postId }).unwrap();
        // console.log('Update response:', response);
  
        if (response?.status) {
          onClose();
          window.location.reload();
        }
      } catch (error) {
        console.error('Error updating status:', error);
      }
    };
  
    const handleSetPublish = async (e) => {
      let response;
      e.preventDefault();
      e.stopPropagation();
  
      try {
        if (!postId) {
          console.error('No post ID provided');
          return;
        }
  
  
        switch (postType) {
          case 'device':
            response = await setAdminToDevicePublish({ id: postId }).unwrap();
            break;
          case 'service':
            response = await setAdminToServicePublish({ id: postId }).unwrap();
            break;
          case 'software':
            response = await setAdminToSoftwarePublish({ id: postId }).unwrap();
            break;
          case 'spare':
            response = await setAdminToSparePublish({ id: postId }).unwrap();
            break;
          case 'job':
            response = await setAdminToJobPublish({ id: postId }).unwrap();
            break;
          default:
            break;
        }
  
        // console.log('Updating status for post:', postId);
        // const response = await setToWantedDevicePublish({ id: postId }).unwrap();
        // console.log('Update response:', response);
  
        if (response.status) {
          onClose();
          window.location.reload();
        }
      } catch (error) {
        console.error('Error updating status:', error);
      }
    };

    const handleSetPending = async (e) => {
      let response;
      e.preventDefault();
      e.stopPropagation();
  
      try {
        if (!postId) {
          console.error('No post ID provided');
          return;
        }
  
  
        switch (postType) {
          case 'device':
            response = await setAdminToDevicePending({ id: postId }).unwrap();
            break;
          case 'service':
            response = await setAdminToServicePending({ id: postId }).unwrap();
            break;
          case 'software':
            response = await setAdminToSoftwarePending({ id: postId }).unwrap();
            break;
          case 'spare':
            response = await setAdminToSparePending({ id: postId }).unwrap();
            break;
          case 'job':
            response = await setAdminToJobPending({ id: postId }).unwrap();
            break;
          default:
            break;
        }
  
        // console.log('Updating status for post:', postId);
        // const response = await setToWantedDevicePublish({ id: postId }).unwrap();
        // console.log('Update response:', response);
  
        if (response.status) {
          onClose();
          window.location.reload();
        }
      } catch (error) {
        console.error('Error updating status:', error);
      }
    };

    const handleItemClick = (e, action) => {
      e.preventDefault();
      e.stopPropagation();
      console.log('Action clicked:', action);
  
      switch (action) {
        case 'edit':
          handleEdit(e);
          break;
        case 'publish':
          handleSetPublish(e);
          break;
        case 'archive':
          handleSetArchive(e);
          break;
          case 'pending':
          handleSetPending(e);
          break;
        // Add other cases as needed
        default:
        // handleRejectUpdate(e);
      }
    };

    const MenuItem = ({ onClick, text, to, action }) => (
        <div
          className="three-dots-filter-dropdown-dropdown-list-item"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (onClick) onClick(e);
            if (action) handleItemClick(e, action);
          }}
        >
          <div className="three-dots-filter-dropdown-content48">
            <div className="three-dots-filter-dropdown-icon-and-text">
              {to ? (
                <Link
                  className="three-dots-filter-dropdown-text247"
                  to={to}
                  onClick={(e) => e.stopPropagation()}
                >
                  {text}
                </Link>
              ) : (
                <div className="three-dots-filter-dropdown-text247">{text}</div>
              )}
            </div>
          </div>
        </div>
      );

  return (
    <div className="three-dots-filter-dropdown-three-dots-filter-all-options-fixed">
      <section className="three-dots-filter-dropdown-menu-items">
          {option === 'published' && (
          <>
            {totalActiveEngagement === 0 ?
              <>
                <MenuItem text="Edit" action="edit" />
                <MenuItem text="Archive" action="archive" />
                <MenuItem text="Unpublish" action="pending" />
              </> :
              <MenuItem text="No action found" />
            }
          </>
        )}
         {option === 'archived' && (
          <>
            {totalActiveEngagement === 0 ?
              <>
                <MenuItem text="Edit" action="edit" />
                <MenuItem text="Republish" action="publish" />
                <MenuItem text="Unpublish" action="pending" />
              </> :
              <MenuItem text="No action found" />
            }
          </>
        )}

          {option === 'pending' && (
                
                  <MenuItem text="No action found" />
                  
              )}
        {/* <div className="three-dots-filter-dropdown-dropdown-list-item" >
          <div className="three-dots-filter-dropdown-content48">
            <div className="three-dots-filter-dropdown-icon-and-text">
              <Link className="three-dots-filter-dropdown-text247" to={`/admin/delete-${url}`}>Delete</Link>
            </div>
          </div>
        </div> */}
        {/* <div className="three-dots-filter-dropdown-dropdown-list-item" >
          <div className="three-dots-filter-dropdown-content48">
            <div className="three-dots-filter-dropdown-icon-and-text">
              <div className="three-dots-filter-dropdown-text247">Price (high to low)</div>
            </div>
          </div>
        </div> */}
      </section>
    </div>
  )
}

