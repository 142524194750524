import React from 'react';
import "./ThreeDotsAdminBusinessComponent.css";
import divider from '../../icons/Gerator/dashboard/divider.svg';
import { Link, useNavigate } from 'react-router-dom';
import { useSetUserActiveMutation, useSetUserInactiveMutation } from '../../api/admin/AdminUserAPI';

export const ThreeDotsAdminBusinessComponent = ({ businessId }) => {

  // const navigate = useNavigate();

  // const [setToInactive] = useSetUserInactiveMutation();

  // const [setToActive] = useSetUserActiveMutation();

  const handleChangeStatus = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      if (!businessId) {
        console.error('No user ID provided');
        return;
      }

      navigate(`/admin/dashboard/settings/business/${businessId}`);

      // console.log('Updating status for user:', businessId);
      // const response = await setToInactive({ id: businessId }).unwrap();
      // console.log('Update response:', response);

      // if (response.status) {
      //   onClose();
      //   window.location.reload();
      // }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  // const handleActiveUpdate = async (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();

  //   try {
  //     if (!businessId) {
  //       console.error('No user ID provided');
  //       return;
  //     }

  //     console.log('Updating status for user:', businessId);
  //     const response = await setToActive({ id: businessId }).unwrap();
  //     console.log('Update response:', response);

  //     if (response.status) {
  //       onClose();
  //       window.location.reload();
  //     }
  //   } catch (error) {
  //     console.error('Error updating status:', error);
  //   }
  // };


  const handleItemClick = (e, action) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('Action clicked:', action);

    switch (action) {
      case 'change_status':
        handleChangeStatus(e);
        break;
      // case 'inactive':
      //   handleInactiveUpdate(e);
      //   break;
      // case 'accept':
      //   handleAcceptUpdate(e);
      //   break;
      // case 'reject':
      //   handleRejectUpdate(e);
      //   break;
      // case 'rejectActive':
      //   handleMakeRejectActiveUpdate(e);
      //   break;
      // case 'revoke':
      //   handleRevokeUpdate(e);
      //   break;
      // case 'revokeActive':
      //   handleMakeRevokeActiveUpdate(e);
      //   break;
      // Add other cases as needed
      default:
      // handleRejectUpdate(e);
    }
  };

  // Reusable menu item component with proper event handling
  // const MenuItem = ({ onClick, text, to, action }) => (
  //   <div
  //     className="three-dots-business-admin-filter-dropdown-dropdown-list-item"
  //     onClick={(e) => {
  //       e.preventDefault();
  //       e.stopPropagation();
  //       if (onClick) onClick(e);
  //       if (action) handleItemClick(e, action);
  //     }}
  //   >
  //     <div className="three-dots-business-admin-filter-dropdown-content48">
  //       <div className="three-dots-business-admin-filter-dropdown-icon-and-text">
  //         {to ? (
  //           <Link
  //             className="three-dots-business-admin-filter-dropdown-text247"
  //             to={to}
  //             onClick={(e) => e.stopPropagation()}
  //           >
  //             {text}
  //           </Link>
  //         ) : (
  //           <div className="three-dots-business-admin-filter-dropdown-text247">{text}</div>
  //         )}
  //       </div>
  //     </div>
  //   </div>
  // );

  return (
    <div className="three-dots-business-admin-filter-dropdown-three-dots-business-admin-filter-all-options-fixed">
      <section className="three-dots-business-admin-filter-dropdown-menu-items">
        {/* {option === 'status' && (
          <MenuItem
            text="Change Status"
            action="change_status"
          />
        )} */}

        {/* {option === 'active' && (
          <MenuItem
            text="Inactive"
            action="inactive"
          />
        )} */}
        <div className="three-dots-business-admin-filter-dropdown-dropdown-list-item" >
          <div className="three-dots-business-admin-filter-dropdown-content48">
            <div className="three-dots-business-admin-filter-dropdown-icon-and-text">
              <Link className="three-dots-business-admin-filter-dropdown-text247" to={`/admin/dashboard/settings/business/${businessId}`}>Change Status</Link>
            </div>
          </div>
        </div>
        {/* <div className="three-dots-business-admin-filter-dropdown-dropdown-list-item" >
          <div className="three-dots-business-admin-filter-dropdown-content48">
            <div className="three-dots-business-admin-filter-dropdown-icon-and-text">
              <Link className="three-dots-business-admin-filter-dropdown-text247" to={`/admin/archive-${url}`}>Inactive</Link>
            </div>
          </div>
        </div> */}
        {/* <div className="three-dots-business-admin-filter-dropdown-dropdown-list-item" >
          <div className="three-dots-business-admin-filter-dropdown-content48">
            <div className="three-dots-business-admin-filter-dropdown-icon-and-text">
              <Link className="three-dots-business-admin-filter-dropdown-text247" to={`/admin/delete-${url}`}>Delete</Link>
            </div>
          </div>
        </div> */}
        {/* <div className="three-dots-business-admin-filter-dropdown-dropdown-list-item" >
          <div className="three-dots-business-admin-filter-dropdown-content48">
            <div className="three-dots-business-admin-filter-dropdown-icon-and-text">
              <div className="three-dots-business-admin-filter-dropdown-text247">Price (high to low)</div>
            </div>
          </div>
        </div> */}
      </section>
    </div>
  )
}



export default ThreeDotsAdminBusinessComponent;