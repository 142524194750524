import React, { useEffect, useState, useRef } from "react";
import "./AdminDetailedWantedDevice.css";
import { Navbar1 } from "../../../../components/Navbar1/Navbar1";
import FooterDetailPage from "../../../../components/Footer/FooterDetailPage/FooterDetailPage";

import divider from "../../../../icons/Gerator/detailed_listing/divider.svg";
import divider2 from "../../../../icons/Gerator/detailed_listing/divider2.svg";

import chevronright from "../../../../icons/Gerator/detailed_listing/chevronright.svg";

import placeholder1 from "../../../../icons/Gerator/detailed_listing/placeholder1.svg";

import markerpin02 from "../../../../icons/Gerator/detailed_listing/markerpin02.svg";

import calendar from "../../../../icons/Gerator/detailed_listing/calendar.svg";

import facebook_1_1_2x from "../../../../icons/Gerator/detailed_listing/facebook-1-1@2x.png";

import instagram_1_1_2x from "../../../../icons/Gerator/detailed_listing/instagram-1-1@2x.png";
import whatsapp_1_2x from "../../../../icons/Gerator/detailed_listing/whatsapp-1@2x.png";
import twitter_1_2x from "../../../../icons/Gerator/detailed_listing/twitter-1@2x.png";
import linkedinlogo_1_2x from "../../../../icons/Gerator/detailed_listing/linkedinlogo-1@2x.png";

import radar_1_1_2x from "../../../../icons/Gerator/detailed_listing/radar-1-1@2x.png";
import deal_2_1_2x from "../../../../icons/Gerator/detailed_listing/deal-2-1@2x.png";
import magnifier_1_2x from "../../../../icons/Gerator/detailed_listing/magnifier-1@2x.png";
import share_1_2x from "../../../../icons/Gerator/detailed_listing/share-1@2x.png";

import group_1010 from "../../../../icons/Gerator/detailed_listing/group-1010.svg";
import group_781_2x from "../../../../icons/Gerator/detailed_listing/group-781@2x.png";

import dotsvertical from "../../../../icons/Gerator/detailed_listing/dotsvertical.svg";
import divider1 from "../../../../icons/Gerator/detailed_listing/divider1.svg";
// import maximize01 from '../../../../icons/Gerator/detailed_listing/maximize01.svg';
// import placeholder2 from '../../../../icons/Gerator/detailed_listing/placeholder2.svg';
// import calendar1 from '../../../../icons/Gerator/detailed_listing/calendar1.svg';
// import divider4 from '../../../../icons/Gerator/detailed_listing/divider4.svg';
// import divider5 from '../../../../icons/Gerator/detailed_listing/divider5.svg';
// import divider3 from '../../../../icons/Gerator/detailed_listing/divider3.svg';
// import rectangle_9_2x from '../../../../icons/Gerator/detailed_listing/rectangle-9@2x.png';
// import rectangle_5_2x from '../../../../icons/Gerator/detailed_listing/rectangle-5@2x.png';
// import rectangle_43_2x from '../../../../icons/Gerator/detailed_listing/rectangle-43@2x.png';
// import rectangle_95_2x from '../../../../icons/Gerator/detailed_listing/rectangle-95@2x.png';
// import rectangle_431_2x from '../../../../icons/Gerator/detailed_listing/rectangle-431@2x.png';
// import rectangle_196_2x from '../../../../icons/Gerator/detailed_listing/rectangle-196@2x.png';

// import arrowleft from '../../../../icons/Gerator/detailed_listing/arrowleft.svg';
// import arrowright from '../../../../icons/Gerator/detailed_listing/arrowright.svg';

// import breadcrumb_controls_2x from '../../../../icons/Gerator/detailed_listing/breadcrumb-controls@2x.png';
// import breadcrumb_controls1_2x from '../../../../icons/Gerator/detailed_listing/breadcrumb-controls1@2x.png';
// import breadcrumb_controls2_2x from '../../../../icons/Gerator/detailed_listing/breadcrumb-controls2@2x.png';

// import placeholder from '../../../../icons/Gerator/detailed_listing/placeholder.svg';
// import group_58_2x from '../../../../icons/Gerator/detailed_listing/group-58@2x.png';
// import group_78_2x from '../../../../icons/Gerator/detailed_listing/group-78@2x.png';
// import group_351 from '../../../../icons/Gerator/detailed_listing/group-351.svg';
// import page from '../../../../icons/Gerator/detailed_listing/page.svg';
// import progress_fill from '../../../../icons/Gerator/detailed_listing/progress-fill.svg';
// import searchlg from '../../../../icons/Gerator/detailed_listing/searchlg.svg';
// import filterlines from '../../../../icons/Gerator/detailed_listing/filterlines.svg';
// import star_background from '../../../../icons/Gerator/detailed_listing/star-background.svg';
// import star_icon from '../../../../icons/Gerator/detailed_listing/star-icon.svg';
// import f8595f4b711e503bc72fe396e5043e0c2_7_2x from '../../../../icons/Gerator/detailed_listing/8595f4b711e503bc72fe396e5043e0c2-7@2x.png';
// import messagedotscircle from '../../../../icons/Gerator/detailed_listing/messagedotscircle.svg';
// import markerpin021 from '../../../../icons/Gerator/detailed_listing/markerpin021.svg';
// import icon1 from '../../../../icons/Gerator/detailed_listing/icon1.svg';
// import login04 from '../../../../icons/Gerator/detailed_listing/login04.svg';
import dot1 from "../../../../icons/Gerator/detailed_listing/-dot1.svg";
import bookmarkcheck from "../../../../icons/Gerator/detailed_listing/bookmarkcheck.svg";
import usercheck02 from "../../../../icons/Gerator/detailed_listing/usercheck02.svg";
import messagechatcircle from "../../../../icons/Gerator/detailed_listing/messagechatcircle.svg";
import users01 from "../../../../icons/Gerator/detailed_listing/users01.svg";
import target_04 from "../../../../icons/Gerator/dashboard/target_04.png";
import add_contact from "../../../../icons/Gerator/detailed_listing/add_contact.svg";

import { Loader } from "../../../../components/Loader";
// import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getCustomizedDate } from "../../../../helper/DeviceHelper";
import { decryptErrorData } from "../../../../helper/AESHelper";
import { useLazyGetProfileImageLinkQuery, useLazyGetUserQuery, } from "../../../../api/Login&RegisterAPI";
import { firestore, doc, getDoc, auth, onAuthStateChanged, } from "../../../Login/FirebaseClient";
import { Helmet } from "react-helmet-async";
import { useLazyFindGetWantedDeviceQuery, useLazyGetWantedDevicesQuery, } from "../../../../api/WantedDeviceAPI";
import { useAddFunnelWantedDeviceMutation, useDeleteFunnelWantedDeviceMutation, } from "../../../../api/Funnel/FunnelWantedDeviceAPI";
import { ErrorShowComponent } from "../../../../components/ErrorShowComponent/ErrorShowComponent";
import { useAddContactMutation, useDeleteContactMutation, } from "../../../../api/ContactAPI";

import follow from "../../../../icons/Gerator/detailed_listing/follow.svg";
import ShareThisMetaData from "../../../../components/ShareThisMetaData/ShareThisMetaData";
import { InlineShareButtons } from "sharethis-reactjs";
import { InlineFollowButtons } from "sharethis-reactjs";

import funnel from "../../../../icons/Gerator/dashboard/funnel.svg";

import { ThreeDotsDetailPageComponent } from "../../../../components/ThreeDotsListingComponent/ThreeDotsDetailPageComponent";
import { ThreeDotsDetailPageUserComponent } from "../../../../components/ThreeDotsListingComponent/ThreeDotsDetailPageUserComponent";
import { useAddViewWantedDeviceMutation, useSetArchiveWantedDeviceMutation, useSetPublishWantedDeviceMutation } from "../../../../api/Beacon/BeaconWantedDeviceAPI";
import { useLazyFindGetAdminWantedDeviceQuery } from "../../../../api/admin/AdminWantedDeviceAPI";
import { AdminNavbar } from "../../../../components/Navbar1/AdminNavbar";

export const AdminDetailedWantedDevice = () => {
  const [isThreeDotOpen, setIsThreeDotOpen] = useState(false);
  const threeDotsRef = useRef(null);
  const [isClickBlocked, setIsClickBlocked] = useState(false);

  // Toggle function for the three dots dropdown
  const toggleThreeDotsDropdown = () => {
    if (isClickBlocked) return; // Prevent rapid toggle

    setIsThreeDotOpen((prev) => !prev); // Toggle the dropdown
    setIsClickBlocked(true);
    setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
  };

  // Handle clicks outside the three dots dropdown
  useEffect(() => {
    const handleThreeDotClickOutside = (event) => {
      const isLink = event.target.closest("a");
      if (isLink) return; // Allow navigation to happen

      if (
        threeDotsRef.current &&
        !threeDotsRef.current.contains(event.target)
      ) {
        setIsThreeDotOpen(false); // Close the dropdown when clicking outside
      }
    };

    document.addEventListener("mousedown", handleThreeDotClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleThreeDotClickOutside);
    };
  }, []);

  // For second dropdown (for user or other context)
  const [isThreeDotUserOpen, setIsThreeDotUserOpen] = useState(false);
  const threeDotsRefUser = useRef(null);
  const [isClickBlockedUser, setIsClickBlockedUser] = useState(false);

  // Toggle function for the second three dots dropdown
  const toggleThreeDotsDropdownUser = () => {
    if (isClickBlockedUser) return; // Prevent rapid toggle

    setIsThreeDotUserOpen((prev) => !prev); // Toggle the dropdown
    setIsClickBlockedUser(true);
    setTimeout(() => setIsClickBlockedUser(false), 400); // Small delay to avoid reopening
  };

  // Handle clicks outside the second three dots dropdown
  useEffect(() => {
    const handleThreeDotClickOutsideUser = (event) => {
      const isLink = event.target.closest("a");
      if (isLink) return; // Allow navigation to happen

      if (
        threeDotsRefUser.current &&
        !threeDotsRefUser.current.contains(event.target)
      ) {
        setIsThreeDotUserOpen(false); // Close the dropdown when clicking outside
      }
    };

    document.addEventListener("mousedown", handleThreeDotClickOutsideUser);
    return () => {
      document.removeEventListener("mousedown", handleThreeDotClickOutsideUser);
    };
  }, []);

  const [activeSection, setActiveSection] = useState(null); // "share", "follow", or null

  const handleShareButtonClick = () => {
    setActiveSection((prevState) => (prevState === "share" ? null : "share")); // Toggle or close
  };

  const handleFollowButtonClick = () => {
    setActiveSection((prevState) => (prevState === "follow" ? null : "follow")); // Toggle or close
  };

  const { id } = useParams();
  let content;
  const [currentUser, setCurrentUser] = useState("");
  const navigate = useNavigate();
  const [profileImageLink, setProfileImageLink] = useState("");
  const [currentUsermeta, setCurrentUserMeta] = useState("");

  const [
    triggerGetWantedDevice,
    {
      data: wantedDeviceData,
      isLoading: isWantedDeviceLoading,
      isSuccess: isWantedDeviceSuccess,
      isError: isWantedDeviceError,
      error: wantedDeviceError,
    },
  ] = useLazyFindGetAdminWantedDeviceQuery(id);

  const [triggerNewProfileImageLink] = useLazyGetProfileImageLinkQuery();

  // Listing Operation
  const [setToWantedDeviceArchive] = useSetArchiveWantedDeviceMutation();

  const [setToWantedDevicePublish] = useSetPublishWantedDeviceMutation();

  // Add/Remove Contact Query
  // const [
  //   addToContact,
  //   {
  //     isLoading: isContactLoading,
  //     isSuccess: isContactSuccess,
  //     isError: isContactError,
  //     error: contactError,
  //   },
  // ] = useAddContactMutation();

  // const [deleteFromContact] = useDeleteContactMutation();

  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        if (user) {
          // Get user to check wizardStep
          const userDocRef = doc(firestore, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);
          setCurrentUser(user);

          if (userDocSnapshot.exists()) {
            const usermeta = userDocSnapshot.data(); // Default to 1 if not set

            if (usermeta.userStatus == 2) {
              navigate("/account-disabled");
              return;
            }

            if (usermeta.userType !== 5) {
              navigate("/access-denied");
              return;
            }

            if (usermeta.wizardStep !== 0) {
              navigate("/account-setup-check-email");
              return;
            }

            // await triggerGetCurrentPlan().unwrap();
            setCurrentUserMeta(usermeta);
            triggerGetWantedDevice(id);
            // User is authenticated
          } else {
            content = <ErrorShowComponent message1={"Internal server error"} />;
          }
        }
      } else {
        // User is not authenticated, redirect to login
        navigate("/sign-in", {
          state: { message: "Please log in to continue." },
        });
      }
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);

  //Profile image load useEffect
  useEffect(() => {
    const refreshProfileImage = async () => {
      if (currentUsermeta && currentUsermeta.profileImage?.expiresAt < Date.now() / 1000) {
        // Pre-signed URL has expired, trigger a new request to the backend
        const newImageData = await triggerNewProfileImageLink().unwrap();
        // Update the profile image link directly
        setProfileImageLink(newImageData.url);
      } else if (currentUsermeta && currentUsermeta.profileImage?.url) {
        // Use the existing pre-signed URL to display the profile image
        setProfileImageLink(currentUsermeta.profileImage.url);
      }
    };

    refreshProfileImage();
  }, [currentUsermeta]);

  // useEffect(() => {
  //   if (isWantedDeviceSuccess) {
  //     addViewWantedDevice(id);
  //     console.log("added view wanted device data");
  //   }
  // }, [isWantedDeviceSuccess]);

  //Funnel Code
  // const handleRemoveFunnel = async (wantedDeviceId) => {
  //   await deleteFromFunnel(wantedDeviceId);
  //   window.location.reload();
  // };

  // const handleAddFunnel = async (wantedDeviceId) => {
  //   await addToFunnel(wantedDeviceId);
  //   window.location.reload();
  // };

  const handleSetArchive = async (e) => {
    let response;
    e.preventDefault();
    e.stopPropagation();

    try {
      if (!id) {
        console.error('No post ID provided');
        return;
      }

      response = await setToWantedDeviceArchive({ id }).unwrap();

      if (response?.status) {
        // onClose();
        window.location.reload();
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleSetPublish = async (e) => {
    let response;
    e.preventDefault();
    e.stopPropagation();

    try {
      if (!id) {
        console.error('No post ID provided');
        return;
      }

      response = await setToWantedDevicePublish({ id }).unwrap();

      // console.log('Updating status for post:', postId);
      // const response = await setToWantedDevicePublish({ id: postId }).unwrap();
      // console.log('Update response:', response);

      if (response.status) {
        window.location.reload();
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  // //Contact Code
  // const handleRemoveContact = async (wantedDeviceId) => {
  //   await deleteFromContact(wantedDeviceId);
  //   window.location.reload();
  // };

  // const handleAddContact = async (wantedDeviceId) => {
  //   await addToContact(wantedDeviceId);
  //   window.location.reload();
  // };

  if (isWantedDeviceError) {
    content = <ErrorShowComponent message1={"Internal server error"} />;
  }

  // const handlePrivateMessage = () => {
  //   const wantedDeviceId = wantedDeviceData?.data?.id; // Use the appropriate ID for the chat
  //   // handleAddRadar(wantedDeviceId);
  //   navigate(`/chat/wanted/Device/${wantedDeviceId}`); // Replace `/chat/:id` with the correct route for your chat page
  // };

  if (isWantedDeviceSuccess) {
    return (
      <>
        <Helmet>
          <title>Wanted Device</title>
        </Helmet>
        <div className="admin-detailed-wanted-device-listings-detailed-page-devic">
          <div className="admin-detailed-wanted-device-rectangle-parent">
            <div className="admin-detailed-wanted-device-frame-child"></div>
            <AdminNavbar userData={currentUser} navigate={navigate} profileImageLink={profileImageLink} />

            <img
              className="admin-detailed-wanted-device-divider-icon"
              alt=""
              src={divider}
            />

            <div className="admin-detailed-wanted-device-breadcrumb-button-base">
              <div className="admin-detailed-wanted-device-text6">...</div>
            </div>
            <img
              className="admin-detailed-wanted-device-chevron-right-icon"
              alt=""
              src={chevronright}
            />

            <div className="admin-detailed-wanted-device-breadcrumb-button-base1">
              <div className="admin-detailed-wanted-device-text7">Another link</div>
            </div>
            <img
              className="admin-detailed-wanted-device-chevron-right-icon1"
              alt=""
              src={chevronright}
            />

            <div className="admin-detailed-wanted-device-frame-parent">
              <div className="admin-detailed-wanted-device-breadcrumb-container-parent">
                <div className="admin-detailed-wanted-device-breadcrumb">
                  <div className="admin-detailed-wanted-device-breadcrumb-items">
                    <div className="admin-detailed-wanted-device-breadcrumb-button-base2">
                      <div className="admin-detailed-wanted-device-text9">
                        Dashboard
                      </div>
                    </div>
                    <div className="admin-detailed-wanted-device-chevron-container">
                      <img
                        className="admin-detailed-wanted-device-chevron-right-icon2"
                        alt=""
                        src={chevronright}
                      />
                    </div>
                    <div className="admin-detailed-wanted-device-breadcrumb-button-base3">
                      <div className="admin-detailed-wanted-device-text10">
                        Wanted Device
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="admin-detailed-wanted-device-listing-content-wrapper">
                <div className="admin-detailed-wanted-device-listing-content">
                  <div className="admin-detailed-wanted-device-description-block-parent">
                    <div className="admin-detailed-wanted-device-description-block">
                      <div className="admin-detailed-wanted-device-description-heading">
                        {/* <span className="admin-detailed-wanted-device-pre-">Pre-</span>
                    <span className="admin-detailed-wanted-device-o">o</span>
                    <span className="admin-detailed-wanted-device-pre-">wned, </span>
                    <span className="admin-detailed-wanted-device-o"
                    >USED, FULLY REFURBISHED 1984 gARAVENTA ACCESSIBILITY
                      WRISTVIEW STREPTOCOCCUS A HYALURONIDASE ANTIBODY
                      DETECTION, IDENTIFICATION REAGENTS FOR RENT / LEASE</span
                    > */}
                        {wantedDeviceData.data.title}
                      </div>
                      <div className="admin-detailed-wanted-device-listing-details">
                        <div className="admin-detailed-wanted-device-detail-container">
                          <div className="admin-detailed-wanted-device-details-content">
                            <div className="admin-detailed-wanted-device-details-row">
                              <div className="admin-detailed-wanted-device-detail-items">
                                <img
                                  className="admin-detailed-wanted-device-marker-pin-02-icon"
                                  alt=""
                                  src={markerpin02}
                                />

                                <div className="admin-detailed-wanted-device-supporting-text">
                                  {
                                    wantedDeviceData.data.wanted_device_user
                                      .user_usermeta.tax_country.name
                                  }
                                  ,{" "}
                                  {
                                    wantedDeviceData.data.wanted_device_user
                                      .user_usermeta.userTown
                                  }
                                </div>
                              </div>
                              <div className="admin-detailed-wanted-device-detail-items">
                                <img
                                  className="admin-detailed-wanted-device-marker-pin-02-icon"
                                  alt=""
                                  src={calendar}
                                />

                                <div className="admin-detailed-wanted-device-supporting-text">
                                  {getCustomizedDate(
                                    wantedDeviceData.data.createdAt
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="admin-detailed-wanted-device-contact-details">
                              <div className="admin-detailed-wanted-device-contact-name-wrapper">
                                <div className="admin-detailed-wanted-device-contact-name">
                                  4.9/5
                                </div>
                              </div>
                              <div className="admin-detailed-wanted-device-contact-role">
                                <div className="admin-detailed-wanted-device-supporting-text2">
                                  99,999 reviews
                                </div>
                              </div>
                              {/* <div className="admin-detailed-wanted-device-social-links">
                                <div className="admin-detailed-wanted-device-rectangle-container">
                                  <div className="admin-detailed-wanted-device-frame-inner"></div>
                                  <div className="admin-detailed-wanted-device-icon-backgrounds-parent">
                                    <div className="admin-detailed-wanted-device-icon-backgrounds"></div>
                                    <img
                                      className="admin-detailed-wanted-device-whatsapp-1-icon"
                                      alt=""
                                      src={facebook_1_1_2x}
                                    />
                                  </div>
                                  <div className="admin-detailed-wanted-device-icon-backgrounds-parent">
                                    <div className="admin-detailed-wanted-device-ellipse-div"></div>
                                    <img
                                      className="admin-detailed-wanted-device-whatsapp-1-icon"
                                      alt=""
                                      src={instagram_1_1_2x}
                                    />
                                  </div>
                                  <div className="admin-detailed-wanted-device-icon-backgrounds-parent">
                                    <div className="admin-detailed-wanted-device-frame-child1"></div>
                                    <img
                                      className="admin-detailed-wanted-device-whatsapp-1-icon"
                                      alt=""
                                      src={whatsapp_1_2x}
                                    />
                                  </div>
                                  <div className="admin-detailed-wanted-device-icon-backgrounds-parent">
                                    <div className="admin-detailed-wanted-device-icon-backgrounds"></div>
                                    <img
                                      className="admin-detailed-wanted-device-whatsapp-1-icon"
                                      alt=""
                                      src={twitter_1_2x}
                                    />
                                  </div>
                                  <div className="admin-detailed-wanted-device-icon-backgrounds-parent">
                                    <div className="admin-detailed-wanted-device-ellipse-div"></div>
                                    <img
                                      className="admin-detailed-wanted-device-whatsapp-1-icon"
                                      alt=""
                                      src={linkedinlogo_1_2x}
                                    />
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          </div>
                          <div className="admin-detailed-wanted-device-button-row">
                            {/* {!wantedDeviceData.data.isAuthor &&
                              (wantedDeviceData.data.addedToFunnel ? (
                                <div
                                  className="admin-detailed-wanted-device-buttonsbutton1"
                                  onClick={() =>
                                    handleRemoveFunnel(wantedDeviceData.data.id)
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    className="admin-detailed-wanted-device-magnifier-1-icon"
                                    alt=""
                                    src={target_04}
                                  />

                                  <div className="admin-detailed-wanted-device-text-padding">
                                    <div className="admin-detailed-wanted-device-text11">
                                      Added To Funnel
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="admin-detailed-wanted-device-buttonsbutton1"
                                  onClick={() =>
                                    handleAddFunnel(wantedDeviceData.data.id)
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    className="admin-detailed-wanted-device-magnifier-1-icon"
                                    alt=""
                                    src={funnel}
                                  />

                                  <div className="admin-detailed-wanted-device-text-padding">
                                    <div className="admin-detailed-wanted-device-text11">
                                      Add To Funnel
                                    </div>
                                  </div>
                                </div>
                              ))} */}

                            {/* <div className="admin-detailed-wanted-device-buttonsbutton2">
                              <img
                                className="admin-detailed-wanted-device-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />

                              <img
                                className="admin-detailed-wanted-device-magnifier-1-icon"
                                alt=""
                                src={deal_2_1_2x}
                              />

                              <img
                                className="admin-detailed-wanted-device-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />
                            </div>
                            <div className="admin-detailed-wanted-device-buttonsbutton2">
                              <img
                                className="admin-detailed-wanted-device-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />

                              <img
                                className="admin-detailed-wanted-device-magnifier-1-icon"
                                alt=""
                                src={magnifier_1_2x}
                              />

                              <img
                                className="admin-detailed-wanted-device-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />
                            </div> */}
                            {/* <div className="admin-detailed-wanted-device-buttonsbutton2">
                              <img
                                className="admin-detailed-wanted-device-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />

                              <img
                                className="admin-detailed-wanted-device-magnifier-1-icon"
                                alt=""
                                src={share_1_2x}
                              />

                              <img
                                className="admin-detailed-wanted-device-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />
                            </div>
                            <img
                              className="admin-detailed-wanted-device-button-row-child"
                              alt=""
                              src={group_1010}
                            /> */}

                            <div className="admin-detailed-wanted-device-sharethis-parent">
                              <div
                                className="admin-detailed-wanted-device-buttonsbutton2"
                                onClick={handleShareButtonClick}
                                style={{ cursor: "pointer" }}
                              >
                                <img
                                  className="admin-detailed-wanted-device-placeholder-icon4"
                                  alt=""
                                  src={placeholder1}
                                />

                                <img
                                  className="admin-detailed-wanted-device-magnifier-1-icon"
                                  alt=""
                                  src={share_1_2x}
                                />

                                <img
                                  className="admin-detailed-wanted-device-placeholder-icon4"
                                  alt=""
                                  src={placeholder1}
                                />
                              </div>

                              {activeSection === "share" && (
                                <div className="admin-detailed-wanted-device-sharethis">
                                  <InlineShareButtons
                                    config={{
                                      alignment: "center",
                                      color: "black",
                                      enabled: true,
                                      font_size: 14,
                                      labels: "cta",
                                      language: "en",
                                      networks: [
                                        "twitter",
                                        "whatsapp",
                                        "linkedin",
                                        "email",
                                        "facebook",
                                        "flipboard",
                                        "google",
                                        "digg",
                                        "livejournal",
                                        "mailru",
                                        "meneame",
                                        "messenger",
                                        "oknoklassniki",
                                        "pinterest",
                                        "print",
                                        "reddit",
                                        "sharethis",
                                        "sms",
                                        "stumbleupon",
                                        "tumblr",
                                        "blogger",
                                        "vk",
                                        "wechat",
                                        "weibo",
                                        "delicious",
                                        "xing",
                                      ],
                                      padding: 12,
                                      radius: 4,
                                      show_total: false,
                                      size: 34,
                                      url: "https://gerator.io/", // Use the current page URL dynamically
                                      image: "https://bit.ly/2CMhCMC",
                                      description:
                                        "Check out this amazing content!",
                                      title: "Custom Share Title",
                                    }}
                                  />
                                </div>
                              )}
                            </div>

                            {/* <img
                                                          className="admin-detailed-wanted-device-button-row-child"
                                                          alt=""
                                                          src={group_1010}
                                                        /> */}

                            <div className="admin-detailed-wanted-device-sharethis-follow-parent">
                              <div
                                className="admin-detailed-wanted-device-buttonsbutton2"
                                onClick={handleFollowButtonClick}
                                style={{ cursor: "pointer" }}
                              >
                                <img
                                  className="admin-detailed-wanted-device-placeholder-icon4"
                                  alt=""
                                  src={placeholder1}
                                />
                                <img
                                  className="admin-detailed-wanted-device-magnifier-1-icon"
                                  alt=""
                                  src={follow}
                                />
                                <img
                                  className="admin-detailed-wanted-device-placeholder-icon4"
                                  alt=""
                                  src={placeholder1}
                                />
                                Follow us
                              </div>

                              {activeSection === "follow" && (
                                <div className="admin-detailed-wanted-device-sharethis-follow">
                                  <InlineFollowButtons
                                    config={{
                                      action: "Follow us:", // call to action (STRING)
                                      action_enable: false,
                                      action_pos: "bottom", // position of call to action (left, top, right)
                                      alignment: "center", // alignment of buttons (left, center, right)
                                      color: "white", // set the color of buttons (social, white)
                                      enabled: true, // show/hide buttons (true, false)
                                      networks: [
                                        "linkedin", // which networks to include (see FOLLOW NETWORKS)
                                        "twitter",
                                        "facebook",
                                        "instagram",
                                      ],
                                      padding: 8, // padding within buttons (INTEGER)
                                      profiles: {
                                        // social profile links for buttons
                                        twitter: "ger8or",
                                        facebook: "Ger8orMarketplace ",
                                        instagram: "ger8or",
                                        linkedin: "company/gerator",
                                      },
                                      radius: 9, // the corner radius on each button (INTEGER)
                                      size: 32, // the size of each button (INTEGER)
                                      spacing: 8, // the spacing between buttons (INTEGER)
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* <div
                          className="admin-detailed-wanted-device-dropdown-container"
                          style={{ cursor: "pointer" }}
                          onClick={toggleThreeDotsDropdown}
                          ref={threeDotsRef}
                        >
                          <div className="admin-detailed-wanted-device-dropdown">
                            <img
                              className="admin-detailed-wanted-device-maximize-01-icon"
                              alt=""
                              src={dotsvertical}
                            />
                          </div>
                          {isThreeDotOpen && <ThreeDotsDetailPageComponent />}
                        </div> */}
                      </div>
                    </div>
                    <img
                      className="admin-detailed-wanted-device-divider-icon"
                      alt=""
                      src={divider1}
                    />

                    <div className="admin-detailed-wanted-device-heading-parent">
                      <div className="admin-detailed-wanted-device-heading">
                        Highlights
                      </div>
                      <div className="admin-detailed-wanted-device-description-content">
                        <div className="admin-detailed-wanted-device-description-columns-first">
                          <div className="admin-detailed-wanted-device-first-columns-paragraphs">
                            <div className="admin-detailed-wanted-device-paragraph">
                              {wantedDeviceData.data.tax_device_category.name}
                            </div>
                            <div className="admin-detailed-wanted-device-paragraph1">
                              Device Category
                            </div>
                          </div>
                          <div className="admin-detailed-wanted-device-first-columns-paragraphs">
                            <div className="admin-detailed-wanted-device-paragraph">
                              {wantedDeviceData.data.tax_status_condition.name}
                            </div>
                            <div className="admin-detailed-wanted-device-paragraph1">
                              Status
                            </div>
                          </div>
                          <div className="admin-detailed-wanted-device-first-columns-paragraphs">
                            <div className="admin-detailed-wanted-device-paragraph">
                              {
                                wantedDeviceData.data.tax_clinical_application
                                  .name
                              }
                            </div>
                            <div className="admin-detailed-wanted-device-paragraph1">
                              Clinical Applications
                            </div>
                          </div>
                        </div>
                        <div className="admin-detailed-wanted-device-description-columns-second">
                          <div className="admin-detailed-wanted-device-second-columns-paragraphs">
                            <div className="admin-detailed-wanted-device-paragraph">
                              {wantedDeviceData.data.tax_oem.name}
                            </div>
                            <div className="admin-detailed-wanted-device-paragraph1">
                              OEM Brand
                            </div>
                          </div>
                          <div className="admin-detailed-wanted-device-second-columns-paragraphs">
                            <div className="admin-detailed-wanted-device-paragraph">
                              {wantedDeviceData.data.tax_age_of_device.name}
                            </div>
                            <div className="admin-detailed-wanted-device-paragraph1">
                              Age of the Device
                            </div>
                          </div>
                          <div className="admin-detailed-wanted-device-second-columns-paragraphs">
                            <div className="admin-detailed-wanted-device-paragraph">
                              {wantedDeviceData.data.tax_purpose_use.name}
                            </div>
                            <div className="admin-detailed-wanted-device-paragraph1">
                              Device Use
                            </div>
                          </div>
                          <div className="admin-detailed-wanted-device-second-columns-paragraphs">
                            <div className="admin-detailed-wanted-device-paragraph">
                              {wantedDeviceData.data.modelName}
                            </div>
                            <div className="admin-detailed-wanted-device-paragraph1">
                              Model Name
                            </div>
                          </div>
                          <div className="admin-detailed-wanted-device-second-columns-paragraphs">
                            <div className="admin-detailed-wanted-device-paragraph">
                              {wantedDeviceData.data.tax_availability.name}
                            </div>
                            <div className="admin-detailed-wanted-device-paragraph1">
                              Availability
                            </div>
                          </div>
                          <div className="admin-detailed-wanted-device-second-columns-paragraphs">
                            <div className="admin-detailed-wanted-device-paragraph">
                              {wantedDeviceData.data.tax_physical_location.name}
                            </div>
                            <div className="admin-detailed-wanted-device-paragraph1">
                              Physical Location
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="admin-detailed-wanted-device-service-card">
                    <div className="admin-detailed-wanted-device-card-content">
                      <div className="admin-detailed-wanted-device-card-content-child"></div>
                      {/* <div className="admin-detailed-wanted-device-card-header">
                        <div className="admin-detailed-wanted-device-price-tag">
                          <div className="admin-detailed-wanted-device-badge-wrapper">
                            <div className="admin-detailed-wanted-device-badge3">
                              <img
                                className="admin-detailed-wanted-device-dot-icon3"
                                alt=""
                                src={dot1}
                              />

                              <div className="admin-detailed-wanted-device-text29">Sale</div>
                            </div>
                          </div>
                          <div className="admin-detailed-wanted-device-price-parent">
                            <div className="admin-detailed-wanted-device-price">$9,999,999.99</div>
                            <div className="admin-detailed-wanted-device-original-price">
                              <div className="admin-detailed-wanted-device-discount">USD - Per Unit</div>
                            </div>
                          </div>
                          <div className="admin-detailed-wanted-device-service-title-container">
                            <div className="admin-detailed-wanted-device-service-title">Posted By Manufacturer</div>
                          </div>
                        </div>
                      </div> */}
                      <div className="admin-detailed-wanted-device-card-footer">
                        <img
                          className="admin-detailed-wanted-device-card-footer-child"
                          alt=""
                          src={wantedDeviceData.data.profileImage}
                        />

                        {/* <div className="admin-detailed-wanted-device-footer-divider">
                          <img
                            className="admin-detailed-wanted-device-divider-icon4"
                            alt=""
                            src={divider2}
                          />
                        </div> */}
                      </div>
                      <div className="admin-detailed-wanted-device-professional-info">
                        <div className="admin-detailed-wanted-device-professional-details">
                          <div className="admin-detailed-wanted-device-professional-actions">
                            <div className="admin-detailed-wanted-device-benefits-container">
                              <div className="admin-detailed-wanted-device-amlie-laurent">
                                {
                                  wantedDeviceData.data.wanted_device_user
                                    .displayName
                                }
                              </div>
                              {wantedDeviceData.data.wanted_device_user
                                ?.user_usermeta?.userBusinessName && (
                                  <div className="admin-detailed-wanted-device-role">
                                    {
                                      wantedDeviceData.data.wanted_device_user
                                        .user_usermeta.userBusinessName
                                    }
                                  </div>
                                )}
                            </div>
                            {/* <div className="admin-detailed-wanted-device-action-buttons">
                              <div className="admin-detailed-wanted-device-contact-button">
                                <div className="admin-detailed-wanted-device-message-button">
                                  <div className="admin-detailed-wanted-device-navigation">
                                    <img
                                      className="admin-detailed-wanted-device-bookmark-check-icon"
                                      alt=""
                                      src={bookmarkcheck}
                                    />
                                  </div>
                                  <div className="admin-detailed-wanted-device-supporting-text10">
                                    Verified by Gerätor
                                  </div>
                                </div>
                              </div>
                              <div className="admin-detailed-wanted-device-supporting-text11">
                                Adani Health conforms to Gerätor's requirements
                                for verification
                              </div>
                            </div> */}
                            <div className="admin-detailed-wanted-device-favorite-button">
                              <div className="admin-detailed-wanted-device-button-container2">
                                {/* {!wantedDeviceData.data.isAuthor && (
                                  <div
                                    className="detailed-device-buttonsbutton5"
                                    onClick={handlePrivateMessage}
                                  >
                                    <img
                                      className="detailed-device-filter-lines-icon"
                                      alt=""
                                      src={messagechatcircle}
                                    />
                                    <div className="detailed-device-text30">
                                      Private Message
                                    </div>
                                  </div>
                                )} */}
                                {/* Add To Contact */}
                                {/* {!wantedDeviceData.data.isAuthor &&
                                  (wantedDeviceData.data.addedToContact ? (
                                    <div
                                      className="admin-detailed-wanted-device-buttonsbutton6"
                                      onClick={() =>
                                        handleRemoveContact(
                                          wantedDeviceData.data
                                            .wanted_device_user.id
                                        )
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img
                                        className="admin-detailed-wanted-device-search-lg-icon"
                                        alt=""
                                        src={usercheck02}
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      className="admin-detailed-wanted-device-buttonsbutton6-add"
                                      onClick={() =>
                                        handleAddContact(
                                          wantedDeviceData.data
                                            .wanted_device_user.id
                                        )
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img
                                        className="admin-detailed-wanted-device-search-lg-icon"
                                        alt=""
                                        src={add_contact}
                                      />
                                    </div>
                                  ))} */}
                              </div>
                            </div>
                            {!wantedDeviceData.data.totalActiveEngagement &&
                              <div className="admin-detailed-device-rectangle-parent4">

                                <div className="admin-detailed-device-testimonial-header">
                                  <div className="admin-detailed-device-header-content1">
                                    {(wantedDeviceData.data.tax_post_status.id === 2 || wantedDeviceData.data.tax_post_status.id === 3) &&
                                      <button className="admin-detailed-page-button11" type="button" onClick={handleSetPublish} >
                                        <div className="admin-detailed-page-text-padding8">
                                          <div className="admin-detailed-page-text76">Publish</div>
                                        </div>

                                      </button>
                                    }

                                    {wantedDeviceData.data.tax_post_status.id === 1 &&
                                      <button className="admin-detailed-page-button12" type="button" onClick={handleSetArchive} >


                                        <div className="admin-detailed-page-text-padding8">
                                          <div className="admin-detailed-page-text76-a">Archive</div>
                                        </div>

                                      </button>
                                    }
                                    {/* <div className="admin-detailed-device-heading23">Jobs</div>
                                    <div className="admin-detailed-device-testimonial-description">
                                   <div className="admin-detailed-device-description">View All</div>
                                    </div> */}
                                  </div>
                                </div>

                              </div>
                            }
                          </div>
                          {/* <div
                            className="admin-detailed-wanted-device-dropdown-menu"
                            style={{ cursor: "pointer" }}
                            onClick={toggleThreeDotsDropdownUser}
                            ref={threeDotsRefUser}
                          >
                            <div className="admin-detailed-wanted-device-dropdown1">
                              <img
                                className="admin-detailed-wanted-device-maximize-01-icon"
                                alt=""
                                src={dotsvertical}
                              />
                            </div>
                            {isThreeDotUserOpen && (
                              <ThreeDotsDetailPageUserComponent />
                            )}
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <FooterDetailPage />
          </div>
        </div>
      </>
    );
  } else {
    return <Loader />;
  }
};
