
import { Beacon } from "../screens/Beacon/Beacon";
import { BeaconWantedDeviceDetailPage } from "../screens/BeaconWantedDetailPage/BeaconWantedDeviceDetailPage/BeaconWantedDeviceDetailPage";
import { BeaconWantedServiceDetailPage } from "../screens/BeaconWantedDetailPage/BeaconWantedServiceDetailPage/BeaconWantedServiceDetailPage";
import { BeaconWantedSoftwareDetailPage } from "../screens/BeaconWantedDetailPage/BeaconWantedSoftwareDetailPage/BeaconWantedSoftwareDetailPage";
import { BeaconWantedSpareDetailPage } from "../screens/BeaconWantedDetailPage/BeaconWantedSpareDetailPage/BeaconWantedSpareDetailPage";




export const beaconRoutes = [

  {
    path: "/beacon",
    element: <Beacon />
  },
  {
    path: "/beacon/wanted-device/:id/*",
    element: <BeaconWantedDeviceDetailPage />
  },
  {
    path: "/beacon/wanted-spare/:id/*",
    element: <BeaconWantedSpareDetailPage />
  },
  {
    path: "/beacon/wanted-service/:id/*",
    element: <BeaconWantedServiceDetailPage />
  },
  {
    path: "/beacon/wanted-software/:id/*",
    element: <BeaconWantedSoftwareDetailPage />
  },

];
