// import React, { useEffect, useState, useRef } from "react";
// import "../ChatPageComponent.css"; // Correct relative path
//
// const MessageBody = ({ messages, currentUser ,deviceData}) => {
//   const [currentTime, setCurrentTime] = useState(Date.now());
//   const messagesEndRef = useRef(null); // Create a ref for the end of the message list
//
//   // Periodically update `currentTime` every minute
//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentTime(Date.now()); // Update currentTime every 1 minute
//     }, 60000); // 60 seconds
//     return () => clearInterval(interval); // Cleanup interval on unmount
//   }, []);
//
//   // Auto-scroll to the bottom when messages update
//   useEffect(() => {
//     if (messagesEndRef.current) {
//       messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
//     }
//   }, [messages]); // Triggered when `messages` changes
//
//   // Helper function to format the timestamp into HH:mm
//   const formatTime = (timestamp) => {
//     const date = new Date(timestamp);
//     const hours = date.getHours();
//     const minutes = date.getMinutes();
//     return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
//   };
//
//   // Helper function to get display time (Just now or formatted time)
//   const getDisplayTime = (timestamp) => {
//     const elapsedTime = currentTime - timestamp;
//
//     // Show "Just now" for the first 60 seconds
//     if (elapsedTime < 60000) {
//       return "Just now";
//     }
//
//     // Otherwise, show formatted time
//     return formatTime(timestamp);
//   };
//
//
//   console.log("PRINTING RECEIVER IN MESSAGEBOX: ", deviceData);
//
//   return (
//     <div className="chatB-container1">
//       <div className="chatB-content-divider1">
//         <div className="chatB-divider-wrapper">
//           <div className="chatB-divider" />
//         </div>
//         <div className="chatB-divider-label">Messages</div>
//         <div className="chatB-divider-wrapper">
//           <div className="chatB-divider" />
//         </div>
//       </div>
//       <div className="chatB-messages-parent">
//         <div className="chatB-messages">
//           {messages.map((message, index) => (
//             <div
//               key={index}
//               className={`${
//                 message.sender === currentUserId
//                   ? "chatB-row"
//                   : "chatB-message12"
//               }`}
//             >
//               {/* Avatar and Online Indicator for Received Messages */}
//               {message.sender !== currentUserId && (
//                  <img
//                  className="chatB-avatar2"
//                  src={listingData?.profileImage|| avatar2}
//                  alt="Avatar"
//               />
//                )}
//               {message.sender === currentUserId ? (
//                 /* Wrap in message10 only for current user's messages */
//                 <div className="chatB-content13">
//                   <div className="chatB-name-and-time3">
//                     <div className="chatB-single-message-username">You</div>
//                     {/* <div className="chatB-sub-text3">
//                       {getDisplayTime(message.timestamp)}
//                     </div> */}
//                   </div>
//                   <div className="chatB-input5">
//                     <div className="chatB-text31">{message.content}</div>
//                     <div className="chatB-sub-text3">
//                       {getDisplayTime(message.timestamp)}
//                     </div>
//                   </div>
//                 </div>
//               ) : (
//                 /* No wrapping for received messages */
//                 <div className="chatB-content8">
//                   <div className="chatB-name-and-time">
//                     <div className="chatB-usernames">{listingData?.device_business?.business_user?.displayName }</div>
//                     {/* <div className="chatB-sub-text">
//                       {getDisplayTime(message.timestamp)}
//                     </div> */}
//                   </div>
//                   <div className="chatB-input2">
//                     <div className="chatB-receiever">{message.content}</div>
//                     <div className="chatB-sub-text">
//                       {getDisplayTime(message.timestamp)}
//                     </div>
//                   </div>
//                 </div>
//               )}
//               {/* Empty Space for Sent Messages */}
//               {message.sender === currentUserId && (
//                 <div className="chatB-empty-space" />
//               )}
//             </div>
//           ))}
//           {/* Invisible div to act as a scroll anchor */}
//           <div ref={messagesEndRef} />
//         </div>
//       </div>
//     </div>
//   );
//
//   // return (
//   //   <div className="chatB-container1">
//   //     <div className="chatB-content-divider1">
//   //       <div className="chatB-divider-wrapper">
//   //         <div className="chatB-divider" />
//   //       </div>
//   //       <div className="chatB-divider-label">Messages</div>
//   //       <div className="chatB-divider-wrapper">
//   //         <div className="chatB-divider" />
//   //       </div>
//   //     </div>
//   //     <div className="chatB-messages-parent">
//   //       <div className="chatB-messages">z
//   //         {messages.map((message, index) => (
//   //           <div
//   //             key={index}
//   //             className={`${
//   //               message.sender === currentUserId
//   //                 ? "chatB-row"
//   //                 : "chatB-message12"
//   //             }`}
//   //           >
//   //             {/* Avatar and Online Indicator for Received Messages */}
//   //             {message.sender !== currentUserId && (
//   //               <img
//   //               className="chatB-avatar2"
//   //               src={receiver.profileImage|| avatar2}
//   //               alt="Avatar"
//   //             />
//   //             )}
//   //             {message.sender === currentUserId ? (
//   //               /* Wrap in message10 only for current user's messages */
//   //               <div className="chatB-content13">
//   //                 <div className="chatB-name-and-time3">
//   //                   <div className="chatB-single-message-username">You</div>
//   //                   <div className="chatB-sub-text3">
//   //                     {getDisplayTime(message.timestamp)}
//   //                   </div>
//   //                 </div>
//   //                 <div className="chatB-input5">
//   //                   <div className="chatB-text31">{message.content}</div>
//   //                 </div>
//   //               </div>
//   //             ) : (
//   //               /* No wrapping for received messages */
//   //               <div className="chatB-content8">
//   //                 <div className="chatB-name-and-time">
//   //                   <div className="chatB-usernames">{receiver}</div>
//   //                   <div className="chatB-sub-text">
//   //                     {getDisplayTime(message.timestamp)}
//   //                   </div>
//   //                 </div>
//   //                 <div className="chatB-input2">
//   //                   <div className="chatB-receiever">{message.content}</div>
//   //                 </div>
//   //               </div>
//   //             )}
//   //             {/* Empty Space for Sent Messages */}
//   //             {message.sender === currentUserId && (
//   //               <div className="chatB-empty-space" />
//   //             )}
//   //           </div>
//   //         ))}
//   //         {/* Invisible div to act as a scroll anchor */}
//   //         <div ref={messagesEndRef} />
//   //       </div>
//   //     </div>
//   //   </div>
//   // );
// };
//
//
// export { MessageBody };

import React, { useEffect, useState, useRef } from "react";
import "../ChatPageComponent.css"; // Correct relative path
import chat_day_divider from "../../../icons/Gerator/chat_page/chat_day_divider.svg";

const MessageBody = ({ messages, currentUserId, listingData }) => {
  const [currentTime, setCurrentTime] = useState(Date.now());
  // const messagesEndRef = useRef(null); // Create a ref for the end of the message list

  const lastMessageRef = useRef(null);
  const [latestMessageTime, setLatestMessageTime] = useState(null);

  // Track the latest message timestamp before updating messages
  useEffect(() => {
    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1];

      // Check if the last message is truly new (based on time)
      if (!latestMessageTime || new Date(lastMessage.timestamp) > new Date(latestMessageTime)) {
        setLatestMessageTime(lastMessage.timestamp); // Update to new latest timestamp

        // Scroll only for new messages, not when older messages are loaded
        if (lastMessageRef.current) {
          lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  }, [messages]);

  // Periodically update `currentTime` every minute
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now()); // Update currentTime every 1 minute
    }, 60000); // 60 seconds
    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  // Auto-scroll to the bottom when messages update
  // useEffect(() => {
  //   if (messagesEndRef.current) {
  //     messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [messages]); // Triggered when `messages` changes

  // Helper function to format the timestamp into HH:mm
  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
  };

  // Helper function to get display time (Just now or formatted time)
  // const getDisplayTime = (timestamp) => {
  //   const elapsedTime = currentTime - timestamp;

  //   // Show "Just now" for the first 60 seconds
  //   if (elapsedTime < 60000) {
  //     return "Just now";
  //   }

  //   // Otherwise, show formatted time
  //   return formatTime(timestamp);
  // };

  // Function to determine the date header (Today, Yesterday, or a date)
  const getDateLabel = (timestamp) => {
    const messageDate = new Date(timestamp);
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today to midnight

    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1); // Normalize yesterday to midnight

    if (messageDate.getTime() >= today.getTime()) return "Today";
    if (messageDate.getTime() >= yesterday.getTime()) return "Yesterday";

    return messageDate.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  // Ensure messages are sorted by timestamp (ascending order)
  const sortedMessages = [...messages].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

  let lastDate = null; // Tracks the last date divider

  // Process messages to include dividers
  const processedMessages = [];
  sortedMessages.forEach((message) => {
    const messageDate = new Date(message.timestamp).toDateString();
    if (messageDate !== lastDate) {
      processedMessages.push({ isDivider: true, text: getDateLabel(message.timestamp) });
      lastDate = messageDate;
    }
    processedMessages.push({ ...message, isDivider: false });
  });

  return (
    <div className="chatB-container1">
      <div className="chatB-messages-parent">
        <div className="chatB-messages">
          {processedMessages.map((item, index) =>
            item.isDivider ? (
              <div key={`divider-${index}`} className="chatB-divider-container">
                {/* <div className="chatB-divider-line"></div> */}
                <img className="chatB-divider-line" src={chat_day_divider} alt="divider" />
                <div className="chatB-divider-label">{item.text}</div>
                <img className="chatB-divider-line" src={chat_day_divider} alt="divider" />
                {/* <div className="chatB-divider-line"></div> */}
              </div>
            ) : (
              <div
                ref={index === processedMessages.length - 1 ? lastMessageRef : null}

                key={item.messageId || index}
                className={`${item.sender === currentUserId ? "chatB-row" : "chatB-message12"
                  }`}
              >
                {/* Avatar for received messages */}
                {item.sender !== currentUserId && (
                  <img
                    className="chatB-avatar2"
                    src={listingData?.profileImage}
                    alt="Avatar"
                  />
                )}

                {/* Sent Messages (Current User) */}
                {item.sender === currentUserId ? (
                  <div className="chatB-content13">
                    <div className="chatB-name-and-time3">
                      <div className="chatB-single-message-username">You</div>
                    </div>
                    <div className="chatB-input5">
                      <div className="chatB-text31">{item.content}</div>
                      <div className="chatB-sub-text3">
                        {formatTime(item.timestamp)}
                      </div>
                    </div>
                  </div>
                ) : (
                  /* Received Messages */
                  <div className="chatB-content8">
                    <div className="chatB-name-and-time">
                      {/* <div className="chatB-usernames">
                        {listingData?.device_business?.business_user?.displayName || listingData?.spare_business?.business_user?.displayName || listingData?.service_business?.business_user?.displayName || listingData?.software_business?.business_user?.displayName || listingData?.job_business?.business_user?.displayName}
                      </div> */}
                    </div>
                    <div className="chatB-input2">
                      <div className="chatB-receiever">{item.content}</div>
                      <div className="chatB-sub-text">
                        {formatTime(item.timestamp)}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )
          )}
          {/* Invisible div to act as a scroll anchor */}
          {/* <div ref={messagesEndRef} /> */}
        </div>
      </div>
    </div>
  );
};

export { MessageBody };
