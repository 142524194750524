import React, { useEffect, useState, useRef } from "react";
import { Provider } from "../../screens/Service/AddService/AddServiceContext";
import { Link, useNavigate } from "react-router-dom";
import { clearForm, updateField } from "./AddServiceSlice";
import { updateAppDataService, clearAppData } from "./AppDataServiceSlice";
import { useGetClinicalApplicationsTaxQuery, useGetServiceCategoryTaxQuery, useGetPurposeUseTaxQuery, useGetServiceCategoryTaxQuery, useGetUnitOfMeasureServiceTaxQuery, useGetServiceandSupportReachTaxQuery, useGetPhysicalLocationTaxQuery, useGetDeviceCategoryTaxQuery } from "../../api/TaxonomyFormAPI";
// import { getAllTaxonomyData } from "../../helper/AddDeviceHelper";
import { AddServiceStep1 } from "./AddServiceStep1";
import { AddServiceStep2 } from "./AddServiceStep2";
import { AddServiceStep3 } from "./AddServiceStep3";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useAddServiceMutation } from "../../api/ServiceAPI";
import { objectToFormDataAddService } from "../../helper/ServiceHelper";
import { decryptErrorData } from "../../helper/AESHelper";
import { useFindGetRelatedAddressRemoteQuery } from "../../api/AddressAPI";



const renderStep = (step) => {
  switch (step) {
    case 0:
      return <AddServiceStep1 />;
    case 1:
      return <AddServiceStep2 />;
    case 2:
      return <AddServiceStep3 />;
    default:
      return null;
  }
};

const AddServiceParent = ({ currentUsermeta, setShowSuccessMessage, currentPlanData }) => {
  //Device Category helper select field
  const formikRef = useRef(null);
  const [newDeviceCategoryLength, setNewDeviceCategoryLength] = useState(50);
  const [deviceCategorySearchField, setDeviceCategorySearchField] = useState("");

  //For Validation message navidating to steps. Used only when backend validation will be trigger
  const step1FormKey = ["serviceCategory",
    "serviceValueProposition",
    "rangeMin",
    "rangeMax",
    "unitOfMeasureService",
    "clinicalApplications",
    "purposeUse",
    "deviceCategory",
    "physicalLocation",];
  const step2FormKey = ["serviceFeatureHighlights",
    "serviceBenefitsHighlights",
    "parentDevices",
    "featureImage",
    "featureImageLink",
    "bannerImage",
    "bannerImageLink",
    "videoType",
    "linkVideo",
    "location",
    "serviceAndSupportReach",]
  const step3FormKey = ["serviceFeatures",
    "serviceBenefits",
    "scopOfWork",
    "serviceLevelAgreement",
    "additionalInformation",
    "document",
    "documentLink",]

  const [addService, { isLoading, isError, error }] = useAddServiceMutation();
  const navigate = useNavigate();
  const serviceCategoryTax = useGetServiceCategoryTaxQuery();
  const unitOfMeasureServiceTax = useGetUnitOfMeasureServiceTaxQuery();
  const deviceCategoryTax = useGetDeviceCategoryTaxQuery({ id: newDeviceCategoryLength, query: deviceCategorySearchField });
  const clinicalApplicationsTax = useGetClinicalApplicationsTaxQuery();
  const purposeUseTax = useGetPurposeUseTaxQuery();
  const physicalLocationTax = useGetPhysicalLocationTaxQuery();
  const serviceAndSupportReachTax = useGetServiceandSupportReachTaxQuery();
  const relatedAddressTax = useFindGetRelatedAddressRemoteQuery();

  // const [taxonomyLoaded, setTaxonomyLoaded] = useState(false);




  const [allTaxonomy, setAllTaxonomy] = useState({ deviceCategory: deviceCategoryTax, physicalLocation: physicalLocationTax, purposeUse: purposeUseTax, clinicalApplications: clinicalApplicationsTax, unitOfMeasureService: unitOfMeasureServiceTax, serviceAndSupportReach: serviceAndSupportReachTax, serviceCategory: serviceCategoryTax, relatedAddress: relatedAddressTax })

  useEffect(() => {

    const allSuccess = [deviceCategoryTax.isSuccess, physicalLocationTax.isSuccess, purposeUseTax.isSuccess, clinicalApplicationsTax.isSuccess, unitOfMeasureServiceTax.isSuccess, serviceAndSupportReachTax.isSuccess, serviceCategoryTax.isSuccess, relatedAddressTax.isSuccess].every(Boolean);


    if (allSuccess) {
      setAllTaxonomy({
        deviceCategory: deviceCategoryTax, physicalLocation: physicalLocationTax, purposeUse: purposeUseTax, clinicalApplications: clinicalApplicationsTax, unitOfMeasureService: unitOfMeasureServiceTax, serviceAndSupportReach: serviceAndSupportReachTax, serviceCategory: serviceCategoryTax, relatedAddress: relatedAddressTax
      });
      // setTaxonomyLoaded(true);
    }
  }, [
    deviceCategoryTax.isSuccess, deviceCategoryTax.data,
    serviceCategoryTax.isSuccess, serviceCategoryTax.data,
    serviceAndSupportReachTax.isSuccess, serviceAndSupportReachTax.data,
    unitOfMeasureServiceTax.isSuccess, unitOfMeasureServiceTax.data,
    clinicalApplicationsTax.isSuccess, clinicalApplicationsTax.data,
    purposeUseTax.isSuccess, purposeUseTax.data,
    physicalLocationTax.isSuccess, physicalLocationTax.data,
    relatedAddressTax.isSuccess, relatedAddressTax.data,
  ]);

  // useEffect(() => {
  //   if(allTaxonomy.serviceCategory.status === "fulfilled")
  //     {
  //       // console.log(allTaxonomy);
  //       setTaxonomyLoaded(true)
  //     }
  // }, [allTaxonomy])


  const dispatch = useDispatch();
  const formData = useSelector((state) => state.addService);
  const appData = useSelector((state) => state.appDataAddService);

  const [backendValidation, setBackendValidation] = useState([]);
  const [selectedImageFile, setSelectedImageFile] = useState("");
  const [cropedImageFile, setCropedImageFile] = useState("");
  const [selectedBannerImageFile, setSelectedBannerImageFile] = useState('');
  const [cropedBannerImageFile, setCropedBannerImageFile] = useState("");
  const [isImageSelected, setIsImageSelected] = useState(false);

  //-----------------------Document File UseState------------------------
  const [selectedMultiDocumentFile, setSelectedMultiDocumentFile] = useState("");
  const [documentMultiCheckboxError, setDocumentMultiCheckboxError] = useState("");
  const [documentMultiUploadStatus, setDocumentMultiUploadStatus] = useState([]);
  const [isDocumentUploading, setIsDocumentUploading] = useState(false);
  const [isUploadedDocumentHasErrorShow, setisUploadedDocumentHasErrorShow] = useState(false);


  //This snippet used to reset form and applied on reset form button
  // const resetForm = () => {
  //   setSelectedImageFile("")
  //   setSelectedBannerImageFile('')
  //   setSelectedDocumentFile("")
  //   setDeviceCategorySearchField("");
  //   dispatch(clearForm())
  //   dispatch(clearAppData());
  //   formikRef.current.resetForm();
  //   // purgeStorage()
  // }

  const step1Schema = Yup.object({
    serviceCategory: Yup.string().required("This is a required field").test(
      "OPTION", "Please select a valid option",
      (value) => value !== '0'
    ),
    serviceValueProposition: Yup.string().required("This is a required field").max(70, 'Must be 70 characters or less'),

    // rangeMin: Yup.string().test('non-negative', 'Field data cannot be negative', value => value >= 0)
    // .required("This is a required field").test(
    //   "OPTION", "Please select a valid option",
    //   (value) => value !== '0'

    // ),

    rangeMin: Yup.string()
      .required("This is a required field")
      .test(
        'is-numeric',
        'Characters are not allowed, please enter numbers only',
        (value) => {
          if (!value) return true; // Allow empty value for required validation to handle

          // Remove commas and check if the remaining string is a valid number pattern
          const cleanValue = value.replace(/,/g, '');

          // Check if the value contains only valid numeric characters
          return /^-?\d*\.?\d*$/.test(cleanValue);
        }
      )
      .test(
        'decimal-places',
        'Only two decimal places are allowed',
        value => {
          if (!value) return true;
          const cleanValue = value.replace(/,/g, '');
          // First, check if it's just a decimal point
          if (cleanValue === '.') return false;
          const decimalMatch = cleanValue.match(/\.(\d+)/);
          return !decimalMatch || decimalMatch[1].length <= 2;
        }
      )
      .test(
        'non-negative',
        'Field data cannot be negative',
        value => {
          if (!value) return true;
          const numValue = Number(value.replace(/,/g, ''));
          return numValue >= 0;
        }
      )
      .test(
        'min-value',
        'Field data must be greater than or equal to 0.01',
        value => {
          if (!value) return true;
          const numValue = Number(value.replace(/,/g, ''));
          return numValue >= 0.01;
        }
      )
      .test(
        'max-value',
        'Field data must be less than or equal to 9,999,999.99',
        value => {
          if (!value) return true;
          const numValue = Number(value.replace(/,/g, ''));
          return numValue <= 9999999.99;
        }
      ),
    rangeMax: Yup.string()
      .required("This is a required field")
      .test(
        'is-numeric',
        'Characters are not allowed, please enter numbers only',
        (value) => {
          if (!value) return true; // Allow empty value for required validation to handle

          // Remove commas and check if the remaining string is a valid number pattern
          const cleanValue = value.replace(/,/g, '');

          // Check if the value contains only valid numeric characters
          return /^-?\d*\.?\d*$/.test(cleanValue);
        }
      )
      .test(
        'decimal-places',
        'Only two decimal places are allowed',
        value => {
          if (!value) return true;
          const cleanValue = value.replace(/,/g, '');
          // First, check if it's just a decimal point
          if (cleanValue === '.') return false;
          const decimalMatch = cleanValue.match(/\.(\d+)/);
          return !decimalMatch || decimalMatch[1].length <= 2;
        }
      )
      .test(
        'non-negative',
        'Field data cannot be negative',
        value => {
          if (!value) return true;
          const numValue = Number(value.replace(/,/g, ''));
          return numValue >= 0;
        }
      )
      .test(
        'min-value',
        'Field data must be greater than or equal to 0.01',
        value => {
          if (!value) return true;
          const numValue = Number(value.replace(/,/g, ''));
          return numValue >= 0.01;
        }
      )
      .test(
        'max-value',
        'Field data must be less than or equal to 9,999,999.99',
        value => {
          if (!value) return true;
          const numValue = Number(value.replace(/,/g, ''));
          return numValue <= 9999999.99;
        }
      )
      .when('rangeMin', {
        is: (rangeMin) => rangeMin != null,
        then: (schema) => schema.test({
          test: (rangeMax, context) => {
            const cleanMin = context.parent.rangeMin ? context.parent.rangeMin.replace(/,/g, '') : '0';
            const cleanMax = rangeMax ? rangeMax.replace(/,/g, '') : '0';
            return Number(cleanMax) > Number(cleanMin);
          },
          message: 'Maximum range must be greater than minimum range'
        })
      }),


    unitOfMeasureService: Yup.string().required("This is a required field").test(
      "OPTION", "Please select a valid option",
      (value) => value !== '0'

    ),
    clinicalApplications: Yup.string().required("This is a required field").test(
      "OPTION", "Please select a valid option",
      (value) => value !== '0'

    ),
    purposeUse: Yup.string().required("This is a required field").test(
      "OPTION", "Please select a valid option",
      (value) => value !== '0'

    ),
    deviceCategory: Yup.string().required("This is a required field").test(
      "OPTION", "Please select a valid option",
      (value) => value !== '0'

    ),
    physicalLocation: Yup.string().required("This is a required field").test(
      "OPTION", "Please select a valid option",
      (value) => value !== '0'

    ),

  })

  const step2Schema = Yup.object().shape({
    serviceFeatureHighlights: Yup.string().required("This is a required field").max(190, 'Must be 190 characters or less'),

    serviceBenefitsHighlights: Yup.string().required("This is a required field").max(190, 'Must be 190 characters or less'),

    parentDevices: Yup.string().max(1000, 'Must be 1000 characters or less'),

    featureImage: Yup.mixed()
      .test(
        'fileType',
        'Unsupported File Format (only JPEG, PNG, and WebP allowed)',
        value => {
          if (!value) return true
          return ['image/jpeg', 'image/png', 'image/webp'].includes(value.type)
        }
      )
      .test(
        'fileSize',
        'File too large (max size 1MB)',
        value => !value || (value && value.size <= 1024 * 1024)
      )
      .required('A file is required'),

    bannerImage: Yup.mixed()
      .test(
        'fileType',
        'Unsupported File Format (only JPEG, PNG, and WebP allowed)',
        value => {
          if (!value) return true
          return ['image/jpeg', 'image/png', 'image/webp'].includes(value.type)
        }
      )
      .test(
        'fileSize',
        'File too large (max size 1MB)',
        value => !value || (value && value.size <= 1024 * 1024)
      )
      .required('A file is required'),

    location: Yup.string().required("This is a required field").test(
      "OPTION", "Please select a valid option",
      (value) => value !== '0'
    ),
    serviceAndSupportReach: Yup.string().required("This is a required field").test(
      "OPTION", "Please select a valid option",
      (value) => value !== '0'
    ),
  }
  )

  const step3Schema = Yup.object().shape({

    serviceFeatures: Yup.string().max(1000, 'Must be 1000 characters or less'),

    serviceBenefits: Yup.string().max(1000, 'Must be 1000 characters or less'),

    scopeOfWork: Yup.string().max(1000, 'Must be 1000 characters or less'),

    serviceLevelAgreement: Yup.string().max(1000, 'Must be 1000 characters or less'),

    additionalInformation: Yup.string().max(2000, 'Must be 2000 characters or less'),

    document: Yup.array()
      .of(
        Yup.mixed()
          .test(
            'fileType',
            'Unsupported File Format (only PDF allowed)',
            value => {
              if (!value) return true
              // if (isValidURL(value)) return true;
              return ['application/pdf'].includes(value.file.type)
            }
          )
          .test(
            'fileSize',
            'File too large (max size 1MB)',
            value => {
              // if (isValidURL(value)) return true;
              return !value.file || (value.file && value.file.size <= 1024 * 1024)
            }
          )
      ),
  })

  const matchErrorsAgainstBackendValidationList = (backendValidationError) => {
    if (backendValidationError?.length > 0) {

      // setBackendValidation(backendValidationError);


      for (const field of backendValidationError) {
        if (step1FormKey.includes(field.path)) {
          dispatch(updateAppDataService({ case: "CURRENTSTEP", value: 0 }));
          console.log("step1 was true");
          break;
        } else if (step2FormKey.includes(field.path)) {
          console.log("step2 was true");
          dispatch(updateAppDataService({ case: "CURRENTSTEP", value: 1 }));
          break;
        } else if (step3FormKey.includes(field.path)) {
          console.log("step3 was true");
          dispatch(updateAppDataService({ case: "CURRENTSTEP", value: 2 }));
        }
      }
    }
  }

  const finalSubmit = async (multiPartData, setSubmitting) => {
    try {

      const response = await addService(multiPartData).unwrap();

      // console.log("Service added successfully!", response);
      //If form was submitted successfully then isCreated return with true, then reseting form
      if (response.status) {
        setShowSuccessMessage({ message: "Service added successfully", color: "green" })
        await new Promise(resolve => setTimeout(resolve, 1500));
        // Reseting form
        // resetForm();
        // window.location.reload();
        dispatch(clearForm());
        dispatch(clearAppData());
        formikRef.current.resetForm();
        navigate('/dashboard');
      }
      // if(!response.isCreated){
      //   setShowSuccessMessage(response.message)
      // }
    } catch (error) {
      if (error.status === 422) {
        console.log(error);
        const decryptedData = decryptErrorData(error);
        console.log(decryptedData);
        setBackendValidation(decryptedData.errors);
        matchErrorsAgainstBackendValidationList(decryptedData.errors)
        // setShowSuccessMessage({ message: decryptedData.message, color: "red" });
      }
      else {
        console.log(error);
        setShowSuccessMessage({ message: "Internal server error", color: "red" });
      }
      setSubmitting(false);
    }
  };



  return (
    <Formik
      innerRef={formikRef}
      initialValues={formData}
      // validateOnChange={false}
      // validateOnBlur={false}
      validationSchema={appData.currentStep === 0 ? step1Schema : appData.currentStep === 1 ? step2Schema : appData.currentStep === 2 ? step3Schema : Yup.object().shape({})}
      onSubmit={(values, { setSubmitting }) => {

        const statusExists = documentMultiUploadStatus.some(status => status.isError === true);

        if (statusExists && !isDocumentUploading) {
          setisUploadedDocumentHasErrorShow(true);
          dispatch(updateAppDataService({ case: "CURRENTSTEP", value: 2 }));
          setSubmitting(false);
          return;
        }

        if (appData.isYoutubeChecked) dispatch(updateField({ field: "videoType", value: "Youtube" }));
        if (appData.isVimeoChecked) dispatch(updateField({ field: "videoType", value: "Vimeo" }));
        // Getting FormData object to send multipart request
        multiPartData = objectToFormDataAddService(values, cropedImageFile, cropedBannerImageFile, documentMultiUploadStatus, currentUsermeta)
        //Final submission of form
        setBackendValidation([]);
        finalSubmit(multiPartData, setSubmitting);
        // console.log(values);
        // console.log(multiPartData);
        // for (const [key, value] of multiPartData.entries()) {
        //   console.log(key, value);
        // }
        // setSubmitting(false);

      }}
    >
      {(formik) => (
        <Form className="add-service-step-section-header-parent">

          <Provider value={{ isDocumentUploading, setIsDocumentUploading, documentMultiUploadStatus, setDocumentMultiUploadStatus, currentPlanData, isUploadedDocumentHasErrorShow, setisUploadedDocumentHasErrorShow, deviceCategorySearchField, setDeviceCategorySearchField, newDeviceCategoryLength, setNewDeviceCategoryLength, backendValidation, setBackendValidation, documentMultiCheckboxError, setDocumentMultiCheckboxError, isImageSelected, setIsImageSelected, allTaxonomy, cropedImageFile, setCropedImageFile, cropedBannerImageFile, setCropedBannerImageFile, selectedBannerImageFile, setSelectedBannerImageFile, selectedMultiDocumentFile, setSelectedMultiDocumentFile, selectedImageFile, setSelectedImageFile }}>

            {renderStep(appData.currentStep)}
          </Provider>
        </Form>
      )}
    </Formik>

  );
};

export default AddServiceParent;