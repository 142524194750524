import React, { useState,useRef } from 'react';
import { createPortal } from "react-dom";

// const Tooltip = ({ text }) => (
//     <div
//       className="SupportingTextFalseArrowLeft"
//       style={{
//         width: 98,
//         height: 14,
//         boxShadow: '0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
//         justifyContent: 'center',
//         alignItems: 'center',
//         display: 'inline-flex',
//         position: 'absolute',
//         left: '40px',  
//         top: '50%',    
//         transform: 'translateY(-50%)',  
//          zIndex:2,
//       }}
//     >
//       <div
//         className="Tooltip"
//         style={{
//           height: 6,
//           transform: 'rotate(90deg)',
//           transformOrigin: '0 0',
//           justifyContent: 'center',
//           alignItems: 'center',
//           display: 'flex',
//           position: 'absolute',
//           left: '9px',
//           top:"4px", // Adjust arrow position to align with tooltip box
//         }}
//       >
//         <div
//           className="TooltipShape"
//           style={{
//             width: 8,
//             height: 8,
//             transform: 'rotate(45deg)',
//             background: '#0C111D',
//             borderRadius: 1,
//           }}
//         ></div>
//       </div>
//       <div
//         className="Content"
//         style={{
//           paddingLeft: 12,
//           paddingRight: 12,
//           paddingTop: 8,
//           paddingBottom: 8,
//           background: '#0C111D',
//           borderRadius: 8,
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//         }}
//       >
//         <div
//           className="Text"
//           style={{
//             color: '#ffffff', // White color for visibility
//             fontSize: 12,
//             fontFamily: 'Inter, sans-serif',
//             fontWeight: 600,
//             lineHeight: '18px',
//             textAlign: 'center',
//           }}
//         >
//           {text}
//         </div>
//       </div>
//     </div>
//   );
  
const Tooltip = ({ text,position }) => {
  
  if (!position) return null; 
  return createPortal (
  <div
    className="SupportingTextFalseArrowLeft"
    style={{
      width: 100,  // Adjusted width for consistent layout
      boxShadow: '0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'inline-flex',
      position: 'absolute',
      left: position.left,  
      top: position.top,    
      transform: 'translateY(-50%)',  
      zIndex: 999,
    }}
  >
    <div
      className="Tooltip"
      style={{
        height: 6,
        transform: 'rotate(90deg)',
        transformOrigin: '0 0',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        position: 'absolute',
        left: '-3px',  // Center arrow horizontally within tooltip box
        top: '50%',   // Center arrow vertically
        transform: 'translateY(-50%)',  // Vertical alignment
      }}
    >
      <div
        className="TooltipShape"
        style={{
          width: 8,
          height: 8,
          transform: 'rotate(45deg)',
          background: '#0C111D',
          borderRadius: 1,
        }}
      ></div>
    </div>
    <div
      className="Content"
      style={{
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 8,
        paddingBottom: 8,
        background: '#0C111D',
        borderRadius: 8,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',  // Ensures content spans tooltip width
      }}
    >
      <div
        className="Text"
        style={{
          color: '#ffffff', // White color for visibility
          fontSize: 12,
          fontFamily: 'Inter, sans-serif',
          fontWeight: 600,
          lineHeight: '18px',
          textAlign: 'center',
        }}
      >
        {text}
      </div>
    </div>
  </div>,
  document.body 
);
}

const TooltipWrapper = ({ children, tooltipText }) => {
  const [position, setPosition] = useState(null);
  const [visible, setVisible] = useState(false);
  const ref = useRef(null);

  const showTooltip = () => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      setPosition({
        left: rect.right + 8, // Center horizontally
        top: rect.top + rect.height / 2,// Position tooltip above the element
      });
      setVisible(true);
    }
  };

  const hideTooltip = () => setVisible(false);

  return (
    <div
      ref={ref}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      style={{ position: "relative", display: "inline-block" }}
    >
      {children}
      {visible && <Tooltip text={tooltipText} position={position} />}
    </div>
  );
};

export default TooltipWrapper;
// const TooltipWrapper = ({ children, tooltipText }) => {
//   // const [showTooltip, setShowTooltip] = useState(false);

//   return (
//     <div
//       // onMouseEnter={() => setShowTooltip(true)}
//       // onMouseLeave={() => setShowTooltip(false)}
//       style={{ position: 'relative', display: 'inline-block' }}
//     >
//       {children}
//       <Tooltip text={tooltipText} />
//     </div>
//   );
// };

// export default TooltipWrapper;
