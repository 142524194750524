import React from 'react';
import "./ThreeDotsListingComponent.css";
import divider from '../../icons/Gerator/dashboard/divider.svg';
// import { div } from 'react-router-dom';
import { useDeleteContactUserDashboardMutation } from '../../api/ContactAPI';

// export const ThreeDotsContactListingComponent = ({ id }) => {

//   console.log(id);

//   const [deleteFromContact] = useDeleteContactUserDashboardMutation();

//   const handleRemoveContact = async () => {
//     console.log("clicked");

//     await deleteFromContact(id);


//     window.location.reload();
//   }


//   return (
//     <>
//       <div className="three-dots-filter-dropdown-three-dots-filter-all-options-fixed">
//         <section className="three-dots-filter-dropdown-menu-items">
//           <div className="three-dots-filter-dropdown-dropdown-list-item" >
//             <div className="three-dots-filter-dropdown-content48">
//               <div className="three-dots-filter-dropdown-icon-and-text">
//                 <div className="three-dots-filter-dropdown-text247">Message</div>
//               </div>
//             </div>
//           </div>
//           {/* <div className="three-dots-filter-dropdown-dropdown-list-item" >
//           <div className="three-dots-filter-dropdown-content48">
//             <div className="three-dots-filter-dropdown-icon-and-text">
//               <div className="three-dots-filter-dropdown-text247" to={`/archive-${url}`}>Archive</div>
//             </div>
//           </div>
//         </div> */}
//           <div className="three-dots-filter-dropdown-dropdown-list-item" onClick={handleRemoveContact} >
//             <div className="three-dots-filter-dropdown-content48">
//               <div className="three-dots-filter-dropdown-icon-and-text">
//                 <div className="three-dots-filter-dropdown-text247">Delete</div>
//               </div>
//             </div>
//           </div>

//         </section>
//       </div>
//     </>
//   )
// }

export const ThreeDotsContactListingComponent = ({ id, onClose }) => {
  const [deleteFromContact] = useDeleteContactUserDashboardMutation();

  const handleDelete = (e) => {
    e.stopPropagation(); // Prevent event bubbling
    console.log("Delete clicked");

    deleteFromContact(id)
      .then(() => {
        console.log("Delete successful");
        onClose(); // Close dropdown before reload
        window.location.reload();
      })
      .catch((error) => console.error("Delete error:", error));
  };

  return (
    <div
      className="three-dots-filter-dropdown-three-dots-filter-all-options-fixed"
      style={{
        position: 'absolute',
        backgroundColor: 'white',
        boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
        zIndex: 1000
      }}
      onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside dropdown
    >
      <section className="three-dots-filter-dropdown-menu-items">
        {/* <div
          className="three-dots-filter-dropdown-dropdown-list-item"
          style={{ padding: '8px', borderBottom: '1px solid #eee' }}
        >
          <div className="three-dots-filter-dropdown-content48">
            <div className="three-dots-filter-dropdown-icon-and-text">
              <div className="three-dots-filter-dropdown-text247">Message</div>
            </div>
          </div>
        </div> */}

        <div
          className="three-dots-filter-dropdown-dropdown-list-item"
          onClick={handleDelete}
          style={{
            padding: '8px',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: '#f5f5f5'
            }
          }}
        >
          <div className="three-dots-filter-dropdown-content48">
            <div className="three-dots-filter-dropdown-icon-and-text">
              <div className="three-dots-filter-dropdown-text247">Remove</div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};