// import React, {useState} from 'react';
// import "./ReportAbuseSlideOutForm.css";
// import xclose from "../../icons/Gerator/slide_out/xclose.svg";
// import report from "../../icons/Gerator/slide_out/report.svg";
// import featured_icon from '../../icons/Gerator/add_form/featured-icon.svg';
// import { ReportAbuseConfirmationModal } from '../ReportAbuseConfirmationModal/ReportAbuseConfirmationModal';


// export const ReportAbuseSlideOut = ({onClose}) => {

  
//  const [reportText, setReportText] = useState("");
// const [attachments, setAttachments] = useState([]);
// const [showConfirmation, setShowConfirmation] = useState(false);

// const handleFileChange = (event) => {
//   const files = event.target.files ? Array.from(event.target.files) : []; // Ensure files is an array
//   let totalSize = files.reduce((acc, file) => acc + file.size, 0);
//   const maxFiles = 3;
//   const maxSize = 5 * 1024 * 1024; // 5MB

//   // Check if files is an array and contains files
//   if (!files.length) {
//     alert("No files selected.");
//     return;
//   }

//   // Check if the new files exceed the maximum allowed files or size
//   if (files.length + attachments.length > maxFiles) {
//     alert("You can only upload a maximum of 3 files.");
//     return;
//   }

//   if (totalSize + attachments.reduce((acc, file) => acc + file.size, 0) > maxSize) {
//     alert("Maximum total file size is 5MB.");
//     return;
//   }

//   // Append the new files to the existing ones
//   setAttachments((prevAttachments) => [...prevAttachments, ...files]);
//   };

//   const handleSubmit = () => {
//     // if (!reportText.trim() && attachments.length === 0) {
//     //   alert("Please provide a report description or attach files.");
//     //   return;
//     // }

//     console.log("Report Submitted", { reportText, attachments });
//     setShowConfirmation(false); // Close modal after confirmation
//     onClose(); // Close slide-out after submission
//   };

//   return (
//     <div className="report-abuse-slide-out-menu">
//       <main className="report-abuse-panel">
//         <section className="report-abuse-slide-out-menu-header-parent">
//           <header className="report-abuse-slide-out-menu-header">
//             <div className="report-abuse-content71">
//               <img
//                 className="report-abuse-featured-icon2"
//                 loading="lazy"
//                 alt=""
//                 src={report}
//               />

//               <div className="report-abuse-text-and-supporting-text26">
//                 <a className="report-abuse-title9">Report Abuse</a>
//                 {/* <div className="report-abuse-supporting-text91">
//                     Tell us what went wrong
//                 </div> */}
//               </div>
//             </div>
//             <div className="report-abuse-button-close-x6" onClick={onClose} >
//               <img
//                 className="report-abuse-mail-01-icon"
//                 loading="lazy"
//                 alt=""
//                 src={xclose}
//               />
//             </div>
//           </header>

          
//           <div className="report-abuse-section-container">
//             <div className="report-abuse-section2">
//               <div className="report-abuse-input-field">
//                 <div className="report-abuse-input-field">
//                   <div className="report-abuse-label9">  Tell us what went wrong</div>
//                   <div className="report-abuse-input6">
//                   <textarea
//                       className="report-abuse-content72"
//                       placeholder="Max 750 characters"
//                       type="text"
//                        maxLength="750"
//                        value={reportText}
//                        onChange={(e) => setReportText(e.target.value)}
//                     />
//                     </div>
                   
//                 </div>
              
//               </div>
//               {/* <div className="report-abuse-form">
//                 <div className="report-abuse-input-field">
//                   <div className="report-abuse-input-field">
//                     <div className="report-abuse-label10">Attachments </div>
                    
//                     <div className="report-abuse-input6">
//                       <div className="report-abuse-content73">

//                       <p> (PDF, PNG, JPEG, max 3 files, 5MB total)</p>
//                         <input 
//                         type="file" 
//                         multiple 
//                         accept=".pdf, .png, .jpeg, .jpg"
//                         onChange={handleFileChange}
//                         />
//                         <ul>
//                         {attachments.map((file, index) => (
//                             <li key={index}>{file.name}</li>
//                         ))}
//                         </ul>
                   
//                       </div>
                     
//                     </div>
                   
                   
                   
//                   </div>
                 
//                 </div>
//               </div> */}
//              <div className="report-abuse-form">
//             <div className="report-abuse-input-field">
//                 <div className="report-abuse-label10">Attachments</div>

//                 {/* File Upload Section */}
//                 <div className="report-abuse-step3-file-upload-item-base3">
//                 <p>(PDF, PNG, JPEG, max 3 files, 5MB total)</p>
//                 <label htmlFor="document">
//                     <div className="report-abuse-step3-content69">
//                     <img
//                         className="report-abuse-step3-featured-icon5"
//                         loading="lazy"
//                         alt=""
//                         src={featured_icon}
//                     />
//                     <div className="report-abuse-step3-text-and-supporting-text13">
//                         <div className="report-abuse-step3-action5">
//                         <div className="report-abuse-step3-buttonsbutton16">
//                             <div className="report-abuse-step3-text88 documentLabel">Click to upload</div>
//                             <input
//                             type="file"
//                             multiple
//                             accept=".pdf, .png, .jpeg, .jpg"
//                             className="hide-input-field-report-abuse"
//                             onChange={handleFileChange}
//                             />
//                         </div>
//                         </div>
//                         <div className="action custom-action">
//                         <div className="report-abuse-step3-text89">or drag and drop</div>
//                         </div>
//                         <div className="report-abuse-step3-supporting-text28">
//                         <span>Please upload files having extension </span>
//                         <span className="report-abuse-step3-pdf1">PDF, PNG, JPEG</span>
//                         <span> only (Maximum 3 files, total size: 5MB)</span>
//                         </div>
//                     </div>
//                     </div>
//                 </label>

//                 {/* Display Selected Files */}
//                 <div className="report-abuse-step3-frame-parent7">
//                     {attachments.map((file, index) => (
//                     <div className="report-abuse-step3-file-upload-item-base3" key={index}>
//                         <div className="report-abuse-step3-file-type-icon-parent">
//                         <div className="report-abuse-step3-file-type-icon3">
//                             <img
//                             className="report-abuse-step3-page-icon3"
//                             src={file.type.includes("pdf") ? "pdf-icon.png" : "image-icon.png"}
//                             alt="file icon"
//                             />
//                             <div className="report-abuse-step3-file-type-wrap3">
//                             <b className="report-abuse-step3-file-type3">
//                                 {file.type.includes("pdf") ? "PDF" : "Image"}
//                             </b>
//                             </div>
//                         </div>
//                         <div className="report-abuse-step3-text-parent1">
//                             <div className="report-abuse-step3-text90">{file.name}</div>
//                             <div className="report-abuse-step3-supporting-text29">
//                             {(file.size / 1024).toFixed(1)} KB
//                             </div>
//                         </div>
//                         </div>
//                     </div>
//                     ))}
//                 </div>
//                 </div>
//             </div>
//             </div>


              
             

//             </div>
//           </div>


//           <div className="report-abuse-divider-wrapper">
//             <div className="report-abuse-divider12"></div>
//           </div>
//         </section>
//         <footer className="report-abuse-footer2">
//           <div className="report-abuse-content76">
//             <div className="report-abuse-actions19">
//               <button className="report-abuse-buttonsbutton49"  onClick={onClose}>
                

//                 <div className="report-abuse-text-padding51">
//                   <div className="report-abuse-text275">Cancel</div>
//                 </div>
               
//               </button>
//               <button className="report-abuse-buttonsbutton50"   onClick={() => setShowConfirmation(true)}>
               

//                 <div className="report-abuse-text-padding51">
//                   <div className="report-abuse-text276">File Report</div>
//                 </div>
                
//               </button>
//             </div>
//           </div>
//         </footer>
       
//       </main>
//       {/* {showConfirmation && (
//         <div className="confirmation-modal">
//           <div className="confirmation-content">
//             <h2>Confirmation</h2>
//             <p>Are you sure you want to file this report?</p>
//             <div className="confirmation-actions">
//               <button className="cancel-button" onClick={() => setShowConfirmation(false)}>Cancel</button>
//               <button className="confirm-button" onClick={handleSubmit}>Confirm</button>
//             </div>
//           </div>
//         </div>
//       )} */}
     
//     </div>
//   )
// }
import React, { useState } from 'react';
import "./ReportAbuseSlideOutForm.css";
import xclose from "../../icons/Gerator/slide_out/xclose.svg";
import report from "../../icons/Gerator/slide_out/report.svg";
import featured_icon from '../../icons/Gerator/add_form/featured-icon.svg';
import { ReportAbuseConfirmationModal } from '../ReportAbuseConfirmationModal/ReportAbuseConfirmationModal';

export const ReportAbuseSlideOut = ({ onClose }) => {
  const [reportText, setReportText] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleFileChange = (event) => {
    const files = event.target.files ? Array.from(event.target.files) : [];
    let totalSize = files.reduce((acc, file) => acc + file.size, 0);
    const maxFiles = 3;
    const maxSize = 5 * 1024 * 1024; // 5MB

    if (!files.length) {
      alert("No files selected.");
      return;
    }

    if (files.length + attachments.length > maxFiles) {
      alert("You can only upload a maximum of 3 files.");
      return;
    }

    if (totalSize + attachments.reduce((acc, file) => acc + file.size, 0) > maxSize) {
      alert("Maximum total file size is 5MB.");
      return;
    }

    setAttachments((prevAttachments) => [...prevAttachments, ...files]);
  };

  const handleSubmit = () => {
    console.log("Report Submitted", { reportText, attachments });
    setShowConfirmation(true);
    
  };

  return (
    <div className="report-abuse-slide-out-menu">
      <main className="report-abuse-panel">
        <section className="report-abuse-slide-out-menu-header-parent">
          <header className="report-abuse-slide-out-menu-header">
            <div className="report-abuse-content71">
              <img
                className="report-abuse-featured-icon2"
                loading="lazy"
                alt=""
                src={report}
              />
              <div className="report-abuse-text-and-supporting-text26">
                <a className="report-abuse-title9">Report Abuse</a>
              </div>
            </div>
            <div className="report-abuse-button-close-x6" onClick={onClose}>
              <img
                className="report-abuse-mail-01-icon"
                loading="lazy"
                alt=""
                src={xclose}
              />
            </div>
          </header>
          
          {showConfirmation ? (
          
            <ReportAbuseConfirmationModal
              show={showConfirmation}
              onClose={() => {
                setShowConfirmation(false);  
                onClose();  
              }}
           
            />
          ) : (
            // Otherwise, show the form
            <div className="report-abuse-section-container">
              <div className="report-abuse-section2">
                <div className="report-abuse-input-field">
                  <div className="report-abuse-label9">Tell us what went wrong</div>
                  <div className="report-abuse-input6">
                    <textarea
                      className="report-abuse-content72"
                      placeholder="Max 750 characters"
                      maxLength="750"
                      value={reportText}
                      onChange={(e) => setReportText(e.target.value)}
                    />
                  </div>
                </div>

                <div className="report-abuse-form">
                  <div className="report-abuse-input-field">
                    <div className="report-abuse-label10">Attachments</div>
                    <div className="report-abuse-step3-file-upload-item-base3">
                      <p>(PDF, PNG, JPEG, max 3 files, 5MB total)</p>
                      <label htmlFor="document">
                        <div className="report-abuse-step3-content69">
                          <img
                            className="report-abuse-step3-featured-icon5"
                            loading="lazy"
                            alt=""
                            src={featured_icon}
                          />
                          <div className="report-abuse-step3-text-and-supporting-text13">
                            <div className="report-abuse-step3-action5">
                              <div className="report-abuse-step3-buttonsbutton16">
                                <div className="report-abuse-step3-text88 documentLabel">
                                  Click to upload
                                </div>
                                <input
                                  type="file"
                                  multiple
                                  accept=".pdf, .png, .jpeg, .jpg"
                                  className="hide-input-field-report-abuse"
                                  onChange={handleFileChange}
                                />
                              </div>
                            </div>
                            <div className="action custom-action">
                              <div className="report-abuse-step3-text89">or drag and drop</div>
                            </div>
                            <div className="report-abuse-step3-supporting-text28">
                              <span>Please upload files having extension </span>
                              <span className="report-abuse-step3-pdf1">PDF, PNG, JPEG</span>
                              <span> only (Maximum 3 files, total size: 5MB)</span>
                            </div>
                          </div>
                        </div>
                      </label>

                      <div className="report-abuse-step3-frame-parent7">
                        {attachments.map((file, index) => (
                          <div className="report-abuse-step3-file-upload-item-base3" key={index}>
                            <div className="report-abuse-step3-file-type-icon-parent">
                              <div className="report-abuse-step3-file-type-icon3">
                                <img
                                  className="report-abuse-step3-page-icon3"
                                  src={file.type.includes("pdf") ? "pdf-icon.png" : "image-icon.png"}
                                  alt="file icon"
                                />
                                <div className="report-abuse-step3-file-type-wrap3">
                                  <b className="report-abuse-step3-file-type3">
                                    {file.type.includes("pdf") ? "PDF" : "Image"}
                                  </b>
                                </div>
                              </div>
                              <div className="report-abuse-step3-text-parent1">
                                <div className="report-abuse-step3-text90">{file.name}</div>
                                <div className="report-abuse-step3-supporting-text29">
                                  {(file.size / 1024).toFixed(1)} KB
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

        </section>

        <footer className="report-abuse-footer2">
          <div className="report-abuse-content76">
            <div className="report-abuse-actions19">
              <button className="report-abuse-buttonsbutton49" onClick={onClose}>
                <div className="report-abuse-text-padding51">
                  <div className="report-abuse-text275">Cancel</div>
                </div>
              </button>
              {!showConfirmation && (
                <button
                  className="report-abuse-buttonsbutton50"
                  onClick={() => setShowConfirmation(true)} // Trigger confirmation on click
                >
                  <div className="report-abuse-text-padding51">
                    <div className="report-abuse-text276">File Report</div>
                  </div>
                </button>
              )}
            </div>
          </div>
        </footer>
      </main>
    </div>
  );
};

