import React, { useEffect, useRef, useState } from 'react';
import "./DashboardDeviceComponent.css"
import dotsvertical from '../../../icons/Gerator/dashboard/dotsvertical.svg';
import calendar1 from '../../../icons/Gerator/dashboard/calendar1.svg';
import content_elements_2x from '../../../icons/Gerator/dashboard/content-elements@2x.png';
import dot1 from '../../../icons/Gerator/dashboard/-dot1.svg';
import radar_1_1_2x from '../../../icons/Gerator/dashboard/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/dashboard/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/dashboard/magnifier-1@2x.png';
import content_elements_2x from '../../../icons/Gerator/dashboard/content-elements@2x.png';
import eye from '../../../icons/Gerator/dashboard/eye.svg';
import icon1 from '../../../icons/Gerator/dashboard/icon1.svg';
import lock04 from '../../../icons/Gerator/dashboard/lock04.svg';
import lockunlocked04 from '../../../icons/Gerator/dashboard/lockunlocked04.svg';
import arrowsquareupright from '../../../icons/Gerator/dashboard/arrowsquareupright.svg';
import messagechatsquare from '../../../icons/Gerator/dashboard/messagechatsquare.svg';
import markerpin02 from '../../../icons/Gerator/dashboard/markerpin02.svg';
import divider2 from '../../../icons/Gerator/dashboard/divider2.svg';
import arrowup1 from '../../../icons/Gerator/dashboard/arrowup1.svg';
import arrowdown1 from '../../../icons/Gerator/dashboard/arrowdown1.svg';
import placeholder2 from '../../../icons/Gerator/dashboard/placeholder2.svg';
import group_58_2x from '../../../icons/Gerator/dashboard/group-58@2x.png';
import sliders02 from '../../../icons/Gerator/dashboard/sliders02.svg';
import { SortByFilterComponent } from '../../SortByFilterComponent/SortByFilterComponent';
import DashboardFilterButtonGroup from '../../DashboardFilterButtonGroup/DashboardFilterButtonGroup';
import { TransactionTypeComponent } from '../../TransactionTypeComponent/TransactionTypeComponent';
import { ThreeDotsListingComponent } from '../../ThreeDotsListingComponent/ThreeDotsListingComponent';
import { Link } from 'react-router-dom';
import { PostStatusLIstingComponent } from '../../PostStatusLIstingComponent/PostStatusLIstingComponent';
import { useGetUserDashboardDevicesQuery } from '../../../api/DeviceAPI';
import { NoListingFound } from '../../NoListing/NoListingFound';
import SkeletonListingLoaderComponent from '../../SkeletonListingLoaderComponent/SkeletonListingLoaderComponent';
import { formatNumber, formatPrice, getCustomizedDate } from '../../../helper/DeviceHelper';
import SearchInputListingComponent from '../../SearchInputListingComponent/SearchInputListingComponent';
import { ThreeDotsNoActionComponent } from '../../ThreeDotsListingComponent/ThreeDotsNoActionComponent';
import TruncatedTextTooltip from "../../TruncatedTextTooltip/TruncatedTextTooltip";


export const DashboardDeviceComponent = () => {




  const [activeThreeDotIdNoAction, setActiveThreeDotIdNoAction] = useState(null);
  const threeDotsRefNoAction = useRef([]);
  const [isClickBlockedNoAction, setIsClickBlockedNoAction] = useState(false);

  const toggleThreeDotsDropdownNoAction = (index) => {
    if (isClickBlockedNoAction) return; // Prevent rapid toggle



    if (activeThreeDotIdNoAction === index) {
      setActiveThreeDotIdNoAction(null); // Close if the same dropdown is clicked again
    } else {
      setActiveThreeDotIdNoAction(index); // Open the dropdown
    }

    setIsClickBlockedNoAction(true);
    setTimeout(() => setIsClickBlockedNoAction(false), 400);
  };



  useEffect(() => {
    const handleThreeDotClickOutsideNoAction = (event) => {
      if (activeThreeDotIdNoAction !== null) {
        const ref = threeDotsRefNoAction.current[activeThreeDotIdNoAction];

        if (ref && !ref.contains(event.target)) {
          setActiveThreeDotIdNoAction(null); // Close when clicking outside
        }
      }
    };

    document.addEventListener("mousedown", handleThreeDotClickOutsideNoAction);
    return () => {
      document.removeEventListener("mousedown", handleThreeDotClickOutsideNoAction);
    };
  }, [activeThreeDotIdNoAction]);


  //Offset to load data when click load more
  const [offset, setOffset] = useState(0);
  const [sortBy, setSortBy] = useState('date_desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [totalCount, setTotalCount] = useState("");
  const [totalCountPublished, settotalCountPublished] = useState("");
  const [totalCountPending, settotalCountPending] = useState("");
  const [totalCountArchived, settotalCountArchived] = useState("");
  const [totalCountExpired, settotalCountExpired] = useState("");

  const [activePostStatus, setActivePostStatusFilter] = useState(null);

  //Query to get data
  const { data, isSuccess, isError, refetch, isLoading, isFetching } = useGetUserDashboardDevicesQuery(
    { offset, sortBy, postStatus: activePostStatus, search: searchTerm },
    {
      // Refetch when component mounts or tab becomes active
      refetchOnMountOrArgChange: true
    }
  );

  //flag var to show listing when loading complete when sekelteon layout is going to stop
  const [loadingComplete, setLoadingComplete] = useState(false);

  //Var is reponsible to show listings and other thing as well
  let content;

  //Sort by text show on button
  const [selectedSortOption, setSelectedSortOption] = useState('');

  //For SortBy Dropdown
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  //For Threedots in listing dropdown
  const [activeThreeDotId, setActiveThreeDotId] = useState(null);
  const threeDotsRef = useRef(null);
  const [isClickBlocked, setIsClickBlocked] = useState(false);




  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  }

  // const toggleThreeDotsDropdown = (id) => {
  //   if (isClickBlocked) return; // Prevent rapid toggle

  //   if (activeThreeDotId === id) {
  //     setActiveThreeDotId(null); // Close if the same one is clicked again
  //     setIsClickBlocked(true);
  //     setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
  //   } else {
  //     setActiveThreeDotId(id); // Open the clicked one
  //   }
  // };

  const handleThreeDotsClick = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveThreeDotId(activeThreeDotId === index ? null : index);
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };


  //Set value for sortby selected option on button text
  const handleOptionSelect = (option) => {
    if (option.value !== sortBy) {
      setSortBy(option.value);
      setOffset(0); // Reset offset when sort option changes
    }
    setSelectedSortOption(option);
    setDropdownVisible(false);
  };

  //DashbaordFilterButtonGroup component to get data
  const handleFilterClick = (filterLabel) => {
    if (activePostStatus === filterLabel) {
      setSortBy('date_desc')
      setSelectedSortOption('')
      setActivePostStatusFilter(null);
    } else {
      setSortBy('date_desc')
      setSelectedSortOption('')
      setActivePostStatusFilter(filterLabel);
    }
    setOffset(0); // Reset offset when changing filters
  };

  //Setting offset for loadmore when user clicked on load more button(this trigger query to fetch new data)
  // Reset offset when component mounts or remounts
  // useEffect(() => {
  //   setOffset(0);
  // }, []);
  // // Reset offset when sort option changes
  // useEffect(() => {
  //   setOffset(0);
  // }, [sortBy]);

  const handleLoadMore = () => {
    if (data?.hasMore && !isFetching) {
      setOffset(currentOffset => currentOffset + 5);
    }
  };

  const showLoadMore = data?.hasMore &&
    data.devices.length < data.totalCount &&
    !isFetching;





  //Onclick listener for sortby dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  //Onclick listener for threedots dropdown
  // useEffect(() => {
  //   const handleThreeDotClickOutside = (event) => {

  //     const isLink = event.target.closest('a');

  //     if (isLink) {
  //       return; // Allow navigation to happen
  //     }

  //     if (threeDotsRef.current && !threeDotsRef.current.contains(event.target)) {
  //       setActiveThreeDotId(null); // Close any open dropdown when clicking outside
  //     }
  //   };

  //   document.addEventListener("mousedown", handleThreeDotClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleThreeDotClickOutside);
  //   };
  // }, []);



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        threeDotsRef.current &&
        !threeDotsRef.current.contains(event.target) &&
        (!dropdownRef.current || !dropdownRef.current.contains(event.target))
      ) {
        setActiveThreeDotId(null);
        setIsClickBlocked(false); // Reset blocked state when closing
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
      setIsClickBlocked(false); // Reset on cleanup
    };
  }, []);





  // Listen for tab visibility changes
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refetch();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [refetch]);


  //Success true then pause to show sekelton layout
  useEffect(() => {
    if (isSuccess) {
      // Set totalcount in listing counter layout
      setTotalCount(data.totalCount);
      settotalCountPublished(data.totalCountPublished);
      settotalCountArchived(data.totalCountArchived);
      settotalCountPending(data.totalCountPending);
      settotalCountExpired(data.totalCountExpired);
      // Set timeout to delay the loading completion
      const timer = setTimeout(() => {
        setLoadingComplete(true);
      }, 1000); // 1 second

      // Cleanup timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);


  if (isError) {
    content = <NoListingFound path={"/add-device"} pathName={"Device"} />
  }

  if (isLoading) {
    content = <SkeletonListingLoaderComponent />;
  } else if (loadingComplete && isSuccess) {

    if (data.devices.length > 0) {
      // dataLength = data.devices.length;
      content = data.devices.map((device, index) =>
      (
        <div className="dashboard-device-component-dashboard-content-items" key={index}>
          <div className="dashboard-device-component-dashboard-divider-container1">
            <div className="dashboard-device-component-dashboard-rectangle-parent5">
              <img
                className="dashboard-device-component-dashboard-frame-child7"
                alt=""
                src={device.featureImage}
              />

              <div className="dashboard-device-component-dashboard-frame-parent1">
                <div className="dashboard-device-component-dashboard-action-fields">
                  <div className="dashboard-device-component-dashboard-input-elements">
                    <Link to={`/device/${device.id}/${device.title}`} className="dashboard-device-component-dashboard-text78">
                      {device.title}
                    </Link>
                    <div className="dashboard-device-component-dashboard-frame-parent21">
                      <div className="dashboard-device-component-dashboard-badge-item-parent">
                        <div className="dashboard-device-component-dashboard-badge-parent">

                          <PostStatusLIstingComponent postStatus={device.tax_post_status.name} postSlug={device.tax_post_status.slug} postId={device.id} />

                        </div>
                        <div className="dashboard-device-component-dashboard-frame-parent4">
                          <div className="dashboard-device-component-dashboard-end-label">
                            <div className="dashboard-device-component-dashboard-search-icon-input">{device.totalRatingAvg ? `${device.totalRatingAvg}/5` : "0/5"}</div>
                          </div>
                          <div className="dashboard-device-component-dashboard-type-support">
                            <div className="dashboard-device-component-dashboard-supporting-text9">
                          {device.totalReviews} {device.totalReviews === 0 || device.totalReviews === 1 ? "review" : "reviews"}
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="dashboard-device-component-dashboard-frame-parent24">
                        <div className="dashboard-device-component-dashboard-location-pin-parent">
                          <div className="dashboard-device-component-dashboard-search-input">
                            <img
                              className="dashboard-device-component-dashboard-marker-pin-02-icon2"
                              alt=""
                              src={markerpin02}
                            />
                          </div>
                          <div className="dashboard-device-component-dashboard-location3">
                            {/* {device.device_address.addressTownCity}, {device.device_address.tax_country.name} */}
                            <TruncatedTextTooltip
                              text={`${device.device_address.addressTownCity}, ${device.device_address.tax_country.name}`}
                            />
                          </div>
                        </div>

                      </div>
                      <div className="dashboard-device-component-dashboard-frame-parent26">
                        <div className="dashboard-device-component-dashboard-frame-wrapper19">
                          <div className="dashboard-device-component-dashboard-legend-series">
                            <div className="dashboard-device-component-dashboard-search-input">
                              <img
                                className="dashboard-device-component-dashboard-marker-pin-02-icon2"
                                alt=""
                                src={calendar1}
                              />
                            </div>
                            <div className="dashboard-device-component-dashboard-type-of-work2">{getCustomizedDate(device.createdAt)}</div>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>

                  <div className="dashboard-device-component-dashboard-small-card">

                    <div className="dashboard-device-component-dashboard-badge33">
                      <div className="dashboard-device-component-dashboard-third-badge-number">

                        {/* {device.tax_device_category.name} */}
                        <TruncatedTextTooltip text={device.tax_device_category.name} />
                      </div>
                    </div>


                    {/* <div className="dashboard-device-component-dashboard-badge36">
                      <div className="dashboard-device-component-dashboard-third-badge-number">
                        11 Health
                      </div>
                    </div> */}

                    <div className="dashboard-device-component-dashboard-badge37">
                      <div className="dashboard-device-component-dashboard-third-badge-number">
                        {/* {device.tax_oem.name} */}
                        <TruncatedTextTooltip text={device.tax_oem.name} />
                      </div>
                    </div>

                    <div className="dashboard-device-component-dashboard-badge38">
                      <div className="dashboard-device-component-dashboard-third-badge-number">
                        {/* {device.tax_clinical_application.name} */}
                        <TruncatedTextTooltip text={device.tax_clinical_application.name} />
                      </div>
                    </div>

                    {/* <div className="dashboard-device-component-dashboard-badge39">
                      <div className="dashboard-device-component-dashboard-third-badge-number">
                        Accounting
                      </div>
                    </div> */}

                  </div>

                </div>


                <div className="dashboard-device-component-dashboard-filter-elements">
                  <div className="dashboard-device-component-dashboard-dropdown10" onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleThreeDotsClick(e, index);
                  }}
                    ref={threeDotsRef}>
                    <img
                      className="dashboard-device-component-dashboard-dots-vertical-icon"
                      alt=""
                      src={dotsvertical}
                    />
                    {/* {activeThreeDotId === index &&
                      <ThreeDotsListingComponent option={device.tax_post_status.slug}
                        postId={device.id}
                        postType={'device'} url={`device/${device.id}`} totalActiveEngagement={device.totalActiveEngagement} />} */}
                    {activeThreeDotId === index && (
                      <div ref={dropdownRef}>
                        <ThreeDotsListingComponent
                          option={device.tax_post_status.slug}
                          postId={device.id}
                          postType={'device'} url={`device/${device.id}`} totalActiveEngagement={device.totalActiveEngagement}
                          onClose={() => {
                            setActiveThreeDotId(null);
                            setIsClickBlocked(false);
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="dashboard-device-component-dashboard-filter-elements-inner">
                    <div className="dashboard-device-component-dashboard-price-parent">
                      <TransactionTypeComponent transactionType={device.tax_transaction_type.name} slug={device.tax_transaction_type.slug} />
                      <div className="dashboard-device-component-dashboard-price2">${formatNumber(device.price)}</div>
                      <div className="dashboard-device-component-dashboard-dropdown-elements">
                        <div className="dashboard-device-component-dashboard-dropdown-options-parent">
                          <div className="dashboard-device-component-dashboard-text83">{device.tax_unit_measure_device_spare.name}</div>
                          <div className="dashboard-device-component-dashboard-frame-wrapper3">
                            <div className="dashboard-device-component-dashboard-radar-1-1-container">
                              {/* <img
                                className="dashboard-device-component-dashboard-magnifier-1-icon"
                                alt=""
                                src={radar_1_1_2x}
                              /> */}

                              {/* <img
                                className="dashboard-device-component-dashboard-magnifier-1-icon"
                                alt=""
                                src={deal_2_1_2x}
                              />

                              <img
                                className="dashboard-device-component-dashboard-magnifier-1-icon"
                                alt=""
                                src={magnifier_1_2x}
                              /> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
            <img className="dashboard-device-component-dashboard-divider-icon8" alt="" src={divider2} />

            <div className="dashboard-device-component-dashboard-frame-parent71">
              <div className="dashboard-device-component-dashboard-buttonsbutton-parent1">
                <div className="dashboard-legend-series">
                  <img
                    className="dashboard-location-column-child"
                    alt=""
                    src={device.profileImage}
                  />

                  <div className="dashboard-location-column-inner">
                    <div className="dashboard-date-picker-dropdown">
                      <div className="dashboard-name">{device.device_user.displayName}</div>
                      <div className="dashboard-role">{device.device_business.businessName}</div>
                    </div>
                  </div>
                </div>
                <div className='dashboard-device-component-main-buttons-group'>
                  <div className="dashboard-device-component-dashboard-buttonsbutton13">
                    <img
                      className="dashboard-device-component-dashboard-arrow-narrow-left-icon"
                      alt=""
                      src={eye}
                    />

                    <div className="dashboard-device-component-dashboard-search-input">
                      <div className="dashboard-device-component-dashboard-text67">View</div>
                    </div>
                  </div>
                  {device.currentPlan === 1 ?
                    <Link to={"/dashboard/settings/plan"}>
                      <div className="dashboard-device-component-dashboard-buttonsbutton25">
                        <div className="dashboard-device-component-dashboard-icon-wrapper">
                          <img className="dashboard-device-component-dashboard-icon1" alt="" src={icon1} />
                        </div>
                        <div className="dashboard-device-component-dashboard-search-input">
                          <div className="dashboard-device-component-dashboard-text68">Sales IQ</div>
                        </div>
                        <img
                          className="dashboard-device-component-dashboard-arrow-narrow-left-icon"
                          alt=""
                          src={lock04}
                        />
                      </div>
                    </Link>
                    :
                    <Link to={"/sales-iq"}>
                      <div className="dashboard-device-component-dashboard-buttonsbutton26">
                        <div className="dashboard-device-component-dashboard-icon-wrapper">
                          <img className="dashboard-device-component-dashboard-icon1" alt="" src={icon1} />
                        </div>
                        <div className="dashboard-device-component-dashboard-search-input">
                          <div className="dashboard-device-component-dashboard-text68">Sales IQ</div>
                        </div>
                        <img
                          className="dashboard-device-component-dashboard-arrow-narrow-left-icon"
                          alt=""
                          src={lockunlocked04}
                        />
                      </div>
                    </Link>
                  }



                  <Link to={`chat/offered/Device/${device.id}`}>
                    <div className="dashboard-device-component-dashboard-buttonsbutton27">
                      <img
                        className="dashboard-device-component-dashboard-upload-04-icon"
                        alt=""
                        src={messagechatsquare}
                      />

                      <div className="dashboard-device-component-dashboard-text145">Messages</div>
                      {device?.totalUnreadMessage > 0 &&
                        <div className="dashboard-device-component-dashboard-ellipse-parent">
                          <div className="dashboard-device-component-dashboard-ellipse-div"></div>
                          <div className="dashboard-device-component-dashboard-div">{device?.totalUnreadMessage || 0}</div>
                        </div>
                      }

                    </div>
                  </Link>
                </div>

              </div>
              <a
                href={`/device/${device.id}/${device.title}`}
                target="_blank"
                rel="noopener noreferrer"
                className="dashboard-device-component-dashboard-arrow-square-up-right-wrapper1"
              >

                <img
                  className="dashboard-device-component-dashboard-arrow-square-up-right-icon1"
                  alt=""
                  src={arrowsquareupright}
                />
              </a>
            </div>
          </div>
        </div>
      ))
    }
    else {
      content = (

        <NoListingFound path={"/add-device"} pathName={"Device"} />

      )
    }

  } else if (!loadingComplete && isSuccess) {
    content = <SkeletonListingLoaderComponent />; // Show loader until loadingComplete is true
  }




  return (
    <>
      <div className="dashboard-frame-wrapper">
        <div className="dashboard-frame-group">
          <div className="dashboard-rectangle-group">
            <div className="dashboard-frame-item"></div>
            <div className="dashboard-metric-card-row">
              <div className="dashboard-metric-card">
                <div className="dashboard-metric-card-title">
                  <div className="dashboard-heading4">Total</div>
                </div>
                <div className="dashboard-dropdown3" style={{ cursor: "pointer" }} onClick={() => toggleThreeDotsDropdownNoAction(0)} ref={(el) => threeDotsRefNoAction.current[0] = el}>
                  <img
                    className="dashboard-dots-vertical-icon"
                    alt=""
                    src={dotsvertical}
                  />
                  {activeThreeDotIdNoAction === 0 && <ThreeDotsNoActionComponent />}

                </div>
              </div>
              <div className="dashboard-number3">{totalCount || 0}</div>
            </div>
            <div className="dashboard-badge11">
              <div className="dashboard-options-icon">
                <img
                  className="dashboard-arrow-up-icon2"
                  alt=""
                  src={arrowup1}
                />
              </div>
              <div className="dashboard-text58">20 slots available</div>
            </div>
          </div>
          <div className="dashboard-rectangle-container">
            <div className="dashboard-frame-inner"></div>
            <div className="dashboard-frame-container">
              <div className="dashboard-heading-parent">
                <div className="dashboard-heading5">Published</div>
                <div className="dashboard-number4">{totalCountPublished || 0}</div>
                <div className="dashboard-badge12">
                  <div className="dashboard-options-icon">
                    <img
                      className="dashboard-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="dashboard-change-amount">20%</div>
                </div>
              </div>
            </div>
            <div className="dashboard-dropdown3" style={{ cursor: "pointer" }} onClick={() => toggleThreeDotsDropdownNoAction(1)} ref={(el) => threeDotsRefNoAction.current[1] = el}>
              <img
                className="dashboard-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
              {activeThreeDotIdNoAction === 1 && <ThreeDotsNoActionComponent />}

            </div>
          </div>
          <div className="dashboard-rectangle-container">
            <div className="dashboard-frame-inner"></div>
            <div className="dashboard-options-icon">
              <div className="dashboard-heading-group">
                <div className="dashboard-heading5">Pending</div>
                <div className="dashboard-number4">{totalCountPending || 0}</div>
                <div className="dashboard-badge12">
                  <div className="dashboard-options-icon">
                    <img
                      className="dashboard-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="dashboard-change-amount">20%</div>
                </div>
              </div>
            </div>
            <div className="dashboard-dropdown3" style={{ cursor: "pointer" }} onClick={() => toggleThreeDotsDropdownNoAction(2)} ref={(el) => threeDotsRefNoAction.current[2] = el}>
              <img
                className="dashboard-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
              {activeThreeDotIdNoAction === 2 && <ThreeDotsNoActionComponent />}

            </div>
          </div>
          <div className="dashboard-rectangle-container">
            <div className="dashboard-frame-inner"></div>
            <div className="dashboard-frame-container">
              <div className="dashboard-heading-container">
                <div className="dashboard-heading5">Archived</div>
                <div className="dashboard-number4">{totalCountArchived || 0}</div>
                <div className="dashboard-badge12">
                  <div className="dashboard-options-icon">
                    <img
                      className="dashboard-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="dashboard-change-amount">20%</div>
                </div>
              </div>
            </div>
            <div className="dashboard-dropdown3" style={{ cursor: "pointer" }} onClick={() => toggleThreeDotsDropdownNoAction(3)} ref={(el) => threeDotsRefNoAction.current[3] = el}>
              <img
                className="dashboard-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
              {activeThreeDotIdNoAction === 3 && <ThreeDotsNoActionComponent />}

            </div>
          </div>
          <div className="dashboard-rectangle-parent2">
            <div className="dashboard-frame-inner"></div>
            <div className="dashboard-frame-container">
              <div className="dashboard-heading-parent1">
                <div className="dashboard-heading5">Expired</div>
                <div className="dashboard-number4">{totalCountExpired || 0}</div>
                <div className="dashboard-badge12">
                  <div className="dashboard-options-icon">
                    <img
                      className="dashboard-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="dashboard-change-amount">20%</div>
                </div>
              </div>
            </div>
            <div className="dashboard-dropdown7" style={{ cursor: "pointer" }} onClick={() => toggleThreeDotsDropdownNoAction(4)} ref={(el) => threeDotsRefNoAction.current[4] = el}>
              <img
                className="dashboard-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
              {activeThreeDotIdNoAction === 4 && <ThreeDotsNoActionComponent />}

            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-section-wrapper">
        <div className="dashboard-section1">
          <div className="dashboard-container1">
            <div className="dashboard-filters-bar">
              <div className="dashboard-content17">
                <div className="dashboard-dropdowns">

                  <DashboardFilterButtonGroup
                    handleFilterClick={handleFilterClick}
                    activePostStatus={activePostStatus}
                  />

                </div>
                <div className="dashboard-input-dropdown2">
                  {/* <div className="dashboard-input-with-label2">
                    <div className="dashboard-label">Search</div>
                    <div className="dashboard-input2">
                      <div className="dashboard-content3">
                        <img
                          className="dashboard-search-lg-icon dashboard-search-lg-icon-custom"
                          alt=""
                          src={searchlg}
                        />
                        <input type="text" className="dashboard-search-placeholder" placeholder='Search' />
                      </div>
                    </div>
                  </div> */}

                  <SearchInputListingComponent onSearch={handleSearch} />

                  <div className="dashboard-buttonsbutton1" onClick={toggleDropdown}>
                    <img
                      className="dashboard-upload-04-icon"
                      alt=""
                      src={sliders02}
                    />
                    <div className="dashboard-text-padding6" ref={dropdownRef}>
                      <div className="dashboard-text65">
                        Sort By {selectedSortOption && `: ${selectedSortOption.label}`}
                      </div>
                      {isDropdownVisible && <SortByFilterComponent onOptionSelect={handleOptionSelect} />}
                    </div>
                    <img
                      className="dashboard-placeholder-icon"
                      alt=""
                      src={placeholder2}
                    />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Listing Container */}
      <div className="dashboard-device-component-listing-container">

        {/* Listing layout start    */}
        {content}

      </div>

      {/* Load more button  */}
      <div className="dashboard-frame-wrapper44">
        <div className="dashboard-frame-parent72">
          <div className="dashboard-buttonsbutton-wrapper1">
            {showLoadMore && (
              <button className='dashboard-buttonsbutton-wrapper1-child'
                onClick={handleLoadMore}
                disabled={isFetching}
              >
                <div className="dashboard-buttonsbutton28">
                  <img
                    className="dashboard-arrow-narrow-left-icon"
                    alt=""
                    src={arrowdown1}
                  />
                  <div className="dashboard-search-input">
                    <div className="dashboard-text146">{isFetching ? 'Loading...' : 'Load More'}</div>
                  </div>
                </div>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

