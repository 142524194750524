import React, { useState } from 'react';
import "./EditSoftware.css"
import { Navbar1 } from '../../../components/Navbar1/Navbar1'
import { FooterAddForms } from '../../../components/Footer/FooterAddForms/FooterAddForms';
import { HeaderForms } from '../../../components/Header/HeaderForms/HeaderForms';
import divider from '../../../icons/Gerator/add_form/navbar_divider/divider.svg';
import divider_light from '../../../icons/Gerator/divider.svg';
import { Sidebar } from '../../../components/Sidebar/Sidebar';
import { SideBarSubProgressStep1 } from '../../../components/SideBars-Sub/SideBarSubAddForm/SideBarSubProgressStep1';
import { SideBarSubProgressStep2 } from '../../../components/SideBars-Sub/SideBarSubAddForm/SideBarSubProgressStep2';
import { SideBarSubProgressStep3 } from '../../../components/SideBars-Sub/SideBarSubAddForm/SideBarSubProgressStep3';
import EditSoftwareParent from '../../../components/EditSoftwareFormComponent/EditSoftwareParent';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { firestore, doc, getDoc, auth, onAuthStateChanged } from '../../Login/FirebaseClient';
import { Loader } from '../../../components/Loader';
import { useLazyGetProfileImageLinkQuery, useLazyGetUserQuery } from '../../../api/Login&RegisterAPI';
import { useEffect } from 'react';
import { decryptErrorData } from '../../../helper/AESHelper';
import { Helmet } from 'react-helmet-async';
import { useLazyFindIsDeviceExistQuery } from '../../../api/DeviceAPI';
import { useGetPlansForAddressQuery } from '../../../api/PlanAPI';
import { useLazyGetBusinessAllListingCountQuery } from '../../../api/BusinessAPI';
import { ErrorShowComponent } from '../../../components/ErrorShowComponent/ErrorShowComponent';
import { NoAccessFormUpgradePlan } from '../../../components/NoAccessFormUpgradePlan/NoAccessFormUpgradePlan';
import { useLazyFindIsSoftwareExistQuery } from '../../../api/SoftwareAPI';
import { NoAccessInactiveMember } from '../../../components/NoAccessFormUpgradePlan/NoAccessInactiveMember';
import { NoAccessRequestedMember } from '../../../components/NoAccessFormUpgradePlan/NoAccessRequestedMember';
import { NoAccessRevokedMember } from '../../../components/NoAccessFormUpgradePlan/NoAccessRevokedMember';
import { NoAccessExpirePlan } from '../../../components/NoAccessFormUpgradePlan/NoAccessExpirePlan';
import { isPlanExpired } from '../../../helper/UserHelper';
import { NoBusinessFound } from '../../../components/NoBusiness/NoBusiness';

const renderProgressStep = (step, userNameEmail) => {
  switch (step) {
    case 0:
      return <SideBarSubProgressStep1 userData={userNameEmail} />;
    case 1:
      return <SideBarSubProgressStep2 userData={userNameEmail} />;
    case 2:
      return <SideBarSubProgressStep3 userData={userNameEmail} />;
    default:
      return null;
  }
};

export const EditSoftware = () => {

  const { id } = useParams();
  let content = "";
  const appData = useSelector((state) => state.appDataEditSoftware);
  // Progress var when this is true means all taxonomy loaded
  const [isTaxonomySet, setIsTaxonomySet] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const navigate = useNavigate();

  const [showSuccessMessage, setShowSuccessMessage] = useState({
    message: "",
    color: "",
  });

  const [currentPlanConditionData, setCurrentPlanConditionData] = useState("")
  const [profileImageLink, setProfileImageLink] = useState("");
  const [currentPlanAllowedListing, setCurrentPlanAllowedListing] = useState("");
  const [currentRemainingListing, setCurrentRemainingListing] = useState("");
  const [currentUsermeta, setCurrentUsermeta] = useState("");
  const [triggerNewProfileImageLink] = useLazyGetProfileImageLinkQuery();

  const [triggerIsSoftwareExist, { data, isSuccess, error, isLoading, isError }] = useLazyFindIsSoftwareExistQuery();

  const { data: planConditionData, isSuccess: isPlanConditionSuccess, error: planConditionError, isLoading: isPlanConditionLoading, isError: isPlanConditionError } = useGetPlansForAddressQuery();

  const [triggerGetListingCount, { data: listingCountData, isError: isListingCountError }] = useLazyGetBusinessAllListingCountQuery();


  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {

        if (user) {
          // Get user to check wizardStep
          const userDocRef = doc(firestore, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);
          setCurrentUser(user);

          if (userDocSnapshot.exists()) {
            const usermeta = userDocSnapshot.data(); // Default to 1 if not set

            if (usermeta.userStatus == 2) {
              navigate('/account-disabled');
              return;
            }

            if (usermeta.userType == 5) {
              navigate('/access-denied');
              return;
            }

            if (usermeta.wizardStep !== 0) {
              navigate('/account-setup-check-email');
              return;
            }

            // await triggerGetCurrentPlan().unwrap();
            setCurrentUsermeta(usermeta);
            triggerIsSoftwareExist(id);
            triggerGetListingCount();
            // User is authenticated
          } else {
            content = <ErrorShowComponent message1={"Internal server error"} message2={"Please come back after some time"} />
          }
        }
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });


    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);

  //Profile image load useEffect
  useEffect(() => {
    const refreshProfileImage = async () => {
      if (currentUsermeta && currentUsermeta.profileImage.expiresAt < Date.now() / 1000) {
        // Pre-signed URL has expired, trigger a new request to the backend
        const newImageData = await triggerNewProfileImageLink().unwrap();
        // Update the profile image link directly
        setProfileImageLink(newImageData.url);
      } else if (currentUsermeta && currentUsermeta.profileImage.url) {
        // Use the existing pre-signed URL to display the profile image
        setProfileImageLink(currentUsermeta.profileImage.url);
      }
    };

    refreshProfileImage();
  }, [currentUsermeta]);


  //Load plan data to restrict page access
  useEffect(() => {
    if (planConditionData && isPlanConditionSuccess && currentUsermeta) {
      console.log(planConditionData);
      const foundObject = planConditionData.data.find(item => item.id === currentUsermeta.userPlan);
      setCurrentPlanAllowedListing(foundObject.planAllowedListing)
      setCurrentPlanConditionData(foundObject);
    }

  }, [isPlanConditionSuccess, planConditionData, currentUsermeta]);

  // Set remaining listing useState to set condition 
  useEffect(() => {
    if (!listingCountData || !currentPlanAllowedListing) {
      return;
    }

    const remainingListingOfCurrentPlan = currentPlanAllowedListing - listingCountData.data;
    console.log(remainingListingOfCurrentPlan);
    setCurrentRemainingListing(remainingListingOfCurrentPlan);

  }, [listingCountData, currentPlanAllowedListing]);

  // if (isError || isListingCountError) {
  //   if (error.status === 404) {
  //     console.log(error);
  //     content = <ErrorShowComponent message1={"Data not found"} message2={"go back to dashboard."} />
  //   } else {
  //     content = <ErrorShowComponent message1={"Internal server error"} message2={"Please come back after some time"} />
  //   }
  // }

  // if (isSuccess) {
  //   console.log(data);
  //   if (data.status && currentRemainingListing) {
  //     content = <>
  //       <EditSoftwareParent setShowSuccessMessage={setShowSuccessMessage} setIsTaxonomySet={setIsTaxonomySet} currentPlanData={currentPlanConditionData} />
  //     </>
  //   } else if (data.status && !currentRemainingListing) {
  //     content = <NoAccessFormUpgradePlan path={'/dashboard/settings/plan'} pathName={"Upgrade"} />
  //   }

  // }


  // User Type is Normal
  if (currentUsermeta.userType === 1) {
    content = <NoBusinessFound path={'/dashboard/settings/business/add-business'} pathName={"Business"} />
  }

  // User Type is Normal but requested for team member
  if (currentUsermeta.userType === 1 && currentUsermeta?.isMemberRequested) {
    content = <NoAccessRequestedMember path={'/dashboard/settings/business'} pathName={"Request Status"} />
  }

  // User type is Owner
  if (currentUsermeta.userType === 2) {

    // If user plan is 1 then dont check for plan expiry and business verification
    if (currentUsermeta.userPlan === 1) {

      if (currentRemainingListing > 0) {

        if (isError || isListingCountError) {
          if (error.status === 404) {
            console.log(error);
            content = <ErrorShowComponent message1={"Data not found"} message2={"go back to dashboard."} />
          } else {
            content = <ErrorShowComponent message1={"Internal server error"} message2={"Please come back after some time"} />
          }
        }

        if (isSuccess && data.status) {

          content = <>
            <EditSoftwareParent setShowSuccessMessage={setShowSuccessMessage} currentUsermeta={currentUsermeta} currentPlanData={currentPlanConditionData} />
          </>

        }

      } else {

        content = <NoAccessFormUpgradePlan path={'/dashboard/settings/plan'} pathName={"Upgrade"} />
      }


    } else {

      //Plan is greater than 1 then Checking plan is expired or not
      if (isPlanExpired(currentUsermeta.userPlanExpiry)) {

        content = <NoAccessExpirePlan path={'/dashboard/settings/plan'} pathName={"Upgrade"} />

      } else {

        if (currentRemainingListing > 0) {

          if (isError || isListingCountError) {
            if (error.status === 404) {
              console.log(error);
              content = <ErrorShowComponent message1={"Data not found"} message2={"go back to dashboard."} />
            } else {
              content = <ErrorShowComponent message1={"Internal server error"} message2={"Please come back after some time"} />
            }
          }

          if (isSuccess && data.status) {

            content = <>
              <EditSoftwareParent setShowSuccessMessage={setShowSuccessMessage} currentUsermeta={currentUsermeta} currentPlanData={currentPlanConditionData} />
            </>

          }


        } else {

          content = <NoAccessFormUpgradePlan path={'/dashboard/settings/plan'} pathName={"Upgrade"} />
        }
      }

    }

  }




  if (currentUsermeta.userType === 3) {

    //Check plan is expired or not
    if (isPlanExpired(currentUsermeta.userPlanExpiry)) {

      content = <NoAccessExpirePlan path={'/dashboard/settings/plan'} pathName={"Upgrade"} />

    } else {
      // Check if team member is active or not
      if (currentUsermeta.isMemberActive) {
        //Current user remaing listing quota is greater than 0 then allow to add listing
        if (currentRemainingListing > 0) {

          if (isError || isListingCountError) {
            if (error.status === 404) {
              console.log(error);
              content = <ErrorShowComponent message1={"Data not found"} message2={"go back to dashboard."} />
            } else {
              content = <ErrorShowComponent message1={"Internal server error"} message2={"Please come back after some time"} />
            }
          }

          if (isSuccess && data.status) {

            content = <>
              <EditSoftwareParent setShowSuccessMessage={setShowSuccessMessage} currentUsermeta={currentUsermeta} currentPlanData={currentPlanConditionData} />
            </>

          }


        } else {

          content = <NoAccessFormUpgradePlan path={'/dashboard/settings/plan'} pathName={"Upgrade"} />
        }
      } else if (!currentUsermeta.isMemberInvited) {
        //Checked if invite was revoked 
        content = <NoAccessRevokedMember />
      } else if (currentUsermeta.isMemberRequested) {
        content = <NoAccessRequestedMember path={'/dashboard/settings/business'} pathName={"Request Status"} />
      } else {
        // Account is not Active
        content = <NoAccessInactiveMember />
      }
    }
  }


  // User Type is Co-Owner Member
  if (currentUsermeta.userType === 4) {

    //Check plan is expired or not
    if (isPlanExpired(currentUsermeta.userPlanExpiry)) {

      content = <NoAccessExpirePlan path={'/dashboard/settings/plan'} pathName={"Upgrade"} />

    } else {
      // Check if team member is active or not
      if (currentUsermeta.isMemberActive) {
        //Current user remaing listing quota is greater than 0 then allow to add listing
        if (currentRemainingListing > 0) {

          if (isError || isListingCountError) {
            if (error.status === 404) {
              console.log(error);
              content = <ErrorShowComponent message1={"Data not found"} message2={"go back to dashboard."} />
            } else {
              content = <ErrorShowComponent message1={"Internal server error"} message2={"Please come back after some time"} />
            }
          }

          if (isSuccess && data.status) {

            content = <>
              <EditSoftwareParent setShowSuccessMessage={setShowSuccessMessage} currentUsermeta={currentUsermeta} currentPlanData={currentPlanConditionData} />
            </>

          }

        } else {

          content = <NoAccessFormUpgradePlan path={'/dashboard/settings/plan'} pathName={"Upgrade"} />
        }
      } else if (!currentUsermeta.isMemberInvited) {
        //Checked if invite was revoked 
        content = <NoAccessRevokedMember />
      } else {
        // Account is not Active
        content = <NoAccessInactiveMember />
      }
    }
  }


  if (content !== "") {
    return (

      <>
        <Helmet>
          <title>Edit Software</title>
        </Helmet>
        <div className="add-software-forms-add-software add-software-forms-add-software-custom-margin">
          <div className="add-software-forms-add-software-child"></div>


          <Navbar1 userData={currentUser} navigate={navigate} profileImageLink={profileImageLink} />


          <img className="divider-icon" alt="" src={divider_light} />


          <main className="add-software-content-parent">

            <Sidebar profileImageLink={profileImageLink}>{renderProgressStep(appData.currentStep, { displayName: currentUser.displayName, email: currentUser.email })}</Sidebar>










            <section className="add-software-bottom-actions">
              <div className="add-software-progress-container-parent">
                <div className="add-software-progress-container">
                  {/* <div className="add-software-progress-steps">
                <div className="add-software-form-parent">
                 
  
  
  
                </div>
              </div> */}
                  {/* {renderProgressStep(step)} */}
                  {/* <ProgressStep1/> */}
                  {/* <ProgressStep2/> */}
                  {/* <ProgressStep3/> */}
                  <div className="add-software-content5">
                    <div className="add-software-main">


                      <HeaderForms text="Edit Software " text1="Edit Software Listing" text2="Your first step to realizing asset value." />



                      <div className="add-software-main-child"></div>
                      <div className="add-software-main-item"></div>
                    </div>
                    <div className="add-software-body">
                      {/* <EditSoftwareParent setShowSuccessMessage={setShowSuccessMessage} setIsTaxonomySet={setIsTaxonomySet} /> */}
                      {
                        content
                      }


                    </div>
                    {showSuccessMessage.message && (
                      <div
                        style={{
                          fontSize: "14px",
                          color: showSuccessMessage.color,
                          padding: "15px 10px",
                          border: "1px solid " + showSuccessMessage.color,
                          borderRadius: "10px",
                          margin: "0 auto",
                        }}
                      >
                        {showSuccessMessage.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </main>
          <img
            className="add-software-divider-icon10"
            loading="lazy"
            alt=""
            src={divider_light}
          />

          <FooterAddForms />
        </div>
      </>
    )
  } else {
    return <Loader />
  }


}

