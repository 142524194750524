import { AddJob } from "../screens/Job/AddJob/AddJob";
import { ArchiveJob } from "../screens/Job/ArchiveJob/ArchiveJob";
import { ArchiveJobNotLoggedIn } from "../screens/Job/ArchiveJob/ArchiveJobNotLoggedIn";
import { DetailedJob } from "../screens/Job/DetailedJob/DetailedJob";
import { EditJob } from "../screens/Job/EditJob/EditJob";
import { SalesIQJobDetailPage } from "../screens/SalesIQDetailsPage/SalesIQJobDetailPage/SalesIQJobDetailPage";

export const jobRoutes = [
  {
    path: "/add-job",
    element: <AddJob />,
  },
  {
    path: "/edit-job/:id/*",
    element: <EditJob />,
  },
  {
    path: "/jobs",
    element: <ArchiveJob />,
  },
  {
    path: "/jobs-not-logged-in",
    element: <ArchiveJobNotLoggedIn />,
  },
  {
    path: "/job/:id/*",
    element: <DetailedJob />,
  },
];
