import { element } from "prop-types";
import { Dashboard } from "../screens/Dashboard/Dashboard";
import { SignIn } from "../screens/Sign_In/SignIn";
import { SignUp } from "../screens/Sign_Up/SignUp";
import { UserProfile } from "../screens/UserProfile/UserProfile";
import { Radar } from "../screens/Radar/Radar";
import { Beacon } from "../screens/Beacon/Beacon";
import { Contact } from "../screens/Contact/Contact";
import { SalesIQ } from "../screens/SalesIQ/SalesIQ";
import { Funnel } from "../screens/Funnel/Funnel";
import { DemandCenter } from "../screens/DemandCenter/DemandCenter";
// import { ChatPage } from "../screens/Chat/ChatPage";
// import { ChatPageB } from "../screens/Chat/ChatPageB";
import { AdminSignIn } from "../screens/Admin/AdminSignIn/AdminSignIn";
import { UserAccessDenied } from "../screens/AccessControl/UserAccessDenied/UserAccessDenied";
import { UserAccountDisabled } from "../screens/AccessControl/UserAccountDisabled/UserAccountDisabled";
import { ArchiveDeviceNotLoggedIn } from "../screens/Device/ArchiveDevice/ArchiveDeviceNotLoggedIn";
import {ArchiveSpareNotLoggedIn} from "../screens/Spare/ArchiveSpare/ArchiveSpareNotLoggedIn";
import {ArchiveServiceNotLoggedIn} from "../screens/Service/ArchiveService/ArchiveServiceNotLoggedIn";
import {ArchiveSoftwareNotLoggedIn} from "../screens/Software/ArchiveSoftware/ArchiveSoftwareNotLoggedIn";
import {ArchiveJobNotLoggedIn} from "../screens/Job/ArchiveJob/ArchiveJobNotLoggedIn";
import { LandingPage } from "../screens/LandingPage/LandingPage";


export const authRoutes = [
  {
    path: "/account-disabled",
    element: <UserAccountDisabled />,
  },
  {
    path: "/access-denied",
    element: <UserAccessDenied />,
  },
  {
    path: "/admin/sign-in",
    element: <AdminSignIn />,
  },
  // {
  //   path: "/",
  //   element: <SignIn />,
  // },
   {
      path: "/",
      element: <LandingPage />,
    },

    {
      path: "devices-not-logged-in",
      element: <ArchiveDeviceNotLoggedIn />,
    },

    {
      path: "spares-not-logged-in",
      element: <ArchiveSpareNotLoggedIn />,
    },

    {
      path: "softwares-not-logged-in",
      element: <ArchiveSoftwareNotLoggedIn />,
    },

    {
      path: "services-not-logged-in",
      element: <ArchiveServiceNotLoggedIn />,
    },

    {
      path: "jobs-not-logged-in",
      element: <ArchiveJobNotLoggedIn />,
    },
  {
    path: "/sign-up",
    element: <SignUp />,
  },
  {
    path: "/sign-in",
    element: <SignIn />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/radar",
    element: <Radar />
  },
  // {
  //   path: "/beacon",
  //   element: <Beacon />
  // },

  {
    path: "/contact",
    element: <Contact />
  },
  {
    path: "/edit-profile",
    element: <UserProfile />,
  },
  // {
  //   path: "/chatPage",
  //   element: <ChatPage />,
  // },
  // {
  //   path: "/chatPageB",
  //   element: <ChatPageB />,
  // },
  // {
  //   path: "/sales-iq",
  //   element: <SalesIQ />
  // },
  // {
  //   path: "/funnel",
  //   element: <Funnel />,
  // },
  {
    path: "/demand-center",
    element: <DemandCenter />,
  },


];
