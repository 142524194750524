import React, { useEffect, useState } from 'react';
import { Link, NavLink, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import "./Settings.css"
// import { Navbar } from '../../components/Navbar/Navbar';
import { Footer } from '../../components/Footer/Footer';
import { Sidebar } from '../../components/Sidebar/Sidebar';
// import { ProfileSettings } from '../../components/ProfileSettings/ProfileSettings';
// import { BusinessDisplay } from '../../components/BusinessDisplay/BusinessDisplay';
// import AddressComponent from '../../components/AddressComponent/AddressComponent';
import { firestore, doc, getDoc, auth, onAuthStateChanged } from '../Login/FirebaseClient';
// import { SideBarSubNormal } from '../../components/SideBars-Sub/SideBarSubNormal/SideBarSubNormal';
import { SideBarSubSettings } from '../../components/SideBars-Sub/SideBarSubSettings/SideBarSubSettings';
import divider from '../../icons/Gerator/account_setup_profile_subscription/divider.svg';
import divider1 from '../../icons/Gerator/profile_settings/divider1.svg';
import divider2 from '../../icons/Gerator/profile_settings/divider2.svg';
import divider3 from "../../icons/Gerator/profile_settings/divider3.svg"
import searchlg from '../../icons/Gerator/profile_settings/searchlg.svg';
import homeline from '../../icons/Gerator/account_setup_profile_subscription/homeline.svg';
import chevronright from '../../icons/Gerator/account_setup_profile_subscription/chevronright.svg';
import divider from '../../icons/Gerator/dashboard/divider.svg';
import homeline from '../../icons/Gerator/dashboard/homeline.svg';
import { NoListingFound } from '../../components/NoListing/NoListingFound';
import { Navbar1 } from '../../components/Navbar1/Navbar1';
import UserProfileFormView from '../../components/UserProfileFormView/UserProfileFormView';
import { BusinessFormView } from '../../components/BusinessFormView/BusinessFormView';
import { Loader } from '../../components/Loader';
import { ViewAddress } from '../Address/ViewAddress/ViewAddress';
import { PlanAndBilling } from '../PlanAndBilling/PlanAndBilling';
import { PlanAndBillingSuccess } from '../PlanAndBillingSuccess/PlanAndBillingSuccess';
import { Team } from '../Team/Team';
import { NoAccessBusiness } from '../../components/NoAccessBusiness/NoAccessBusiness';
import { useLazyGetProfileImageLinkQuery } from '../../api/Login&RegisterAPI';
import { AdminNavbar } from '../../components/Navbar1/AdminNavbar';
import { AdminSidebar } from '../../components/Sidebar/AdminSidebar';
import { AdminBusinessFormView } from '../../components/Admin/BusinessSection/AdminBusinessFormView/AdminBusinessFormView';


export const AdminSettingsBusiness = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState("");
  const [currentUsermeta, setCurrentUserMeta] = useState("");
  const [profileImageLink, setProfileImageLink] = useState("");
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('Profile');
  const [triggerNewProfileImageLink] = useLazyGetProfileImageLinkQuery();


  const tabs = [
    // { name: 'Profile', path: '/admin/dashboard/settings/profile' },
    { name: 'Business', path: `/admin/dashboard/settings/business/${id}` },
    // { name: 'Address', path: '/dashboard/settings/address' },
    // { name: 'Team', path: '/dashboard/settings/team' },
    // { name: 'Plan & Billing', path: '/dashboard/settings/plan' },
    // // { name: 'Billing', path: '/dashboard/settings/billing' },
    // { name: 'Social', path: '/dashboard/settings/social' },
    // { name: 'Preferences', path: '/dashboard/settings/preferences' },
    // { name: 'Integrations', path: '/dashboard/settings/integrations' },
    // { name: 'API', path: '/dashboard/settings/api' },
  ];

  const handleTabChange = (pathname, tabs) => {

    if (pathname === '/dashboard/settings/success') {
      return "Plan & Billing";
    }

    const matchingTab = tabs.find(tab => tab.path === pathname);
    return matchingTab ? matchingTab.name : "Profile";
  };

  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {

        if (user) {
          // Get user to check wizardStep
          const userDocRef = doc(firestore, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const usermeta = userDocSnapshot.data(); // Default to 1 if not set

            if (usermeta.userType !== 5) {
              navigate('/access-denied');
              return;
            }

            // await triggerGetCurrentPlan().unwrap();
            setCurrentUser(user);
            setCurrentUserMeta(usermeta);
            // User is authenticated
          }
        }
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });




    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);

  useEffect(() => {
    const refreshProfileImage = async () => {
      if (currentUsermeta && currentUsermeta.profileImage?.expiresAt < Date.now() / 1000) {
        // Pre-signed URL has expired, trigger a new request to the backend
        const newImageData = await triggerNewProfileImageLink().unwrap();
        // Update the profile image link directly
        setProfileImageLink(newImageData.url);
      } else if (currentUsermeta && currentUsermeta.profileImage?.url) {
        // Use the existing pre-signed URL to display the profile image
        setProfileImageLink(currentUsermeta.profileImage.url);
      }
    };

    refreshProfileImage();
  }, [currentUsermeta]);


  useEffect(() => {
    const name = handleTabChange(location.pathname, tabs);
    setActiveTab(name);
  }, [location.pathname, tabs]);


  if (currentUser) {

    return (
      <>
        {/* <Helmet>
        <title>{activeTab}</title>
      </Helmet> */}
        <div className="setting-dashboard-frame-layout-setti">
          <main className="setting-dashboard-frame-layout-setti1">
            <div className="setting-dashboard-frame-layout-setti-child"></div>


            <div className="setting-frame-child"></div>

            <AdminNavbar userData={currentUser} profileImageLink={profileImageLink} />

            <section className="setting-divider-wrapper">
              <img
                className="setting-divider-icon"
                loading="lazy"
                alt=""
                src={divider3}
              />
            </section>
            <section className="setting-sidebar-navigation-parent">
            
            <AdminSidebar profileImageLink={profileImageLink}>
                  {/* <SideBarSubSettings userData={currentUser} /> */}
                </AdminSidebar>
              {/* <div className="admin-setting-sidebar-navigation">



              </div> */}
              <div className="setting-main-parent">
                <div className="setting-main">
                  <div className="setting-header-section">
                    <div className="setting-container">
                      <div className="setting-page-header">
                        <div className="setting-breadcrumbs">
                          <div className="setting-tabs">
                            <div className="setting-breadcrumb-button-base">
                              <img
                                className="setting-search-lg-icon"
                                alt=""
                                src={homeline}
                              />
                            </div>
                            <img
                              className="setting-chevron-right-icon"
                              alt=""
                              src={chevronright}
                            />

                            <div className="setting-breadcrumb-button-base1">
                              <a className="setting-text22">Dashboard</a>
                            </div>
                            <img
                              className="setting-chevron-right-icon"
                              alt=""
                              src={chevronright}
                            />

                            <div className="setting-breadcrumb-button-base1">
                              <a className="setting-text22">Settings</a>
                            </div>
                            <img
                              className="setting-chevron-right-icon"
                              alt=""
                              src={chevronright}
                            />
                            <div className="setting-breadcrumb-button-base4">
                              <a className="setting-text25">{activeTab}</a>
                            </div>
                          </div>
                        </div>
                        <img
                          className="setting-divider-icon1"
                          alt=""
                          src={divider1}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="setting-main-child"></div>
                  <div className="setting-main-item"></div>
                </div>
                <div className="setting-main-wrapper">
                  <div className="setting-main1">
                    <div className="setting-header-content">
                      <div className="setting-header-section1">
                        <div className="setting-container1">
                          <div className="setting-page-header1">
                            {/* <div className="setting-breadcrumbs1">
                              <div className="setting-tabs1">
                                <div className="setting-breadcrumb-button-base">
                                  <img
                                    className="setting-search-lg-icon"
                                    alt=""
                                    src="/profile_settings/homeline-1.svg"
                                  />
                                </div>
                                <img
                                  className="setting-chevron-right-icon"
                                  alt=""
                                  src="/profile_settings/chevronright.svg"
                                />

                                <div className="setting-breadcrumb-button-base6">
                                  <div className="setting-text26">Settings</div>
                                </div>
                                <img
                                  className="setting-chevron-right-icon"
                                  alt=""
                                  src="/profile_settings/chevronright.svg"
                                />

                                <div className="setting-breadcrumb-button-base7">
                                  <div className="setting-text23">...</div>
                                </div>
                                <img
                                  className="setting-chevron-right-icon"
                                  alt=""
                                  src="/profile_settings/chevronright.svg"
                                />

                                <div className="setting-breadcrumb-button-base3">
                                  <div className="setting-text24">Another link</div>
                                </div>
                                <img
                                  className="setting-chevron-right-icon2"
                                  alt=""
                                  src="/profile_settings/chevronright.svg"
                                />

                                <div className="setting-breadcrumb-button-base4">
                                  <div className="setting-text29">Team</div>
                                </div>
                              </div>
                            </div> */}
                            <div className="setting-content13">
                              <div className="setting-text-and-supporting-text">
                                <h1 className="setting-page-title">Settings</h1>
                                <div className="setting-supporting-text1">
                                  Manage your team members and their account
                                  permissions here.
                                </div>
                              </div>
                              {/* <div className="setting-actions">
                                <div className="setting-button">
                                  <img
                                    className="setting-placeholder-icon"
                                    alt=""
                                    src="/profile_settings/placeholder.svg"
                                  />

                                  <div className="setting-text-padding">
                                    <div className="setting-title">Tertiary</div>
                                  </div>
                                  <img
                                    className="setting-placeholder-icon"
                                    alt=""
                                    src="/profile_settings/placeholder.svg"
                                  />
                                </div>
                                <div className="setting-button1">
                                  <img
                                    className="setting-placeholder-icon"
                                    alt=""
                                    src="/profile_settings/placeholder1.svg"
                                  />

                                  <div className="setting-text-padding">
                                    <div className="setting-title">Secondary</div>
                                  </div>
                                  <img
                                    className="setting-placeholder-icon"
                                    alt=""
                                    src="/profile_settings/placeholder1.svg"
                                  />
                                </div>
                                <div className="setting-button2">
                                  <img
                                    className="setting-placeholder-icon"
                                    alt=""
                                    src="/profile_settings/placeholder2.svg"
                                  />

                                  <div className="setting-text-padding">
                                    <div className="setting-title">Secondary</div>
                                  </div>
                                  <img
                                    className="setting-placeholder-icon"
                                    alt=""
                                    src="/profile_settings/placeholder2.svg"
                                  />
                                </div>
                                <div className="setting-button3">
                                  <img
                                    className="setting-placeholder-icon"
                                    alt=""
                                    src="/profile_settings/placeholder3.svg"
                                  />

                                  <div className="setting-text-padding">
                                    <div className="setting-title">Primary</div>
                                  </div>
                                  <img
                                    className="setting-placeholder-icon"
                                    alt=""
                                    src="/profile_settings/placeholder3.svg"
                                  />
                                </div>
                              </div> */}
                              <div className="setting-input-dropdown">
                                <div className="setting-input-with-label1">
                                  <div className="setting-label">Search</div>
                                  <div className="setting-input1">
                                    <div className="setting-content3">
                                      <img
                                        className="setting-search-lg-icon"
                                        alt=""
                                        src={searchlg}
                                      />

                                      <input
                                        className="setting-text34"
                                        placeholder="Search"
                                        type="text"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="setting-hint-text">
                                  This is a hint text to help user.
                                </div>
                              </div>
                            </div>
                            <img
                              className="setting-divider-icon2"
                              alt=""
                              src={divider2}
                            />
                          </div>
                          <div className="setting-horizontal-tabs">
                            <div className="setting-tabs2">
                              {tabs.map((tab) => (
                                <div className={activeTab === tab.name ? "setting-tab-button-base" : "setting-tab-button-base1"} style={{ cursor: "pointer" }} onClick={() => setActiveTab(tab.name)} key={tab.name}>
                                  <Link

                                    to={tab.path}
                                    className={location.pathname === tab.path ? 'setting-text36 active' : 'setting-text36'}
                                  >
                                    {tab.name}
                                  </Link>
                                </div>
                              ))}

                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                    <AdminBusinessFormView currentUser={currentUser} currentUsermeta={currentUsermeta} id={id} />
                    {/* <Routes> */}
                    {/* <Route path="/business/:id" element={<UserProfileFormView currentUsermeta={currentUsermeta} />} /> */}
                    {/* <Route path="/business/:id" element={} /> */}
                    {/* <Route path="/business" element={<BusinessFormView currentUser={currentUser} currentUsermeta={currentUsermeta} />} />
                      <Route path="/address" element={<ViewAddress currentUser={currentUser} currentUsermeta={currentUsermeta} />} />
                      <Route path="/team" element={<Team currentUser={currentUser} currentUsermeta={currentUsermeta} />} />
                      <Route path="/plan" element={<PlanAndBilling currentUser={currentUser} currentUsermeta={currentUsermeta} />} />
                      <Route path="/social" element={<NoAccessBusiness path={currentUser.email} />} />
                      <Route path="/preferences" element={<NoListingFound />} />
                      <Route path="/success" element={<PlanAndBillingSuccess />} /> */}
                    {/* </Routes> */}

                  </div>
                </div>
              </div>
            </section>
            <img className="setting-divider-icon5" alt="" src={divider3} />
            <footer className="setting-footer1">

              <Footer />


            </footer>
          </main>
        </div>
      </>
    )


  }
  else {
    return <Loader />
  }



}

