import React from "react";
import "./Footer.css"
import gerator_logo from '../../icons/Gerator/dashboard/gerator10-2@2x.png';
import { Link } from "react-router-dom";


export const Footer = () => {
    return(
        <div className="footer-footer1">
        <div className="footer-container3">
          <div className="footer-content47">
            <div className="footer-footer-text">
              © Gerator Asia LLP. All rights reserved.
            </div>
            <div className="footer-checkbox">
              <div className="footer-logo-container">
                <div className="footer-change">
                  <div className="footer-footer-text">All prices are in USD</div>
                </div>
                <Link to={"/dashboard"}>
                <div className="footer-logomark" style={{cursor:"pointer"}}>
                  <div className="footer-logomark1">
                    <img
                      className="footer-gerator-10-2-icon"
                      alt=""
                      src={gerator_logo}
                    />
                  </div>
                </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}