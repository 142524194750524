import React from "react";
import "./SkeletonChatLoaderComponent.css"; // Import the CSS file

const SkeletonChatLoaderComponent = () => {
    return (
        <div className="layout-chat-container">
            {/* Chat Header */}
            <div className="layout-chat-header">
                <div className="layout-skeleton layout-profile-pic"></div>
                <div className="layout-skeleton layout-name"></div>
            </div>

            {/* Load Previous Messages Button */}
            <div className="layout-skeleton layout-load-btn"></div>

            {/* Messages */}
            <div className="layout-messages">
                <div className="layout-skeleton layout-message"></div>
                <div className="layout-skeleton layout-message"></div>
                <div className="layout-skeleton layout-self-message"></div>
                <div className="layout-skeleton layout-self-message"></div>
            </div>

            {/* Input Box */}
            <div className="layout-input-container">
                <div className="layout-skeleton layout-input-box"></div>
            </div>
        </div>
    );
};

export default SkeletonChatLoaderComponent;
