import React, { useState } from "react";
import "./Sidebar.css";
import homeline from '../../icons/Gerator/dashboard/homeline.svg';
import barchartsquare02 from '../../icons/Gerator/dashboard/barchartsquare02.svg';
import layersthree01 from '../../icons/Gerator/dashboard/layersthree01.svg';
import checkdone01 from '../../icons/Gerator/dashboard/checkdone01.svg';
import piechart03 from '../../icons/Gerator/dashboard/piechart03.svg';
import users01 from '../../icons/Gerator/dashboard/users01.svg';
import lifebuoy01 from '../../icons/Gerator/dashboard/lifebuoy01.svg';
import settings01 from '../../icons/Gerator/dashboard/settings01.svg';
import arrownarrowleft from '../../icons/Gerator/dashboard/arrownarrowleft.svg';
import arrowright from '../../icons/Gerator/dashboard/arrowright.svg';
import activity from '../../icons/Gerator/dashboard/activity.svg';
import chevrondown from '../../icons/Gerator/dashboard/chevrondown.svg';
import notificationbox from '../../icons/Gerator/dashboard/notificationbox.svg';
import barlinechart from '../../icons/Gerator/dashboard/barlinechart.svg';
import star01 from '../../icons/Gerator/dashboard/star01.svg';
import clockfastforward from '../../icons/Gerator/dashboard/clockfastforward.svg';
import usersquare from '../../icons/Gerator/dashboard/usersquare.svg';
import settings03 from '../../icons/Gerator/dashboard/settings03.svg';
import logout01 from '../../icons/Gerator/dashboard/logout01.svg';
// import xclose from '../../icons/Gerator/dashboard/xclose.svg';
import { Link } from "react-router-dom";
import { useEffect } from "react";

import rocket_launch from "../../icons/Gerator/dashboard/rocket_launch.svg";
import TooltipWrapper from "../Tooltip/Tooltip";
import buyer_tools from "../../icons/Gerator/dashboard/buyer_tools.svg"

export const Sidebar = ({ children, profileImageLink }) => {

  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("activeTab") || "dashboard"
  );

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    localStorage.setItem("activeTab", tab); // Save active tab in localStorage
  };

  useEffect(() => {
    // Check localStorage when the component mounts
    const savedTab = localStorage.getItem("activeTab");
    if (savedTab) {
      setActiveTab(savedTab);
    }
  }, []);

  const [isSubSideBarVisible, setSubSideBarVisible] = useState(true);

  const [hasTransitionStarted, setHasTransitionStarted] = useState(false); // New state to manage transitions

  useEffect(() => {
    // Set the transition state after the component has mounted
    setHasTransitionStarted(true);
  }, []); // This ensures transitions apply only after page load

  return (
    // <div className='sidebar-sidebar-navigation'>
    <div className={`
      ${isSubSideBarVisible
        ? 'sidebar-sidebar-navigation'
        : 'sidebar-sidebar-navigation-disabled'} 
      ${hasTransitionStarted ? 'sidebar-animate' : ''}  /* Add transition class after load */}
    `}>
      <div className="sidebar-content4">
        <div className={`sidebar-navigation1 ${isSubSideBarVisible ? '' : 'sidebar-navigation1-padding'}`}>
          <div className="sidebar-navigation2">
            {!isSubSideBarVisible && (
              <div className="sidebar-nav-item-button2">
                <img onClick={() => setSubSideBarVisible(!isSubSideBarVisible)}
                  className="sidebar-home-line-icon"
                  alt=""
                  src={logout01}
                />
              </div>
            )}


            <TooltipWrapper tooltipText="Dashboard">
              <Link to={"/dashboard"}
              onClick={() => handleTabClick('dashboard')}
              className={activeTab === 'dashboard' ? 'sidebar-nav-item-button3' : 'sidebar-nav-item-button2'}
              >
              
            
                  <img className="sidebar-home-line-icon" alt="" src={homeline} />
                

              </Link>
            </TooltipWrapper>


            <TooltipWrapper tooltipText={"Buyer Tools"}>
              <Link to={"/radar"}
              className={activeTab === 'radar' ? 'sidebar-nav-item-button3' : 'sidebar-nav-item-button2'} 
              onClick={() => handleTabClick('radar')}
              >
               
                  <img className="sidebar-home-line-icon" alt="" src={buyer_tools} />
               
              </Link>
            </TooltipWrapper>

            <TooltipWrapper tooltipText={"Seller Tools"}>
              <Link to={"/sales-iq"}
                 className={activeTab === 'sales-iq' ? 'sidebar-nav-item-button3' : 'sidebar-nav-item-button2'} 
                 onClick={() => handleTabClick('sales-iq')}
              >
             
                  <img className="sidebar-home-line-icon" alt="" src={rocket_launch} />
               
              </Link>
            </TooltipWrapper>


            <TooltipWrapper tooltipText={"Contacts"}>
              <Link to={"/contact"}
                 className={activeTab === 'contact' ? 'sidebar-nav-item-button3' : 'sidebar-nav-item-button2'} 
                 onClick={() => handleTabClick('contact')}
             >
                  <img className="sidebar-home-line-icon" alt="" src={users01} />
           
              </Link>
            </TooltipWrapper>


            {/* <div className="sidebar-nav-item-button2">
              <img className="sidebar-home-line-icon" alt="" src={piechart03} />
            </div>
            <div className="sidebar-nav-item-button2">
              <img className="sidebar-home-line-icon" alt="" src={checkdone01} />
            </div> */}
          </div>
        </div>
        <div className="sidebar-footer">
          <div className="sidebar-navigation3">

            <TooltipWrapper tooltipText={"Help Center"}>
              <a href="https://helpcenter.gerator.com/">
                <div className="sidebar-nav-item-button2" >
                  <img className="sidebar-home-line-icon" alt="" src={lifebuoy01} />
                </div>
              </a>
            </TooltipWrapper>


            <TooltipWrapper tooltipText={"Settings"}>
              <Link to={"/dashboard/settings/profile"}>
                <div className="sidebar-nav-item-button2" >
                  <img className="sidebar-home-line-icon" alt="" src={settings01} />
                </div>
              </Link>
            </TooltipWrapper>




          </div>

          <Link to={"/dashboard/settings/profile"}>
            <div className="sidebar-avatar1" style={{
              backgroundImage: profileImageLink
                && `url(${profileImageLink})`
            }}>
              <div className="sidebar-contrast-border1"></div>
            </div>
          </Link>


        </div>
      </div>
      {(isSubSideBarVisible && children) && (
        React.cloneElement(children, { isSubSideBarVisible, setSubSideBarVisible })
      )}
    </div>
  )
}
