import React, { useEffect, useRef, useState } from 'react';
import "./AdminDashboardBusinessComponent.css";
import plus from "../../../../icons/Gerator/contacts/plus.svg";
import uploadcloud02 from "../../../../icons/Gerator/contacts/uploadcloud02.svg";
import dotsvertical from "../../../../icons/Gerator/contacts/dotsvertical.svg";
import divider from "../../../../icons/Gerator/contacts/divider.svg";
import searchlg from "../../../../icons/Gerator/contacts/searchlg.svg";
import filterlines from "../../../../icons/Gerator/contacts/filterlines.svg";
import arrowdown from "../../../../icons/Gerator/contacts/arrowdown.svg";
import markerpin02 from "../../../../icons/Gerator/contacts/markerpin02.svg";
import help_icon from "../../../../icons/Gerator/contacts/help_icon.svg";
import arrowright from "../../../../icons/Gerator/contacts/arrowright.svg"
import arrowleft from "../../../../icons/Gerator/contacts/arrowleft.svg"

import arrowdown1 from '../../../../icons/Gerator/dashboard/arrowdown1.svg';
import { auth, onAuthStateChanged } from '../../../../screens/Login/FirebaseClient';

import { SlideOutMenu } from '../../../SlideOutMenu/SlideOutMenu';
// import { useDeleteContactMutation, useGetUserDashboardContactsQuery } from '../../../../api/ContactAPI';
import { useNavigate } from 'react-router-dom';
import { NoListingFound } from '../../../NoListing/NoListingFound';
import SkeletonListingLoaderComponent from '../../../SkeletonListingLoaderComponent/SkeletonListingLoaderComponent';
import { ThreeDotsListingComponent } from '../../../ThreeDotsListingComponent/ThreeDotsListingComponent';
import { ThreeDotsContactListingComponent } from '../../../ThreeDotsListingComponent/ThreeDotsContactListingComponent';
import SearchComponentAdmin from '../../UserSection/SearchComponentAdmin/SearchComponentAdmin';
import { ErrorShowComponent } from '../../../ErrorShowComponent/ErrorShowComponent';
import { useGetAdminDashboardBusinessQuery } from '../../../../api/admin/AdminBusinessAPI';
import { ThreeDotsAdminListingComponent } from '../../../ThreeDotsListingComponent/ThreeDotsAdminListingComponent';
import { FilterAdminUserComponent } from '../../UserSection/FilterAdminUserComponent/FilterAdminUserComponent';
import { ThreeDotsAdminBusinessComponent } from '../../../ThreeDotsListingComponent/ThreeDotsAdminBusinessComponent';
import EasyFilterAdminBusinessComponent from '../EasyFilterAdminBusinessComponent/EasyFilterAdminBusinessComponent';
import { FilterAdminBusinessComponent } from '../FilterAdminBusinessComponent/FilterAdminBusinessComponent';
import SkeletonTableListingLoaderComponent from '../../../SkeletonTableListingLoaderComponent/SkeletonTableListingLoaderComponent';


export const AdminDashboardBusinessComponent = () => {

  // const [currentUser, setCurrentUser] = useState("");
  const navigate = useNavigate();
  const [isMenuVisible, setMenuVisible] = useState(false);

  const [offset, setOffset] = useState(0);
  const [sortBy, setSortBy] = useState('date_desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [totalCount, setTotalCount] = useState("");


  const [activePostStatus, setActivePostStatusFilter] = useState(null);
  //Query to get data
  const { data, isSuccess, isError, refetch, isLoading, isFetching } = useGetAdminDashboardBusinessQuery(
    { offset, sortBy, postStatus: activePostStatus, search: searchTerm },
    {
      // Refetch when component mounts or tab becomes active
      refetchOnMountOrArgChange: true
    }
  );


  //flag var to show listing when loading complete when sekelteon layout is going to stop
  const [loadingComplete, setLoadingComplete] = useState(false);

  //Var is reponsible to show listings and other thing as well
  let content;

  //Sort by text show on button
  const [selectedSortOption, setSelectedSortOption] = useState('');

  //For SortBy Dropdown
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  //For Threedots in listing dropdown
  const [activeThreeDotId, setActiveThreeDotId] = useState(null);
  const threeDotsRef = useRef(null);
  const [isClickBlocked, setIsClickBlocked] = useState(false);



  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  }

  const toggleThreeDotsDropdown = (id) => {
    if (isClickBlocked) return; // Prevent rapid toggle

    if (activeThreeDotId === id) {
      setActiveThreeDotId(null); // Close if the same one is clicked again
      setIsClickBlocked(true);
      setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
    } else {
      setActiveThreeDotId(id); // Open the clicked one
    }
  };

  // const handleThreeDotsClick = (id) => {
  //   console.log("Three dots clicked for id:", id);
  //   // setActiveThreeDotId(activeThreeDotId === index ? null : index);
  //   // if (isClickBlocked) return; // Prevent rapid toggle

  //   if (activeThreeDotId === id) {
  //     setActiveThreeDotId(null); // Close if the same one is clicked again
  //     // setIsClickBlocked(true);
  //     // setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
  //   } else {
  //     setActiveThreeDotId(id); // Open the clicked one
  //   }
  // };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };


  //Set value for sortby selected option on button text
  const handleOptionSelect = (option) => {
    if (option.value !== sortBy) {
      setSortBy(option.value);
      setOffset(0); // Reset offset when sort option changes
    }
    setSelectedSortOption(option);
    setDropdownVisible(false);
  };

  //DashbaordFilterButtonGroup component to get data
  const handleFilterClick = (filterLabel) => {
    if (activePostStatus === filterLabel) {
      setSortBy('date_desc')
      setSelectedSortOption('')
      setActivePostStatusFilter(null);
    } else {
      setSortBy('date_desc')
      setSelectedSortOption('')
      setActivePostStatusFilter(filterLabel);
    }
    setOffset(0); // Reset offset when changing filters
  };

  //Setting offset for loadmore when user clicked on load more button(this trigger query to fetch new data)
  // Reset offset when component mounts or remounts
  // useEffect(() => {
  //   setOffset(0);
  // }, []);
  // // Reset offset when sort option changes
  useEffect(() => {
    console.log(data);

  }, [data]);

  const handleLoadMore = () => {
    if (data?.hasMore && !isFetching) {
      setOffset(currentOffset => currentOffset + 5);
    }
  };

  const showLoadMore = data?.hasMore &&
    data.businesses.length < data.totalCount &&
    !isFetching;





  //Onclick listener for sortby dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);



  const handleThreeDotsClick = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveThreeDotId(activeThreeDotId === index ? null : index);
  };

  // Modified click outside handler
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     // Check if click is outside both the three dots icon and the dropdown
  //     if (
  //       threeDotsRef.current &&
  //       !threeDotsRef.current.contains(event.target) &&
  //       (!dropdownRef.current || !dropdownRef.current.contains(event.target))
  //     ) {
  //       setActiveThreeDotId(null);
  //     }
  //   };

  //   document.addEventListener("click", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Ensure event target is not inside any active dropdown
      if (!event.target.closest(".admin-dashboard-business-dropdown")) {
        setActiveThreeDotId(null);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);


  //Onclick listener for threedots dropdown
  useEffect(() => {
    const handleThreeDotClickOutside = (event) => {
      const isLink = event.target.closest('a');

      if (isLink) {
        return; // Allow navigation to happen
      }

      if (threeDotsRef.current && !threeDotsRef.current.contains(event.target)) {
        setActiveThreeDotId(null); // Close any open dropdown when clicking outside
      }
    };

    document.addEventListener("mousedown", handleThreeDotClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleThreeDotClickOutside);
    };
  }, []);

  // Listen for tab visibility changes
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refetch();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [refetch]);


  //Success true then pause to show sekelton layout
  useEffect(() => {
    if (isSuccess) {
      // Set totalcount in listing counter layout
      setTotalCount(data.totalCount);
      // Set timeout to delay the loading completion
      const timer = setTimeout(() => {
        setLoadingComplete(true);
      }, 1000); // 1 second

      // Cleanup timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);


  if (isError) {
    content = <ErrorShowComponent message1={'Something went wrong..'} />
  }

  if (isLoading) {
    content = <SkeletonTableListingLoaderComponent />;
  } else if (loadingComplete && isSuccess) {

    if (data.businesses.length > 0) {
      // dataLength = data.businesses.length;
      content = data.businesses.map((businesses, index) =>
      (
        // <div className="admin-dashboard-business-table-header-cell-parent" key={index}>


        //   <div className="admin-dashboard-business-table-cell10">
        //     {/* <div className="admin-dashboard-business-cell-content">
        //       <input className="admin-dashboard-business-checkbox-wrapper" type="checkbox" />
        //     </div> */}
        //     <div className="admin-dashboard-business-avatar" style={{
        //       backgroundImage: contact.profileImage
        //         && `url(${contact.profileImage})`
        //     }}>
        //       <div className="admin-dashboard-business-contrast-border">
        //         {/* <div className="admin-dashboard-business-avatar-online-indicator"></div> */}
        //       </div>
        //     </div>
        //     <div className="admin-dashboard-business-dropdown1">
        //       <div className="admin-dashboard-business-cell-title">{`${contact.user_usermeta.firstName} ${contact.user_usermeta.lastName}`}</div>
        //       <div className="admin-dashboard-business-supporting-text4">{contact.user_usermeta.userWorkTitle}</div>
        //     </div>
        //   </div>

        //   <div className="admin-dashboard-business-table-cell20">
        //     <div className="admin-dashboard-business-location-content">
        //       <img
        //         className="admin-dashboard-business-upload-cloud-02-icon"
        //         alt=""
        //         src={markerpin02}
        //       />
        //       <div className="admin-dashboard-business-text36">India, {contact.user_usermeta.userState}</div>
        //     </div>
        //   </div>


        //   <div className="admin-dashboard-business-table-cell30">
        //     <div className="admin-dashboard-business-text47">2 hours back</div>
        //   </div>

        //   <div className="admin-dashboard-business-table-cell40">
        //     <div className="admin-dashboard-business-text47">2 hours back</div>
        //   </div>

        //   <div className="admin-dashboard-business-table-cell50">
        //     <div className="admin-dashboard-business-text47">2 hours back</div>
        //   </div>

        //   <div className="admin-dashboard-business-table-cell60">
        //     <div className="admin-dashboard-business-text47">2 hours back</div>
        //   </div>




        // <div className="admin-dashboard-business-table-cell70">
        //   {/* <div className="admin-dashboard-business-dropdown" onClick={() => {
        //     toggleThreeDotsDropdown(index)
        //   }
        //   } ref={threeDotsRef}>
        //     <img
        //       className="admin-dashboard-business-dots-vertical-icon"
        //       alt=""
        //       src={dotsvertical}
        //     />
        //   </div> */}
        //   {/* <div className="admin-dashboard-business-dropdown" onClick={() => handleThreeDotsClick(index)} ref={threeDotsRef}> */}
        //   <div
        //     className="admin-dashboard-business-dropdown"
        //     onClick={(e) => {
        //       e.stopPropagation(); // Prevent event bubbling
        //       handleThreeDotsClick(index);
        //     }}
        //     ref={threeDotsRef}
        //   >
        //     <img src={dotsvertical} alt="options" />
        //   </div>
        //   {/* {activeThreeDotId === index && (
        //     <ThreeDotsContactListingComponent id={contact.contact_pk_id} />)} */}
        //   {activeThreeDotId === index && (
        //     <div ref={dropdownRef}>
        //       <ThreeDotsContactListingComponent
        //         id={contact.contact_pk_id}
        //         onClose={() => setActiveThreeDotId(null)}
        //       />
        //     </div>
        //   )}
        // </div>

        // </div>
        <div className="admin-dashboard-business-table-header-cell-parent" key={index} >


          <div className="admin-dashboard-business-table-cell10">
            {/* <div className="admin-dashboard-business-cell-content">
            <input className="admin-dashboard-business-checkbox-wrapper" type="checkbox" />
          </div> */}
            <div className="admin-dashboard-business-avatar-business" style={{
              backgroundImage: businesses.logoImage
                && `url(${businesses.logoImage})`
            }}>
              {/* <div className="admin-dashboard-business-contrast-border">
              <div className="admin-dashboard-business-avatar-online-indicator"></div>
            </div> */}
            </div>
            <div className="admin-dashboard-business-dropdown1-business">
              <div className="admin-dashboard-business-cell-title-business">{businesses.businessName}</div>
              <div className="admin-dashboard-business-supporting-text4-business">{businesses.tax_business_genre.name}</div>
            </div>
          </div>

          <div className="admin-dashboard-business-table-cell20">
            <div className="admin-dashboard-business-location-content">

              <div className="admin-dashboard-business-text36">{businesses.businessTownCity}, {businesses.tax_country.name}</div>
            </div>
          </div>


          <div className="admin-dashboard-business-table-cell30">
            <div className="admin-dashboard-business-avatar" style={{
              backgroundImage: businesses.profileImage
                && `url(${businesses.profileImage})`
            }}>
              {/* <div className="admin-dashboard-business-contrast-border">
              <div className="admin-dashboard-business-avatar-online-indicator"></div>
            </div> */}
            </div>
            <div className="admin-dashboard-business-dropdown1">
              <div className="admin-dashboard-business-cell-title">{businesses.business_user.displayName}</div>
              <div className="admin-dashboard-business-supporting-text4">{businesses.business_user.user_usermeta.userWorkTitle}</div>
            </div>
          </div>

          <div className="admin-dashboard-business-table-cell40">
            <div className="admin-dashboard-business-text47">{businesses.tax_verification.name}</div>
          </div>





          {/* <div className="admin-dashboard-business-table-cell70">


            <img
              className="admin-dashboard-business-table-dots-vertical-icon"
              alt=""
              src={dotsvertical}
            />

          </div> */}


          <div className="admin-dashboard-business-table-cell70">
            {/* <div className="admin-dashboard-business-dropdown" onClick={() => {
             toggleThreeDotsDropdown(index)
           }
           } ref={threeDotsRef}>
             <img
               className="admin-dashboard-business-dots-vertical-icon"
               alt=""
               src={dotsvertical}
             />
           </div>  */}
            <div className="admin-dashboard-business-dropdown" style={{ cursor: "pointer" }} onClick={() => toggleThreeDotsDropdown(index)} ref={threeDotsRef}>
              <div
                className="admin-dashboard-business-dropdown"
              //  onClick={(e) => {
              //    e.stopPropagation(); // Prevent event bubbling
              //    handleThreeDotsClick(index);
              //  }}
              // ref={threeDotsRef}
              >
                <img src={dotsvertical} alt="options" />
              </div>
              {/* {activeThreeDotId === index && (
               <ThreeDotsContactListingComponent id={contact.contact_pk_id} />)} */}
              {activeThreeDotId === index && (
                <div ref={dropdownRef}>
                  <ThreeDotsAdminBusinessComponent
                    // option={'status'}
                    businessId={businesses.id}
                  // onClose={() => {
                  //   setActiveThreeDotId(null);
                  //   setIsClickBlocked(false);
                  // }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      ))
    }
    else {
      content = (

        <NoListingFound path="#" />

      )
      // content = <ErrorShowComponent message1={'Something went wrong..'} />

    }

  } else if (!loadingComplete && isSuccess) {
    content = <SkeletonTableListingLoaderComponent />; // Show loader until loadingComplete is true
  }



  return (
    <div className="admin-dashboard-business-prsopective-buyers">
      <header className="admin-dashboard-business-main">
        <div className="admin-dashboard-business-card-header">
          <div className="admin-dashboard-business-content1">
            <div className="admin-dashboard-business-text-and-supporting-text1">
              <div className="admin-dashboard-business-text-and-badge">
                <a className="admin-dashboard-business-text2">Businesses</a>
                {/* <div className="admin-dashboard-business-badge">
                  <div className="admin-dashboard-business-text3">240 have shown interest</div>
                </div> */}
              </div>
              <div className="admin-dashboard-business-supporting-text1">
                Details of Businesses
              </div>
            </div>
            <div className="admin-dashboard-business-actions1" style={{ position: "relative" }}>

              <div className="admin-dashboard-business-actions2">
                <div className="admin-dashboard-business-input-dropdown">
                  {/* <div className="admin-dashboard-business-input-with-label">
                  <div className="admin-dashboard-business-label">Search</div>
                  <div className="admin-dashboard-business-input">
                    <div className="admin-dashboard-business-content3">
                      <img
                        className="admin-dashboard-business-search-lg-icon"
                        alt=""
                        src={searchlg}
                      />

                      <input
                        className="admin-dashboard-business-text17"
                        placeholder="Magnetom"
                        type="text"
                      />

                      <div className="admin-dashboard-business-supporting-text2">@olivia</div>
                    </div>
                  </div>
                </div> */}
                  <SearchComponentAdmin onSearch={handleSearch} />

                </div>
                <button className="admin-dashboard-business-button4" onClick={toggleDropdown}>
                  <img
                    className="admin-dashboard-business-upload-cloud-02-icon"
                    alt=""
                    src={filterlines}
                  />

                  <div className="admin-dashboard-business-text-padding4" ref={dropdownRef}>
                    <div className="admin-dashboard-business-text18">Filter By{selectedSortOption && `: ${selectedSortOption.label}`}</div>
                    {isDropdownVisible && <FilterAdminBusinessComponent onOptionSelect={handleOptionSelect} />}
                  </div>
                  <img
                    className="admin-dashboard-business-placeholder-icon3"
                    alt=""
                    src="./public/placeholder.svg"
                  />
                </button>
              </div>

              {/* <div className="admin-dashboard-business-button">
                <img
                  className="admin-dashboard-business-placeholder-icon3"
                  alt=""
                  src="../../../placeholder2.svg"
                />

                <div className="admin-dashboard-business-text-padding2">
                  <div className="admin-dashboard-business-text4">Tertiary</div>
                </div>
                <img
                  className="admin-dashboard-business-placeholder-icon3"
                  alt=""
                  src="./public/placeholder2.svg"
                />
              </div>
              <div className="admin-dashboard-business-button1">
                <img
                  className="admin-dashboard-business-placeholder-icon3"
                  alt=""
                  src="./public/placeholder3.svg"
                />

                <div className="admin-dashboard-business-text-padding3">
                  <div className="admin-dashboard-business-text4">Secondary</div>
                </div>
                <img
                  className="admin-dashboard-business-placeholder-icon3"
                  alt=""
                  src="./public/placeholder3.svg"
                />
              </div> */}
              {/* <button className="admin-dashboard-business-button2">
                <img
                  className="admin-dashboard-business-upload-cloud-02-icon"
                  alt=""
                  src={uploadcloud02}
                />

                <div className="admin-dashboard-business-text-padding4">
                  <a className="admin-dashboard-business-text6">Import</a>
                </div>
                <img
                  className="admin-dashboard-business-placeholder-icon3"
                  alt=""
                  src="./public/placeholder.svg"
                />
              </button>
              <button className="admin-dashboard-business-button3">
                <img
                  className="admin-dashboard-business-upload-cloud-02-icon"
                  alt=""
                  src={plus}
                />

                <div className="admin-dashboard-business-text-padding4" >
                  <a className="admin-dashboard-business-text7">Add Contact</a>
                </div>
                <img
                  className="admin-dashboard-business-placeholder-icon3"
                  alt=""
                  src="./public/placeholder1.svg"
                />
              </button> */}

              {/* <SlideOutMenu isVisible={isMenuVisible} onClose={handleCloseMenu} /> */}

            </div>
            {/* <div className="admin-dashboard-business-dropdown">
              <img
                className="admin-dashboard-business-dots-vertical-icon"
                loading="lazy"
                alt=""
                src={dotsvertical}
              />
            </div> */}
          </div>
          <div className="admin-dashboard-business-section-wrapper">
            <div className="admin-dashboard-business-section1">
              <div className="admin-dashboard-business-container1">
                <div className="admin-dashboard-business-filters-bar">
                  <div className="admin-dashboard-business-content17">
                    <div className="admin-dashboard-business-dropdowns">

                      <EasyFilterAdminBusinessComponent
                        handleFilterClick={handleFilterClick}
                        activePostStatus={activePostStatus}
                        publishedCount={data?.totalCountPublished || 0}
                        unpublishedCount={data?.totalCountUnpublished || 0}
                        verifiedCount={data?.totalCountVerified || 0}
                        unverifiedCount={data?.totalCountUnverified || 0}
                      />

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            className="admin-dashboard-business-divider-icon"
            loading="lazy"
            alt=""
            src={divider}
          />
        </div>
        {/* <div className="admin-dashboard-business-filters-bar">
          <div className="admin-dashboard-business-content2">

            <ContactFilterButtonGroup
              handleFilterClick={handleFilterClick}
              activePostStatus={activePostStatus}
            />

            
          </div>
        </div> */}
      </header>


      <form className="admin-dashboard-business-table">
        {/* <div className="admin-dashboard-business-card-header1">
          <div className="admin-dashboard-business-content4">
            <div className="admin-dashboard-business-text-and-supporting-text2">
              <div className="admin-dashboard-business-text-and-badge1">
                <div className="admin-dashboard-business-table-header-title">Viewed listing</div>
                <div className="admin-dashboard-business-badge1">
                  <div className="admin-dashboard-business-text19">100 users</div>
                </div>
              </div>
              <div className="admin-dashboard-business-supporting-text3">
                Manage your team members and their account permissions here.
              </div>
            </div>
            <div className="admin-dashboard-business-actions3">
              <div className="admin-dashboard-business-button5">
                <img
                  className="admin-dashboard-business-placeholder-icon3"
                  alt=""
                  src="./public/placeholder2.svg"
                />

                <div className="admin-dashboard-business-text-padding7">
                  <div className="admin-dashboard-business-text20">Tertiary</div>
                </div>
                <img
                  className="admin-dashboard-business-placeholder-icon3"
                  alt=""
                  src="./public/placeholder2.svg"
                />
              </div>
              <div className="admin-dashboard-business-button6">
                <img
                  className="admin-dashboard-business-placeholder-icon3"
                  alt=""
                  src="./public/placeholder3.svg"
                />

                <div className="admin-dashboard-business-text-padding7">
                  <div className="admin-dashboard-business-text21">Secondary</div>
                </div>
                <img
                  className="admin-dashboard-business-placeholder-icon3"
                  alt=""
                  src="./public/placeholder3.svg"
                />
              </div>
              <div className="admin-dashboard-business-button7">
                <img
                  className="admin-dashboard-business-placeholder-icon3"
                  alt=""
                  src="./public/placeholder.svg"
                />

                <div className="admin-dashboard-business-text-padding7">
                  <div className="admin-dashboard-business-text22">Secondary</div>
                </div>
                <img
                  className="admin-dashboard-business-placeholder-icon3"
                  alt=""
                  src="./public/placeholder.svg"
                />
              </div>
              <div className="admin-dashboard-business-button8">
                <img
                  className="admin-dashboard-business-placeholder-icon3"
                  alt=""
                  src="./public/placeholder1.svg"
                />

                <div className="admin-dashboard-business-text-padding7">
                  <div className="admin-dashboard-business-text23">Primary</div>
                </div>
                <img
                  className="admin-dashboard-business-placeholder-icon3"
                  alt=""
                  src="./public/placeholder1.svg"
                />
              </div>
            </div>
            <div className="admin-dashboard-business-dropdown">
              <img
                className="admin-dashboard-business-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
            </div>
          </div>
          <img className="admin-dashboard-business-divider-icon" alt="" src="./public/divider.svg" />
        </div> */}

        {/* Table */}
        <div className="admin-dashboard-business-table-row">

{/* Business Name */}



<div className="admin-dashboard-business-table-header-cell1">
  {/* <input className="admin-dashboard-business-checkbox-wrapper" type="checkbox" /> */}
  <div className="admin-dashboard-business-table-header">
    <a className="admin-dashboard-business-column-title">Business</a>
    {/* <img
      className="admin-dashboard-business-arrow-down-icon1"
      alt=""
      src={arrowdown}
    /> */}
  </div>
</div>


{/* Location  */}

<div className="admin-dashboard-business-table-header-cell2">
  <div className="admin-dashboard-business-table-header">
    <a className="admin-dashboard-business-column-title">Location</a>
    <img
      className="admin-dashboard-business-arrow-down-icon1"
      alt=""
      src={arrowdown}
    />

  </div>
</div>





{/* Business owner */}

<div className="admin-dashboard-business-table-header-cell3">
  <div className="admin-dashboard-business-table-header">
    <a className="admin-dashboard-business-column-title">Business Owner</a>

  </div>
</div>

{/* Status */}

<div className="admin-dashboard-business-table-header-cell4">
  <div className="admin-dashboard-business-table-header">
    <a className="admin-dashboard-business-column-title">Status</a>

  </div>
</div>



{/* Action  */}

<div className="admin-dashboard-business-table-header-cell7">
  <div className="admin-dashboard-business-table-header2">
    <div className="admin-dashboard-business-action-column-title">Action</div>
    <img
      className="admin-dashboard-business-arrow-down-icon2"
      alt=""
      src={arrowdown}
    />
  </div>
</div>

</div>
        <div className="admin-dashboard-business-table-content">

         


          {/*
           */}
          {content}


        </div>

        {/* Load More Button */}
        {showLoadMore && (
          <div className="admin-dashboard-frame-wrapper44">
            <div className="admin-dashboard-frame-parent72">
              <div className="admin-dashboard-buttonsbutton-wrapper1">

                <button className='contact-buttonsbutton-wrapper1-child'
                  onClick={handleLoadMore}
                  disabled={isFetching}
                >
                  <div className="admin-dashboard-buttonsbutton28">
                    <img
                      className="admin-dashboard-arrow-narrow-left-icon"
                      alt=""
                      src={arrowdown1}
                    />
                    <div className="admin-dashboard-search-input">
                      <div className="admin-dashboard-text146">{isFetching ? 'Loading...' : 'Load More'}</div>
                    </div>
                  </div>
                </button>

              </div>
            </div>
          </div>
        )}

        {/* <div className="admin-dashboard-business-pagination">
        <div className="admin-dashboard-business-pagination-wrapper">
          <div className="admin-dashboard-business-button-wrap">
            <button className="admin-dashboard-business-buttonsbutton2">
              <img
                className="admin-dashboard-business-upload-cloud-02-icon"
                alt=""
                src={arrowleft}
              />

              <div className="admin-dashboard-business-text-padding4">
                <div className="admin-dashboard-business-text56">Previous</div>
              </div>
              <img
                className="admin-dashboard-business-placeholder-icon3"
                alt=""
                src="./public/placeholder.svg"
              />
            </button>
          </div>
        </div>
        <div className="admin-dashboard-business-pagination-numbers">
          <div className="admin-dashboard-business-pagination-number-base">
            <div className="admin-dashboard-business-content5">
              <div className="admin-dashboard-business-number">1</div>
            </div>
          </div>
          <div className="admin-dashboard-business-pagination-number-base1">
            <div className="admin-dashboard-business-content6">
              <div className="admin-dashboard-business-number1">2</div>
            </div>
          </div>
          <div className="admin-dashboard-business-pagination-number-base2">
            <div className="admin-dashboard-business-content7">
              <div className="admin-dashboard-business-number2">3</div>
            </div>
          </div>
          <div className="admin-dashboard-business-pagination-number-base1">
            <div className="admin-dashboard-business-content8">
              <div className="admin-dashboard-business-number3">...</div>
            </div>
          </div>
          <div className="admin-dashboard-business-pagination-number-base1">
            <div className="admin-dashboard-business-content6">
              <div className="admin-dashboard-business-number1">8</div>
            </div>
          </div>
          <div className="admin-dashboard-business-pagination-number-base1">
            <div className="admin-dashboard-business-content6">
              <div className="admin-dashboard-business-number1">9</div>
            </div>
          </div>
          <div className="admin-dashboard-business-pagination-number-base2">
            <div className="admin-dashboard-business-content11">
              <div className="admin-dashboard-business-number6">10</div>
            </div>
          </div>
        </div>
        <div className="admin-dashboard-business-pagination-wrapper">
          <div className="admin-dashboard-business-button-wrap1">
            <button className="admin-dashboard-business-buttonsbutton3">
              <img
                className="admin-dashboard-business-placeholder-icon3"
                alt=""
                src="./public/placeholder.svg"
              />

              <div className="admin-dashboard-business-text-padding4">
                <div className="admin-dashboard-business-text57">Next</div>
              </div>
              <img
                className="admin-dashboard-business-upload-cloud-02-icon"
                alt=""
                src={arrowright}
              />
            </button>
          </div>
        </div>
      </div> */}
      </form>
    </div>
  )
}
