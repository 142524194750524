import { SalesIQ } from "../screens/SalesIQ/SalesIQ";
import { Funnel } from "../screens/Funnel/Funnel";
import { DemandCenter } from "../screens/DemandCenter/DemandCenter";
import { SalesIQDeviceDetailPage } from "../screens/SalesIQDetailsPage/SalesIQDeviceDetailPage/SalesIQDeviceDetailPage";
import { SalesIQSpareDetailPage } from "../screens/SalesIQDetailsPage/SalesIQSpareDetailPage/SalesIQSpareDetailPage";
import { SalesIQServiceDetailPage } from "../screens/SalesIQDetailsPage/SalesIQServiceDetailPage/SalesIQServiceDetailPage";
import { SalesIQSoftwareDetailPage } from "../screens/SalesIQDetailsPage/SalesIQSoftwareDetailPage/SalesIQSoftwareDetailPage";
import { SalesIQJobDetailPage } from "../screens/SalesIQDetailsPage/SalesIQJobDetailPage/SalesIQJobDetailPage";
import { FunnelDeviceDetailPage } from "../screens/FunnelDetailsPage/FunnelDeviceDetailPage/FunnelDeviceDetailPage";
import { FunnelSpareDetailPage } from "../screens/FunnelDetailsPage/FunnelSpareDetailPage/FunnelSpareDetailPage";
import { BeaconWantedDeviceDetailPage } from "../screens/BeaconWantedDetailPage/BeaconWantedDeviceDetailPage/BeaconWantedDeviceDetailPage";
import { BeaconWantedSpareDetailPage } from "../screens/BeaconWantedDetailPage/BeaconWantedSpareDetailPage/BeaconWantedSpareDetailPage";
import { BeaconWantedServiceDetailPage } from "../screens/BeaconWantedDetailPage/BeaconWantedServiceDetailPage/BeaconWantedServiceDetailPage";
import { BeaconWantedSoftwareDetailPage } from "../screens/BeaconWantedDetailPage/BeaconWantedSoftwareDetailPage/BeaconWantedSoftwareDetailPage";
import { FunnelServiceDetailPage } from "../screens/FunnelDetailsPage/FunnelServiceDetailPage/FunnelServiceDetailPage";
import { FunnelSoftwareDetailPage } from "../screens/FunnelDetailsPage/FunnelSoftwareDetailPage/FunnelSoftwareDetailPage";
import { FunnelJobDetailPage } from "../screens/FunnelDetailsPage/FunnelJobDetailPage/FunnelJobDetailPage";




export const salesIqRoutes = [

  {
    path: "/sales-iq",
    element: <SalesIQ />
  },
  {
    path: "/sales-iq/device/:id/*",
    element: <SalesIQDeviceDetailPage />,
  },
  {
    path: "/sales-iq/spare/:id/*",
    element: <SalesIQSpareDetailPage />,
  },
  {
    path: "/sales-iq/service/:id/*",
    element: <SalesIQServiceDetailPage />,
  },
  {
    path: "/sales-iq/software/:id/*",
    element: <SalesIQSoftwareDetailPage />,
  },
  {
    path: "/sales-iq/job/:id/*",
    element: <SalesIQJobDetailPage />,
  },
  {
    path: "/funnel",
    element: <Funnel />,
  },
  {
    path: "/demand-center",
    element: <DemandCenter />,
  },
  {
    path: "/funnel-device-detail-page",
    element: <FunnelDeviceDetailPage />,
  },
  {
    path: "/funnel-spare-detail-page",
    element: <FunnelSpareDetailPage />,
  },
  {
    path: "/funnel-service-detail-page",
    element: <FunnelServiceDetailPage />,
  },
  {
    path: "/funnel-software-detail-page",
    element: <FunnelSoftwareDetailPage />,
  },
  {
    path: "/funnel-job-detail-page",
    element: <FunnelJobDetailPage />,
  },
  {
    path: "/beacon-wanted-device-detail-page",
    element: <BeaconWantedDeviceDetailPage />,
  },
  {
    path: "/beacon-wanted-spare-detail-page",
    element: <BeaconWantedSpareDetailPage />,
  },
  {
    path: "/beacon-wanted-service-detail-page",
    element: <BeaconWantedServiceDetailPage />,
  },
  {
    path: "/beacon-wanted-software-detail-page",
    element: <BeaconWantedSoftwareDetailPage />,
  },

];
