import { encryptData } from "./AESHelper";




export function objectToReviewFormData(obj, listingId, currentUsermeta) {

  // const formData = new FormData();

  // Destructure to remove file fields from obj
  const { id, ...values } = obj;

  values.userId = currentUsermeta.userId;

  values.listingId = parseInt(listingId);

  // values.businessId = currentUsermeta.userBusinessId;

  console.log(values);
  // Encrypt the object without the document
  const encryptedValue = encryptData(values); // Custom encryptData function

  // // Append the encrypted text data
  // formData.append("encryptedData", encryptedValue);

  return encryptedValue;
}


