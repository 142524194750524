import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  deviceCategory:'',
  oem:'',
  partName:'',
  ageOfDevice:'',
  availability:'',
  partNumber:'',
  clinicalApplications:'',
  purposeUse:'',
  statusCondition:'',
  physicalLocation:'',
}

const adminEditWantedSpareSlice = createSlice({
  name: "adminEditWantedSpare",
  initialState,
  reducers: {
    fillAdminEditWantedSpareForm(state, action) {
     return action.payload;
    },
    updateAdminEditWantedSpareField(state, action) {
      const {field, value} = action.payload
      if (value !== undefined) { 
        state[field] = value; 
      }
    },
    clearAdminEditWantedSpareForm() {
      return initialState;
    }
  },
});

export const {fillAdminEditWantedSpareForm, updateAdminEditWantedSpareField,clearAdminEditWantedSpareForm } = adminEditWantedSpareSlice.actions

export default adminEditWantedSpareSlice.reducer

