import React from 'react';
import "./NoReviews.css";

import anton from "../../../icons/Gerator/dashboard/Anton.png";

export const NoReviews = () => {
  return (
    <div className="no-messages-no-messages">
      <section className="no-messages-empty-state">
        <div className="no-messages-empty-state1">
          <div className="no-messages-content">
            <img
              className="no-messages-bifocal-optometry-measurement-icon"
              loading="lazy"
              alt=""
              src={anton}
            />

            <div className="no-messages-text-and-supporting-text">
              <div className="no-messages-title">No reviews yet</div>
              {/* <div className="no-messages-supporting-text">
                Add a review!💬
              </div> */}
            </div>
          </div>
          {/* <div className="no-messages-actions">
            <button className="no-messages-buttonsbutton">
              <img
                className="no-messages-placeholder-icon"
                alt=""
                src={placeholder}
              />

              <div className="no-messages-text-padding">
                <div className="no-messages-text">Clear search</div>
              </div>
              <img
                className="no-messages-placeholder-icon"
                alt=""
                src={placeholder}
              />
            </button>
            <button className="no-messages-buttonsbutton1"  >
              <Link to={path} className='no-messages-button-link'>
                <img className="no-messages-plus-icon" alt="" src={plus} />

                <div className="no-messages-text-padding">
                  <div className="no-messages-text1">Add {pathName}</div>
                </div>
                <img
                  className="no-messages-placeholder-icon"
                  alt=""
                  src={placeholder1}
                />
              </Link>
            </button>
          </div> */}
        </div>
      </section>
    </div>
  )
}


