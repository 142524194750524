import React from 'react';
import "./FooterAddForms.css"
import gerator_logo from '../../../icons/Gerator/dashboard/gerator10-2@2x.png';
import { Link } from "react-router-dom";



export const FooterAddForms = () => {
  return (


    <footer className="add-form-footer1">

      <div className="add-form-page-footer-divider">
        <img
          className="add-form-divider-icon10"
          loading="lazy"
          alt=""
          src="add_form/divider.svg"
        />
      </div>

      <div className="add-form-container1">
        <div className="add-form-content51">
          <div className="add-form-footer-text">
            © Gerator Asia LLP. All rights reserved.
          </div>
          <div className="add-form-logo1">
            <div className="add-form-footer-logo-container">
              <div className="add-form-footer-brand">
                <div className="add-form-footer-text">All prices are in USD</div>
              </div>
                <Link to={"/dashboard"}>
              <div className="add-form-logomark">
                <div className="add-form-logomark1">
                  <img
                    className="add-form-gerator-10-2-icon"
                    loading="lazy"
                    alt=""
                    src={gerator_logo}
                  />
                </div>
              </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

