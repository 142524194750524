import React from 'react';
import "./ThreeDotsListingComponent.css";
import divider from '../../icons/Gerator/dashboard/divider.svg';
import { Link, useNavigate } from 'react-router-dom';
import { useAddFunnelDeviceMutation } from '../../api/Funnel/FunnelDeviceAPI';
import { useAddFunnelSpareMutation } from '../../api/Funnel/FunnelSpareAPI';
import { useAddFunnelSoftwareMutation } from '../../api/Funnel/FunnelSoftwareAPI';
import { useAddFunnelWantedSoftwareMutation } from '../../api/Funnel/FunnelWantedSoftwareAPI';
import { useAddFunnelWantedServiceMutation } from '../../api/Funnel/FunnelWantedServiceAPI';
import { useAddFunnelWantedSpareMutation } from '../../api/Funnel/FunnelWantedSpareAPI';
import { useAddFunnelWantedDeviceMutation } from '../../api/Funnel/FunnelWantedDeviceAPI';


export const ThreeDotsDemandCenterComponent = ({ option, postId, onClose, postType, url }) => {
  // const handleOptionClick = (option) => {
  //   onOptionSelect(option); // Call the function from the parent with the selected option
  // };

  const navigate = useNavigate();

  // Radar query delete
  const [addToFunnelWantedDevice] = useAddFunnelWantedDeviceMutation();

  const [addToFunnelWantedSpare] = useAddFunnelWantedSpareMutation();

  const [addToFunnelWantedService] = useAddFunnelWantedServiceMutation();

  const [addToFunnelWantedSoftware] = useAddFunnelWantedSoftwareMutation();


  const handleMessage = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      navigate(url);
    } catch (error) {
      console.error('Error navigating ' + error);
    }
  };

  const handleAddToFunnel = async (e) => {
    let response;
    e.preventDefault();
    e.stopPropagation();

    try {
      if (!postId) {
        console.error('No post ID provided');
        return;
      }

      switch (postType) {
        case 'wanted-device':
          response = await addToFunnelWantedDevice({ id: postId }).unwrap();
          break;
        case 'wanted-service':
          response = await addToFunnelWantedService({ id: postId }).unwrap();
          break;
        case 'wanted-software':
          response = await addToFunnelWantedSoftware({ id: postId }).unwrap();
          break;
        case 'wanted-spare':
          response = await addToFunnelWantedSpare({ id: postId }).unwrap();
          break;
        default:
          break;
      }

      // console.log('Updating status for post:', postId);
      // const response = await setToWantedDeviceArchive({ id: postId }).unwrap();
      // console.log('Update response:', response);

      if (response?.status) {
        onClose();
        navigate(url);
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };



  const handleItemClick = (e, action) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('Action clicked:', action);

    switch (action) {
      case 'message':
        handleMessage(e);
        break;
      case 'funnel':
        handleAddToFunnel(e);
        break;
      // Add other cases as needed
      default:
      // handleRejectUpdate(e);
    }
  };



  // Reusable menu item component with proper event handling
  const MenuItem = ({ onClick, text, to, action, disabled }) => (
    <div
      className={`three-dots-filter-dropdown-dropdown-list-item ${disabled ? disabled : ""}`}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (onClick) onClick(e);
        if (action) handleItemClick(e, action);
      }}

    >
      <div className="three-dots-filter-dropdown-content48">
        <div className="three-dots-filter-dropdown-icon-and-text">
          {to ? (
            <Link
              className="three-dots-filter-dropdown-text247"
              to={to}
              onClick={(e) => e.stopPropagation()}
            >
              {text}
            </Link>
          ) : (
            <div className="three-dots-filter-dropdown-text247">{text}</div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="three-dots-filter-dropdown-three-dots-filter-all-options-fixed">
      <section className="three-dots-filter-dropdown-menu-items">
        {option === false ?
          <>
            <MenuItem text="Add to Funnel" action="funnel" />
            <MenuItem text="Message" action="message" />
          </> :
          <>
            <MenuItem text="Added to Funnel" disabled={'three-dots-filter-dropdown-disabled'} />
            <MenuItem text="Message" action="message" />
          </>
        }
        {/* {option === 'published' && (
          <>
          </>
        )} */}
        {/* <div className="three-dots-filter-dropdown-dropdown-list-item" >
          <div className="three-dots-filter-dropdown-content48">
            <div className="three-dots-filter-dropdown-icon-and-text">
              <Link className="three-dots-filter-dropdown-text247" to={`/edit-${url}`}>Edit</Link>
            </div>
          </div>
        </div>
        <div className="three-dots-filter-dropdown-dropdown-list-item" >
          <div className="three-dots-filter-dropdown-content48">
            <div className="three-dots-filter-dropdown-icon-and-text">
              <Link className="three-dots-filter-dropdown-text247" to={`/archive-${url}`}>Archive</Link>
            </div>
          </div>
        </div>
        <div className="three-dots-filter-dropdown-dropdown-list-item" >
          <div className="three-dots-filter-dropdown-content48">
            <div className="three-dots-filter-dropdown-icon-and-text">
              <Link className="three-dots-filter-dropdown-text247" >Republish</Link>
            </div>
          </div>
        </div> */}
        {/* <div className="three-dots-filter-dropdown-dropdown-list-item" >
          <div className="three-dots-filter-dropdown-content48">
            <div className="three-dots-filter-dropdown-icon-and-text">
              <div className="three-dots-filter-dropdown-text247">Price (high to low)</div>
            </div>
          </div>
        </div> */}
      </section>
    </div>
  )
}