import React, { useState } from "react";
import "../../Sidebar/Sidebar.css";
import arrownarrowleft from '../../../icons/Gerator/dashboard/arrownarrowleft.svg';
import activity from '../../../icons/Gerator/dashboard/activity.svg';
import chevrondown from '../../../icons/Gerator/dashboard/chevrondown.svg';
import notificationbox from '../../../icons/Gerator/dashboard/notificationbox.svg';
import barlinechart from '../../../icons/Gerator/dashboard/barlinechart.svg';
import star01 from '../../../icons/Gerator/dashboard/star01.svg';
import clockfastforward from '../../../icons/Gerator/dashboard/clockfastforward.svg';
import usersquare from '../../../icons/Gerator/dashboard/usersquare.svg';
import settings03 from '../../../icons/Gerator/dashboard/settings03.svg';
import logout01 from '../../../icons/Gerator/dashboard/logout01.svg';
// import xclose from '../../icons/AGerator/dashboard/xclose.svg';
import { Link } from "react-router-dom";
import { useEffect } from "react";

import pluscircle_gray from '../../../icons/Gerator/dashboard/pluscircle_gray.svg';
import address from "../../../icons/Gerator/dashboard/address.svg";
import business from "../../../icons/Gerator/dashboard/business.svg";
import plan from "../../../icons/Gerator/dashboard/plan.svg";
import TruncatedTextTooltip from "../../TruncatedTextTooltip/TruncatedTextTooltip";

export const SideBarSubSettings = ({ userData, isSubSideBarVisible, setSubSideBarVisible }) => {

  // const [userData, setUserData] = useState({ displayName: "Demo", email: "demo@gerator.com" })

  // useEffect(() => {
  //   if (!userData) {
  //     setUser({ displayName: "Demo", email: "demo@gerator.com" })
  //   }
  // },)


  return (
    <div className="sidebar-sub-nav">
      <div className="sidebar-content5">
        <div className="sidebar-sub-nav-header">
          <div className="sidebar-sub-nav-title"><Link to={"/dashboard"}>Dashboard</Link></div>
          <img
            className="sidebar-arrow-narrow-left-icon" onClick={() => setSubSideBarVisible(!isSubSideBarVisible)}
            alt=""
            src={arrownarrowleft}
          />
        </div>
        <div className="sidebar-navigation4">
          <div className="sidebar-nav-item-base3">
            <Link to={"/add-device"} style={{color:"#475467"}}>
            <div className="sidebar-content6">
              <img className="sidebar-dot-icon" alt="" src="/dashboard/-dot.svg" />

              <img
                className="sidebar-arrow-narrow-left-icon"
                alt=""
                src={pluscircle_gray}
              />

              <div className="sidebar-text6">Add Device</div>
            </div>
            </Link>
            {/* <div className="sidebar-badge1">
              <div className="sidebar-text1">10</div>
            </div>
            <img
              className="sidebar-chevron-down-icon"
              alt=""
              src={chevrondown}
            /> */}
          </div>
          <div className="sidebar-nav-item-base4">
          <Link to={"/add-spare"} style={{color:"#475467"}}>
            <div className="sidebar-content7">
              <img className="sidebar-dot-icon" alt="" src="/dashboard/-dot.svg" />

              <img
                className="sidebar-arrow-narrow-left-icon"
                alt=""
                src={pluscircle_gray}
              />

              <div className="sidebar-text8">Add Spare</div>
            </div>
            </Link>
            {/* <div className="sidebar-badge4">
              <div className="sidebar-badge-count">10</div>
            </div>
            <img
              className="sidebar-chevron-down-icon"
              alt=""
              src={chevrondown}
            /> */}
          </div>
          <div className="sidebar-nav-item-base4">
          <Link to={"/add-service"} style={{color:"#475467"}}>
            <div className="sidebar-content8">
              <img className="sidebar-dot-icon" alt="" src="/dashboard/-dot.svg" />

              <img
                className="sidebar-arrow-narrow-left-icon"
                alt=""
                src={pluscircle_gray}
              />

              <div className="sidebar-text8">Add Service</div>
            </div>
            </Link>
            {/* <div className="sidebar-badge">
              <div className="sidebar-text1">10</div>
            </div>
            <img
              className="sidebar-chevron-down-icon"
              alt=""
              src={chevrondown}
            /> */}
          </div>
          <div className="sidebar-nav-item-base4">
          <Link to={"/add-software"} style={{color:"#475467"}}>
            <div className="sidebar-content8">
              <img className="sidebar-dot-icon" alt="" src="/dashboard/-dot.svg" />

              <img
                className="sidebar-arrow-narrow-left-icon"
                alt=""
                src={pluscircle_gray}
              />

              <div className="sidebar-text8">Add Software</div>
            </div>
            </Link>
            {/* <div className="sidebar-badge">
              <div className="sidebar-text1">10</div>
            </div>
            <img
              className="sidebar-chevron-down-icon"
              alt=""
              src={chevrondown}
            /> */}
          </div>
          <div className="sidebar-nav-item-base4">
          <Link to={"/add-job"} style={{color:"#475467"}}>
            <div className="sidebar-content8">
              <img className="sidebar-dot-icon" alt="" src="/dashboard/-dot.svg" />

              <img
                className="sidebar-arrow-narrow-left-icon"
                alt=""
                src={pluscircle_gray}
              />

              <div className="sidebar-text8">Add Job</div>
            </div>
            </Link>
            {/* <div className="sidebar-badge">
              <div className="sidebar-text1">10</div>
            </div>
            <img
              className="sidebar-chevron-down-icon"
              alt=""
              src={chevrondown}
            /> */}
          </div>
          <div className="sidebar-nav-item-base4">
          <Link to={"/add-wanted-device"} style={{color:"#475467"}}>
            <div className="sidebar-content8">
              <img className="sidebar-dot-icon" alt="" src="/dashboard/-dot.svg" />

              <img
                className="sidebar-arrow-narrow-left-icon"
                alt=""
                src={pluscircle_gray}
              />

              <div className="sidebar-text8">Add Device RFQ</div>
            </div>
            </Link>
            {/* <div className="sidebar-badge">
              <div className="sidebar-text1">10</div>
            </div>
            <img
              className="sidebar-chevron-down-icon"
              alt=""
              src={chevrondown}
            /> */}
          </div>

          <div className="sidebar-nav-item-base4">
          <Link to={"/add-wanted-spare"} style={{color:"#475467"}}>
            <div className="sidebar-content12">
              <img className="sidebar-dot-icon" alt="" src="/dashboard/-dot.svg" />

              <img
                className="sidebar-arrow-narrow-left-icon"
                alt=""
                src={pluscircle_gray}
              />

              <div className="sidebar-text17">Add Spare RFQ</div>
            </div>
            </Link>
            {/* <div className="sidebar-badge">
              <div className="sidebar-text1">10</div>
            </div>
            <img
              className="sidebar-chevron-down-icon"
              alt=""
              src={chevrondown}
            /> */}
          </div>
              

          <div className="sidebar-nav-item-base4">
          <Link to={"/add-wanted-service"} style={{color:"#475467"}}>
            <div className="sidebar-content12">
              <img className="sidebar-dot-icon" alt="" src="/dashboard/-dot.svg" />

              <img
                className="sidebar-arrow-narrow-left-icon"
                alt=""
                src={pluscircle_gray}
              />

              <div className="sidebar-text17">Add Service RFP</div>
            </div>
            </Link>
            {/* <div className="sidebar-badge">
              <div className="sidebar-text1">10</div>
            </div>
            <img
              className="sidebar-chevron-down-icon"
              alt=""
              src={chevrondown}
            /> */}
          </div>

          <div className="sidebar-nav-item-base4">
          <Link to={"/add-wanted-software"} style={{color:"#475467"}}>
            <div className="sidebar-content12">
              <img className="sidebar-dot-icon" alt="" src="/dashboard/-dot.svg" />

              <img
                className="sidebar-arrow-narrow-left-icon"
                alt=""
                src={pluscircle_gray}
              />

              <div className="sidebar-text17">Add Software RFQ</div>
            </div>
            </Link>
            {/* <div className="sidebar-badge">
              <div className="sidebar-text1">10</div>
            </div>
            <img
              className="sidebar-chevron-down-icon"
              alt=""
              src={chevrondown}
            /> */}
          </div>
        </div>

      </div>
      <div className="sidebar-account">

        
        <Link to={"/dashboard/settings/profile"}>
        <div className="sidebar-price-parent">
          <div className="sidebar-title">
            {/* {userData?.displayName ? userData.displayName : "Demo User"} */}
            <TruncatedTextTooltip 
                                  text={userData?.displayName ? userData.displayName : "Demo User"}


              />

          </div>
          <div className="sidebar-supporting-text">
            {/* {userData?.email ? userData.email : "demo@gerator.com"} */}
            <TruncatedTextTooltip 
                                  text={userData?.email ? userData.email : "demo@gerator.com"}

              />
            </div>
        </div>
        </Link>
      
        <Link to={"/dashboard/settings/profile"}>
        <div className="sidebar-buttonsbutton">
          <img
            className="sidebar-search-lg-icon"
            alt=""
            src={logout01}
          />
        </div>
        </Link>
      </div>
    </div>
  )
}