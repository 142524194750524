import React, { useEffect, useRef, useState } from 'react';
import "./DetailedBusiness.css"
// import { Navbar } from '../../components/Navbar/Navbar';
import iamge_wrap_2x from '../../../icons/Gerator/business_page/image-wrap@2x.png';
import logo_business_7_2x from '../../../icons/Gerator/business_page/8595f4b711e503bc72fe396e5043e0c2-7@2x.png';
import f8595f4b711e503bc72fe396e5043e0c2_7_1_2x from '../../../icons/Gerator/business_page/8595f4b711e503bc72fe396e5043e0c2-7-1@2x.png';
import users01 from '../../../icons/Gerator/detailed_listing/users01.svg';
import chevronright from '../../../icons/Gerator/business_page/chevronright.svg';
import homeline from '../../../icons/Gerator/business_page/homeline.svg';
import divider1 from '../../../icons/Gerator/business_page/divider1.svg';
import markerpin02 from '../../../icons/Gerator/business_page/markerpin02.svg';
import bookmarkcheck from '../../../icons/Gerator/business_page/bookmarkcheck.svg';
import placeholder2 from '../../../icons/Gerator/business_page/placeholder2.svg';
import radar_1_1_2x from '../../../icons/Gerator/business_page/radar-1-1@2x.png';
import shield03 from '../../../icons/Gerator/business_page/shield03.svg';
import deal_2_1_2x from '../../../icons/Gerator/business_page/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/business_page/magnifier-1@2x.png';
import share_1_2x from '../../../icons/Gerator/business_page/share-1@2x.png';
import placeholder from '../../../icons/Gerator/business_page/placeholder.svg';
import dotshorizontal from '../../../icons/Gerator/business_page/dotshorizontal.svg';
import placeholder3 from '../../../icons/Gerator/business_page/placeholder3.svg';
import divider2 from '../../../icons/Gerator/business_page/divider2.svg';
import divider_1 from '../../../icons/Gerator/business_page/divider-1.svg';
import placeholder1 from '../../../icons/Gerator/business_page/placeholder1.svg';
import arrowupright from '../../../icons/Gerator/business_page/arrowupright.svg';
import divider_7 from '../../../icons/Gerator/business_page/divider-7.svg';
import rectangle_43_2x from '../../../icons/Gerator/business_page/rectangle-43@2x.png';
import searchlg from '../../../icons/Gerator/business_page/searchlg.svg';
import filterlines from '../../../icons/Gerator/business_page/filterlines.svg';
import star_background from '../../../icons/Gerator/business_page/star-background.svg';
import divider_9 from '../../../icons/Gerator/business_page/divider-9.svg';
import star_icon from '../../../icons/Gerator/business_page/star-icon.svg';
import group_78_2x from '../../../icons/Gerator/business_page/group-78@2x.png';
import messagedotscircle from '../../../icons/Gerator/business_page/messagedotscircle.svg';
import login04 from '../../../icons/Gerator/business_page/login04.svg';
import rectangle_95_2x from '../../../icons/Gerator/business_page/rectangle-95@2x.png';
import markerpin02_1 from '../../../icons/Gerator/business_page/markerpin02-1.svg';
import calendar from '../../../icons/Gerator/business_page/calendar.svg';
import divider_11 from '../../../icons/Gerator/business_page/divider-11.svg';
import group_58_2x from '../../../icons/Gerator/business_page/group-58@2x.png';
import icon_1 from '../../../icons/Gerator/business_page/icon-1.svg';
import arrowleft from '../../../icons/Gerator/business_page/arrowleft.svg';
import arrowright from '../../../icons/Gerator/business_page/arrowright.svg';
import gerator04_1_2x from '../../../icons/Gerator/business_page/gerator04-1@2x.png';
import facebook_1_2_2x from '../../../icons/Gerator/business_page/facebook-1-2@2x.png';
import linkedinlogo_2_2x from '../../../icons/Gerator/business_page/linkedinlogo-2@2x.png';
import instagram_1_3_2x from '../../../icons/Gerator/business_page/instagram-1-3@2x.png';
import youtube_1_1_2x from '../../../icons/Gerator/business_page/youtube-1-1@2x.png';
import divider_27 from '../../../icons/Gerator/business_page/divider-27.svg';
import gerator10_2_2x from '../../../icons/Gerator/business_page/gerator10-2@2x.png';
import help_icon from '../../../icons/Gerator/business_page/help-icon.svg';
import vimeo_1_2_2x from '../../../icons/Gerator/business_page/vimeo-1-2@2x.png';
import { Navbar1 } from '../../../components/Navbar1/Navbar1';
import { Loader } from '../../../components/Loader';
import { decryptErrorData } from '../../../helper/AESHelper';
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyFindGetBusinessQuery } from '../../../api/BusinessAPI';
import { onAuthStateChanged } from 'firebase/auth';
import { firestore, doc, getDoc, auth, onAuthStateChanged } from '../../Login/FirebaseClient';
import { Helmet } from 'react-helmet-async';
import target_04 from "../../../icons/Gerator/dashboard/target_04.png"
import { useAddContactMutation, useDeleteContactMutation } from '../../../api/ContactAPI';
import { useAddRadarBusinessMutation, useDeleteRadarBusinessMutation } from '../../../api/Radar/RadarBusinessAPI';
import { useLazyGetProfileImageLinkQuery } from '../../../api/Login&RegisterAPI';

import follow from "../../../icons/Gerator/detailed_listing/follow.svg";

import ShareThisMetaData from '../../../components/ShareThisMetaData/ShareThisMetaData';


import { InlineShareButtons } from 'sharethis-reactjs';

import { InlineFollowButtons } from 'sharethis-reactjs';
import ReviewBusinessView from '../../../components/review/ReviewViewComponent/ReviewBusinessView';
import AddReviewBusinessForm from '../../../components/review/ReviewFormComponent/AddReviewBusinessForm';
import { ReportAbuseSlideOut } from '../../../components/ReportAbuseSlideOutForm/ReportAbuseSlideOutForm';


export const DetailedBusiness = () => {


   const [isFormOpen, setIsFormOpen] = useState(false);

  const toggleForm = () => {
    console.log('Toggling form:', !isFormOpen); // Debug log
    setIsFormOpen(!isFormOpen);
  };

  const [activeSection, setActiveSection] = useState(null); // "share", "follow", or null

  const handleShareButtonClick = () => {
    setActiveSection((prevState) => (prevState === 'share' ? null : 'share')); // Toggle or close
  };

  const handleFollowButtonClick = () => {
    setActiveSection((prevState) => (prevState === 'follow' ? null : 'follow')); // Toggle or close
  };

  const { id } = useParams();

  let content;
  const formikRef = useRef(null);

  const [currentUser, setCurrentUser] = useState("");
  const navigate = useNavigate();
  const [profileImageLink, setProfileImageLink] = useState("");
  const [currentUsermeta, setCurrentUsermeta] = useState("");


  const [triggerNewProfileImageLink] = useLazyGetProfileImageLinkQuery();

  const [triggerGetBusiness, { data, isSuccess, error, isLoading, isError }] = useLazyFindGetBusinessQuery();


  // Radar query delete
  const [deleteFromRadar] = useDeleteRadarBusinessMutation();

  // Radar query delete
  const [addToRadar] = useAddRadarBusinessMutation();



  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {

        if (user) {
          // Get user to check wizardStep
          const userDocRef = doc(firestore, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);
          setCurrentUser(user);

          if (userDocSnapshot.exists()) {
            const usermeta = userDocSnapshot.data(); // Default to 1 if not set

            if (usermeta.userStatus == 2) {
              navigate('/account-disabled');
              return;
            }

            if (usermeta.userType == 5) {
              navigate('/access-denied');
              return;
            }

            if (usermeta.wizardStep !== 0) {
              navigate('/account-setup-check-email');
              return;
            }

            // await triggerGetCurrentPlan().unwrap();
            setCurrentUsermeta(usermeta);
            triggerGetBusiness(id);
            // User is authenticated
          } else {
            content = <ErrorShowComponent message1={"Internal server error"} />
          }
        }
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });


    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);


  //Profile image load useEffect
  useEffect(() => {
    const refreshProfileImage = async () => {
      if (currentUsermeta && currentUsermeta.profileImage.expiresAt < Date.now() / 1000) {
        // Pre-signed URL has expired, trigger a new request to the backend
        const newImageData = await triggerNewProfileImageLink().unwrap();
        // Update the profile image link directly
        setProfileImageLink(newImageData.url);
      } else if (currentUsermeta && currentUsermeta.profileImage.url) {
        // Use the existing pre-signed URL to display the profile image
        setProfileImageLink(currentUsermeta.profileImage.url);
      }
    };

    refreshProfileImage();
  }, [currentUsermeta]);

  const handleRemoveRadar = async (businessId) => {
    await deleteFromRadar(businessId);
    window.location.reload();
  };

  const handleAddRadar = async (businessId) => {
    await addToRadar(businessId);
    window.location.reload();
  };




  if (isSuccess && profileImageLink) {
    return (
      <>
        <Helmet>
          <title>Business</title>
        </Helmet>
        <div className="business-page-business-page">

          <main className="business-page-frame-parent">

            <Navbar1 userData={currentUser} navigate={navigate} profileImageLink={profileImageLink} />




            <section className="business-page-image-wrap-wrapper">
              <img
                className="business-page-image-wrap-icon"
                loading="lazy"
                alt=""
                // src={iamge_wrap_2x}
                src={data.bannerImageLink}

              />
            </section>
            <section className="business-page-frame-section">
              <div className="business-page-frame-parent2">
                <div className="business-page-frame-parent3">
                  <div className="business-page-rectangle-group">
                    <div className="business-page-frame-item"></div>
                    <div className="business-page-rectangle-container">
                      <div className="business-page-frame-inner"></div>
                      <img
                        className="business-page-f4b711e503bc72fe396e5043e0c2-7-icon"
                        loading="lazy"
                        alt=""
                        src={data.logoImageLink}
                      />
                    </div>
                  </div>
                  <div className="business-page-frame-wrapper1">
                    <div className="business-page-header-section-parent">
                      <div className="business-page-header-section">
                        <div className="business-page-container">
                          <div className="business-page-page-header">
                            <div className="business-page-breadcrumbs">
                              <div className="business-page-tabs">
                                <div className="business-page-breadcrumb-button-base2">
                                  <img
                                    className="business-page-search-lg-icon"
                                    alt=""
                                    src={homeline}
                                  />
                                </div>
                                <img
                                  className="business-page-chevron-right-icon2"
                                  alt=""
                                  src={chevronright}
                                />

                                <div className="business-page-breadcrumb-button-base3">
                                  <a className="business-page-text13">Home</a>
                                </div>
                                <img
                                  className="business-page-chevron-right-icon2"
                                  alt=""
                                  src={chevronright}
                                />

                                <div className="business-page-breadcrumb-button-base4">
                                  <div className="business-page-text14">...</div>
                                </div>
                                <img
                                  className="business-page-chevron-right-icon4"
                                  alt=""
                                  src={chevronright}
                                />

                                <div className="business-page-breadcrumb-button-base5">
                                  <div className="business-page-text15">Another link</div>
                                </div>
                                <img
                                  className="business-page-chevron-right-icon4"
                                  alt=""
                                  src={chevronright}
                                />

                                <div className="business-page-breadcrumb-button-base6">
                                  <a className="business-page-text16">Business</a>
                                </div>
                                <img
                                  className="business-page-chevron-right-icon2"
                                  alt=""
                                  src={chevronright}
                                />

                                <div className="business-page-breadcrumb-button-base7">
                                  <div className="business-page-text17">ID# {data.id}</div>
                                </div>
                              </div>
                            </div>
                            <img
                              className="business-page-divider-icon1"
                              alt=""
                              src={divider1}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="business-page-frame-parent4">
                        <div className="business-page-frame-parent5">
                          <div className="business-page-frame-parent6">
                            <div className="business-page-text-parent">
                              <h1 className="business-page-text18">{data.businessName}</h1>
                              <div className="business-page-marker-pin-02-parent">
                                <img
                                  className="business-page-marker-pin-02-icon"
                                  alt=""
                                  src={markerpin02}
                                />

                                <div className="business-page-supporting-text">
                                  {data.businessTownCity},
                                  {data.tax_country.name}
                                </div>
                              </div>
                              <div className="business-page-frame-parent7">
                                <div className="business-page-text-wrapper">
                                  <div className="business-page-text19">4.9/5</div>
                                </div>
                                <div className="business-page-supporting-text-wrapper">
                                  <div className="business-page-supporting-text1">99,999 reviews</div>
                                </div>
                              </div>
                            </div>
                            <div className="business-page-bookmark-check-wrapper">
                              <img
                                className="business-page-bookmark-check-icon"
                                alt=""
                                src={bookmarkcheck}
                              />
                            </div>
                            <div className="business-page-supporting-text-container">
                              <div className="business-page-supporting-text2">
                                Verified by Gerätor
                              </div>
                            </div>
                          </div>
                          <div className="business-page-buttonsbutton-parent">
                            {!data.isAuthor && (
                              data.addedToRadar ? (

                                <div className="business-page-buttonsbutton" onClick={() => handleRemoveRadar(data.id)} style={{ cursor: "pointer" }}>
                                  <img
                                    className="business-page-magnifier-1-icon"
                                    alt=""
                                    src={target_04}
                                  />
                                  <div className="business-page-text-padding4">
                                    <div className="business-page-text20">Added To Radar</div>
                                  </div>

                                </div>
                              ) : (
                                <div className="business-page-buttonsbutton" onClick={() => handleAddRadar(data.id)} style={{ cursor: "pointer" }}>
                                  <img
                                    className="business-page-magnifier-1-icon"
                                    alt=""
                                    src={radar_1_1_2x}
                                  />

                                  <div className="business-page-text-padding4">
                                    <div className="business-page-text20">Add To Radar</div>
                                  </div>
                                </div>
                              )
                            )}
                            {/* <button className="business-page-buttonsbutton">
                             
                              <img
                                className="business-page-magnifier-1-icon"
                                alt=""
                                src={radar_1_1_2x}
                              />

                              <div className="business-page-text-padding4">
                                <div className="business-page-text20">Add T Radar</div>
                              </div>

                              
                            </button> */}

                            <button className="business-page-buttonsbutton1"    onClick={toggleForm}  >
                              <img
                                className="business-page-chevron-down-icon"
                                alt=""
                                src={placeholder2}
                              />

                              <img
                                className="business-page-search-lg-icon"
                                alt=""
                                src={shield03}
                              />

                              <div className="business-page-text-padding4">
                                <div className="business-page-text21">Report Abuse</div>
                              </div>
                              <img
                                className="business-page-chevron-down-icon"
                                alt=""
                                src={placeholder2}
                              />
                            </button>

                              {isFormOpen && <ReportAbuseSlideOut onClose={toggleForm}  />}
                            
                            {/* <div className="business-page-buttonsbutton2">
                              <img
                                className="business-page-placeholder-icon"
                                alt=""
                                src={placeholder2}
                              />

                              <img
                                className="business-page-magnifier-1-icon"
                                alt=""
                                src={deal_2_1_2x}
                              />

                              <img
                                className="business-page-placeholder-icon"
                                alt=""
                                src={placeholder2}
                              />
                            </div>
                            <div className="business-page-buttonsbutton2">
                              <img
                                className="business-page-placeholder-icon"
                                alt=""
                                src={placeholder2}
                              />

                              <img
                                className="business-page-magnifier-1-icon"
                                alt=""
                                src={magnifier_1_2x}
                              />

                              <img
                                className="business-page-placeholder-icon"
                                alt=""
                                src={placeholder2}
                              />
                            </div> */}
                            {/* <div className="business-page-buttonsbutton2">
                              <img
                                className="business-page-placeholder-icon"
                                alt=""
                                src={placeholder2}
                              />

                              <img
                                className="business-page-magnifier-1-icon"
                                alt=""
                                src={share_1_2x}
                              />

                              <img
                                className="business-page-placeholder-icon"
                                alt=""
                                src={placeholder2}
                              />
                            </div> */}

                            <div className='business-page-sharethis-parent'>

                              <div className="business-page-buttonsbutton2" onClick={handleShareButtonClick} style={{ cursor: 'pointer' }}>


                                <img
                                  className="business-page-magnifier-1-icon"
                                  alt=""
                                  src={share_1_2x}
                                />





                              </div>

                              {activeSection === 'share' && (
                                <div className='business-page-sharethis'>
                                  <InlineShareButtons
                                    config={{
                                      alignment: 'center',
                                      color: 'black',
                                      enabled: true,
                                      font_size: 14,
                                      labels: 'cta',
                                      language: 'en',
                                      networks: ["twitter", "whatsapp", "linkedin", "email", "facebook"
                                        , "flipboard"
                                        , "google"
                                        , "digg"
                                        , "livejournal"
                                        , "mailru"
                                        , "meneame"
                                        , "messenger"
                                        , "oknoklassniki"
                                        , "pinterest"
                                        , "print"
                                        , "reddit"
                                        , "sharethis"
                                        , "sms"
                                        , "stumbleupon"
                                        , "tumblr"
                                        , "blogger", "vk", "wechat", "weibo", "delicious", "xing"],
                                      padding: 12,
                                      radius: 4,
                                      show_total: false,
                                      size: 34,
                                      url: "https://gerator.io/", // Use the current page URL dynamically
                                      image: 'https://bit.ly/2CMhCMC',
                                      description: 'Check out this amazing content!',
                                      title: 'Custom Share Title',
                                    }}
                                  />
                                </div>
                              )}

                            </div >


                            {/* <img
                                                                                      className="business-page-button-row-child"
                                                                                      alt=""
                                                                                      src={group_1010}
                                                                                    /> */}

                            <div className='business-page-sharethis-follow-parent'>

                              <div className="business-page-buttonsbutton2" onClick={handleFollowButtonClick} style={{ cursor: 'pointer' }}>


                                <img
                                  className="business-page-magnifier-1-icon"
                                  alt=""
                                  src={follow}

                                />



                                Follow us

                              </div>

                              {activeSection === 'follow' && (
                                <div className='business-page-sharethis-follow'>
                                  <InlineFollowButtons
                                    config={{
                                      action: 'Follow us:', // call to action (STRING)
                                      action_enable: false,
                                      action_pos: 'bottom', // position of call to action (left, top, right)
                                      alignment: 'center',  // alignment of buttons (left, center, right)
                                      color: 'white',       // set the color of buttons (social, white)
                                      enabled: true,        // show/hide buttons (true, false)
                                      networks: [
                                        'linkedin',         // which networks to include (see FOLLOW NETWORKS)
                                        'twitter',
                                        'facebook',
                                        'instagram',

                                      ],
                                      padding: 8,           // padding within buttons (INTEGER)
                                      profiles: {           // social profile links for buttons
                                        twitter: 'ger8or',
                                        facebook: 'Ger8orMarketplace ',
                                        instagram: 'ger8or',
                                        linkedin: 'company/gerator',
                                      },
                                      radius: 9,            // the corner radius on each button (INTEGER)
                                      size: 32,             // the size of each button (INTEGER)
                                      spacing: 8            // the spacing between buttons (INTEGER)
                                    }}
                                  />
                                </div>
                              )}

                            </div>
                          </div>
                        </div>
                        <div className="business-page-actions1">
                          <div className="business-page-button4">
                            <img
                              className="business-page-placeholder-icon"
                              alt=""
                              src={placeholder}
                            />

                            <div className="business-page-text-padding">
                              <div className="business-page-text2">Tertiary</div>
                            </div>
                            <img
                              className="business-page-placeholder-icon"
                              alt=""
                              src={placeholder}
                            />
                          </div>
                          <div className="business-page-button5">
                            <img
                              className="business-page-search-lg-icon"
                              alt=""
                              src={dotshorizontal}
                            />
                          </div>
                          {/* <button className="business-page-button6">
                            <img
                              className="business-page-placeholder-icon"
                              alt=""
                              src={placeholder2}
                            />

                            <div className="business-page-text-padding4">
                              <div className="business-page-text23">View portfolio</div>
                            </div>
                            <img
                              className="business-page-placeholder-icon"
                              alt=""
                              src={placeholder2}
                            />
                          </button> */}
                          {/* <button className="business-page-button7">
                            <img
                              className="business-page-placeholder-icon"
                              alt=""
                              src={placeholder3}
                            />

                            <div className="business-page-text-padding4">
                              <div className="business-page-text24">Follow</div>
                            </div>
                            <img
                              className="business-page-placeholder-icon"
                              alt=""
                              src={placeholder3}
                            />
                          </button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img className="business-page-divider-icon2" alt="" src={divider2} />

                <div className="business-page-frame-parent8">
                  <div className="business-page-frame-parent9">
                    <div className="business-page-group-div">
                      <div className="business-page-rectangle-div"></div>
                      <div className="business-page-frame-wrapper2">
                        <div className="business-page-heading-parent">
                          <h3 className="business-page-heading">Jobs</h3>
                          <div className="business-page-text-container">
                            <a className="business-page-testimonial-carousel-labels">View All</a>
                          </div>
                        </div>
                      </div>
                      <div className="business-page-divider-parent">
                        <img
                          className="business-page-divider-icon3"
                          alt=""
                          src={divider_1}
                        />

                        <div className="business-page-about-details-divider">
                          <div className="business-page-about-details-rows">
                            <div className="business-page-rectangle-parent1">
                              <div className="business-page-frame-child1"></div>
                              <img
                                className="business-page-f4b711e503bc72fe396e5043e0c2-7-icon1"
                                alt=""
                                src={f8595f4b711e503bc72fe396e5043e0c2_7_1_2x}
                              />
                            </div>
                            <div className="business-page-service-block-one">
                              <div className="business-page-service-title-one">
                                WHI Program assistant / Undergraduate researcher
                              </div>
                              <div className="business-page-heading1">Marketing Fusion SARL</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="business-page-divider-icon3"
                          alt=""
                          src={divider_1}
                        />

                        <div className="business-page-about-details-divider">
                          <div className="business-page-about-details-rows">
                            <div className="business-page-rectangle-parent1">
                              <div className="business-page-frame-child1"></div>
                              <img
                                className="business-page-f4b711e503bc72fe396e5043e0c2-7-icon1"
                                alt=""
                                src={f8595f4b711e503bc72fe396e5043e0c2_7_1_2x}
                              />
                            </div>
                            <div className="business-page-service-block-one">
                              <div className="business-page-service-title-one">
                                WHI Program assistant / Undergraduate researcher
                              </div>
                              <div className="business-page-heading1">Marketing Fusion SARL</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="business-page-divider-icon3"
                          alt=""
                          src={divider_1}
                        />

                        <div className="business-page-about-details-divider">
                          <div className="business-page-about-details-rows">
                            <div className="business-page-rectangle-parent1">
                              <div className="business-page-frame-child1"></div>
                              <img
                                className="business-page-f4b711e503bc72fe396e5043e0c2-7-icon1"
                                alt=""
                                src={f8595f4b711e503bc72fe396e5043e0c2_7_1_2x}
                              />
                            </div>
                            <div className="business-page-service-block-one">
                              <div className="business-page-service-title-one">
                                WHI Program assistant / Undergraduate researcher
                              </div>
                              <div className="business-page-heading1">Marketing Fusion SARL</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="business-page-divider-icon3"
                          alt=""
                          src={divider_1}
                        />

                        <div className="business-page-about-details-divider">
                          <div className="business-page-about-details-rows">
                            <div className="business-page-rectangle-parent1">
                              <div className="business-page-frame-child1"></div>
                              <img
                                className="business-page-f4b711e503bc72fe396e5043e0c2-7-icon1"
                                alt=""
                                src={f8595f4b711e503bc72fe396e5043e0c2_7_1_2x}
                              />
                            </div>
                            <div className="business-page-service-block-one">
                              <div className="business-page-service-title-one">
                                WHI Program assistant / Undergraduate researcher
                              </div>
                              <div className="business-page-heading1">Marketing Fusion SARL</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="business-page-divider-icon3"
                          alt=""
                          src={divider_1}
                        />

                        <div className="business-page-about-details-divider">
                          <div className="business-page-about-details-rows">
                            <div className="business-page-rectangle-parent1">
                              <div className="business-page-frame-child1"></div>
                              <img
                                className="business-page-f4b711e503bc72fe396e5043e0c2-7-icon1"
                                alt=""
                                src={f8595f4b711e503bc72fe396e5043e0c2_7_1_2x}
                              />
                            </div>
                            <div className="business-page-service-block-one">
                              <div className="business-page-service-title-one">
                                WHI Program assistant / Undergraduate researcher
                              </div>
                              <div className="business-page-heading1">Marketing Fusion SARL</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="business-page-divider-icon3"
                          alt=""
                          src={divider_1}
                        />

                        <div className="business-page-about-details-divider">
                          <div className="business-page-about-details-rows">
                            <div className="business-page-rectangle-parent1">
                              <div className="business-page-frame-child1"></div>
                              <img
                                className="business-page-f4b711e503bc72fe396e5043e0c2-7-icon1"
                                alt=""
                                src={f8595f4b711e503bc72fe396e5043e0c2_7_1_2x}
                              />
                            </div>
                            <div className="business-page-service-block-one">
                              <div className="business-page-service-title-one">
                                WHI Program assistant / Undergraduate researcher
                              </div>
                              <div className="business-page-heading1">Marketing Fusion SARL</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-page-service-block-two">
                      <div className="business-page-service-content-two">
                        <div className="business-page-content4">
                          <div className="business-page-about">
                            <div className="business-page-paragraph-and-social-icons">
                              <div className="business-page-paragraph-and-button">
                                <div className="business-page-paragraph">
                                  <p className="business-page-as-lawmakers-seek">
                                    {data.businessExpertiseHighlights}
                                  </p>
                                  <p className="business-page-the-texas-hospital">
                                    {data.businessExpertiseDescription}
                                  </p>
                                </div>
                                <div className="business-page-buttonsbutton5">
                                  <img
                                    className="business-page-chevron-down-icon"
                                    alt=""
                                    src={placeholder1}
                                  />

                                  <div className="business-page-text31">Read more</div>
                                  <img
                                    className="business-page-chevron-down-icon"
                                    alt=""
                                    src={placeholder1}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="business-page-details">
                              <div className="business-page-label-and-text">
                                <div className="business-page-label1">Business Genre</div>
                                <div className="business-page-text-and-icon">
                                  <div className="business-page-text32">{data.tax_business_genre.name}</div>
                                </div>
                              </div>
                              <div className="business-page-label-and-text1">
                                <div className="business-page-text14">Ownership</div>
                                <div className="business-page-buttonsbutton6">
                                  <img
                                    className="business-page-chevron-down-icon"
                                    alt=""
                                    src={placeholder1}
                                  />

                                  <div className="business-page-text33">{data.tax_ownership.name}</div>
                                  <img
                                    className="business-page-chevron-down-icon"
                                    alt=""
                                    src={arrowupright}
                                  />
                                </div>
                              </div>
                              <div className="business-page-label-and-text2">
                                <div className="business-page-text14">Size</div>
                                <div className="business-page-buttonsbutton6">
                                  <img
                                    className="business-page-chevron-down-icon"
                                    alt=""
                                    src={placeholder1}
                                  />

                                  <div className="business-page-text34">{data.tax_business_size.name}</div>
                                  <img
                                    className="business-page-chevron-down-icon"
                                    alt=""
                                    src={arrowupright}
                                  />
                                </div>
                              </div>
                              <div className="business-page-label-and-text3">
                                <div className="business-page-text14">Website</div>
                                <div className="business-page-buttonsbutton8">
                                  <img
                                    className="business-page-chevron-down-icon"
                                    alt=""
                                    src={placeholder1}
                                  />

                                  <div className="business-page-text35">{data.businessWebsite}</div>
                                  <img
                                    className="business-page-search-lg-icon"
                                    alt=""
                                    src={arrowupright}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="business-page-divider-group">
                        <img
                          className="business-page-divider-icon9"
                          alt=""
                          src={divider_7}
                        />

                        <div className="business-page-heading-paragraph">
                          <div className="business-page-heading7">Location</div>
                          <div className="business-page-paragraph1">
                            {data.businessFullAddress}
                          </div>
                        </div>
                      </div>
                      <div className="business-page-background-shape-wrapper">
                        <img
                          className="business-page-background-shape-icon"
                          loading="lazy"
                          alt=""
                          src={rectangle_43_2x}
                        />
                      </div>
                      <div className="business-page-divider-container">
                        <img
                          className="business-page-divider-icon9"
                          alt=""
                          src={divider_7}
                        />


                      </div>
                      {/* <h3 className="business-page-heading8">Reviews (2)</h3>
                      <div className="business-page-filters-bar-parent">
                        <div className="business-page-filters-bar">
                          <div className="business-page-content5">
                            <div className="business-page-button-group">
                              <button className="business-page-button-group-base">
                                <div className="business-page-text36">Newest</div>
                              </button>
                              <div className="business-page-button-group-base1">
                                <div className="business-page-text37">Highest</div>
                              </div>
                              <div className="business-page-button-group-base2">
                                <div className="business-page-text38">Text</div>
                              </div>
                              <div className="business-page-button-group-base3">
                                <div className="business-page-text38">Text</div>
                              </div>
                              <div className="business-page-button-group-base4">
                                <div className="business-page-text38">Text</div>
                              </div>
                              <div className="business-page-button-group-base5">
                                <div className="business-page-text38">Text</div>
                              </div>
                              <div className="business-page-button-group-base6">
                                <div className="business-page-text38">Text</div>
                              </div>
                              <div className="business-page-button-group-base7">
                                <div className="business-page-text38">Text</div>
                              </div>
                              <div className="business-page-button-group-base8">
                                <div className="business-page-text44">Lowest</div>
                              </div>
                            </div>
                            <div className="business-page-actions2">
                              <div className="business-page-input-dropdown">
                                <div className="business-page-input-with-label1">
                                  <div className="business-page-label">Search</div>
                                  <div className="business-page-input1">
                                    <div className="business-page-content6">
                                      <img
                                        className="business-page-search-lg-icon"
                                        alt=""
                                        src={searchlg}
                                      />

                                      <input
                                        className="business-page-search-placeholder"
                                        placeholder="Thomson Medical"
                                        type="text"
                                      />

                                      <div className="business-page-supporting-text3">@olivia</div>
                                    </div>
                                  </div>
                                </div>
                                <div className="business-page-hint-text">
                                  This is a hint text to help user.
                                </div>
                              </div>
                              <button className="business-page-button8">
                                <img
                                  className="business-page-filter-lines-icon"
                                  alt=""
                                  src={filterlines}
                                />

                                <div className="business-page-text-padding4">
                                  <a className="business-page-text45">Filters</a>
                                </div>
                                <img
                                  className="business-page-placeholder-icon"
                                  alt=""
                                  src={placeholder2}
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="business-page-frame-parent15">
                          <div className="business-page-frame-parent16">
                            <div className="business-page-parent">
                              <b className="business-page-b">5.0</b>
                              <div className="business-page-heading-wrapper">
                                <div className="business-page-heading9">/5</div>
                              </div>
                            </div>
                            <div className="business-page-review-stars">
                              <img
                                className="business-page-star-background-icon"
                                loading="lazy"
                                alt=""
                                src={star_background}
                              />

                              <img
                                className="business-page-star-background-icon"
                                alt=""
                                src={star_background}
                              />

                              <img
                                className="business-page-star-background-icon"
                                alt=""
                                src={star_background}
                              />

                              <img
                                className="business-page-star-background-icon"
                                alt=""
                                src={star_background}
                              />

                              <img
                                className="business-page-star-background-icon"
                                alt=""
                                src={star_background}
                              />
                            </div>
                          </div>
                          <div className="business-page-divider-parent1">
                            <img
                              className="business-page-divider-icon11"
                              alt=""
                              src={divider_9}
                            />

                            <div className="business-page-review-heading-block">
                              <div className="business-page-rectangle-parent7">
                                <div className="business-page-frame-child7"></div>
                                <div className="business-page-stars">
                                  <img
                                    className="business-page-filter-lines-icon"
                                    alt=""
                                    src={star_icon}
                                  />

                                  <img
                                    className="business-page-filter-lines-icon"
                                    alt=""
                                    src={star_icon}
                                  />

                                  <img
                                    className="business-page-filter-lines-icon"
                                    alt=""
                                    src={star_icon}
                                  />

                                  <img
                                    className="business-page-filter-lines-icon"
                                    alt=""
                                    src={star_icon}
                                  />

                                  <img
                                    className="business-page-filter-lines-icon"
                                    alt=""
                                    src={star_icon}
                                  />
                                </div>
                                <div className="business-page-review-title">
                                  <div className="business-page-heading10">Trust (5.0)</div>
                                </div>
                              </div>
                              <div className="business-page-rectangle-parent7">
                                <div className="business-page-frame-child7"></div>
                                <div className="business-page-stars">
                                  <img
                                    className="business-page-filter-lines-icon"
                                    alt=""
                                    src={star_icon}
                                  />

                                  <img
                                    className="business-page-filter-lines-icon"
                                    alt=""
                                    src={star_icon}
                                  />

                                  <img
                                    className="business-page-filter-lines-icon"
                                    alt=""
                                    src={star_icon}
                                  />

                                  <img
                                    className="business-page-filter-lines-icon"
                                    alt=""
                                    src={star_icon}
                                  />

                                  <img
                                    className="business-page-filter-lines-icon"
                                    alt=""
                                    src={star_icon}
                                  />
                                </div>
                                <div className="business-page-heading-container">
                                  <div className="business-page-heading10">Accuracy (5.0)</div>
                                </div>
                              </div>
                              <div className="business-page-rectangle-parent9">
                                <div className="business-page-frame-child7"></div>
                                <div className="business-page-stars-wrapper">
                                  <div className="business-page-stars2">
                                    <img
                                      className="business-page-filter-lines-icon"
                                      alt=""
                                      src={star_icon}
                                    />

                                    <img
                                      className="business-page-filter-lines-icon"
                                      alt=""
                                      src={star_icon}
                                    />

                                    <img
                                      className="business-page-filter-lines-icon"
                                      alt=""
                                      src={star_icon}
                                    />

                                    <img
                                      className="business-page-filter-lines-icon"
                                      alt=""
                                      src={star_icon}
                                    />

                                    <img
                                      className="business-page-filter-lines-icon"
                                      alt=""
                                      src={star_icon}
                                    />
                                  </div>
                                </div>
                                <div className="business-page-heading12">Responsiveness (5.0)</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="business-page-frame-wrapper3">
                    <div className="business-page-frame-parent2">
                      {/* <div className="business-page-frame-parent18">
                        <img
                          className="business-page-group-icon"
                          loading="lazy"
                          alt=""
                          src={group_78_2x}
                        />

                        <div className="business-page-heading-group">
                          <div className="business-page-heading13">Chloe Tammy</div>
                          <div className="business-page-heading14">
                            <span>Marketing Communications at</span>
                            <span className="business-page-alvo-medical"> Alvo Medical</span>
                          </div>
                        </div>
                        <div className="business-page-frame-wrapper4">
                          <div className="business-page-stars-parent">
                            <div className="business-page-stars3">
                              <img
                                className="business-page-filter-lines-icon"
                                alt=""
                                src={star_icon}
                              />

                              <img
                                className="business-page-filter-lines-icon"
                                alt=""
                                src={star_icon}
                              />

                              <img
                                className="business-page-filter-lines-icon"
                                alt=""
                                src={star_icon}
                              />

                              <img
                                className="business-page-filter-lines-icon"
                                alt=""
                                src={star_icon}
                              />

                              <img
                                className="business-page-filter-lines-icon"
                                alt=""
                                src={star_icon}
                              />
                            </div>
                            <div className="business-page-heading-frame">
                              <div className="business-page-heading15">1 month ago</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="business-page-paragraph-parent">
                        <div className="business-page-paragraph2">
                          One of the best offers I found in the market
                        </div>
                        <div className="business-page-paragraph1">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the industry's
                          standard dummy text ever since the 1500s, when an unknown
                          printer took a galley of type and scrambled it to make a
                          type specimen book.
                        </div>
                      </div> */}
                      {/* <div className="business-page-frame-parent19">
                        <img
                          className="business-page-frame-child10"
                          alt=""
                          src={group_78_2x}
                        />

                        <div className="business-page-frame-wrapper5">
                          <div className="business-page-heading-parent1">
                            <div className="business-page-heading13">Chloe Tammy</div>
                            <div className="business-page-heading14">
                              <span>Marketing Communications at</span>
                              <span className="business-page-alvo-medical"> Alvo Medical</span>
                            </div>
                          </div>
                        </div>
                        <div className="business-page-buttonsbutton-group">
                          <button className="business-page-buttonsbutton9">
                            <img
                              className="business-page-filter-lines-icon"
                              alt=""
                              src={messagedotscircle}
                            />

                            <a className="business-page-text46">Reply</a>
                          </button>
                          <div className="business-page-frame-wrapper6">
                            <div className="business-page-stars-group">
                              <div className="business-page-stars">
                                <img
                                  className="business-page-filter-lines-icon"
                                  alt=""
                                  src={star_icon}
                                />

                                <img
                                  className="business-page-filter-lines-icon"
                                  alt=""
                                  src={star_icon}
                                />

                                <img
                                  className="business-page-filter-lines-icon"
                                  alt=""
                                  src={star_icon}
                                />

                                <img
                                  className="business-page-filter-lines-icon"
                                  alt=""
                                  src={star_icon}
                                />

                                <img
                                  className="business-page-filter-lines-icon"
                                  alt=""
                                  src={star_icon}
                                />
                              </div>
                              <div className="business-page-stars-heading">
                                <div className="business-page-heading18">1 month ago</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="business-page-paragraph-parent">
                        <div className="business-page-paragraph2">
                          One of the best offers I found in the market
                        </div>
                        <div className="business-page-paragraph1">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the industry's
                          standard dummy text ever since the 1500s, when an unknown
                          printer took a galley of type and scrambled it to make a
                          type specimen book.
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <div className="business-page-form-divider">
              <img className="business-page-divider-icon12" alt="" src={divider2} />
            </div> */}
            {/* <section className="business-page-reviews-header-wrapper">
              <div className="business-page-reviews-header">
               
                <div className="business-page-pagination">
                  <div className="business-page-button-wrap">
                    <button className="business-page-buttonsbutton11">
                      <img
                        className="business-page-placeholder-icon"
                        alt=""
                        src={placeholder2}
                      />

                      <div className="business-page-text-padding4">
                        <div className="business-page-page-number">Previous</div>
                      </div>
                      <img
                        className="business-page-placeholder-icon"
                        alt=""
                        src={placeholder2}
                      />
                    </button>
                  </div>
                  <div className="business-page-details1">1 of 10</div>
                  <div className="business-page-button-wrap1">
                    <button className="business-page-buttonsbutton12">
                      <img
                        className="business-page-placeholder-icon"
                        alt=""
                        src={placeholder2}
                      />

                      <div className="business-page-text-padding4">
                        <div className="business-page-text48">Next</div>
                      </div>
                      <img
                        className="business-page-placeholder-icon"
                        alt=""
                        src={placeholder2}
                      />
                    </button>
                  </div>
                </div>
               
                <div className="business-page-review-form">
                  <div className="business-page-rectangle-parent10">
                    <div className="business-page-frame-child11"></div>
                    <div className="business-page-divider-group">
                      <h3 className="business-page-heading19">Write Review</h3>
                      <div className="business-page-frame-parent20">
                        <div className="business-page-label-and-text">
                          <div className="business-page-heading20">Trust</div>
                          <div className="business-page-pin-icon">
                            <img
                              className="business-page-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="business-page-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="business-page-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="business-page-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="business-page-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
                          </div>
                        </div>
                        <div className="business-page-label-and-text">
                          <div className="business-page-heading20">Accuracy</div>
                          <div className="business-page-pin-icon">
                            <img
                              className="business-page-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="business-page-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="business-page-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="business-page-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="business-page-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
                          </div>
                        </div>
                        <div className="business-page-label-and-text">
                          <div className="business-page-heading22">Responsiveness</div>
                          <div className="business-page-pin-icon">
                            <img
                              className="business-page-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="business-page-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="business-page-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="business-page-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="business-page-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="business-page-review-input">
                        <div className="business-page-input-field">
                          <div className="business-page-input-with-label1">
                            <div className="business-page-label6">Title</div>
                            <div className="business-page-content7">
                              <div className="business-page-textarea-input-field">
                                <div className="business-page-input-with-label3">
                                  <div className="business-page-label7">Description</div>
                                  <div className="business-page-input2">
                                    <input
                                      className="business-page-review-placeholder"
                                      placeholder="70 characters"
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="business-page-hint-text1">10 characters left</div>
                              </div>
                            </div>
                          </div>
                          <div className="business-page-hint-text">
                            This is a hint text to help user.
                          </div>
                        </div>
                      </div>
                      <div className="business-page-contact-input">
                        <div className="business-page-input-field1">
                          <div className="business-page-input-with-label1">
                            <div className="business-page-label8">Review</div>
                            <div className="business-page-content7">
                              <div className="business-page-textarea-input-field1">
                                <div className="business-page-input-with-label5">
                                  <div className="business-page-label7">Description</div>
                                  <div className="business-page-input3">
                                    <div className="business-page-contact-placeholder">
                                      1000 characters
                                    </div>
                                  </div>
                                </div>
                                <div className="business-page-hint-text3">40 characters left</div>
                              </div>
                            </div>
                          </div>
                          <div className="business-page-hint-text">
                            This is a hint text to help user.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-page-submit-button">
                      <button className="business-page-button9">
                        <img
                          className="business-page-chevron-down-icon"
                          alt=""
                          src={placeholder3}
                        />

                        <img
                          className="business-page-log-in-04-icon"
                          alt=""
                          src={login04}
                        />

                        <div className="business-page-navigation-wrapper">
                          <div className="business-page-submit-label">Submit</div>
                        </div>
                        <img
                          className="business-page-chevron-down-icon"
                          alt=""
                          src={placeholder3}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
            {/* Review Form End */}
            {/* <div className="business-page-form-divider">
              <img className="business-page-divider-icon12" alt="" src={divider2} />
            </div> */}

            <ReviewBusinessView isAuthor={data?.isAuthor} listingId={id} currentUsermeta={currentUsermeta} />


            <div className="detailed-device-listing-image-grid1">
              <img className="detailed-device-divider-icon3" alt="" src={divider1} />
            </div>

            {/* Review Add Form component */}
            {(!data?.isAuthor && (!data?.isReviewed) && <AddReviewBusinessForm listingId={id} currentUsermeta={currentUsermeta} formikRef={formikRef} />)
            }

            <div className="business-page-frame-wrapper7">
              <div className="business-page-heading-parent5">
                <h3 className="business-page-heading23">Devices</h3>
                <div className="business-page-testimonial-carousel-controls">
                  <a className="business-page-testimonial-carousel-labels">View All</a>
                </div>
              </div>
            </div>
            <section className="business-page-testimonial-carousel">
              <div className="business-page-carousel-container">
                <div className="business-page-carousel-background"></div>
                <div className="business-page-testimonials">
                  <div className="business-page-rectangle-parent11">
                    <div className="business-page-frame-child12"></div>
                    <div className="business-page-testimonial-card">
                      <img
                        className="business-page-testimonial-card-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="business-page-image-overlay">
                        <div className="business-page-badge3">
                          <div className="business-page-text49">Assured</div>
                        </div>
                        <div className="business-page-overlay">
                          <div className="business-page-badge4">
                            <div className="business-page-badge-label">ID# AXJYN</div>
                          </div>
                          <div className="business-page-buttonsbutton13">
                            <img
                              className="business-page-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-page-card-content">
                      <div className="business-page-card-info">
                        <div className="business-page-business-name">
                          <div className="business-page-business">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="business-page-card-details">
                          <div className="business-page-bottom-content">
                            <div className="business-page-label-and-text">
                              <div className="business-page-job-location">
                                <div className="business-page-pin-icon">
                                  <div className="business-page-location-pin">
                                    <img
                                      className="business-page-marker-pin-02-icon1"
                                      alt=""
                                      src={markerpin02_1}
                                    />
                                  </div>
                                  <div className="business-page-location">India, New Delhi</div>
                                </div>
                                <div className="business-page-pin-icon">
                                  <div className="business-page-location-pin">
                                    <img
                                      className="business-page-marker-pin-02-icon1"
                                      alt=""
                                      src={calendar}
                                    />
                                  </div>
                                  <div className="business-page-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="business-page-job-description">
                                <div className="business-page-job-title">
                                  <div className="business-page-job-name">4.9/5</div>
                                </div>
                                <div className="business-page-supporting-text-wrapper">
                                  <div className="business-page-supporting-text4">99,999 reviews</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="business-page-bottom-info">
                            <div className="business-page-job-details">
                              <div className="business-page-job-meta">$9,999,999.99</div>
                              <div className="business-page-supporting-text-frame">
                                <div className="business-page-supporting-text5">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="business-page-divider-icon3"
                            alt=""
                            src={divider_11}
                          />
                        </div>
                        <div className="business-page-testimonial-team-member">
                          <div className="business-page-testimonial-member-details">
                            <div className="business-page-review-member-details">
                              <img
                                className="business-page-review-member-details-child"
                                loading="lazy"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="business-page-testimonial-member-name-role-parent">
                                <div className="business-page-testimonial-member-name-role">
                                  <div className="business-page-name">Olivia Rhye</div>
                                </div>
                                <div className="business-page-role">Business Name</div>
                              </div>
                            </div>
                            <div className="business-page-testimonial-member-icon">
                              <img className="business-page-icon1" alt="" src={icon_1} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="business-page-rectangle-parent11">
                    <div className="business-page-frame-child12"></div>
                    <div className="business-page-testimonial-card">
                      <img
                        className="business-page-testimonial-card-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="business-page-image-overlay">
                        <div className="business-page-badge3">
                          <div className="business-page-text49">Assured</div>
                        </div>
                        <div className="business-page-overlay">
                          <div className="business-page-badge4">
                            <div className="business-page-badge-label">ID# AXJYN</div>
                          </div>
                          <div className="business-page-buttonsbutton13">
                            <img
                              className="business-page-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-page-card-content">
                      <div className="business-page-card-info">
                        <div className="business-page-business-name">
                          <div className="business-page-business">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="business-page-card-details">
                          <div className="business-page-bottom-content">
                            <div className="business-page-label-and-text">
                              <div className="business-page-job-location">
                                <div className="business-page-pin-icon">
                                  <div className="business-page-location-pin">
                                    <img
                                      className="business-page-marker-pin-02-icon1"
                                      alt=""
                                      src={markerpin02_1}
                                    />
                                  </div>
                                  <div className="business-page-location">India, New Delhi</div>
                                </div>
                                <div className="business-page-pin-icon">
                                  <div className="business-page-location-pin">
                                    <img
                                      className="business-page-marker-pin-02-icon1"
                                      alt=""
                                      src={calendar}
                                    />
                                  </div>
                                  <div className="business-page-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="business-page-job-description">
                                <div className="business-page-job-title">
                                  <div className="business-page-job-name">4.9/5</div>
                                </div>
                                <div className="business-page-supporting-text-wrapper">
                                  <div className="business-page-supporting-text4">99,999 reviews</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="business-page-bottom-info">
                            <div className="business-page-job-details">
                              <div className="business-page-job-meta">$9,999,999.99</div>
                              <div className="business-page-supporting-text-frame">
                                <div className="business-page-supporting-text5">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="business-page-divider-icon3"
                            alt=""
                            src={divider_11}
                          />
                        </div>
                        <div className="business-page-testimonial-team-member">
                          <div className="business-page-testimonial-member-details">
                            <div className="business-page-review-member-details">
                              <img
                                className="business-page-review-member-details-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="business-page-testimonial-member-name-role-parent">
                                <div className="business-page-testimonial-member-name-role">
                                  <div className="business-page-name">Olivia Rhye</div>
                                </div>
                                <div className="business-page-role">Business Name</div>
                              </div>
                            </div>
                            <div className="business-page-testimonial-member-icon">
                              <img className="business-page-icon1" alt="" src={icon_1} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="business-page-rectangle-parent14">
                    <div className="business-page-frame-child12"></div>
                    <div className="business-page-rectangle-parent15">
                      <img
                        className="business-page-testimonial-card-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="business-page-image-overlay">
                        <div className="business-page-badge3">
                          <div className="business-page-text49">Assured</div>
                        </div>
                        <div className="business-page-overlay">
                          <div className="business-page-badge4">
                            <div className="business-page-badge-label">ID# AXJYN</div>
                          </div>
                          <div className="business-page-buttonsbutton13">
                            <img
                              className="business-page-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-page-frame-wrapper12">
                      <div className="business-page-text-parent6">
                        <div className="business-page-text57">
                          New / Surplus 2021 Philips Health-care HD11 XE (P/N:23A...
                        </div>
                        <div className="business-page-frame-parent32">
                          <div className="business-page-pin-icon">
                            <div className="business-page-location-pin">
                              <img
                                className="business-page-marker-pin-02-icon1"
                                alt=""
                                src={markerpin02_1}
                              />
                            </div>
                            <div className="business-page-location">India, New Delhi</div>
                          </div>
                          <div className="business-page-pin-icon">
                            <div className="business-page-location-pin">
                              <img
                                className="business-page-marker-pin-02-icon1"
                                alt=""
                                src={calendar}
                              />
                            </div>
                            <div className="business-page-type-of-work">April 09, 2023</div>
                          </div>
                        </div>
                        <div className="business-page-job-description">
                          <div className="business-page-job-title">
                            <div className="business-page-job-name">4.9/5</div>
                          </div>
                          <div className="business-page-supporting-text-wrapper">
                            <div className="business-page-supporting-text4">99,999 reviews</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-page-testimonial-content-wrapper">
                      <div className="business-page-testimonial-content">
                        <div className="business-page-bottom-content">
                          <div className="business-page-job-details">
                            <div className="business-page-job-meta">$9,999,999.99</div>
                            <div className="business-page-supporting-text-frame">
                              <div className="business-page-supporting-text5">USD-Per Unit</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="business-page-divider-icon3"
                          alt=""
                          src={divider_11}
                        />

                        <div className="business-page-review-team-member-card">
                          <div className="business-page-testimonial-member-details">
                            <div className="business-page-review-member-details">
                              <img
                                className="business-page-review-member-details-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="business-page-testimonial-member-name-role-parent">
                                <div className="business-page-testimonial-member-name-role">
                                  <div className="business-page-name">Olivia Rhye</div>
                                </div>
                                <div className="business-page-role">Business Name</div>
                              </div>
                            </div>
                            <div className="business-page-testimonial-member-icon">
                              <img className="business-page-icon1" alt="" src={icon_1} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="business-page-rectangle-parent11">
                    <div className="business-page-frame-child12"></div>
                    <div className="business-page-testimonial-card">
                      <img
                        className="business-page-testimonial-card-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="business-page-image-overlay3">
                        <div className="business-page-badge3">
                          <div className="business-page-text49">Assured</div>
                        </div>
                        <div className="business-page-badge-container">
                          <div className="business-page-badge4">
                            <div className="business-page-badge-label">ID# AXJYN</div>
                          </div>
                          <div className="business-page-buttonsbutton16">
                            <img
                              className="business-page-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-page-card-content">
                      <div className="business-page-icon-parent">
                        <img className="business-page-icon4" alt="" src={icon_1} />

                        <div className="business-page-card-info">
                          <div className="business-page-business-name">
                            <div className="business-page-business">
                              New / Surplus 2021 Philips Health-care HD11 XE
                              (P/N:23A...
                            </div>
                          </div>
                          <div className="business-page-frame-wrapper14">
                            <div className="business-page-label-and-text">
                              <div className="business-page-job-location">
                                <div className="business-page-pin-icon">
                                  <div className="business-page-location-pin">
                                    <img
                                      className="business-page-marker-pin-02-icon1"
                                      alt=""
                                      src={markerpin02_1}
                                    />
                                  </div>
                                  <div className="business-page-location">India, New Delhi</div>
                                </div>
                                <div className="business-page-pin-icon">
                                  <div className="business-page-location-pin">
                                    <img
                                      className="business-page-marker-pin-02-icon1"
                                      alt=""
                                      src={calendar}
                                    />
                                  </div>
                                  <div className="business-page-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="business-page-job-description">
                                <div className="business-page-job-title">
                                  <div className="business-page-job-name">4.9/5</div>
                                </div>
                                <div className="business-page-supporting-text-wrapper">
                                  <div className="business-page-supporting-text4">99,999 reviews</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="business-page-frame-wrapper15">
                            <div className="business-page-job-details">
                              <div className="business-page-job-meta">$9,999,999.99</div>
                              <div className="business-page-supporting-text-frame">
                                <div className="business-page-supporting-text5">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <div className="business-page-team-divider">
                            <img
                              className="business-page-divider-icon12"
                              alt=""
                              src={divider_11}
                            />
                          </div>
                          <div className="business-page-team-member-card">
                            <div className="business-page-member-card-content">
                              <img
                                className="business-page-review-member-details-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="business-page-testimonial-member-name-role-parent">
                                <div className="business-page-testimonial-member-name-role">
                                  <div className="business-page-name">Olivia Rhye</div>
                                </div>
                                <div className="business-page-role">Business Name</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="business-page-testiomonial-carousel-arrow">
                  <img
                    className="business-page-arrow-left-icon"
                    loading="lazy"
                    alt=""
                    src={arrowleft}
                  />
                </div>
              </div>
              <div className="business-page-testiomonial-carousel-arrow1">
                <img className="business-page-arrow-left-icon" alt="" src={arrowright} />
              </div>
            </section>
            <div className="business-page-frame-wrapper7">
              <div className="business-page-heading-parent6">
                <h3 className="business-page-heading23">Spares</h3>
                <div className="business-page-text-container">
                  <a className="business-page-testimonial-carousel-labels">View All</a>
                </div>
              </div>
            </div>
            <section className="business-page-frame-wrapper17">
              <div className="business-page-frame-parent39">
                <div className="business-page-frame-parent40">
                  <div className="business-page-rectangle-parent11">
                    <div className="business-page-frame-child12"></div>
                    <div className="business-page-testimonial-card">
                      <img
                        className="business-page-testimonial-card-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="business-page-image-overlay">
                        <div className="business-page-badge3">
                          <div className="business-page-text49">Assured</div>
                        </div>
                        <div className="business-page-overlay">
                          <div className="business-page-badge4">
                            <div className="business-page-badge-label">ID# AXJYN</div>
                          </div>
                          <div className="business-page-buttonsbutton13">
                            <img
                              className="business-page-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-page-card-content">
                      <div className="business-page-card-info">
                        <div className="business-page-business-name">
                          <div className="business-page-business">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="business-page-card-details">
                          <div className="business-page-bottom-content">
                            <div className="business-page-label-and-text">
                              <div className="business-page-job-location">
                                <div className="business-page-pin-icon">
                                  <div className="business-page-location-pin">
                                    <img
                                      className="business-page-marker-pin-02-icon1"
                                      alt=""
                                      src={markerpin02_1}
                                    />
                                  </div>
                                  <div className="business-page-location">India, New Delhi</div>
                                </div>
                                <div className="business-page-pin-icon">
                                  <div className="business-page-location-pin">
                                    <img
                                      className="business-page-marker-pin-02-icon1"
                                      alt=""
                                      src={calendar}
                                    />
                                  </div>
                                  <div className="business-page-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="business-page-supporting-description">
                                <div className="business-page-job-title">
                                  <div className="business-page-job-name">4.9/5</div>
                                </div>
                                <div className="business-page-supporting-text-wrapper">
                                  <div className="business-page-supporting-text4">99,999 reviews</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="business-page-bottom-info">
                            <div className="business-page-job-details">
                              <div className="business-page-job-meta">$9,999,999.99</div>
                              <div className="business-page-supporting-text-frame">
                                <div className="business-page-supporting-text5">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="business-page-divider-icon3"
                            alt=""
                            src={divider_11}
                          />
                        </div>
                        <div className="business-page-testimonial-team-member">
                          <div className="business-page-testimonial-member-details">
                            <div className="business-page-review-member-details">
                              <img
                                className="business-page-review-member-details-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="business-page-testimonial-member-name-role-parent">
                                <div className="business-page-name-container">
                                  <div className="business-page-name">Olivia Rhye</div>
                                </div>
                                <div className="business-page-role">Business Name</div>
                              </div>
                            </div>
                            <div className="business-page-testimonial-member-icon">
                              <img className="business-page-icon1" alt="" src={icon_1} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="business-page-rectangle-parent11">
                    <div className="business-page-frame-child12"></div>
                    <div className="business-page-testimonial-card">
                      <img
                        className="business-page-testimonial-card-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="business-page-image-overlay">
                        <div className="business-page-badge3">
                          <div className="business-page-text49">Assured</div>
                        </div>
                        <div className="business-page-overlay">
                          <div className="business-page-badge4">
                            <div className="business-page-badge-label">ID# AXJYN</div>
                          </div>
                          <div className="business-page-buttonsbutton13">
                            <img
                              className="business-page-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-page-card-content">
                      <div className="business-page-card-info">
                        <div className="business-page-business-name">
                          <div className="business-page-business">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="business-page-card-details">
                          <div className="business-page-bottom-content">
                            <div className="business-page-label-and-text">
                              <div className="business-page-job-location">
                                <div className="business-page-pin-icon">
                                  <div className="business-page-location-pin">
                                    <img
                                      className="business-page-marker-pin-02-icon1"
                                      alt=""
                                      src={markerpin02_1}
                                    />
                                  </div>
                                  <div className="business-page-location">India, New Delhi</div>
                                </div>
                                <div className="business-page-pin-icon">
                                  <div className="business-page-location-pin">
                                    <img
                                      className="business-page-marker-pin-02-icon1"
                                      alt=""
                                      src={calendar}
                                    />
                                  </div>
                                  <div className="business-page-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="business-page-supporting-description">
                                <div className="business-page-job-title">
                                  <div className="business-page-job-name">4.9/5</div>
                                </div>
                                <div className="business-page-supporting-text-wrapper">
                                  <div className="business-page-supporting-text4">99,999 reviews</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="business-page-bottom-info">
                            <div className="business-page-job-details">
                              <div className="business-page-job-meta">$9,999,999.99</div>
                              <div className="business-page-supporting-text-frame">
                                <div className="business-page-supporting-text5">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="business-page-divider-icon3"
                            alt=""
                            src={divider_11}
                          />
                        </div>
                        <div className="business-page-testimonial-team-member">
                          <div className="business-page-testimonial-member-details">
                            <div className="business-page-review-member-details">
                              <img
                                className="business-page-review-member-details-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="business-page-testimonial-member-name-role-parent">
                                <div className="business-page-name-container">
                                  <div className="business-page-name">Olivia Rhye</div>
                                </div>
                                <div className="business-page-role">Business Name</div>
                              </div>
                            </div>
                            <div className="business-page-testimonial-member-icon">
                              <img className="business-page-icon1" alt="" src={icon_1} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="business-page-rectangle-parent11">
                    <div className="business-page-frame-child12"></div>
                    <div className="business-page-testimonial-card">
                      <img
                        className="business-page-testimonial-card-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="business-page-image-overlay">
                        <div className="business-page-badge3">
                          <div className="business-page-text49">Assured</div>
                        </div>
                        <div className="business-page-overlay">
                          <div className="business-page-badge4">
                            <div className="business-page-badge-label">ID# AXJYN</div>
                          </div>
                          <div className="business-page-buttonsbutton13">
                            <img
                              className="business-page-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-page-card-content">
                      <div className="business-page-card-info">
                        <div className="business-page-business-name">
                          <div className="business-page-business">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="business-page-card-details">
                          <div className="business-page-bottom-content">
                            <div className="business-page-label-and-text">
                              <div className="business-page-job-location">
                                <div className="business-page-pin-icon">
                                  <div className="business-page-location-pin">
                                    <img
                                      className="business-page-marker-pin-02-icon1"
                                      alt=""
                                      src={markerpin02_1}
                                    />
                                  </div>
                                  <div className="business-page-location">India, New Delhi</div>
                                </div>
                                <div className="business-page-pin-icon">
                                  <div className="business-page-location-pin">
                                    <img
                                      className="business-page-marker-pin-02-icon1"
                                      alt=""
                                      src={calendar}
                                    />
                                  </div>
                                  <div className="business-page-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="business-page-supporting-description">
                                <div className="business-page-job-title">
                                  <div className="business-page-job-name">4.9/5</div>
                                </div>
                                <div className="business-page-supporting-text-wrapper">
                                  <div className="business-page-supporting-text4">99,999 reviews</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="business-page-bottom-info">
                            <div className="business-page-job-details">
                              <div className="business-page-job-meta">$9,999,999.99</div>
                              <div className="business-page-supporting-text-frame">
                                <div className="business-page-supporting-text5">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="business-page-divider-icon3"
                            alt=""
                            src={divider_11}
                          />
                        </div>
                        <div className="business-page-testimonial-team-member">
                          <div className="business-page-testimonial-member-details">
                            <div className="business-page-review-member-details">
                              <img
                                className="business-page-review-member-details-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="business-page-testimonial-member-name-role-parent">
                                <div className="business-page-name-container">
                                  <div className="business-page-name">Olivia Rhye</div>
                                </div>
                                <div className="business-page-role">Business Name</div>
                              </div>
                            </div>
                            <div className="business-page-testimonial-member-icon">
                              <img className="business-page-icon1" alt="" src={icon_1} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="business-page-rectangle-parent11">
                    <div className="business-page-frame-child12"></div>
                    <div className="business-page-testimonial-card">
                      <img
                        className="business-page-testimonial-card-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="business-page-image-overlay3">
                        <div className="business-page-badge3">
                          <div className="business-page-text49">Assured</div>
                        </div>
                        <div className="business-page-badge-container">
                          <div className="business-page-badge4">
                            <div className="business-page-badge-label">ID# AXJYN</div>
                          </div>
                          <div className="business-page-buttonsbutton16">
                            <img
                              className="business-page-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-page-card-content">
                      <div className="business-page-icon-parent">
                        <img className="business-page-icon4" alt="" src={icon_1} />

                        <div className="business-page-card-info">
                          <div className="business-page-business-name">
                            <div className="business-page-business">
                              New / Surplus 2021 Philips Health-care HD11 XE
                              (P/N:23A...
                            </div>
                          </div>
                          <div className="business-page-frame-wrapper14">
                            <div className="business-page-label-and-text">
                              <div className="business-page-job-location">
                                <div className="business-page-pin-icon">
                                  <div className="business-page-location-pin">
                                    <img
                                      className="business-page-marker-pin-02-icon1"
                                      alt=""
                                      src={markerpin02_1}
                                    />
                                  </div>
                                  <div className="business-page-location">India, New Delhi</div>
                                </div>
                                <div className="business-page-pin-icon">
                                  <div className="business-page-location-pin">
                                    <img
                                      className="business-page-marker-pin-02-icon1"
                                      alt=""
                                      src={calendar}
                                    />
                                  </div>
                                  <div className="business-page-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="business-page-supporting-description">
                                <div className="business-page-job-title">
                                  <div className="business-page-job-name">4.9/5</div>
                                </div>
                                <div className="business-page-supporting-text-wrapper">
                                  <div className="business-page-supporting-text4">99,999 reviews</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="business-page-frame-wrapper15">
                            <div className="business-page-job-details">
                              <div className="business-page-job-meta">$9,999,999.99</div>
                              <div className="business-page-supporting-text-frame">
                                <div className="business-page-supporting-text5">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <div className="business-page-team-divider">
                            <img
                              className="business-page-divider-icon12"
                              alt=""
                              src={divider_11}
                            />
                          </div>
                          <div className="business-page-team-member-card">
                            <div className="business-page-member-card-content">
                              <img
                                className="business-page-review-member-details-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="business-page-testimonial-member-name-role-parent">
                                <div className="business-page-name-container">
                                  <div className="business-page-name">Olivia Rhye</div>
                                </div>
                                <div className="business-page-role">Business Name</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="business-page-testiomonial-carousel-arrow2">
                    <img
                      className="business-page-arrow-left-icon"
                      alt=""
                      src={arrowright}
                    />
                  </div>
                </div>
                <div className="business-page-testiomonial-carousel-arrow3">
                  <img
                    className="business-page-arrow-left-icon"
                    alt=""
                    src={arrowleft}
                  />
                </div>
              </div>
            </section>
            <div className="business-page-frame-wrapper7">
              <div className="business-page-heading-parent6">
                <h3 className="business-page-heading23">Services</h3>
                <div className="business-page-text-container">
                  <a className="business-page-testimonial-carousel-labels">View All</a>
                </div>
              </div>
            </div>
            <section className="business-page-frame-wrapper17">
              <div className="business-page-frame-parent39">
                <div className="business-page-frame-parent40">
                  <div className="business-page-rectangle-parent11">
                    <div className="business-page-frame-child12"></div>
                    <div className="business-page-testimonial-card">
                      <img
                        className="business-page-testimonial-card-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="business-page-image-overlay">
                        <div className="business-page-badge3">
                          <div className="business-page-text49">Assured</div>
                        </div>
                        <div className="business-page-overlay">
                          <div className="business-page-badge4">
                            <div className="business-page-badge-label">ID# AXJYN</div>
                          </div>
                          <div className="business-page-buttonsbutton13">
                            <img
                              className="business-page-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-page-frame-wrapper20">
                      <div className="business-page-card-info">
                        <div className="business-page-text-wrapper12">
                          <div className="business-page-business">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="business-page-frame-parent56">
                          <div className="business-page-bottom-content">
                            <div className="business-page-label-and-text">
                              <div className="business-page-job-location">
                                <div className="business-page-pin-icon">
                                  <div className="business-page-location-pin">
                                    <img
                                      className="business-page-marker-pin-02-icon1"
                                      alt=""
                                      src={markerpin02_1}
                                    />
                                  </div>
                                  <div className="business-page-location">India, New Delhi</div>
                                </div>
                                <div className="business-page-pin-icon">
                                  <div className="business-page-location-pin">
                                    <img
                                      className="business-page-marker-pin-02-icon1"
                                      alt=""
                                      src={calendar}
                                    />
                                  </div>
                                  <div className="business-page-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="business-page-supporting-description">
                                <div className="business-page-job-title">
                                  <div className="business-page-job-name">4.9/5</div>
                                </div>
                                <div className="business-page-supporting-text-wrapper">
                                  <div className="business-page-supporting-text4">99,999 reviews</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="business-page-bottom-info">
                            <div className="business-page-job-details">
                              <div className="business-page-job-meta">$9,999,999.99</div>
                              <div className="business-page-supporting-text-frame">
                                <div className="business-page-supporting-text5">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="business-page-divider-icon3"
                            alt=""
                            src={divider_11}
                          />

                          <div className="business-page-team-wrapper">
                            <div className="business-page-testimonial-member-details">
                              <div className="business-page-review-member-details">
                                <img
                                  className="business-page-review-member-details-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="business-page-testimonial-member-name-role-parent">
                                  <div className="business-page-name-container">
                                    <div className="business-page-name">Olivia Rhye</div>
                                  </div>
                                  <div className="business-page-role">Business Name</div>
                                </div>
                              </div>
                              <div className="business-page-testimonial-member-icon">
                                <img
                                  className="business-page-icon1"
                                  alt=""
                                  src={icon_1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="business-page-rectangle-parent11">
                    <div className="business-page-frame-child12"></div>
                    <div className="business-page-testimonial-card">
                      <img
                        className="business-page-testimonial-card-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="business-page-image-overlay">
                        <div className="business-page-badge3">
                          <div className="business-page-text49">Assured</div>
                        </div>
                        <div className="business-page-overlay">
                          <div className="business-page-badge4">
                            <div className="business-page-badge-label">ID# AXJYN</div>
                          </div>
                          <div className="business-page-buttonsbutton13">
                            <img
                              className="business-page-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-page-frame-wrapper20">
                      <div className="business-page-card-info">
                        <div className="business-page-text-wrapper12">
                          <div className="business-page-business">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="business-page-frame-parent56">
                          <div className="business-page-bottom-content">
                            <div className="business-page-label-and-text">
                              <div className="business-page-job-location">
                                <div className="business-page-pin-icon">
                                  <div className="business-page-location-pin">
                                    <img
                                      className="business-page-marker-pin-02-icon1"
                                      alt=""
                                      src={markerpin02_1}
                                    />
                                  </div>
                                  <div className="business-page-location">India, New Delhi</div>
                                </div>
                                <div className="business-page-pin-icon">
                                  <div className="business-page-location-pin">
                                    <img
                                      className="business-page-marker-pin-02-icon1"
                                      alt=""
                                      src={calendar}
                                    />
                                  </div>
                                  <div className="business-page-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="business-page-supporting-description">
                                <div className="business-page-job-title">
                                  <div className="business-page-job-name">4.9/5</div>
                                </div>
                                <div className="business-page-supporting-text-wrapper">
                                  <div className="business-page-supporting-text4">99,999 reviews</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="business-page-bottom-info">
                            <div className="business-page-job-details">
                              <div className="business-page-job-meta">$9,999,999.99</div>
                              <div className="business-page-supporting-text-frame">
                                <div className="business-page-supporting-text5">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="business-page-divider-icon3"
                            alt=""
                            src={divider_11}
                          />

                          <div className="business-page-team-wrapper">
                            <div className="business-page-testimonial-member-details">
                              <div className="business-page-review-member-details">
                                <img
                                  className="business-page-review-member-details-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="business-page-testimonial-member-name-role-parent">
                                  <div className="business-page-name-container">
                                    <div className="business-page-name">Olivia Rhye</div>
                                  </div>
                                  <div className="business-page-role">Business Name</div>
                                </div>
                              </div>
                              <div className="business-page-testimonial-member-icon">
                                <img
                                  className="business-page-icon1"
                                  alt=""
                                  src={icon_1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="business-page-rectangle-parent11">
                    <div className="business-page-frame-child12"></div>
                    <div className="business-page-testimonial-card">
                      <img
                        className="business-page-testimonial-card-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="business-page-image-overlay">
                        <div className="business-page-badge3">
                          <div className="business-page-text49">Assured</div>
                        </div>
                        <div className="business-page-overlay">
                          <div className="business-page-badge4">
                            <div className="business-page-badge-label">ID# AXJYN</div>
                          </div>
                          <div className="business-page-buttonsbutton13">
                            <img
                              className="business-page-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-page-card-content">
                      <div className="business-page-card-info">
                        <div className="business-page-business-name">
                          <div className="business-page-business">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="business-page-card-details">
                          <div className="business-page-bottom-content">
                            <div className="business-page-label-and-text">
                              <div className="business-page-job-location">
                                <div className="business-page-pin-icon">
                                  <div className="business-page-location-pin">
                                    <img
                                      className="business-page-marker-pin-02-icon1"
                                      alt=""
                                      src={markerpin02_1}
                                    />
                                  </div>
                                  <div className="business-page-location">India, New Delhi</div>
                                </div>
                                <div className="business-page-pin-icon">
                                  <div className="business-page-location-pin">
                                    <img
                                      className="business-page-marker-pin-02-icon1"
                                      alt=""
                                      src={calendar}
                                    />
                                  </div>
                                  <div className="business-page-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="business-page-supporting-description">
                                <div className="business-page-job-title">
                                  <div className="business-page-job-name">4.9/5</div>
                                </div>
                                <div className="business-page-supporting-text-wrapper">
                                  <div className="business-page-supporting-text4">99,999 reviews</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="business-page-bottom-info">
                            <div className="business-page-job-details">
                              <div className="business-page-job-meta">$9,999,999.99</div>
                              <div className="business-page-supporting-text-frame">
                                <div className="business-page-supporting-text5">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="business-page-divider-icon3"
                            alt=""
                            src={divider_11}
                          />
                        </div>
                        <div className="business-page-testimonial-team-member">
                          <div className="business-page-testimonial-member-details">
                            <div className="business-page-review-member-details">
                              <img
                                className="business-page-review-member-details-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="business-page-testimonial-member-name-role-parent">
                                <div className="business-page-name-container">
                                  <div className="business-page-name">Olivia Rhye</div>
                                </div>
                                <div className="business-page-role">Business Name</div>
                              </div>
                            </div>
                            <div className="business-page-testimonial-member-icon">
                              <img className="business-page-icon1" alt="" src={icon_1} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="business-page-rectangle-parent11">
                    <div className="business-page-frame-child12"></div>
                    <div className="business-page-testimonial-card">
                      <img
                        className="business-page-testimonial-card-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="business-page-image-overlay3">
                        <div className="business-page-badge3">
                          <div className="business-page-text49">Assured</div>
                        </div>
                        <div className="business-page-badge-container">
                          <div className="business-page-badge4">
                            <div className="business-page-badge-label">ID# AXJYN</div>
                          </div>
                          <div className="business-page-buttonsbutton16">
                            <img
                              className="business-page-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-page-card-content">
                      <div className="business-page-icon-parent">
                        <img className="business-page-icon4" alt="" src={icon_1} />

                        <div className="business-page-card-info">
                          <div className="business-page-business-name">
                            <div className="business-page-business">
                              New / Surplus 2021 Philips Health-care HD11 XE
                              (P/N:23A...
                            </div>
                          </div>
                          <div className="business-page-frame-wrapper14">
                            <div className="business-page-label-and-text">
                              <div className="business-page-job-location">
                                <div className="business-page-pin-icon">
                                  <div className="business-page-location-pin">
                                    <img
                                      className="business-page-marker-pin-02-icon1"
                                      alt=""
                                      src={markerpin02_1}
                                    />
                                  </div>
                                  <div className="business-page-location">India, New Delhi</div>
                                </div>
                                <div className="business-page-pin-icon">
                                  <div className="business-page-location-pin">
                                    <img
                                      className="business-page-marker-pin-02-icon1"
                                      alt=""
                                      src={calendar}
                                    />
                                  </div>
                                  <div className="business-page-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="business-page-supporting-description">
                                <div className="business-page-job-title">
                                  <div className="business-page-job-name">4.9/5</div>
                                </div>
                                <div className="business-page-supporting-text-wrapper">
                                  <div className="business-page-supporting-text4">99,999 reviews</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="business-page-frame-wrapper15">
                            <div className="business-page-job-details">
                              <div className="business-page-job-meta">$9,999,999.99</div>
                              <div className="business-page-supporting-text-frame">
                                <div className="business-page-supporting-text5">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <div className="business-page-team-divider">
                            <img
                              className="business-page-divider-icon12"
                              alt=""
                              src={divider_11}
                            />
                          </div>
                          <div className="business-page-team-member-card">
                            <div className="business-page-member-card-content">
                              <img
                                className="business-page-review-member-details-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="business-page-testimonial-member-name-role-parent">
                                <div className="business-page-name-container">
                                  <div className="business-page-name">Olivia Rhye</div>
                                </div>
                                <div className="business-page-role">Business Name</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="business-page-testiomonial-carousel-arrow4">
                    <img
                      className="business-page-arrow-left-icon"
                      alt=""
                      src={arrowright}
                    />
                  </div>
                </div>
                <div className="business-page-testiomonial-carousel-arrow5">
                  <img
                    className="business-page-arrow-left-icon"
                    alt=""
                    src={arrowleft}
                  />
                </div>
              </div>
            </section>
            <section className="business-page-frame-wrapper34">
              <div className="business-page-frame-parent2">
                <div className="business-page-frame-wrapper35">
                  <div className="business-page-heading-parent6">
                    <h3 className="business-page-heading23">Software</h3>
                    <div className="business-page-text-container">
                      <a className="business-page-testimonial-carousel-labels">View All</a>
                    </div>
                  </div>
                </div>
                <div className="business-page-frame-parent90">
                  <div className="business-page-frame-parent40">
                    <div className="business-page-rectangle-parent11">
                      <div className="business-page-frame-child12"></div>
                      <div className="business-page-testimonial-card">
                        <img
                          className="business-page-testimonial-card-child"
                          alt=""
                          src={rectangle_95_2x}
                        />

                        <div className="business-page-image-overlay">
                          <div className="business-page-badge3">
                            <div className="business-page-text49">Assured</div>
                          </div>
                          <div className="business-page-overlay">
                            <div className="business-page-badge4">
                              <div className="business-page-badge-label">ID# AXJYN</div>
                            </div>
                            <div className="business-page-buttonsbutton13">
                              <img
                                className="business-page-magnifier-1-icon"
                                alt=""
                                src={radar_1_1_2x}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="business-page-frame-wrapper20">
                        <div className="business-page-card-info">
                          <div className="business-page-text-wrapper12">
                            <div className="business-page-business">
                              New / Surplus 2021 Philips Health-care HD11 XE
                              (P/N:23A...
                            </div>
                          </div>
                          <div className="business-page-frame-parent56">
                            <div className="business-page-bottom-content">
                              <div className="business-page-label-and-text">
                                <div className="business-page-job-location">
                                  <div className="business-page-pin-icon">
                                    <div className="business-page-location-pin">
                                      <img
                                        className="business-page-marker-pin-02-icon1"
                                        alt=""
                                        src={markerpin02_1}
                                      />
                                    </div>
                                    <div className="business-page-location">India, New Delhi</div>
                                  </div>
                                  <div className="business-page-pin-icon">
                                    <div className="business-page-location-pin">
                                      <img
                                        className="business-page-marker-pin-02-icon1"
                                        alt=""
                                        src={calendar}
                                      />
                                    </div>
                                    <div className="business-page-type-of-work">April 09, 2023</div>
                                  </div>
                                </div>
                                <div className="business-page-supporting-description">
                                  <div className="business-page-job-title">
                                    <div className="business-page-job-name">4.9/5</div>
                                  </div>
                                  <div className="business-page-supporting-text-wrapper">
                                    <div className="business-page-supporting-text4">
                                      99,999 reviews
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="business-page-bottom-info">
                              <div className="business-page-job-details">
                                <div className="business-page-job-meta">$9,999,999.99</div>
                                <div className="business-page-supporting-text-frame">
                                  <div className="business-page-supporting-text5">USD-Per Unit</div>
                                </div>
                              </div>
                            </div>
                            <img
                              className="business-page-divider-icon3"
                              alt=""
                              src={divider_11}
                            />

                            <div className="business-page-team-wrapper">
                              <div className="business-page-testimonial-member-details">
                                <div className="business-page-review-member-details">
                                  <img
                                    className="business-page-review-member-details-child"
                                    alt=""
                                    src={group_58_2x}
                                  />

                                  <div className="business-page-testimonial-member-name-role-parent">
                                    <div className="business-page-name-container">
                                      <div className="business-page-name">Olivia Rhye</div>
                                    </div>
                                    <div className="business-page-role">Business Name</div>
                                  </div>
                                </div>
                                <div className="business-page-testimonial-member-icon">
                                  <img
                                    className="business-page-icon1"
                                    alt=""
                                    src={icon_1}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-page-rectangle-parent11">
                      <div className="business-page-frame-child12"></div>
                      <div className="business-page-testimonial-card">
                        <img
                          className="business-page-testimonial-card-child"
                          alt=""
                          src={rectangle_95_2x}
                        />

                        <div className="business-page-image-overlay">
                          <div className="business-page-badge3">
                            <div className="business-page-text49">Assured</div>
                          </div>
                          <div className="business-page-overlay">
                            <div className="business-page-badge4">
                              <div className="business-page-badge-label">ID# AXJYN</div>
                            </div>
                            <div className="business-page-buttonsbutton13">
                              <img
                                className="business-page-magnifier-1-icon"
                                alt=""
                                src={radar_1_1_2x}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="business-page-frame-wrapper20">
                        <div className="business-page-card-info">
                          <div className="business-page-text-wrapper12">
                            <div className="business-page-business">
                              New / Surplus 2021 Philips Health-care HD11 XE
                              (P/N:23A...
                            </div>
                          </div>
                          <div className="business-page-frame-parent56">
                            <div className="business-page-bottom-content">
                              <div className="business-page-label-and-text">
                                <div className="business-page-job-location">
                                  <div className="business-page-pin-icon">
                                    <div className="business-page-location-pin">
                                      <img
                                        className="business-page-marker-pin-02-icon1"
                                        alt=""
                                        src={markerpin02_1}
                                      />
                                    </div>
                                    <div className="business-page-location">India, New Delhi</div>
                                  </div>
                                  <div className="business-page-pin-icon">
                                    <div className="business-page-location-pin">
                                      <img
                                        className="business-page-marker-pin-02-icon1"
                                        alt=""
                                        src={calendar}
                                      />
                                    </div>
                                    <div className="business-page-type-of-work">April 09, 2023</div>
                                  </div>
                                </div>
                                <div className="business-page-supporting-description">
                                  <div className="business-page-job-title">
                                    <div className="business-page-job-name">4.9/5</div>
                                  </div>
                                  <div className="business-page-supporting-text-wrapper">
                                    <div className="business-page-supporting-text4">
                                      99,999 reviews
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="business-page-bottom-info">
                              <div className="business-page-job-details">
                                <div className="business-page-job-meta">$9,999,999.99</div>
                                <div className="business-page-supporting-text-frame">
                                  <div className="business-page-supporting-text5">USD-Per Unit</div>
                                </div>
                              </div>
                            </div>
                            <img
                              className="business-page-divider-icon3"
                              alt=""
                              src={divider_11}
                            />

                            <div className="business-page-team-wrapper">
                              <div className="business-page-testimonial-member-details">
                                <div className="business-page-review-member-details">
                                  <img
                                    className="business-page-review-member-details-child"
                                    alt=""
                                    src={group_58_2x}
                                  />

                                  <div className="business-page-testimonial-member-name-role-parent">
                                    <div className="business-page-name-container">
                                      <div className="business-page-name">Olivia Rhye</div>
                                    </div>
                                    <div className="business-page-role">Business Name</div>
                                  </div>
                                </div>
                                <div className="business-page-testimonial-member-icon">
                                  <img
                                    className="business-page-icon1"
                                    alt=""
                                    src={icon_1}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-page-rectangle-parent11">
                      <div className="business-page-frame-child12"></div>
                      <div className="business-page-testimonial-card">
                        <img
                          className="business-page-testimonial-card-child"
                          alt=""
                          src={rectangle_95_2x}
                        />

                        <div className="business-page-image-overlay">
                          <div className="business-page-badge3">
                            <div className="business-page-text49">Assured</div>
                          </div>
                          <div className="business-page-overlay">
                            <div className="business-page-badge4">
                              <div className="business-page-badge-label">ID# AXJYN</div>
                            </div>
                            <div className="business-page-buttonsbutton13">
                              <img
                                className="business-page-magnifier-1-icon"
                                alt=""
                                src={radar_1_1_2x}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="business-page-card-content">
                        <div className="business-page-card-info">
                          <div className="business-page-business-name">
                            <div className="business-page-business">
                              New / Surplus 2021 Philips Health-care HD11 XE
                              (P/N:23A...
                            </div>
                          </div>
                          <div className="business-page-card-details">
                            <div className="business-page-bottom-content">
                              <div className="business-page-label-and-text">
                                <div className="business-page-job-location">
                                  <div className="business-page-pin-icon">
                                    <div className="business-page-location-pin">
                                      <img
                                        className="business-page-marker-pin-02-icon1"
                                        alt=""
                                        src={markerpin02_1}
                                      />
                                    </div>
                                    <div className="business-page-location">India, New Delhi</div>
                                  </div>
                                  <div className="business-page-pin-icon">
                                    <div className="business-page-location-pin">
                                      <img
                                        className="business-page-marker-pin-02-icon1"
                                        alt=""
                                        src={calendar}
                                      />
                                    </div>
                                    <div className="business-page-type-of-work">April 09, 2023</div>
                                  </div>
                                </div>
                                <div className="business-page-supporting-description">
                                  <div className="business-page-job-title">
                                    <div className="business-page-job-name">4.9/5</div>
                                  </div>
                                  <div className="business-page-supporting-text-wrapper">
                                    <div className="business-page-supporting-text4">
                                      99,999 reviews
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="business-page-bottom-info">
                              <div className="business-page-job-details">
                                <div className="business-page-job-meta">$9,999,999.99</div>
                                <div className="business-page-supporting-text-frame">
                                  <div className="business-page-supporting-text5">USD-Per Unit</div>
                                </div>
                              </div>
                            </div>
                            <img
                              className="business-page-divider-icon3"
                              alt=""
                              src={divider_11}
                            />
                          </div>
                          <div className="business-page-testimonial-team-member">
                            <div className="business-page-testimonial-member-details">
                              <div className="business-page-review-member-details">
                                <img
                                  className="business-page-review-member-details-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="business-page-testimonial-member-name-role-parent">
                                  <div className="business-page-name-container">
                                    <div className="business-page-name">Olivia Rhye</div>
                                  </div>
                                  <div className="business-page-role">Business Name</div>
                                </div>
                              </div>
                              <div className="business-page-testimonial-member-icon">
                                <img
                                  className="business-page-icon1"
                                  alt=""
                                  src={icon_1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-page-rectangle-parent11">
                      <div className="business-page-frame-child12"></div>
                      <div className="business-page-testimonial-card">
                        <img
                          className="business-page-testimonial-card-child"
                          alt=""
                          src={rectangle_95_2x}
                        />

                        <div className="business-page-image-overlay3">
                          <div className="business-page-badge3">
                            <div className="business-page-text49">Assured</div>
                          </div>
                          <div className="business-page-badge-container">
                            <div className="business-page-badge4">
                              <div className="business-page-badge-label">ID# AXJYN</div>
                            </div>
                            <div className="business-page-buttonsbutton16">
                              <img
                                className="business-page-magnifier-1-icon"
                                alt=""
                                src={radar_1_1_2x}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="business-page-card-content">
                        <div className="business-page-card-info">
                          <div className="business-page-business-name">
                            <div className="business-page-business">
                              New / Surplus 2021 Philips Health-care HD11 XE
                              (P/N:23A...
                            </div>
                          </div>
                          <div className="business-page-frame-wrapper14">
                            <div className="business-page-label-and-text">
                              <div className="business-page-job-location">
                                <div className="business-page-pin-icon">
                                  <div className="business-page-location-pin">
                                    <img
                                      className="business-page-marker-pin-02-icon1"
                                      alt=""
                                      src={markerpin02_1}
                                    />
                                  </div>
                                  <div className="business-page-location">India, New Delhi</div>
                                </div>
                                <div className="business-page-pin-icon">
                                  <div className="business-page-location-pin">
                                    <img
                                      className="business-page-marker-pin-02-icon1"
                                      alt=""
                                      src={calendar}
                                    />
                                  </div>
                                  <div className="business-page-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="business-page-supporting-description">
                                <div className="business-page-job-title">
                                  <div className="business-page-job-name">4.9/5</div>
                                </div>
                                <div className="business-page-supporting-text-wrapper">
                                  <div className="business-page-supporting-text4">99,999 reviews</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="business-page-frame-wrapper15">
                            <div className="business-page-job-details">
                              <div className="business-page-job-meta">$9,999,999.99</div>
                              <div className="business-page-supporting-text-frame">
                                <div className="business-page-supporting-text5">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <div className="business-page-team3">
                            <img
                              className="business-page-divider-icon3"
                              alt=""
                              src={divider_11}
                            />

                            <div className="business-page-team-member-card">
                              <div className="business-page-member-card-content">
                                <img
                                  className="business-page-review-member-details-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="business-page-testimonial-member-name-role-parent">
                                  <div className="business-page-name-container">
                                    <div className="business-page-name">Olivia Rhye</div>
                                  </div>
                                  <div className="business-page-role">Business Name</div>
                                </div>
                              </div>
                            </div>
                            <img className="business-page-icon16" alt="" src={icon_1} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-page-testiomonial-carousel-arrow6">
                      <img
                        className="business-page-arrow-left-icon"
                        alt=""
                        src={arrowright}
                      />
                    </div>
                  </div>
                  <div className="business-page-testiomonial-carousel-arrow7">
                    <img
                      className="business-page-arrow-left-icon"
                      alt=""
                      src={arrowleft}
                    />
                  </div>
                </div>
              </div>
            </section>
            <img className="detailed-device-divider-icon3" alt="" src={divider1} />
            <footer className="business-page-footer">
              <div className="business-page-footer1">
                <div className="business-page-container1">
                  <div className="business-page-content11">
                    <div className="business-page-footer-info">
                      <div className="business-page-logo-and-supporting-text">
                        <div className="business-page-logo1">
                          <img
                            className="business-page-gerator-04-1-icon"
                            alt=""
                            src={gerator04_1_2x}
                          />
                        </div>
                        <div className="business-page-supporting-text36">
                          Buy, sell, lease or exchange medical devices, spares,
                          services and software with ease.
                        </div>
                      </div>
                      <div className="business-page-job-details">
                        <img
                          className="business-page-linkedin-logo-2-icon"
                          alt=""
                          src={facebook_1_2_2x}
                        />

                        <img
                          className="business-page-linkedin-logo-2-icon"
                          alt=""
                          src={linkedinlogo_2_2x}
                        />

                        <img
                          className="business-page-linkedin-logo-2-icon"
                          alt=""
                          src={instagram_1_3_2x}
                        />

                        <img
                          className="business-page-linkedin-logo-2-icon"
                          alt=""
                          src={youtube_1_1_2x}
                        />

                        <img
                          className="business-page-linkedin-logo-2-icon"
                          alt=""
                          src={vimeo_1_2_2x}
                        />
                      </div>
                    </div>
                    <div className="business-page-links-and-newsletter">
                      <div className="business-page-links">
                        <div className="business-page-footer-links-column">
                          <div className="business-page-text2">Buy & Sell</div>
                          <div className="business-page-footer-links">
                            <div className="business-page-footer-link">
                              <div className="business-page-buttonsbutton29">
                                <img
                                  className="business-page-chevron-down-icon"
                                  alt=""
                                  src={placeholder1}
                                />

                                <div className="business-page-text122">Devices</div>
                                <img
                                  className="business-page-chevron-down-icon"
                                  alt=""
                                  src={placeholder1}
                                />
                              </div>
                            </div>
                            <div className="business-page-footer-link">
                              <div className="business-page-buttonsbutton29">
                                <img
                                  className="business-page-chevron-down-icon"
                                  alt=""
                                  src={placeholder1}
                                />

                                <div className="business-page-text123">Spares</div>
                                <img
                                  className="business-page-chevron-down-icon"
                                  alt=""
                                  src={placeholder1}
                                />
                              </div>
                            </div>
                            <div className="business-page-footer-link2">
                              <div className="business-page-buttonsbutton29">
                                <img
                                  className="business-page-chevron-down-icon"
                                  alt=""
                                  src={placeholder1}
                                />

                                <div className="business-page-text124">Services</div>
                                <img
                                  className="business-page-chevron-down-icon"
                                  alt=""
                                  src={placeholder1}
                                />
                              </div>
                              <div className="business-page-badge35">
                                <div className="business-page-text125">New</div>
                              </div>
                            </div>
                            <div className="business-page-footer-link">
                              <div className="business-page-buttonsbutton29">
                                <img
                                  className="business-page-chevron-down-icon"
                                  alt=""
                                  src={placeholder1}
                                />

                                <div className="business-page-text126">Software</div>
                                <img
                                  className="business-page-chevron-down-icon"
                                  alt=""
                                  src={placeholder1}
                                />
                              </div>
                            </div>
                            <div className="business-page-footer-link">
                              <div className="business-page-buttonsbutton29">
                                <img
                                  className="business-page-chevron-down-icon"
                                  alt=""
                                  src={placeholder1}
                                />

                                <div className="business-page-text127">Add Listings</div>
                                <img
                                  className="business-page-chevron-down-icon"
                                  alt=""
                                  src={placeholder1}
                                />
                              </div>
                            </div>
                            <div className="business-page-footer-link">
                              <div className="business-page-buttonsbutton29">
                                <img
                                  className="business-page-chevron-down-icon"
                                  alt=""
                                  src={placeholder1}
                                />

                                <div className="business-page-text128">Post a Job</div>
                                <img
                                  className="business-page-chevron-down-icon"
                                  alt=""
                                  src={placeholder1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="business-page-footer-links-column">
                          <div className="business-page-text2">Quick Links</div>
                          <div className="business-page-footer-links1">
                            <div className="business-page-footer-link">
                              <div className="business-page-buttonsbutton29">
                                <img
                                  className="business-page-chevron-down-icon"
                                  alt=""
                                  src={placeholder1}
                                />

                                <div className="business-page-text129">Terms of use</div>
                                <img
                                  className="business-page-chevron-down-icon"
                                  alt=""
                                  src={placeholder1}
                                />
                              </div>
                            </div>
                            <div className="business-page-footer-link">
                              <div className="business-page-buttonsbutton29">
                                <img
                                  className="business-page-chevron-down-icon"
                                  alt=""
                                  src={placeholder1}
                                />

                                <div className="business-page-text130">Privacy</div>
                                <img
                                  className="business-page-chevron-down-icon"
                                  alt=""
                                  src={placeholder1}
                                />
                              </div>
                            </div>
                            <div className="business-page-footer-link">
                              <div className="business-page-buttonsbutton29">
                                <img
                                  className="business-page-chevron-down-icon"
                                  alt=""
                                  src={placeholder1}
                                />

                                <div className="business-page-text131">Security</div>
                                <img
                                  className="business-page-chevron-down-icon"
                                  alt=""
                                  src={placeholder1}
                                />
                              </div>
                            </div>
                            <div className="business-page-footer-link">
                              <div className="business-page-buttonsbutton29">
                                <img
                                  className="business-page-chevron-down-icon"
                                  alt=""
                                  src={placeholder1}
                                />

                                <div className="business-page-text132">Verification</div>
                                <img
                                  className="business-page-chevron-down-icon"
                                  alt=""
                                  src={placeholder1}
                                />
                              </div>
                            </div>
                            <div className="business-page-footer-link">
                              <div className="business-page-buttonsbutton29">
                                <img
                                  className="business-page-chevron-down-icon"
                                  alt=""
                                  src={placeholder1}
                                />

                                <div className="business-page-text133">Pricing</div>
                                <img
                                  className="business-page-chevron-down-icon"
                                  alt=""
                                  src={placeholder1}
                                />
                              </div>
                            </div>
                            <div className="business-page-footer-link">
                              <div className="business-page-buttonsbutton29">
                                <img
                                  className="business-page-chevron-down-icon"
                                  alt=""
                                  src={placeholder1}
                                />

                                <div className="business-page-text134">Support</div>
                                <img
                                  className="business-page-chevron-down-icon"
                                  alt=""
                                  src={placeholder1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="business-page-newsletter">
                        <div className="business-page-text2">Stay up to date</div>
                        <div className="business-page-email-capture">
                          <div className="business-page-input-field2">
                            <div className="business-page-input-with-label6">
                              <div className="business-page-label10">Stay up to date</div>
                              <div className="business-page-input4">
                                <div className="business-page-button-wrap">
                                  <div className="business-page-text135">Enter your email</div>
                                </div>
                                <img
                                  className="business-page-help-icon"
                                  alt=""
                                  src={help_icon}
                                />
                              </div>
                            </div>
                            <div className="business-page-hint-text5">
                              This is a hint text to help user.
                            </div>
                          </div>
                          <button className="business-page-buttonsbutton41">
                            <img
                              className="business-page-chevron-down-icon"
                              alt=""
                              src={placeholder3}
                            />

                            <div className="business-page-text-padding4">
                              <div className="business-page-text136">Subscribe</div>
                            </div>
                            <img
                              className="business-page-chevron-down-icon"
                              alt=""
                              src={placeholder3}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
            <img className="detailed-device-divider-icon3" alt="" src={divider1} />
            <section className="business-page-footer2">
              <div className="business-page-footer3">
                <div className="business-page-container2">
                  <div className="business-page-content13">
                    <div className="business-page-footer-text">
                      © Gerator Asia LLP. All rights reserved.
                    </div>
                    <div className="business-page-logo2">
                      <div className="business-page-logomark-container">
                        <input
                          className="business-page-logomark-container-child"
                          placeholder="All prices are in USD"
                          type="text"
                        />

                        <div className="business-page-logomark">
                          <div className="business-page-logomark1">
                            <img
                              className="business-page-gerator-10-2-icon"
                              alt=""
                              src={gerator10_2_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
          {/* <div className="business-page-footer-divider">
            <img className="business-page-divider-icon12" alt="" src={divider_27} />
          </div> */}
          <img className="business-page-divider-icon30" alt="" src={divider_27} />
        </div>
      </>
    )
  }
  else {
    return <Loader />
  }

}

