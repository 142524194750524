import React, { useEffect, useState } from "react";
import "./ChatPageComponent.css"; // Correct relative path

import homeline1 from "../../icons/Gerator/chat_page/homeline-1.svg";
import chevronright from "../../icons/Gerator/chat_page/chevronright.svg";
import divider1 from "../../icons/Gerator/chat_page/divider1.svg";

const TitleHeader = () => {    
  return (
<div className="chatB-header-section-wrapper">
                      <div className="chatB-header-section">
                        <div className="chatB-container">
                          <div className="chatB-page-header">
                            <div className="chatB-breadcrumbs">
                              <div className="chatB-tabs">
                                <div className="chatB-breadcrumb-button-base">
                                  <img className="chatB-search-lg-icon" alt="" src={homeline1} />
                                </div>
                                <img className="chatB-chevron-right-icon" alt="" src={chevronright} />
                                <div className="chatB-breadcrumb-button-base1">
                                  <a className="chatB-crumb-labels">Dashboard</a>
                                </div>
                                <img className="chatB-chevron-right-icon" alt="" src={chevronright} />
                                <div className="chatB-breadcrumb-button-base2">
                                  <div className="chatB-text6">...</div>
                                </div>
                                <img className="chatB-chevron-right-icon2" alt="" src={chevronright} />
                                <div className="chatB-breadcrumb-button-base3">
                                  <div className="chatB-text7">Another link</div>
                                </div>
                                <img className="chatB-chevron-right-icon2" alt="" src={chevronright} />
                                <div className="chatB-breadcrumb-button-base4">
                                  <a className="chatB-text8">Messaging</a>
                                </div>
                              </div>
                            </div>
                            <img className="chatB-divider-icon1" alt="" src={divider1} />
                          </div>
                        </div>
                      </div>
                    </div>
  )};
  export {TitleHeader};
