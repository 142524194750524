import { SalesIQSpareDetailPage } from "../screens/SalesIQDetailsPage/SalesIQSpareDetailPage/SalesIQSpareDetailPage";
import { AddSpare } from "../screens/Spare/AddSpare/AddSpare";
import { ArchiveSpare } from "../screens/Spare/ArchiveSpare/ArchiveSpare";
import { ArchiveSpareNotLoggedIn } from "../screens/Spare/ArchiveSpare/ArchiveSpareNotLoggedIn";
import { DetailedSpare } from "../screens/Spare/DetailedSpare/DetailedSpare";
import { EditSpare } from "../screens/Spare/EditSpare/EditSpare";

export const spareRoutes = [
  {
    path: "/add-spare",
    element: <AddSpare />,
  },
  {
    path: "/edit-spare/:id/*",
    element: <EditSpare />,
  },
  {
    path: "/spares",
    element: <ArchiveSpare />,
  },
  {
    path: "/spares-not-logged-in",
    element: <ArchiveSpareNotLoggedIn />,
  },
  {
    path: "/spare/:id/*",
    element: <DetailedSpare />,
  },
];
