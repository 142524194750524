import React from 'react';
import "./ThreeDotsListingComponent.css";
import divider from '../../icons/Gerator/dashboard/divider.svg';
import { Link, useNavigate } from 'react-router-dom';

import { useSetAdminArchiveWantedDeviceMutation, useSetAdminPublishWantedDeviceMutation,useSetAdminPendingWantedDeviceMutation } from '../../api/admin/AdminWantedDeviceAPI';
import { useSetAdminArchiveWantedServiceMutation, useSetAdminPublishWantedServiceMutation,useSetAdminPendingWantedServiceMutation} from '../../api/admin/AdminWantedServiceAPI';
import {useSetAdminArchiveWantedSoftwareMutation, useSetAdminPublishWantedSoftwareMutation,useSetAdminPendingWantedSoftwareMutation  } from '../../api/admin/AdminWantedSoftwareAPI';
import { useSetAdminArchiveWantedSpareMutation, useSetAdminPublishWantedSpareMutation,useSetAdminPendingWantedSpareMutation } from '../../api/admin/AdminWantedSpareAPI';

export const ThreeDotsAdminWantedListingComponent = ({  option, postId, onClose, postType, url, totalActiveEngagement }) => {
  // const handleOptionClick = (option) => {
  //   onOptionSelect(option); // Call the function from the parent with the selected option
  // };

  const navigate = useNavigate();
    
      const [setAdminToWantedDeviceArchive] = useSetAdminArchiveWantedDeviceMutation();
    
      const [setAdminToWantedDevicePublish] = useSetAdminPublishWantedDeviceMutation();
  
      const [setAdminToWantedDevicePending] = useSetAdminPendingWantedDeviceMutation();
    
  
       
      const [setAdminToWantedSpareArchive] = useSetAdminArchiveWantedSpareMutation();
    
      const [setAdminToWantedSparePublish] = useSetAdminPublishWantedSpareMutation();
  
      const [setAdminToWantedSparePending] = useSetAdminPendingWantedSpareMutation();
  
  
  
      const [setAdminToWantedServiceArchive] = useSetAdminArchiveWantedServiceMutation();
    
      const [setAdminToWantedServicePublish] = useSetAdminPublishWantedServiceMutation();
  
      const [setAdminToWantedServicePending] = useSetAdminPendingWantedServiceMutation();
  
  
      const [setAdminToWantedSoftwareArchive] = useSetAdminArchiveWantedSoftwareMutation();
    
      const [setAdminToWantedSoftwarePublish] = useSetAdminPublishWantedSoftwareMutation();
  
      const [setAdminToWantedSoftwarePending] = useSetAdminPendingWantedSoftwareMutation();
  
    const handleEdit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
    
        try {
    
          navigate(`/admin/edit-${url}`);
        } catch (error) {
          console.error('Error navigating ' + error);
        }
      };
    
      const handleSetArchive = async (e) => {
        let response;
        e.preventDefault();
        e.stopPropagation();
    
        try {
          if (!postId) {
            console.error('No post ID provided');
            return;
          }
    
          switch (postType) {
            case 'wanted-device':
              response = await setAdminToWantedDeviceArchive({ id: postId }).unwrap();
              break;
            case 'wanted-service':
              response = await setAdminToWantedServiceArchive({ id: postId }).unwrap();
              break;
            case 'wanted-software':
              response = await setAdminToWantedSoftwareArchive({ id: postId }).unwrap();
              break;
            case 'wanted-spare':
              response = await setAdminToWantedSpareArchive({ id: postId }).unwrap();
              break;
        
            default:
              break;
          }
    
          // console.log('Updating status for post:', postId);
          // const response = await setToWantedDeviceArchive({ id: postId }).unwrap();
          // console.log('Update response:', response);
    
          if (response?.status) {
            onClose();
            window.location.reload();
          }
        } catch (error) {
          console.error('Error updating status:', error);
        }
      };
    
      const handleSetPublish = async (e) => {
        let response;
        e.preventDefault();
        e.stopPropagation();
    
        try {
          if (!postId) {
            console.error('No post ID provided');
            return;
          }
    
    
          switch (postType) {
            case 'wanted-device':
              response = await setAdminToWantedDevicePublish({ id: postId }).unwrap();
              break;
            case 'wanted-service':
              response = await setAdminToWantedServicePublish({ id: postId }).unwrap();
              break;
            case 'wanted-software':
              response = await setAdminToWantedSoftwarePublish({ id: postId }).unwrap();
              break;
            case 'wanted-spare':
              response = await setAdminToWantedSparePublish({ id: postId }).unwrap();
              break;
           
            default:
              break;
          }
    
          // console.log('Updating status for post:', postId);
          // const response = await setToWantedDevicePublish({ id: postId }).unwrap();
          // console.log('Update response:', response);
    
          if (response.status) {
            onClose();
            window.location.reload();
          }
        } catch (error) {
          console.error('Error updating status:', error);
        }
      };
  
      const handleSetPending = async (e) => {
        let response;
        e.preventDefault();
        e.stopPropagation();
    
        try {
          if (!postId) {
            console.error('No post ID provided');
            return;
          }
    
    
          switch (postType) {
            case 'wanted-device':
              response = await setAdminToWantedDevicePending({ id: postId }).unwrap();
              break;
            case 'wanted-service':
              response = await setAdminToWantedServicePending({ id: postId }).unwrap();
              break;
            case 'wanted-software':
              response = await setAdminToWantedSoftwarePending({ id: postId }).unwrap();
              break;
            case 'wanted-spare':
              response = await setAdminToWantedSparePending({ id: postId }).unwrap();
              break;
           
            default:
              break;
          }
    
          // console.log('Updating status for post:', postId);
          // const response = await setToWantedDevicePublish({ id: postId }).unwrap();
          // console.log('Update response:', response);
    
          if (response.status) {
            onClose();
            window.location.reload();
          }
        } catch (error) {
          console.error('Error updating status:', error);
        }
      };
  
      const handleItemClick = (e, action) => {
        e.preventDefault();
        e.stopPropagation();
        console.log('Action clicked:', action);
    
        switch (action) {
          case 'edit':
            handleEdit(e);
            break;
          case 'publish':
            handleSetPublish(e);
            break;
          case 'archive':
            handleSetArchive(e);
            break;
            case 'pending':
            handleSetPending(e);
            break;
          // Add other cases as needed
          default:
          // handleRejectUpdate(e);
        }
      };
  
      const MenuItem = ({ onClick, text, to, action }) => (
          <div
            className="three-dots-filter-dropdown-dropdown-list-item"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (onClick) onClick(e);
              if (action) handleItemClick(e, action);
            }}
          >
            <div className="three-dots-filter-dropdown-content48">
              <div className="three-dots-filter-dropdown-icon-and-text">
                {to ? (
                  <Link
                    className="three-dots-filter-dropdown-text247"
                    to={to}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {text}
                  </Link>
                ) : (
                  <div className="three-dots-filter-dropdown-text247">{text}</div>
                )}
              </div>
            </div>
          </div>
        );

  return (
    <div className="three-dots-filter-dropdown-three-dots-filter-all-options-fixed">
      <section className="three-dots-filter-dropdown-menu-items">
      {option === 'published' && (
          <>
            {totalActiveEngagement === 0 ?
              <>
                <MenuItem text="Edit" action="edit" />
                <MenuItem text="Archive" action="archive" />
                <MenuItem text="Unpublish" action="pending" />
              </> :
              <MenuItem text="No action found" />
            }
          </>
        )}
         {option === 'archived' && (
          <>
            {totalActiveEngagement === 0 ?
              <>
                <MenuItem text="Edit" action="edit" />
                <MenuItem text="Republish" action="publish" />
                <MenuItem text="Unpublish" action="pending" />
              </> :
              <MenuItem text="No action found" />
            }
          </>
        )}

          {option === 'pending' && (
                
                  <MenuItem text="No action found" />
                  
              )}
        {/* <div className="three-dots-filter-dropdown-dropdown-list-item" >
          <div className="three-dots-filter-dropdown-content48">
            <div className="three-dots-filter-dropdown-icon-and-text">
              <Link className="three-dots-filter-dropdown-text247" to={`/admin/delete-${url}`}>Delete</Link>
            </div>
          </div>
        </div> */}
        {/* <div className="three-dots-filter-dropdown-dropdown-list-item" >
          <div className="three-dots-filter-dropdown-content48">
            <div className="three-dots-filter-dropdown-icon-and-text">
              <div className="three-dots-filter-dropdown-text247">Price (high to low)</div>
            </div>
          </div>
        </div> */}
      </section>
    </div>
  )
}

