import React, { useEffect, useRef, useState } from 'react';
import "./ActiveTeamMemberTableComponent.css";
import divider3 from '../../../icons/Gerator/sales_iq/divider3.svg';
import arrowdown from '../../../icons/Gerator/dashboard/arrowdown.svg';
import dotsvertical from '../../../icons/Gerator/sales_iq/dotsvertical.svg';
import arrowdown1 from '../../../icons/Gerator/dashboard/arrowdown1.svg';
import { useNavigate } from 'react-router-dom';
import { useLazyGetActiveMemberForTeamDashboardQuery } from '../../../api/MemberAPI';
import SkeletonTableListingLoaderComponent from '../../SkeletonTableListingLoaderComponent/SkeletonTableListingLoaderComponent';
import { NoTableDataDisplay } from '../../NoTableDataDisplay/NoTableDataDisplay';
import { ErrorShowComponent } from '../../ErrorShowComponent/ErrorShowComponent';
import { getLastSeen } from '../../../helper/DeviceHelper';
import { ThreeDotsListingComponent } from '../../ThreeDotsListingComponent/ThreeDotsListingComponent';
import { ThreeDotsMemberComponent } from '../../ThreeDotsListingComponent/ThreeDotsMemberComponent';



export const ActiveTeamMemberTableComponent = ({ currentUser, currentUsermeta }) => {

  const [loadingComplete, setLoadingComplete] = useState(false);
  let content;
  const [offSetActiveMember, setOffSetActiveMember] = useState(0);

  //For Threedots in listing dropdown
  const [activeThreeDotId, setActiveThreeDotId] = useState(null);
  const threeDotsRef = useRef(null);
  const dropdownRef = useRef(null);
  const [isClickBlocked, setIsClickBlocked] = useState(false);

  //Query to get Active Member 
  const [triggerGetActiveMemberData, { data: currentActiveMemberData, isError: isActiveMembersDataError, error: currentActiveMemberError, isLoading: isActiveMembersDataLoading, isSuccess: isActiveMembersDataSuccess, isFetching: isActiveMemberDataFetching }] = useLazyGetActiveMemberForTeamDashboardQuery();

  // const toggleThreeDotsDropdown = (id) => {
  //   if (isClickBlocked) return; // Prevent rapid toggle

  //   if (activeThreeDotId === id) {
  //     setActiveThreeDotId(null); // Close if the same one is clicked again
  //     setIsClickBlocked(true);
  //     setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
  //   } else {
  //     setActiveThreeDotId(id); // Open the clicked one
  //   }
  // };

  // const handleThreeDotsClick = (id) => {
  //   console.log("Three dots clicked for id:", id);
  //   if (activeThreeDotId === id) {
  //     setActiveThreeDotId(null);
  //   } else {
  //     setActiveThreeDotId(id);
  //   }
  // };

  useEffect(() => {
    if (currentUser) {
      triggerGetActiveMemberData({ offSetActiveMember },
        {
          // Refetch when component mounts or tab becomes active
          refetchOnMountOrArgChange: true
        });
    }
  }, [currentUser, offSetActiveMember]);


  // useEffect(() => {
  //   if (currentActiveMemberData) {
  //     console.log(currentActiveMemberData);
  //   }
  // }, [currentActiveMemberData])


  const handleThreeDotsClick = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveThreeDotId(activeThreeDotId === index ? null : index);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        threeDotsRef.current &&
        !threeDotsRef.current.contains(event.target) &&
        (!dropdownRef.current || !dropdownRef.current.contains(event.target))
      ) {
        setActiveThreeDotId(null);
        setIsClickBlocked(false); // Reset blocked state when closing
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
      setIsClickBlocked(false); // Reset on cleanup
    };
  }, []);

  // // Modified click outside handler
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     // Check if click is outside both the three dots icon and the dropdown
  //     if (
  //       threeDotsRef.current &&
  //       !threeDotsRef.current.contains(event.target) &&
  //       (!dropdownRef.current || !dropdownRef.current.contains(event.target))
  //     ) {
  //       setActiveThreeDotId(null);
  //     }
  //   };

  //   document.addEventListener("click", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, []);


  const handleActiveMemberDataLoadMore = () => {
    if (currentActiveMemberData?.hasMore && !isActiveMemberDataFetching) {
      setOffSetActiveMember(currentOffset => currentOffset + 5);
    }
  };

  const showActiveMemberDataLoadMore = currentActiveMemberData?.hasMore &&
    currentActiveMemberData.activeMembers.length < currentActiveMemberData.totalCount &&
    !isActiveMemberDataFetching;

  //Success true then pause to show sekelton layout
  useEffect(() => {
    if (isActiveMembersDataSuccess) {

      const timer = setTimeout(() => {
        setLoadingComplete(true);
      }, 1000); // 1 second

      // Cleanup timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [isActiveMembersDataSuccess]);

  if (isActiveMembersDataError) {
    content = <ErrorShowComponent message1={'Internal server error'} />
  }

  if (isActiveMembersDataLoading) {
    content = <SkeletonTableListingLoaderComponent />;
  } else if (loadingComplete && isActiveMembersDataSuccess) {

    if (currentActiveMemberData?.activeMembers.length > 0) {
      // currentActiveMemberData.activeMembersLength = currentActiveMemberData.activeMembers.length;
      content = currentActiveMemberData.activeMembers.map((data, index) =>
      (
        <div className="team-member-table-table-header-cell-parent" key={index}>

          <div className="team-member-table-table-cell10-Active">

            {/* <div className="team-member-table-cell-content-Active">
                                  <input className="team-member-table-checkbox-wrapper" type="checkbox" />
                                </div> */}

            <div className="team-member-table-avatar-Active" style={{
              backgroundImage: data.profileImage
                && `url(${data.profileImage})`
            }}>
              {/* <div className="team-member-table-contrast-border">
                                        <div className="team-member-table-avatar-online-indicator"></div>
                                    </div> */}
            </div>

            <div className="team-member-table-dropdown1-Active">
              <div className="team-member-table-cell-title-Active">{data.displayName}</div>
              <div className="team-member-table-supporting-text4-Active">{data.user_usermeta.userWorkTitle}, {data.user_usermeta.tax_country.name}</div>
            </div>
          </div>



          <div className="team-member-table-table-cell20-Active">
            <div className="team-member-table-location-content">
              <div className="team-member-table-text36">{data.email}</div>
            </div>
          </div>

          <div className="team-member-table-table-cell30-Active">
            <div className="team-member-table-location-content">
              <div className="team-member-table-text36">{data.user_usermeta.tax_user_type.name}</div>
              {/* <select
                                    className="team-member-role-dropdown"
                                  
                                >
                                    <option value="teamMember">Team Member</option>
                                    <option value="coOwner">Co-owner</option>
                                    
                                </select> */}
            </div>
          </div>

          <div className="team-member-table-table-cell30-Active">
            <div className="team-member-table-location-content">

              <div className="team-member-table-text36">{getLastSeen(data.updatedAt)}</div>
            </div>
          </div>



          <div className="team-member-table-table-cell40-Active" onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleThreeDotsClick(e, index);
          }}
            ref={threeDotsRef}>
            <div className="team-member-table-location-content" >

              <img
                className="team-member-table-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
              {/* <div className="team-member-table-text36">
                                 
                                </div> */}
              {/* {activeThreeDotId === index && <ThreeDotsMemberComponent option={data.userInvitationStatus.slug} userId={data.id}  />} */}

              {activeThreeDotId === index && (
                <div ref={dropdownRef}>
                  <ThreeDotsMemberComponent
                    option={data.userInvitationStatus.slug}
                    userId={data.id}
                    onClose={() => {
                      setActiveThreeDotId(null);
                      setIsClickBlocked(false);
                    }}
                  />
                </div>
              )}
            </div>

          </div>

        </div>

      ))
    }
    else {
      content = (
        <NoTableDataDisplay />
      )
    }

  } else if (!loadingComplete && isActiveMembersDataSuccess) {
    content = <SkeletonTableListingLoaderComponent />; // Show loader until loadingComplete is true
  }


  return (
    <>


      <div className="team-member-table-display-form-content-wrapper-Active">
        <div className="team-member-table-display-form-content-Active">
          <div className="team-member-table-display-toggle-header-parent-Active">
            <div className="team-member-table-display-toggle-header-Active">
              {/* <div className="team-member-table-display-toggle-title-Active">Active</div> */}
              {/* <PlanExpiryStatus expiryDate={currentPlanExpiry} planName={currentPlanName} /> */}
            </div>

          </div>
          {/* <div className="team-member-table-display-divider-container-Active">
                        <img
                        className="team-member-table-display-divider-icon3-Active"
                        alt=""
                        src={divider3}
                        />
                    </div> */}

          <form className="team-member-table-table-Active">

            <div className="team-member-table-table-content-Active">

              <div className="team-member-table-table-row-Active">

                {/* Invoice */}



                <div className="team-member-table-table-header-cell1-Active">
                  {/* <input className="team-member-table-checkbox-wrapper" type="checkbox" /> */}
                  <div className="team-member-table-table-header-Active">
                    <a className="team-member-table-column-title-Active">Name</a>
                    {/* <img
                                className="team-member-table-arrow-down-icon1"
                                alt=""
                                src={arrowdown}
                                /> */}
                  </div>
                </div>


                {/* Location */}

                <div className="team-member-table-table-header-cell2-Active">
                  <div className="team-member-table-table-header-Active">
                    <a className="team-member-table-column-title-Active">Email</a>

                  </div>
                </div>


                {/* Role  */}

                <div className="team-member-table-table-header-cell3-Active">
                  <div className="team-member-table-table-header-Active">
                    <a className="team-member-table-column-title-Active">Role</a>


                  </div>
                </div>

                {/* Last seen  */}

                <div className="team-member-table-table-header-cell3-Active">
                  <div className="team-member-table-table-header-Active">
                    <a className="team-member-table-column-title-Active">Last seen</a>


                  </div>
                </div>

                {/* Price  */}

                {/* <div className="team-member-table-table-header-cell4">
                            <div className="team-member-table-table-header">
                                <a className="team-member-table-column-title">Price</a>


                            </div>
                            </div> */}

                {/* Action  */}

                <div className="team-member-table-table-header-cell4-Active">
                  <div className="team-member-table-table-header2-Active">
                    <div className="team-member-table-action-column-title-Active">Action</div>
                    <img
                      className="team-member-table-arrow-down-icon2-Active"
                      alt=""
                      src={arrowdown}
                    />
                  </div>
                </div>

              </div>



            </div>

            <div className='team-member-table-table-data-custom-main-container'>

              {content}
            </div>

            {/* Load More  */}
            {showActiveMemberDataLoadMore || undefined && (
              <div className="team-member-table-frame-wrapper44">
                <div className="team-member-table-frame-parent72">
                  <div className="team-member-table-buttonsbutton-wrapper1">

                    <button className='team-member-table-buttonsbutton-wrapper1-child'
                      onClick={handleActiveMemberDataLoadMore}
                      disabled={isActiveMemberDataFetching}
                    >
                      <div className="team-member-table-buttonsbutton28">
                        <img
                          className="team-member-table-arrow-narrow-left-icon"
                          alt=""
                          src={arrowdown1}
                        />
                        <div className="team-member-table-search-input">
                          <div className="team-member-table-text146">{isActiveMemberDataFetching ? 'Loading...' : 'Load More'}</div>
                        </div>
                      </div>
                    </button>

                  </div>
                </div>
              </div>
            )}



          </form>


        </div>
      </div >
    </>
  )
}

