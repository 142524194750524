import React from "react";
import "./ThreeDotsListingComponent.css";

export const ThreeDotsDownloadComponent = ({ invoiceUrl }) => {

  console.log("Invoice URL Prop:", invoiceUrl);
  
  const handleDownload = () => {

     // Debugging

   
      console.log("Download button clicked");
      
      if (!invoiceUrl) {
        console.error("No invoice URL provided");
        return;
      }

    
    // if (!invoiceUrl) {
    //   console.error("No invoice URL provided");
    //   return;
    // }

    const link = document.createElement("a");
    link.href = invoiceUrl;
    link.download = "Gerator invoice.pdf"; // ✅ Change filename as needed
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (

      <div className="three-dots-filter-dropdown-three-dots-filter-all-options-fixed">
          <section className="three-dots-filter-dropdown-menu-items">
            <div className="three-dots-filter-dropdown-dropdown-list-item" >
              <div className="three-dots-filter-dropdown-content48">
                <div className="three-dots-filter-dropdown-icon-and-text" >
                  <div className="three-dots-filter-dropdown-text247"  onClick={handleDownload} >Download</div>
                </div>
              </div>
            </div>
          
            
          </section>
        </div>  
    // <div className="three-dots-no-action-filter-dropdown">
    //   <div className="three-dots-no-action-filter-dropdown-item" onClick={handleDownload}>
    //     Download Invoice
    //   </div>
    // </div>
  );
};
