//
// import { ChatPageDeviceOwner} from "../screens/Chat/ChatPage";
// import { ChatPageDeviceApproacher } from "../screens/Chat/ChatPageDeviceApproacher";
//
// import { ChatPageSpareOwner } from "../screens/Chat/ChatPageSpareOwner";
// import { ChatPageSpareApproacher } from "../screens/Chat/ChatPageSpareApproacher";
//
// import { ChatPageSoftwareOwner } from "../screens/Chat/ChatPageSoftwareOwner";
// import { ChatPageSoftwareApproacher } from "../screens/Chat/ChatPageSoftwareApproacher";
//
//
// import { ChatPageServiceOwner } from "../screens/Chat/ChatPageServiceOwner";
// import { ChatPageServiceApproacher } from "../screens/Chat/ChatPageServiceApproacher";
//
//
//
// import { ChatPageJobOwner } from "../screens/Chat/ChatPageJobOwner";
// import { ChatPageJobApproacher } from "../screens/Chat/ChatPageJobApproacher";
//
// import { ChatPageWantedDeviceOwner } from "../screens/Chat/Wanted/ChatPageWantedDeviceOwner";
// import { ChatPageWantedDeviceApproacher } from "../screens/Chat/Wanted/ChatPageWantedDeviceApproacher";
//
//
// import { ChatPageWantedSpareOwner } from "../screens/Chat/Wanted/ChatPageWantedSpareOwner";
// import { ChatPageWantedSpareApproacher } from "../screens/Chat/Wanted/ChatPageWantedSpareApproacher";
//
//
// import { ChatPageWantedSoftwareOwner } from "../screens/Chat/Wanted/ChatPageWantedSoftwareOwner";
// import { ChatPageWantedSoftwareApproacher } from "../screens/Chat/Wanted/ChatPageWantedSoftwareApproacher";
//
//
// import { ChatPageWantedServiceOwner } from "../screens/Chat/Wanted/ChatPageWantedServiceOwner";
// import { ChatPageWantedServiceApproacher } from "../screens/Chat/Wanted/ChatPageWantedServiceApproacher";
//
// export const chatRoutes = [
//
//   //DEVICE
//   {
//   path: "/dashboard/chat/:listing/:entity/:id",
//   element: <ChatPageDeviceOwner/>,
// },
// {
// path: "/chat/:listing/Device/:id",
// element: <ChatPageDeviceApproacher />,
// },
//
// //SPARE
// {
// path: "/dashboard/chat/:listing/:entity/:id",
// element: <ChatPageSpareOwner />,
// },
// {
//   path: "/chat/:listing/:entity/:id",
// element: <ChatPageSpareApproacher />,
// },
//
// //SOFTWARE
// {
//   path: "/dashboard/chat/:listing/:entity/:id",
//   element: <ChatPageSoftwareOwner />,
//   },
//   {
//     path: "/chat/:listing/:entity/:id",
//   element: <ChatPageSoftwareApproacher />,
//   },
//
//
// //SERVICE
//
// {
//   path: "/dashboard/chat/:listing/:entity/:id",
//   element: <ChatPageServiceOwner />,
//   },
//   {
//     path: "/chat/:listing/:entity/:id",
//   element: <ChatPageServiceApproacher />,
//   },
//
//
//
// //JOB
//
//   {
//     path: "/dashboard/chat/:listing/:entity/:id",
//     element: <ChatPageJobOwner />,
//   },
//   {
//     path: "/chat/:listing/:entity/:id",
//     element: <ChatPageJobApproacher />,
//   },
//
// //WANTED DEVICE
//
//   {
//     path: "/beacon/chat/:listing/:entity/:id",
//     element: <ChatPageWantedDeviceOwner />,
//   },
//   {
//     path: "/demand-center/chat/:listing/:entity/:id",
//     element: <ChatPageWantedDeviceApproacher />,
//   },
//
//     //WANTED SPARE
//
//   {
//     path: "/beacon/chat/:listing/:entity/:id",
//     element: <ChatPageWantedSpareOwner />,
//   },
//   {
//     path: "/demand-center/chat/offered/Spare/:id",
//     element: <ChatPageWantedSpareApproacher />,
//   },
//
//   //WANTED SOFTWARE
//
//   {
//     path: "/beacon/chat/wanted/Software/:id",
//     element: <ChatPageWantedSoftwareOwner/>,
//   },
//   {
//     path: "/demand-center/chat/wanted/Software/:id",
//     element: <ChatPageWantedSoftwareApproacher />,
//   },
//
//   //WANTED SERVICE   "/beacon/chat/wanted/:entity/:id"
//
//   {
//     path: "/beacon/chat/wanted/Service/:id",
//     element: <ChatPageWantedServiceOwner/>,
//   },
//   {
//     path: "/demand-center/chat/wanted/Service/:id",
//     element: <ChatPageWantedServiceApproacher />,
//   },
//
// ];
import { useParams } from "react-router-dom";
import { ChatPageDeviceOwner } from "../screens/Chat/ChatPageDeviceOwner";
import { ChatPageDeviceApproacher } from "../screens/Chat/ChatPageDeviceApproacher";

import { ChatPageSpareOwner } from "../screens/Chat/ChatPageSpareOwner";
import { ChatPageSpareApproacher } from "../screens/Chat/ChatPageSpareApproacher";

import { ChatPageSoftwareOwner } from "../screens/Chat/ChatPageSoftwareOwner";
import { ChatPageSoftwareApproacher } from "../screens/Chat/ChatPageSoftwareApproacher";


import { ChatPageServiceOwner } from "../screens/Chat/ChatPageServiceOwner";
import { ChatPageServiceApproacher } from "../screens/Chat/ChatPageServiceApproacher";



import { ChatPageJobOwner } from "../screens/Chat/ChatPageJobOwner";
import { ChatPageJobApproacher } from "../screens/Chat/ChatPageJobApproacher";

import { ChatPageWantedDeviceOwner } from "../screens/Chat/Wanted/ChatPageWantedDeviceOwner";
import { ChatPageWantedDeviceApproacher } from "../screens/Chat/Wanted/ChatPageWantedDeviceApproacher";


import { ChatPageWantedSpareOwner } from "../screens/Chat/Wanted/ChatPageWantedSpareOwner";
import { ChatPageWantedSpareApproacher } from "../screens/Chat/Wanted/ChatPageWantedSpareApproacher";


import { ChatPageWantedSoftwareOwner } from "../screens/Chat/Wanted/ChatPageWantedSoftwareOwner";
import { ChatPageWantedSoftwareApproacher } from "../screens/Chat/Wanted/ChatPageWantedSoftwareApproacher";


import { ChatPageWantedServiceOwner } from "../screens/Chat/Wanted/ChatPageWantedServiceOwner";
import { ChatPageWantedServiceApproacher } from "../screens/Chat/Wanted/ChatPageWantedServiceApproacher";


// **Wrapper Component to Conditionally Render Chat Pages**
const ChatPageWrapper = () => {
  const { listing, entity, id } = useParams();

  const isWanted = listing === "wanted";

  switch (entity) {
    case "Device":
      return isWanted ? <ChatPageWantedDeviceApproacher /> : <ChatPageDeviceApproacher />;
    case "Spare":
      return isWanted ? <ChatPageWantedSpareApproacher /> : <ChatPageSpareApproacher />;
    case "Software":
      return isWanted ? <ChatPageWantedSoftwareApproacher /> : <ChatPageSoftwareApproacher />;
    case "Service":
      return isWanted ? <ChatPageWantedServiceApproacher /> : <ChatPageServiceApproacher />;
    case "Job":
      return <ChatPageJobApproacher />;
    default:
      return <ChatPageDeviceApproacher />; // Default fallback
  }
};

const ChatPageOwnerWrapper = () => {
  const { listing, entity, id } = useParams();

  const isWanted = listing === "wanted";

  switch (entity) {
    case "Device":
      return isWanted ? <ChatPageWantedDeviceOwner /> : <ChatPageDeviceOwner />;
    case "Spare":
      return isWanted ? <ChatPageWantedSpareOwner /> : <ChatPageSpareOwner />;
    case "Software":
      return isWanted ? <ChatPageWantedSoftwareOwner /> : <ChatPageSoftwareOwner />;
    case "Service":
      return isWanted ? <ChatPageWantedServiceOwner /> : <ChatPageServiceOwner />;
    case "Job":
      return <ChatPageJobOwner />;
    default:
      return <ChatPageDeviceOwner />; // Default fallback
  }
};

export const chatRoutes = [
  // **General Chat Routes with Conditional Rendering**
  {
    path: "/dashboard/chat/:listing/:entity/:id",
    element: <ChatPageOwnerWrapper />,
  },
  {
    path: "/chat/:listing/:entity/:id",
    element: <ChatPageWrapper />,
  },
];
