import React, { useState } from 'react';
import "./AddDevice.css"
import { Navbar1 } from '../../../components/Navbar1/Navbar1'
import { FooterAddForms } from '../../../components/Footer/FooterAddForms/FooterAddForms';
import { HeaderForms } from '../../../components/Header/HeaderForms/HeaderForms';
import divider from '../../../icons/Gerator/add_form/navbar_divider/divider.svg';
import divider_light from '../../../icons/Gerator/divider.svg';
import { Sidebar } from '../../../components/Sidebar/Sidebar';
import { SideBarSubProgressStep1 } from '../../../components/SideBars-Sub/SideBarSubAddForm/SideBarSubProgressStep1';
import { SideBarSubProgressStep2 } from '../../../components/SideBars-Sub/SideBarSubAddForm/SideBarSubProgressStep2';
import { SideBarSubProgressStep3 } from '../../../components/SideBars-Sub/SideBarSubAddForm/SideBarSubProgressStep3';
import AddDeviceParent from '../../../components/AddDeviceFormComponent/AddDeviceParent';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { firestore, doc, getDoc, auth, onAuthStateChanged } from '../../Login/FirebaseClient';
import { Loader } from '../../../components/Loader';
import { useLazyGetProfileImageLinkQuery, useLazyGetUserProfileQuery, useLazyGetUserQuery } from '../../../api/Login&RegisterAPI';
import { useEffect } from 'react';
import { useRef } from 'react';
import { decryptErrorData } from '../../../helper/AESHelper';
import { Helmet } from 'react-helmet-async';
import { ErrorShowComponent } from '../../../components/ErrorShowComponent/ErrorShowComponent';
import { useLazyFindGetMyBusinessQuery, useLazyFindIsUserHasBusinessQuery, useLazyGetBusinessAllListingCountQuery } from '../../../api/BusinessAPI';
import { NoBusinessFound } from '../../../components/NoBusiness/NoBusiness';
import { NoAccessAddress } from '../../../components/NoAccessAddress/NoAccessAddress';
import { NoAccessFormUpgradePlan } from '../../../components/NoAccessFormUpgradePlan/NoAccessFormUpgradePlan';
import { useGetPlansForAddressQuery, useLazyGetCurrentPlanOfUserQuery } from '../../../api/PlanAPI';
import { isPlanExpired } from '../../../helper/UserHelper';
import { NoAccessExpirePlan } from '../../../components/NoAccessFormUpgradePlan/NoAccessExpirePlan';
import { NoAccessRequestedMember } from '../../../components/NoAccessFormUpgradePlan/NoAccessRequestedMember';
import { NoAccessInactiveMember } from '../../../components/NoAccessFormUpgradePlan/NoAccessInactiveMember';
import { NoAccessRevokedMember } from '../../../components/NoAccessFormUpgradePlan/NoAccessRevokedMember';
import { NoBusinessPublished } from '../../../components/NoBusinessPublished/NoBusinessPublished';

const renderProgressStep = (step, userNameEmail) => {
  switch (step) {
    case 0:
      return <SideBarSubProgressStep1 userData={userNameEmail} />;
    case 1:
      return <SideBarSubProgressStep2 userData={userNameEmail} />;
    case 2:
      return <SideBarSubProgressStep3 userData={userNameEmail} />;
    default:
      return null;
  }
};


export const AddDevice = () => {

  let content = "";
  const appData = useSelector((state) => state.appDataAddDevice);
  // Progress var when this is true means all taxonomy loaded
  const [isTaxonomySet, setIsTaxonomySet] = useState(false);
  // const [triggerGetUser, { data, isSuccess, error, isLoading, isError }] = useLazyGetUserQuery();
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState({
    message: "",
    color: "",
  });
  const [currentPlanConditionData, setCurrentPlanConditionData] = useState("")
  const [profileImageLink, setProfileImageLink] = useState("");
  const [currentPlanAllowedListing, setCurrentPlanAllowedListing] = useState("");
  const [currentRemainingListing, setCurrentRemainingListing] = useState("");
  const [currentUsermeta, setCurrentUsermeta] = useState("");
  // const [currentPlanExpiry, setCurrentPlanExpiry] = useState("");
  // const [currentPlanName, setCurrentPlanName] = useState("");
  // const [triggerGetCurrentPlan, { data: currentPlanData, isError: isCurrentPlanError, error: currentPlanError, isLoading: isCurrentPlanLoading, isSuccess: isCurrentPlanSuccess }] = useLazyGetCurrentPlanOfUserQuery();
  const [triggerNewProfileImageLink] = useLazyGetProfileImageLinkQuery();

  // const [triggerGetBusiness, { data, isSuccess, error, isLoading, isError }] = useLazyFindIsUserHasBusinessQuery();

  const { data: planConditionData, isSuccess: isPlanConditionSuccess, error: planConditionError, isLoading: isPlanConditionLoading, isError: isPlanConditionError } = useGetPlansForAddressQuery();

  const [triggerGetListingCount, { data: listingCountData, isError: isListingCountError }] = useLazyGetBusinessAllListingCountQuery();


  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {

        if (user) {
          // Get user to check wizardStep
          const userDocRef = doc(firestore, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);
          setCurrentUser(user);

          if (userDocSnapshot.exists()) {
            const usermeta = userDocSnapshot.data(); // Default to 1 if not set

            if (usermeta.userStatus == 2) {
              navigate('/account-disabled');
              return;
            }

            if (usermeta.userType == 5) {
              navigate('/access-denied');
              return;
            }

            if (usermeta.wizardStep !== 0) {
              navigate('/account-setup-check-email');
              return;
            }

            // await triggerGetCurrentPlan().unwrap();
            setCurrentUsermeta(usermeta);
            // triggerGetBusiness();
            triggerGetListingCount();
            // User is authenticated
          } else {
            content = <ErrorShowComponent message1={"Internal server error"} />
          }
        }
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });


    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);

  //Profile image load useEffect
  useEffect(() => {
    const refreshProfileImage = async () => {
      if (currentUsermeta && currentUsermeta.profileImage.expiresAt < Date.now() / 1000) {
        // Pre-signed URL has expired, trigger a new request to the backend
        const newImageData = await triggerNewProfileImageLink().unwrap();
        // Update the profile image link directly
        setProfileImageLink(newImageData.url);
      } else if (currentUsermeta && currentUsermeta.profileImage.url) {
        // Use the existing pre-signed URL to display the profile image
        setProfileImageLink(currentUsermeta.profileImage.url);
      }
    };

    refreshProfileImage();
  }, [currentUsermeta]);


  //Load plan data to restrict page access
  useEffect(() => {
    if (planConditionData && isPlanConditionSuccess && currentUsermeta) {
      console.log(planConditionData);
      const foundObject = planConditionData.data.find(item => item.id === currentUsermeta.userPlan);
      setCurrentPlanAllowedListing(foundObject.planAllowedListing);
      setCurrentPlanConditionData(foundObject);
    }

  }, [isPlanConditionSuccess, planConditionData, currentUsermeta]);


  // Set remaining listing useState to set condition 
  useEffect(() => {
    if (!listingCountData || !currentPlanAllowedListing) {
      return;
    }

    const remainingListingOfCurrentPlan = currentPlanAllowedListing - listingCountData.data;
    console.log(remainingListingOfCurrentPlan);
    setCurrentRemainingListing(remainingListingOfCurrentPlan);

  }, [listingCountData, currentPlanAllowedListing]);

  // if (isError || isListingCountError) {

  //   content = <ErrorShowComponent message1={"Internal server error"} />

  // }

  // if (isSuccess) {
  //   console.log(data);
  //   if (data.status && currentRemainingListing) {
  //     content = <>
  //       <AddDeviceParent setShowSuccessMessage={setShowSuccessMessage} setIsTaxonomySet={setIsTaxonomySet} currentPlanData={currentPlanConditionData} />
  //     </>
  //   } else if (data.status && !currentRemainingListing) {
  //     content = <NoAccessFormUpgradePlan path={'/dashboard/settings/plan'} pathName={"Upgrade"} />
  //   }
  //   else {
  //     content = <NoBusinessFound path={'/dashboard/settings/business/add-business'} pathName={"Business"} />
  //   }

  // }
// If business page created but still in pending state then show banner
  if (currentUsermeta.businessPostStatus === 2){
    content = <NoBusinessPublished path={'/dashboard/settings/business'} />
  } else {

    // User Type is Normal
    if (currentUsermeta.userType === 1) {
      content = <NoBusinessFound path={'/dashboard/settings/business/add-business'} pathName={"Business"} />
    }
  
    // User Type is Normal but requested for team member
    if (currentUsermeta.userType === 1 && currentUsermeta?.isMemberRequested) {
      content = <NoAccessRequestedMember path={'/dashboard/settings/business'} pathName={"Request Status"} />
    }
  
    // User type is Owner
    if (currentUsermeta.userType === 2) {
  
      // If user plan is 1 then dont check for plan expiry and business verification
      if (currentUsermeta.userPlan === 1) {
  
        if (currentRemainingListing > 0) {
          content = <>
            <AddDeviceParent setShowSuccessMessage={setShowSuccessMessage} currentUsermeta={currentUsermeta} currentPlanData={currentPlanConditionData} />
          </>
  
        } else {
  
          content = <NoAccessFormUpgradePlan path={'/dashboard/settings/plan'} pathName={"Upgrade"} />
        }
  
  
      } else {
  
        //Plan is greater than 1 then Checking plan is expired or not
        if (isPlanExpired(currentUsermeta.userPlanExpiry)) {
  
          content = <NoAccessExpirePlan path={'/dashboard/settings/plan'} pathName={"Upgrade"} />
  
        } else {
  
          if (currentRemainingListing > 0) {
            content = <>
              <AddDeviceParent setShowSuccessMessage={setShowSuccessMessage} currentUsermeta={currentUsermeta} currentPlanData={currentPlanConditionData} />
            </>
  
          } else {
  
            content = <NoAccessFormUpgradePlan path={'/dashboard/settings/plan'} pathName={"Upgrade"} />
          }
        }
  
      }
  
    }
  
  
    
  
    if (currentUsermeta.userType === 3) {
  
      //Check plan is expired or not
      if (isPlanExpired(currentUsermeta.userPlanExpiry)) {
  
        content = <NoAccessExpirePlan path={'/dashboard/settings/plan'} pathName={"Upgrade"} />
  
      } else {
        // Check if team member is active or not
        if (currentUsermeta.isMemberActive) {
          //Current user remaing listing quota is greater than 0 then allow to add listing
          if (currentRemainingListing > 0) {
            content = <>
              <AddDeviceParent setShowSuccessMessage={setShowSuccessMessage} currentUsermeta={currentUsermeta} currentPlanData={currentPlanConditionData} />
            </>
  
          } else {
  
            content = <NoAccessFormUpgradePlan path={'/dashboard/settings/plan'} pathName={"Upgrade"} />
          }
        } else if (!currentUsermeta.isMemberInvited) {
          //Checked if invite was revoked 
          content = <NoAccessRevokedMember />
        } else if (currentUsermeta.isMemberRequested) {
          content = <NoAccessRequestedMember path={'/dashboard/settings/business'} pathName={"Request Status"} />
        } else {
          // Account is not Active
          content = <NoAccessInactiveMember />
        }
      }
    }
  
  
    // User Type is Co-Owner Member
    if (currentUsermeta.userType === 4) {
  
      //Check plan is expired or not
      if (isPlanExpired(currentUsermeta.userPlanExpiry)) {
  
        content = <NoAccessExpirePlan path={'/dashboard/settings/plan'} pathName={"Upgrade"} />
  
      } else {
        // Check if team member is active or not
        if (currentUsermeta.isMemberActive) {
          //Current user remaing listing quota is greater than 0 then allow to add listing
          if (currentRemainingListing > 0) {
            content = <>
              <AddDeviceParent setShowSuccessMessage={setShowSuccessMessage} currentUsermeta={currentUsermeta} currentPlanData={currentPlanConditionData} />
            </>
  
          } else {
  
            content = <NoAccessFormUpgradePlan path={'/dashboard/settings/plan'} pathName={"Upgrade"} />
          }
        } else if (!currentUsermeta.isMemberInvited) {
          //Checked if invite was revoked 
          content = <NoAccessRevokedMember />
        } else {
          // Account is not Active
          content = <NoAccessInactiveMember />
        }
      }
    }

  }




  if (content !== "" && profileImageLink) {
    return (

      <>
        <Helmet>
          <title>Add Device</title>
        </Helmet>
        <div className="forms-add-device forms-add-device-custom-margin">
          {/* <div className="forms-add-device-child"></div> */}


          <Navbar1 userData={currentUser} navigate={navigate} profileImageLink={profileImageLink} />


          <img className="divider-icon" alt="" src={divider_light} />

          <main className="content-parent">

            {/* <Sidebar><SideBarSubNormal userData={undefined}/></Sidebar> */}
            <Sidebar profileImageLink={profileImageLink}>{renderProgressStep(appData.currentStep, { displayName: currentUser.displayName, email: currentUser.email })}</Sidebar>
            <section className="bottom-actions bottom-actions-custom-padding">
              <div className="progress-container-parent">
                <div className="progress-container">
                  {/* <div className="progress-steps">  */}
                  {/* <div className="form-parent">  */}

                  {/* <ProgressStep1/>  */}
                  {/* <ProgressStep2/> 
                 <ProgressStep3/> 
                 </div> 
                 </div>  */}
                  <div className="content5">
                    <div className="main">

                      <HeaderForms text="Add Device " text1=" Add Device Listing" text2="Your first step to realizing asset value." />




                      <div className="main-child"></div>
                      <div className="main-item"></div>
                    </div>
                    <div className="body">
                      {content}
                    </div>
                    {showSuccessMessage.message && (
                      <div
                        style={{
                          fontSize: "14px",
                          color: showSuccessMessage.color,
                          padding: "15px 10px",
                          border: "1px solid " + showSuccessMessage.color,
                          borderRadius: "10px",
                          margin: "0 auto",
                        }}
                      >
                        {showSuccessMessage.message}
                      </div>
                    )}
                  </div>
                </div>

              </div>
            </section>

          </main>
          <img
            className="divider-icon"
            loading="lazy"
            alt=""
            src={divider_light}
          />

          <FooterAddForms />

        </div>
      </>
    )
  } else {
    return <Loader />
  }


}

// User type is Team Member
  // if (currentUsermeta.userType === 3) {

  //   //Check plan is expired or not
  //   if (isPlanExpired(currentUsermeta.userPlanExpiry)) {

  //     content = <NoAccessExpirePlan path={'/dashboard/settings/plan'} pathName={"Upgrade"} />

  //   } else {
  //     // Check if team member is active or not
  //     if (currentUsermeta.isMemberActive) {
  //       // Business is Verified or not
  //       if (currentUsermeta.businessVerification === 1) {
  //         //Current user remaing listing quota is greater than 0 then allow to add listing
  //         if (currentRemainingListing > 0) {
  //           content = <>
  //             <AddDeviceParent setShowSuccessMessage={setShowSuccessMessage} currentUsermeta={currentUsermeta} currentPlanData={currentPlanConditionData} />
  //           </>

  //         } else {

  //           content = <NoAccessFormUpgradePlan path={'/dashboard/settings/plan'} pathName={"Upgrade"} />
  //         }

  //       } else {

  //         content = <ErrorShowComponent message1={"Business is not verified"} message2={'Please wait! while we verify business'} />

  //       }
  //     } else if (!currentUsermeta.isMemberInvited) {
  //       //Checked if invite was revoked 
  //       content = <NoAccessRevokedMember />
  //     } else if (currentUsermeta.isMemberRequested) {
  //       content = <NoAccessRequestedMember path={'/dashboard/settings/business'} pathName={"Request Status"} />
  //     } else {
  //       // Account is not Active
  //       content = <NoAccessInactiveMember />
  //     }
  //   }
  // }